(function() {
  'use strict';
  angular
    .module('app.organization')
    .controller('OrganizationProfileController', OrganizationProfileController);

  OrganizationProfileController.$inject = [
    '$state',
    'APP_CONSTANTS',
    '$stateParams',
    'SessionService',
    '$scope',
    '$uibModal',
    'OrganizationProfileService',
    'ngDialog',
    'toaster'
  ];

  function OrganizationProfileController(
    $state,
    APP_CONSTANTS,
    $stateParams,
    SessionService,
    $scope,
    $uibModal,
    OrganizationProfileService,
    ngDialog,
    toaster
  ) {
    var vm = this;

    vm.loading = {};
    vm.searchZip = '';
    vm.searchURL = '';
    vm.searchName = '';
    vm.formSubmit = false;
    vm.myCroppedImage = '';
    vm.readOnlyFlag = true;
    vm.fetchSchools = fetchSchools;
    vm.fetchSchoolId = fetchSchoolId;
    vm.removeSchoolId = removeSchoolId;
    vm.schoolSelected = schoolSelected;
    vm.countrySelected = countrySelected;
    vm.search = APP_CONSTANTS.searchSchool;
    vm.updateOrganization = updateOrganization;
    vm.availableLanguages = APP_CONSTANTS.availableLanguages;
    vm.availableCountries = APP_CONSTANTS.availableCountries;

    var userLoginDetail = SessionService.getUser();
    // TODO to refactor this in future.
    var customerId = userLoginDetail.sponsoredOrganizationId;
    var customerCountry;

    if (
      userLoginDetail.roles.isAccountManager ||
      userLoginDetail.roles.isOrganizationManager ||
      userLoginDetail.roles.isCounselor
    ) {
      vm.readOnlyFlag = false;
    }

    function schoolSelected() {
      vm.schools = [];
    }

    function countrySelected() {
      if (customerCountry.id !== vm.orgInfo.country.id) {
        vm.schools = [];
        vm.searchSchool = null;
        vm.orgInfo.schoolId = null;
        vm.orgInfo.collegeMajor = 'all';
        customerCountry = vm.orgInfo.country;
      }
    }

    var getOrganizationDetail = function() {
      vm.loading.org = true;
      OrganizationProfileService.fetchOrganization(customerId).then(
        function(response) {
          vm.orgInfo = response.data.data;

          customerCountry = vm.orgInfo.country;
          vm.orgInfo.educationLevel = vm.orgInfo.educationLevel || 'all';
          vm.orgInfo.collegeMajor = vm.orgInfo.collegeMajor || 'all';
          vm.orgInfo.careersAndMajors = vm.orgInfo.careersAndMajors || 'all';

          /** TODO - remove: commented due to slow loading of profileImage on blob storage upload */
          // vm.myCroppedImage = vm.orgInfo.profileImage || 'app/common/img/user/user.png';
          vm.myCroppedImage =
            SessionService.getOrganizationImage() ||
            APP_CONSTANTS.defaultOrganizationImage;
          vm.loading.org = false;
        },
        function(error) {
          vm.loading.org = false;
          toaster.pop('error', error.data.message || error.statusText);
        }
      );
    };

    vm.saveImage = function() {
      var imageData = {};

      vm.loading.org = true;
      imageData.base64 = vm.myCroppedImage;
      OrganizationProfileService.uploadImage(imageData, vm.orgInfo.id).then(
        function() {
          vm.loading.org = false;
          SessionService.setOrganizationImage(vm.myCroppedImage);
          toaster.pop({
            type: 'success',
            body: APP_CONSTANTS.MESSAGE.sucessfullyUpdate,
            showCloseButton: true,
            bodyOutputType: 'trustedHtml',
            timeout: 3000,
            onHideCallback: function() {
              $state.reload();
            }
          });
        },
        function(error) {
          vm.loading.org = false;
          if (error.status === 413) {
            toaster.pop('error', APP_CONSTANTS.MESSAGE.imageSizeLarge);
          } else {
            toaster.pop('error', error.data.message || error.statusText);
          }
          vm.myCroppedImage =
            vm.orgInfo.profileImage || APP_CONSTANTS.defaultOrganizationImage;
        }
      );
    };

    function updateOrganization() {
      vm.loading.org = true;

      OrganizationProfileService.updateOrganization(vm.orgInfo)
        .then(function(response) {
          var user = SessionService.getUser();

          user.schoolId = response.data.data.IPED_ID;
          user.customerLegalName = response.data.data.legalName;
          user.country = response.data.data.country;
          user.language = response.data.data.language;
          user.careersAndMajors = response.data.data.careersAndMajors;
          SessionService.setUser(user);
          getOrganizationDetail();
          toaster.pop('success', APP_CONSTANTS.MESSAGE.sucessfullyUpdate);
        })
        .catch(function(error) {
          vm.loading.org = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function fetchSchools(key, type, country) {
      if (key) {
        var params = {
          searchKey: key,
          searchType: type,
          searchCountry: country
        };

        OrganizationProfileService.fetchSchools(params).then(function(
          response
        ) {
          vm.schools = response.data.data;
          if (vm.schools.length === 0) {
            if (type === vm.search.name) {
              vm.schools.push({ name: key });
            } else {
              vm.schools.push({ zip: key });
            }
          }
        });
      }
    }

    function removeSchoolId() {
      ngDialog
        .openConfirm({
          template: 'deleteConformDialog'
        })
        .then(
          function() {
            vm.orgInfoDetail.$setPristine(true);
            vm.orgInfo.schoolId = null;
            // College Major should be "All (default)" when school ID is removed.
            vm.orgInfo.collegeMajor = 'all';
            // Clear the input fields
            vm.searchSchool = null;
            vm.searchZip = '';
            vm.searchURL = '';
          },
          function() {
            ngDialog.close();
          }
        );
    }

    function fetchSchoolId() {
      if (!vm.searchSchool && vm.searchURL === '') {
        toaster.pop('warning', APP_CONSTANTS.MESSAGE.ipedValidation);

        return;
      } else {
        vm.loading.org = true;
        var schoolName = vm.searchSchool ? vm.searchSchool.name : '';
        var searchZip = vm.searchSchool ? vm.searchSchool.zip : '';
        var params = {
          zip: searchZip,
          url: vm.searchURL,
          name: schoolName,
          country: vm.orgInfo.country.id
        };

        OrganizationProfileService.fetchSchoolId(params).then(
          function(response) {
            vm.loading.org = false;
            vm.orgInfo.schoolId = response.data.data.id;
            toaster.pop('success', response.data.data.message);
          },
          function(error) {
            vm.orgInfoDetail.$setPristine(true);
            vm.loading.org = false;
            vm.searchSchool = null;
            vm.searchZip = '';
            vm.searchURL = '';
            toaster.pop('error', error.data.message || error.statusText);
            vm.myCroppedImage = vm.orgInfo.profileImage;
          }
        );
      }
    }

    function activate() {
      $scope.reset = function() {
        $scope.myImage = '';
        $scope.imgcropType = 'square';
      };
      $scope.reset();

      var handleFileSelect = function(evt) {
        var file = evt.currentTarget.files[0];
        var reader = new FileReader();

        reader.onload = function(evt) {
          $scope.$apply(function() {
            $scope.myImage = evt.target.result;
            $scope.myCroppedImage = evt.target.result;
          });
        };
        if (file) reader.readAsDataURL(file);
      };

      angular
        .element(document.querySelector('#fileInput'))
        .on('change', handleFileSelect);

      vm.open = function(size) {
        var modalInstance = $uibModal.open({
          templateUrl: '/myModalContent.html',
          controller: ModalInstanceCtrl,
          size: size
        });
        var state = $('#modal-state');

        modalInstance.result.then(
          function(url) {
            $scope.myCroppedImage = url;
            state.text('Modal dismissed with OK status');
          },
          function() {
            state.text('Modal dismissed with Cancel status');
          }
        );
      };

      ModalInstanceCtrl.$inject = ['$scope', '$uibModalInstance'];

      function ModalInstanceCtrl($scope, $uibModalInstance) {
        $scope.wrongFormat = false;
        $scope.ok = function(cropUrl) {
          if (
            cropUrl.substring(0, 10) === 'data:image' &&
            cropUrl.length > 40
          ) {
            $scope.wrongFormat = false;
            vm.myCroppedImage = cropUrl;
            vm.saveImage();
            $uibModalInstance.close(cropUrl);
          } else {
            $scope.wrongFormat = true;

            return;
          }
        };
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }

      $scope.validateImage = function(files) {
        var ext = files[0].name.match(/\.(.+)$/)[1];

        if (
          angular.lowercase(ext) === 'jpg' ||
          angular.lowercase(ext) === 'jpeg' ||
          angular.lowercase(ext) === 'png' ||
          angular.lowercase(ext) === 'gif'
        ) {
          $scope.wrongFormat = false;
        } else {
          $scope.wrongFormat = true;
        }
      };
    }

    getOrganizationDetail();
    activate();
  }
})();
