(function() {
  'use strict';
  angular
    .module('app.customerAccount')
    .controller('NewSubscriptionController', SubscriptionController);

  SubscriptionController.$inject = [
    '$state',
    'APP_CONSTANTS',
    'toaster',
    'NewSubscriptionService',
    '$uibModal',
    '$rootScope',
    '$scope'
  ];

  function SubscriptionController(
    $state,
    APP_CONSTANTS,
    toaster,
    NewSubscriptionService,
    $uibModal,
    $rootScope,
    $scope
  ) {
    var vm = this;

    vm.loading = {};
    vm.percentage = 0;
    vm.usedQuantity = 0;
    vm.totalQuantity = 0;
    vm.displayAddonsFlag = false;
    vm.updateCustomLabel = APP_CONSTANTS.MESSAGE.updateCustomLabel;

    vm.displayAddons = displayAddons;
    vm.openDialogBox = openDialogBox;

    vm.dtOptions = {
      order: [],
      columnDefs: [
        {
          orderable: false,
          targets: 'no-sort'
        }
      ]
    };

    var modalInstance = '';

    activate();

    function activate() {
      $scope.$parent.vm.setActiveTab();
      fetchSubscriptions();
    }

    function fetchSubscriptions() {
      vm.loading.categories = true;

      NewSubscriptionService.fetchSubscription()
        .then(function(response) {
          var data = _.get(response, 'data.data', {});

          vm.usedQuantity = data.usedQuantity || 0;
          vm.totalQuantity = data.totalQuantity || 0;
          vm.percentage =
            (vm.totalQuantity && (vm.usedQuantity * 100) / vm.totalQuantity) ||
            0;
          vm.subscriptions = data.subscriptions;
          vm.subscriptions.filter(function(subscription) {
            if (
              subscription.periodUnit ===
                APP_CONSTANTS.AUTO_RENEWAL_TYPE.MONTHLY &&
              subscription.type === APP_CONSTANTS.SUBSCRIPTION_TYPE.AUTO_RENEWAL
            ) {
              subscription.endDate = $rootScope
                .moment(subscription.startDate)
                .add(1, 'years')
                .format();
            }

          });
          vm.loading.categories = false;
        })
        .catch(function(error) {
          vm.loading.categories = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }


    function displayAddons(subscription) {
      if (!modalInstance) {
        modalInstance = $uibModal.open({
          templateUrl:
            'app/v2/customerAccount/views/partials/displayAddons.html',
          controller: 'DisplayAddonsController',
          controllerAs: 'vm',
          resolve: {
            subscription: function() {
              return subscription;
            }
          }
        });

        modalInstance.result
          .then(function() {
            modalInstance = '';
          })
          .catch(function() {
            modalInstance = '';
          });
      }
    }

    function openDialogBox(subscription) {
      if (!modalInstance) {
        var selectedSubscription = subscription;

        modalInstance = $uibModal.open({
          templateUrl:
            'app/v2/customerAccount/views/partials/updateCustomLabelModal.html',
          controller: 'UpdateCustomLabelController',
          controllerAs: 'vm',
          resolve: {
            subscription: function() {
              return selectedSubscription;
            }
          }
        });

        modalInstance.result
          .then(function() {
            fetchSubscriptions();
            modalInstance = '';
          })
          .catch(function() {
            modalInstance = '';
          });
      }
    }
  }
})();
