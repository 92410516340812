(function() {
  'use strict';

  angular
    .module('app.shared')
    .factory('PublishMajorService', PublishMajorService);

  PublishMajorService.$inject = ['APP_CONSTANTS', 'ApiCaller', '$filter', '$q'];

  function PublishMajorService(APP_CONSTANTS, ApiCaller, $filter, $q) {
    var self = this;

    self.setMajorPublishDate = setMajorPublishDate;

    function setMajorPublishDate(workgroupId, majorId, publishDate) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.setMajorPublishDate,
        APP_CONSTANTS.rootUrl,
        workgroupId,
        majorId
      );

      ApiCaller.apiPut(url, publishDate)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    return self;
  }
})();
