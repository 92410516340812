'use strict';
(function () {

  angular
    .module('careerKeyCentral')
    .factory('APP_CONSTANTS', APP_CONSTANTS);

  APP_CONSTANTS.$inject = ['ENV'];

  function APP_CONSTANTS(ENV) {
    let appConstants = {
      rootUrl: ENV.apiURL,
      hostName: ENV.hostName,
      googleOauthScope: ENV.googleOauthScope,
      googleOauthConsent: ENV.googleOauthConsent,
      googleOauthClientId: ENV.googleOauthClientId,

      login: '%s/v2/auth/login',
      logout: '%s/v2/auth/logout',
      generateAccessToken: '%s/v2/auth/refresh-token',
      linkGoogleAccount: '%s/v2/users/%s/activate-google',
      userSingle: '%s/users/%s',
      // userCollection: '%s/users',
      userSignup: '%s/users/signup',
      userSignupWithACH: '%s/users/signup_with_ach',
      userSignupWithPO: '%s/users/signup_with_po',
      userSignUpWithBillingInvitation: '%s/users/signup_with_billing_invitation',
      userGrant: '%s/users/%s/grant',
      fetchUserGroup: '%s/users/%s/groups',
      userRevoke: '%s/users/%s/revoke',
      // saveActivation: '%s/users/activate', // unused
      userActivate: '%s/v2/users/%s/activate',
      userImageUpload: '%s/users/%s/images',
      userSignUpTrial: '%s/users/signup_trial',
      resetPassword: '%s/users/reset_password',
      changePassword: '%s/users/change_password',
      forgotPassword: '%s/users/forgot_password',
      userActivation: '%s/v2/users/user-activation',
      // activation: '%s/users/%s/activation_details',
      verifyforgotpassword: '%s/users/forgot_password/%s',
      checkEmail: '%s/users/email/exists?email=%s',
      checkGoogleId: '%s/v2/users/google?token=%s',

      sendDemo: '%s/invitations/demos',
      invitationSingle: '%s/invitations/%s',
      invitationCollection: '%s/invitations',
      generateCheckoutPage: '%s/subscriptions/generate_checkout_page',
      uploadTaxDocument: '%s/customers/tax_document',

      DOWNLOAD_W9_FILE: '%s/customers/w9/download',

      customerSingle: '%s/customers/%s',
      customerCollection: '%s/customers?%s',
      customerAllList: '%s/admin/customers',
      mailList: '%s/customers/%s/messages',
      activityLogs: '%s/customers/%s/activitylogs',
      usersByCustomerId: '%s/customers/%s/users',
      mail: '%s/customers/%s/messages/%s?userId=%s',
      customerImageUpload: '%s/customers/%s/images',
      schoolsByCustomerId: '%s/customers/%s/schools',
      paymentDetails: '%s/customers/payment_details',
      discoverersByCustomerId: '%s/customers/%s/discoverers',

      subscriptionCollection: '%s/subscriptions',
      subscription: '%s/subscriptions',
      buySubscription: '%s/subscriptions/buy_subscription',

      transactions: '%s/subscriptions/transactions/%s',

      fetchDiscovererGroup: '%s/events/categories',
      eventCategories: '%s/events/categories?groupId=%s',
      discovererProgress: '%s/events/categories/progress/?%s',

      schoolSingle: '%s/schools?%s',
      schoolCollection: '%s/schools/search?%s',
      // discovererbyKeyCollection: '%s/discoverers/%s/search-discoverers?searchKey=%s',

      messageCollection: '%s/messages',
      sendCdpMessage: '%s/v2/messages/cdp/send-message',

      userNotification: '%s/users/%s/notifications',
      notificationCollection: '%s/notifications',
      notificationSingle: '%s/notifications/%s',
      updateUserNotification: '%s/users/%s/notifications',
      dismissUserNotifictaion: '%s/users/%s/notifications/%s',

      groupSingle: '%s/groups/%s',
      groupCollection: '%s/groups',
      discoverersByGroupId: '%s/groups/%s/discoverers?%s',
      discovererByGroupId: '%s/groups/%s/discoverers/%s',
      discoverersWithoutGroupId: '%s/discoverers/',

      generateDiscovererAPIKey: '%s/discoverers?emailId=%s',
      customerAPIKey: '%s/customers/%s/api_keys',

      // Need to change the key
      deactivateCustomerAPIKey: '%s/customers/%s/api_keys/%s',

      pluginSingle: '%s/v2/sponsored-organizations/%s/widgets/%s',
      pluginCollection: '%s/v2/sponsored-organizations/%s/widgets',
      pluginImage: '%s/v2/sponsored-organizations/%s/widgets/%s/image',

      discovererSingle: '%s/discoverers/%s',
      discoverersDelete: '%s/v2/discoverers',
      discovererCollection: '%s/discoverers?%s',
      discovererExportToCSV: '%s/discoverers/export/csv',
      discovererNote: '%s/discoverers/%s/notes',
      discovererMessage: '%s/discoverers/%s/messages',
      discovererProfilePdf: '%s/v2/discoverers/%s/generateProfilePdf',
      generateCdpProfilePdf: '%s/v2/discoverers/%s/generateCdpProfilePdf',

      // planSingle: '%s/plans/%s',
      planTrial: '%s/plans/%s',

      hostedPage: '%s/subscritions/hosted_page/%s',
      updatePoNumber: '%s/v2/subscriptions/update-po-number',
      updatePaymentMethod: '%s/subscriptions/update_payment_method/%s',

      // resource: '%s/resources?resourceName=%s',

      discovererDetail: '%s/v2/discoverers/%s',
      planCollection: '%s/v2/plans?%s',
      estimateTax: '%s/v2/estimates/tax',
      planFromToken: '%s/v2/plans/token/%s',
      newSubscription: '%s/v2/subscriptions',
      estimateAddonTax: '%s/v2/estimates/addons/tax',
      addNextPlan: '%s/v2/subscriptions/%s/next-plans',
      upgradeFreeTrial: '%s/v2/subscriptions/%s/plans',
      customerPlanCollection: '%s/v2/plans/customers',
      paymentSource: '%s/v2/subscriptions/payment-source',
      accountDetail: '%s/v2/subscriptions/account-details',
      billingDetail: '%s/v2/subscriptions/billing-details',
      billingHistory: '%s/v2/subscriptions/billing-history',
      countries: '%s/v2/countries?countryName=%s',
      countriesByCode: '%s/v2/countries?countryCode=%s',
      statesByCountryCode: '%s/v2/countries/%s/states',
      signUpWithBilling: '%s/v2/users/signup-with-billing',
      signUp: '%s/v2/users/signup',
      addCustomer: '%s/v2/users/addCustomer',
      addReseller: '%s/v2/users/addReseller',
      addB2BUser: '%s/v2/users/addB2BUser',
      paypalPayment: '%s/v2/paypal/payments',
      bulkEmail: '%s/v2/messages/bulk',
      cdpBulkEmail: '%s/v2/messages/cdp/bulk',
      emailContent: '%s/v2/messages/contents?category=%s',
      paypalBillingAgreement: '%s/v2/paypal/billings',

      estimate: '%s/v2/estimates/subscriptions',
      exportSelectedToCSV: '%s/discoverers/export-selected/csv',
      exportAllToCSV: '%s/discoverers/export-all/csv',
      exportSelectedCDPToCSV: '%s/discoverers/export-selected/cdp-csv',
      exportAllCDPToCSV: '%s/discoverers/export-all/cdp-csv',
      customerToken: '%s/v2/customers/%s/token',
      customerAccountInfo: '%s/v2/customers/%s',
      customerInfo: '%s/v2/customers/%s/details',
      customerDetails: '%s/v2/customers/%s/info',
      customerAccountImage: '%s/v2/customers/%s/image',
      accountManagerList: '%s/v2/customers/%s/account-managers',
      organizationToken: '%s/v2/sponsored-organizations/%s/token',
      organizationClusters: '%s/v2/sponsored-organizations/%s/clusters',
      customerPrimaryAccountManager: '%s/v2/customers/%s/primary-contacts',
      customerSubscription: '%s/v2/customers/%s/subscriptions/%s',
      customerBilling: '%s/v2/customers/%s/billing',
      customerPaymentMethod: '%s/v2/customers/%s/payment-method',

      productCollection: '%s/v2/products',
      productSingle: '%s/v2/products/%s',
      productBatches: '%s/v2/products/%s/batches?%s',
      getUniqueMajorId: '%s/v2/products/%s/workgroups/%s/unique-major-id',
      getUniqueCareerId: '%s/v2/products/%s/workgroups/%s/unique-career-id',
      addNewMajor: '%s/v2/products/%s/workgroups/%s/add-new-major',
      addNewCareer: '%s/v2/products/%s/workgroups/%s/add-new-career',
      productRegions: '%s/v2/products/%s/regions',
      productTags: '%s/v2/products/%s/tags',
      regionSingle: '%s/v2/products/%s/regions/%s',
      tagSingle: '%s/v2/products/%s/tags/%s',
      addSurveyVariation: '%s/v2/products/%s/add-survey-variation',
      removeSurveyVariation: '%s/v2/products/%s/remove-survey-variation',
      productWorkgroups: '%s/v2/products/%s/workgroups',
      allAssessments: '%s/v2/assessments',
      workgroupSchools: '%s/v2/workgroups/%s/schools?name=%s',
      workgroupMappedSchools: '%s/v2/workgroups/%s/majors/%s/mapped-schools',
      workgroupMajors: '%s/v2/products/%s/workgroups/%s/majors?searchKey=%s&batchId=%s&tagId=%s&regionId=%s',
      workgroupCareers: '%s/v2/products/%s/workgroups/%s/careers?searchKey=%s&batchId=%s&tagId=%s&regionId=%s',
      searchProductWorkgroups: '%s/v2/products/%s/workgroups/search?%s',
      saveMajorDraft: '%s/v2/workgroups/%s/unpublished-majors/%s',
      saveCareerDraft: '%s/v2/workgroups/%s/unpublished-careers/%s',
      publishMajor: '%s/v2/workgroups/%s/unpublished-majors/%s/published',
      majorPublishStatus: '%s/v2/workgroups/%s/majors/%s/publish-status',
      careerPublishStatus: '%s/v2/workgroups/%s/careers/%s/publish-status',
      publishCareer: '%s/v2/workgroups/%s/unpublished-careers/%s/published',
      setMajorPublishDate: '%s/v2/workgroups/%s/unpublished-majors/%s/publish-date',
      setCareerPublishDate: '%s/v2/workgroups/%s/unpublished-careers/%s/publish-date',
      workgroupUnpublishedMajors: '%s/v2/products/%s/workgroups/%s/unpublished-majors?searchKey=%s&batchId=%s&tagId=%s&regionId=%s',
      workgroupUnpublishedCareers: '%s/v2/products/%s/workgroups/%s/unpublished-careers?searchKey=%s&batchId=%s&tagId=%s&regionId=%s',
      revertUnpublishedMajors: '%s/v2/workgroups/%s/unpublished-majors/%s/revert',
      revertUnpublishedCareers: '%s/v2/workgroups/%s/unpublished-careers/%s/revert',
      addWorkgroupUnpublishedMajors: '%s/v2/workgroups/%s/majors/%s/add-to-unpublish',
      addWorkgroupUnpublishedCareers: '%s/v2/workgroups/%s/careers/%s/add-to-unpublish',
      workgroupMajorsLanguageVariation: '%s/v2/workgroups/%s/majors/%s/language-variations',
      workgroupCareersLanguageVariation: '%s/v2/workgroups/%s/careers/%s/language-variations',
      schoolList: '%s/v2/schools?%s',
      batchCollection: '%s/v2/products/%s/batches/%s',
      majorsByCipIds: '%s/v2/schools/%s/majors',
      subscriptionList: '%s/v2/subscriptions/list',
      invoiceLink: '%s/v2/invoices/%s/pdf',
      creditNoteLink: '%s/v2/credit-notes/%s/pdf',
      clusterSets: '%s/v2/sponsored-organizations/%s/cluster-sets',
      sponsoredOrgStatus: '%s/v2/sponsored-organizations/%s/status',
      availableWidgetLanguages: '%s/customers/%s/available-languages?type=%s',
      sponsoredOrganizationSingle: '%s/v2/customers/%s/sponsored-organization',
      sponsoredOrganizationInfo: '%s/v2/customers/%s/sponsored-organizations/info',
      sponsoredOrgCkdWidgetStatus: '%s/v2/sponsored-organizations/%s/widgetAccess',
      sponsoredOrganizationCollection: '%s/v2/customers/%s/sponsored-organizations',
      sponsoredOrganizationUserInvitation: '%s/v2/invitations/%s',
      sponsoredOrganizationImage: '%s/v2/sponsored-organizations/%s/image',
      sponsoredOrganizationStatus: '%s/v2/sponsored-organizations/%s/status',
      sponsoredOrganizationDetails: '%s/v2/sponsored-organizations/%s/details',
      organizationPrimaryAccountManager: '%s/v2/sponsored-organizations/%s/primary-contacts',
      accountAndOrganizationManagerList: '%s/v2/sponsored-organizations/%s/organization-managers',
      searchOrganizationDiscoverers: '%s/v2/sponsored-organizations/%s/discoverers?searchKey=%s&searchByCategory=%s',

      activeSubscriptionList: '%s/v2/subscriptions/active',
      cancelSubscriptions: '%s/v2/subscriptions/deactivate',
      pendingSubscriptionList: '%s/v2/subscriptions/pending',
      discoverersCount: '%s/v2/subscriptions/discoverers-count',
      customLabelAndPO: '%s/v2/subscriptions/%s/update-subscription',

      addons: '%s/v2/addons?planId=%s',
      userEmail: '%s/v2/users/email?email=%s',
      applyAddons: '%s/v2/subscriptions/addons',

      clusters: '%s/v2/products/%s/clusters',

      getAllDiscoverers: '%s/v2/discoverers?%s',
      cdpDiscovererDetail: '%s/v2/discoverers/%s/cdp',
      shareCDPResult: '%s/v2/discoverers/%s/share-cdp-result',
      shareCKDResult: '%s/v2/discoverers/%s/share-ckd-result',

      unAuthorized: 401,
      successStatusCode: 200,
      notFoundStatusCode: 501,
      successfullCreatedStatusCode: 201,
      scheduledcancellation: 'Scheduled Cancelled',

      defaultPassword: "1234567",
      decimalFixedValue: 2,
      defaultBillingCycle: 1,

      Middle: 'Middle',
      Higher: 'Higher',
      Medium: 'Medium',
      trialMedium: 'middle_trial',
      trialHigher: 'higher_trial',
      ARHeadersDescriptors: 'ARHeadersDescriptors',
      export_to_csv: 'Export to CSV',

      dashboardList: ['app.ownerdashboard', 'app.counselordashboard', 'app.stakeholderdashboard', 'app.billingdashboard', 'app.technicaldashboard', 'app.careerdecisionprofileclient'],
      publiclyAccessibleRoutes: ['register', 'purchase', 'trial', 'billinginvitation', 'review_order', 'registrationcomplete', 'payment', 'purchaseorder', 'achpayment', 'buysubscription', 'activateRegistration'],

      letsConnect: '%s > Let’s Connect - %s',
      customerDashboard: '%s > Dashboard - %s',
      createAndManageGroupBoard: '%s > Create & Manage Groups - %s',
      discovererProgressBoard: '%s > Discoverers\' Progress - %s',
      careerDecisionProfileClients: '%s > CDP Clients - %s',

      Continue: 'Continue',
      errorHeader: 'Error!',
      addToCart: 'Add to Cart',
      successHeader: 'Thank You!',
      registerSuccessMessage: "We successfully processed your order. Your confirmation email is on it's way and includes a receipt and link to sign in and get started. Welcome to Career Key Discovery!",
      registrationSuccessWithPayment: 'The billing contact you invited will receive an email with sign in instructions. Once logged in, the billing contact can complete your order so you can get started. Welcome to Career Key!',
      registrationSuccessWithBillingInvitatioin: 'We successfully processed your order! You and invited billing contact will receive a confirmation email shortly with a link to log in and get started. Welcome to CareerKey !',

      noAvailablePlans: 'Looks like there aren\'t any plans you can buy at the moment.',
      emptyPaymentDetail: 'To purchase a paid subscription, please enter a payment method.',
      emptyBillingDetail: 'To purchase a paid subscription, please enter your billing information.',
      noPaymentOrBillingDetail: 'Looks like you don\'t have either your payment or billing detail set. Update them to be able to buy a subscription.',

      achAgreementMessage: '<p>I authorize Career Key, Inc. (Career Key) to electronically debit my account for the amount(s) selected above and, if necessary, electronically credit my account to correct erroneous debits. These debits or credits will occur on or after today\’s date, when Career Key activates your Career Key Central account.</p><p>I will <strong>provide a minimum of 15 days written notice</strong> (via email, see below), prior to my next scheduled debit/payment, to cancel this subscription and revoke this authorization. If I fail to notify Career Key within that time limit, my account will be debited one additional time before cancellation takes effect and that payment is non-refundable.</p><p>It is my responsibility to bring any billing discrepancies to Career Key’s attention within 60 days after my financial institution processes it. After 60 days, I waive my right to dispute such discrepancies.</p><p> This authorization will remain in effect until I revoke it by contacting Career Key at support@careerkey.org or by calling (206) 850-6195.</p>',

      addAddonsMessageTop: 'Choose to add on one or more blocks of discoverers at the same price per discoverer as your Prepaid subscription. Adding new discoverers takes place immediately after purchase, and they expire at the end of your subscription. ',
      addAddonsMessageBottom: 'When it\'s time to renew, consider choosing one of our convenient, cost-effective Auto Renewal plans that automatically expand your discoverer volume, at a lower price per discoverer. <a href="http://resources.careerkeydiscovery.com/articles/billing">Learn more</a>',
      verifyAccountMessage: 'You are ready to verify when you see two micro-deposits from us in your bank account, which may take 24-72 hours after you add the account, depending on your bank. Next, email support@careerkey.org with the subject. Verify Bank Account and tell us:<br><br> 1. Your organization name, and,<br> 2. The amounts of the two micro-deposits we made into your bank account.<br><br> Then, we’ll do the rest! We complete the verification process for you and accept payment for any invoice that is due (if any).',

      updateToPOMessage: 'This is a fancy way to say, “pay by check.”  When you click on "Confirm" below, we email you an invoice, also known as a Pro Forma Invoice or quote.<br/>You send us a check for that amount and once it clears our bank, we make your subscription active.',

      paymentInformation: 'This is a fancy way to say, “pay by check.” When you click on “Confirm My Order” below to complete your purchase, we email you an invoice, also known as a Pro Forma invoice or quote.',
      checkRequest: 'Next, send us a check for that amount within 30 days. Once we receive it and it clears our bank, we email you a receipt and make your subscription active.',

      ckdInstallationHeader: 'Instructions for installing CKD Widget',
      ckdInstallationBody: 'In order for your discoverers to access Career Key Discovery (CKD), you need to host the CKD plug-in on a single page application comprised of just one html page. Once you complete installation and you link visitors to that page, discoverers will be able to access CKD, and their assessment results and data will appear here in the customer action portal, Career Key Central. That’s where your organization’s administrators and counselors will be able to view discoverers’ data and engage with them. <br><br>1. First, make sure you or a colleague configures CKD’s assessment display in Technical page (Click on the gear icon<i class="fa fa-wrench"></i>) so that it shows the desired options to discoverers. (i.e. careers, programs, locale). <br>2. Generate your Access API Key and plug-in code.<br>3. Install the plug-in and test it. <br><br>For more details, read Resources > Set Up My Organization > How to Install CKD Plug-In.',

      defaultOrganizationImage: 'app/common/img/organization.png',

      roleList: [
        // { role: 'owner', state: 'app.ownerdashboard', priority: 2 },
        { role: 'accountManager', state: 'app.ownerdashboard', priority: 2 },
        { role: 'organizationManager', state: 'app.ownerdashboard', priority: 3 },
        { role: 'ckadmin', state: 'app.ckadmin.products', priority: 1 },
        { role: 'billing', state: 'app.billingdashboard', priority: 7 },
        { role: 'counselor', state: 'app.counselordashboard', priority: 4 },
        { role: 'groupCounselor', state: 'app.discoverersprogress', priority: 5 },
        { role: 'technical', state: 'app.technicaldashboard', priority: 8 },
        { role: 'stakeholder', state: 'app.stakeholderdashboard', priority: 6 }
      ],

      widthWithPO: '25%',
      widthWithoutPO: '33.33333%',

      topNavBarMenuItems: {
        signOut: 'Sign Out',
        changePassword: 'Change Password',
        personalProfile: 'Personal Profile',
        customerAccount: 'Account Administration',
        organizationInfo: 'Organization Info',
        sponsoredOrganization: 'My Organization'
      },

      searchSchool: {
        zip: 'ZIP',
        name: 'NAME'
      },

      // Locales
      availableLocales: [
        { name: 'Canada', id: 'en-ca' },
        { name: 'United States', id: 'en-us' }
      ],
      availableCountries: [
        { name: 'United States', id: 'US' },
        { name: 'Canada', id: 'CA' }
      ],
      availableLanguages: [
        { name: 'English', id: 'en' }
      ],
      productLocales: [
        { name: 'English - US', id: 'en-US', currency: 'USD' },
        { name: 'English - CA', id: 'en-CA', currency: 'CAD' }
      ],

      invitationRoleList: [{
        id: 0,
        role: 'accountManager',
        label: 'Account Manager',
        value: 'Account Manager: Full access, including billing'
      },
      {
        id: 1,
        role: 'organizationManager',
        label: 'Organization Manager',
        value: 'Organization Manager: Full access, no billing access'
      },
      {
        id: 2,
        role: 'counselor',
        label: 'Counselor',
        value: 'Counselor: Full access, unassigned and all groups'
      }, 
      {
        id: 3,
        role: 'groupCounselor',
        label: 'Group Counselor',
        value: 'Group Counselor: Access only to assigned groups'
      },
      {
        id: 4,
        role: 'stakeholder',
        label: 'Stakeholder',
        value: "Stakeholder: View discoverers' information only"
      }, {
        id: 5,
        role: 'billing',
        label: 'Billing Contact',
        value: 'Billing Contact: Payment, billing access only'
      }, {
        id: 6,
        role: 'technical',
        label: 'Technical Contact',
        value: 'Technical Contact: Sets up your web page that hosts and displays assessment'
      }],

      discoverers_progress: [
        {
          key: 'All',
          value: 'All'
        }, {
          key: 'not_started',
          value: 'Not Started',
          template: 'INVITE TO START CKD'
        }, {
          key: 'not_completed',
          value: 'Assessment Not Completed',
          template: 'REMIND TO COMPLETE CKD'
        }, {
          key: 'completed_assessment',
          value: 'Completed Assessment',
          template: 'PROMPT TO ADD OPTIONS TO PROFILE'
        }, {
          key: 'started_profile',
          value: 'Started Profile',
          template: 'KUDOS FOR PROGRESS'
        }, {
          key: 'returned_to_profile',
          value: 'Returned To Profile',
          template: 'OFFER SUPPORT'
        }],

      EVENT_CATEGORY: {
        NOT_STARTED: 'not_started',
        NOT_COMPLETED: 'not_completed',
        INVITE_TO_START: 'invite_to_start',
        STARTED_PROFILE: 'started_profile',
        RETURNED_TO_PROFILE: 'returned_to_profile',
        COMPLETED_ASSESSMENT: 'completed_assessment',
      },

      discoverers_riasec: [
        {
          key: 'All',
          value: 'All'
        }, {
          key: 'R',
          value: 'R'
        }, {
          key: 'I',
          value: 'I'
        }, {
          key: 'A',
          value: 'A'
        }, {
          key: 'S',
          value: 'S'
        }, {
          key: 'E',
          value: 'E'
        }, {
          key: 'C',
          value: 'C'
        }],
      pageSizeOptions: [
        {
          key: '5',
          value: 5
        }, {
          key: '10',
          value: 10
        }, {
          key: '25',
          value: 25
        }, {
          key: '50',
          value: 50
        }, {
          key: '100',
          value: 100
        }, {
          key: '200',
          value: 200
        }
        // TODO: May required in future
        // , {
        //   key: 'All',
        //   value: 'All'
        // }
      ],

      OWNER: 'owner',
      CKADMIN: 'ckadmin',
      BILLING: 'billing',
      TECHNICAL: 'technical',
      COUNSELOR: 'counselor',
      STAKEHOLDER: 'stakeholder',
      ACCOUNT_MANAGER: 'accountManager',
      ORGANIZATION_MANAGER: 'organizationManager',
      GROUP_COUNSELOR: 'groupCounselor',
      NOTIFICATION: {
        productUpTime: {
          scheduledMaintenance: 'Please note Career Key Central will be temporarily unavailable between 00:00 a.m./p.m. Pacific Time for scheduled maintenance. Thanks for your patience!',
          someFunctionDisbale: 'Some functions in Career Key Central may be temporarily disabled. We’re working on them and will tell you when we’re done.',
          FinishedUpdated: 'Career Key Central is now working 100% … we finished our updates. '
        },
        productUsage: {
          prepaid: {
            subscriptionLimitAll: 'Congratulations! You’ve reached your subscription’s limit of discoverers. Go to your Account and renew now to add more!',
            subscriptionLimitToStakeholder: 'Congratulations! Your organization reached its limit of discoverers. We’ve notified your Career Key Central administrator.'
          },
          autoRenewal: {
            subscriptionLimit: 'Congratulations! You’ve surpassed your subscription’s limit of discoverers, unlocking even more capacity. Your account has been adjusted to the next billing tier. View in Your Account',
            surpassSubscriptionLimit: 'Congratulations! You’re just about to surpass your subscription’s discoverer limit. When that happens, your account will adjust to the next billing tier, unlocking more capacity.  View In Your Account'
          }
        },
        productActivation: {
          inviteTechnical: 'Welcome! Now go to Organization Settings and invite your Technical Contact to finish set up and access to Career Key Discovery. Need help? Visit Support>Set Up. ',
          remindTechnical: 'Almost done! Discoverers will soon be able to access Career Key Discovery. We’re just waiting for your Technical Contact to get your site ready.  Want to remind them? Use Let’s Connect.',
          remindBilling: 'Almost done! Discoverers will soon be able to access Career Key Discovery. We’re just waiting for your payment to arrive. Want to remind Billing? Use Let’s Connect. ',
          hostCareerKeyDiscoverer: 'Welcome! Now create a web page to host Career Key Discovery and start student or client access to it. View Technical Support>Set Up.',
          viewDiscovererStepByStep: 'Congratulations! Career Key Discovery is now ready for new discoverers! For tips to invite and engage them, view Discovery Step-by-Step>Getting Started. ',
          trialExpored: 'Sorry! Your free trial has expired. To continue using Career Key Discovery and maintain access to your data, buy a subscription now. View in Account.'
        }
      },
      ACCESS: {
        GRANT: 'grant',
        REVOKE: 'revoke'
      },
      MESSAGE: {
        notSignedIn: 'Not signed in',
        noteSave: 'Successfully saved.',
        imageSizeLarge: 'Image too large',
        validUrl: 'Please enter a valid url',
        notAssigned: 'Audience not assigned',
        productAdded: 'Product added successfully',
        messageSent: 'Message sent successfully.',
        successfullyUpdated: 'Successfully updated.',
        messageEmailValidation: 'Email is required.',
        majorReverted: "Successfully reverted majors",
        addonsApplyFailure: 'Could not apply addons.',
        onSuccess: 'Operation successfully completed.',
        PLUGIN_UPDATED: 'Plugin updated successfully!',
        resetPassword: 'Password changed successfully.',
        dataNotFound: 'No data found in our database',
        emailNotFound: 'No email found in our database',
        planSelectionValidation: 'Please select a plan',
        careerReverted: "Successfully reverted careers",
        downloadSuccessfull: 'Successfully downloaded.',
        batchUploadSuccess: '%s uploaded successfully',
        updateCustomLabel: 'Update custom label',
        addonsApplySuccess: 'Successfully applied addons.',
        promotionCodeFailure: 'Could not %s promotion code',
        optionSelectionValidation: 'Please select an option',
        promotionCodeSuccess: 'Successfuly %s promotion code',
        deletedDiscoverer: 'Successfully deleted discoverer.',
        deletedDiscoverers: 'Successfully deleted discoverers.',
        unAuthorizedRole: 'Sorry! This role not yet available.',
        discovererAssigned: 'Discoverer successfully assigned!',
        discoverersAssigned: 'Discoverers successfully assigned!',
        nextPlanAddedSuccesfully: 'Next plan is set successfully',
        noChangesToRevert: 'Sorry, there are no changes to revert.',
        majorsMappedForSchools: 'Instructional programs mapped successfully.',
        noDiscovererFound: 'No discoverers found for this category.',
        regionSuccessfullyAdded: 'Region added successfully',
        tagSuccessfullyAdded: 'Tag added successfully',
        regionSuccessfullyEdited: 'Region updated successfully.',
        tagSuccessfullyEdited: 'Tag updated successfully.',
        assessmentSuccessfullyAdded: 'Assessment added successfully.',
        assessmentSuccessfullyRemoved: 'Assessment removed successfully.',
        majorSuccessfullyAdded: 'Instructional Program successfully added to unpublished.',
        careerSuccessfullyAdded: 'Occupation successfully added to unpublished',
        noGroupAssigned: 'You are not assigned to any of the groups.',
        INVALID_USER_INFORMATION: 'Please enter valid user information',
        csvDownloadInProgress: 'Please wait, the data is being exported',
        groupSuccessfullyCreated: 'Group has been created successfully.',
        groupSuccessfullydeleted: 'Group has been deleted successfully.',
        groupNameExists: 'The group name already exists. Please try again with another name.',
        oldNewPasswordValidation: 'Old and new password cannot be same.',
        technicalApiKeyAddedSuccess: 'API key has been added successfully!',
        discovererRequired: 'Please invite discoverers or upload csv file.',
        csvBeingDownload: 'Your data is being exported now, please wait...',
        oneYearOnly: 'End date should not be more than a year to start date',
        startDateGreaterThanEndDate: 'Start date must be less than end date',
        makeChangeToSaveDraft: 'Please make some changes to save the draft.',
        makeChangeToPublishMajor: 'Please make some changes to publish this major.',
        makeChangeToPublishCareer: 'Please make some changes to publish this career.',
        majorUpdatedToUnpublished: 'Instructional Program(Unpublished) successfully updated.',
        majorAddedToUnpublished: 'Instructional Program is successfuly added to unpublished.',
        majorSuccessfullyPublished: 'Instructional Program(Unpublished) is successfully published.',
        careerSuccessfullyPublished: 'Occupation is successfully published.',
        majorPublishDateSuccessfullySet: 'Publish date is set successfully for this instructional program.',
        careerPublishDateSuccessfullySet: 'Publish date is set successfully for this occupation.',
        accountInfoUpdateSuccess: 'Successfully updated account information.',
        searchSchoolFieldValidation: 'At least one search field is required.',
        discoverersSelectValidation: 'Please select at least one discoverer.',
        subscriptionCreatedSuccessfully: 'Subscription created successfully.',
        successfullyRevertChanges: 'Successfully reverted all recent changes.',
        INVALID_BILLING_INFORMATION: 'Please enter valid billing information.',
        accountInfoUpdateSuccesss: 'Successfully updated account information.',
        INVALID_ACCOUNT_INFORMATION: 'Please enter valid account information.',
        INVALID_SUBSCRIPTION: 'Please enter valid subscription.',
        technicalApiKeyUpdateSuccess: 'API key has been updated successfully!',
        careerUpdatedToUnpublished: "Occupation(Unpublished) successfuly updated.",
        careerAddedToUnpublished: "Occupation is successfuly added to unpublished.",
        subscriptionUpgradedSuccessfully: 'Subscription upgraded successfully.',
        initializeForgotPasswordRequest: 'Initiating password reset request...',
        billingContactUpdateSuccessful: 'Billing contact updated successfully.',
        selectAutorenwalSubscription: 'Please select a autorenewal subscription',
        demoMessage: 'Sucessfully Processed the request. Please check your email.',
        subscriptionCancellationFailure: 'Sorry. Could not cancel the subscription',
        notThreeYearBefore: 'Start date should be within 3 years from current date',
        subscriptionCancellationSuccess: 'Successfully cancelled the subscription.',
        noApiKeyGenerated: "Api Key hasn't been generated in this organization yet.",
        noActiveApiKey: 'There is no active api key currently in this organization.',
        accountInfoUpdateFailed: 'Sorry, could not update your account information.',
        successfullyCreatedSponoredOrg: 'Sponsored organization created successfully.',
        organizationInfoUpdateSuccess: 'Successfully updated organization information.',
        organizationInfoUpdateFailed: 'Sorry, could not update your organization information.',
        activateUser: 'Your account has been activated successfully. Please sign in to get started.',
        documentUploadFailed: 'Sorry! There was an error while uploading document. Please try again.',
        csvExportedThroughMail: "We're processing your export and we will email you the download link shortly.",
        primaryContactChangedSuccessfully: 'Successfully changed the primary contact of your organization.',
        discoverersEventTypeSelectValidation: 'The discoverer progress does not match the message option you choose.',
        addUserSuccess: 'User added successfully.',
        inviteUser: 'We successfully sent your invite to this user. When they accept, you will see them in the list below.',
        batchUploadSuccessWithNonExistingMajors: '%s uploaded successfully. The majors with the ids %s were not mapped.',
        batchUploadSuccessWithNonExistingCareers: '%s uploaded successfully. The careers with the ids %s were not mapped.',
        batchUploadSuccessWithNonExistingSchools: '%s uploaded successfully. The schools with the ids %s were not mapped.',
        firstTimeLogin: 'Welcome!, Go to Support > Set Up for next steps to using Career Key Discovery with students or clients.',
        forgotPassword: "You'll receive an email with a link to reset you password. Not seeing the email? Please check your spam folder.",
        technicalConfirmMessage: 'After creating a plug in with an existing url, access to previous plug in will be deactivated. Please confirm?',
        batchUploadSuccessWithNonExistingCareersAndMajors: '%s uploaded successfully. The careers with the ids %s and majors with the ids %s were not mapped.',
        batchUploadSuccessWithNonExistingSchoolsAndMajors: '%s uploaded successfully. The schools with the ids %s and majors with the ids %s were not mapped.',
        technicalDeactivateConfirm: 'If you deactivate this plug in, users will no longer be able to access Career Key Discovery from this URL. To avoid disruption to past users, you MUST put in a redirect to a new plug in URL. Do you still want to proceed?',
        technicalReactivateConfirm: 'After reactivating this plug in, users will be able to access Career Key Discovery from this URL. Please confirm?',
        welcomeMessage: 'We successfully processed your order! You will receive a confirmation email shortly with your receipt and link to log in and get started. Welcome to Career Key Discovery!'
      },

      CKD_TECHNICAL_PAGE_HEADING: '%s\'s CKD Plug Ins',
      CDP_TECHNICAL_PAGE_HEADING: '%s\'s CDP Plug Ins',
      CK_TECHNICAL_PAGE_HEADING: '%s\'s CK Plug Ins',

      PAYMENT_TYPE: {
        ALL: 'All',
        DUE: 'Due',
        REFUND: 'Refund',
        PAYMENT: 'Payment'
      },

      INSTRUCTIONAL_PROGRAM: {
        'en-US': 'Major',
        'en-CB': 'Major',
        'en-CA': 'Program'
      },

      CKD_MENU:{
        OWNER_DASHBOARD: {
          "text": "Organization: Dashboard",
          "icon": "icon-speedometer",
          "sref": "app.ownerdashboard"
        },
        DISCOVERY_PROGRESS:{
          "text": "Discoverers & Progress",
          "sref": "app.discoverersprogress",
          "icon": "icon-magnifier",
        }
      },

      CDP_MENU:{
        CAREER_DECISION_PROFILE_CLIENT: {
          "text": "Career Decision Profile Clients",
          "icon": "icon-magnifier",
          "sref": "app.careerdecisionprofileclient"
        }
      },

      PAYMENT_METHOD: {
        CARD: 'card',
        PAYPAL: 'paypal',
        CREDIT_CARD: 'creditCard',
        DIRECT_DEBIT: 'direct_debit',
        BANK_TRANSFER: 'bankTransfer',
        PURCHASE_ORDER: 'purchaseOrder',
        PAYPAL_EXPRESS_CHECKOUT: 'paypal_express_checkout'
      },

      DISCOVERERS_PROGRESS: {
        INVITE_TO_START: 'invite_to_start',
        NOT_STARTED: 'not_started',
        COMPLETED_ASSESSMENT: 'completed_assessment',
        STARTED_PROFILE: 'started_profile',
        RETURNED_TO_PROFILE: 'returned_to_profile'
      },

      SUBSCRIPTION_MESSAGE: {
        PREPAID: 'Prepay for a set quantity of assessments at a per-user price. When you reach your user limit, new users gain access only after you buy more. Unused new user slots expire after 2 years.',
        AUTO_RENEWAL: 'Start at a base quantity you select for your expected user volume during the 1-year subscription. If during that time your usage exceeds this initial quantity, we automatically move you to the next volume tier and bill you accordingly. Grow as you go and never turn away users.'
      },

      PAYMENT_STATUS: {
        NOT_VERIFIED: 'not verified'
      },

      PAYMENT_PERIOD: {
        TRIAL: 'Trial',
        PREPAID: 'One-Time Payment',
        AUTO_RENEWAL_ANNUAL: 'Annual Payment',
        AUTO_RENEWAL_MONTHLY: 'Monthly Payment'
      },

      AUTOPAY_STATUS: {
        NO: 'N',
        YES: 'Y'
      },

      AUTO_COLLECTION_STATUS: {
        ON: 'on',
        OFF: 'off'
      },

      STRIPE: {
        PUBLISHABLE_KEY: ENV.stripePublishableKey
      },

      PAYPAL:{
        ENVIRONMENT: ENV.paypalEnv || 'sandbox'
      },

      MONTHS: [
        { name: 'January', value: 01 },
        { name: 'February', value: 02 },
        { name: 'March', value: 03 },
        { name: 'April', value: 04 },
        { name: 'May', value: 05 },
        { name: 'June', value: 06 },
        { name: 'July', value: 07 },
        { name: 'August', value: 08 },
        { name: 'September', value: 09 },
        { name: 'October', value: 10 },
        { name: 'November', value: 11 },
        { name: 'December', value: 12 }
      ],

      REGISTER_TYPE: {
        TRIAL: 'trial',
        PAYPAL: 'paypal',
        PURCHASE: 'purchase',
        CREDIT_CARD: 'creditCard',
        BANK_TRANSFER: 'bankTransfer',
        PURCHASE_ORDER: 'purchaseOrder',
        BILLING_CONTACT: 'billingContact'
      },

      SUBSCRIPTION_STATUS: {
        ACTIVE: 'active',
        PENDING: 'pending',
        EXPIRED: 'expired',
        INACTIVE: 'inactive',
        COMPLETED: 'completed',
        CANCELLED: 'cancelled',
        SUSPENDED: 'suspended',
        SCHEDULE_TO_CANCEL: 'scheduled cancellation'
      },

      SUBSCRIPTION_DURATIONS: [
        { name: '1 Year', value: 1 },
        { name: '2 Years', value: 2 },
      ],

      REGISTRATION_BLOCK: {
        PURCHASE: [
          {
            id: 'accountInformation',
            label: 'Account Information'
          },
          {
            id: 'billingInformation',
            label: 'Billing Information'
          },
          {
            id: 'paymentInformation',
            label: 'Payment Information'
          },
          {
            id: 'confirmation',
            label: 'Confirmation'
          }
        ],
        BILLING: [
          {
            id: 'accountInformation',
            label: 'Account Information'
          },
          {
            id: 'billingContactInformation',
            label: 'Billing Information'
          },
          {
            id: 'confirmation',
            label: 'Confirmation'
          }
        ],
        TRIAL: [
          {
            id: 'accountInformation',
            label: 'Account Information',
          },
          {
            id: 'confirmation',
            label: 'Confirmation'
          }
        ],
        SUBSCRIPTION: [
          {
            id: 'subscription',
            label: 'Select Subscription'
          }
        ]
      },

      ADD_CUSTOMER_BLOCK: [
        {
          id: 'subscriptionInformation',
          label: 'Subscription Information'
        },
        {
          id: 'accountInformation',
          label: 'Account Information'
        },
        {
          id: 'billingInformation',
          label: 'Billing Information'
        },
        {
          id: 'paymentInformation',
          label: 'Payment Information'
        },
        {
          id: 'confirmation',
          label: 'Confirmation'
        }
      ],

      LABEL: {
        CONFIRMATION: 'Confirmation',
        ADD_NEW_SUBSCRIPTION: 'Add Subscription',
        UPGRADE_FREE_TRIAL: 'Upgrade Free Trial',
        PAYMENT_INFORMTION: 'Payment Information',
        ACCOUNT_INFORMATION: 'Account Information',
        BILLING_INFORMATION: 'Billing Information',
        SELECT_SUBSCRIPTION: 'Select Subscription',
        ADD_SUBSCRIPTION: 'Add Subscription'
      },

      GROUP_FILTER: [
        {
          id: '',
          name: 'All'
        },
        {
          id: 'unassign',
          name: 'Unassigned'
        }
      ],

      SERVICE_LEVEL: [
        {
          key: 'All',
          value: 'All',
        },
        {
          key: 'Self-help',
          value: 'Self-help',
          template: 'SELF HELP',
          SCORE_PATTERN: [
            {
              key: 'Differentiated - consistent',
              value: 'Differentiated - consistent',
            }
          ],
        },
        {
          key: 'Brief staff-assisted',
          value: 'Brief Staff-Assisted',
          template: 'BRIEF STAFF ASSIST',
          SCORE_PATTERN: [
            {
              key: 'Differentiated - inconsistent',
              value: 'Differentiated - inconsistent',
            },
            {
              key: 'Undifferentiated - high flat',
              value: 'Undifferentiated - high flat',
            }
          ],
        },
        {
          key: 'Individual, case-managed services',
          value: 'Individual Case-Managed',
          template: 'INDIVIDUAL CASE MANAGED',
          SCORE_PATTERN: [
            {
              key: 'Undifferentiated - low flat',
              value: 'Undifferentiated - low flat',
            }
          ],
        }
      ],

      PROGRESS_LEVEL: [
        {
          key:'',
          value: 'All'
        },
        {
          key: 0,
          value: 'Not Completed'
        },
        {
          key: 1,
          value: 'Pre Intervention'
        },
        {
          key: 2,
          value: 'Post Intervention'
        }
      ],

      SCALE_LEVEL: [
        {
          key: 6,
          value: 'Capacity for Progress'
        },
        {
          key: 2,
          value: 'Self-Clarity'
        },
        {
          key: 3,
          value: 'Knowledge of Careers/Education'
        },
        {
          key: 4,
          value: 'Decisiveness'
        },
        {
          key: 5,
          value: 'Career Choice Importance'
        }
      ],

      SCORE_LEVEL: [
        {
          key: 'low',
          value: 'Low'
        },
        {
          key: 'moderate',
          value: 'Moderate'
        },
        {
          key: 'high',
          value: 'High'
        }
      ],

      SELF_HELP: 'Self-help',
      BRIEF_STAFF_ASSISTED: 'Brief staff-assisted',
      INDIVIDUAL_CASE_MANAGED_SERVICES: 'Individual, case-managed services',

      DIFFERENTIATED_CONSISTENT: 'Differentiated - consistent',
      DIFFERENTIATED_INCONSISTENT: 'Differentiated - inconsistent',
      UNDIFFERENTIATED: 'Undifferentiated',
      UNDIFFERENTIATED_HIGH_FLAT: 'Undifferentiated - high flat',
      UNDIFFERENTIATED_LOW_FLAT: 'Undifferentiated - low flat',

      TAXABILITY: {
        TAXABLE: 'taxable',
        EXEMPT: 'exempt'
      },

      CONFIRMATION: {
        GRANT_REVOKE_ACCESS_USER: 'Are you sure you want to %s the access to the user %s?',
        GRANT_REVOKE_ACCESS_ORGANIZATION: 'Are you sure you want to %s the access to the organization %s?',
        CHANGE_PRIMARY_CONTACT: 'Are you sure you want to make %s the primary contact for your organization?',
        GRANT_REVOKE_ACCESS_CKD_WIDGET: 'Are you sure you want to %s CKD Plugin Access for the organization %s?'
      },

      REGEX: {
        PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\/|\-`]).{8,20}$/,
        EMAIL: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^><>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      },

      SUBSCRIPTION_TYPE: {
        TRIAL: 'trial',
        PREPAID: 'prepaid',
        AUTO_RENEWAL: 'autorenewal',
      },

      AUDIENCE: {
        ADULT: 'adult',
        HIGHER: 'higher',
        SECONDARY: 'secondary'
      },

      CUSTOMER_TYPE: {
        DIRECT: 'direct',
        ENTERPRISE: 'enterprise'
      },

      AUTO_RENEWAL_TYPE: {
        YEARLY: 'year',
        MONTHLY: 'month'
      },

      EXTERNAL_URL: {
        TERMS: 'https://www.careerkey.org/discovery/legal/terms.html',
        PRIVACY: 'https://www.careerkey.org/discovery/legal/privacy.html',
        CK_W9: 'https://ckaccountimages.blob.core.windows.net/ck-files/Career_Key_W9_2017.pdf'
      },

      GRACE_PERIOD: {
        DAYS: 60
      },

      PERSONALITY_TYPES: {
        REALISTIC: 'Realistic',
        INVESTIGATIVE: 'Investigative',
        ARTISTIC: 'Artistic',
        SOCIAL: 'Social',
        ENTERPRISING: 'Enterprising',
        CONVENTIONAL: 'Conventional'
      },

      ORGANIZATION_TAB_INDEX: {
        'app.sponsoredOrg.OrgInfo': 0,
        'app.sponsoredOrg.userManagement': 2,
        'app.sponsoredOrg.technicalDashboard': 1
      },

      CKADMIN_TAB_INDEX: {
        'app.ckadmin.products': 0,
        'app.ckadmin.customers': 2,
        'app.ckadmin.assessments': 1,
        'app.ckadmin.resellers': 3,
        'app.ckadmin.discoverers': 4
      },

      PRODUCT_TAB_INDEX: {
        'app.ckadmin.selectedProduct.details': 0,
        'app.ckadmin.selectedProduct.ips': 1,
        'app.ckadmin.selectedProduct.occupations': 2,
        'app.ckadmin.selectedProduct.schools': 3,
        'app.ckadmin.selectedProduct.batches': 4,
      },

      CUSTOMER_ACCOUNT_TAB_INDEX: {
        'app.customerAcc.billing': 2,
        'app.customerAcc.accountInfo': 0,
        'app.customerAcc.subscription': 1,
        'app.customerAcc.organizations': 3,
        'app.customerAcc.billingHistory': 2
      },

      collegeMajor: {
        ALL: 0,
        MY_INSTITUTION_OFFER: 1,
        CUSTOMIZED_CAREERS_MAJORS: 2
      },

      occupation: 'Occupation',
      instructionalProgram: 'Instructional Program',

      majors: 'majors',
      careers: 'careers',

      productPublishOptions: [
        {
          id: 1,
          name: 'Published'
        },
        {
          id: 2,
          name: 'Unpublished'
        }
      ],

      CKADMIN_TABS: {
        PRODUCTS: 'products',
        ASSESSMENTS: 'assessments',
        CUSTOMERS: 'customers',
        RESELLERS: 'resellers',
        DISCOVERERS: 'discoverers'
      },

      batchTypeOptions: [
        {
          id: 1,
          name: 'Workgroups',
          identifier: 'workgroups',
          showPublishNow: false
        },
        {
          id: 2,
          name: 'Instructional Programs',
          identifier: 'majors',
          showPublishNow: true
        },
        {
          id: 3,
          name: 'Occupations',
          identifier: 'careers',
          showPublishNow: true
        },
        {
          id: 4,
          name: 'Clusters',
          identifier: 'clusters',
          showPublishNow: false
        },
        {
          id: 5,
          name: 'Map Instructional Programs for Occupations',
          identifier: 'mapped-majors',
          showPublishNow: false
        },
        {
          id: 6,
          name: 'Map Occupations for Instructional Programs',
          identifier: 'mapped-careers',
          showPublishNow: false
        },
        {
          id: 7,
          name: 'Map Schools for Instructional Programs',
          identifier: 'mapped-schools',
          showPublishNow: false
        }
      ],

      languageVariations: {
        en: 'US English',
        es: 'US Spanish',
        vi: 'Vietnamese'
      },

      PERSONALITY_SCORE: {
        R: 'Realistic',
        I: 'Investigative',
        A: 'Artistic',
        S: 'Social',
        E: 'Enterprising',
        C: 'Conventional'
      },

      PT_TYPES: {
        REALISTIC: 'R',
        INVESTIGATIVE: 'I',
        ARTISTIC: 'A',
        SOCIAL: 'S',
        ENTERPRISING: 'E',
        CONVENTIONAL: 'C'
      },

      CREATE_MANAGE_GROUP_TABS: {
        GROUP: 'createAndManageGroup',
        ADD_DISCOVERERS: 'addDiscoverers'
      },

      PDF_OPERATION: {
        DOWNLOAD: 'download',
        PRINT: 'print'
      },

      ENVIRONMENT_TYPE: {
        THRIVING: 'Thriving',
        PROMISING: 'Promising',
        CHALLENGING: 'Challenging'
      },
      CKD: 'ckd',
      CDP: 'cdp',
      BOTH: 'both',

      CLUSTER_COLORS: ['#5d9cec', '#0a4f9c', '#4082c9', '#4d9bf0', '#0e79ed', '#0064d1'],

      SURVEY_DIMENSIONS: {
        DECIDEDNESS: 'Decidedness',
        COMFORT: 'Comfort',
        CAREER_DECISION_NEEDS: 'Career Decision Needs'
      },

      CAREER_DECISION_REASONS: {
        SELF_CLARITY: 'Self Clarity',
        KNOWLEDGE: 'Knowledge',
        DECISIVENESS: 'Decisiveness',
        CAREER_CHOICE_IMPORTANCE: 'Career Choice Importance',
        BARRIER: 'Barrier',
        EXTERNAL_BARRIERS: 'External Barriers'
      },

      CDP_RETAKE_ASSESSMENT_LIMIT: 2,
      RETAKE_CDP: 'retake cdp',
      INVITE_TO_START: 'invite to start',
      CUSTOM: 'custom',

      EMAIL_TEMPLATES: {
        SELF_HELP: 'SELF HELP',
        RETAKE_CDP: 'RETAKE CDP',
        RETAKE_CKD: 'RETAKE CKD',
        OFFER_SUPPORT: 'OFFER SUPPORT',
        BRIEF_STAFF_ASSIST: 'BRIEF STAFF ASSIST',
        KUDOS_FOR_PROGRESS: 'KUDOS FOR PROGRESS',
        INVITE_TO_START_CKD: 'INVITE TO START CKD',
        INVITE_TO_START_CDP: 'INVITE TO START CDP',
        REMIND_TO_COMPLETE_CKD: 'REMIND TO COMPLETE CKD',
        INDIVIDUAL_CASE_MANAGED: 'INDIVIDUAL CASE MANAGED',
        PROMPT_TO_ADD_OPTIONS_TO_PROFILE: 'PROMPT TO ADD OPTIONS TO PROFILE',
        DIFFERENTIATED_CONSISTENT: 'DIFFERENTIATED CONSISTENT',
        DIFFERENTIATED_INCONSISTENT: 'DIFFERENTIATED INCONSISTENT',
        UNDIFFERENTIATED: 'UNDIFFERENTIATED',
      }
    };

    appConstants.letsConnectOptions = [
      {
        label: 'Invite to start CKD',
        messageType: appConstants.EVENT_CATEGORY.NOT_STARTED,
        subscriptionType: appConstants.CKD,
        template: appConstants.EMAIL_TEMPLATES.INVITE_TO_START_CKD,
      },
      {
        label: 'Remind to complete',
        messageType: appConstants.EVENT_CATEGORY.NOT_COMPLETED,
        subscriptionType: appConstants.CKD,
        template: appConstants.EMAIL_TEMPLATES.REMIND_TO_COMPLETE_CKD,
      },
      {
        label: 'Prompt to add options to profile',
        messageType: appConstants.EVENT_CATEGORY.COMPLETED_ASSESSMENT,
        subscriptionType: appConstants.CKD,
        template: appConstants.EMAIL_TEMPLATES.PROMPT_TO_ADD_OPTIONS_TO_PROFILE,
      },
      {
        label: 'Kudos for progress',
        messageType: appConstants.EVENT_CATEGORY.STARTED_PROFILE,
        subscriptionType: appConstants.CKD,
        template: appConstants.EMAIL_TEMPLATES.KUDOS_FOR_PROGRESS,
      },
      {
        label: 'Offer support',
        messageType: appConstants.EVENT_CATEGORY.RETURNED_TO_PROFILE,
        subscriptionType: appConstants.CKD,
        template: appConstants.EMAIL_TEMPLATES.OFFER_SUPPORT,
      },
      {
        label: 'Differentiated - consistent',
        messageType: appConstants.DIFFERENTIATED_CONSISTENT,
        subscriptionType: appConstants.CKD,
        template: appConstants.EMAIL_TEMPLATES.DIFFERENTIATED_CONSISTENT,
      },
      {
        label: 'Differentiated - inconsistent',
        messageType: appConstants.DIFFERENTIATED_INCONSISTENT,
        subscriptionType: appConstants.CKD,
        template: appConstants.EMAIL_TEMPLATES.DIFFERENTIATED_INCONSISTENT,
      },
      {
        label: 'Undifferentiated',
        messageType: appConstants.UNDIFFERENTIATED,
        subscriptionType: appConstants.CKD,
        template: appConstants.EMAIL_TEMPLATES.UNDIFFERENTIATED,
      },
      {
        label: 'Invite to start CDP',
        messageType: appConstants.INVITE_TO_START,
        subscriptionType: appConstants.CDP,
        template: appConstants.EMAIL_TEMPLATES.INVITE_TO_START_CDP,
      },
      {
        label: 'Self help',
        messageType: appConstants.SELF_HELP,
        subscriptionType: appConstants.CDP,
        template: appConstants.EMAIL_TEMPLATES.SELF_HELP,
      },
      {
        label: 'Brief staff assist',
        messageType: appConstants.BRIEF_STAFF_ASSISTED,
        subscriptionType: appConstants.CDP,
        template: appConstants.EMAIL_TEMPLATES.BRIEF_STAFF_ASSIST,
      },
      {
        label: 'Individual case managed',
        messageType: appConstants.INDIVIDUAL_CASE_MANAGED_SERVICES,
        subscriptionType: appConstants.CDP,
        template: appConstants.EMAIL_TEMPLATES.INDIVIDUAL_CASE_MANAGED,
      },
      {
        label: 'Retake CDP',
        messageType: appConstants.RETAKE_CDP,
        subscriptionType: appConstants.CDP,
        template: appConstants.EMAIL_TEMPLATES.RETAKE_CDP,
      },
      {
        label: 'Custom message',
        messageType: appConstants.CUSTOM,
        subscriptionType: appConstants.BOTH,
        template: appConstants.CUSTOM,
      },
    ];

    return appConstants;
  };
})();
