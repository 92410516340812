(function() {
  'use strict';

  angular
    .module('app.user')
    .factory('CommonNotificationService', CommonNotificationService);

  CommonNotificationService.$inject = [
    'APP_CONSTANTS',
    '$q',
    '$filter',
    'ApiCaller'
  ];

  function CommonNotificationService(APP_CONSTANTS, $q, $filter, ApiCaller) {
    var self = this;

    self.getUserNotification = getUserNotification;
    self.dismissUserNotification = dismissUserNotification;
    self.updateUserLastSeenNotification = updateUserLastSeenNotification;

    function getUserNotification(userId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.userNotification,
        APP_CONSTANTS.rootUrl,
        userId
      );

      ApiCaller.apiGet(url)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.resolve(error);
        });

      return deferred.promise;
    }

    function updateUserLastSeenNotification(userId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.updateUserNotification,
        APP_CONSTANTS.rootUrl,
        userId
      );

      ApiCaller.apiPut(url)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.resolve(error);
        });

      return deferred.promise;
    }

    function dismissUserNotification(userId, notificationId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.dismissUserNotifictaion,
        APP_CONSTANTS.rootUrl,
        userId,
        notificationId
      );

      ApiCaller.apiPut(url)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.resolve(error);
        });

      return deferred.promise;
    }

    return self;
  }
})();
