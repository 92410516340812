(function() {
  'use strict';

  angular
    .module('app.shared')
    .controller('CustomerDetailsController', CustomerDetailsController);

  CustomerDetailsController.$inject = [
    '$uibModal',
    '$state',
    'APP_CONSTANTS',
    'toaster',
    'AdminCustomerSubscriptionService',
    'AdminCustomerBillingService',
    'CustomerService',
    '$rootScope',
    '$scope',
    '$stateParams'
  ];
  function CustomerDetailsController(
    $uibModal,
    $state,
    APP_CONSTANTS,
    toaster,
    AdminCustomerSubscriptionService,
    AdminCustomerBillingService,
    CustomerService,
    $rootScope,
    $scope,
    $stateParams
  ) {
    var vm = this;

    vm.loading = {};
    vm.percentage = 0;
    vm.usedQuantity = 0;
    vm.totalQuantity = 0;
    vm.constants = {
      updateCustomLabel: APP_CONSTANTS.MESSAGE.updateCustomLabel
    };
    vm.data = {
      customerId: $stateParams.customerId,
      accountInfo: {},
    };

    vm.dtOptions = {
      searching: false,
      order: [],
      columnDefs: [
        {
          orderable: false,
          targets: [9]
        }
      ]
    };

    activate();
    fetchData();

    function activate() {
      $scope.$parent.vm.setActiveTab();
      $scope.$parent.vm.generateBreadCrumbs('customer detail');

      var modalInstance = '';

      vm.addSubscriptionModal = function(size) {
        if (!modalInstance) {
          modalInstance = $uibModal.open({
            templateUrl: 'app/ckadmin/customers/views/adminAddSubscriptionModal.html',
            controller: 'AdminAddSubscriptionModalController',
            controllerAs: 'vm',
            windowClass: 'add-subscription-modal',
            size: size,
            resolve: {
              data: function() {
                return vm.data;
              }
            }
          });

          modalInstance.result
            .then(function() {
              vm.selectedSubscription = {};
              getSubscription();
            })
            .finally(function() {
              modalInstance = '';
            });
        }
      };

      vm.editAccountInformationModal = function (size) {
        if (!modalInstance) {
          modalInstance = $uibModal.open({
            templateUrl: 'app/ckadmin/customers/views/adminEditAccountInformationModal.html',
            controller: 'AdminEditAccountInformationModalController',
            controllerAs: 'vm',
            size: size,
            resolve: {
              data: function () {
                return vm.data;
              }
            }
          });

          modalInstance.result
            .then(function () {
              getAccountInfo();
            })
            .finally(function () {
              modalInstance = '';
            });
        }
      };

      vm.updateCustomLabelModal = function (subscription) {
        if (modalInstance) return;

        modalInstance = $uibModal.open({
          templateUrl: 'app/v2/customerAccount/views/partials/updateCustomLabelModal.html',
          controller: 'UpdateCustomLabelController',
          controllerAs: 'vm',
          resolve: {
            subscription: function () {
              return subscription;
            }
          }
        });

        modalInstance.result
          .then(function () {
            getSubscription();
          })
          .finally(function () {
            modalInstance = '';
          });
      }
    }

    function fetchData() {
      getAccountInfo();
      getSubscription();
    }

    function getAccountInfo() {
      vm.loading.accountInfo = true;

      AdminCustomerBillingService.getAccountDetail(vm.data.customerId)
        .then(function(response) {
          vm.loading.accountInfo = false;
          vm.accountInfo = _.get(response, 'data.data', {});
          angular.copy(vm.accountInfo, vm.data.accountInfo);
        })
        .catch(function(error) {
          vm.loading.accountInfo = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function getSubscription() {
      vm.loading.subscription = true;
      AdminCustomerSubscriptionService.fetchSubscription(vm.data.customerId)
        .then(function(response) {
          var data = _.get(response, 'data.data', {});

          vm.usedQuantity = _.get(data, 'usedQuantity') || 0;
          vm.totalQuantity = _.get(data, 'totalQuantity') || 0;
          vm.subscriptions = _.get(data, 'subscriptions', []);
          vm.percentage =
            (vm.totalQuantity && (vm.usedQuantity * 100) / vm.totalQuantity) ||
            0;

          vm.loading.subscription = false;
        })
        .catch(function(error) {
          vm.loading.subscription = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    vm.loginAsCustomer = () => {
      vm.loading.login = true;

      CustomerService.loginAsCustomer(vm.data.customerId)
        .catch(error => {
          vm.loading.login = false;
          toaster.pop({
            type: 'error',
            body: error.data.message || error.statusText,
          });
        });
    }
  }
})();
