(function() {
  'use strict';
  angular.module('app.shared').controller('MajorsController', MajorsController);

  MajorsController.$inject = [
    'APP_CONSTANTS',
    'toaster',
    '$scope',
    '$rootScope',
    '$uibModal',
    'WorkgroupService',
    'MajorsService',
    'ProductService',
    'BatchesService',
    'DetailsService',
    'PublishMajorService'
  ];

  function MajorsController(
    APP_CONSTANTS,
    toaster,
    $scope,
    $rootScope,
    $uibModal,
    WorkgroupService,
    MajorsService,
    ProductService,
    BatchesService,
    DetailsService,
    PublishMajorService
  ) {
    var vm = this;

    var modalInstance = '';

    vm.loading = {};
    vm.existingSearchKey = '';
    vm.selectedFilters = {};
    vm.productTagList = [];
    vm.productRegionList = [];

    vm.updateNewVariationInMajor = updateNewVariationInMajor;
    vm.updateExistingVariationInMajor = updateExistingVariationInMajor;
    // vm.getAllTags = getAllTags;
    vm.mapSchools = mapSchools;
    vm.mapCareers = mapCareers;
    vm.mapClusters = mapClusters;
    vm.revertMajor = revertMajor;
    vm.publishMajor = publishMajor;
    // vm.getAllRegions = getAllRegions;
    vm.getAllBatches = getAllBatches;
    vm.addToUnpublish = addToUnpublish;
    vm.extractMajorId = extractMajorId;
    vm.updatedUnpublishedMajorsList = [];
    vm.addNewVariation = addNewVariation;
    vm.setMajorPublishDate = setMajorPublishDate;
    vm.getProductDetails = getProductDetails;
    vm.getMajorPublishStatus = getMajorPublishStatus;
    vm.searchWorkgroupMajors = searchWorkgroupMajors;
    vm.openPublishMajorModal = openPublishMajorModal;
    vm.openAddNewMajorModal = openAddNewMajorModal;
    vm.openMappedTagsModal = openMappedTagsModal;
    vm.openMappedRegionsModal = openMappedRegionsModal;
    vm.getTagsByIds = getTagsByIds;
    vm.getRegionsByIds = getRegionsByIds;
    vm.saveUnpublishedMajor = saveUnpublishedMajor;
    vm.fetchWorkGroupMajors = fetchWorkGroupMajors;
    vm.fetchProductWorkgroups = fetchProductWorkgroups;
    vm.getProductPublishOptions = getProductPublishOptions;
    vm.openViewMajorDetailsModal = openViewMajorDetailsModal;
    vm.updateMappedCareerInMajor = updateMappedCareerInMajor;
    vm.updateMappedClustersInMajor = updateMappedClustersInMajor;
    vm.publishedId = APP_CONSTANTS.productPublishOptions[0].id;
    vm.getUnpublishedMajorIfExist = getUnpublishedMajorIfExist;
    vm.unpublishedId = APP_CONSTANTS.productPublishOptions[1].id;
    vm.updateMappedSchoolInMajor = updateMappedSchoolInMajor;
    vm.updateMappedTagsInMajor = updateMappedTagsInMajor;
    vm.updateMappedRegionsInMajor = updateMappedRegionsInMajor;
    vm.selectedFilters.publishOption = { id: 1, name: 'Published' };
    vm.updateUnpublishedMajors = updateUnpublishedMajors;
    vm.openEditMajorsModal = openEditMajorsModal;
    vm.isPublished = true;
    vm.formatDate = formatDate;
    vm.productTagList = $scope.$parent.vm.productTagList;
    vm.productRegionList = $scope.$parent.vm.productRegionList;

    vm.productId = ProductService.getProductId();
    vm.productAssessments = [];
    vm.productWorkgroups = [];
    vm.workgroupMajors = [];
    vm.isOpen = true;
    vm.ptTypes = APP_CONSTANTS.PT_TYPES;
    vm.personalityTypes = APP_CONSTANTS.PERSONALITY_TYPES;

    resetCategorizedWorkgroups();
    fetchProductWorkgroups();
    resetMajorsCount();

    function resetMajorsCount() {
      vm.majorsCount = {
        realistic: 0,
        investigative: 0,
        artistic: 0,
        social: 0,
        enterprising: 0,
        conventional: 0
      };
    }

    function getTagsByIds(tagIdArray) {
      var tags = [];

      tagIdArray = tagIdArray || [];

      tagIdArray.forEach(function(tagId) {
        vm.productTagList.forEach(function(tag) {
          if (tag.id === tagId) {
            tags.push(tag.name);
          }
        });
      });

      return tags.join().replace(/,/g, ', ');
    }

    function getRegionsByIds(regionIdArray) {
      var regions = [];

      regionIdArray = regionIdArray || [];

      regionIdArray.forEach(function(regionId) {
        vm.productRegionList.forEach(function(region) {
          if (region.id === regionId) {
            regions.push(region.name);
          }
        });
      });

      return regions.join().replace(/,/g, ', ');
    }

    function getProductPublishOptions() {
      vm.productPublishOptions = APP_CONSTANTS.productPublishOptions;
    }

    function getProductDetails() {
      vm.loading.productInfo = true;

      DetailsService.fetchProductDetails(vm.productId)
        .then(function(result) {
          vm.loading.productInfo = false;

          vm.productTagList = _.get(result, 'data.products[0].tags', []);
          vm.productRegionList = _.get(result, 'data.products[0].regions', []);
        })
        .catch(function(error) {
          vm.loading.productInfo = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function getMajorPublishStatus(major) {
      if (!major.isPublishStatusFetched) {
        vm.loading.productInfo = true;
        MajorsService.fetchMajorPublishStatus(vm.activeWorkgroupId, major.id)
          .then(function(result) {
            vm.loading.productInfo = false;
            major.isPublishStatusFetched = true;

            major.publishStatus = _.get(result, 'data', {});
          })
          .catch(function(error) {
            vm.loading.productInfo = false;
            toaster.pop('error', error.data.message || error.statusText);
          });
      }
    }

    function getUnpublishedMajorIfExist(majorId) {
      var majorDetail = vm.updatedUnpublishedMajorsList.filter(function(
        updatedMajor
      ) {
        return updatedMajor.id === majorId;
      });

      if (majorDetail.length) {
        return majorDetail[0];
      }
    }

    function formatDate(date) {
      return $rootScope.moment(date).format('MMMM DD, YYYY');
    }

    function revertMajor(majorId) {
      MajorsService.revertMajor(vm.activeWorkgroupId, majorId, vm.productId)
        .then(function() {
          vm.loading.productInfo = false;
          removeMajorsFromUpdateList(majorId);
          toaster.pop('success', APP_CONSTANTS.MESSAGE.majorReverted);

          fetchWorkGroupMajors(vm.activeWorkgroupId);
        })
        .catch(function(error) {
          vm.loading.productInfo = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function saveUnpublishedMajor(majorId) {
      var index = vm.updatedUnpublishedMajorsList
        .map(function(updatedMajor) {
          return updatedMajor.id;
        })
        .indexOf(majorId);

      if (index < 0) {
        toaster.pop('info', APP_CONSTANTS.MESSAGE.makeChangeToSaveDraft);

        return;
      }

      vm.updatedUnpublishedMajorsList[index].productId = vm.productId;

      delete vm.updatedUnpublishedMajorsList[index].isPublishStatusFetched;
      delete vm.updatedUnpublishedMajorsList[index].publishStatus;

      vm.loading.productInfo = true;
      MajorsService.saveDraft(
        vm.updatedUnpublishedMajorsList[index],
        vm.activeWorkgroupId,
        majorId,
        vm.productId
      )
        .then(function() {
          vm.loading.productInfo = false;
          removeMajorsFromUpdateList(majorId);
          toaster.pop(
            'success',
            APP_CONSTANTS.MESSAGE.majorUpdatedToUnpublished
          );

          fetchWorkGroupMajors(vm.activeWorkgroupId);
        })
        .catch(function(error) {
          vm.loading.productInfo = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function addToUnpublish(majorId) {
      vm.loading.productInfo = true;
      MajorsService.addToUnpublished(
        vm.activeWorkgroupId,
        majorId,
        vm.productId
      )
        .then(function() {
          vm.loading.productInfo = false;

          toaster.pop('success', APP_CONSTANTS.MESSAGE.majorAddedToUnpublished);
        })
        .catch(function(error) {
          vm.loading.productInfo = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function removeMajorsFromUpdateList(majorId) {
      var index = vm.updatedUnpublishedMajorsList
        .map(function(major) {
          return major.id;
        })
        .indexOf(majorId);

      if (index > -1) {
        vm.updatedUnpublishedMajorsList.splice(index, 1);
        toaster.pop('success', APP_CONSTANTS.MESSAGE.successfullyRevertChanges);
      } else toaster.pop('info', APP_CONSTANTS.MESSAGE.noChangesToRevert);
    }

    function getAllBatches() {
      vm.loading.productInfo = false;

      BatchesService.fetchBatchesByProductAndType(
        vm.productId,
        APP_CONSTANTS.batchTypeOptions[1].name
      )
        .then(function(result) {
          vm.loading.productInfo = false;

          vm.batches = _.get(result, 'data.batches', []);
        })
        .catch(function(error) {
          vm.loading.productInfo = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function resetCategorizedWorkgroups() {
      vm.categorizedWorkgroups = {
        realistic: [],
        investigative: [],
        artistic: [],
        social: [],
        enterprising: [],
        conventional: []
      };
    }

    function updateUnpublishedMajors(majorId) {
      var majorDetail = vm.updatedUnpublishedMajorsList.filter(function(
        updatedMajor
      ) {
        return updatedMajor.id === majorId;
      });

      if (!majorDetail.length) {
        var selectedMajor = vm.workgroupMajors.filter(function(selectedMajor) {
          return selectedMajor.id === majorId;
        });

        vm.updatedUnpublishedMajorsList.push(selectedMajor[0]);
      }
    }

    function fetchProductWorkgroups() {
      vm.loading.productInfo = true;
      vm.searchKey = '';
      vm.existingSearchKey = '';
      resetMajorsCount();
      vm.isPublished = vm.selectedFilters.publishOption.id === vm.publishedId;

      WorkgroupService.fetchProductWorkgroups(vm.productId)
        .then(function(result) {
          vm.loading.productInfo = false;

          resetCategorizedWorkgroups();

          vm.productWorkgroups = _.get(result, 'data.workgroups', []);
          vm.productWorkgroups.forEach(function(workgroup) {
            if (workgroup.pt === vm.ptTypes.REALISTIC) {
              vm.categorizedWorkgroups.realistic.push(workgroup);
            } else if (workgroup.pt === vm.ptTypes.INVESTIGATIVE) {
              vm.categorizedWorkgroups.investigative.push(workgroup);
            } else if (workgroup.pt === vm.ptTypes.ARTISTIC) {
              vm.categorizedWorkgroups.artistic.push(workgroup);
            } else if (workgroup.pt === vm.ptTypes.SOCIAL) {
              vm.categorizedWorkgroups.social.push(workgroup);
            } else if (workgroup.pt === vm.ptTypes.ENTERPRISING) {
              vm.categorizedWorkgroups.enterprising.push(workgroup);
            } else if (workgroup.pt === vm.ptTypes.CONVENTIONAL) {
              vm.categorizedWorkgroups.conventional.push(workgroup);
            }
          });

          fetchWorkGroupMajors(
            vm.activeWorkgroupId || vm.categorizedWorkgroups.realistic[0].id
          );
        })
        .catch(function(error) {
          vm.loading.productInfo = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function searchWorkgroupMajors($event) {
      if ($event.keyCode !== 13) {
        return;
      }

      vm.loading.productInfo = true;
      vm.existingSearchKey = vm.searchKey;

      if (vm.searchKey === '') {
        fetchProductWorkgroups();
        resetMajorsCount();
      } else {
        resetMajorsCount();

        var params = {
          type: APP_CONSTANTS.majors,
          batchId: angular.isUndefined(vm.selectedFilters.batch)
            ? ''
            : vm.selectedFilters.batch.id,
          publishType: vm.selectedFilters.publishOption.name.toLowerCase(),
          searchKey: vm.existingSearchKey,
          tagId: angular.isUndefined(vm.selectedFilters.tag)
            ? ''
            : vm.selectedFilters.tag.id,
          regionId: angular.isUndefined(vm.selectedFilters.region)
            ? ''
            : vm.selectedFilters.region.id
        };

        MajorsService.searchWorkgroupMajors(vm.productId, params)
          .then(function(result) {
            vm.loading.productInfo = false;

            vm.filteredWorkgroups = _.get(result, 'data.workgroups', []);

            vm.productWorkgroups.forEach(function(productWorkgroup) {
              var filteredWorkgroup = vm.filteredWorkgroups.filter(function(
                filteredWorkgroup
              ) {
                return productWorkgroup.id === filteredWorkgroup.id;
              });

              productWorkgroup.count = filteredWorkgroup.length
                ? filteredWorkgroup[0].count
                : 0;

              switch (productWorkgroup.pt) {
                case 'R':
                  vm.majorsCount.realistic += productWorkgroup.count;
                  break;
                case 'I':
                  vm.majorsCount.investigative += productWorkgroup.count;
                  break;
                case 'A':
                  vm.majorsCount.artistic += productWorkgroup.count;
                  break;
                case 'S':
                  vm.majorsCount.social += productWorkgroup.count;
                  break;
                case 'E':
                  vm.majorsCount.enterprising += productWorkgroup.count;
                  break;
                case 'C':
                  vm.majorsCount.conventional += productWorkgroup.count;
                  break;
                default:
                  break;
              }
            });

            fetchWorkGroupMajors(vm.activeWorkgroupId);
          })
          .catch(function(error) {
            vm.loading.productInfo = false;
            toaster.pop('error', error.data.message || error.statusText);
          });
      }
    }

    function publishMajor(majorId) {
      vm.loading.productInfo = true;

      MajorsService.publishMajor(vm.activeWorkgroupId, majorId, vm.productId)
        .then(function() {
          vm.loading.productInfo = false;

          fetchWorkGroupMajors(vm.activeWorkgroupId);

          toaster.pop(
            'success',
            APP_CONSTANTS.MESSAGE.majorSuccessfullyPublished
          );
        })
        .catch(function(error) {
          vm.loading.productInfo = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function setMajorPublishDate(workgroupId, majorId, selectedDate) {
      vm.loading.productInfo = true;

      var publishDate = $rootScope.moment(selectedDate).format('YYYY-MM-DD');

      PublishMajorService.setMajorPublishDate(workgroupId, majorId, {
        publishDate: publishDate
      })
        .then(function() {
          vm.loading.productInfo = false;

          fetchWorkGroupMajors(workgroupId);

          toaster.pop(
            'success',
            APP_CONSTANTS.MESSAGE.majorPublishDateSuccessfullySet
          );
        })
        .catch(function(error) {
          vm.loading.productInfo = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function fetchWorkGroupMajors(workgroupId) {
      vm.loading.productInfo = true;
      vm.activeWorkgroupId = workgroupId;
      var isPubishedFlag =
        vm.selectedFilters.publishOption.id ===
        APP_CONSTANTS.productPublishOptions[0].id;
      var batchId = angular.isUndefined(vm.selectedFilters.batch)
        ? ''
        : vm.selectedFilters.batch.id;
      var tagId = angular.isUndefined(vm.selectedFilters.tag)
        ? ''
        : vm.selectedFilters.tag.id;
      var regionId = angular.isUndefined(vm.selectedFilters.region)
        ? ''
        : vm.selectedFilters.region.id;

      MajorsService.fetchMajorsByWorkgroupId(
        vm.productId,
        workgroupId,
        isPubishedFlag,
        vm.existingSearchKey,
        batchId,
        tagId,
        regionId
      )
        .then(function(result) {
          vm.loading.productInfo = false;
          vm.workgroupMajors = _.get(
            result,
            'data.workgroup[0].instructionalPrograms',
            []
          );
          vm.selectedWorkgroupId = _.get(result, 'data.workgroup[0].id', null);
        })
        .catch(function(error) {
          vm.loading.productInfo = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function mapCareers(selectedMajor) {
      if (!modalInstance) {
        modalInstance = $uibModal.open({
          templateUrl:
            'app/ckadmin/products/majors/mappedCareers/mappedCareers.html',
          controller: 'MappedCareersController',
          controllerAs: 'vm',
          animation: true,
          size: 'lg',
          scope: $scope,
          resolve: {
            selectedMajor: function() {
              return selectedMajor;
            },
            isPublished: function() {
              return vm.selectedFilters.publishOption.id === vm.publishedId;
            }
          }
        });

        modalInstance.result
          .then(function() {
            modalInstance = '';
          })
          .catch(function() {
            modalInstance = '';
          });
      }
    }

    function mapSchools(selectedMajor, selectedWorkgroupId) {
      if (!modalInstance) {
        modalInstance = $uibModal.open({
          templateUrl:
            'app/ckadmin/products/majors/mappedSchools/mappedSchools.html',
          controller: 'MappedSchoolsController',
          controllerAs: 'vm',
          animation: true,
          size: 'lg',
          scope: $scope,
          resolve: {
            selectedMajor: function() {
              return selectedMajor;
            },
            selectedWorkgroupId: function() {
              return selectedWorkgroupId;
            },
            isPublished: function() {
              return vm.selectedFilters.publishOption.id === vm.publishedId;
            }
          }
        });

        modalInstance.result
          .then(function() {
            modalInstance = '';
          })
          .catch(function() {
            modalInstance = '';
          });
      }
    }

    function mapClusters(selectedMajor) {
      if (!modalInstance) {
        modalInstance = $uibModal.open({
          templateUrl:
            'app/ckadmin/products/clusters/mappedClusters/mappedClusters.html',
          controller: 'MappedClustersController',
          controllerAs: 'vm',
          animation: true,
          size: 'lg',
          scope: $scope,
          resolve: {
            selectedItem: function() {
              return selectedMajor;
            },
            isPublished: function() {
              return vm.selectedFilters.publishOption.id === vm.publishedId;
            },
            programType: function() {
              return APP_CONSTANTS.instructionalProgram;
            }
          }
        });

        modalInstance.result
          .then(function() {
            modalInstance = '';
          })
          .catch(function() {
            modalInstance = '';
          });
      }
    }

    function openEditMajorsModal(selectedMajor) {
      if (!modalInstance) {
        modalInstance = $uibModal.open({
          templateUrl: 'app/ckadmin/products/majors/editMajor/editMajor.html',
          controller: 'EditMajorController',
          controllerAs: 'vm',
          animation: true,
          size: 'lg',
          scope: $scope,
          resolve: {
            selectedMajor: function() {
              return selectedMajor;
            }
          }
        });

        modalInstance.result
          .then(function() {
            modalInstance = '';
          })
          .catch(function() {
            modalInstance = '';
          });
      }
    }

    function openViewMajorDetailsModal(selectedMajor) {
      if (!modalInstance) {
        modalInstance = $uibModal.open({
          templateUrl:
            'app/ckadmin/products/majors/viewMajorDetails/viewMajorDetails.html',
          controller: 'ViewMajorDetailsController',
          controllerAs: 'vm',
          animation: true,
          size: 'lg',
          scope: $scope,
          resolve: {
            selectedMajor: function() {
              return selectedMajor;
            }
          }
        });

        modalInstance.result
          .then(function() {
            modalInstance = '';
          })
          .catch(function() {
            modalInstance = '';
          });
      }
    }

    function addNewVariation(selectedMajor) {
      if (!modalInstance) {
        modalInstance = $uibModal.open({
          templateUrl:
            'app/ckadmin/products/majors/newVariation/newVariation.html',
          controller: 'MajorsNewVariationController',
          controllerAs: 'vm',
          animation: true,
          size: 'lg',
          scope: $scope,
          resolve: {
            selectedMajor: function() {
              return selectedMajor;
            }
          }
        });

        modalInstance.result
          .then(function() {
            modalInstance = '';
          })
          .catch(function() {
            modalInstance = '';
          });
      }
    }

    function openPublishMajorModal(selectedMajor, selectedWorkgroupId) {
      if (!modalInstance) {
        modalInstance = $uibModal.open({
          templateUrl: 'app/ckadmin/products/majors/publish/publish.html',
          controller: 'PublishMajorController',
          controllerAs: 'vm',
          animation: true,
          size: 'sm',
          scope: $scope,
          resolve: {
            selectedMajor: function() {
              return selectedMajor;
            },
            selectedWorkgroupId: function() {
              return selectedWorkgroupId;
            }
          }
        });

        modalInstance.result
          .then(function() {
            modalInstance = '';
          })
          .catch(function() {
            modalInstance = '';
          });
      }
    }

    function openAddNewMajorModal(selectedWorkgroupId) {
      if (!modalInstance) {
        modalInstance = $uibModal.open({
          templateUrl:
            'app/ckadmin/products/majors/addNewMajor/addNewMajor.html',
          controller: 'AddNewMajorController',
          controllerAs: 'vm',
          animation: true,
          size: 'lg',
          scope: $scope,
          resolve: {
            selectedWorkgroupId: function() {
              return selectedWorkgroupId;
            },
            selectedProductId: function() {
              return vm.productId;
            }
          }
        });

        modalInstance.result
          .then(function() {
            modalInstance = '';
          })
          .catch(function() {
            modalInstance = '';
          });
      }
    }

    function openMappedTagsModal(selectedMajor) {
      if (!modalInstance) {
        modalInstance = $uibModal.open({
          templateUrl: 'app/ckadmin/products/majors/mappedTags/mappedTags.html',
          controller: 'MappedTagsForMajorController',
          controllerAs: 'vm',
          animation: true,
          size: 'md',
          scope: $scope,
          resolve: {
            selectedMajor: function() {
              return selectedMajor;
            },
            isPublished: function() {
              return vm.selectedFilters.publishOption.id === vm.publishedId;
            },
            productTagList: function() {
              return vm.productTagList;
            }
          }
        });

        modalInstance.result
          .then(function() {
            modalInstance = '';
          })
          .catch(function() {
            modalInstance = '';
          });
      }
    }

    function openMappedRegionsModal(selectedMajor) {
      if (!modalInstance) {
        modalInstance = $uibModal.open({
          templateUrl:
            'app/ckadmin/products/majors/mappedRegions/mappedRegions.html',
          controller: 'MappedRegionsForMajorController',
          controllerAs: 'vm',
          animation: true,
          size: 'md',
          scope: $scope,
          resolve: {
            selectedMajor: function() {
              return selectedMajor;
            },
            isPublished: function() {
              return vm.selectedFilters.publishOption.id === vm.publishedId;
            },
            productRegionList: function() {
              return vm.productRegionList;
            }
          }
        });

        modalInstance.result
          .then(function() {
            modalInstance = '';
          })
          .catch(function() {
            modalInstance = '';
          });
      }
    }

    function updateMappedCareerInMajor(majorId, mappedCareers) {
      var majorDetail = vm.updatedUnpublishedMajorsList.filter(function(
        updatedMajor
      ) {
        return updatedMajor.id === majorId;
      });

      majorDetail[0].careers = mappedCareers;
    }

    function updateMappedClustersInMajor(
      majorId,
      mappedClusters,
      mappedSubClusters
    ) {
      var majorDetail = vm.updatedUnpublishedMajorsList.filter(function(
        updatedMajor
      ) {
        return updatedMajor.id === majorId;
      });

      majorDetail[0].clusterId = mappedClusters;
      majorDetail[0].subClusterId = mappedSubClusters;
    }

    function updateMappedTagsInMajor(majorId, mappedTags) {
      var majorDetail = vm.updatedUnpublishedMajorsList.filter(function(
        updatedMajor
      ) {
        return updatedMajor.id === majorId;
      });

      majorDetail[0].tags = mappedTags;
    }

    function updateMappedRegionsInMajor(majorId, mappedRegions) {
      var majorDetail = vm.updatedUnpublishedMajorsList.filter(function(
        updatedMajor
      ) {
        return updatedMajor.id === majorId;
      });

      majorDetail[0].regions = mappedRegions;
    }

    function updateNewVariationInMajor(majorId, newLanguageVariation) {
      var majorDetail = vm.updatedUnpublishedMajorsList.filter(function(
        updatedMajor
      ) {
        return updatedMajor.id === majorId;
      });

      majorDetail[0].variations[
        newLanguageVariation.language.id
      ] = formatVariationObject(newLanguageVariation);
    }

    function updateExistingVariationInMajor(majorId, editableFieldsObject) {
      var majorDetail = vm.updatedUnpublishedMajorsList.filter(function(
        updatedMajor
      ) {
        return updatedMajor.id === majorId;
      });

      Object.keys(editableFieldsObject).forEach(function(languageId) {
        Object.keys(editableFieldsObject[languageId]).forEach(function(key) {
          if (
            majorDetail[0].variations[languageId][key] !==
            editableFieldsObject[languageId][key]
          ) {
            majorDetail[0].variations[languageId][key] =
              editableFieldsObject[languageId][key];
          }
        });
      });
    }

    function formatVariationObject(newLanguageVariation) {
      return {
        name: newLanguageVariation.name,
        cipName: newLanguageVariation.name,
        description: newLanguageVariation.description || '',
        shortDescription: newLanguageVariation.shortDescription || '',
        descriptionUrl: newLanguageVariation.descriptionUrl || '',
        educationalRequirementName:
          newLanguageVariation.educationalRequirementName || '',
        duration: newLanguageVariation.duration || ''
      };
    }

    function updateMappedSchoolInMajor(majorId, mappedSchools) {
      var mappedSchoolsIdArray = [];
      var majorDetail = vm.updatedUnpublishedMajorsList.filter(function(
        updatedMajor
      ) {
        return updatedMajor.id === majorId;
      });

      mappedSchools.forEach(function(school) {
        mappedSchoolsIdArray.push(school.id);
      });

      majorDetail[0].Schools = mappedSchoolsIdArray;
    }

    function extractMajorId(id) {
      var majorId = '';

      majorId = id.split('base-')[1];

      return majorId;
    }
  }
})();
