(() => {
  'use strict';

  /**
   * add custom email validation to input using RegEx
   * example: <input type="email" valid-email>
   * for multiple email validation: <input type="email" valid-email="multiple">
   * for conditional email validation: <input type="email" valid-email="{{vm.allowMultiple?'multiple':'single'}}">
   * can be used with input type="text" as well
   */
  angular.module('careerKeyCentral')
    .directive('validEmail', [
      'APP_CONSTANTS',
      validEmail,
    ]);

  function validEmail(
    APP_CONSTANTS,
  ) {
    return {
      require: '?ngModel',
      link: (scope, elm, attrs, ctrl) => {
        if (!ctrl) return;

        ctrl.$validators.validEmail = (viewValue) => {
          if (!viewValue) return true;

          const pattern = APP_CONSTANTS.REGEX.EMAIL;

          if (attrs.validEmail === 'multiple') {
            const emails = viewValue.split(',').filter(email => email.trim().length);

            if (emails.length) {
              return emails.every(email => pattern.test(email.trim()));
            }
          }

          return pattern.test(viewValue);
        };

        if (ctrl.$validators.email) {
          // if email validator is already present, override it with custom email validator
          ctrl.$validators.email = ctrl.$validators.validEmail;
        }
      }
    };
  }
})();
