(function() {
  'use strict';
  angular
    .module('app.stakeholder')
    .controller(
      'StakeHolderDashboardController',
      StakeHolderDashboardController
    );

  StakeHolderDashboardController.$inject = [
    'StakeHolderDashboardService',
    'GroupService',
    'toaster',
    'APP_CONSTANTS',
    'SessionService',
    '$filter',
    '$state'
  ];

  function StakeHolderDashboardController(
    StakeHolderDashboardService,
    GroupService,
    toaster,
    APP_CONSTANTS,
    SessionService,
    $filter,
    $state
  ) {
    var vm = this;
    var currentUser = SessionService.getUser();

    vm.loading = {};
    vm.groupId = '';
    vm.organization = {};
    vm.dashboardEntities = {};
    vm.stateChange = stateChange;
    vm.groupSelected = groupSelected;

    loadOrganizationDetails();
    fetchGroups();

    function loadOrganizationDetails() {
      vm.organization.legalName = currentUser.customerLegalName;
      vm.organization.profileImage = SessionService.getOrganizationImage();
    }

    function fetchGroups() {
      vm.loading.categories = true;
      GroupService.fetchGroupsByUser(currentUser.sponsoredOrganizationId)
        .then(function(response) {
          if (response.data.data.length) {
            vm.dashboardEntities.groupList = response.data.data;
            vm.dashboardEntities.selectedGroup =
              vm.dashboardEntities.groupList[0];
            vm.groupId = vm.dashboardEntities.groupList[0].id;
            fetchCategories(vm.dashboardEntities.selectedGroup.id);
          } else {
            vm.loading.categories = false;
            toaster.pop('info', APP_CONSTANTS.MESSAGE.noGroupAssigned);
          }
        })
        .catch(function(error) {
          vm.loading.categories = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function fetchCategories(groupId) {
      removeLabels();
      vm.loading.categories = true;
      StakeHolderDashboardService.fetchEventCategories(groupId)
        .then(function(response) {
          setLabels(response);
          vm.loading.categories = false;
        })
        .catch(function(error) {
          toaster.pop('error', error.data.message || error.statusText);
          vm.dashboardEntities.events = {
            notStarted: 0,
            startedProfile: 0,
            returnedToProfile: 0,
            completedAssessment: 0
          };
          vm.loading.categories = false;
        });
    }

    function removeLabels() {
      vm.dashboardEntities.labels = {
        notStarted: '',
        startedProfile: '',
        returnedToProfile: '',
        completedAssessment: ''
      };
    }

    function setLabels(response) {
      if (response.data.data.groupDiscoverCount !== null) {
        var totalDiscoverers = response.data.data.groupDiscoverCount;
      } else {
        totalDiscoverers = response.data.data.totalDiscoverCount;
      }

      var notStartedPercent = totalDiscoverers
        ? ((response.data.data.notStartedCount +
            response.data.data.inviteToStartCount) /
            totalDiscoverers) *
          100
        : 0;
      var startedProfilePercent = totalDiscoverers
        ? (response.data.data.startedProfileCount / totalDiscoverers) * 100
        : 0;
      var returnedToProfilePercent = totalDiscoverers
        ? (response.data.data.returnedToProfileCount / totalDiscoverers) * 100
        : 0;
      var completedAssessmentPercent = totalDiscoverers
        ? (response.data.data.completedAssessmentCount / totalDiscoverers) * 100
        : 0;

      var dashboardProgressPercents = [
        notStartedPercent,
        startedProfilePercent,
        returnedToProfilePercent,
        completedAssessmentPercent
      ];

      var adjustedProgressPercents = totalDiscoverers
        ? adjustPercentsInWholeNumber(dashboardProgressPercents, 100)
        : [0, 0, 0, 0];
      var notStarted = adjustedProgressPercents[0];
      var startedProfile = adjustedProgressPercents[1];
      var returnedToProfile = adjustedProgressPercents[2];
      var completedAssessment = adjustedProgressPercents[3];

      vm.dashboardEntities.totalDiscovererCount =
        response.data.data.totalDiscoverCount;
      vm.dashboardEntities.groupDiscovererCount =
        response.data.data.groupDiscoverCount;

      vm.dashboardEntities.events = {
        notStarted: isNaN(notStarted) ? 0 : notStarted,
        startedProfile: isNaN(startedProfile) ? 0 : startedProfile,
        returnedToProfile: isNaN(returnedToProfile) ? 0 : returnedToProfile,
        completedAssessment: isNaN(completedAssessment)
          ? 0
          : completedAssessment
      };

      vm.dashboardEntities.labels = {
        notStarted: $filter('percentage')(notStarted, 0),
        startedProfile: $filter('percentage')(startedProfile, 0),
        returnedToProfile: $filter('percentage')(returnedToProfile, 0),
        completedAssessment: $filter('percentage')(completedAssessment, 0)
      };
    }

    function adjustPercentsInWholeNumber(sourceArray, target) {
      var diff, i, parts, sum;

      sum = 0;
      parts = _.map(sourceArray, function(item, i) {
        var int;

        int = item | 0;
        sum += int;

        return {
          integer: int,
          decimal: item % 1,
          originalIndex: i
        };
      });
      if (sum !== target) {
        parts = _.sortBy(parts, 'decimal').reverse();
        diff = target - sum;
        i = 0;
        while (i < diff) {
          parts[i].integer++;
          i++;
        }
      }

      return _.map(_.sortBy(parts, 'originalIndex'), 'integer');
    }

    function groupSelected() {
      vm.groupId = vm.dashboardEntities.selectedGroup.id || '';
      fetchCategories(vm.dashboardEntities.selectedGroup.id);
    }

    function stateChange(category) {
      vm.parameter = {};
      vm.parameter.category = category;
      vm.parameter.groupId = vm.groupId || '';
      $state.go('app.discoverersprogress', { parameter: vm.parameter });
    }
  }
})();
