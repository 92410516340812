(function() {
  'use strict';

  angular
    .module('careerKeyCentral')
    .service('ApiCaller', [
      '$http',
      '$q',
      'SessionService',
      ApiCaller
    ]);

  function ApiCaller(
    $http,
    $q,
    SessionService,
  ) {
    var self = this;

    self.apiGet = apiGet;
    self.apiPut = apiPut;
    self.apiPost = apiPost;
    self.apiDelete = apiDelete;
    self.apiFormPost = apiFormPost;
    self.apiFilePost = apiFilePost;
    self.apiProfilePdfGet = apiProfilePdfGet;
    self.buildErrorMessage = buildErrorMessage;

    function apiGet(uri) {
      var deferred = $q.defer();

      $http.get(uri).then(
        function(response) {
          if (response.status >= 200 && response.status < 300) {
            deferred.resolve(response);
          } else {
            deferred.reject(response);
          }
        },
        function(response) {
          if (response.status == 500) {
            response.data.message =
              'Oops! Something went wrong. Career Key Discovery may be temporarily unavailable. If this problem continues, please contact support@careerkey.org.';
          }
          deferred.reject(response);
        }
      );

      return deferred.promise;
    }

    function apiProfilePdfGet(uri) {
      var deferred = $q.defer();

      $http.get(uri, { responseType: 'arraybuffer' }).then(
        function(response) {
          if (response.status >= 200 && response.status < 300) {
            deferred.resolve(response);
          } else {
            deferred.reject(response);
          }
        },
        function(response) {
          if (response.status == 500) {
            response.data.message =
              'Oops! Something went wrong. Career Key Discovery may be temporarily unavailable. If this problem continues, please contact support@careerkey.org.';
          }
          deferred.reject(response);
        }
      );

      return deferred.promise;
    }

    function apiDelete(uri, data) {
      var deferred = $q.defer();

      $http.delete(uri, {
        data,
        headers: { 'Content-Type': 'application/json' }
      }).then(
        function(response) {
          if (response.status >= 200 && response.status < 300) {
            deferred.resolve(response);
          } else {
            deferred.reject(response);
          }
        },
        function(response) {
          if (response.status == 500) {
            response.data.message =
              'Oops! Something went wrong. Career Key Discovery may be temporarily unavailable. If this problem continues, please contact support@careerkey.org.';
          }
          deferred.reject(response);
        }
      );

      return deferred.promise;
    }

    function apiPost(uri, data) {
      var deferred = $q.defer();

      $http.post(uri, data).then(
        function(response) {
          if (response.status >= 200 && response.status < 300) {
            deferred.resolve(response);
          } else {
            deferred.reject(response);
          }
        },
        function(response) {
          if (response.status == 500) {
            response.data.message =
              'Oops! Something went wrong. Career Key Discovery may be temporarily unavailable. If this problem continues, please contact support@careerkey.org.';
          }
          deferred.reject(response);
        }
      );

      return deferred.promise;
    }

    function apiPut(uri, data) {
      var deferred = $q.defer();

      $http.put(uri, data).then(
        function(response) {
          if (response.status >= 200 && response.status < 300) {
            deferred.resolve(response);
          } else {
            deferred.reject(response);
          }
        },
        function(response) {
          if (response.status == 500) {
            response.data.message =
              'Oops! Something went wrong. Career Key Discovery may be temporarily unavailable. If this problem continues, please contact support@careerkey.org.';
          }
          deferred.reject(response);
        }
      );

      return deferred.promise;
    }

    function apiFormPost(url, data, target) {
      const form = document.createElement('form');
      form.action = url;
      form.method = 'POST';
      form.target = target || '_self';

      if (data) {
        data.refreshToken = SessionService.getRefreshToken();

        for (const key in data) {
          const input = document.createElement('textarea');
          input.name = key;
          input.value = (typeof data[key] === 'object') ? JSON.stringify(data[key]) : data[key];

          form.appendChild(input);
        }
      }

      form.style.display = 'none';
      document.body.appendChild(form);
      form.submit();
    }

    function apiFilePost(url, formData, identity) {
      var deferred = $q.defer();

      $http.post(url, formData, identity).then(
        function(response) {
          if (response.status >= 200 && response.status < 300) {
            deferred.resolve(response);
          } else {
            deferred.reject(response);
          }
        },
        function(response) {
          if (response.status == 500) {
            response.data.message =
              'Oops! Something went wrong. Career Key Discovery may be temporarily unavailable. If this problem continues, please contact support@careerkey.org.';
          }
          deferred.reject(response);
        }
      );
      return deferred.promise;
    }

    function buildErrorMessage(result) {
      var errorMessage = result.status;
      if (result.data.message) errorMessage += ' : ' + result.data.message;
      return errorMessage;
    }
  }
})();
