(function() {
  'use strict';
  angular.module('app.user').factory('PlanDetailService', PlanDetailService);

  PlanDetailService.$inject = ['RegistrationService', '$state', '$q'];

  function PlanDetailService(RegistrationService, $state, $q) {
    var self = this;

    self.getPlanDetails = getPlanDetails;

    /**
     * Get details of a particular plan from passed token
     *
     * @returns {plan} Returns plan details
     */
    function getPlanDetails() {
      var deferred = $q.defer();
      var token = RegistrationService.getToken();

      if (!token) {
        return deferred.resolve(null);
      }

      RegistrationService.getPlanDetails(token)
        .then(function(result) {
          deferred.resolve(result.data.data);
        })
        .catch(function() {
          deferred.resolve(null);
        });

      return deferred.promise;
    }

    return self;
  }
})();
