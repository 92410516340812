angular

  .module('careerKeyCentral')
  .directive('fileModel', [
    '$parse',
    function($parse) {
      return {
        restrict: 'A',
        link: function(scope, element, attrs) {
          var model = $parse(attrs.fileModel);
          var modelSetter = model.assign;

          element.bind('change', function() {
            scope.$apply(function() {
              if (element[0].files[0].name.split('.')[1] != 'csv') {
                modelSetter(scope, 'invalid');
              } else {
                modelSetter(scope, element[0].files[0]);
              }
            });
          });
        }
      };
    }
  ])
  .directive('fileTax', [
    '$parse',
    function($parse) {
      return {
        restrict: 'A',
        link: function(scope, element, attrs) {
          var model = $parse(attrs.fileTax);
          var modelSetter = model.assign;
          // modelSetter(scope, element[0].files[0]);
          element.bind('change', function() {
            scope.$apply(function() {
              modelSetter(scope, element[0].files[0]);
            });
          });
        }
      };
    }
  ])
  .directive('xlsxFileModel', [
    '$parse',
    function($parse) {
      return {
        restrict: 'A',
        link: function(scope, element, attrs) {
          var model = $parse(attrs.xlsxFileModel);
          var modelSetter = model.assign;

          element.bind('change', function() {
            scope.$apply(function() {
              if (element[0].files[0].name.split('.')[1] != 'xlsx') {
                modelSetter(scope, 'invalid');
              } else {
                modelSetter(scope, element[0].files[0]);
              }
            });
          });
        }
      };
    }
  ]);
