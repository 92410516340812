(function() {
  'use strict';

  angular
    .module('app.mailbox')
    .controller('MailViewController', MailViewController);

  MailViewController.$inject = [
    '$stateParams',
    'MailViewService',
    'APP_CONSTANTS',
    'toaster',
    'SessionService'
  ];

  function MailViewController(
    $stateParams,
    MailViewService,
    APP_CONSTANTS,
    toaster,
    SessionService
  ) {
    var vm = this;

    vm.loading = {};

    var userLoginDetail = SessionService.getUser();

    vm.fetchMailDetail = function() {
      vm.loading.mail = true;

      MailViewService.fetchMailDetail(
        userLoginDetail.sponsoredOrganizationId,
        $stateParams.mailId,
        $stateParams.userId
      ).then(
        function(result) {
          vm.mailDetail = result.data.data;
          vm.loading.mail = false;
        },
        function(error) {
          vm.loading.mail = false;
          toaster.pop('error', error.data.message || error.statusText);
        }
      );
    };
    vm.fetchMailDetail();
  }
})();
