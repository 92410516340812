/** =========================================================
 * Module: access-register.js
 * Demo for register account api
 =========================================================*/

(function() {
  'use strict';
  angular
    .module('app.user')
    .controller('InvitationFormController', InvitationFormController);

  InvitationFormController.$inject = [
    '$scope',
    '$http',
    '$state',
    '$stateParams',
    'APP_CONSTANTS',
    'InvitationService',
    'toaster',
    'SessionService',
  ];

  function InvitationFormController(
    $scope,
    $http,
    $state,
    $stateParams,
    APP_CONSTANTS,
    InvitationService,
    toaster,
    SessionService,
  ) {
    var user = SessionService.getUser();

    if (user) $state.go('app.ownerdashboard');

    var vm = this;

    vm.showNameFields = true;
    vm.showPassword = {
      password: false,
      confirmPassword: false
    };

    vm.loading = {
      activate: true
    };
    vm.regex = {
      password: APP_CONSTANTS.REGEX.PASSWORD
    };
    vm.externalUrl = APP_CONSTANTS.EXTERNAL_URL;

    vm.submit = submit;
    vm.viewPassword = viewPassword;
    vm.activateWithGoogle = activateWithGoogle;

    if ($stateParams.invitationId) {
      InvitationService.fetchById($stateParams.invitationId)
        .then(function(result) {
          vm.account = result.data.data;

          if (vm.account.firstName && vm.account.lastName) {
            vm.showNameFields = false;
          }

          if (result.data.data.length === 0) {
            toaster.pop({
              type: 'error',
              body: APP_CONSTANTS.MESSAGE.emailNotFound,
              showCloseButton: true,
              bodyOutputType: 'trustedHtml',
              timeout: 3000,
              onHideCallback: function() {
                vm.loading.activate = false;
                $state.go('login');
              }
            });
          }
          vm.loading.activate = false;
        })
        .catch(function(error) {
          vm.loading.activate = false;
          $state.go('error', {
            message: error.data.message || error.statusText
          });
        });
    }

    function submit() {
      if (!vm.registerForm.$valid) return;
      activate(vm.account);
    }

    function viewPassword(key) {
      vm.showPassword[key] = !vm.showPassword[key];
    }

    function activate(payload) {
      vm.loading.activate = true;

      InvitationService.activate({ account: payload })
        .then(function() {
          vm.loading.activate = true;
          vm.account = {};
          vm.registerForm.$setPristine();

          toaster.pop({
            type: 'success',
            body: APP_CONSTANTS.MESSAGE.activateUser,
            showCloseButton: true,
            bodyOutputType: 'trustedHtml',
            timeout: 3000,
            onHideCallback: function() {
              $state.go('login');
            }
          });
        })
        .catch(function(error) {
          if (error.data.error && error.data.error.code === 400)
            toaster.pop({
              type: 'error',
              body: APP_CONSTANTS.MESSAGE.INVALID_USER_INFORMATION
            });
          else {
            vm.loading.activate = false;
            $state.go('error', {
              message: error.data.message || error.statusText
            });
          }
        });
    }

    function activateWithGoogle(credential) {
            var payload = {
              email: vm.account.email,
              // googleEmail: credential.email,
              agreement: true,
              invitationId: vm.account.invitationId,
              oauth: {
                token: credential.token,
                type: 'google'
              }
            };

            if (vm.showNameFields) {
              const names = credential.name.split(' ');
              payload.firstName = vm.account.firstName || names[0];
              payload.lastName = vm.account.lastName || names[1];
            }

            activate(payload);
    }
  }
})();
