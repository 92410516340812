(function() {
  'use strict';

  angular
    .module('app.sponsoredOrganization')
    .controller(
      'SponsoredOrganizationController',
      SponsoredOrganizationController
    );

  SponsoredOrganizationController.$inject = [
    'APP_CONSTANTS',
    'SessionService',
    '$scope',
    '$state',
    'toaster',
    'OrganizationInfoService',
    '$rootScope'
  ];

  function SponsoredOrganizationController(
    APP_CONSTANTS,
    SessionService,
    $scope,
    $state,
    toaster,
    OrganizationInfoService,
    $rootScope
  ) {
    var vm = this;

    var user = SessionService.getUser();

    if (
      !(
        user.roles.isOrganizationManager ||
        user.roles.isAccountManager ||
        user.roles.isCounselor ||
        user.roles.isTechnicalContact
      )
    ) {
      $state.go('app.ownerdashboard');

      return;
    }

    vm.loading = {};
    vm.usedQuantity = 0;
    vm.totalQuantity = 0;
    vm.organizationInfo = {};

    vm.open = open;
    vm.fetchOrganizationImage = fetchOrganizationImage;
    vm.fetchOrganizationInformation = fetchOrganizationInformation;
    vm.availableLanguages = APP_CONSTANTS.availableLanguages;

    vm.regex = {
      email: APP_CONSTANTS.REGEX.EMAIL
    };

    vm.heading = user.roles.isAccountManager
      ? APP_CONSTANTS.topNavBarMenuItems.sponsoredOrganization
      : APP_CONSTANTS.topNavBarMenuItems.organizationInfo;

    activate();

    function activate() {
      fetchOrganizationImage();
      fetchOrganizationInformation();
      setActiveTab();
    }

    $scope.$on('$destroy', $scope.$on("$stateChangeSuccess", setActiveTab));

    function setActiveTab() {
      vm.activeTabIndex =
        APP_CONSTANTS.ORGANIZATION_TAB_INDEX[$state.current.name];
    }

    function fetchOrganizationInformation() {
      vm.loading.organizationInfo = true;
      OrganizationInfoService.fetchOrganizationInfo(
        user.sponsoredOrganizationId
      )
        .then(function(response) {
          vm.organizationInfo = response.data?.data || {};

          vm.organizationName = vm.organizationInfo.legalName || '';
          vm.loading.organizationInfo = false;
        })
        .catch(function(error) {
          vm.loading.organizationInfo = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function fetchOrganizationImage() {
      vm.loading.organizationImage = true;

      OrganizationInfoService.getOrganizationImage(user.sponsoredOrganizationId)
        .then(function(response) {
          var data = _.get(response, 'data.data', {});

          vm.profileImage = _.get(data, 'profileImage', '');
          vm.profileImage = vm.profileImage
            ? vm.profileImage + '?=' + $rootScope.moment().unix()
            : APP_CONSTANTS.defaultOrganizationImage;
          vm.loading.organizationImage = false;
        })
        .catch(function(error) {
          vm.loading.organizationImage = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }
  }
})();
