(function() {
  'use strict';
  angular
    .module('app.shared')
    .controller('UploadImageController', UploadImageController);
  UploadImageController.$inject = [
    '$scope',
    '$uibModalInstance',
    'ConfigurePluginService',
    'toaster',
    'APP_CONSTANTS',
    '$rootScope',
    'plugin'
  ];

  function UploadImageController(
    $scope,
    $uibModalInstance,
    ConfigurePluginService,
    toaster,
    APP_CONSTANTS,
    $rootScope,
    plugin
  ) {
    var vm = this;

    vm.plugin = plugin;
    vm.loading = false;
    vm.wrongImageFormat = false;

    vm.submit = submit;
    vm.cancel = cancel;

    $scope.validateImage = function(files) {
      var ext = files[0].name.match(/\.(.+)$/)[1];

      if (
        angular.lowercase(ext) === 'jpg' ||
        angular.lowercase(ext) === 'jpeg' ||
        angular.lowercase(ext) === 'png' ||
        angular.lowercase(ext) === 'gif'
      ) {
        vm.wrongImageFormat = false;
        handleFileSelect(files);
      } else {
        vm.wrongImageFormat = true;
      }
    };

    function handleFileSelect(files) {
      var file = files[0];
      var reader = new FileReader();

      reader.onload = function(evt) {
        $scope.$apply(function() {
          vm.image = evt.target.result;
          vm.croppedImage = evt.target.result;
        });
      };
      if (file) reader.readAsDataURL(file);
    }

    function saveImage() {
      var imageData = {};

      imageData.base64 = vm.croppedImage;
      vm.loading = true;

      ConfigurePluginService.uploadImage(imageData, vm.plugin).then(
        function(response) {
          vm.loading = false;
          ConfigurePluginService.setLogoUrl(
            response.data.data + '?=' + $rootScope.moment().unix()
          );

          toaster.pop('success', APP_CONSTANTS.MESSAGE.successfullyUpdated);
          $uibModalInstance.close();
        },
        function(error) {
          vm.loading = false;
          if (error.status === 413) {
            toaster.pop('error', APP_CONSTANTS.MESSAGE.imageSizeLarge);
          } else {
            toaster.pop('error', error.data.message || error.statusText);
          }
          vm.croppedImage =
            ConfigurePluginService.getLogoUrl() ||
            'app/common/img/organization.png';
        }
      );
    }

    angular
      .element(document.querySelector('#fileInput'))
      .on('change', handleFileSelect);

    function submit(cropUrl) {
      if (cropUrl.substring(0, 10) === 'data:image' && cropUrl.length > 40) {
        vm.wrongImageFormat = false;
        vm.croppedImage = cropUrl;
        saveImage();
      } else {
        $scope.$apply(function() {
          vm.wrongImageFormat = true;
        });

        return;
      }
    }

    function cancel() {
      $uibModalInstance.dismiss('cancel');
    }
  }
})();
