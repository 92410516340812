(function() {
  'use strict';

  angular
    .module('app.mailbox')
    .controller('MailboxController', MailboxController);

  MailboxController.$inject = [
    'SessionService',
    'APP_CONSTANTS',
    '$rootScope',
    '$state',
    'toaster'
  ];

  function MailboxController(
    SessionService,
    APP_CONSTANTS,
    $rootScope,
    $state,
    toaster
  ) {
    var vm = this;

    vm.loadingBreadCrumb = false;
    var userLoginDetail = SessionService.getUser();

    vm.isSuperAdmin = userLoginDetail.roles.isSuperAdmin;
    vm.letsConnectOptions = _.filter(APP_CONSTANTS.letsConnectOptions, function (option) {
      return option.messageType === APP_CONSTANTS.CUSTOM
        || (!vm.isSuperAdmin
          && (userLoginDetail.subscriptionType === APP_CONSTANTS.BOTH
            || option.subscriptionType === APP_CONSTANTS.BOTH
            || option.subscriptionType === userLoginDetail.subscriptionType
          ));
    });

    vm.letsConnect = function (option) {
      let params = option || _.find(APP_CONSTANTS.letsConnectOptions, { messageType: APP_CONSTANTS.CUSTOM });
      let stateName = params.subscriptionType === APP_CONSTANTS.CDP ? 'cdp' : 'compose';

      $state.go('app.mailbox.' + stateName, params);
    }

    activate();

    function activate() {
      vm.folders = [
        // {name: 'Inbox',   folder: 'inbox',   alert: 42, icon: 'fa-inbox' },
        // {name: 'Starred', folder: 'starred', alert: 10, icon: 'fa-star' },
        { name: 'Sent', folder: 'sent', alert: 0, icon: 'fa-paper-plane-o' }
        // {name: 'Draft',   folder: 'draft',   alert: 5,  icon: 'fa-edit' },
        // {name: 'Trash',   folder: 'trash',   alert: 0,  icon: 'fa-trash'}
      ];
      //
      // vm.labels = [
      //  {name: 'Red',     color: 'danger'},
      //  {name: 'Pink',    color: 'pink'},
      //  {name: 'Blue',    color: 'info'},
      //  {name: 'Yellow',  color: 'warning'}
      // ];

      vm.mail = {
        cc: false,
        bcc: false
      };
      // Mailbox editr initial content
      vm.content = '<p>Type something..</p>';

      // ui select
    }
  }
})();
