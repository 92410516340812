(function() {
  'use strict';

  angular
    .module('app.mailbox')
    .controller('CustomMessageController', CustomMessageController);

  CustomMessageController.$inject = [
    '$stateParams',
    'APP_CONSTANTS',
    'CustomMessageService',
    'GroupService',
    'SendEmailToAllService',
    'SessionService',
    'toaster',
    '$state',
    'ngDialog'
  ];

  function CustomMessageController(
    $stateParams,
    APP_CONSTANTS,
    CustomMessageService,
    GroupService,
    SendEmailToAllService,
    SessionService,
    toaster,
    $state,
    ngDialog
  ) {
    var vm = this;

    vm.loading = {};
    vm.customMessage = {};
    vm.customMessage.subject = '';
    vm.customMessage.message = '';
    vm.customMessage.messageType = $stateParams.messageType || APP_CONSTANTS.CUSTOM;
    vm.customMessage.discoverer = $stateParams.emails || [];
    vm.availablediscoverers = vm.customMessage.discoverer;

    let userLoginDetail = SessionService.getUser();
    vm.isSuperAdmin = userLoginDetail.roles.isSuperAdmin;
    let eventCategories = [
      APP_CONSTANTS.EVENT_CATEGORY.NOT_STARTED,
      APP_CONSTANTS.EVENT_CATEGORY.NOT_COMPLETED,
      APP_CONSTANTS.EVENT_CATEGORY.COMPLETED_ASSESSMENT,
      APP_CONSTANTS.EVENT_CATEGORY.STARTED_PROFILE,
      APP_CONSTANTS.EVENT_CATEGORY.RETURNED_TO_PROFILE,
    ];
    let scorePatterns = [
      APP_CONSTANTS.DIFFERENTIATED_CONSISTENT,
      APP_CONSTANTS.DIFFERENTIATED_INCONSISTENT,
      APP_CONSTANTS.UNDIFFERENTIATED,
    ];

    vm.fetchDiscoverersBySponsoredOrg = function (key) {
      if (!key) return;

      let eventCategory = scorePatterns.indexOf($stateParams.messageType) > -1
        && [
          APP_CONSTANTS.EVENT_CATEGORY.COMPLETED_ASSESSMENT,
          APP_CONSTANTS.EVENT_CATEGORY.STARTED_PROFILE,
          APP_CONSTANTS.EVENT_CATEGORY.RETURNED_TO_PROFILE,
        ]
        || eventCategories.indexOf($stateParams.messageType) > -1
        && $stateParams.messageType;

      GroupService.fetchdiscoverer(
        key,
        userLoginDetail.sponsoredOrganizationId,
        eventCategory
      ).then(function (response) {
        let discoverers = response && response.data && response.data.data;
        vm.availablediscoverers = _.map(discoverers, 'email');
      });
    }

    vm.sendMessage = function () {
      if (!vm.message.$valid) return;
      if (!vm.customMessage.discoverer.length) {
        return toaster.pop('warning', APP_CONSTANTS.MESSAGE.messageEmailValidation);
      }

      CustomMessageService.validateEmail(vm.customMessage.discoverer).then(
        function () {
          vm.loading.message = true;
          CustomMessageService.sendCustomMessage(vm.customMessage).then(
            function (response) {
              vm.loading.message = false;
              let deliveryStatus = response.data || {};

              if (!deliveryStatus.failure || !deliveryStatus.failure.length) {
                toaster.pop('success', APP_CONSTANTS.MESSAGE.messageSent);
                $state.go('app.mailbox.folder');
                return;
              }

              ngDialog.openConfirm({
                template: 'app/letsConnect/views/deliveryStatusPopup.html',
                data: deliveryStatus
              }).finally(function () {
                $state.go('app.mailbox.folder');
              });
            },
            function (error) {
              vm.loading.message = false;
              toaster.pop('error', error.data.message || error.statusText);
            }
          );
        },
        function (error) {
          ngDialog.openConfirm({
            template: 'app/letsConnect/views/invalidEmailPopup.html',
            data: {
              invalidEmail: error
            }
          });
        }
      );
    };

    function fetchTemplates() {
      let template = $stateParams.template;
      if (!template && $stateParams.messageType) {
        let letsConnectOption = _.find(APP_CONSTANTS.letsConnectOptions, {
          messageType: $stateParams.messageType,
          subscriptionType: APP_CONSTANTS.CKD,
        });
        if (!letsConnectOption) return;

        template = letsConnectOption.template;
      }
      if (!template || template === APP_CONSTANTS.CUSTOM) return;

      vm.loading.message = true;
      SendEmailToAllService.fetchEmailTemplates(template)
        .then(function (response) {
          vm.customMessage.subject = response.data.subject;
          vm.customMessage.message = response.data.message;
          vm.customMessage.template = template;
          vm.loading.message = false;
        })
        .catch(function (error) {
          toaster.pop('error', error.data && error.data.message || error.statusText);
        })
        .finally(function () {
          vm.loading.message = false;
        });
    };

    fetchTemplates();
  }
})();
