(function() {
  'use strict';
  angular
    .module('app.shared')
    .factory('UserProfileService', UserProfileService);

  UserProfileService.$inject = ['APP_CONSTANTS', 'ApiCaller', '$q', '$filter'];

  function UserProfileService(APP_CONSTANTS, ApiCaller, $q, $filter) {
    var self = this;

    self.profileImage = '';

    self.fetchUser = fetchUser;
    self.updateUser = updateUser;
    self.uploadImage = uploadImage;
    self.setProfileImage = setProfileImage;
    self.getProfileImage = getProfileImage;
    self.linkGoogleAccount = linkGoogleAccount;

    function fetchUser(userId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.userSingle,
        APP_CONSTANTS.rootUrl,
        userId
      );

      ApiCaller.apiGet(url).then(
        function(result) {
          deferred.resolve(result);
        },
        function(err) {
          deferred.reject(err);
        }
      );

      return deferred.promise;
    }

    function uploadImage(imageData, userId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.userImageUpload,
        APP_CONSTANTS.rootUrl,
        userId
      );

      ApiCaller.apiPut(url, imageData).then(
        function(result) {
          deferred.resolve(result);
        },
        function(err) {
          deferred.reject(err);
        }
      );

      return deferred.promise;
    }

    function updateUser(user) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.userSingle,
        APP_CONSTANTS.rootUrl,
        user.id
      );

      ApiCaller.apiPut(url, user).then(
        function(result) {
          deferred.resolve(result);
        },
        function(err) {
          deferred.reject(err);
        }
      );

      return deferred.promise;
    }

    function setProfileImage(url) {
      self.profileImage = url;
    }

    function getProfileImage() {
      return self.profileImage;
    }

    function linkGoogleAccount(userId, googleToken) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.linkGoogleAccount,
        APP_CONSTANTS.rootUrl,
        userId
      );

      ApiCaller.apiPut(url, { token: googleToken }).then(
        function(result) {
          deferred.resolve(result);
        },
        function(err) {
          deferred.reject(err);
        }
      );

      return deferred.promise;
    }

    return self;
  }
})();
