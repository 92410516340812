(function() {
  'use strict';
  angular
    .module('careerKeyCentral')
    .controller('ErrorController', ErrorController);

  ErrorController.$inject = ['$stateParams'];

  function ErrorController($stateParams) {
    var vm = this;

    vm.message = $stateParams.message;
  }
})();
