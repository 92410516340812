(function() {
  'use strict';

  angular
    .module('app.customerAccount')
    .controller('AddonsController', AddonsController);

  AddonsController.$inject = [
    '$uibModal',
    '$state',
    'APP_CONSTANTS',
    'toaster',
    '$uibModalInstance',
    'NewBillingService',
    'data',
    'ngDialog',
    'BillingInfoService'
  ];
  function AddonsController(
    $uibModal,
    $state,
    APP_CONSTANTS,
    toaster,
    $uibModalInstance,
    NewBillingService,
    data,
    ngDialog,
    BillingInfoService
  ) {
    var vm = this;

    vm.addons = [];
    vm.loading = {};
    vm.addonsQuantity = 1;
    vm.selectedAddon = {};
    vm.addonInfo = {};
    vm.noPaymentOrBillingDetail = false;
    vm.noActiveSubscriptions =
      _.isEmpty(data.subscription) ||
      data.subscription.actualStatus !== APP_CONSTANTS.SUBSCRIPTION_STATUS.ACTIVE ||
      data.subscription.type.toLowerCase() !==
        APP_CONSTANTS.SUBSCRIPTION_TYPE.PREPAID
        ? true
        : false;
    vm.subscription = !vm.noActiveSubscriptions ? data.subscription : {};
    vm.constants = {
      decimalFixedValue: APP_CONSTANTS.decimalFixedValue,
      addAddonsMessageTop: APP_CONSTANTS.addAddonsMessageTop,
      addAddonsMessageBottom: APP_CONSTANTS.addAddonsMessageBottom,
      noPaymentOrBillingDetail: APP_CONSTANTS.noPaymentOrBillingDetail
    };

    vm.cancel = cancel;
    vm.submit = submit;
    vm.handleAddonSelect = handleAddonSelect;

    activate();
    getBillingDetail();

    function activate() {
      if (_.isEmpty(data.billingDetail || _.isEmpty(data.paymentDetail)))
        vm.noPaymentOrBillingDetail = true;
      else if (!vm.noActiveSubscriptions) fetchAddons();
    }

    function getBillingDetail() {
      NewBillingService.billingDetail()
        .then(function(response) {
          vm.loading.billingDetail = false;
          vm.billingDetail = _.get(response, 'data.data', {});
          vm.emptyBillingDetail = _.isEmpty(vm.billingDetail) ? true : false;
          vm.autoCollection = vm.billingDetail.autoCollection;
        })
        .catch(function(error) {
          vm.loading.billingDetail = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function handleAddonSelect(addon) {
      vm.addonQuantityInvalid = false;
      if (!vm.addonsQuantity) {
        vm.addonQuantityInvalid = true;

        return;
      }
      vm.loading.addons = true;
      vm.addonInfo = {
        addon: addon,
        planId: vm.subscription.planId
      };
      vm.selectedAddon = {
        addonId: addon.id,
        subscriptionId: vm.subscription.id
      };

      vm.addon = addon;
      var customerInfo = {
        taxability: addon.taxable
          ? APP_CONSTANTS.TAXABILITY.TAXABLE
          : APP_CONSTANTS.TAXABILITY.EXEMPT
      };

      vm.billingInfo = {};
      vm.billingInfo.country = {
        countryCode: vm.billingDetail.country,
        name: vm.billingDetail.state_code
      };
      vm.billingInfo.city = vm.billingDetail.city;
      vm.billingInfo.zipCode = vm.billingDetail.zip;
      vm.billingInfo.addressLine1 = vm.billingDetail.line1;
      vm.billingInfo.lastName = vm.billingDetail.last_name;
      vm.billingInfo.firstName = vm.billingDetail.first_name;
      BillingInfoService.getAddonTaxEstimate(
        vm.addonInfo.planId,
        addon.id,
        vm.billingInfo,
        customerInfo,
        vm.addonsQuantity
      )
        .then(function(result) {
          vm.quantity = result.data.data.quantity;
          vm.taxAmount = result.data.data.tax_amount;
          vm.totalAmount =
            parseFloat(result.data.data.amount) + parseFloat(vm.taxAmount);
          vm.loading.addons = false;
        })
        .catch(function(err) {
          vm.loading.addons = false;

          if (err.data.error && err.data.error.code === 400)
            toaster.pop({
              type: 'error',
              body: APP_CONSTANTS.MESSAGE.INVALID_BILLING_INFORMATION,
              toasterId: 'billing'
            });
          else
            toaster.pop({
              type: 'error',
              body: err.data.message || err.statusText,
              toasterId: 'billing'
            });
        });
    }

    function fetchAddons() {
      vm.loading.addons = true;

      NewBillingService.getAddons(vm.subscription.planId)
        .then(function(response) {
          vm.loading.addons = false;
          vm.addons = response.data.data;
          vm.emptyAddons = _.isEmpty(vm.addons) ? true : false;
          handleAddonSelect(vm.addons[0]);
        })
        .catch(function(error) {
          vm.loading.addons = false;
          toaster.pop('error', error.data.message);
        });
    }

    function applyAddon(selectedAddon) {
      vm.addonQuantityInvalid = false;
      if (!vm.addonsQuantity) {
        vm.addonQuantityInvalid = true;

        return;
      }
      selectedAddon.quantity = vm.addonsQuantity;

      NewBillingService.applyAddon(selectedAddon)
        .then(function() {
          vm.loading.addons = false;
          closePopup();
          toaster.pop('success', APP_CONSTANTS.MESSAGE.addonsApplySuccess);
        })
        .catch(function() {
          vm.loading.addons = false;
          toaster.pop({
            type: 'error',
            body: APP_CONSTANTS.MESSAGE.addonsApplyFailure,
            toasterId: 'applyAddons'
          });
        });
    }

    function submit() {
      vm.invalid = false;

      if (_.isEmpty(vm.selectedAddon)) {
        vm.invalid = true;

        return;
      }

      if (!vm.addonsQuantity) {
        vm.addonQuantityInvalid = true;

        return;
      }

      ngDialog
        .openConfirm({
          template: 'addAddonConformDialog',
          data: data
        })
        .then(
          function() {
            ngDialog.close();
            vm.loading.addons = true;
            applyAddon(vm.selectedAddon);
          },
          function() {
            ngDialog.close();
          }
        );
    }

    function closePopup() {
      $uibModalInstance.close('closed');
    }

    function cancel() {
      $uibModalInstance.dismiss('cancel');
    }
  }
})();
