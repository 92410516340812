(function() {
  'use strict';

  angular
    .module('careerKeyCentral')
    .directive('customPagination', customPagination);
  customPagination.$inject = ['$compile'];

  function customPagination($compile) {
    return {
      restrict: 'A',
      link: function($scope, $element, $attrs) {
        var updatePagination = function() {
          setTimeout(function() {
            var pageSize =
              typeof $attrs.pageSize != 'undefined'
                ? parseInt($attrs.pageSize)
                : 10;
            var pageNum =
              typeof $attrs.pageNum != 'undefined'
                ? parseInt($attrs.pageNum)
                : 1;
            var totalRecords =
              typeof $attrs.totalRecords != 'undefined'
                ? parseInt($attrs.totalRecords)
                : 0;
            var totalPage = Math.ceil(totalRecords / pageSize);
            var endRecord =
              pageNum * pageSize < totalRecords
                ? pageNum * pageSize
                : totalRecords;
            var rowCount =
              endRecord < totalRecords
                ? pageSize
                : totalRecords - (pageNum - 1) * pageSize;
            var startRecord = endRecord - rowCount + 1;
            var clickHandler = $attrs.clickHandler;
            if (totalPage > 0) {
              var paginations = getPaginationPages(pageNum, totalPage);
              $element.html(
                '<div class="dataTables_info">\nShowing ' +
                  startRecord +
                  ' to ' +
                  endRecord +
                  ' of ' +
                  totalRecords +
                  ' entries\n</div>\n<div class="dataTables_paginate paging_simple_numbers" id="paginate">\n<a class="paginate_button previous ' +
                  (pageNum == 1 ? 'disabled' : '') +
                  '"\nng-click="' +
                  clickHandler +
                  '" data-page-num="' +
                  (pageNum - 1) +
                  '">Previous</a>\n<span class="page-numbers"></span>\n<a class="paginate_button next ' +
                  (pageNum == totalPage ? 'disabled' : '') +
                  '"\nng-click="' +
                  clickHandler +
                  '" data-page-num="' +
                  (pageNum + 1) +
                  '">Next</a>\n</div>\n'
              );

              var tempContent = '';
              for (var i = 0; i < paginations.length; i++) {
                if (paginations[i].clickable) {
                  tempContent +=
                    '<a class="paginate_button ' +
                    (paginations[i].currentPage ? 'current' : '') +
                    '" ng-click="' +
                    clickHandler +
                    '" data-page-num="' +
                    paginations[i].label +
                    '"> ' +
                    paginations[i].label +
                    '</a>';
                } else {
                  tempContent += '<span class="ellipsis">\u2026</span>';
                }
              }
              $element.find('.page-numbers').html(tempContent);

              var compileElement = $compile($element.html())($scope);
              $element.html(compileElement);
            } else {
              $element.html('');
            }
          }, 300);
        };

        var getPaginationPages = function(pageNum, totalPage) {
          var paginations = [];
          var paginationNum = 1;
          paginations.push({
            label: paginationNum,
            currentPage: pageNum == 1 ? true : false,
            clickable: true
          });
          paginationNum++;

          if (pageNum - 1 > paginationNum) {
            paginations.push({
              label: '...',
              currentPage: false,
              clickable: false
            });
          }

          paginationNum = pageNum - 1 > 1 ? pageNum - 1 : paginationNum;
          var nextThreePageNum =
            paginationNum + 3 < totalPage ? paginationNum + 3 : totalPage;
          while (paginationNum <= nextThreePageNum) {
            paginations.push({
              label: paginationNum,
              currentPage: paginationNum == pageNum ? true : false,
              clickable: true
            });
            paginationNum++;
          }

          if (paginationNum <= totalPage) {
            if (paginationNum != totalPage) {
              paginations.push({
                label: '...',
                currentPage: false,
                clickable: false
              });
            }

            paginations.push({
              label: totalPage,
              currentPage: pageNum == totalPage ? true : false,
              clickable: true
            });
          }

          return paginations;
        };

        $attrs.$observe('refreshTimeStamp', updatePagination, true);
      }
    };
  }
})();
