(function() {
  'use strict';

  angular
    .module('app.customerAccount')
    .controller('OrganizationController', OrganizationController);

  OrganizationController.$inject = [
    '$state',
    '$uibModal',
    'RegistrationService',
    'OrganizationService',
    '$scope',
    '$filter',
    'SessionService',
    'toaster',
    'ngDialog',
    'APP_CONSTANTS',
    '$rootScope',
    'FileSaver'
  ];
  function OrganizationController(
    $state,
    $uibModal,
    RegistrationService,
    OrganizationService,
    $scope,
    $filter,
    SessionService,
    toaster,
    ngDialog,
    APP_CONSTANTS,
    $rootScope,
    FileSaver
  ) {
    var vm = this;

    vm.loading = {};
    vm.organizationList = [];
    vm.export_to_csv = APP_CONSTANTS.export_to_csv;
    vm.scheduledcancellrganizationation = APP_CONSTANTS.scheduledcancellation;

    vm.dtOptions = {
      order: [],
      columnDefs: [
        {
          orderable: false,
          targets: [1, 9, 10, 11]
        }
      ]
    };

    var modalInstance = '';
    var customerDetails = SessionService.getUser();

    activate();

    function activate() {
      $scope.$parent.vm.setActiveTab();
      fetchOrganizationList();

      function fetchOrganizationList() {
        vm.loading.sponsoredOrg = true;

        OrganizationService.fetchSponsoredOrganizations(
          customerDetails.customerId
        )
          .then(function(response) {
            vm.loading.sponsoredOrg = false;
            vm.sponsoredOrganizationId =
              customerDetails.sponsoredOrganizationId;
            var organizationList = response.data.data;

            vm.organizationList = [];
            organizationList &&
              organizationList.forEach(function(data) {
                data.subscriptions = [];
                if (data.ckdSubscriptions) {
                  data.subscriptions = data.subscriptions.concat(data.ckdSubscriptions);
                }
                if (data.cdpSubscriptions) {
                  data.subscriptions = data.subscriptions.concat(data.cdpSubscriptions);
                }
                data.expandAccordion = false;
                vm.organizationList.push(data);
              });

            vm.organizationList = _.sortBy(vm.organizationList, [
              function(org) {
                return org.organizationName.toLowerCase();
              }
            ]);
            var ownOrganization = _.remove(vm.organizationList, function(org) {
              return (
                org.organizationId === customerDetails.sponsoredOrganizationId
              );
            });

            vm.organizationList.unshift(ownOrganization[0]);

            vm.currentOrganizationId = customerDetails.sponsoredOrganizationId;
          })
          .catch(function(error) {
            vm.loading.sponsoredOrg = false;
            toaster.pop('error', error.data.message || error.statusText);
          });
      }


      vm.getHeaders = function() {
        var headerArray = [
          'Organization Name',
          'Subscription',
          'First Name',
          'Last Name',
          'Status',
          'Last Sign in',
          'Audience',
          'Usage'
        ];

        return headerArray;
      };

      vm.exportToCSV = function() {
        var organizationList = '';
        var headerArray =
          'Organization Name, Subscription, First Name, Last Name, Status, Last Sign in, Audience, Usage';

        organizationList += headerArray + '\r\n';

        vm.organizationList.filter(function(org) {
          organizationList += org.organizationName + ', ';
          organizationList +=
            (org.subscriptionName.includes('–')
              ? _.replace(org.subscriptionName, '–', '-')
              : org.subscriptionName) + ', ';
          organizationList += (org.firstName ? org.firstName : '-') + ', ';
          organizationList += (org.lastName ? org.lastName : '-') + ', ';
          organizationList += org.subscriptionStatus + ', ';
          organizationList +=
            (org.lastLoggedIn
              ? $rootScope.moment(org.lastLoggedIn).format('L')
              : '-') + ', ';
          organizationList += (org.audience ? org.audience : '-') + ', ';
          organizationList += (org.usage ? org.usage : 0) + '\r\n';
        });

        var refreshTimeStamp = $filter('date')(
          new Date(),
          'MM-dd-yyyy HH:mm:ss'
        ).replace(/:| |-|/g, '');
        var fileName =
          'CareerKeySponsoredOrganization' + refreshTimeStamp + '.csv';
        var data = new Blob([organizationList], {
          type: 'text/plain;charset=utf-8'
        });

        FileSaver.saveAs(data, fileName);

        return organizationList;
      };

      vm.grantOrRevokeOrganization = function(user, task) {
        var status;
        var newUserData = {};

        angular.copy(user, newUserData);

        switch (task) {
          case 'ckdWidgetAccess':
            status = newUserData.ckdWidgetAccess;
            user.ckdWidgetAccess = !user.ckdWidgetAccess;
            break;
          case 'organizationStatus':
            status = newUserData.organizationStatus;
            user.organizationStatus = !user.organizationStatus;
            break;
          default:
            break;
        }

        var data = {};
        var operation = status
          ? APP_CONSTANTS.ACCESS.GRANT
          : APP_CONSTANTS.ACCESS.REVOKE;
        var message =
          task === 'organizationStatus'
            ? APP_CONSTANTS.CONFIRMATION.GRANT_REVOKE_ACCESS_ORGANIZATION
            : APP_CONSTANTS.CONFIRMATION.GRANT_REVOKE_ACCESS_CKD_WIDGET;

        data.message = $filter('sprintf')(
          message,
          operation,
          user.organizationName
        );

        ngDialog
          .openConfirm({
            template: 'app/shared/views/partials/ngDialogConfirmation.html',
            data: data
          })
          .then(
            function() {
              ngDialog.close();
              vm.loading.users = true;

              if (task === 'organizationStatus') {
                OrganizationService.grantOrRevokeOrganizationStatus(
                  user.organizationId,
                  task
                ).then(
                  function(response) {
                    vm.loading.users = false;
                    user.organizationStatus = response.data[0].status;
                    toaster.pop('success', APP_CONSTANTS.MESSAGE.onSuccess);
                  },
                  function(error) {
                    vm.loading.users = false;
                    toaster.pop(
                      'error',
                      error.data.message || error.statusText
                    );
                  }
                );
              } else if (task === 'ckdWidgetAccess') {
                OrganizationService.grantOrRevokeCkdWidgetAccess(
                  user.organizationId
                ).then(
                  function(response) {
                    vm.loading.users = false;
                    user.ckdWidgetAccess = response.data[0].ckdWidgetAccess;
                    toaster.pop('success', APP_CONSTANTS.MESSAGE.onSuccess);
                  },
                  function(error) {
                    vm.loading.users = false;
                    toaster.pop(
                      'error',
                      error.data.message || error.statusText
                    );
                  }
                );
              }
            },
            function() {
              ngDialog.close();
            }
          );
      };

      vm.loginAsSponsoredOrganization = function(sponsoredOrganization) {
        vm.loading.sponsoredOrg = true;
        OrganizationService.getOrganizationLoggedInDetails(
          sponsoredOrganization.organizationId
        ).then(
          function() {
            vm.loading.sponsoredOrg = false;
            $state.go('login');
          },
          function(error) {
            vm.loading.sponsoredOrg = false;
            toaster.pop('error', error.data.message || error.statusText);
          }
        );
      };

      // TODO take the add organization popup to a new page
      vm.addOrganization = function(size) {
        var modalInstance = $uibModal.open({
          templateUrl: '/addOrganizationModal.html',
          controller: ModalInstanceCtrl,
          controllerAs: 'ctrl',
          size: size
        });

        modalInstance.result
          .then(function() {
            fetchOrganizationList();
          })
          .catch(function() {
            modalInstance = '';
          });
      };

      vm.updateSponsoredOrganizationInfo = function(sponsoredOrg) {
        if (!modalInstance) {
          modalInstance = $uibModal.open({
            templateUrl:
              'app/v2/customerAccount/views/partials/updateSponsoredOrganization.html',
            controller: 'UpdateSponsoredOrganizationController',
            controllerAs: 'vm',
            resolve: {
              sponsoredOrg: function() {
                return sponsoredOrg;
              }
            }
          });

          modalInstance.result
            .then(function() {
              modalInstance = '';
              fetchOrganizationList();
            })
            .catch(function() {
              modalInstance = '';
            });
        }
      };

      vm.toggleAccordion = function(index) {
        if (
          vm.organizationList[index] &&
          vm.organizationList[index].subscriptions.length > 1
        ) {
          vm.organizationList[index].expandAccordion = !vm.organizationList[
            index
          ].expandAccordion;
        }
      };

      // Please note that $uibModalInstance represents a modal window (instance) dependency.
      // It is not the same as the $uibModal service used above.

      ModalInstanceCtrl.$inject = [
        '$scope',
        '$uibModalInstance',
        'APP_CONSTANTS',
        'SessionService',
        'toaster'
      ];
      function ModalInstanceCtrl(
        $scope,
        $uibModalInstance,
        APP_CONSTANTS,
        SessionService,
        toaster
      ) {
        var vm = this;

        vm.plans = [];
        vm.cdpPlan = [];
        vm.ckdPlan = [];
        vm.loading = {};
        vm.formData = {};
        vm.countries = [];
        vm.submitted = '';
        vm.submit = submit;
        vm.emailIsMatch = true;
        vm.selectedCountry = '';
        vm.fetchPlans = fetchPlans;
        vm.setLocale = setLocale;
        vm.loading.addSponsoredOrg = true;
        vm.checkEmailMatch = checkEmailMatch;
        vm.checkDuplicateEmail = checkDuplicateEmail;
        vm.availableLanguages = APP_CONSTANTS.availableLanguages;

        vm.regex = {
          email: APP_CONSTANTS.REGEX.EMAIL
        };

        fetchPlans();

        function fetchPlans() {
          OrganizationService.fetchSubscriptions().then(
            function(result) {
              vm.loading.addSponsoredOrg = false;
              var subscriptions = result.data.data;

              subscriptions.forEach(function(plan) {
                vm.plans.push(plan);
                if (plan.assessmentType === APP_CONSTANTS.CDP) {
                  vm.cdpPlan.push(plan);
                } else {
                  vm.ckdPlan.push(plan);
                }
              });
            },
            function(error) {
              vm.loading.addSponsoredOrg = false;
              toaster.pop('error', error.data.message || error.statusText);
            }
          );
        }

        function setLocale() {
          vm.formData.language =
            _.get(vm.formData, 'ckdSubscription.locale.language', '') ||
            _.get(vm.formData, 'cdpSubscription.locale.language', '');
          vm.formData.country =
            _.get(vm.formData, 'ckdSubscription.locale.country', '') ||
            _.get(vm.formData, 'cdpSubscription.locale.country', '');
        }

        function checkDuplicateEmail() {
          if (!vm.formData.email) return;

          OrganizationService.checkEmail(vm.formData.email).then(
            function() {
              vm.duplicateEmail = false;
            },
            function() {
              vm.duplicateEmail = true;
            }
          );
        }

        function checkEmailMatch() {
          if (
            !vm.formData.email ||
            !vm.formData.confirmEmail ||
            vm.formData.email !== vm.formData.confirmEmail
          ) {
            vm.emailIsMatch = false;
          } else {
            vm.emailIsMatch = true;
          }
        }

        function submit(form) {
          vm.form = form;
          vm.submitted = form.$submitted;

          if (form.$invalid || vm.duplicateEmail || !vm.emailIsMatch) return;

          vm.loading.addSponsoredOrg = true;

          var organization = {
            phone: form.phone.$modelValue.replace(/[^0-9]+/g, ''),
            title: form.title.$modelValue,
            email: form.email.$modelValue,
            country: form.country.$modelValue,
            lastName: form.lastName.$modelValue,
            language: form.language.$modelValue,
            firstName: form.firstName.$modelValue,
            confirmEmail: form.confirmEmail.$modelValue,
            ckdSubscriptionId: _.get(
              form,
              'ckdSubscription.$modelValue.id',
              ''
            ),
            cdpSubscriptionId: _.get(
              form,
              'cdpSubscription.$modelValue.id',
              ''
            ),
            organizationName: form.organizationName.$modelValue
          };

          OrganizationService.addSponsoredOrganization(
            organization,
            customerDetails.customerId
          )
            .then(function() {
              vm.loading.addSponsoredOrg = false;
              vm.closePopup();
              toaster.pop({
                type: 'success',
                body: APP_CONSTANTS.MESSAGE.successfullyCreatedSponoredOrg
              });
            })
            .catch(function(error) {
              vm.loading.addSponsoredOrg = false;
              toaster.pop('error', error.data.message || error.statusText);
            });
        }

        vm.closePopup = function() {
          $uibModalInstance.close('closed');
        };

        vm.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    }
  }
})();
