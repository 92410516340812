(function() {
  'use strict';

  angular.module('app.shared').factory('NewProductService', NewProductService);

  NewProductService.$inject = ['APP_CONSTANTS', 'ApiCaller', '$filter', '$q'];

  function NewProductService(APP_CONSTANTS, ApiCaller, $filter, $q) {
    var self = this;

    self.addNewProduct = addNewProduct;
    self.fetchCountries = fetchCountries;
    self.fetchAllAssessments = fetchAllAssessments;

    function fetchAllAssessments() {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.allAssessments,
        APP_CONSTANTS.rootUrl
      );

      ApiCaller.apiGet(url)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function fetchCountries(name) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.countries,
        APP_CONSTANTS.rootUrl,
        name
      );

      ApiCaller.apiGet(url).then(
        function(result) {
          deferred.resolve(result);
        },
        function(err) {
          deferred.reject(err);
        }
      );

      return deferred.promise;
    }

    function addNewProduct(data) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.productCollection,
        APP_CONSTANTS.rootUrl
      );

      ApiCaller.apiPost(url, data)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    return self;
  }
})();
