(function() {
  'use strict';
  angular.module('app.counselor').config(state);

  state.$inject = ['$stateProvider', 'RouteHelpersProvider'];

  function state($stateProvider, helper) {
    $stateProvider.state('app.counselordashboard', {
      url: '/counselor/dashboard',
      title: 'Counselor Dashboard',
      templateUrl: helper.basepath('counselor/views/counselorDashboard.html'),
      controller: 'CounselorDashboardController',
      controllerAs: 'vm',
      secured: true,
      resolve: helper.resolveFor('icons', 'ui.select')
    });
  }
})();
