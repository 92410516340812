(function() {
  'use strict';
  angular
    .module('app.user')
    .controller('PurchaseOrderController', PurchaseOrderController);

  PurchaseOrderController.$inject = [
    '$state',
    'PurchaseOrderService',
    'APP_CONSTANTS',
    'toaster'
  ];

  function PurchaseOrderController(
    $state,
    PurchaseOrderService,
    APP_CONSTANTS,
    toaster
  ) {
    var vm = this;

    vm.submit = submit;

    function submit(valid) {
      if (!valid) {
        vm.formSubmit = true;

        return;
      }
      vm.loading = true;
      PurchaseOrderService.register(vm.poNumber).then(
        function() {
          vm.loading = false;
          PurchaseOrderService.destroyRegistrationData();
          toaster.pop({
            type: 'info',
            title: APP_CONSTANTS.successHeader,
            body: APP_CONSTANTS.registerSuccessMessage,
            showCloseButton: true,
            bodyOutputType: 'trustedHtml',
            timeout: 150000,
            onHideCallback: function() {
              $state.go('login');
            }
          });
        },
        function(error) {
          vm.loading = false;
          toaster.pop('error', error.data.message || error.statusText);
        }
      );
    }
  }
})();
