(function () {
  'use strict';

  angular
    .module('careerKeyCentral')
    .filter('PlanFilter', PlanFilter);

  PlanFilter.$inject = ['APP_CONSTANTS'];

  function PlanFilter(APP_CONSTANTS) {
    return function (plans, key) {
      switch (key) {
        case APP_CONSTANTS.SUBSCRIPTION_TYPE.PREPAID:
        case APP_CONSTANTS.SUBSCRIPTION_TYPE.AUTO_RENEWAL:
          return plans.filter(function (plan) {
            return plan.plan.cf_subscription_type.toLowerCase() === key;
          });
          break;

        case APP_CONSTANTS.CKD:
          return plans.filter(function (plan) {
            return !plan.plan.cf_type || plan.plan.cf_type.toLowerCase() === key;
          });
          break;

        case APP_CONSTANTS.CDP:
          return plans.filter(function (plan) {
            return plan.plan.cf_type && plan.plan.cf_type.toLowerCase() === key;
          });
          break;

        default:
          return plans;
          break;
      }
    }
  }
})();
