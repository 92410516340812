(function () {
  'use strict';

  angular.module('app.shared')
    .controller('DiscovererProgressChartController', DiscovererProgressChartController);

  DiscovererProgressChartController.$inject = [
    'OwnerDashboardService',
    'GroupService',
    'toaster',
    'SessionService',
    '$state',
    '$stateParams',
    'APP_CONSTANTS',
    '$scope',
  ];

  function DiscovererProgressChartController(
    OwnerDashboardService,
    GroupService,
    toaster,
    SessionService,
    $state,
    $stateParams,
    APP_CONSTANTS,
    $scope,
  ) {
    let vm = this;
    let currentUser = SessionService.getUser();
    let sponsoredOrganizationId = $stateParams.sponsoredOrganizationId;

    vm.loading = {};
    vm.hasCDPSubscription = _.some(currentUser.subscription, { isActive: true, type: APP_CONSTANTS.CDP });
    vm.viewSponsoredOrganizationDashboard = !!sponsoredOrganizationId;

    $scope.$watch('vm.selectedGroup', function (group) {
      if (!group) return;

      fetchCategories(group.id || '');
    }, true);

    let loadingWatcher = $scope.$watch('vm.loading.groups', function (newValue) {
      if (newValue !== false) return;
      $scope.startTour();
      loadingWatcher();
    });

    fetchGroups();

    function fetchGroups() {
      vm.loading.categories = true;
      vm.loading.groups = true;

      let organizationId = sponsoredOrganizationId || currentUser.sponsoredOrganizationId;

      GroupService.fetchGroupsByUser(organizationId)
        .then(function (response) {
          let groups = _.get(response, 'data.data', []);
          let filters = APP_CONSTANTS.GROUP_FILTER;
          if (currentUser.roles.isCounselor
            || currentUser.roles.isTechnicalContact
          ) {
            if (!groups.length) {
              resetPieChartCounts();
              vm.loading.categories = false;
              return;
            }
            filters = _.filter(filters, { name: 'All' });
          }

          vm.groupList = _.concat(filters, groups);
          vm.selectedGroup = _.first(groups.concat(filters));
          vm.loading.groups = false;
        })
        .catch(function (error) {
          vm.loading.groups = false;
          vm.loading.categories = false;
          toaster.pop('error', error.data.message || error.statusText);
        })
    }

    function fetchCategories(groupId) {
      resetPieChartCounts();
      vm.loading.categories = true;
      groupId = sponsoredOrganizationId ? '' : groupId;

      OwnerDashboardService
        .fetchEventCategories(groupId, sponsoredOrganizationId)
        .then(setPieChartCounts)
        .catch(function (error) {
          toaster.pop('error', error.data.message || error.statusText);
        })
        .finally(function () {
          vm.loading.categories = false;
        });
    }

    function resetPieChartCounts() {
      vm.totalDiscovererCount = 0;
      vm.groupDiscovererCount = null;
      vm.ckdProgressCounts = generateEmptyCKDProgressCounts();
      vm.ckdServiceLevelCounts = generateEmptyServiceLevelCounts();
      vm.hasCDPSubscription && (vm.cdpServiceLevelCounts = generateEmptyServiceLevelCounts());
    }

    function setPieChartCounts(response) {
      let data = _.get(response, 'data.data', {});
      vm.totalDiscovererCount = data.totalDiscovererCount;
      vm.groupDiscovererCount = data.groupDiscovererCount;

      setCKDProgressTile(data.ckdProgressCounts);
      setCKDServiceLevelTile(data.ckdServiceLevelCounts);
      setCDPServiceLevelTile(data.cdpServiceLevelCounts);
    }

    function setCKDServiceLevelTile(ckdServiceLevelCounts) {
      calculateServiceLevelCounts(ckdServiceLevelCounts, vm.ckdServiceLevelCounts);
    }

    function setCDPServiceLevelTile(cdpServiceLevelCounts) {
      if (!vm.hasCDPSubscription) return;

      calculateServiceLevelCounts(cdpServiceLevelCounts, vm.cdpServiceLevelCounts);
    }

    function generateEmptyServiceLevelCounts() {
      return APP_CONSTANTS.SERVICE_LEVEL
        .filter(function (item) {
          return item.key !== 'All';
        })
        .map(function (item) {
          return {
            key: item.key,
            label: item.value,
            count: 0,
            percent: 0,
          };
        });
    }

    function calculateServiceLevelCounts(serviceLevelCounts, target) {
      let total = 0;
      let result = target || generateEmptyServiceLevelCounts();
      result.forEach(function (item) {
        let serviceLevel = _.find(serviceLevelCounts, { serviceLevel: item.key });
        item.count = serviceLevel && serviceLevel.count || 0;
        total += item.count;
      });
      if (!total) return;

      calculatePercentage(_.map(result, 'count'), total)
        .forEach(function (percent, index) {
          if (!result[index] || !result[index].count) return;

          result[index].percent = isNaN(percent) ? 0 : percent;
        });

      target = result;
    }

    function generateEmptyCKDProgressCounts() {
      let keyMap = {};
      keyMap[APP_CONSTANTS.EVENT_CATEGORY.NOT_STARTED] = { 'not_started': 'invite_to_start' };
      keyMap[APP_CONSTANTS.EVENT_CATEGORY.NOT_COMPLETED] = { 'assessment_not_completed': 'remind_to_complete' };
      keyMap[APP_CONSTANTS.EVENT_CATEGORY.COMPLETED_ASSESSMENT] = { 'completed_assessment': 'prompt_add_options' };
      keyMap[APP_CONSTANTS.EVENT_CATEGORY.STARTED_PROFILE] = { 'started_profile': 'kudos_progress' };
      keyMap[APP_CONSTANTS.EVENT_CATEGORY.RETURNED_TO_PROFILE] = { 'returned_profile': 'offer_support' };

      return APP_CONSTANTS.discoverers_progress
        .filter(function (item) {
          return item.key !== 'All';
        })
        .map(function (item) {
          let [title] = Object.keys(keyMap[item.key]) || '';
          let action = keyMap[item.key][title] || '';

          return {
            key: item.key,
            action,
            count: 0,
            percent: 0,
            title,
          };
        });
    }

    function setCKDProgressTile(ckdProgressCounts) {
      let result = vm.ckdProgressCounts;
      result.forEach(function (item) {
        let progress = _.find(ckdProgressCounts, { progress: item.key }) || {};
        item.count = progress.count || 0;
      });

      let totalDiscoverers = vm.groupDiscovererCount !== null ? vm.groupDiscovererCount : vm.totalDiscovererCount;
      calculatePercentage(_.map(result, 'count'), totalDiscoverers)
        .forEach(function (percent, index) {
          if (!result[index] || !result[index].count) return;

          result[index].percent = isNaN(percent) ? 0 : percent;
        });

      vm.ckdProgressCounts = result;
    }

    function calculatePercentage(numbers, total) {
      if (!numbers || !numbers.length) return [];

      let totalNumber = (!total || total == 0) ? _.sum(numbers) : total;
      if (totalNumber <= 0) return new Array(numbers.length).fill(0);

      let percents = _.map(numbers, function (n) {
        return (n / totalNumber) * 100;
      });

      return adjustPercentsInWholeNumber(percents, 100);
    }

    function adjustPercentsInWholeNumber(sourceArray, target) {
      let sum = 0;
      let parts = _.map(sourceArray, function (item, i) {
        let int = item | 0;
        sum += int;

        return {
          integer: int,
          decimal: item % 1,
          originalIndex: i,
        };
      });

      if (sum < target) {
        parts = _.sortBy(parts, 'decimal').reverse();
        let diff = target - sum;
        let i = 0;
        while (i < diff) {
          parts[i] && parts[i].decimal > 0 && parts[i].integer++;
          i++;
        }
      }

      return _.map(_.sortBy(parts, 'originalIndex'), 'integer');
    }

    vm.stateProgress = function (category) {
      let parameter = {
        category,
        groupId: _.get(vm, 'selectedGroup.id', ''),
      };
      $state.go('app.discoverersprogress', { parameter });
    }

    function generateServiceLevelParams(serviceLevelKey) {
      return {
        parameter: {
          serviceLevelKey,
          groupId: _.get(vm, 'selectedGroup.id', ''),
        },
      };
    }

    vm.stateCKDServiceLevel = function (key) {
      $state.go('app.discoverersprogress', generateServiceLevelParams(key));
    }
    vm.stateCDPServiceLevel = function (key) {
      $state.go('app.careerdecisionprofileclient', generateServiceLevelParams(key));
    }
  }
})();