(function () {
  'use strict';

  angular
    .module('app.shared')
    .controller('AdminEditBillingInformationModalController', AdminEditBillingInformationModalController);

  AdminEditBillingInformationModalController.$inject = [
    '$uibModalInstance',
    'AdminCustomerBillingService',
    'RegistrationService',
    'toaster',
    'APP_CONSTANTS',
    'data'
  ];

  function AdminEditBillingInformationModalController(
    $uibModalInstance,
    AdminCustomerBillingService,
    RegistrationService,
    toaster,
    APP_CONSTANTS,
    data
  ) {
    var bc = this;

    bc.loading = {};
    bc.accountDetail = {};
    bc.billingDetail = {};
    bc.fetchStates = fetchStates;
    bc.sameAsAccountInfo = false;
    bc.originalBillingDetail = {};
    bc.copyAccountInfo = copyAccountInfo;
    bc.countrySelected = countrySelected;
    bc.updateBillingContact = updateBillingContact;

    init();

    function init() {
      bc.loading.billingDetail = true;
      angular.copy(data.accountInfo, bc.accountDetail);
      angular.copy(data.billingDetail, bc.originalBillingDetail);
      fetchCountries();
    }

    function copyAccountInfo() {
      if (!bc.sameAsAccountInfo) {
        angular.copy(bc.originalBillingDetail, bc.billingDetail);
      } else {
        angular.copy(bc.accountDetail, bc.billingDetail);
      }
      countrySelected();
    }

    function fetchCountries(name) {
      RegistrationService.getCountries(name)
        .then(function (result) {
          bc.countries = result.data.data.map(function (country) {
            return {
              id: country.countryCode,
              name: country.name
            }
          });
          bc.originalBillingDetail.country = bc.countries.find(function (country) {
            return country.id === bc.originalBillingDetail.country
          });
          angular.copy(bc.originalBillingDetail, bc.billingDetail);
          countrySelected();
        })
        .catch(function (error) {
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function countrySelected() {
      RegistrationService.getStates(bc.billingDetail.country.id)
        .then(function (result) {
          bc.loading.billingDetail = false;
          bc.states = result.data.data.states;
        })
        .catch(function (error) {
          bc.loading.billingDetail = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function fetchStates() {
      RegistrationService.getStates(bc.billingDetail.country.id)
        .then(function (result) {
          bc.states = result.data.data.states;
          bc.billingDetail.state_code = null;
        })
        .catch(function (error) {
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function updateBillingContact(form) {
      bc.form = form;

      if (bc.form.$invalid) return;

      var newBillingContact = {
        city: bc.form.city.$modelValue,
        zip: bc.form.zipCode.$modelValue,
        state: bc.billingDetail.state_code,
        line1: bc.form.addressLine1.$modelValue,
        line2: bc.form.addressLine2.$modelValue,
        last_name: bc.form.lastName.$modelValue,
        first_name: bc.form.firstName.$modelValue,
        country: bc.billingDetail.country.id
      };

      bc.loading.billingDetail = true;

      AdminCustomerBillingService.updateBillingDetail(newBillingContact, data.customerId)
        .then(function (response) {
          bc.loading.billingDetail = false;
          bc.billingDetail = _.get(response, 'data.data', {});
          toaster.pop(
            'success',
            APP_CONSTANTS.MESSAGE.billingContactUpdateSuccessful
          );
          bc.ok();
        })
        .catch(function (err) {
          bc.loading.billingDetail = false;

          if (err.data && err.data.error && err.data.error.code === 400)
            toaster.pop({
              type: 'error',
              body: 'Invalid Form Details',
              toasterId: 'updateBilling'
            });
          else
            toaster.pop({
              type: 'error',
              body: err.data.message || err.statusText,
              toasterId: 'updateBilling'
            });
        });
    }

    bc.ok = function () {
      $uibModalInstance.close('closed');
    };

    bc.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  }
})();