(function() {
  'use strict';
  angular
    .module('app.user')
    .factory('RegistrationCompleteService', RegistrationCompleteService);

  RegistrationCompleteService.$inject = [
    'APP_CONSTANTS',
    '$q',
    '$filter',
    'ApiCaller',
    'SubscriptionPlansService',
    'RegistrationService'
  ];

  function RegistrationCompleteService(
    APP_CONSTANTS,
    $q,
    $filter,
    ApiCaller,
    SubscriptionPlansService,
    RegistrationService
  ) {
    var self = this;

    self.fetchHostedPageDetails = fetchHostedPageDetails;
    self.destroyRegistrationData = destroyRegistrationData;
    self.updateOrganizationPaymentDetails = updateOrganizationPaymentDetails;

    function fetchHostedPageDetails(hostedPageId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.hostedPage,
        APP_CONSTANTS.rootUrl,
        hostedPageId
      );

      ApiCaller.apiGet(url).then(
        function(result) {
          deferred.resolve(result);
        },
        function(err) {
          deferred.reject(err);
        }
      );

      return deferred.promise;
    }

    function updateOrganizationPaymentDetails(paymentDetails) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.paymentDetails,
        APP_CONSTANTS.rootUrl
      );

      ApiCaller.apiPost(url, paymentDetails).then(
        function(result) {
          deferred.resolve(result);
        },
        function(err) {
          deferred.reject(err);
        }
      );

      return deferred.promise;
    }

    function destroyRegistrationData() {
      SubscriptionPlansService.destroyPlan();
      RegistrationService.destroyRegistrationInfo();
    }

    return self;
  }
})();
