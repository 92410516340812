/** =========================================================
 * Module: Discoverers & Progress
 * Angular controller
 =========================================================*/

/* eslint-disable */
(function () {
  'use strict';

  angular
    .module('app.discovererProgress')
    .controller('DiscoverersProgressController', DiscoverersProgressController);

  DiscoverersProgressController.$inject = [
    'APP_CONSTANTS',
    'toaster',
    '$scope',
    'discovererProgressService',
    '$stateParams',
    'GroupModal',
    'GroupService',
    'AccessRoleService',
    'SessionService',
    '$state',
    '$uibModal',
    'ngDialog',
    '$rootScope',
    '$timeout',
    'OrganizationInfoService'
  ];

  function DiscoverersProgressController(
    APP_CONSTANTS,
    toaster,
    $scope,
    discovererProgressService,
    $stateParams,
    GroupModal,
    GroupService,
    AccessRoleService,
    SessionService,
    $state,
    $uibModal,
    ngDialog,
    $rootScope,
    $timeout,
    OrganizationInfoService
  ) {
    var vm = this;

    vm.loading = {};
    vm.isDateOpen = false;
    vm.isServiceOpen = false;
    vm.selectedDiscoverers = [];
    vm.selectAllFlag = false;
    vm.showNotCompleted = false;
    vm.categorySelected = false;
    vm.showInviteToStart = false;
    vm.showStartedProfile = false;
    vm.endDatePickerIsOpen = false;
    vm.startDatePickerIsOpen = false;
    vm.showReturnedToProfile = false;
    vm.showCompletedAssessment = false;
    vm.showClustersFilter = false;
    vm.filterClass = 'col-md-3 col-lg-3';

    vm.breadCrumbs = '';
    vm.allCheck = allCheck;
    vm.resetDate = resetDate;
    vm.resetService = resetService;
    vm.exportAllToCSV = exportAllToCSV;
    vm.filter_selected = filter_selected;
    vm.selectDiscoverer = selectDiscoverer;
    vm.fetchDiscoverers = fetchDiscoverers;
    vm.discovererDisplayName = discovererDisplayName;
    vm.displayInvitation = displayInvitation;
    vm.exportSelectedToCSV = exportSelectedToCSV;
    vm.assignDiscoverersToGroup = assignDiscoverersToGroup;

    var userRole = AccessRoleService.getHighPriorityRole();
    const currentUser = SessionService.getUser();
    const hasCDPSubscription = _.some(currentUser.subscription, {
      isActive: true,
      type: APP_CONSTANTS.CDP,
    }) || currentUser.subscriptionType === APP_CONSTANTS.BOTH;

    // TODO Refactor checking the role of the user
    vm.role = '';

    if (userRole.priority === 5) {
      vm.role = 'groupCounselor';
    }

    vm.discoverers = [];
    vm.pageNum = 1;
    vm.pageSize = 10;
    vm.searchKey = '';
    vm.discoverers_clusters = [];

    if (!_.isEmpty($stateParams.parameter)) {
      vm.category = $stateParams.parameter.category || '';
      vm.groupId = $stateParams.parameter.groupId || '';
      vm.selectedService = _.find(APP_CONSTANTS.SERVICE_LEVEL, {
        key: $stateParams.parameter.serviceLevelKey
      });
    } else {
      vm.category = '';
      vm.groupId = '';
    }

    if ($rootScope.ckdFilters.progress || vm.category) vm.categorySelected = true;

    vm.riasec = '';
    vm.totalRecords = 0;
    vm.refreshTimeStamp = Date.now();
    vm.pageSizeOptions = APP_CONSTANTS.pageSizeOptions;
    vm.scorePatterns = [];
    vm.serviceLevels = APP_CONSTANTS.SERVICE_LEVEL.filter(function (service) {
      return service.key !== 'All';
    });
    vm.letsConnectOptions = _.filter(APP_CONSTANTS.letsConnectOptions, function (option) {
      return option.messageType === APP_CONSTANTS.CUSTOM ||
        option.subscriptionType === APP_CONSTANTS.CKD;
    });

    let loadingWatcher = $scope.$watchGroup(['vm.loading.discoverers', 'vm.loading.clusters'], function (newValues) {
      if (!newValues.every(val => val === false)) return;
      $scope.startTour();
      loadingWatcher();
    });

    $scope.$watch('vm.selectedService', function (serviceLevel) {
      vm.selectedScore = serviceLevel ? $rootScope.ckdFilters.selectedScore : null;
      vm.scorePatterns = serviceLevel ? serviceLevel.SCORE_PATTERN : [];
      if (vm.scorePatterns.length > 1) {
        vm.scorePatterns = _.concat({
          key: 'All',
          value: 'All',
        }, vm.scorePatterns);
      }
      if (vm.scorePatterns.indexOf(vm.selectedScore) == -1) {
        vm.selectedScore = vm.scorePatterns[0] || null;
      }
    });

    vm.selectedPageSize = {
      key: "'" + vm.pageSize + "'",
      value: vm.pageSize
    };

    // Make sure latest discoverers are fetched as soon as they are added from Groups.
    vm.endDate = new Date(moment(new Date()).add(1, 'days').format());

    vm.startDate = new Date(
      moment(new Date())
        .subtract(1, 'years')
        .format()
    );
    setDateRange();

    fetchGroups();
    fetchRIASEC();
    fetchProgress();
    fetchOrganizationClusters(SessionService.getUser().sponsoredOrganizationId);

    var sponsoredOrganizationId = $stateParams.sponsoredOrganizationId;

    vm.viewSponsoredOrganizationDashboard = sponsoredOrganizationId
      ? true
      : false;


    function setDateRange() {
      if (vm.startDate && vm.endDate)
        vm.dateRange =
          $rootScope.moment(vm.startDate).format('M/DD/YY') +
          ' - ' +
          $rootScope.moment(vm.endDate).format('M/DD/YY');
      else vm.dateRange = '';
    }

    function openBulkEmailModal(option) {
      let category = vm.progress || vm.discoverers_progress[0];
      if (option.template) {
        category.template = option.template;
      }

      const recipients = [];
      if (vm.group?.name) recipients.push(`Group: ${vm.group.name}`);
      if (vm.selectedServiceLabel) recipients.push(`Service Level: ${vm.selectedServiceLabel}`);
      if (vm.progress?.value) recipients.push(`Progress: ${vm.progress.value}`);
      if (vm.riasecSelected?.value) recipients.push(`RIASEC: ${vm.riasecSelected.value}`);
      if (vm.dateRange) recipients.push(`Date: ${vm.dateRange}`);
      if (vm.searchKey) recipients.push(`Search: ${vm.searchKey}`);

      var modalInstance = $uibModal.open({
        animation: true,
        templateUrl: 'app/sendEmailToAll/views/sendEmailToAll.html',
        controller: 'SendEmailToAllController',
        controllerAs: 'vm',
        size: 'lg',
        resolve: {
          tags: function () {
            return {
              category,
              recipients,
            };
          }
        }
      });

      modalInstance.result.then(sendBulkEmail);
    }

    function sendBulkEmail(customMessage) {
      const filters = {
        groupId: vm.groupId,
        category: vm.category,
        searchKey: vm.searchKey,
        riasec: vm.riasec,
        fromDate: vm.startDate?.toJSON() || '',
        toDate: vm.endDate?.toJSON() || '',
        service: vm.serviceLevel,
        score: vm.scorePattern,
        clusterId: vm.clusterId,
      };

      if (vm.category && vm.category !== 'All') {
        vm.loading.message = true;
        discovererProgressService
          .sendBulkMessage({
            filters,
            customMessage,
          })
          .then(
            function () {
              vm.loading.message = false;
              toaster.pop('success', APP_CONSTANTS.MESSAGE.messageSent);
            },
            function (error) {
              vm.loading.message = false;
              toaster.pop('error', error.data.message || error.statusText);
            }
          );
      }
    }


    function resetService() {
      vm.selectedService = null;
      vm.selectedScore = null;
      vm.selectedServiceLabel && filter_selected();
    }

    function resetDate() {
      vm.endDate = '';
      vm.startDate = '';
      filter_selected();
    }


    vm.columnOrderMap = [
      { col: 0, orderable: false, sortBy: '' },
      { col: 1, orderable: true, sortBy: 'last' },
      { col: 2, orderable: false, sortBy: 'groupName' },
      { col: 3, orderable: true, sortBy: 'assessmentDate', default: true },
      { col: 4, orderable: false, sortBy: 'serviceLevel' },
      { col: 5, orderable: false, sortBy: 'scorePattern' },
      { col: 6, orderable: true, sortBy: 'lastEventType' },
      { col: 7, orderable: true, sortBy: 'riasec1' },
      { col: 8, orderable: true, sortBy: 'riasec2' },
      { col: 9, orderable: false, sortBy: 'clusterName' },
      { col: 10, orderable: true, sortBy: 'lastContact' },
      { col: 11, orderable: true, sortBy: 'lastEventAt' }];
    vm.defaultColumnOrder = _.find(vm.columnOrderMap, 'default');
    vm.orderCol = vm.defaultColumnOrder.col;
    vm.sortBy = vm.defaultColumnOrder.sortBy;
    vm.orderBy = 'desc';

    $.fn.dataTable.ext.order['disable-custom-sort'] = function (settings, col) {
      var scope = angular.element($('.table-responsive')).scope();

      if (scope.vm.loading.clusters || scope.vm.loading.discoverers) return false;

      if (scope.vm.orderCol !== col) {
        scope.vm.orderCol = col;
        scope.vm.orderBy = 'asc';
        scope.vm.sortBy = _.get(
          _.find(vm.columnOrderMap, { col, orderable: true }),
          'sortBy',
          vm.defaultColumnOrder.sortBy);
        scope.vm.loadedNewData = true;

        scope.vm.fetchDiscoverers(
          scope.vm.pageNum,
          scope.vm.pageSize,
          scope.vm.searchKey,
          scope.vm.groupId,
          scope.vm.category,
          scope.vm.riasec,
          scope.vm.role,
          scope.vm.sortBy,
          scope.vm.orderBy,
          scope.vm.startDate,
          scope.vm.endDate,
          scope.vm.clusterId,
        );
      } else if (scope.vm.orderCol === col) {
        if (scope.vm.loadedNewData) {
          scope.vm.loadedNewData = false;
        } else {
          scope.vm.orderBy = scope.vm.orderBy === 'asc' ? 'desc' : 'asc';
          scope.vm.loadedNewData = true;

          scope.vm.fetchDiscoverers(
            scope.vm.pageNum,
            scope.vm.pageSize,
            scope.vm.searchKey,
            scope.vm.groupId,
            scope.vm.category,
            scope.vm.riasec,
            scope.vm.role,
            scope.vm.sortBy,
            scope.vm.orderBy,
            scope.vm.startDate,
            scope.vm.endDate,
            scope.vm.clusterId,
          );
        }
      }

      return false;
    };
    vm.dtOptions = {
      paging: false,
      searching: false,
      info: false,
      order: [],
      destroy: true,
      columnDefs: [
        {
          orderable: false,
          targets: _.filter(vm.columnOrderMap, { orderable: false }).map(v => v.col)
        },
        {
          orderDataType: 'disable-custom-sort',
          targets: _.filter(vm.columnOrderMap, { orderable: true }).map(v => v.col)
        }
      ]
    };


    function exportAllToCSV(exportSelectedFlag) {
      exportSelectedFlag = exportSelectedFlag ? true : false;
      vm.groupId = angular.isUndefined(vm.group) ? '' : vm.group.id;
      vm.endDate = angular.isUndefined(vm.endDate) ? '' : vm.endDate;
      vm.searchKey = angular.isUndefined(vm.searchKey) ? '' : vm.searchKey;
      vm.startDate = angular.isUndefined(vm.startDate) ? '' : vm.startDate;
      vm.category = angular.isUndefined(vm.progress) ? '' : vm.progress.key;
      vm.riasec = angular.isUndefined(vm.riasecSelected)
        ? ''
        : vm.riasecSelected.key;

      if (!vm.startDate && !vm.endDate) {
        toaster.pop('error', 'Both start and end date should be selected');
        return;
      }

      var params = {
        riasec: vm.riasec,
        groupId: vm.groupId,
        endDate: vm.endDate,
        category: vm.category,
        startDate: vm.startDate,
        searchKey: vm.searchKey,
        service: vm.serviceLevel || '',
        score: vm.scorePattern || '',
      };

      var endDate = moment(vm.endDate);
      var todayDate = moment(new Date());
      var startDate = moment(vm.startDate);
      var differenceInStartAndEndDate = endDate.diff(startDate, 'days');
      var differenceInTodayAndStartDate = todayDate.diff(startDate, 'days');

      if (differenceInStartAndEndDate <= 0) {
        toaster.pop('error', APP_CONSTANTS.MESSAGE.startDateGreaterThanEndDate);
        return;
      }

      if (differenceInStartAndEndDate > 367) {
        toaster.pop('error', APP_CONSTANTS.MESSAGE.oneYearOnly);
        return;
      }

      if (differenceInTodayAndStartDate > 1095) {
        toaster.pop('error', APP_CONSTANTS.MESSAGE.notThreeYearBefore);
        return;
      }
      if (exportSelectedFlag && vm.totalRecords < 200) {
        discovererProgressService.exportToCSV({
          params: params,
          downloadAllFlag: true
        });
      } else {
        discovererProgressService
          .exportAllToCSV({ params: params })
          .then(function (response) {
            toaster.pop(
              'success',
              APP_CONSTANTS.MESSAGE.csvExportedThroughMail
            );
          })
          .catch(function (err) {
            toaster.pop('error', err.data.message || err.statusText);
          });
      }
    }

    function exportSelectedToCSV() {
        if (!vm.selectedDiscoverers.length) return;

        var discovererId = vm.selectedDiscoverers.map(function (discoverer) {
          return discoverer.id;
        });

        discovererProgressService.exportToCSV({
          discovererId: discovererId,
          downloadAllFlag: false
        });
    }

    vm.showDeleteDiscoverersBtn = () => {
      return (vm.discoverers.length
        && vm.selectedDiscoverers.length
        && !_.some(vm.selectedDiscoverers, d => d.progress.completed_assessment)
      );
    }

    vm.confirmDeleteDiscoverers = () => {
      if (!vm.showDeleteDiscoverersBtn()) return;

      if (vm.selectedDiscoverers.length===1) {
        return confirmDeleteDiscoverer(vm.selectedDiscoverers[0]);
      }

      ngDialog
        .openConfirm({
          template: 'app/shared/views/partials/ngDialogConfirmation.html',
          data: {
            message: 'Are you sure you want to delete the selected discoverers?'
          }
        })
        .then(function () {
          deleteDiscoverers(_.map(vm.selectedDiscoverers, 'id'));
        });
    }

    function confirmDeleteDiscoverer(discoverer) {
      ngDialog
        .openConfirm({
          template: 'app/shared/views/partials/ngDialogConfirmation.html',
          data: {
            message: 'Are you sure you want to delete the discoverer ' + discovererDisplayName(discoverer) + '?'
          }
        })
        .then(function () {
          deleteDiscoverers([discoverer.id]);
        });
    }

    function discovererDisplayName(discoverer) {
      return discoverer.firstName
        ? (discoverer.lastName + ', ' + discoverer.firstName)
        : (discoverer.email || discoverer.customerSourcedId || discoverer.id);
    }

    function deleteDiscoverers(discovererIds) {
      vm.loading.discoverers = true;
      discovererProgressService
        .deleteDiscoverers(discovererIds)
        .then(function () {
          toaster.pop('success', discovererIds.length > 1 ? APP_CONSTANTS.MESSAGE.deletedDiscoverers : APP_CONSTANTS.MESSAGE.deletedDiscoverer);
          vm.selectedDiscoverers = [];
          vm.loadedNewData = true;
          fetchDiscoverers(
            vm.pageNum,
            vm.pageSize,
            vm.searchKey,
            vm.groupId,
            vm.category,
            vm.riasec,
            vm.role,
            vm.sortBy,
            vm.orderBy,
            vm.startDate,
            vm.endDate,
            vm.clusterId,
          );
        })
        .catch(function (error) {
          vm.loading.discoverers = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }


    function fetchDiscoverers(
      pageNum,
      pageSize,
      searchKey,
      groupId,
      category,
      riasec,
      role,
      sortBy,
      orderBy,
      startDate,
      endDate,
      clusterId
    ) {
      vm.isDefaultFilter = (
        moment(vm.endDate).isSame(moment(new Date()).add(1, 'days'), 'day')
        && moment(vm.startDate).isSame(moment(new Date()).subtract(1, 'years'), 'day')
        && _.isEmpty(vm.group)
        && _.isEmpty(vm.selectedService)
        && _.isEmpty(vm.selectedScore)
        && _.isEmpty(vm.progress)
        && _.isEmpty(vm.riasecSelected)
        && _.isEmpty(vm.cluster)
      );

      vm.loading.discoverers = true;

      var params = {
        groupId: groupId,
        category: category,
        searchKey: searchKey,
        riasec: riasec,
        pageSize: pageSize,
        pageNum: pageNum,
        sortBy: sortBy,
        orderBy: orderBy,
        fromDate: startDate ? startDate.toJSON() : '',
        toDate: endDate ? endDate.toJSON() : '',
        service: vm.serviceLevel,
        score: vm.scorePattern,
        clusterId: clusterId
      };

      Object.keys(params).forEach(function (key) {
        if (!params[key]) {
          delete params[key];
        }
      });

      discovererProgressService
        .fetchDiscoverers(params)
        .then(function (response) {
          vm.discoverers = _.get(response, 'data.data.discovers', []);
          vm.pageNum = response.data.data.pageNum
            ? response.data.data.pageNum
            : 1;
          vm.pageSize =
            response.data.data.pageSize == 'All'
              ? response.data.data.totalRecords
              : response.data.data.pageSize;
          // vm.discoverers_careers = response.data.data.careers;
          // vm.discoverers_careers.unshift({
          //   'name': 'All',
          //   'id': ''
          // });
          // vm.discoverers_majors = response.data.data.majors;
          // vm.discoverers_majors.unshift({
          //   'name': 'All',
          //   'id': ''
          // });
          vm.totalRecords = response.data.data.totalRecords;
          vm.refreshTimeStamp = Date.now();
          // if (response.data.data.pageSize == "All") {
          //   vm.pageSizeOption = {
          //     key: "All",
          //     value: "All"
          //   };
          // } else {
          //   vm.pageSizeOption = {
          //     key: "'" + vm.pageSize + "'",
          //     value: vm.pageSize
          //   };
          // }
          // if (!_.isEmpty($stateParams.parameter)) {
          //   angular.copy(vm.discoverers, vm.selectedDiscoverers);
          //   angular.element($('.checkDiscoverer').attr('checked', 'true'));
          //   vm.selectAllFlag = true;
          // }

          vm.discoverers &&
            vm.discoverers.forEach(function (discoverer) {
              discoverer.clusterNameArray = [];
              if (discoverer.clusters && discoverer.clusters.length) {
                discoverer.clusters.forEach(function (clusterId) {
                  discoverer.clusterNameArray.push(
                    vm.discoverers_clusters[
                    vm.discoverers_clusters.findIndex(function (cluster) {
                      return cluster.clusterId === clusterId;
                    })
                    ]
                  );
                });
              }
              calculateDiscovererProgress(discoverer);
              discoverer.showPersonalityProfileHistory = false;
            });

          if (vm.category) {
            var selectedDiv = angular.element(
              document.querySelector('#progress-filter')
            );

            selectedDiv.find('span').addClass('ui-select-outline');
          }

          if (vm.groupId) {
            var selectedDiv = angular.element(
              document.querySelector('#group-filter')
            );

            selectedDiv.find('span').addClass('ui-select-outline');
          }

          if (vm.riasec) {
            var selectedDiv = angular.element(
              document.querySelector('#riasec-filter')
            );

            selectedDiv.find('span').addClass('ui-select-outline');
          }

          vm.selectedDiscoverers = vm.selectedDiscoverers.map(function (selectedDiscoverer) {
            return vm.discoverers.find(function (discoverer) {
              return selectedDiscoverer.id === discoverer.id;
            }) || selectedDiscoverer;
          });

          mapDiscoverersGroupsName();
          selectDiscoverer();

          vm.loading.discoverers = false;
        })
        .catch(function (error) {
          vm.loading.discoverers = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function mapDiscoverersGroupsName() {
      if (!vm.discoverers_groups?.length || !vm.discoverers?.length) return;

      vm.discoverers.forEach(dis => {
        dis.groupsName = vm.discoverers_groups
          .filter(group => dis.groupId.includes(group.id))
          .map(group => group.name);
      });
    }

    // TODO to refactor this in future.
    function fetchGroups() {
      vm.loading.groups = true;

      if (vm.role !== '' && vm.role === 'groupCounselor') {
        GroupService.fetchGroupsByUser(
          SessionService.getUser().sponsoredOrganizationId
        )
          .then(function (response) {
            vm.listGroups = _.sortBy(response.data.data, (i) => i.name.toLowerCase());
            vm.discoverers_groups = _.concat({
              name: 'All Assigned Groups',
              id: ''
            }, vm.listGroups);
            vm.loading.groups = false;
            if (!_.isEmpty($stateParams.parameter)) {
              if ($stateParams.parameter.groupId !== '') {
                vm.group = vm.discoverers_groups.filter(function (item) {
                  return item.id === vm.groupId;
                })[0];
              }
            }

            mapDiscoverersGroupsName();
          })
          .catch(function (error) {
            toaster.pop('error', error.data.message || error.statusText);
            vm.loading.groups = false;
          });
      } else {
        GroupService.fetchGroups()
          .then(function (response) {
            vm.listGroups = _.sortBy(response.data.data, (i) => i.name.toLowerCase());
            vm.discoverers_groups = _.concat(APP_CONSTANTS.GROUP_FILTER, vm.listGroups);
            vm.loading.groups = false;
            if (!_.isEmpty($stateParams.parameter)) {
              if ($stateParams.parameter.groupId !== '') {
                vm.group = vm.discoverers_groups.filter(function (item) {
                  return item.id === vm.groupId;
                })[0];
              }
            }

            mapDiscoverersGroupsName();
          })
          .catch(function (error) {
            vm.loading.groups = false;
            toaster.pop('error', error.data.message || error.statusText);
          });
      }
    }

    function validateDiscoverer(option) {
      if (vm.selectedDiscoverers.length === 0) {
        return toaster.pop(
          'warning',
          APP_CONSTANTS.MESSAGE.discoverersSelectValidation
        );
      }

      option.emails = _.map(vm.selectedDiscoverers, 'email');
      if (option.messageType === APP_CONSTANTS.CUSTOM) {
        return $state.go('app.mailbox.compose', option);
      }

      let scorePatterns = [
        APP_CONSTANTS.DIFFERENTIATED_CONSISTENT,
        APP_CONSTANTS.DIFFERENTIATED_INCONSISTENT,
        APP_CONSTANTS.UNDIFFERENTIATED,
        APP_CONSTANTS.UNDIFFERENTIATED_HIGH_FLAT,
        APP_CONSTANTS.UNDIFFERENTIATED_LOW_FLAT
      ];
      let checkScorePattern = scorePatterns.some((sp) => sp === option.messageType);

      for (var i = 0; i < vm.selectedDiscoverers.length; i++) {
        let discoverer = vm.selectedDiscoverers[i];
        if (checkScorePattern && scorePatterns.some((sp) => sp === discoverer.scorePattern)) continue;

        let lastEventCategory = discoverer.lastEventCategory === APP_CONSTANTS.EVENT_CATEGORY.INVITE_TO_START
          ? APP_CONSTANTS.EVENT_CATEGORY.NOT_STARTED
          : discoverer.lastEventCategory;
        if (lastEventCategory === option.messageType) continue;

        return toaster.pop(
          'warning',
          APP_CONSTANTS.MESSAGE.discoverersEventTypeSelectValidation
        );
      }

      $state.go('app.mailbox.compose', option);
    };

    function fetchProgress() {
      vm.loading.progress = true;
      vm.discoverers_progress = angular.copy(APP_CONSTANTS.discoverers_progress);

      if (hasCDPSubscription) {
        const index = _.findIndex(vm.discoverers_progress, {
          key: APP_CONSTANTS.EVENT_CATEGORY.NOT_STARTED,
        });
        const option = Object.assign({}, vm.discoverers_progress[index], {
          cdpCompleted: true,
          key: vm.discoverers_progress[index].key + '-cdp_completed',
          value: vm.discoverers_progress[index].value + ' (CDP Completed)',
        });

        vm.discoverers_progress.splice(index + 1, 0, option);
      }

      if (!_.isEmpty($stateParams.parameter)) {
        if ($stateParams.parameter.category !== '') {
          vm.progress = vm.discoverers_progress.filter(function (
            item
          ) {
            return item.key.indexOf(vm.category) > -1;
          })[0];
        }
      }

      vm.loading.progress = false;
    }

    function fetchRIASEC() {
      vm.loading.riasec = true;
      vm.discoverers_riasec = APP_CONSTANTS.discoverers_riasec;
      vm.loading.riasec = false;
    }

    function allCheck() {
      vm.selectAllFlag = !vm.selectAllFlag;

      vm.discoverers.forEach(function (discoverer) {
        var selectedDiscovererIndex = vm.selectedDiscoverers.findIndex(function (selectedDiscoverer) {
          return selectedDiscoverer.id === discoverer.id;
        });
        if (vm.selectAllFlag && selectedDiscovererIndex < 0) {
          vm.selectedDiscoverers.push(discoverer);
        }
        if (!vm.selectAllFlag && selectedDiscovererIndex > -1) {
          vm.selectedDiscoverers.splice(selectedDiscovererIndex, 1);
        }
      });
    }

    function selectDiscoverer() {
      var shouldSelectAllFlag = false;
      if (vm.discoverers.length && vm.selectedDiscoverers.length >= vm.discoverers.length) {
        shouldSelectAllFlag = true;
        vm.discoverers.forEach(function (discoverer) {
          var hasSelectedDiscoverer = vm.selectedDiscoverers.some(function (selectedDiscoverer) {
            return selectedDiscoverer.id === discoverer.id;
          });
          if (!hasSelectedDiscoverer) {
            shouldSelectAllFlag = false;
          }
        });
      }
      vm.selectAllFlag = shouldSelectAllFlag;
    }

    function setServiceAndScore() {
      vm.serviceLevel = '';
      vm.scorePattern = '';
      vm.selectedServiceLabel = '';
      if (vm.selectedService && vm.selectedService.key
        && vm.selectedScore && vm.selectedScore.key
      ) {
        vm.serviceLevel = vm.selectedService.key;
        vm.scorePattern = vm.selectedScore.key;
        vm.selectedServiceLabel = vm.selectedService.value + ': ' + vm.selectedScore.value;
      }
    }

    vm.resetFilters = function () {
      delete vm.group;
      delete vm.selectedService;
      delete vm.selectedScore;
      delete vm.progress;
      delete vm.riasecSelected;
      delete vm.cluster;
      delete vm.searchKey;
      vm.endDate = new Date(moment(new Date()).add(1, 'days').format());
      vm.startDate = new Date(moment(new Date()).subtract(1, 'years').format());
      filter_selected();
    }

    function filter_selected() {
      vm.categorySelected = false;
      vm.groupId = angular.isUndefined(vm.group) ? '' : vm.group.id;
      vm.endDate = angular.isUndefined(vm.endDate) ? '' : vm.endDate;
      vm.searchKey = angular.isUndefined(vm.searchKey) ? '' : vm.searchKey;
      vm.startDate = angular.isUndefined(vm.startDate) ? '' : vm.startDate;
      vm.category = angular.isUndefined(vm.progress) ? '' : vm.progress.key;
      // vm.major = angular.isUndefined(vm.majorSelected)
      //   ? ''
      //   : vm.majorSelected.id;
      // vm.career = angular.isUndefined(vm.careerSelected)
      //   ? ''
      //   : vm.careerSelected.id;
      vm.riasec = angular.isUndefined(vm.riasecSelected)
        ? ''
        : vm.riasecSelected.key;
      vm.pageSize = angular.isUndefined(vm.selectedPageSize.value)
        ? 10
        : vm.selectedPageSize.value;
      vm.clusterId = angular.isUndefined(vm.cluster)
        ? ''
        : vm.cluster.clusterId;

      setDateRange();
      setServiceAndScore();

      if (vm.category && vm.category !== 'All') vm.categorySelected = true;

      if ((!vm.startDate && vm.endDate) || (vm.startDate && !vm.endDate))
        return;

      var endDate = moment(vm.endDate);
      var startDate = moment(vm.startDate);
      var differenceInStartAndEndDate = endDate.diff(startDate, 'days');

      if (differenceInStartAndEndDate <= 0) {
        toaster.pop('error', APP_CONSTANTS.MESSAGE.startDateGreaterThanEndDate);
        return;
      }

      vm.isDateOpen = false;
      vm.isServiceOpen = false;
      vm.loadedNewData = true;

      vm.selectedDiscoverers = [];

      fetchDiscoverers(
        1,
        vm.pageSize,
        vm.searchKey,
        vm.groupId,
        vm.category,
        vm.riasec,
        vm.role,
        vm.sortBy,
        vm.orderBy,
        vm.startDate,
        vm.endDate,
        vm.clusterId
      );

      $rootScope.ckdFilters = {
        riasecSelected: vm.riasecSelected,
        group: vm.group,
        progress: vm.progress,
        endDate: vm.endDate,
        startDate: vm.startDate,
        searchKey: vm.searchKey,
        selectedService: vm.selectedService,
        selectedScore: vm.selectedScore,
        cluster: vm.cluster,
      }
    }

    function assignDiscoverersToGroup() {
      GroupModal.assignDiscoverersToGroup(
        vm.listGroups,
        vm.selectedDiscoverers,
      ).then(() => {
        vm.loadedNewData = true;
        fetchDiscoverers(
          vm.pageNum,
          vm.pageSize,
          vm.searchKey,
          vm.groupId,
          vm.category,
          vm.riasec,
          vm.role,
          vm.sortBy,
          vm.orderBy,
          vm.startDate,
          vm.endDate,
          vm.clusterId,
        );
      });
    }

    function displayInvitation() {
      vm.showInviteToStart = vm.category === APP_CONSTANTS.EVENT_CATEGORY.NOT_STARTED;
      vm.showNotCompleted = vm.category === APP_CONSTANTS.EVENT_CATEGORY.NOT_COMPLETED;
      vm.showCompletedAssessment = vm.category === APP_CONSTANTS.EVENT_CATEGORY.COMPLETED_ASSESSMENT;
      vm.showStartedProfile = vm.category === APP_CONSTANTS.EVENT_CATEGORY.STARTED_PROFILE;
      vm.showReturnedToProfile = vm.category === APP_CONSTANTS.EVENT_CATEGORY.RETURNED_TO_PROFILE;
      var shouldShowOptions = true;

      vm.selectedDiscoverers.forEach(function (discovererType) {
        switch (discovererType.lastEventCategory) {
          case APP_CONSTANTS.EVENT_CATEGORY.NOT_STARTED: vm.showInviteToStart = true;
            break;
          case APP_CONSTANTS.EVENT_CATEGORY.NOT_COMPLETED: vm.showNotCompleted = true;
            break;
          case APP_CONSTANTS.EVENT_CATEGORY.COMPLETED_ASSESSMENT: vm.showCompletedAssessment = true;
            break;
          case APP_CONSTANTS.EVENT_CATEGORY.STARTED_PROFILE: vm.showStartedProfile = true;
            break;
          case APP_CONSTANTS.EVENT_CATEGORY.RETURNED_TO_PROFILE: vm.showReturnedToProfile = true;
            break;
          default: shouldShowOptions = false;
            break;
        }
      });

      if (!shouldShowOptions) {
        vm.showInviteToStart = false;
        vm.showNotCompleted = false;
        vm.showCompletedAssessment = false;
        vm.showStartedProfile = false;
        vm.showReturnedToProfile = false;
      }

      // TODO: Enable this feature later
      // vm.showRetakeAssessment = (
      //   shouldShowOptions
      //   && !vm.showInviteToStart
      //   && !vm.showNotCompleted
      //   && (
      //     vm.showCompletedAssessment
      //     || vm.showStartedProfile
      //     || vm.showReturnedToProfile
      //   )
      // );

      let showScorePatternOption = vm.scorePattern || vm.showCompletedAssessment || vm.showStartedProfile || vm.showReturnedToProfile;
      let onlyOneCategorySelected = vm.selectedDiscoverers.length && verifyOnlyOneCategorySelected();
      vm.letsConnectOptions.forEach(function (option) {
        option.show = option.messageType === APP_CONSTANTS.CUSTOM
          || option.messageType === vm.category
          || (showScorePatternOption
            && (option.messageType === APP_CONSTANTS.DIFFERENTIATED_CONSISTENT
              || option.messageType === APP_CONSTANTS.DIFFERENTIATED_INCONSISTENT
              || option.messageType === APP_CONSTANTS.UNDIFFERENTIATED
            )
          )
          || (onlyOneCategorySelected
            && vm.selectedDiscoverers.some(function (discoverer) {
              return discoverer.lastEventCategory === option.messageType;
            })
          );
      });
    }

    function calculateDiscovererProgress(discoverer) {
      discoverer.progress = {
        not_started: false,
        not_completed: false,
        completed_assessment: false,
        started_profile: false,
        returned_to_profile: false
      }

      switch (discoverer.lastEventCategory) {
        case APP_CONSTANTS.EVENT_CATEGORY.RETURNED_TO_PROFILE:
          discoverer.progress.returned_to_profile = true;
        case APP_CONSTANTS.EVENT_CATEGORY.STARTED_PROFILE:
          discoverer.progress.started_profile = true;
        case APP_CONSTANTS.EVENT_CATEGORY.COMPLETED_ASSESSMENT:
          discoverer.progress.completed_assessment = true;
        case APP_CONSTANTS.EVENT_CATEGORY.NOT_COMPLETED:
          discoverer.progress.not_completed = true;
        case APP_CONSTANTS.EVENT_CATEGORY.NOT_STARTED:
          discoverer.progress.not_started = true;
        default:
          break;
      }
    }

    function fetchOrganizationClusters(sponsoredOrganizationId) {
      vm.loading.clusters = true;

      OrganizationInfoService.fetchOrganizationClusters(sponsoredOrganizationId)
        .then(function (response) {
          vm.discoverers_clusters.push({
            color: '',
            clusterId: '',
            clusterSetId: '',
            clusterSetName: '',
            clusterName: 'All'
          });

          var clustersList = response.data.data;

          if (clustersList.length) {
            clustersList = mapClusterColors(clustersList);

            vm.discoverers_clusters = vm.discoverers_clusters.concat(
              clustersList
            );
            vm.showClustersFilter = true;

            vm.filterClass = 'col-md-2 col-lg-2';
          }

          $timeout(function () {

            if ($rootScope.ckdFilters.group) {
              vm.group = $rootScope.ckdFilters.group;
              vm.groupId = $rootScope.ckdFilters.group.id;
            }

            if ($rootScope.ckdFilters.riasecSelected) {
              vm.riasecSelected = $rootScope.ckdFilters.riasecSelected;
              vm.riasec = $rootScope.ckdFilters.riasecSelected.key;
            }

            if ($rootScope.ckdFilters.progress) {
              vm.progress = $rootScope.ckdFilters.progress;
              vm.category = $rootScope.ckdFilters.progress.key;
            }

            if ($rootScope.ckdFilters.selectedService && $rootScope.ckdFilters.selectedScore) {
              vm.selectedService = $rootScope.ckdFilters.selectedService;
              vm.selectedScore = $rootScope.ckdFilters.selectedScore;
            }
            setServiceAndScore();

            if ($rootScope.ckdFilters.startDate && $rootScope.ckdFilters.endDate) {
              vm.startDate = $rootScope.ckdFilters.startDate;
              vm.endDate = $rootScope.ckdFilters.endDate;

              setDateRange();
            }

            if ($rootScope.ckdFilters.cluster) {
              vm.cluster = $rootScope.ckdFilters.cluster;
              vm.clusterId = _.get(vm.cluster, 'clusterId', '');
            }

            vm.searchKey = $rootScope.ckdFilters.searchKey;

            fetchDiscoverers(
              vm.pageNum,
              vm.pageSize,
              vm.searchKey,
              vm.groupId,
              vm.category,
              vm.riasec,
              vm.role,
              vm.sortBy,
              vm.orderBy,
              vm.startDate,
              vm.endDate,
              vm.clusterId,
            );

            vm.loading.clusters = false;
          });
        })
        .catch(function (error) {
          toaster.pop('error', error.data.message || error.statusText);
          vm.loading.clusters = false;
        });
    }

    function verifyOnlyOneCategorySelected() {
      var count = 0;
      [
        vm.showInviteToStart,
        vm.showNotCompleted,
        vm.showCompletedAssessment,
        vm.showStartedProfile,
        vm.showReturnedToProfile
      ].forEach(function (categorySelected) {
        if (categorySelected) count++;
      });

      if (count === 1) return true;

      return false;
    }

    vm.getTableData = function (event) {
      var $targetElement = $(event.currentTarget);

      if (
        !$targetElement.hasClass('disabled') &&
        !$targetElement.hasClass('current')
      ) {
        var pageNumber = $targetElement.data('pageNum');

        vm.loadedNewData = true;

        fetchDiscoverers(
          pageNumber,
          vm.pageSize,
          vm.searchKey,
          vm.groupId,
          vm.category,
          vm.riasec,
          vm.role,
          vm.sortBy,
          vm.orderBy,
          vm.startDate,
          vm.endDate,
          vm.clusterId,
        );
      }
    };

    ModalInstanceCtrl.$inject = ['$scope', '$uibModalInstance'];
    function ModalInstanceCtrl($scope, $uibModalInstance) {
      $scope.ok = function () {
        $uibModalInstance.close('closed');
      };

      $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
      };
    }

    function mapClusterColors(clusters) {
      var clusterSetIds = [];

      clusters.forEach(function (cluster) {
        var index = clusterSetIds.indexOf(cluster.clusterSetId);

        if (index < 0) {
          clusterSetIds.push(cluster.clusterSetId);

          cluster.color =
            APP_CONSTANTS.CLUSTER_COLORS[
            clusterSetIds.indexOf(cluster.clusterSetId)
            ] || '';
        } else {
          cluster.color = APP_CONSTANTS.CLUSTER_COLORS[index] || '';
        }
      });

      return clusters;
    }

    vm.sendMessage = function (option) {
      if (!vm.selectedDiscoverers.length) {
        return openBulkEmailModal(option);
      }
      validateDiscoverer(option);
    }
  }
})();
