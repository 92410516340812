(function() {
  'use strict';
  angular.module('app.discovererProgress').config(state);

  state.$inject = ['$stateProvider', 'RouteHelpersProvider'];

  function state($stateProvider, helper) {
    $stateProvider

      .state('app.discoverersprogress', {
        url: '/discoverers/progress',
        title: 'Discoverers Progress',
        templateUrl: helper.basepath(
          'discoverersProgress/views/discoverersProgress.html'
        ),
        controller: 'DiscoverersProgressController',
        controllerAs: 'vm',
        params: {
          parameter: null
        },
        resolve: helper.resolveFor(
          'modernizr',
          'icons',
          'datatables',
          'ui.select',
          'ngDialog',
          'ngWig'
        ),
        secured: true
      })
      .state('app.discovererdetails', {
        url: '/discoverers/:discovererId/details',
        title: 'Discoverers Details',
        templateUrl: helper.basepath(
          'discoverersProgress/views/discovererDetails.html'
        ),
        controller: 'DiscovererDetailsController',
        controllerAs: 'vm',
        resolve: helper.resolveFor('modernizr', 'icons', 'datatables'),
        secured: true
      });
  }
})();
