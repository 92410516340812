(function() {
  'use strict';

  angular
    .module('app.shared')
    .controller('TopnavbarController', TopnavbarController);

  TopnavbarController.$inject = [
    'toaster',
    'SessionService',
    '$state',
    'logoutService',
    '$rootScope',
    '$scope',
    '$timeout',
    'CommonNotificationService',
    'NotificationViewService',
    '$uibModal',
    'APP_CONSTANTS'
  ];

  function TopnavbarController(
    toaster,
    SessionService,
    $state,
    logoutService,
    $rootScope,
    $scope,
    $timeout,
    CommonNotificationService,
    NotificationViewService,
    $uibModal,
    APP_CONSTANTS
  ) {
    var self = this;

    self.logout = logout;
    self.backToDashboard = backToDashboard;
    self.getNotification = getNotification;
    self.notificationSeen = notificationSeen;
    self.primaryTokenExist = primaryTokenExist;
    self.tempAccountManagerTokenExist = tempAccountManagerTokenExist;
    self.backToAccountManagerDashboard = backToAccountManagerDashboard;
    self.menuItems = APP_CONSTANTS.topNavBarMenuItems;

    self.primaryTokenFlag = false;
    self.hideBackToDashboardFlag = false;
    self.tempAccountManagerTokenFlag = false;
    var loginUserDetail = SessionService.getUser();

    self.pic = loginUserDetail.profileImage;
    self.dismissNotification = dismissNotification;
    self.isSuperAdmin = loginUserDetail.isSuperAdmin;
    self.getNotificationDetail = getNotificationDetail;
    var primaryTokenDetail = SessionService.getAdminToken() || {};
    var tempAccountManagerTokenDetail =
      SessionService.getAccountManagerToken() || {};

    self.role = SessionService.getRoles();
    self.name = loginUserDetail.firstName + ' ' + loginUserDetail.lastName;

    var notificationIconClick = true;

    self.showOrganizationFlag = false;

    if (self.role.indexOf('accountManager') < 0) {
      if (
        self.role.indexOf('counselor') >= 0 ||
        self.role.indexOf('technical') >=0 ||
        self.role.indexOf('organizationManager') >= 0
      )
        self.showOrganizationFlag = true;
    }

    function resetSettingsTour() {
      self.isSettingsTourEnabled = false;
      self.isDropdownSettingsOpen = false;
    };
    resetSettingsTour();
    $scope.$on('appTourStart', function (event, params) {
      if (!params.isFirstTime || !$rootScope.isFirstMenuActive) return;
      self.isSettingsTourEnabled = true;
      self.isDropdownSettingsOpen = true;
    });
    $scope.$on('appTourStepChange', resetSettingsTour);
    $scope.$on('appTourEnd', resetSettingsTour);
    angular.element(document)
      .on('keydown', function (e) {
        if (!self.isSettingsTourEnabled || e.which != 27) return;
        e.preventDefault();
        // the preventDefault is not working as expected, need to open DropdownSettings
        $timeout(function () {
          self.isDropdownSettingsOpen = true;
        }, 100);
      });

    //  self.notificationList={};

    setInterval(function() {
      self.getNotification();
    }, 36000000);

    // TODO
    // if (!self.isSuperAdmin) {
    //         if (data.customerId == null) {
    //             self.notificationList.count++;
    //         } else if (data.customerId == loginUserDetail.customerId) {
    //             data.roles = data.roles.join('~').toLowerCase().split('~');
    //             if (!_.isEmpty(_.intersection(loginUserRoles, data.roles))) {
    //                 self.notificationList.count++;
    //             }
    //         }
    //     }

    function logout() {
      $rootScope.app.logOutLoding = true;
      logoutService
        .logoutApiPost()
        .then(function() {
          $rootScope.app.logOutLoding = false;
          $state.go('login');
        })
        .catch(function(error) {
          $rootScope.app.logOutLoding = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function backToDashboard() {
      if (_.isEmpty(primaryTokenDetail)) return;

      $rootScope.app.logOutLoding = true;

      logoutService.logoutCustomerApiPost();

      $rootScope.app.logOutLoding = false;
      SessionService.setUser(primaryTokenDetail.user);
      SessionService.setOrganizationImage(primaryTokenDetail.customerImg || '');
      SessionService.removeAdminToken();
      $state.go('login');
    }

    function backToAccountManagerDashboard() {
      if (_.isEmpty(tempAccountManagerTokenDetail)) return;

      $rootScope.app.logOutLoding = true;

      logoutService.logoutCustomerApiPost();

      $rootScope.app.logOutLoding = false;
      SessionService.setUser(tempAccountManagerTokenDetail.user);
      SessionService.setOrganizationImage(
        tempAccountManagerTokenDetail.customerImg || ''
      );
      SessionService.removeAccountManagerToken();
      $state.go('login');
    }

    function notificationSeen() {
      if (!loginUserDetail.isSuperAdmin) {
        if (notificationIconClick) {
          if (self.notificationList) {
            if (self.notificationList.count > 0) {
              self.notificationList.count = 0;
              CommonNotificationService.updateUserLastSeenNotification(
                loginUserDetail.id
              )
                .then(function() {})
                .catch(function(error) {
                  toaster.pop('error', error.data.message || error.statusText);
                });
            }
          }
        }

        notificationIconClick = !notificationIconClick;
      }
    }

    function getNotification() {
      self.notificationLoading = true;

      if (!loginUserDetail.isSuperAdmin) {
        CommonNotificationService.getUserNotification(loginUserDetail.id)
          .then(function(result) {
            self.notificationList = result.data.data;
            self.notificationLoading = false;
            if (
              self.notificationList &&
              self.notificationList.unseenNotification &&
              self.notificationList.unseenNotification.length > 0
            ) {
              var popUp = _.filter(
                self.notificationList.unseenNotification,
                function(notification) {
                  return notification.status === 'Scheduled Maintenance';
                }
              );

              if (popUp.length > 0) {
                toaster.pop('info', popUp[0].notification);
                self.dismissNotification(popUp[0].id);
              }
            }
          })
          .catch(function(error) {
            toaster.pop('error', error.data.message || error.statusText);
            self.notificationLoading = false;
          });
      }
    }

    function getNotificationDetail(notificationId) {
      var modalScope = $rootScope.$new();

      NotificationViewService.fetchNotificationDetail(notificationId).then(
        function(result) {
          self.notificationDetail = result.data.data;
          modalScope.modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'notificationDetail',
            controller: function() {
              var vm = this;

              vm.notificationDetail = result.data.data;
              vm.close = function() {
                modalScope.modalInstance.close();
              };
            },
            controllerAs: 'vm',
            size: 'md',
            scope: modalScope
          });
        },
        function(error) {
          toaster.pop('error', error.data.message || error.statusText);
        }
      );
    }

    function primaryTokenExist() {
      if (_.isEmpty(primaryTokenDetail)) {
        self.primaryTokenFlag = false;
      } else {
        self.primaryTokenFlag = true;
        self.hideBackToDashboardFlag = true;
      }
    }

    function tempAccountManagerTokenExist() {
      if (_.isEmpty(tempAccountManagerTokenDetail)) {
        self.tempAccountManagerTokenFlag = false;
      } else {
        self.tempAccountManagerTokenFlag = true;
        self.hideBackToDashboardFlag = false;
      }
    }

    function dismissNotification(notificationId) {
      self.notificationLoading = true;
      CommonNotificationService.dismissUserNotification(
        loginUserDetail.id,
        notificationId
      )
        .then(function() {
          self.getNotification();
        })
        .catch(function(error) {
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    self.getNotification();
    self.primaryTokenExist();
    self.tempAccountManagerTokenExist();
  }
})();
