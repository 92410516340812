(function() {
  'use strict';
  angular.module('app.organization').config(state);

  state.$inject = ['$stateProvider', 'RouteHelpersProvider'];

  function state($stateProvider, helper) {
    $stateProvider
      .state('app.orgprofile', {
        url: '/organization',
        abstract: true,
        templateUrl: helper.basepath('organization/views/index.html'),
        controller: 'UserOrganizationController',
        controllerAs: 'vm',
        resolve: helper.resolveFor(
          'modernizr',
          'icons',
          'ngImgCrop',
          'filestyle'
        ),
        secured: true
      })
      .state('app.orgprofile.setting', {
        url: '/setting',
        title: 'Organization Profile',
        templateUrl: helper.basepath(
          'organization/views/partials/organizationProfile.html'
        ),
        controller: 'OrganizationProfileController',
        controllerAs: 'org',
        resolve: helper.resolveFor(
          'modernizr',
          'icons',
          'ngImgCrop',
          'ngDialog',
          'filestyle',
          'ui.select'
        ),
        secured: true
      })
      .state('app.orgprofile.user_management', {
        url: '/user_management',
        title: 'User Management',
        templateUrl: helper.basepath(
          'organization/views/partials/userInvitation.html'
        ),
        controller: 'UserInvitationController',
        controllerAs: 'vm',
        resolve: helper.resolveFor(
          'modernizr',
          'icons',
          'datatables',
          'ngDialog',
          'ui.select'
        ),
        secured: true
      });
  }
})();
