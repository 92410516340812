(function() {
  'use strict';

  angular
    .module('app.shared')
    .factory('PublishCareerService', PublishCareerService);

  PublishCareerService.$inject = [
    'APP_CONSTANTS',
    'ApiCaller',
    '$filter',
    '$q'
  ];

  function PublishCareerService(APP_CONSTANTS, ApiCaller, $filter, $q) {
    var self = this;

    self.setCareerPublishDate = setCareerPublishDate;

    function setCareerPublishDate(workgroupId, careerId, publishDate) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.setCareerPublishDate,
        APP_CONSTANTS.rootUrl,
        workgroupId,
        careerId
      );

      ApiCaller.apiPut(url, publishDate)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    return self;
  }
})();
