(function() {
  'use strict';
  angular
    .module('app.shared')
    .controller('AddNewCareerController', AddNewCareerController);

  AddNewCareerController.$inject = [
    'APP_CONSTANTS',
    'toaster',
    '$scope',
    '$uibModalInstance',
    'AddNewCareerService',
    'ProductService',
    'selectedWorkgroupId',
    'selectedProductId'
  ];

  function AddNewCareerController(
    APP_CONSTANTS,
    toaster,
    $scope,
    $uibModalInstance,
    AddNewCareerService,
    ProductService,
    selectedWorkgroupId,
    selectedProductId
  ) {
    var vm = this;

    vm.loading = {};
    vm.newCareer = {};
    vm.submit = submit;
    vm.addNewMajorFormError = {};
    vm.validateCareerId = validateCareerId;
    vm.productId = ProductService.getProductId();
    vm.dismissModal = dismissModal;

    getUniqueCareerId(vm.productId, selectedWorkgroupId);

    function getUniqueCareerId(productId, workgroupId) {
      vm.loading.productInfo = true;

      AddNewCareerService.getUniqueCareerId(productId, workgroupId)
        .then(function(result) {
          vm.loading.productInfo = false;

          vm.newCareer.id = _.get(result, 'data.uniqueCareerId', '');
        })
        .catch(function(error) {
          vm.loading.productInfo = false;

          dismissModal();
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function validateCareerId() {
      var splittedCareerIdArray = vm.newCareer.id.split('-');
      var splittedProductIdArray = selectedProductId.split('.');
      var splittedWorkgroupIdArray = selectedWorkgroupId.split('.');

      var generatedCareerId =
        'ckc' +
        '.' +
        splittedProductIdArray[1] +
        '.' +
        splittedProductIdArray[2] +
        '-' +
        splittedWorkgroupIdArray[3] +
        '.' +
        splittedWorkgroupIdArray[4];
      var userDefinedCareerId =
        splittedCareerIdArray[0] + '-' + splittedCareerIdArray[1];

      if (generatedCareerId !== userDefinedCareerId) {
        vm.addNewCareerFormError.id = true;
      } else {
        vm.addNewCareerFormError.id = false;
      }
    }

    function submit() {
      vm.loading.productInfo = true;

      var valuesArray = [];

      vm.newCareer.values &&
        vm.newCareer.values.split(',').forEach(function(value) {
          valuesArray.push(value.trim());
        });

      vm.newCareer.values = valuesArray;

      AddNewCareerService.addNewCareer(
        vm.productId,
        selectedWorkgroupId,
        vm.newCareer
      )
        .then(function() {
          vm.loading.productInfo = false;

          dismissModal();
          $scope.$parent.vm.fetchWorkGroupCareers(selectedWorkgroupId);
          toaster.pop('success', APP_CONSTANTS.MESSAGE.careerSuccessfullyAdded);
        })
        .catch(function(error) {
          vm.loading.productInfo = false;

          dismissModal();
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function dismissModal() {
      $uibModalInstance.dismiss();
    }
  }
})();
