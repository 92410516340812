(function() {
  'use strict';
  angular
    .module('app.shared')
    .controller('AddNewBatchController', AddNewBatchController);

  AddNewBatchController.$inject = [
    'APP_CONSTANTS',
    '$timeout',
    '$scope',
    'toaster',
    '$rootScope',
    '$filter',
    '$uibModalInstance',
    'AddBatchService',
    'ProductService'
  ];

  function AddNewBatchController(
    APP_CONSTANTS,
    $timeout,
    $scope,
    toaster,
    $rootScope,
    $filter,
    $uibModalInstance,
    AddBatchService,
    ProductService
  ) {
    var vm = this;

    vm.loading = {};
    vm.batch = {};
    vm.submit = submit;
    vm.xlsxFile = '';
    vm.selectedDate = '';
    vm.addNewBatch = addNewBatch;
    vm.dismissModal = dismissModal;
    vm.getBatchTypeOptions = getBatchTypeOptions;
    vm.productId = ProductService.getProductId();
    vm.checkBoxModel = {
      isPublishNow: false
    };

    // vm.dateOptions = {
    //   minDate: $rootScope
    //     .moment(new Date())
    //     .add(1, 'day')
    //     .format()
    // };

    function getBatchTypeOptions() {
      vm.batchTypeOptions = APP_CONSTANTS.batchTypeOptions;
    }

    function submit(valid) {
      vm.formSubmit = true;
      vm.fileValid = true;
      vm.batch.file = vm.xlsxFile;
      vm.batch.publishNow = vm.checkBoxModel.isPublishNow;
      // vm.batch.publishDate = $rootScope
      //   .moment(vm.selectedDate)
      //   .format('YYYY-MM-DD');

      if (vm.xlsxFile === 'invalid') {
        vm.fileValid = false;
      }

      if (!valid || !vm.fileValid) {
        return;
      }

      addNewBatch(vm.batch, vm.productId);
    }

    function addNewBatch(batch, productId) {
      vm.loading.productInfo = true;

      var nonExistingCareerIds;
      var nonExistingMajorIds;
      var nonExistingSchoolIds;

      AddBatchService.addNewBatch(batch, productId)
        .then(function(response) {
          var message;

          message = $filter('sprintf')(
            APP_CONSTANTS.MESSAGE.batchUploadSuccess,
            batch.type.name
          );

          if (
            batch.type === APP_CONSTANTS.batchTypeOptions[4] ||
            batch.type === APP_CONSTANTS.batchTypeOptions[5]
          ) {
            nonExistingCareerIds = response.data.nonExistingCareerIds;
            nonExistingMajorIds = response.data.nonExistingMajorIds;

            if (!nonExistingCareerIds.length && !nonExistingMajorIds.length) {
              message = $filter('sprintf')(
                APP_CONSTANTS.MESSAGE.batchUploadSuccess,
                batch.type.name
              );
            } else if (
              !nonExistingCareerIds.length &&
              nonExistingMajorIds.length
            ) {
              message = $filter('sprintf')(
                APP_CONSTANTS.MESSAGE.batchUploadSuccessWithNonExistingMajors,
                batch.type.name,
                nonExistingMajorIds.join(', ')
              );
            } else if (
              nonExistingCareerIds.length &&
              !nonExistingMajorIds.length
            ) {
              message = $filter('sprintf')(
                APP_CONSTANTS.MESSAGE.batchUploadSuccessWithNonExistingCareers,
                batch.type.name,
                nonExistingCareerIds.join(', ')
              );
            } else {
              message = $filter('sprintf')(
                APP_CONSTANTS.MESSAGE
                  .batchUploadSuccessWithNonExistingCareersAndMajors,
                batch.type.name,
                nonExistingCareerIds.join(', '),
                nonExistingMajorIds.join(', ')
              );
            }
          }

          if (batch.type === APP_CONSTANTS.batchTypeOptions[6]) {
            nonExistingMajorIds = response.data.nonExistingMajorIds;
            nonExistingSchoolIds = response.data.nonExistingSchoolIds;

            if (!nonExistingSchoolIds.length && !nonExistingMajorIds.length) {
              message = $filter('sprintf')(
                APP_CONSTANTS.MESSAGE.batchUploadSuccess,
                batch.type.name
              );
            } else if (
              !nonExistingSchoolIds.length &&
              nonExistingMajorIds.length
            ) {
              message = $filter('sprintf')(
                APP_CONSTANTS.MESSAGE.batchUploadSuccessWithNonExistingMajors,
                batch.type.name,
                nonExistingMajorIds.join(', ')
              );
            } else if (
              nonExistingSchoolIds.length &&
              !nonExistingMajorIds.length
            ) {
              message = $filter('sprintf')(
                APP_CONSTANTS.MESSAGE.batchUploadSuccessWithNonExistingSchools,
                batch.type.name,
                nonExistingSchoolIds.join(', ')
              );
            } else {
              message = $filter('sprintf')(
                APP_CONSTANTS.MESSAGE
                  .batchUploadSuccessWithNonExistingSchoolsAndMajors,
                batch.type.name,
                nonExistingSchoolIds.join(', '),
                nonExistingMajorIds.join(', ')
              );
            }
          }

          $scope.$parent.vm.fetchBatchesByProductId();

          toaster.pop('success', message);
          dismissModal();
        })
        .catch(function(error) {
          vm.loading.createGroup = false;
          dismissModal();
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function dismissModal() {
      $uibModalInstance.dismiss();
    }
  }
})();
