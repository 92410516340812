(function() {
  'use strict';

  angular.module('careerKeyCentral').directive('compareTo', compareTo);

  function compareTo() {
    return {
        require: "ngModel",
        scope: {
          otherModelValue: "=compareTo"
        },
        link: function(scope, element, attributes, ngModel) {
  
          ngModel.$validators.compareTo = function(modelValue) {
            let myValue = modelValue || '';
            let theirValue = scope.otherModelValue || '';
            return myValue.length == theirValue.length && myValue == theirValue;
          };
  
          scope.$watch("otherModelValue", function() {
            ngModel.$validate();
          });
        }
      };
  }
})();
