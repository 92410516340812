(function() {
  'use strict';
  angular
    .module('app.shared')
    .controller('DiscoverersController', DiscoverersController);

  DiscoverersController.$inject = [
    'APP_CONSTANTS',
    '$scope',
    'DiscoverersService',
    'toaster'
  ];

  function DiscoverersController(
    APP_CONSTANTS,
    $scope,
    DiscoverersService,
    toaster
  ) {
    var vm = this;

    vm.fetchDiscoverersList = fetchDiscoverersList;

    $scope.$parent.vm.setActiveTab();
    $scope.$parent.vm.generateBreadCrumbs(
      APP_CONSTANTS.CKADMIN_TABS.DISCOVERERS
    );

    vm.searchkey = '';

    vm.dtOptions = {
      paging: false,
      searching: false,
      info: false,
      order: [],
      columnDefs: [
        {
          orderable: false,
          targets: [0, 1, 2, 3, 4]
        }
      ]
    };

    vm.fetchDiscoverersList(vm.searchKey);

    vm.searchByKey = function(event) {
      if (event.keyCode === 13) {
        vm.fetchDiscoverersList(vm.searchKey);
      }
    };

    function fetchDiscoverersList(searchKey) {
      vm.fetchingDiscoverers = true;
      DiscoverersService.fetchDiscoverersList(searchKey).then(
        function(response) {
          vm.discoverersList = response.data.data;

          vm.fetchingDiscoverers = false;
        },
        function(error) {
          toaster.pop({
            type: 'error',
            body: error.data.message || error.statusText,
            toasterId: 'ckAdmin'
          });

          vm.fetchingDiscoverers = false;
        }
      );
    }
  }
})();
