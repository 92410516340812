(function () {
  'use strict';

  angular.module('app.pathAdvisor')
    .config([
      '$stateProvider',
      'RouteHelpersProvider',
      state
    ]);

  function state(
    $stateProvider,
    helper
  ) {
    $stateProvider
      .state('app.pathadvisor', {
        url: '/pathadvisor',
        title: 'PathAdvisor: Dashboard',
        templateUrl: helper.basepath(
          'pathAdvisor/pathAdvisor.html'
        ),
        controller: 'PathAdvisorController',
        controllerAs: 'vm',
        params: {
          parameter: null
        },
        resolve: helper.resolveFor(
          'datatables',
          'icons',
          'modernizr',
          'ngDialog',
          'ngWig',
          'ui.select'
        ),
        secured: true
      });
  }
})();
