(function() {
  'use strict';

  angular
    .module('app.customerAccount')
    .controller('CancelSubscriptionController', CancelSubscriptionController);

  CancelSubscriptionController.$inject = [
    '$uibModal',
    '$state',
    'APP_CONSTANTS',
    'toaster',
    '$uibModalInstance',
    'SessionService',
    'CancelSubscriptionService',
    'data',
    '$location',
    '$anchorScroll'
  ];
  function CancelSubscriptionController(
    $uibModal,
    $state,
    APP_CONSTANTS,
    toaster,
    $uibModalInstance,
    SessionService,
    CancelSubscriptionService,
    data,
    $location,
    $anchorScroll
  ) {
    var vm = this;

    vm.loading = {};
    vm.noPaymentOrBillingDetail = false;
    vm.noActiveSubscriptions =
      _.isEmpty(data.subscription) ||
      data.subscription.actualStatus.toLowerCase() !==
        APP_CONSTANTS.SUBSCRIPTION_STATUS.ACTIVE ||
      data.subscription.type.toLowerCase() !==
        APP_CONSTANTS.SUBSCRIPTION_TYPE.AUTO_RENEWAL
        ? true
        : false;
    vm.subscription = !vm.noActiveSubscriptions ? data.subscription : {};
    vm.isPeriodUnitMonthly =
      !vm.noActiveSubscriptions &&
      vm.subscription.periodUnit.toLowerCase() === 'month'
        ? true
        : false;

    vm.cancel = cancel;
    vm.submit = submit;

    function submit(form) {
      vm.form = form;
      vm.deleteSubscriptions = [];

      if (!vm.choice) {
        $location.hash('myModalLabel');
        $anchorScroll();
        toaster.clear('cancelBillingSubscription');
        toaster.pop({
          type: 'error',
          body: APP_CONSTANTS.MESSAGE.optionSelectionValidation,
          showCloseButton: true,
          toasterId: 'cancelBillingSubscription',
          timeout: 5000
        });

        return;
      }
      if (vm.choice === 'goBack') {
        cancel();

        return;
      }

      var subscriptionsList = [];

      subscriptionsList.push(vm.subscription.id);

      var payload = {
        id: subscriptionsList
      };

      vm.loading.deleteSubscriptions = true;

      CancelSubscriptionService.deleteSubscriptions(payload)
        .then(function() {
          vm.loading.deleteSubscriptions = false;
          toaster.pop(
            'success',
            APP_CONSTANTS.MESSAGE.subscriptionCancellationSuccess
          );
          closePopup();
        })
        .catch(function() {
          vm.loading.deleteSubscriptions = false;
          toaster.pop({
            type: 'error',
            body: APP_CONSTANTS.MESSAGE.subscriptionCancellationFailure,
            toasterId: 'subscriptionCancellation'
          });
        });
    }

    function closePopup() {
      $uibModalInstance.close('closed');
    }

    function cancel() {
      $uibModalInstance.dismiss('cancel');
    }
  }
})();
