(function() {
  'use strict';

  angular
    .module('app.shared')
    .factory('AddNewMajorService', AddNewMajorService);

  AddNewMajorService.$inject = ['APP_CONSTANTS', 'ApiCaller', '$filter', '$q'];

  function AddNewMajorService(APP_CONSTANTS, ApiCaller, $filter, $q) {
    var self = this;

    self.addNewMajor = addNewMajor;
    self.getUniqueMajorId = getUniqueMajorId;

    function getUniqueMajorId(productId, workgroupId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.getUniqueMajorId,
        APP_CONSTANTS.rootUrl,
        productId,
        workgroupId
      );

      ApiCaller.apiGet(url)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function addNewMajor(productId, workgroupId, majorDetails) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.addNewMajor,
        APP_CONSTANTS.rootUrl,
        productId,
        workgroupId
      );

      ApiCaller.apiPost(url, { majorDetails: majorDetails })
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    return self;
  }
})();
