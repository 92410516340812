(function() {
  'use strict';

  angular
    .module('app.user')
    .factory('ForgotPasswordService', ForgotPasswordService);

  ForgotPasswordService.$inject = [
    'APP_CONSTANTS',
    'ApiCaller',
    '$q',
    '$filter'
  ];

  function ForgotPasswordService(APP_CONSTANTS, ApiCaller, $q, $filter) {
    var vm = this;

    vm.resetRequest = resetRequest;

    function resetRequest(email) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.forgotPassword,
        APP_CONSTANTS.rootUrl
      );

      ApiCaller.apiPost(url, { emailId: email }).then(
        function(result) {
          deferred.resolve(result);
        },
        function(err) {
          deferred.reject(err);
        }
      );

      return deferred.promise;
    }

    return vm;
  }
})();
