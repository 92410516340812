(function() {
  'use strict';

  angular
    .module('app.sponsoredOrganization')
    .controller('DisplayInstructionsController', DisplayInstructionsController);

  DisplayInstructionsController.$inject = [
    'APP_CONSTANTS',
    '$uibModalInstance'
  ];

  function DisplayInstructionsController(APP_CONSTANTS, $uibModalInstance) {
    var vm = this;

    vm.closePopup = closePopup;

    vm.constants = {
      body: APP_CONSTANTS.ckdInstallationBody,
      header: APP_CONSTANTS.ckdInstallationHeader
    };

    function closePopup() {
      $uibModalInstance.close('closed');
    }
  }
})();
