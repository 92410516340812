(function() {
  'use strict';
  angular
    .module('app.owner')
    .controller('OwnerDashboardController', OwnerDashboardController);

  function OwnerDashboardController(
  ) {
    var vm = this;

    vm.loading = {};

  }
})();
