(function() {
  'use strict';
  angular.module('app.user').controller('LoginController', LoginController);

  LoginController.$inject = [
    'loginService',
    'InvitationService',
    'toaster',
    '$stateParams',
    '$rootScope',
    'SessionService',
    'APP_CONSTANTS',
    'ngDialog',
    '$state',
  ];

  function LoginController(
    loginService,
    InvitationService,
    toaster,
    $stateParams,
    $rootScope,
    SessionService,
    APP_CONSTANTS,
    ngDialog,
    $state,
  ) {
    var vm = this;

    vm.demo = demo;
    vm.flag = false;
    vm.submit = submit;
    vm.errorMessage = null;
    vm.isFormSubmit = false;
    vm.showPassword = false;
    vm.loginWithGoogle = loginWithGoogle;
    vm.viewPassword = viewPassword;
    vm.openDemoDialog = openDemoDialog;
    vm.activateDetails = activateDetails;
    vm.openActivateUserDialog = openActivateUserDialog;
    vm.hideLoginPage =
      SessionService.getUser() ||
      SessionService.getAdminToken() ||
      SessionService.getAccountManagerToken();
    vm.regex = {
      email: APP_CONSTANTS.REGEX.EMAIL,
    };

    authenticateUser();

    function authenticateUser() {
      if ($state.params.token) {
        SessionService.removeUser();
        SessionService.removeAdminToken();
        SessionService.removeAccountManagerToken();
        vm.hideLoginPage = false;
        return login({ token: $state.params.token });
      }

      if (SessionService.isAuthenticatedUser()) {
        $state.go(loginService.getNextState());
      }
    }

    function loginWithGoogle(credential) {
            var payload = {
              username: '',
              password: '',
              activationId: '',
              oauth: {
                token: credential.token,
                type: 'google'
              }
            };

            login(payload);
    }

    function submit(valid) {
      vm.isFormSubmit = true;
      if (!valid) {
        return;
      }
      vm.account.activationId =
        typeof $stateParams.userId === 'undefined' ? null : $stateParams.userId;
      login(vm.account);
    }

    function login(payload) {
      vm.loading = true;
      loginService
        .loginApiPost(payload)
        .then(function() {
          vm.loading = false;
          $state.go(loginService.getNextState());
        })
        .catch(function(error) {
          vm.loading = false;
          // TODO: Would be better if the error message be displayed at the top of the login wrapper.
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function openActivateUserDialog() {
      ngDialog.openConfirm({
        template: 'activateDialog',
        className: 'ngdialog-theme-default'
      });
    }

    function activateDetails() {
      if (vm.activateForm.$valid) {
        InvitationService.fetchByEmail(vm.activate.email)
          .then(function(result) {
            ngDialog.close();
            if (result.data.data.length === 0) {
              toaster.pop({
                type: 'error',
                body: APP_CONSTANTS.MESSAGE.emailNotFound,
                showCloseButton: true,
                bodyOutputType: 'trustedHtml',
                timeout: 5000,
                onHideCallback: function() {
                  $state.go('login');
                }
              });
            } else {
              $state.go('invitation', {
                invitationId: result.data.data.invitationId
              });
            }
          })
          .catch(function(error) {
            ngDialog.close();
            toaster.pop({
              type: 'error',
              body: error.data.message || error.statusText,
              showCloseButton: true,
              bodyOutputType: 'trustedHtml',
              timeout: 5000,
              onHideCallback: function() {
                $state.go('login');
              }
            });
          });
      } else {
        vm.activateForm.activate_email.$dirty = true;
      }
    }

    function openDemoDialog() {
      ngDialog.openConfirm({
        template: 'demoDialog',
        className: 'ngdialog-theme-default'
      });
    }

    function demo() {
      if (vm.demoForm.$valid) {
        ngDialog.close();
        loginService.sendDemo(vm.email).then(
          function() {
            toaster.pop('success', APP_CONSTANTS.MESSAGE.demoMessage);
            $state.go('mailbox');
          },
          function(error) {
            toaster.pop('error', error.data.message || error.statusText);
          }
        );
      } else {
        vm.demoForm.email.$dirty = true;
      }
    }

    function viewPassword() {
      vm.showPassword = !vm.showPassword;
    }

    $rootScope.registrationDetail = {};
  }
})();
