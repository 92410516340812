(function() {
  'use strict';

  angular
    .module('app.shared')
    .factory('DiscoverersService', DiscoverersService);

  DiscoverersService.$inject = ['APP_CONSTANTS', 'ApiCaller', '$q', '$filter'];

  function DiscoverersService(APP_CONSTANTS, ApiCaller, $q, $filter) {
    var self = this;

    self.fetchDiscoverersList = fetchDiscoverersList;

    function fetchDiscoverersList(searchKey) {
      var deferred = $q.defer();
      var parameters = {
        searchKey: searchKey
      };

      var url = $filter('sprintf')(
        APP_CONSTANTS.getAllDiscoverers,
        APP_CONSTANTS.rootUrl,
        $.param(parameters)
      );

      ApiCaller.apiGet(url).then(
        function(result) {
          deferred.resolve(result);
        },
        function(err) {
          deferred.reject(err);
        }
      );

      return deferred.promise;
    }

    return self;
  }
})();
