(function() {
  'use strict';
  angular
    .module('app.user')
    .controller('ResetPasswordController', ResetPasswordController);

  ResetPasswordController.$inject = [
    '$stateParams',
    'toaster',
    '$state',
    'APP_CONSTANTS',
    '$scope',
    'PasswordService'
  ];

  function ResetPasswordController(
    $stateParams,
    toaster,
    $state,
    APP_CONSTANTS,
    $scope,
    PasswordService
  ) {
    var vm = this;

    if (!$stateParams.userId) $state.go('login');

    vm.loading = {};
    vm.regex = {
      password: APP_CONSTANTS.REGEX.PASSWORD
    };
    vm.showPassword = {
      password: false,
      confirmPassword: false
    };

    vm.viewPassword = viewPassword;
    vm.submit = submit;

    vm.loading.resetPassword = true;
    PasswordService.fetchUser($stateParams.userId)
      .then(function() {
        vm.loading.resetPassword = false;
      })
      .catch(function(error) {
        toaster.pop({
          type: 'error',
          body: error.data.message || error.statusText,
          showCloseButton: true,
          bodyOutputType: 'trustedHtml',
          timeout: 3000,
          onHideCallback: function() {
            vm.loading.resetPassword = false;
            $state.go('login');
          }
        });
      });

    function submit() {
      var payload = {
        password: vm.password,
        token: $stateParams.userId,
        confirmPassword: vm.confirmPassword
      };

      if (vm.changePasswordForm.$valid) {
        vm.loading.resetPassword = true;

        PasswordService.resetPassword(payload)
          .then(function() {
            toaster.pop({
              type: 'success',
              body: APP_CONSTANTS.MESSAGE.resetPassword,
              showCloseButton: true,
              bodyOutputType: 'trustedHtml',
              timeout: 3000,
              onHideCallback: function() {
                vm.loading.resetPassword = false;
                $state.go('login');
              }
            });
          })
          .catch(function(error) {
            vm.loading.resetPassword = false;
            toaster.pop(
              'error',
              'Error',
              error.data.message || error.statusText
            );
          });
      }
    }

    function viewPassword(key) {
      vm.showPassword[key] = !vm.showPassword[key];
    }
  }
})();
