(function() {
  'use strict';
  angular
    .module('app.sponsoredOrganization')
    .controller('OrganizationInfoController', OrganizationInfoController);

  OrganizationInfoController.$inject = [
    'APP_CONSTANTS',
    'SessionService',
    '$state',
    'toaster',
    '$uibModal',
    'AccountUpdateService',
    'RegistrationService',
    'OrganizationInfoService',
    'NewSubscriptionService',
    'ngDialog',
    '$filter',
    '$scope',
    '$rootScope'
  ];

  function OrganizationInfoController(
    APP_CONSTANTS,
    SessionService,
    $state,
    toaster,
    $uibModal,
    AccountUpdateService,
    RegistrationService,
    OrganizationInfoService,
    NewSubscriptionService,
    ngDialog,
    $filter,
    $scope,
    $rootScope
  ) {
    var vm = this;

    var user = SessionService.getUser();

    if (
      !(
        user.roles.isOrganizationManager ||
        user.roles.isAccountManager ||
        user.roles.isCounselor || 
        user.roles.isTechnicalContact
      )
    ) {
      $state.go('app.ownerdashboard');

      return;
    }

    vm.loading = {};
    vm.ckdPercentage = 0;
    vm.cdpPercentage = 0;
    vm.usedQuantity = 0;
    vm.totalQuantity = 0;
    vm.organizationInfo = {};
    vm.organizationInfo.isNotPrimaryContact = true;

    vm.open = open;
    vm.submit = submit;
    vm.fetchCountries = fetchCountries;
    vm.displayConfirmationPopup = displayConfirmationPopup;
    vm.availableLanguages = APP_CONSTANTS.availableLanguages;

    vm.regex = {
      email: APP_CONSTANTS.REGEX.EMAIL
    };

    var modalInstance = '';

    activate();

    function activate() {
      fetchOrganizationInformation();
      fetchOrganizationImage();
      fetchCountries();
      fetchDiscoverersCount();
    }

    function fetchOrganizationInformation() {
      vm.loading.organizationInfo = true;
      OrganizationInfoService.fetchOrganizationInfo(
        user.sponsoredOrganizationId
      )
        .then(function(result) {
          vm.loading.organizationInfo = false;
          vm.organizationInfo = _.get(result, 'data.data', {});
          vm.organizationInfo.country = vm.organizationInfo.locale.country;
          vm.organizationInfo.isNotPrimaryContact =
            vm.organizationInfo.primaryContact.id !== user.id ? true : false;
          vm.organizationInfo.language = _.isEmpty(
            vm.organizationInfo.locale.language
          )
            ? vm.availableLanguages[0]
            : vm.organizationInfo.locale.language;

          if (!vm.organizationInfo.isNotPrimaryContact)
            fetchAccountAndOrganizationManagers();
        })
        .catch(function(error) {
          vm.loading.organizationInfo = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function fetchOrganizationImage() {
      vm.loading.organizationImage = true;

      OrganizationInfoService.getOrganizationImage(user.sponsoredOrganizationId)
        .then(function(response) {
          vm.loading.organizationImage = false;
          vm.profileImage =
            response.data.data.profileImage ||
            APP_CONSTANTS.defaultOrganizationImage;
          vm.profileImage += '?=' + $rootScope.moment().unix();
        })
        .catch(function(error) {
          vm.loading.organizationImage = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function fetchCountries(name) {
      RegistrationService.getCountries(name)
        .then(function(result) {
          vm.countries = [];
          var countries = _.get(result, 'data.data', {});

          countries.forEach(function(country) {
            vm.countries.push(country.name);
          });
        })
        .catch(function(error) {
          toaster.pop('error', error.data.error.message || error.statusText);
        });
    }

    function fetchAccountAndOrganizationManagers() {
      vm.loading.managerList = true;
      OrganizationInfoService.fetchAccountAndOrganizationManagers(
        user.sponsoredOrganizationId
      )
        .then(function(response) {
          var organizationManagers = _.get(response, 'data.data', {});

          vm.managers = [];

          organizationManagers.forEach(function(organizationManager) {
            if (user.id !== organizationManager.id) {
              var name =
                organizationManager.firstName +
                ' ' +
                organizationManager.lastName;
              var temp = {
                name: name,
                id: organizationManager.id,
                email: organizationManager.email
              };

              vm.managers.push(temp);
            }
          });
          vm.loading.managerList = false;
        })
        .catch(function(error) {
          vm.loading.managerList = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function fetchDiscoverersCount() {
      OrganizationInfoService.fetchDiscoverersCount()
        .then(function(response) {
          var data = _.get(response, 'data.data', {});

          data &&
            data.forEach(function(data) {
              if (data.assessmentType === 'cdp') {
                vm.cdpUsedQuantity = data.usedQuantity || 0;
                vm.cdpTotalQuantity = data.totalQuantity || 0;
                vm.cdpPercentage =
                  (vm.cdpTotalQuantity &&
                    (vm.cdpUsedQuantity * 100) / vm.cdpTotalQuantity) ||
                  0;
                vm.cdpCountAvailable = true;
              } else {
                vm.ckdUsedQuantity = data.usedQuantity || 0;
                vm.ckdTotalQuantity = data.totalQuantity || 0;
                vm.ckdPercentage =
                  (vm.ckdTotalQuantity &&
                    (vm.ckdUsedQuantity * 100) / vm.ckdTotalQuantity) ||
                  0;
                vm.ckdCountAvailable = true;
              }
            });
        })
        .catch(function(error) {
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function displayConfirmationPopup(manager) {
      var data = {};

      data.message = $filter('sprintf')(
        APP_CONSTANTS.CONFIRMATION.CHANGE_PRIMARY_CONTACT,
        manager.name
      );

      ngDialog
        .openConfirm({
          template: 'app/shared/views/partials/ngDialogConfirmation.html',
          data: data
        })
        .then(function() {
          ngDialog.close();
          changePrimaryContact(manager);
        });
    }

    function changePrimaryContact(manager) {
      vm.loading.organizationInfo = true;

      var payload = {
        email: manager.email,
        primaryId: manager.id
      };

      OrganizationInfoService.changePrimaryContact(
        payload,
        user.sponsoredOrganizationId
      )
        .then(function() {
          vm.loading.organizationInfo = false;
          toaster.pop(
            'success',
            APP_CONSTANTS.MESSAGE.primaryContactChangedSuccessfully
          );
          fetchOrganizationInformation();
        })
        .catch(function(error) {
          vm.loading.organizationInfo = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function submit(form) {
      vm.formData = form;

      if (form.$invalid) return;

      vm.organizationInfo = {
        title: form.userTitle.$modelValue,
        lastName: form.lastName.$modelValue,
        firstName: form.firstName.$modelValue,
        legalName: form.organizationName.$modelValue,
        phone: form.phone.$modelValue.replace(/[^0-9]+/g, '')
      };

      vm.loading.organizationInfo = true;
      OrganizationInfoService.updateOrganizationInfo(
        vm.organizationInfo,
        user.sponsoredOrganizationId
      )
        .then(function() {
          vm.loading.organizationInfo = false;
          fetchOrganizationInformation();
          $scope.$parent.vm.fetchOrganizationInformation();
          toaster.pop(
            'success',
            APP_CONSTANTS.MESSAGE.organizationInfoUpdateSuccess
          );
        })
        .catch(function() {
          vm.loading.organizationInfo = false;
          toaster.pop(
            'error',
            APP_CONSTANTS.MESSAGE.organizationInfoUpdateFailed
          );
        });
    }

    function open(size) {
      if (!modalInstance) {
        modalInstance = $uibModal.open({
          templateUrl:
            'app/v2/customerAccount/views/partials/imageUploadModal.html',
          controller: 'OrganizationImageUploadController',
          controllerAs: 'vm',
          size: size
        });

        modalInstance.result
          .then(function() {
            vm.profileImage = OrganizationInfoService.getImage();
            $scope.$parent.vm.fetchOrganizationImage();
            modalInstance = '';
          })
          .catch(function() {
            modalInstance = '';
          });
      }
    }
  }
})();
