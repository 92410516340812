(function () {
  'use strict';

  angular.module('app.shared')
    .factory('DiscovererProgressChartService', DiscovererProgressChartService);

  DiscovererProgressChartService.$inject = [
    'ApiCaller',
    'APP_CONSTANTS',
    '$q',
    '$filter',
  ];

  function DiscovererProgressChartService(
    ApiCaller,
    APP_CONSTANTS,
    $q,
    $filter,
  ) {
    var self = this;

    self.fetchEventCategories = fetchEventCategories;

    function fetchEventCategories(groupId, sponsoredOrganizationId) {
      var deferred = $q.defer();
      var params = {
        groupId,
        sponsoredOrganizationId
      };
      var url = $filter('sprintf')(
        APP_CONSTANTS.discovererProgress,
        APP_CONSTANTS.rootUrl,
        $.param(params)
      );

      ApiCaller.apiGet(url)
        .then(function (result) {
          deferred.resolve(result);
        })
        .catch(function (error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    return self;
  }
})();
