(function () {
  'use strict';

  angular
    .module('app.shared')
    .controller('AdminEditResellerPaymentModalController', AdminEditResellerPaymentModalController);

  AdminEditResellerPaymentModalController.$inject = [
    'APP_CONSTANTS',
    'toaster',
    '$scope',
    '$uibModalInstance',
    'AdminResellerBillingService',
    'PaymentInfoService',
    'SessionService',
    '$filter',
    'parentData'
  ];

  function AdminEditResellerPaymentModalController(
    APP_CONSTANTS,
    toaster,
    $scope,
    $uibModalInstance,
    AdminResellerBillingService,
    PaymentInfoService,
    SessionService,
    $filter,
    parentData
  ) {
    var vm = this;

    vm.loading = {};
    vm.hidePO = parentData.hidePO;
    vm.months = APP_CONSTANTS.MONTHS;
    vm.paymentBlock = parentData.paymentDetail.type || APP_CONSTANTS.PAYMENT_METHOD.CARD;
    vm.width = vm.hidePO
      ? { width: APP_CONSTANTS.widthWithoutPO }
      : { width: APP_CONSTANTS.widthWithPO };

    vm.paymentInfo = {
      paypal: {},
      creditCard: {},
      bankTransfer: {},
      purchaseOrder: {},
      taxExemptUrl: '',
      promotionCode: ''
    };
    vm.constants = {
      paymentMethod: APP_CONSTANTS.PAYMENT_METHOD,
      updateToPOMessage: APP_CONSTANTS.updateToPOMessage,
      achAgreementMessage: APP_CONSTANTS.achAgreementMessage
    };

    vm.ok = ok;
    vm.cancel = cancel;
    vm.submit = submit;
    vm.printAgreement = printAgreement;
    vm.getExpiryYears = getExpiryYears;
    vm.validateCardCvv = validateCardCvv;
    vm.renderPaypalButton = renderPaypalButton;
    vm.validateCardNumber = validateCardNumber;
    vm.validateBankAccountNumber = validateBankAccountNumber;
    vm.validateBankRoutingNumber = validateBankRoutingNumber;

    vm.paymentInfo.creditCard.type = {};
    vm.paymentInfo.creditCard.mask = '';

    $scope.maskOptions = {
      allowInvalidValue: true,
      clearOnBlur: false
    };

    $scope.$watch('vm.paymentInfo.creditCard.cardNumber', function (newNumber) {
      vm.paymentInfo.creditCard.type = getCreditCardType(newNumber);
      vm.paymentInfo.creditCard.mask = getMaskType(
        vm.paymentInfo.creditCard.type
      );
    });

    var countryCode = 'US';
    var paypal = window.paypal;
    var paypalActions,
      paypalButtonRendered = false,
      paypalButtonDisabled = false;

    function renderPaypalButton() {
      if (!paypalButtonRendered && !paypalButtonDisabled) {
        vm.loading.payment = true;
        paypalButtonRendered = true;

        paypal.Button.render(
          {
            env: APP_CONSTANTS.PAYPAL.ENVIRONMENT, // sandbox | production

            style: {
              label: 'checkout', // checkout || credit
              size: 'small', // tiny | small | medium
              shape: 'rect', // pill | rect
              color: 'blue' // gold | blue | silver
            },

            validate: function (actions) {
              paypalActions = actions;
              $scope.$apply(function () {
                checkPaypalValidation();
              });
            },

            onClick: function () {
              $scope.$apply(function () {
                checkPaypalValidation();
              });
            },

            payment: function () {
              return paypal.request
                .post(
                  $filter('sprintf')(
                    APP_CONSTANTS.paypalPayment,
                    APP_CONSTANTS.rootUrl
                  )
                )
                .then(function (res) {
                  return res.data.TOKEN;
                })
                .catch(function (err) {
                  handlePaypalError(err);
                });
            },
            onAuthorize: function (data) {
              return paypal.request
                .post(
                  $filter('sprintf')(
                    APP_CONSTANTS.paypalBillingAgreement,
                    APP_CONSTANTS.rootUrl
                  ),
                  {
                    paymentToken: data.paymentToken
                  }
                )
                .then(function (res) {
                  subscribeWithPaypal(res.data.BILLINGAGREEMENTID);
                })
                .catch(function (err) {
                  handlePaypalError(err);
                });
            }
          },
          '#paypal-button'
        ).then(function () {
          vm.loading.payment = false;
        });
      }
    }

    function checkPaypalValidation() {
      paypalActions.disable();

      if (SessionService.getUser()) paypalActions.enable();
      else generalError(APP_CONSTANTS.MESSAGE.INVALID_ACCOUNT_INFORMATION);
    }

    function subscribeWithPaypal(id) {
      $scope.$apply(function () {
        vm.loading.payment = true;
      });

      if (!id) {
        $scope.$apply(function () {
          vm.loading.payment = false;
          toaster.clear('payment');

          toaster.pop({
            type: 'error',
            body: 'Something went wrong, Please Try Again',
            toasterId: 'payment'
          });
          document.getElementById('payment').scrollIntoView(true);
        });
      } else {
        var paymentDetail = {
          payment: {
            billingAgreementId: id,
            poNumber: vm.paymentInfo.paypal.poNumber,
            type: APP_CONSTANTS.PAYMENT_METHOD.PAYPAL_EXPRESS_CHECKOUT
          }
        };

        updatePaymentDetail(paymentDetail);
      }
    }

    function updatePaymentDetail(paymentDetail) {
      AdminResellerBillingService.updatePaymentDetail(paymentDetail, parentData.customerId)
        .then(function () {
          if (paymentDetail.payment.type === APP_CONSTANTS.PAYMENT_METHOD.PAYPAL_EXPRESS_CHECKOUT) {
            disablePaypalButton();
          }
          showPaymentUpdatedMessage();
        })
        .catch(function (error) {
          vm.loading.payment = false;
          toaster.clear('payment');
          toaster.pop({
            type: 'error',
            body: error.data.message || error.statusText,
            toasterId: 'payment'
          });
        });
    }

    function disablePaypalButton() {
      paypalActions.disable();
      paypalButtonDisabled = true;
    }

    function handlePaypalError(err) {
      // TODO: Better error handle
      if (!err) {
        $scope.$apply(function () {
          generalError('Invalid Paypal Options');
        });
      }
    }

    function printAgreement() {
      var printPopup = window.open(
        '',
        '_blank',
        'width=600,height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no'
      );

      printPopup.document.write(
        '<html><head></head><body onload="window.print()">' +
        vm.constants.achAgreementMessage +
        '</body></html>'
      );
      printPopup.document.close();
    }

    function validateBankAccountNumber() {
      if (
        vm.paymentInfo.bankTransfer.accountNumber &&
        !PaymentInfoService.validateBankAccountNumber(
          vm.paymentInfo.bankTransfer.accountNumber,
          countryCode
        )
      )
        vm.paymentInfo.invalidAccountNumber = true;
      else vm.paymentInfo.invalidAccountNumber = false;
    }

    function validateBankRoutingNumber() {
      if (
        vm.paymentInfo.bankTransfer.routingNumber &&
        !PaymentInfoService.validateBankRoutingNumber(
          vm.paymentInfo.bankTransfer.routingNumber,
          countryCode
        )
      )
        vm.paymentInfo.invalidRoutingNumber = true;
      else vm.paymentInfo.invalidRoutingNumber = false;
    }

    function submit(form) {
      vm.paymentInfoForm = form;

      if (vm.paymentInfoForm.$invalid) return;

      vm.loading.payment = true;

      switch (vm.paymentBlock) {
        case APP_CONSTANTS.PAYMENT_METHOD.CARD:
          PaymentInfoService.createCardToken(
            vm.paymentInfo.creditCard,
            subscribeWithCreditCard
          );
          break;
        case APP_CONSTANTS.PAYMENT_METHOD.BANK_TRANSFER:
          PaymentInfoService.createBankToken(
            vm.paymentInfo.bankTransfer,
            countryCode,
            subscribeWithBankAccount
          );
          break;
        case APP_CONSTANTS.PAYMENT_METHOD.PURCHASE_ORDER:
          subscribeWithPurchaseOrder();
          break;
        default:
          vm.loading.payment = false;

          return;
      }
    }

    function subscribeWithCreditCard(status, response) {
      if (response.error) {
        $scope.$apply(function () {
          vm.loading.payment = false;
          toaster.clear('payment');

          toaster.pop({
            type: 'error',
            body: response.error.message || response.error.code,
            toasterId: 'payment'
          });
          document.getElementById('payment').scrollIntoView(true);
        });
      } else {
        $('#creditCardNumber').val(
          $('#creditCardNumber')
            .val()
            .replace(/\d/g, 'X')
        );
        $('#creditCardCvv').val(
          $('#creditCardCvv')
            .val()
            .replace(/\d/g, 'X')
        );

        var paymentDetail = {
          payment: {
            token: response.id,
            type: APP_CONSTANTS.PAYMENT_METHOD.CARD,
            poNumber: vm.paymentInfo.creditCard.poNumber
          }
        };

        updatePaymentDetail(paymentDetail);
      }
    }

    function subscribeWithBankAccount(status, response) {
      if (response.error) {
        $scope.$apply(function () {
          vm.loading.payment = false;
          toaster.clear('payment');

          toaster.pop({
            type: 'error',
            body: response.error.message || response.error.code,
            toasterId: 'payment'
          });
          document.getElementById('payment').scrollIntoView(true);
        });
      } else {
        var paymentDetail = {
          payment: {
            token: response.id,
            poNumber: vm.paymentInfo.bankTransfer.poNumber,
            type: APP_CONSTANTS.PAYMENT_METHOD.DIRECT_DEBIT
          }
        };

        updatePaymentDetail(paymentDetail);
      }
    }

    function subscribeWithPurchaseOrder() {
      var paymentDetail = {
        payment: {
          type: APP_CONSTANTS.PAYMENT_METHOD.PURCHASE_ORDER
        }
      };

      updatePaymentDetail(paymentDetail);
    }

    function showPaymentUpdatedMessage() {
      vm.loading.payment = false;
      toaster.clear('payment');

      toaster.pop({
        type: 'success',
        body: 'Payment information successfully updated!',
        showCloseButton: true
      });

      vm.ok();
    }

    function generalError(message) {
      toaster.clear('payment');
      // toaster.pop('error', message || 'Invalid Form Details');
      toaster.pop({ type: 'error', body: message || 'Invalid Form Details' });
      document.getElementById('payment').scrollIntoView(true);
    }

    function getExpiryYears() {
      var years = [];
      var today = new Date();
      var currentYear = today.getFullYear();

      for (var i = 0; i <= 10; i++) {
        years.push(currentYear + i);
      }

      return years;
    }

    function validateCardNumber() {
      if (
        vm.paymentInfo.creditCard.cardNumber &&
        !PaymentInfoService.validateCardNumber(
          vm.paymentInfo.creditCard.cardNumber
        )
      )
        vm.paymentInfo.invalidCardNo = true;
      else vm.paymentInfo.invalidCvv = false;

      vm.cardType = vm.paymentInfo.creditCard.cardNumber
        ? PaymentInfoService.getCardType(vm.paymentInfo.creditCard.cardNumber)
        : '';
    }

    function validateCardCvv() {
      if (
        vm.paymentInfo.creditCard.cardCvv &&
        !PaymentInfoService.validateCardCvv(vm.paymentInfo.creditCard.cardCvv)
      )
        vm.paymentInfo.invalidCvv = true;
      else vm.paymentInfo.invalidCvv = false;
    }

    function getMaskType(cardType) {
      var masks = {
        mastercard: '9999 9999 9999 9999',
        visa: '9999 9999 9999 9999',
        amex: '9999 999999 99999',
        diners: '9999 9999 9999 99',
        discover: '9999 9999 9999 9999',
        unknown: '9999 9999 9999 9999'
      };

      return masks[cardType];
    }

    function getCreditCardType(creditCardNumber) {
      // start without knowing the credit card type
      var result = 'unknown';

      // first check for MasterCard
      if (/^5[1-5]/.test(creditCardNumber)) {
        result = 'mastercard';
      }
      // then check for Visa
      else if (/^4/.test(creditCardNumber)) {
        result = 'visa';
      }
      // then check for AmEx
      else if (/^3[47]/.test(creditCardNumber)) {
        result = 'amex';
      }
      // then check for Diners
      else if (/3(?:0[0-5]|[68][0-9])[0-9]{11}/.test(creditCardNumber)) {
        result = 'diners';
      }
      // then check for Discover
      else if (/6(?:011|5[0-9]{2})[0-9]{12}/.test(creditCardNumber)) {
        result = 'discover';
      }

      return result;
    }

    function ok() {
      $uibModalInstance.close('closed');
    }

    function cancel() {
      $uibModalInstance.dismiss('closed');
    }
  }
})();