(function () {
  'use strict';

  angular.module('app.pathAdvisor')
    .controller('PathAdvisorController', [
      'APP_CONSTANTS',
      'toaster',
      '$scope',
      'discovererProgressService',
      '$stateParams',
      'GroupModal',
      'GroupService',
      'AccessRoleService',
      'SessionService',
      '$state',
      '$uibModal',
      'ngDialog',
      '$rootScope',
      '$timeout',
      'OrganizationInfoService',
      'PathAdvisorService',
      PathAdvisorController
    ]);

  function PathAdvisorController(
    APP_CONSTANTS,
    toaster,
    $scope,
    discovererProgressService,
    $stateParams,
    GroupModal,
    GroupService,
    AccessRoleService,
    SessionService,
    $state,
    $uibModal,
    ngDialog,
    $rootScope,
    $timeout,
    OrganizationInfoService,
    PathAdvisorService,
  ) {
    var vm = this;

    vm.loading = {};
    vm.isDateOpen = false;
    vm.selectedDiscoverers = [];
    vm.selectAllFlag = false;
    vm.endDatePickerIsOpen = false;
    vm.startDatePickerIsOpen = false;
    vm.showClustersFilter = false;
    vm.filterClass = 'col-md-3 col-lg-3';

    vm.allCheck = allCheck;
    vm.resetDate = resetDate;
    vm.exportAllToCSV = exportAllToCSV;
    vm.filter_selected = filter_selected;
    vm.selectDiscoverer = selectDiscoverer;
    vm.discovererDisplayName = discovererDisplayName;
    vm.exportSelectedToCSV = exportSelectedToCSV;
    vm.assignDiscoverersToGroup = assignDiscoverersToGroup;

    var userRole = AccessRoleService.getHighPriorityRole();
    const currentUser = SessionService.getUser();

    // TODO Refactor checking the role of the user
    vm.role = '';

    if (userRole.priority === 5) {
      vm.role = 'groupCounselor';
    }

    vm.discoverers = [];
    vm.pageNum = 1;
    vm.pageSize = 10;
    vm.searchKey = '';
    vm.discoverers_clusters = [];

    if (!_.isEmpty($stateParams.parameter)) {
      vm.category = $stateParams.parameter.category || '';
      vm.groupId = $stateParams.parameter.groupId || '';
      vm.selectedServiceLevel = _.find(APP_CONSTANTS.SERVICE_LEVEL, {
        key: $stateParams.parameter.serviceLevelKey
      });
    } else {
      vm.category = '';
      vm.groupId = '';
    }

    $rootScope.pathAdvisorFilters ||= {};

    vm.totalRecords = 0;
    vm.refreshTimeStamp = Date.now();
    vm.pageSizeOptions = APP_CONSTANTS.pageSizeOptions;
    vm.serviceLevels = APP_CONSTANTS.SERVICE_LEVEL;
    vm.letsConnectOptions = _.filter(APP_CONSTANTS.letsConnectOptions, (option) => option.messageType === APP_CONSTANTS.CUSTOM);

    let loadingWatcher = $scope.$watchGroup(['vm.loading.discoverers', 'vm.loading.clusters'], function (newValues) {
      if (!newValues.every(val => val === false)) return;
      $scope.startTour();
      loadingWatcher();
    });

    vm.selectedPageSize = {
      key: "'" + vm.pageSize + "'",
      value: vm.pageSize
    };

    // Make sure latest discoverers are fetched as soon as they are added from Groups.
    vm.endDate = moment().add(1, 'day').toDate();
    vm.startDate = moment().subtract(1, 'year').toDate();
    setDateRange();

    fetchGroups();
    fetchProgress();
    fetchOrganizationClusters(currentUser.sponsoredOrganizationId);

    var sponsoredOrganizationId = $stateParams.sponsoredOrganizationId;

    vm.viewSponsoredOrganizationDashboard = sponsoredOrganizationId
      ? true
      : false;


    function setDateRange() {
      if (vm.startDate && vm.endDate)
        vm.dateRange = moment(vm.startDate).format('M/DD/YY') +
          ' - ' + moment(vm.endDate).format('M/DD/YY');
      else vm.dateRange = '';
    }

    function openBulkEmailModal(option) {
      let category = vm.progress || vm.discoverers_progress[0];
      if (option.template) {
        category.template = option.template;
      }

      const recipients = [];
      if (vm.group?.name) recipients.push(`Group: ${vm.group.name}`);
      if (vm.selectedServiceLevel?.value) recipients.push(`Service Level: ${vm.selectedServiceLevel.value}`);
      if (vm.progress?.value) recipients.push(`Progress: ${vm.progress.value}`);
      if (vm.dateRange) recipients.push(`Date: ${vm.dateRange}`);
      if (vm.searchKey) recipients.push(`Search: ${vm.searchKey}`);

      var modalInstance = $uibModal.open({
        animation: true,
        templateUrl: 'app/sendEmailToAll/views/sendEmailToAll.html',
        controller: 'SendEmailToAllController',
        controllerAs: 'vm',
        size: 'lg',
        resolve: {
          tags: function () {
            return {
              category,
              recipients,
            };
          }
        }
      });

      modalInstance.result.then(sendBulkEmail);
    }

    function sendBulkEmail(customMessage) {
      const filters = {
        groupId: vm.groupId,
        category: vm.category,
        searchKey: vm.searchKey,
        fromDate: vm.startDate?.toJSON() || '',
        toDate: vm.endDate?.toJSON() || '',
        serviceLevel: vm.selectedServiceLevel?.key || '',
        clusterId: vm.clusterId,
      };

      if (vm.category && vm.category !== 'All') {
        vm.loading.message = true;
        discovererProgressService
          .sendBulkMessage({
            filters,
            customMessage,
          })
          .then(
            function () {
              vm.loading.message = false;
              toaster.pop('success', APP_CONSTANTS.MESSAGE.messageSent);
            },
            function (error) {
              vm.loading.message = false;
              toaster.pop('error', error.data.message || error.statusText);
            }
          );
      }
    }

    function setPageHeader(customerName, organizationName) {
      const menu = _.find(currentUser.menus, { sref: 'app.pathadvisor' }) || {};

      vm.title = menu.text || 'PathAdvisor: Dashboard';
      vm.subtitle = menu.hover || '';
      vm.breadCrumbs = customerName + ' > ' + vm.title + ' - ' + organizationName;
    }


    function resetDate() {
      vm.endDate = '';
      vm.startDate = '';
      filter_selected();
    }


    const dtColumns = [
      { orderable: false, sortBy: '' },
      { orderable: true, sortBy: 'last' },
      { orderable: false, sortBy: 'groupName' },
      { orderable: true, sortBy: 'lastEventAt', orderBy: 'desc' },
      { orderable: false, sortBy: 'serviceLevel' },
      { orderable: true, sortBy: 'pathAdvisorProgressLevel' },
      { orderable: false, sortBy: 'clusterName' },
    ];
    const defaultColumnIndex = _.findIndex(dtColumns, 'orderBy');
    const defaultColumnOrder = Object.assign({}, dtColumns[defaultColumnIndex]);
    vm.orderCol = defaultColumnIndex;
    vm.sortBy = defaultColumnOrder.sortBy;
    vm.orderBy = defaultColumnOrder.orderBy || 'asc';

    $.fn.dataTable.ext.order['disable-custom-sort'] = function (settings, col) {
      var scope = angular.element($('.table-responsive')).scope();

      if (scope.vm.loading.clusters || scope.vm.loading.discoverers) return false;

      if (scope.vm.orderCol !== col) {
        scope.vm.orderCol = col;
        scope.vm.orderBy = 'asc';
        scope.vm.sortBy = dtColumns[col].orderable
          ? dtColumns[col].sortBy
          : defaultColumnOrder.sortBy;
        scope.vm.loadedNewData = true;

        fetchDiscoverers();
      } else if (scope.vm.orderCol === col) {
        if (scope.vm.loadedNewData) {
          scope.vm.loadedNewData = false;
        } else {
          scope.vm.orderBy = scope.vm.orderBy === 'asc' ? 'desc' : 'asc';
          scope.vm.loadedNewData = true;

          fetchDiscoverers();
        }
      }

      return true;
    };

    vm.dtOptions = {
      paging: false,
      searching: false,
      info: false,
      order: [],
      destroy: true,
      columnDefs: [
        {
          orderable: false,
          targets: _.map(dtColumns, (c, i) => c.orderable ? -1 : i).filter(v => v > -1)
        },
        {
          orderDataType: 'disable-custom-sort',
          targets: _.map(dtColumns, (c, i) => c.orderable ? i : -1).filter(v => v > -1)
        }
      ]
    };


    function exportAllToCSV() {
      if (!vm.totalRecords) return;

      // if (vm.totalRecords < 200) {
      //   PathAdvisorService.exportToCSV({
      //     discovererId: vm.discoverers.map(d => d.id),
      //     downloadAllFlag: false
      //   });
      //   return;
      // }

      vm.groupId = angular.isUndefined(vm.group) ? '' : vm.group.id;
      vm.endDate = angular.isUndefined(vm.endDate) ? '' : vm.endDate;
      vm.searchKey = angular.isUndefined(vm.searchKey) ? '' : vm.searchKey;
      vm.startDate = angular.isUndefined(vm.startDate) ? '' : vm.startDate;
      vm.category = angular.isUndefined(vm.progress) ? '' : vm.progress.key;

      if (!vm.startDate && !vm.endDate) {
        toaster.pop('error', 'Both start and end date should be selected');
        return;
      }

      var endDate = moment(vm.endDate);
      var startDate = moment(vm.startDate);
      var differenceInStartAndEndDate = endDate.diff(startDate, 'days');

      if (differenceInStartAndEndDate <= 0) {
        toaster.pop('error', APP_CONSTANTS.MESSAGE.startDateGreaterThanEndDate);
        return;
      }

      if (differenceInStartAndEndDate > 367) {
        toaster.pop('error', APP_CONSTANTS.MESSAGE.oneYearOnly);
        return;
      }

      const params = {
        groupId: vm.groupId,
        endDate: vm.endDate,
        category: vm.category,
        startDate: vm.startDate,
        searchKey: vm.searchKey,
        serviceLevel: vm.selectedServiceLevel?.key || '',
      };

      PathAdvisorService.exportAllToCSV(params)
        .then(() => {
          toaster.pop('success', APP_CONSTANTS.MESSAGE.csvExportedThroughMail);
        })
        .catch(err => {
          toaster.pop('error', err.data.message || err.statusText);
        });
    }

    function exportSelectedToCSV() {
      if (!vm.selectedDiscoverers.length) return;

      var discovererId = vm.selectedDiscoverers.map((discoverer) => discoverer.id);

      PathAdvisorService.exportToCSV({
        discovererId: discovererId,
        downloadAllFlag: false
      });
    }

    vm.showDeleteDiscoverersBtn = () => {
      return (vm.discoverers.length
        && vm.selectedDiscoverers.length
        && _.every(vm.selectedDiscoverers, d => d.progress <= 1)
      );
    }

    vm.confirmDeleteDiscoverers = () => {
      if (!vm.showDeleteDiscoverersBtn()) return;

      const message = vm.selectedDiscoverers.length === 1
        ? 'Are you sure you want to delete the discoverer ' + discovererDisplayName(vm.selectedDiscoverers[0]) + '?'
        : 'Are you sure you want to delete the selected discoverers?';

      ngDialog
        .openConfirm({
          template: 'app/shared/views/partials/ngDialogConfirmation.html',
          data: {
            message: message
          }
        })
        .then(() => {
          deleteDiscoverers(_.map(vm.selectedDiscoverers, 'id'));
        });
    }

    function discovererDisplayName(discoverer) {
      return discoverer.firstName
        ? (discoverer.lastName + ', ' + discoverer.firstName)
        : (discoverer.email || discoverer.customerSourcedId || discoverer.id);
    }

    function deleteDiscoverers(discovererIds) {
      vm.loading.discoverers = true;
      PathAdvisorService
        .deleteDiscoverers(discovererIds)
        .then(function () {
          toaster.pop('success', discovererIds.length > 1 ? APP_CONSTANTS.MESSAGE.deletedDiscoverers : APP_CONSTANTS.MESSAGE.deletedDiscoverer);
          vm.selectedDiscoverers = [];
          vm.loadedNewData = true;
          vm.pageNum = 1;
          fetchDiscoverers();
        })
        .catch(function (error) {
          vm.loading.discoverers = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }


    function fetchDiscoverers() {
      vm.isDefaultFilter = (
        moment(vm.endDate).isSame(moment().add(1, 'day'), 'day')
        && moment(vm.startDate).isSame(moment().subtract(1, 'year'), 'day')
        && _.isEmpty(vm.group)
        && _.isEmpty(vm.selectedServiceLevel)
        && _.isEmpty(vm.progress)
        && _.isEmpty(vm.cluster)
      );

      vm.loading.discoverers = true;

      var params = {
        groupId: vm.groupId || '',
        progressLevel: vm.progress?.key || '',
        searchKey: vm.searchKey || '',
        pageSize: vm.pageSize || 10,
        pageNumber: vm.pageNum || 1,
        sortBy: vm.sortBy,
        orderBy: vm.orderBy,
        fromDate: vm.startDate?.toJSON() || '',
        toDate: vm.endDate?.toJSON() || '',
        serviceLevel: vm.selectedServiceLevel?.key || '',
        clusterId: vm.clusterId
      };

      Object.keys(params).forEach(function (key) {
        if (!params[key]) {
          delete params[key];
        }
      });

      PathAdvisorService
        .fetchDiscoverers(params)
        .then(function (response) {
          vm.discoverers = _.get(response, 'data.data.discovers', []);
          vm.pageNum = response.data.data.pageNumber || 1;
          vm.pageSize =
            response.data.data.pageSize == 'All'
              ? response.data.data.totalRecords
              : response.data.data.pageSize;
          vm.totalRecords = response.data.data.totalRecords;
          vm.refreshTimeStamp = Date.now();

          vm.discoverers &&
            vm.discoverers.forEach(function (discoverer) {
              discoverer.clusterNameArray = [];
              if (discoverer.clusters && discoverer.clusters.length) {
                discoverer.clusters.forEach(function (clusterId) {
                  discoverer.clusterNameArray.push(
                    vm.discoverers_clusters[
                    vm.discoverers_clusters.findIndex(function (cluster) {
                      return cluster.clusterId === clusterId;
                    })
                    ]
                  );
                });
              }
              calculateDiscovererProgress(discoverer);
            });

          if (vm.category) {
            var selectedDiv = angular.element(
              document.querySelector('#progress-filter')
            );

            selectedDiv.find('span').addClass('ui-select-outline');
          }

          if (vm.groupId) {
            var selectedDiv = angular.element(
              document.querySelector('#group-filter')
            );

            selectedDiv.find('span').addClass('ui-select-outline');
          }

          vm.selectedDiscoverers = vm.selectedDiscoverers.map(function (selectedDiscoverer) {
            return vm.discoverers.find(function (discoverer) {
              return selectedDiscoverer.id === discoverer.id;
            }) || selectedDiscoverer;
          });

          mapDiscoverersGroupsName();
          selectDiscoverer();

          vm.loading.discoverers = false;
        })
        .catch(function (error) {
          vm.loading.discoverers = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function mapDiscoverersGroupsName() {
      if (!vm.discoverers_groups?.length || !vm.discoverers?.length) return;

      vm.discoverers.forEach(dis => {
        dis.groupsName = vm.discoverers_groups
          .filter(group => dis.groupId.includes(group.id))
          .map(group => group.name);
      });
    }

    // TODO to refactor this in future.
    function fetchGroups() {
      vm.loading.groups = true;

      if (vm.role === 'groupCounselor') {
        GroupService.fetchGroupsByUser(currentUser.sponsoredOrganizationId)
          .then(function (response) {
            vm.listGroups = _.sortBy(response.data.data, (i) => i.name.toLowerCase());
            vm.discoverers_groups = _.concat({
              name: 'All Assigned Groups',
              id: ''
            }, vm.listGroups);
            vm.loading.groups = false;
            if (!_.isEmpty($stateParams.parameter)) {
              if ($stateParams.parameter.groupId !== '') {
                vm.group = vm.discoverers_groups.filter(function (item) {
                  return item.id === vm.groupId;
                })[0];
              }
            }

            mapDiscoverersGroupsName();
          })
          .catch(function (error) {
            toaster.pop('error', error.data.message || error.statusText);
            vm.loading.groups = false;
          });
      } else {
        GroupService.fetchGroups()
          .then(function (response) {
            vm.listGroups = _.sortBy(response.data.data, (i) => i.name.toLowerCase());
            vm.discoverers_groups = _.concat(APP_CONSTANTS.GROUP_FILTER, vm.listGroups);
            vm.loading.groups = false;
            if (!_.isEmpty($stateParams.parameter)) {
              if ($stateParams.parameter.groupId !== '') {
                vm.group = vm.discoverers_groups.filter(function (item) {
                  return item.id === vm.groupId;
                })[0];
              }
            }

            mapDiscoverersGroupsName();
          })
          .catch(function (error) {
            vm.loading.groups = false;
            toaster.pop('error', error.data.message || error.statusText);
          });
      }
    }

    function validateDiscoverer(option) {
      if (vm.selectedDiscoverers.length === 0) {
        return toaster.pop(
          'warning',
          APP_CONSTANTS.MESSAGE.discoverersSelectValidation
        );
      }

      option.emails = _.map(vm.selectedDiscoverers, 'email');
      if (option.messageType === APP_CONSTANTS.CUSTOM) {
        return $state.go('app.mailbox.compose', option);
      }

      for (var i = 0; i < vm.selectedDiscoverers.length; i++) {
        let discoverer = vm.selectedDiscoverers[i];

        let lastEventCategory = discoverer.lastEventCategory === APP_CONSTANTS.EVENT_CATEGORY.INVITE_TO_START
          ? APP_CONSTANTS.EVENT_CATEGORY.NOT_STARTED
          : discoverer.lastEventCategory;
        if (lastEventCategory === option.messageType) continue;

        return toaster.pop(
          'warning',
          APP_CONSTANTS.MESSAGE.discoverersEventTypeSelectValidation
        );
      }

      $state.go('app.mailbox.compose', option);
    };

    function fetchProgress() {
      vm.discoverers_progress = [
        {
          key: 'All',
          value: 'All'
        },
        {
          key: 1,
          value: 'Not Started',
        },
        {
          key: 2,
          value: 'CDP Completed',
        },
        {
          key: 3,
          value: 'CDP & CKD Completed',
        },
      ];

      if (!_.isEmpty($stateParams.parameter)) {
        if ($stateParams.parameter.category !== '') {
          vm.progress = vm.discoverers_progress.filter(function (
            item
          ) {
            return item.key.indexOf(vm.category) > -1;
          })[0];
        }
      }
    }

    function allCheck() {
      vm.selectAllFlag = !vm.selectAllFlag;

      vm.discoverers.forEach(function (discoverer) {
        var selectedDiscovererIndex = vm.selectedDiscoverers.findIndex((d) => d.id === discoverer.id);
        if (vm.selectAllFlag && selectedDiscovererIndex < 0) {
          vm.selectedDiscoverers.push(discoverer);
        }
        if (!vm.selectAllFlag && selectedDiscovererIndex > -1) {
          vm.selectedDiscoverers.splice(selectedDiscovererIndex, 1);
        }
      });
    }

    function selectDiscoverer() {
      var shouldSelectAllFlag = false;
      if (vm.discoverers.length && vm.selectedDiscoverers.length >= vm.discoverers.length) {
        shouldSelectAllFlag = true;
        vm.discoverers.forEach(function (discoverer) {
          var hasSelectedDiscoverer = vm.selectedDiscoverers.some((d) => d.id === discoverer.id);
          if (!hasSelectedDiscoverer) {
            shouldSelectAllFlag = false;
          }
        });
      }
      vm.selectAllFlag = shouldSelectAllFlag;
    }

    vm.resetFilters = function () {
      delete vm.group;
      delete vm.selectedServiceLevel;
      delete vm.progress;
      delete vm.cluster;
      vm.searchKey = '';
      vm.endDate = moment().add(1, 'day').toDate();
      vm.startDate = moment().subtract(1, 'year').toDate();
      filter_selected();
    }

    function filter_selected() {
      if ((!vm.startDate && vm.endDate) || (vm.startDate && !vm.endDate)) return;

      const endDate = moment(vm.endDate);
      const startDate = moment(vm.startDate);
      const differenceInStartAndEndDate = endDate.diff(startDate, 'days');

      if (differenceInStartAndEndDate <= 0) {
        toaster.pop('error', APP_CONSTANTS.MESSAGE.startDateGreaterThanEndDate);
        return;
      }

      vm.groupId = vm.group?.id || '';
      vm.category = vm.progress?.key || '';
      vm.searchKey ||= '';
      vm.pageSize = vm.selectedPageSize?.value || 10;
      vm.clusterId = vm.cluster?.clusterId || '';

      setDateRange();

      vm.isDateOpen = false;
      vm.loadedNewData = true;
      vm.pageNum = 1;
      vm.selectedDiscoverers = [];

      fetchDiscoverers();

      $rootScope.pathAdvisorFilters = {
        group: vm.group,
        progress: vm.progress,
        endDate: vm.endDate,
        startDate: vm.startDate,
        searchKey: vm.searchKey,
        serviceLevel: vm.selectedServiceLevel,
        cluster: vm.cluster,
      }
    }

    function assignDiscoverersToGroup() {
      GroupModal.assignDiscoverersToGroup(
        vm.listGroups,
        vm.selectedDiscoverers,
      ).then(() => {
        vm.loadedNewData = true;
        fetchDiscoverers();
      });
    }

    function calculateDiscovererProgress(discoverer) {
      if (discoverer.progressLevel) {
        discoverer.progress = discoverer.progressLevel;
        return;
      }

      discoverer.progress = 1;

      if (discoverer.cdpAssessmentDate) {
        discoverer.progress = 2;

        if (discoverer.assessmentDate) {
          discoverer.progress = 3;
        }
      }
    }

    function fetchOrganizationClusters(sponsoredOrganizationId) {
      vm.loading.clusters = true;

      OrganizationInfoService.fetchOrganizationClusters(sponsoredOrganizationId)
        .then(function (response) {
          vm.discoverers_clusters.push({
            color: '',
            clusterId: '',
            clusterSetId: '',
            clusterSetName: '',
            clusterName: 'All'
          });

          var clustersList = response.data.data;

          if (clustersList.length) {
            clustersList = mapClusterColors(clustersList);

            vm.discoverers_clusters = vm.discoverers_clusters.concat(clustersList);

            // vm.showClustersFilter = true;

            vm.filterClass = 'col-md-2 col-lg-2';
          }

          $timeout(function () {

            if ($rootScope.pathAdvisorFilters.group) {
              vm.group = $rootScope.pathAdvisorFilters.group;
              vm.groupId = $rootScope.pathAdvisorFilters.group.id;
            }

            if ($rootScope.pathAdvisorFilters.progress) {
              vm.progress = $rootScope.pathAdvisorFilters.progress;
              vm.category = $rootScope.pathAdvisorFilters.progress.key;
            }

            if ($rootScope.pathAdvisorFilters.serviceLevel) {
              vm.selectedServiceLevel = $rootScope.pathAdvisorFilters.serviceLevel;
            }

            if ($rootScope.pathAdvisorFilters.startDate && $rootScope.pathAdvisorFilters.endDate) {
              vm.startDate = $rootScope.pathAdvisorFilters.startDate;
              vm.endDate = $rootScope.pathAdvisorFilters.endDate;

              setDateRange();
            }

            if ($rootScope.pathAdvisorFilters.cluster) {
              vm.cluster = $rootScope.pathAdvisorFilters.cluster;
              vm.clusterId = _.get(vm.cluster, 'clusterId', '');
            }

            vm.searchKey = $rootScope.pathAdvisorFilters.searchKey;

            fetchDiscoverers();

            vm.loading.clusters = false;
          });
        })
        .catch(function (error) {
          toaster.pop('error', error.data.message || error.statusText);
          vm.loading.clusters = false;
        });
    }

    vm.getTableData = function (event) {
      var $targetElement = $(event.currentTarget);

      if (
        !$targetElement.hasClass('disabled') &&
        !$targetElement.hasClass('current')
      ) {
        vm.pageNum = $targetElement.data('pageNum');

        vm.loadedNewData = true;

        fetchDiscoverers();
      }
    };

    function mapClusterColors(clusters) {
      var clusterSetIds = [];

      clusters.forEach(function (cluster) {
        var index = clusterSetIds.indexOf(cluster.clusterSetId);

        if (index < 0) {
          clusterSetIds.push(cluster.clusterSetId);

          cluster.color =
            APP_CONSTANTS.CLUSTER_COLORS[
            clusterSetIds.indexOf(cluster.clusterSetId)
            ] || '';
        } else {
          cluster.color = APP_CONSTANTS.CLUSTER_COLORS[index] || '';
        }
      });

      return clusters;
    }

    vm.sendMessage = function (option) {
      if (!vm.selectedDiscoverers.length) {
        return openBulkEmailModal(option);
      }
      validateDiscoverer(option);
    }
  }
})();
