(function() {
  'use strict';

  angular
    .module('app.stakeholder')
    .factory('StakeHolderDashboardService', StakeHolderDashboardService);

  StakeHolderDashboardService.$inject = [
    'ApiCaller',
    'APP_CONSTANTS',
    '$q',
    '$filter'
  ];

  function StakeHolderDashboardService(ApiCaller, APP_CONSTANTS, $q, $filter) {
    var self = this;

    self.fetchEventCategories = fetchEventCategories;

    function fetchEventCategories(groupId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.discovererProgress,
        APP_CONSTANTS.rootUrl,
        groupId
      );

      ApiCaller.apiGet(url)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    return self;
  }
})();
