(function() {
  'use strict';
  angular
    .module('app.shared')
    .controller('PublishMajorController', PublishMajorController);

  PublishMajorController.$inject = [
    '$scope',
    '$uibModalInstance',
    'selectedMajor',
    'selectedWorkgroupId',
    '$rootScope'
  ];

  function PublishMajorController(
    $scope,
    $uibModalInstance,
    selectedMajor,
    selectedWorkgroupId,
    $rootScope
  ) {
    var vm = this;

    vm.loading = {};
    vm.selectedDate = '';
    vm.publish = publish;
    vm.isPublishNowChecked = false;
    vm.dismissModal = dismissModal;
    vm.publishDatePickerIsOpen = false;
    vm.checkPublishNow = checkPublishNow;
    vm.dateOptions = {
      minDate: $rootScope
        .moment(new Date())
        .add(1, 'day')
        .format()
    };

    function checkPublishNow() {
      if (vm.isPublishNowChecked === true) {
        vm.selectedDate = '';
      }
    }

    function publish() {
      if (!vm.isPublishNowChecked) {
        $scope.$parent.vm.setMajorPublishDate(
          selectedWorkgroupId,
          selectedMajor.id,
          vm.selectedDate
        );
      } else {
        $scope.$parent.vm.publishMajor(selectedMajor.id);
      }

      dismissModal();
    }

    function dismissModal() {
      $uibModalInstance.dismiss();
    }
  }
})();
