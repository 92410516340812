/**
 * Created by Romit Amgai<romitamgai@lftechnology.com> on 4/18/17.
 */
(function() {
  'use strict';
  angular.module('app.user').factory('ConfirmService', ConfirmService);

  ConfirmService.$inject = [
    'localStorageService',
    'APP_CONSTANTS',
    'ApiCaller',
    '$filter',
    '$q'
  ];

  /* eslint-disable */
  function ConfirmService(
    localStorageService,
    APP_CONSTANTS,
    ApiCaller,
    $filter,
    $q
  ) {
    var self = this;

    return self;
  }
  /* eslint-enable */
})();
