(function() {
  'use strict';

  /**
   * Makes the `uib-accordion-group` element scroll to top on the
   * `shown.bs.collapse` event. We use it in bootstrap accordions, when the
   * user opens one of the accordion items this directive will scroll the
   * item to the top of the screen.
   */
  angular.module('careerKeyCentral').directive('scrollTop', scrollTop);

  function scrollTop() {
    return {
      restrict: 'A',
      link: link
    };
  }

  function link(scope, element) {
    scope.collapsing = false;
    var jqElement = $(element);
    scope.$watch(
      function() {
        return jqElement.find('.panel-collapse').hasClass('collapsing');
      },
      function(status) {
        if (scope.collapsing && !status) {
          if (jqElement.hasClass('panel-open')) {
            $('html,body').animate(
              {
                scrollTop: jqElement.offset().top - 20
              },
              500
            );
          }
        }
        scope.collapsing = status;
      }
    );
  }
})();
