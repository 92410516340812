(function() {
  'use strict';

  angular
    .module('app.sponsoredOrganization')
    .controller('ConfigurePluginController', ConfigurePluginController);

  ConfigurePluginController.$inject = [
    'APP_CONSTANTS',
    'ConfigurePluginService',
    'GroupService',
    'ngDialog',
    'SessionService',
    'toaster',
    'plugin',
    '$scope',
    '$uibModal',
    '$uibModalInstance',
    '$location',
    '$anchorScroll',
    '$rootScope',
    'uuid'
  ];

  function ConfigurePluginController(
    APP_CONSTANTS,
    ConfigurePluginService,
    GroupService,
    ngDialog,
    SessionService,
    toaster,
    plugin,
    $scope,
    $uibModal,
    $uibModalInstance,
    $location,
    $anchorScroll,
    $rootScope,
    uuid
  ) {
    var vm = this;

    var userLoginDetail = SessionService.getUser();
    var modalInstance = '';

    vm.plugin = {};
    vm.loading = {};
    vm.searchURL = '';
    vm.sameLanguage = false;
    vm.fetchedClusters = [];
    vm.fallbackLanguageRequired = false;
    vm.search = APP_CONSTANTS.searchSchool;
    vm.collegeMajorOptions = APP_CONSTANTS.collegeMajor;
    vm.availableLanguages = [];
    vm.availableCountries = APP_CONSTANTS.availableCountries;

    vm.fetchSchools = fetchSchools;
    vm.dismissModal = dismissModal;
    vm.updatePlugin = updatePlugin;
    vm.fetchSchoolId = fetchSchoolId;
    vm.schoolSelected = schoolSelected;
    vm.removeSchoolId = removeSchoolId;
    vm.checkPluginLanguage = checkPluginLanguage;
    vm.openImageUploadModal = openImageUploadModal;
    vm.clearSchoolIdWhenCountryChanges = clearSchoolIdWhenCountryChanges;

    vm.regenerateSecretKey = regenerateSecretKey;

    angular.copy(plugin, vm.plugin);
    vm.searchSchool = vm.plugin.school;
    vm.croppedImage = '';
    vm.plugin.clusterSets ||= [];
    vm.isCkd = vm.plugin.type === APP_CONSTANTS.CKD;
    vm.isCdp = vm.plugin.type === APP_CONSTANTS.CDP;
    vm.isUnify = vm.plugin.type === APP_CONSTANTS.BOTH;
    vm.options = {};

    ConfigurePluginService.setLogoUrl(vm.plugin.logoUrl);

    $scope.$watch('vm.plugin.privateAccess', function (newValue) {
      vm.plugin.accessOption = newValue ? 'emailRequired' : plugin.accessOption;
    });

    if (vm.plugin.logoUrl) {
      vm.croppedImage = vm.plugin.logoUrl + '?=' + $rootScope.moment().unix();
    }
    if (vm.plugin.collegeMajor == APP_CONSTANTS.collegeMajor.CUSTOMIZED_CAREERS_MAJORS) {
      vm.plugin.collegeMajor = APP_CONSTANTS.collegeMajor.MY_INSTITUTION_OFFER;
    }

    if (vm.isCkd || vm.isUnify) {
      configureCareersAndMajors();
      fetchAvailableLanguages();
      fetchClusterSets();
    }

    function clearSchoolIdWhenCountryChanges() {
      vm.plugin.school = {};
    }

    function checkPluginLanguage() {
      vm.sameLanguage = false;
      vm.fallbackLanguageRequired = false;

      if (
        vm.plugin.fallbackVariation.enabled &&
        Object.keys(vm.plugin.fallbackVariation.language).length === 0
      ) {
        vm.fallbackLanguageRequired = true;

        return vm.fallbackLanguageRequired;
      }

      if (
        vm.plugin.fallbackVariation.language.id ===
          vm.plugin.locale.language.id &&
        vm.plugin.fallbackVariation.enabled
      ) {
        vm.sameLanguage = true;

        return vm.sameLanguage;
      }

      return false;
    }

    function fetchSchools(key, type, country) {
      if (key) {
        var params = {
          searchKey: key,
          searchType: type,
          searchCountry: country
        };

        ConfigurePluginService.fetchSchools(params).then(function(response) {
          vm.schools = response.data.data;
          if (vm.schools.length === 0) {
            if (type === vm.search.name) {
              vm.schools.push({ name: key });
            } else {
              vm.schools.push({ zip: key });
            }
          }
        });
      }
    }

    function configureCareersAndMajors() {
      vm.plugin.careersAndMajors = vm.plugin.hideIPs
        ? 'careersOnly'
        : vm.plugin.hideCareers
          ? 'majorsOnly'
          : 'all';
      vm.options.careersAndMajors = [
        {
          label: 'Careers only',
          value: 'careersOnly',
        },
        {
          label: 'Majors only',
          value: 'majorsOnly',
        },
        {
          label: 'All (Default)',
          value: 'all',
        },
      ];
      vm.options.collegeMajors = [
        {
          label: 'Only those my institution offers (School ID required)',
          value: APP_CONSTANTS.collegeMajor.MY_INSTITUTION_OFFER,
        },
        {
          label: 'All (Default)',
          value: APP_CONSTANTS.collegeMajor.ALL,
        },
      ];
    }

    /* eslint-disable */
    function fetchAvailableLanguages() {
      vm.loading.plugin = true;

      ConfigurePluginService.fetchAvailableLanguages(
        userLoginDetail.sponsoredOrganizationId,
        vm.plugin.type
      )
        .then(function(response) {
          vm.availableLanguages = response.data.data;
        })
        .finally(() => {
          vm.loading.plugin = false;
        });
    }
    /* eslint-enable */

    function fetchSchoolId() {
      if (!vm.searchSchool && vm.searchURL === '') {
        toaster.clear('configurePlugin');
        toaster.pop({
          type: 'warning',
          body: APP_CONSTANTS.MESSAGE.searchSchoolFieldValidation,
          toasterId: 'configurePlugin'
        });

        return;
      } else {
        vm.loading.searchSchoolField = true;
        var schoolName = vm.searchSchool ? vm.searchSchool.name : '';
        var searchZip = vm.searchSchool ? vm.searchSchool.zip : '';
        var params = {
          zip: searchZip,
          url: vm.searchURL,
          name: schoolName,
          country: vm.plugin.locale.country.id
        };

        ConfigurePluginService.fetchSchoolId(params)
          .then(function(response) {
            vm.loading.searchSchoolField = false;
            vm.plugin.schoolId = response.data.data.id;
            if (vm.plugin.schoolId)
              vm.plugin.collegeMajor =
                APP_CONSTANTS.collegeMajor.MY_INSTITUTION_OFFER;

            toaster.clear('configurePlugin');
            toaster.pop({
              type: 'success',
              body: response.data.data.message,
              toasterId: 'configurePlugin'
            });
          })
          .catch(function(error) {
            vm.searchURL = '';
            vm.searchZip = '';
            vm.searchSchool = null;
            vm.loading.searchSchoolField = false;
            $location.hash('plugIn');
            $anchorScroll();
            toaster.clear('configurePlugin');
            toaster.pop({
              type: 'error',
              body: error.data.message || error.statusText,
              toasterId: 'configurePlugin'
            });
          });
      }
    }

    function schoolSelected(selectedSchool) {
      vm.schools = [];
      vm.plugin.school = selectedSchool;
      vm.plugin.collegeMajor = APP_CONSTANTS.collegeMajor.MY_INSTITUTION_OFFER;
    }

    vm.removeLogo = function () {
      ngDialog
        .openConfirm({
          template: 'app/shared/views/partials/ngDialogConfirmation.html',
          data: {
            message: 'Are you sure you want to remove the Logo?'
          }
        })
        .then(function () {
          vm.croppedImage = '';
          ConfigurePluginService.setLogoUrl('');
        });
    }

    function removeSchoolId() {
      ngDialog
        .openConfirm({
          template: 'app/shared/views/partials/ngDialogConfirmation.html',
          data: {
            message: 'Are you sure you want to remove the school ID?'
          }
        })
        .then(
          function() {
            // Clear the input fields
            vm.searchURL = '';
            vm.searchZip = '';
            vm.searchSchool = null;
            vm.plugin.school = {};

            // College Major should be "All (default)" when school ID is removed.
            vm.plugin.collegeMajor = APP_CONSTANTS.collegeMajor.ALL;
          },
          function() {
            ngDialog.close();
          }
        );
    }

    function fetchClusterSets() {
      vm.loading.plugin = true;

      ConfigurePluginService.fetchClusterSets(
        userLoginDetail.sponsoredOrganizationId
      )
        .then(function(response) {
          vm.fetchedClusters = response.data?.data || [];
          vm.plugin.clusterSets = _.filter(vm.fetchedClusters, c => vm.plugin.clusterSets.includes(c.id));
        })
        .finally(() => {
          vm.loading.plugin = false;
        });
    }

    if (vm.plugin.groupSelection) {
      fetchGroups();
    }

    vm.fetchGroups = fetchGroups;
    function fetchGroups() {
      if (vm.groups) return;

      vm.loading.advanced = true;
      GroupService.fetchGroups()
        .then((response) => {
          vm.groups = response.data.data || [];
        })
        .catch((error) => {
          toaster.pop('error', error.data?.message || error.statusText);
        })
        .finally(() => {
          vm.loading.advanced = false;
        });
    }

    function updatePlugin(form) {
      if (!form.$valid) {
        return;
      }

      if (vm.isCkd || vm.isUnify) {
        vm.plugin.clusterSets = _.map(vm.plugin.clusterSets, 'id');
        vm.plugin.hideIPs = vm.plugin.careersAndMajors === 'careersOnly';
        vm.plugin.hideCareers = vm.plugin.careersAndMajors === 'majorsOnly';
      }

      vm.plugin.logoUrl = ConfigurePluginService.getLogoUrl();

      if (checkPluginLanguage()) {
        return;
      }

      if (vm.plugin.groupSelection && !vm.plugin.groupOptions?.length) {
        return;
      }

      vm.loading.plugin = true;

      ConfigurePluginService.updatePlugin(vm.plugin)
        .then(function() {
          vm.loading.plugin = false;

          toaster.pop({
            type: 'success',
            body: APP_CONSTANTS.MESSAGE.PLUGIN_UPDATED
          });
          $uibModalInstance.close();
        })
        .catch(function(error) {
          vm.loading.plugin = false;

          toaster.clear('configurePlugin');
          toaster.pop({
            type: 'error',
            body: error.data.message || error.statusText,
            toasterId: 'configurePlugin'
          });
        });
    }

    function dismissModal() {
      $uibModalInstance.dismiss();
    }

    function openImageUploadModal(size) {
      if (!modalInstance) {
        modalInstance = $uibModal.open({
          templateUrl:
            'app/v2/sponsoredOrganization/technicalDashboard/pluginConfiguration/uploadImage/uploadImageModal.html',
          controller: 'UploadImageController',
          controllerAs: 'vm',
          size: size,
          resolve: {
            plugin: function() {
              return vm.plugin;
            }
          }
        });

        modalInstance.result
          .then(function() {
            modalInstance = '';
            vm.croppedImage = ConfigurePluginService.getLogoUrl();
          })
          .catch(function() {
            modalInstance = '';
          });
      }
    }

    function regenerateSecretKey() {
      vm.plugin.secretKey = uuid.v4();
    }
  }
})();
