(function () {
  'use strict';
  angular.module('app.user').controller('AddResellerConfirmController', AddResellerConfirmController);

  AddResellerConfirmController.$inject = [
    '$scope',
    '$state',
    'toaster',
    'APP_CONSTANTS',
    'PaymentInfoService',
    'RegistrationService'
  ];

  function AddResellerConfirmController(
    $scope,
    $state,
    toaster,
    APP_CONSTANTS,
    PaymentInfoService,
    RegistrationService
  ) {
    var vm = this;

    vm.constants = {
      productLocales: APP_CONSTANTS.productLocales,
      decimalFixedValue: APP_CONSTANTS.decimalFixedValue,
      trialSubscription: APP_CONSTANTS.SUBSCRIPTION_TYPE.TRIAL
    };
    vm.confirmOrder = confirmOrder;
    vm.activateUser = activateUser;

    init();

    function init() {
      if ($scope.regController.plan) {
        vm.orderConfirmed = false;
        $scope.regController.orderSummary.totalAmount =
          $scope.regController.plan.price *
          ($scope.regController.plan.billing_cycles ||
            APP_CONSTANTS.defaultBillingCycle);

        vm.selectedLocale =
          $scope.regController.plan.cf_language_name +
          ' - ' +
          $scope.regController.plan.cf_country_id;

        if ($scope.regController.plan.setup_cost) {
          $scope.regController.orderSummary.setupCost =
            $scope.regController.plan.setup_cost;
          $scope.regController.orderSummary.totalAmount +=
            $scope.regController.plan.setup_cost;
        }
      }
    }

    function confirmOrder() {
      vm.loading = true;

      PaymentInfoService.addReseller(
        RegistrationService.getAccountInfo(),
        RegistrationService.getBillingInfo(),
        RegistrationService.getPaymentDetail(),
        $scope.regController.plan
      )
        .then(function (res) {
          $scope.regController.signUpComplete.status = true;
          $scope.regController.signUpComplete.activationId =
            res.data.data.activationId;
          $scope.regController.signUpComplete.type = RegistrationService.getSignupType();
          $scope.regController.signUpComplete.paymentFailed =
            res.data.data.subscriptionDetail &&
            res.data.data.subscriptionDetail.paymentFailed;

          vm.loading = false;
          toaster.clear('confirm');

          $scope.$parent.closeModal();
          toaster.pop('success', 'Reseller created successfully!');
        })
        .catch(function (err) {
          vm.loading = false;

          createSubscriptionError(err);
        });
    }

    function activateUser() {
      $state.go('activation', {
        activationId: $scope.regController.signUpComplete.activationId
      });
    }

    function createSubscriptionError(err) {
      // TODO: Better error handle
      toaster.clear('confirm');

      if (err.data && err.data.error && err.data.error.code === 400)
        toaster.pop({
          type: 'error',
          body: 'Invalid Form Details',
          toasterId: 'confirm'
        });
      else
        toaster.pop({
          type: 'error',
          body: err.data.message || err.statusText,
          toasterId: 'confirm'
        });

      document.getElementById('confirm').scrollIntoView(true);
    }
  }
})();
