(function () {
  'use strict';
  angular
    .module('app.user')
    .controller('AddCustomerSubscriptionController', AddCustomerSubscriptionController);

  AddCustomerSubscriptionController.$inject = [
    '$scope',
    'APP_CONSTANTS',
    'ProductService',
    'toaster',
  ];

  function AddCustomerSubscriptionController(
    $scope,
    APP_CONSTANTS,
    ProductService,
    toaster,
  ) {
    var vm = this;

    vm.loading = {};
    vm.submit = submit;
    vm.subscription = {};
    vm.selected = {};
    init();

    var today = new Date();

    vm.durations = APP_CONSTANTS.SUBSCRIPTION_DURATIONS;
    vm.selected.duration = vm.durations[0];
    vm.subscription.startDate = today;

    // var sixMonthFromToday = new Date(today).setMonth(today.getMonth() + 6);
    var sixMonthFromToday = moment(today).add({ month: 6 }).toDate();

    vm.openDatePicker = openDatePicker;
    vm.datePicker = { opened: false };
    vm.dateOptions = {
      dateDisabled: dateDisabled,
      formatYear: 'yy',
      maxDate: sixMonthFromToday,
      minDate: today,
      startingDay: 1
    };

    function openDatePicker() {
      vm.datePicker.opened = true;
    }

    function dateDisabled(data) {
      var date = data.date,
        mode = data.mode;

      return mode === 'day' && (date.getDay() === 0 || date.getDay() === 6);
    }

    function init() {
      vm.loading.products = true;

      ProductService.fetchProductIds()
        .then(function (result) {
          vm.products = _.get(result, 'data.products', []);
          vm.loading.products = false;
        })
        .catch(function (error) {
          toaster.pop({
            type: 'error',
            body: error.data.message || error.statusText,
            toasterId: 'subscription'
          });

          vm.loading.products = false;
        });
    }

    function submit(subscriptionForm) {
      if (!subscriptionForm.$valid) return;

      subscriptionForm.$setPristine();
      vm.subscription.product = {
        id: vm.selected.product.id,
        name: vm.selected.product.name,
        version: vm.selected.product.version,
        assessmentType: vm.selected.product.assessmentType,
      };
      vm.subscription.endDate = moment(vm.subscription.startDate)
        .add({ year: vm.selected.duration.value })
        .toDate();
      angular.copy(vm.subscription, $scope.regController.subscription);
      toaster.clear('subscription');
      $scope.regController.gotoNextBlock();
    }
  }
})();
