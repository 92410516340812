(function () {
  'use strict';
  angular
    .module('app.shared')
    .controller('ResellerListController', ResellerListController);

  ResellerListController.$inject = [
    '$state',
    'APP_CONSTANTS',
    'toaster',
    'ResellerService',
    '$uibModal',
    '$scope'
  ];

  function ResellerListController(
    $state,
    APP_CONSTANTS,
    toaster,
    ResellerService,
    $uibModal,
    $scope
  ) {
    var vm = this;

    $scope.$parent.vm.setActiveTab();
    $scope.$parent.vm.generateBreadCrumbs(APP_CONSTANTS.CKADMIN_TABS.RESELLERS);

    vm.register = register;
    vm.closeModal = closeModal;
    vm.loginAsReseller = loginAsReseller;
    vm.openCreateResellerModal = openCreateResellerModal;

    vm.pageNumber = 1;
    vm.pageSize = 10;
    vm.searchKey = '';
    vm.orderCol = 1;
    vm.sortBy = 'name';
    vm.orderBy = 'asc';
    vm.totalRecords = 0;
    vm.refreshTimeStamp = Date.now();
    vm.pageSizeOptions = APP_CONSTANTS.pageSizeOptions;
    vm.pageSizeOption = {
      key: "'" + vm.pageSize + "'",
      value: vm.pageSize
    };

    $.fn.dataTable.ext.order['reseller-custom-sort'] = function (settings, col) {
      const orderBy = _.get(settings, 'aaSorting[0][1]');
      const sortBy = _.get(settings, ['aoColumns', col, 'sortBy']);

      if (!sortBy || orderBy == vm.orderBy && sortBy == vm.sortBy) return false;

      vm.orderBy = orderBy;
      vm.sortBy = sortBy;
      fetchResellerList();
      return false;
    };
    vm.dtOptions = {
      paging: false,
      searching: false,
      info: false,
      order: [],
      columnDefs: [
        {
          orderable: false,
          targets: 'no-sort'
        },
        {
          orderDataType: 'reseller-custom-sort',
          targets: '_all'
        }
      ]
    };

    function fetchResellerList() {
      vm.fetchResellerLoading = true;
      ResellerService.fetchResellerList(
        vm.pageNumber,
        vm.pageSize,
        vm.searchKey,
        vm.sortBy,
        vm.orderBy
      ).then(
        function (response) {
          vm.resellerList = response.data.data.customers;

          vm.pageNumber = response.data.data.pageNumber
            ? response.data.data.pageNumber
            : 1;
          vm.pageSize = response.data.data.pageSize
            ? response.data.data.pageSize
            : 10;
          vm.totalRecords = response.data.data.totalRecords;
          vm.refreshTimeStamp = Date.now();
          vm.pageSizeOption = {
            key: "'" + vm.pageSize + "'",
            value: vm.pageSize
          };
          vm.fetchResellerLoading = false;
        },
        function (error) {
          toaster.pop({
            type: 'error',
            body: error.data.message || error.statusText,
            toasterId: 'ckAdmin'
          });

          vm.fetchResellerLoading = false;
        }
      );
    };

    fetchResellerList();

    vm.getTableData = function (event) {
      var $targetElement = $(event.currentTarget);

      if (
        !$targetElement.hasClass('disabled') &&
        !$targetElement.hasClass('current')
      ) {
        vm.pageNumber = $targetElement.data('pageNum');
        fetchResellerList();
      }
    };

    vm.pageSizeChanged = function () {
      vm.pageSize = vm.pageSizeOption;
      vm.searchKey = '';
      vm.pageNumber = 1;
      fetchResellerList();
    };

    vm.searchByKey = function (event) {
      if (event.keyCode !== 13) return;

      vm.pageNumber = 1;
      fetchResellerList();
    };

    function closeModal() {
      $scope.modalInstance.close();
    }

    function openCreateResellerModal() {
      $scope.modalInstance = $uibModal.open({
        animation: true,
        templateUrl: 'app/ckadmin/resellers/views/addReseller.html',
        controller: 'AddResellerController',
        controllerAs: 'regController',
        size: 'lg',
        scope: $scope
      });
    }

    function loginAsReseller(reseller) {
      vm.fetchResellerLoading = true;

      ResellerService.loginAsReseller(reseller.id)
        .catch(error => {
          vm.fetchResellerLoading = false;
          toaster.pop({
            type: 'error',
            body: error.data.message || error.statusText,
          });
        });
    }

    function register(valid) {
      var vm = this;

      if (!valid) {
        vm.formSubmit = true;

        return;
      }
      vm.registrationData.password = '';
      ResellerService.storeRegistrationInfo(vm.registrationData);
      vm.resellerRegistrationLoader = true;
      ResellerService.register().then(
        function () {
          $scope.modalInstance.close();
          ResellerService.destroyRegistrationData();
          vm.resellerRegistrationLoader = false;
          $state.go('login');
        },
        function (error) {
          vm.resellerRegistrationLoader = false;
          toaster.pop({
            type: 'error',
            body: error.data.message || error.statusText,
            toasterId: 'ckAdmin'
          });
        }
      );
    }
  }
})();
