(function() {
  'use strict';

  angular.module('app.shared').factory('DetailsService', DetailsService);

  DetailsService.$inject = ['APP_CONSTANTS', 'ApiCaller', '$filter', '$q'];

  function DetailsService(APP_CONSTANTS, ApiCaller, $filter, $q) {
    var self = this;

    self.addTag = addTag;
    self.editTag = editTag;
    self.addRegion = addRegion;
    self.editRegion = editRegion;
    self.fetchAllAssessments = fetchAllAssessments;
    self.addSurveyVariation = addSurveyVariation;
    self.removeSurveyVariation = removeSurveyVariation;
    self.fetchProductDetails = fetchProductDetails;

    function fetchProductDetails(productId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.productSingle,
        APP_CONSTANTS.rootUrl,
        productId
      );

      ApiCaller.apiGet(url)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function addSurveyVariation(productId, assessmentId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.addSurveyVariation,
        APP_CONSTANTS.rootUrl,
        productId
      );

      ApiCaller.apiPut(url, { assessmentId: assessmentId })
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function addRegion(productId, regionName) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.productRegions,
        APP_CONSTANTS.rootUrl,
        productId
      );

      ApiCaller.apiPost(url, { name: regionName })
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function addTag(productId, tagName) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.productTags,
        APP_CONSTANTS.rootUrl,
        productId
      );

      ApiCaller.apiPost(url, { name: tagName })
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function editRegion(productId, region) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.regionSingle,
        APP_CONSTANTS.rootUrl,
        productId,
        region.id
      );

      ApiCaller.apiPut(url, { name: region.name })
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function editTag(productId, tag) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.tagSingle,
        APP_CONSTANTS.rootUrl,
        productId,
        tag.id
      );

      ApiCaller.apiPut(url, { name: tag.name })
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function removeSurveyVariation(productId, assessmentId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.removeSurveyVariation,
        APP_CONSTANTS.rootUrl,
        productId
      );

      ApiCaller.apiPut(url, { assessmentId: assessmentId })
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function fetchAllAssessments() {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.allAssessments,
        APP_CONSTANTS.rootUrl
      );

      ApiCaller.apiGet(url)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    return self;
  }
})();
