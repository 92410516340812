/* =========================================================
 * Module: Discoverer Detatils
 =========================================================*/

(function() {
  'use strict';

  angular
    .module('app.careerDecisionProfileClient')
    .controller('CDPClientDetailsController', CDPClientDetailsController);

  CDPClientDetailsController.$inject = [
    '$scope',
    '$stateParams',
    'toaster',
    'CDPClientDetailsService',
    'APP_CONSTANTS',
    'FileSaver',
    '$state',
    '$uibModal'
  ];

  function CDPClientDetailsController(
    $scope,
    $stateParams,
    toaster,
    CDPClientDetailsService,
    APP_CONSTANTS,
    FileSaver,
    $state,
    $uibModal
  ) {
    var vm = this;

    vm.loading = {};
    vm.activeIndex = 0;
    vm.assessmentCompleted = false;
    vm.decidedness = APP_CONSTANTS.SURVEY_DIMENSIONS.DECIDEDNESS;
    vm.comfort = APP_CONSTANTS.SURVEY_DIMENSIONS.COMFORT;
    vm.selfClarity = APP_CONSTANTS.CAREER_DECISION_REASONS.SELF_CLARITY;
    vm.knowledge = APP_CONSTANTS.CAREER_DECISION_REASONS.KNOWLEDGE;
    vm.decisiveness = APP_CONSTANTS.CAREER_DECISION_REASONS.DECISIVENESS;
    vm.careerChoiceImportance =
      APP_CONSTANTS.CAREER_DECISION_REASONS.CAREER_CHOICE_IMPORTANCE;
    vm.barrier = APP_CONSTANTS.CAREER_DECISION_REASONS.BARRIER;
    vm.externalBarriers =
      APP_CONSTANTS.CAREER_DECISION_REASONS.EXTERNAL_BARRIERS;

    vm.toggleResults = toggleResults;
    vm.sendMessage = sendMessage;
    vm.shareResult = shareResult;
    vm.exportToPdf = exportToPdf;
    vm.discovererId = $stateParams.clientId;

    vm.dtOptions = {
      paging: false,
      searching: false,
      info: false,
      order: [],
      destroy: true,
      columnDefs: [
        {
          orderable: true
        },
        {
          orderDataType: 'fa-check-circle',
          targets: [3]
        }
      ]
    };
    $.fn.dataTable.ext.order['fa-check-circle'] = function (settings, col) {
      return this.api().column(col, { order: 'index' }).nodes().map(function (td, i) {
        return $('i', td).hasClass('fa-check-circle');
      });
    }

    getClientDetails($stateParams.clientId);

    function getClientDetails(clientId) {
      vm.loading.clientDetails = true;

      CDPClientDetailsService.getClientDetails(clientId)
        .then(function(result) {
          vm.resultContent = result.data.content[0];
          showDiscovererDetails(result.data.discoverer);
          if (result.data.discoverer.cdpScore.serviceLevel) {
            vm.assessmentCompleted = true;
            toggleResults(0);
          } else {
            vm.assessmentCompleted = false;
          }
          handleSendMessageOptions();
          vm.loading.clientDetails = false;
        })
        .catch(function(error) {
          vm.loading.clientDetails = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function showDiscovererDetails(result) {
      vm.fullName = result.fullName;
      vm.email = result.email;
      vm.assignedGroups = result.groupsName.join(', ');
      vm.lastContact = result.lastContact;
      vm.messages = _.filter(result.messages, { type: APP_CONSTANTS.CDP })
        .map(function (message) {
          let option = _.find(APP_CONSTANTS.letsConnectOptions, function (option) {
            return message.template === option.template ||
              message.template === option.messageType;
          }) || {};
          return {
            date: message.date,
            subject: message.subject,
            template: option.label || message.template,
            deliveryStatus: message.deliveryStatus !== false
          };
        });

      const maxTabCount = APP_CONSTANTS.CDP_RETAKE_ASSESSMENT_LIMIT + 1;

      vm.cdpScores = result.cdpScoreHistory.concat({
        cdpAssessmentDate: result.cdpAssessmentDate,
        cdpScore: result.cdpScore
      });
      vm.cdpScores = vm.cdpScores.filter(function(cdpScore) {
        return cdpScore.cdpAssessmentDate;
      });
      vm.cdpScores.reverse();
      vm.cdpScores.splice(maxTabCount);

      vm.cdpSurveyResponse = result.cdpSurveyResponseHistory.concat({
        cdpAssessmentDate: result.cdpAssessmentDate,
        surveyResponse: result.cdpSurveyResponse
      });
      vm.cdpSurveyResponse.reverse();
      vm.cdpSurveyResponse.splice(maxTabCount);
    }

    function calculateScores() {
      vm.calculatedScores = {};

      _.get(vm.cdpScores, `[${vm.activeIndex}].cdpScore.score`, []).forEach(function (scoreObj) {
        if (!scoreObj.score) return;

        let maxScore = 14;

        if (scoreObj.dimension === APP_CONSTANTS.SURVEY_DIMENSIONS.CAREER_DECISION_NEEDS) {
          maxScore = 21;

          if (scoreObj.reason === APP_CONSTANTS.CAREER_DECISION_REASONS.BARRIER) {
            maxScore = 28;
          }
          else if (scoreObj.reason === APP_CONSTANTS.CAREER_DECISION_REASONS.EXTERNAL_BARRIERS) {
            maxScore = 42;
          }
        }

        vm.calculatedScores[scoreObj.reason] = {
          scale: scoreObj.scale,
          score: 100 - (scoreObj.score / maxScore) * 100
        };
      });
    }

    function calculateServiceLevel() {
      vm.serviceLevel = _.get(vm.cdpScores, `[${vm.activeIndex}].cdpScore.serviceLevel`, '');
      let serviceLevel = vm.serviceLevel.toLowerCase();

      if (serviceLevel === APP_CONSTANTS.SELF_HELP.toLowerCase()) {
        vm.serviceLevelClass = 'sl-self-help fa fa-circle';
      }
      else if (serviceLevel === APP_CONSTANTS.BRIEF_STAFF_ASSISTED.toLowerCase()) {
        vm.serviceLevelClass = 'sl-brief-staff-assist fa fa-circle';
      }
      else if (serviceLevel === APP_CONSTANTS.INDIVIDUAL_CASE_MANAGED_SERVICES.toLowerCase()) {
        vm.serviceLevelClass = 'sl-individual-case-managed fa fa-circle';
      }
    }

    function showOtherFactors() {
      let surveyResponse = _.get(vm.cdpSurveyResponse, `[${vm.activeIndex}].surveyResponse`, {});
      vm.otherFactors = _.get(surveyResponse, 'otherFactors', []).join(', ');
      vm.selectedExternalBarriers = _.get(surveyResponse, 'selectedExternalBarriers', []).join(', ');
      vm.customExternalBarriers = _.get(surveyResponse, 'customExternalBarriers', []).join(', ');
      vm.impactReason = _.get(vm.cdpScores, `[${vm.activeIndex}].cdpScore.reasonOfImpact`, '');
    }

    function toggleResults(index) {
      vm.activeIndex = index || 0;
      calculateScores();
      calculateServiceLevel();
      showOtherFactors();
    }

    function exportToPdf() {
      vm.loading.clientDetails = true;

      CDPClientDetailsService
        .generateCDPProfilePdf(vm.discovererId)
        .then(function(response) {
          const file = new Blob([response.data], { type: 'application/pdf' });

          FileSaver.saveAs(file, 'profile.pdf');
        })
        .catch(function(error) {
          toaster.pop('error', error.data?.message || error.statusText);
        })
        .finally(function () {
          vm.loading.clientDetails = false;
        });
    }

    function shareResult(discovererId) {
      $scope.modalInstance = $uibModal.open({
        animation: true,
        templateUrl: 'emailTemplate',
        controller: function() {
          var vm = this;

          vm.loading = {};
          vm.shareEmail = '';
          vm.discovererId = discovererId;
          vm.isSubmitted = false;
          vm.regex = {
            email: APP_CONSTANTS.REGEX.EMAIL
          };

          vm.close = function() {
            $scope.modalInstance.close();
          };
          vm.sendEmail = function(form) {
            vm.isSubmitted = true;
            if (!form.$valid) {
              return;
            }
            vm.loading.shareResult = true;

            CDPClientDetailsService.shareResult(vm.discovererId, vm.shareEmail)
              .then(function(result) {
                vm.isSubmitted = false;
                vm.loading.shareResult = false;

                $scope.modalInstance.close();
                toaster.pop('success', 'Email sent successfully');
              })
              .catch(function(error) {
                vm.loading.shareResult = false;
                vm.isSubmitted = false;
                toaster.pop('error', error.data.message || error.statusText);
              });
          };
        },
        controllerAs: 'vm',
        size: 'md',
        scope: $scope
      });
    }

    function handleSendMessageOptions() {
      vm.letsConnectOptions = _.filter(APP_CONSTANTS.letsConnectOptions, function (option) {
        if (option.messageType === APP_CONSTANTS.CUSTOM) return true;
        if (option.subscriptionType !== APP_CONSTANTS.CDP) return false;

        if (option.messageType === APP_CONSTANTS.INVITE_TO_START) {
          return !vm.assessmentCompleted;
        }

        if (option.messageType === APP_CONSTANTS.RETAKE_CDP) {
          return (vm.cdpScores.length
            && vm.cdpScores.length <= APP_CONSTANTS.CDP_RETAKE_ASSESSMENT_LIMIT
          );
        }

        return vm.assessmentCompleted;
      });
    }

    function sendMessage(option) {
      option.discoverers = [{ email: vm.email }];
      $state.go('app.mailbox.cdp', option);
    }
  }
})();
