(() => {
  'use strict';

  angular.module('careerKeyCentral')
    .controller('BreadCrumbController', [
      '$state',
      '$stateParams',
      'APP_CONSTANTS',
      'OwnerDashboardService',
      'SessionService',
      'toaster',
      BreadCrumbController
    ]);

  function BreadCrumbController(
    $state,
    $stateParams,
    APP_CONSTANTS,
    OwnerDashboardService,
    SessionService,
    toaster,
  ) {
    const vm = this;
    vm.breadCrumbs = '';
    vm.loading = {};

    const currentUser = SessionService.getUser();
    const sponsoredOrganizationId = $stateParams.sponsoredOrganizationId;

    if (sponsoredOrganizationId) {
      fetchSponsoredOrganizationDetails();
    } else {
      fetchCustomerDetails();
    }

    function fetchCustomerDetails() {
      vm.loading.breadCrumb = true;

      OwnerDashboardService.fetchCustomerDetails(currentUser.customerId)
        .then(function (response) {
          const customerDetails = _.get(response, 'data.data') || {};

          setPageHeader(
            customerDetails.customerName,
            customerDetails.sponsoredOrganizationName,
            customerDetails.profileImage
          );
        })
        .catch(function (error) {
          toaster.pop('error', error.data.message || error.statusText);
        })
        .finally(() => {
          vm.loading.breadCrumb = false;
        });
    }

    function fetchSponsoredOrganizationDetails() {
      vm.loading.breadCrumb = true;

      OwnerDashboardService.fetchSponsoredOrganizationName(sponsoredOrganizationId)
        .then((response) => {
          const sponsoredOrganizationDetails = _.get(response, 'data.data') || {};

          setPageHeader(
            sponsoredOrganizationDetails.customerName,
            sponsoredOrganizationDetails.legalName,
            sponsoredOrganizationDetails.profileImage
          );
        })
        .catch((error) => {
          toaster.pop('error', error.data.message || error.statusText);
        })
        .finally(() => {
          vm.loading.breadCrumb = false;
        });
    }

    function setPageHeader(
      customerName = '',
      organizationName = '',
      profileImage = ''
    ) {
      const menu = _.find(currentUser.menus, { sref: $state.current.name }) || {};
      const page = menu.text || $state.current.title || '';

      vm.organizationName = organizationName;
      vm.breadCrumbs = customerName + ' > ' + page + ' - ' + organizationName;

      if (profileImage) {
        vm.profileImage = profileImage + '?=' + Math.floor(new Date().getTime() / 15000); //15sec interval to avoid caching of image
      } else {
        vm.profileImage = APP_CONSTANTS.defaultOrganizationImage;
      }
    }

  }

})();
