(function() {
  'use strict';

  angular
    .module('app.ckadmin')
    .controller('ckAdminController', ckAdminController);

  ckAdminController.$inject = ['APP_CONSTANTS', '$state'];

  function ckAdminController(APP_CONSTANTS, $state) {
    var vm = this;

    vm.loading = {};
    vm.breadCrumbs = 'Dashboard';
    vm.setActiveTab = setActiveTab;
    vm.loadBreadCrumbs = loadBreadCrumbs;
    vm.generateBreadCrumbs = generateBreadCrumbs;

    function setActiveTab() {
      vm.activeTabIndex = APP_CONSTANTS.CKADMIN_TAB_INDEX[$state.current.adminTabState || $state.current.name]
        || vm.activeTabIndex || 0;
    }

    function generateBreadCrumbs(state) {
      vm.breadCrumbs = 'Dashboard' + ' > ' + state;
    }

    function loadBreadCrumbs(state) {
      vm.loading.productInfo = state;
    }
  }
})();
