(function() {
  'use strict';
  angular.module('app.user').controller('TrialController', TrialController);

  TrialController.$inject = [
    '$state',
    'TrialService',
    'APP_CONSTANTS',
    'toaster'
  ];

  function TrialController($state, TrialService, APP_CONSTANTS, toaster) {
    var vm = this;

    vm.trialSignUpLoading = true;
    TrialService.fetchTrialPlan().then(function(result) {
      TrialService.storePlan(result.data.data);
      activate();
      vm.trialSignUpLoading = false;
    });

    function activate() {
      vm.register = register;
      var plan = TrialService.retrievePlan();

      vm.trialDetail = plan || {};
      vm.organizationType = TrialService.organizationType();

      function register() {
        vm.trialSignUpLoading = true;
        TrialService.register().then(
          function() {
            TrialService.destroyRegistrationData();
            toaster.pop({
              type: 'info',
              title: APP_CONSTANTS.successHeader,
              body: APP_CONSTANTS.registerSuccessMessage,
              showCloseButton: true,
              bodyOutputType: 'trustedHtml',
              timeout: 5000,
              onHideCallback: function() {
                vm.trialSignUpLoading = false;
                $state.go('login');
              }
            });
          },
          function(error) {
            toaster.pop('error', error.data.message || error.statusText);
            vm.trialSignUpLoading = false;
          }
        );
      }
    }
  }
})();
