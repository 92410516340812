/* eslint-disable */
(function() {
  'use strict';

  angular
    .module('app.sponsoredOrganization')
    .controller('CKDPluginController', CKDPluginController);

  CKDPluginController.$inject = ['$scope'];

  function CKDPluginController($scope) {
    $scope.copyToolTip = 'Copy!';

    $scope.copyClicked = function() {
      $scope.copyToolTip = 'Copied to clipboard!';
      $timeout(resetCopyToolTip, 3000);
    };

    function resetCopyToolTip() {
      $scope.copyToolTip = 'Copy!';
    }
  }
})();
/* eslint-enable */
