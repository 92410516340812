(function() {
  'use strict';
  angular.module('app.mailbox').config(routesConfig);

  routesConfig.$inject = [
    '$stateProvider',
    '$locationProvider',
    '$urlRouterProvider',
    'RouteHelpersProvider'
  ];

  function routesConfig(
    $stateProvider,
    $locationProvider,
    $urlRouterProvider,
    helper
  ) {
    // Set the following to true to enable the HTML5 Mode
    // You may have to set <base> tag in index and a routing configuration in your server
    $locationProvider.html5Mode(false);

    // Mailbox
    // -----------------------------------
    $stateProvider
      .state('app.mailbox', {
        url: '/mailbox',
        title: 'Mailbox',
        templateUrl: helper.basepath('letsConnect/views/mailbox.html'),
        controller: 'MailboxController',
        controllerAs: 'vm'
      })
      .state('app.mailbox.folder', {
        url: '/folder/:folder',
        title: 'Mailbox',
        templateUrl: helper.basepath('letsConnect/views/mailboxInbox.html'),
        controller: 'MailFolderController',
        controllerAs: 'mailfolder',
        resolve: helper.resolveFor('modernizr', 'icons', 'datatables')
      })
      .state('app.mailbox.view', {
        url: '/users/:userId/mails/:mailId',
        title: 'View mail',
        templateUrl: helper.basepath('letsConnect/views/mailboxView.html'),
        controller: 'MailViewController',
        controllerAs: 'mailview',
        resolve: helper.resolveFor('ngWig')
      })
      .state('app.mailbox.compose', {
        url: '/compose',
        title: 'Mailbox',
        templateUrl: helper.basepath('letsConnect/views/customMessage.html'),
        controller: 'CustomMessageController',
        controllerAs: 'custom',
        params: {
          emails: [],
          messageType: '',
          template: '',
        },
        resolve: helper.resolveFor('ngWig', 'ui.select', 'ngDialog')
      })
      .state('app.mailbox.cdp', {
        url: '/cdp/send-messages',
        title: 'Mailbox',
        templateUrl: helper.basepath('letsConnect/views/cdpMessage.html'),
        controller: 'CDPMessageController',
        controllerAs: 'vm',
        params: {
          discoverers: [],
          messageType: 'custom',
          template: ''
        },
        secured: true,
        resolve: helper.resolveFor('ngWig', 'ui.select', 'ngDialog')
      });
  }
})();
