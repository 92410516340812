(function() {
  'use strict';

  angular
    .module('app.sponsoredOrganization')
    .factory('TechnicalDashboardService', TechnicalDashboardService);

  TechnicalDashboardService.$inject = [
    'APP_CONSTANTS',
    '$q',
    '$filter',
    'ApiCaller',
    'SessionService'
  ];

  function TechnicalDashboardService(APP_CONSTANTS, $q, $filter, ApiCaller) {
    var self = this;

    self.generateWidget = generateWidget;

    // TODO: Rename CustomerApiKey to Plugin
    self.addCustomerApiKey = addCustomerApiKey;
    self.updateCustomerApiKey = updateCustomerApiKey;
    self.fetchCustomerApiKeys = fetchCustomerApiKeys;

    function generateWidget(plugin) {
      var deferred = $q.defer();
      var url =
        'app/v2/sponsoredOrganization/technicalDashboard/ckdPlugin/ckdPlugin.html';
      // var user = SessionService.getUser();
      // SchoolId to be populated if myInstitutionOffer option is selected.

      // TODO: School Id should be removed as a param in the plugin
      // var schoolId = user.collegeMajor === 'myInstitutionOffer' ? user.schoolId : '';

      ApiCaller.apiGet(url)
        .then(function(response) {
          var sourceHtml = response.data;
          let title = (plugin.type === APP_CONSTANTS.BOTH
            ? 'PathAdvisor'
            : (
              plugin.type === APP_CONSTANTS.CDP
                ? 'Career Decision Profile'
                : 'Career Key Discovery'
            )
          );

          sourceHtml = sourceHtml.replace(/\$title/, title);
          sourceHtml = sourceHtml.replace(/\$apiKey/, plugin.accesskey);
          
          // sourceHtml = sourceHtml.replace(/\$schoolId/, plugin.schoolId);
          sourceHtml = sourceHtml.replace(
            /\$hostName/g,
            APP_CONSTANTS.hostName
          );
          deferred.resolve(sourceHtml);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function addCustomerApiKey(data, customer_id) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.pluginCollection,
        APP_CONSTANTS.rootUrl,
        customer_id
      );

      ApiCaller.apiPost(url, data)
        .then(function(response) {
          deferred.resolve(response);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function fetchCustomerApiKeys(customer_id) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.customerAPIKey,
        APP_CONSTANTS.rootUrl,
        customer_id
      );

      ApiCaller.apiGet(url)
        .then(function(response) {
          deferred.resolve(response);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function updateCustomerApiKey(data, customer_id) {
      var deferred = $q.defer();
      var apikey_id = data.accessKey.id;
      var url = $filter('sprintf')(
        APP_CONSTANTS.deactivateCustomerAPIKey,
        APP_CONSTANTS.rootUrl,
        customer_id,
        apikey_id
      );

      ApiCaller.apiPut(url, data)
        .then(function(response) {
          deferred.resolve(response);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }


    return self;
  }
})();
