(function() {
  'use strict';
  angular
    .module('app.customerAccount')
    .controller('CustomerAccountController', CustomerAccountController);

  CustomerAccountController.$inject = [
    'APP_CONSTANTS',
    'SessionService',
    '$state',
    'toaster',
    'AccountUpdateService',
    'OrganizationInfoService',
    '$rootScope'
  ];

  function CustomerAccountController(
    APP_CONSTANTS,
    SessionService,
    $state,
    toaster,
    AccountUpdateService,
    OrganizationInfoService,
    $rootScope
  ) {
    var vm = this;

    var user = SessionService.getUser();

    if (!user.roles.isAccountManager) {
      $state.go('app.ownerdashboard');

      return;
    }

    vm.loading = {};
    vm.usedQuantity = 0;
    vm.totalQuantity = 0;
    vm.organizationInfo = {};
    vm.organizationInfo.isNotPrimaryContact = true;

    vm.open = open;
    vm.setActiveTab = setActiveTab;
    vm.fetchAccountImage = fetchAccountImage;
    var currentUser = SessionService.getUser();

    vm.fetchAccountInformation = fetchAccountInformation;
    vm.availableLanguages = APP_CONSTANTS.availableLanguages;

    vm.regex = {
      email: APP_CONSTANTS.REGEX.EMAIL
    };

    activate();

    function activate() {
      fetchAccountImage();
      fetchAccountInformation();
    }

    function setActiveTab() {
      vm.activeTabIndex =
        APP_CONSTANTS.CUSTOMER_ACCOUNT_TAB_INDEX[$state.current.name];
    }

    function fetchAccountInformation() {
      vm.loading.organizationInfo = true;
      AccountUpdateService.getAccountInfo(currentUser.customerId)
        .then(function(response) {
          var data = _.get(response, 'data.data', {});

          vm.organizationName = data.legalName || '';
          vm.showBillingTab = data.isReseller;
          vm.loading.organizationInfo = false;
        })
        .catch(function(error) {
          vm.loading.organizationInfo = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function fetchAccountImage() {
      vm.loading.organizationImage = true;

      AccountUpdateService.getAccountImage(user.customerId)
        .then(function(response) {
          var data = _.get(response, 'data.data', {});

          vm.loading.organizationImage = false;
          vm.profileImage = _.get(data, 'profileImage', '');
          vm.profileImage = vm.profileImage
            ? vm.profileImage + '?=' + $rootScope.moment().unix()
            : APP_CONSTANTS.defaultOrganizationImage;
          AccountUpdateService.setImage(vm.profileImage);
        })
        .catch(function(error) {
          vm.loading.organizationImage = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }
  }
})();
