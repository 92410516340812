(function() {
  'use strict';

  angular
    .module('app.customerAccount')
    .controller('NewBillingController', BillingController);

  BillingController.$inject = [
    '$uibModal',
    '$state',
    'APP_CONSTANTS',
    'toaster',
    'NewSubscriptionService',
    'NewBillingService',
    'SessionService',
    '$rootScope',
    '$scope'
  ];
  function BillingController(
    $uibModal,
    $state,
    APP_CONSTANTS,
    toaster,
    NewSubscriptionService,
    NewBillingService,
    SessionService,
    $rootScope,
    $scope
  ) {
    var vm = this;

    vm.autopay = '';
    vm.loading = {};
    vm.accountType = {};
    vm.percentage = 0;
    vm.usedQuantity = 0;
    vm.totalQuantity = 0;
    vm.hidePO = false;
    vm.loading.payment = true;
    vm.manageSubscriptions = false;
    vm.editPaymentMethodFlag = true;
    vm.loading.billingDetail = true;
    vm.selectedSubscriptionType = '';
    vm.subscriptionOptionFlag = false;
    vm.scheduledcancellation = APP_CONSTANTS.scheduledcancellation;
    vm.constants = {
      emptyPaymentDetail: APP_CONSTANTS.emptyPaymentDetail,
      emptyBillingDetail: APP_CONSTANTS.emptyBillingDetail
    };
    vm.data = {
      poNumber: '',
      billingDetail: {},
      paymentDetail: {}
    };

    vm.dtOptions = {
      order: [],
      columnDefs: [
        {
          orderable: false,
          targets: [0]
        }
      ]
    };

    var currentUser = SessionService.getUser();
    vm.selfService = currentUser.selfService || false;

    activate();
    fetchBillingDetail();

    function activate() {
      $scope.$parent.vm.setActiveTab();
      var modalInstance = '';

      vm.addAddons = function(size) {
        if (!modalInstance) {
          modalInstance = $uibModal.open({
            templateUrl: 'app/v2/customerAccount/views/partials/addAddonModal.html',
            controller: 'AddonsController',
            controllerAs: 'vm',
            windowClass: 'billing-modal',
            size: size,
            resolve: {
              data: function() {
                return vm.data;
              }
            }
          });

          modalInstance.result
            .then(function() {
              vm.selectedSubscription = {};
              modalInstance = '';
              getSubscription();
            })
            .catch(function() {
              modalInstance = '';
            });
        }
      };

      vm.addSubscription = function(size) {
        if (!modalInstance) {
          vm.data.subscription = {};
          vm.selectedSubscription = {};

          modalInstance = $uibModal.open({
            templateUrl: '/addSubscriptionModal.html',
            controller: 'AddSubscriptionController',
            controllerAs: 'vm',
            windowClass: 'add-subscription-modal',
            size: size,
            resolve: {
              data: function() {
                return vm.data;
              }
            }
          });

          modalInstance.result
            .then(function() {
              modalInstance = '';
              getSubscription();
            })
            .catch(function() {
              modalInstance = '';
            });
        }
      };

      vm.upgradeToPaidPlan = function(size) {
        if (!modalInstance) {
          modalInstance = $uibModal.open({
            templateUrl: '/addSubscriptionModal.html',
            controller: 'AddSubscriptionController',
            controllerAs: 'vm',
            windowClass: 'add-subscription-modal',
            size: size,
            resolve: {
              data: function() {
                return vm.data;
              }
            }
          });

          modalInstance.result
            .then(function() {
              vm.selectedSubscription = {};
              modalInstance = '';
              getSubscription();
            })
            .catch(function() {
              modalInstance = '';
            });
        }
      };

      vm.nextPlan = function(size) {
        if (!modalInstance) {
          modalInstance = $uibModal.open({
            templateUrl: '/addNextPlanModal.html',
            controller: 'AddNextPlanController',
            controllerAs: 'vm',
            windowClass: 'add-next-plan-modal',
            size: size,
            resolve: {
              data: function() {
                return vm.data;
              }
            }
          });

          modalInstance.result
            .then(function() {
              vm.selectedSubscription = {};
              getSubscription();
              modalInstance = '';
            })
            .catch(function() {
              modalInstance = '';
            });
        }
      };

      vm.displayAddons = function(subscription) {
        if (!modalInstance) {
          modalInstance = $uibModal.open({
            templateUrl:
              'app/v2/customerAccount/views/partials/displayAddons.html',
            controller: 'DisplayAddonsController',
            controllerAs: 'vm',
            resolve: {
              subscription: function() {
                return subscription;
              }
            }
          });

          modalInstance.result
            .then(function() {
              modalInstance = '';
            })
            .catch(function() {
              modalInstance = '';
            });
        }
      };

      vm.cancel = function(size) {
        if (!modalInstance) {
          modalInstance = $uibModal.open({
            templateUrl: '/cancelModal.html',
            controller: 'CancelSubscriptionController',
            controllerAs: 'vm',
            size: size,
            resolve: {
              data: function() {
                return vm.data;
              }
            }
          });

          modalInstance.result
            .then(function() {
              vm.selectedSubscription = {};
              getSubscription();
              modalInstance = '';
            })
            .catch(function() {
              modalInstance = '';
            });
        }
      };

      vm.editBillingInformationModal = function(size) {
        if (!modalInstance) {
          modalInstance = $uibModal.open({
            templateUrl: '/editBillingModal.html',
            controller: EditBillingInformationModalInstanceCtrl,
            controllerAs: 'bc',
            size: size
          });

          modalInstance.result
            .then(function() {
              vm.loading.billingDetail = true;
              getBillingDetail();
              modalInstance = '';
            })
            .catch(function() {
              modalInstance = '';
            });
        }
      };

      vm.verifyAcc = function(size) {
        if (!modalInstance) {
          modalInstance = $uibModal.open({
            templateUrl: '/verifyAccountModal.html',
            controller: 'VerifyAccountController',
            controllerAs: 'vm',
            size: size
          });

          modalInstance.result
            .then(function() {
              modalInstance = '';
            })
            .catch(function() {
              modalInstance = '';
            });
        }
      };

      vm.editPayment = function(size) {
        if (!modalInstance) {
          modalInstance = $uibModal.open({
            templateUrl: '/editPaymnetModal.html',
            controller: 'EditPaymentInfoController',
            controllerAs: 'vm',
            size: size,
            resolve: {
              hidePO: function() {
                return vm.hidePO;
              }
            }
          });

          modalInstance.result
            .then(function() {
              vm.loading.payment = true;
              getPaymentDetail();
              modalInstance = '';
            })
            .catch(function() {
              modalInstance = '';
            });
        }
      };

      vm.handleSubscriptionSelect = function(subscription) {
        vm.nextPlanFlag = false;
        vm.addAddonsFlag = false;
        vm.upgradeToPaidPlanFlag = false;
        vm.cancelSubscriptionFlag = false;
        vm.selectedSubscription = subscription;
        vm.selectedSubscriptionType = subscription.type;

        vm.data = {
          billingDetail: vm.billingDetail,
          paymentDetail: vm.paymentDetail,
          subscription: vm.selectedSubscription
        };

        if (
          vm.selectedSubscription.type.toLowerCase() ===
            APP_CONSTANTS.SUBSCRIPTION_TYPE.PREPAID &&
          subscription.status.toLowerCase() !==
            APP_CONSTANTS.SUBSCRIPTION_STATUS.PENDING
        ) {
          vm.addAddonsFlag = true;
        } else if (
          vm.selectedSubscription.type.toLowerCase() ===
          APP_CONSTANTS.SUBSCRIPTION_TYPE.TRIAL
        ) {
          vm.upgradeToPaidPlanFlag = true;
        } else if (
          vm.selectedSubscription.type.toLowerCase() ===
            APP_CONSTANTS.SUBSCRIPTION_TYPE.AUTO_RENEWAL &&
          !(
            vm.selectedSubscription.status ===
              APP_CONSTANTS.SUBSCRIPTION_STATUS.CANCELLED ||
            vm.selectedSubscription.status ===
              APP_CONSTANTS.SUBSCRIPTION_STATUS.SCHEDULE_TO_CANCEL
          )
        ) {
          vm.nextPlanFlag = true;
          vm.cancelSubscriptionFlag = true;
        }

        if (
          vm.upgradeToPaidPlanFlag ||
          vm.cancelSubscriptionFlag ||
          vm.addAddonsFlag ||
          vm.selectedSubscriptionType.toLowerCase() ===
            APP_CONSTANTS.SUBSCRIPTION_TYPE.AUTORENEWAL
        ) {
          vm.subscriptionOptionFlag = true;
        } else {
          vm.subscriptionOptionFlag = false;
        }
      };

      // Please note that $uibModalInstance represents a modal window (instance) dependency.
      // It is not the same as the $uibModal service used above.

      EditBillingInformationModalInstanceCtrl.$inject = [
        '$uibModalInstance',
        'NewBillingService',
        'RegistrationService',
        'toaster',
        'APP_CONSTANTS',
        'SessionService'
      ];

      function EditBillingInformationModalInstanceCtrl(
        $uibModalInstance,
        NewBillingService,
        RegistrationService,
        toaster,
        APP_CONSTANTS,
        SessionService
      ) {
        var bc = this;

        bc.loading = {};
        bc.billingDetail = {};
        bc.selectedCountry = '';
        bc.fetchStates = fetchStates;
        bc.sameAsAccountInfo = false;
        bc.originalBillingDetail = {};
        bc.fetchCountries = fetchCountries;
        bc.copyAccountInfo = copyAccountInfo;
        bc.countrySelected = countrySelected;
        bc.getBillingDetail = getBillingDetail;
        bc.updateBillingContact = updateBillingContact;

        init();

        function init() {
          fetchCountries();
          getBillingDetail();
          getAccountInfo();
        }

        function getBillingDetail() {
          bc.loading.billingDetail = true;

          NewBillingService.billingDetail()
            .then(function(response) {
              bc.loading.billingDetail = false;
              bc.originalBillingDetail = _.get(response, 'data.data', {});
              if (_.isEmpty(bc.originalBillingDetail)) {
                angular.copy(bc.originalBillingDetail, bc.billingDetail);
              } else {
                NewBillingService.fetchCountries(
                  bc.originalBillingDetail.country
                )
                  .then(function(response) {
                    var country = _.get(response, 'data.data[0]', {});

                    bc.originalBillingDetail.country = country ? country : {};
                    angular.copy(bc.originalBillingDetail, bc.billingDetail);
                    countrySelected();
                  })
                  .catch(function(error) {
                    toaster.pop(
                      'error',
                      error.data.message || error.statusText
                    );
                  });
              }
            })
            .catch(function(error) {
              bc.loading.billingDetail = false;
              toaster.pop('error', error.data.message || error.statusText);
            });
        }

        function getAccountInfo() {
          var user = SessionService.getUser();

          bc.loading.accountDetail = true;

          NewBillingService.getAccountDetail(user.customerId)
            .then(function(response) {
              bc.loading.accountDetail = false;
              bc.accountDetail = _.get(response, 'data.data', {});
            })
            .catch(function(error) {
              bc.loading.accountDetail = false;
              toaster.pop('error', error.data.message || error.statusText);
            });
        }

        function copyAccountInfo() {
          if (!bc.sameAsAccountInfo) {
            angular.copy(bc.originalBillingDetail, bc.billingDetail);

            return;
          } else {
            angular.copy(bc.accountDetail, bc.billingDetail);
            bc.billingDetail.country.countryCode = bc.billingDetail.country.id;
            countrySelected();
          }
        }

        function fetchCountries(name) {
          RegistrationService.getCountries(name)
            .then(function(result) {
              var countries = result.data.data;

              bc.countries = [];

              countries.forEach(function(country) {
                bc.countries.push(country);
              });
            })
            .catch(function(error) {
              toaster.pop('error', error.data.message || error.statusText);
            });
        }

        function countrySelected() {
          RegistrationService.getStates(bc.billingDetail.country.countryCode)
            .then(function(result) {
              bc.states = result.data.data.states;
            })
            .catch(function(error) {
              toaster.pop('error', error.data.message || error.statusText);
            });
        }

        function fetchStates() {
          RegistrationService.getStates(bc.billingDetail.country.countryCode)
            .then(function(result) {
              bc.states = result.data.data.states;
              bc.billingDetail.state_code = null;
            })
            .catch(function(error) {
              toaster.pop('error', error.data.message || error.statusText);
            });
        }

        function updateBillingContact(form) {
          bc.form = form;

          if (bc.form.$invalid) return;

          var newBillingContact = {
            city: bc.form.city.$modelValue,
            zip: bc.form.zipCode.$modelValue,
            state: bc.billingDetail.state_code,
            line2: bc.form.addressLine2.$modelValue,
            last_name: bc.form.lastName.$modelValue,
            line1: bc.form.addressLine1.$modelValue,
            first_name: bc.form.firstName.$modelValue,
            country: bc.billingDetail.country.countryCode
          };

          bc.loading.billingDetail = true;

          NewBillingService.updateBillingDetail(newBillingContact)
            .then(function(response) {
              bc.loading.billingDetail = false;
              bc.billingDetail = _.get(response, 'data.data', {});
              toaster.pop(
                'success',
                APP_CONSTANTS.MESSAGE.billingContactUpdateSuccessful
              );
              bc.ok();
            })
            .catch(function(err) {
              bc.loading.billingDetail = false;

              if (err.data && err.data.error && err.data.error.code === 400)
                toaster.pop({
                  type: 'error',
                  body: 'Invalid Form Details',
                  toasterId: 'updateBilling'
                });
              else
                toaster.pop({
                  type: 'error',
                  body: err.data.message || err.statusText,
                  toasterId: 'updateBilling'
                });
            });
        }

        bc.ok = function() {
          $uibModalInstance.close('closed');
        };

        bc.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }

      ModalInstanceCtrl.$inject = ['$scope', '$uibModalInstance'];
      function ModalInstanceCtrl($scope, $uibModalInstance) {
        $scope.ok = function() {
          $uibModalInstance.close('closed');
        };

        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    }

    function fetchBillingDetail() {
      getSubscription();
      getBillingDetail();
      getPaymentDetail();
    }

    function getSubscription() {
      vm.loading.subscription = true;
      NewSubscriptionService.fetchSubscription()
        .then(function(response) {
          var data = _.get(response, 'data.data', {});

          vm.usedQuantity = _.get(data, 'usedQuantity', 0);
          vm.totalQuantity = _.get(data, 'totalQuantity', 0);
          vm.subscriptions = _.get(data, 'subscriptions', 0);
          vm.percentage =
            (vm.totalQuantity && (vm.usedQuantity * 100) / vm.totalQuantity) ||
            0;

          vm.subscriptions.filter(function(subscription) {

            if (
              subscription.type === APP_CONSTANTS.SUBSCRIPTION_TYPE.PREPAID ||
              subscription.type === APP_CONSTANTS.SUBSCRIPTION_TYPE.TRIAL
            ) {
              subscription.nextPlan.planName = '-';
              subscription.next_billing_at = '-';
            }
            if (
              subscription.status ===
                APP_CONSTANTS.SUBSCRIPTION_STATUS.SCHEDULE_TO_CANCEL ||
              subscription.status ===
                APP_CONSTANTS.SUBSCRIPTION_STATUS.CANCELLED
            ) {
              subscription.nextPlan.planName = '-';
              subscription.next_billing_at = '-';
            }

            if (
              subscription.periodUnit ===
                APP_CONSTANTS.AUTO_RENEWAL_TYPE.MONTHLY &&
              subscription.type === APP_CONSTANTS.SUBSCRIPTION_TYPE.AUTO_RENEWAL
            ) {
              subscription.endDate = $rootScope
                .moment(subscription.startDate)
                .add(1, 'years')
                .format();
            }

            subscription.actualStatus = subscription.status;

            if (
              subscription.periodUnit ===
              APP_CONSTANTS.AUTO_RENEWAL_TYPE.MONTHLY
            )
              vm.hidePO = true;

          });
          vm.loading.subscription = false;
        })
        .catch(function(error) {
          vm.loading.subscription = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function getBillingDetail() {
      NewBillingService.billingDetail()
        .then(function(response) {
          vm.loading.billingDetail = false;
          vm.billingDetail = _.get(response, 'data.data', {});
          vm.emptyBillingDetail = _.isEmpty(vm.billingDetail) ? true : false;
          vm.autoCollection = vm.billingDetail.autoCollection;
          angular.copy(vm.billingDetail, vm.data.billingDetail);
        })
        .catch(function(error) {
          vm.loading.billingDetail = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function getPaymentDetail() {
      NewBillingService.paymentDetail()
        .then(function(response) {
          vm.paymentDetail = [];
          vm.loading.payment = false;
          vm.paymentDetail = _.get(response, 'data.data', {});
          vm.emptyPaymentDetail = _.isEmpty(vm.paymentDetail) ? true : false;

          angular.copy(vm.paymentDetail, vm.data.paymentDetail);

          vm.accountType = {
            creditCard:
              vm.paymentDetail.type === APP_CONSTANTS.PAYMENT_METHOD.CARD
                ? true
                : false,
            bankTransfer:
              vm.paymentDetail.type ===
              APP_CONSTANTS.PAYMENT_METHOD.DIRECT_DEBIT
                ? true
                : false,
            purchaseOrder:
              vm.paymentDetail.type ===
              APP_CONSTANTS.PAYMENT_METHOD.PURCHASE_ORDER
                ? true
                : false,
            paypal:
              vm.paymentDetail.type ===
              APP_CONSTANTS.PAYMENT_METHOD.PAYPAL_EXPRESS_CHECKOUT
                ? true
                : false
          };

          if (vm.accountType.bankTransfer)
            vm.verificationRequired =
              vm.paymentDetail.status === 'pending_verification' ? true : false;
        })
        .catch(function(error) {
          vm.loading.payment = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }
  }
})();
