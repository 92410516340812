(function() {
  'use strict';

  angular
    .module('app.shared')
    .factory('MappedSchoolsService', MappedSchoolsService);

  MappedSchoolsService.$inject = [
    'APP_CONSTANTS',
    'ApiCaller',
    '$filter',
    '$q'
  ];

  function MappedSchoolsService(APP_CONSTANTS, ApiCaller, $filter, $q) {
    var self = this;

    self.fetchWorkgroupSchools = fetchWorkgroupSchools;
    self.fetchWorkgroupMappedSchoolsById = fetchWorkgroupMappedSchoolsById;

    function fetchWorkgroupSchools(workgroupId, name) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.workgroupSchools,
        APP_CONSTANTS.rootUrl,
        workgroupId,
        name || ''
      );

      ApiCaller.apiGet(url)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function fetchWorkgroupMappedSchoolsById(
      workgroupId,
      majorId,
      schoolIdsArray
    ) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.workgroupMappedSchools,
        APP_CONSTANTS.rootUrl,
        workgroupId,
        majorId
      );

      ApiCaller.apiPost(url, { schoolIds: schoolIdsArray })
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    return self;
  }
})();
