(function() {
  'use strict';
  angular.module('app.notification').config(routesConfig);

  routesConfig.$inject = [
    '$stateProvider',
    '$locationProvider',
    '$urlRouterProvider',
    'RouteHelpersProvider'
  ];

  function routesConfig(
    $stateProvider,
    $locationProvider,
    $urlRouterProvider,
    helper
  ) {
    // Set the following to true to enable the HTML5 Mode
    // You may have to set <base> tag in index and a routing configuration in your server
    $locationProvider.html5Mode(false);

    // Mailbox
    // -----------------------------------
    $stateProvider
      .state('app.notification', {
        url: '/notification',
        title: 'NotificationBox',
        templateUrl: helper.basepath('notification/views/notificationBox.html'),
        controller: 'NotificationBoxController',
        secured: true,
        controllerAs: 'vm'
      })
      .state('app.notification.folder', {
        url: '/folder/:folder',
        title: 'NotificationList',
        templateUrl: helper.basepath(
          'notification/views/notificationList.html'
        ),
        controller: 'NotificationListController',
        controllerAs: 'vm',
        secured: true,
        resolve: helper.resolveFor('modernizr', 'icons', 'datatables')
      })
      .state('app.notification.view', {
        url: '/notification/:notificationId',
        title: 'View Notification',
        templateUrl: helper.basepath(
          'notification/views/notificationViewDetail.html'
        ),
        controller: 'NotificationViewController',
        controllerAs: 'vm',
        secured: true,
        resolve: helper.resolveFor('ngWig')
      })
      .state('app.notification.productUptime', {
        url: '/productUptime',
        title: 'ProductUptime',
        templateUrl: helper.basepath('notification/views/productUptime.html'),
        controller: 'ProductUptimeController',
        controllerAs: 'vm',
        secured: true,
        params: {
          discovererEmail: null
        },
        resolve: helper.resolveFor('ngWig', 'ui.select')
      })
      .state('app.notification.productUsage', {
        url: '/productUsage',
        title: 'ProductUsage',
        templateUrl: helper.basepath('notification/views/productUsage.html'),
        controller: 'ProductUsageController',
        controllerAs: 'vm',
        secured: true,
        params: {
          discovererEmail: null
        },
        resolve: helper.resolveFor('ngWig', 'ui.select')
      })
      .state('app.notification.productActivation', {
        url: '/productActivation',
        title: 'ProductActivation',
        templateUrl: helper.basepath(
          'notification/views/productActivation.html'
        ),
        controller: 'ProductActivationController',
        controllerAs: 'vm',
        secured: true,
        params: {
          discovererEmail: null
        },
        resolve: helper.resolveFor('ngWig', 'ui.select')
      });
  }
})();
