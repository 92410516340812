(function() {
  'use strict';
  angular
    .module('app.user')
    .factory('SubscriptionPlansService', SubscriptionPlansService);

  SubscriptionPlansService.$inject = ['localStorageService'];

  function SubscriptionPlansService(localStorageService) {
    var self = this;

    self.storePlan = storePlan;
    self.destroyPlan = destroyPlan;
    self.retrievePlan = retrievePlan;

    function storePlan(data) {
      localStorageService.set('plan', data);
    }

    function retrievePlan() {
      return localStorageService.get('plan');
    }

    function destroyPlan() {
      localStorageService.remove('plan');
    }

    return self;
  }
})();
