(function() {
  'use strict';
  angular.module('app.account').config(routesConfig);

  routesConfig.$inject = ['$stateProvider', 'RouteHelpersProvider'];

  function routesConfig($stateProvider, helper) {
    $stateProvider
      .state('app.account', {
        url: '/account',
        abstract: true,
        templateUrl: helper.basepath('account/views/account.html'),
        resolve: helper.resolveFor('modernizr', 'icons'),
        secured: true
      })
      .state('app.account.subscription', {
        url: '/subscription',
        title: 'Subscription',
        templateUrl: helper.basepath(
          'account/views/partials/subscription.html'
        ),
        resolve: helper.resolveFor('modernizr', 'icons', 'datatables'),
        secured: true
      })
      .state('app.account.billing', {
        url: '/billing',
        title: 'Billing',
        templateUrl: helper.basepath('account/views/partials/billing.html'),
        resolve: helper.resolveFor('modernizr', 'icons', 'datatables'),
        secured: true
      })
      .state('app.account.technical', {
        url: '/technical',
        title: 'Technical',
        templateUrl: helper.basepath('account/views/partials/technical.html'),
        resolve: helper.resolveFor('modernizr', 'icons'),
        secured: true
      });
  }
})();
