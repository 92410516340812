/* eslint no-undef: "off"*/
/**=========================================================
 * Module: Common
 * Services to check user access role
 =========================================================
 */
(function() {
  'use strict';

  angular
    .module('careerKeyCentral')
    .service('AccessRoleService', AccessRoleService);

  AccessRoleService.$inject = ['SessionService', 'APP_CONSTANTS'];

  function AccessRoleService(SessionService, APP_CONSTANTS) {
    var accessLevelRoles = {
      'app.inviteuser': [
        APP_CONSTANTS.OWNER,
        APP_CONSTANTS.ACCOUNT_MANAGER,
        APP_CONSTANTS.ORGANIZATION_MANAGER
      ],
      'app.ownerdashboard': [
        APP_CONSTANTS.OWNER,
        APP_CONSTANTS.ACCOUNT_MANAGER,
        APP_CONSTANTS.ORGANIZATION_MANAGER
      ],
      'app.notification': [APP_CONSTANTS.CKADMIN],
      'app.notification.view': [APP_CONSTANTS.CKADMIN],

      'app.ckadmin': [APP_CONSTANTS.CKADMIN],
      'app.ckadmin.products': [APP_CONSTANTS.CKADMIN],
      'app.ckadmin.customers': [APP_CONSTANTS.CKADMIN],
      'app.ckadmin.customerDetails': [APP_CONSTANTS.CKADMIN],
      'app.ckadmin.resellers': [APP_CONSTANTS.CKADMIN],
      'app.ckadmin.resellerDetails': [APP_CONSTANTS.CKADMIN],
      'app.ckadmin.assessments': [APP_CONSTANTS.CKADMIN],
      'app.ckadmin.discoverers': [APP_CONSTANTS.CKADMIN],
      'app.ckadmin.selectedProduct': [APP_CONSTANTS.CKADMIN],
      'app.ckadmin.selectedProduct.details': [APP_CONSTANTS.CKADMIN],
      'app.ckadmin.selectedProduct.ips': [APP_CONSTANTS.CKADMIN],
      'app.ckadmin.selectedProduct.occupations': [APP_CONSTANTS.CKADMIN],
      'app.ckadmin.selectedProduct.schools': [APP_CONSTANTS.CKADMIN],
      'app.ckadmin.selectedProduct.batches': [APP_CONSTANTS.CKADMIN],

      'app.billingdashboard': [APP_CONSTANTS.BILLING],
      'app.technicaldashboard': [APP_CONSTANTS.TECHNICAL],
      'app.counselordashboard': [APP_CONSTANTS.COUNSELOR, APP_CONSTANTS.GROUP_COUNSELOR, APP_CONSTANTS.TECHNICAL],
      'app.notification.folder': [APP_CONSTANTS.CKADMIN],
      'app.stakeholderdashboard': [APP_CONSTANTS.STAKEHOLDER],
      'app.notification.productUptime': [APP_CONSTANTS.CKADMIN],
      'app.notification.productUsage': [APP_CONSTANTS.CKADMIN],
      'app.notification.productActivation': [APP_CONSTANTS.CKADMIN],
      'app.customerAcc': [APP_CONSTANTS.ACCOUNT_MANAGER],
      'app.customerAcc.accountInfo': [APP_CONSTANTS.ACCOUNT_MANAGER],
      'app.customerAcc.billing': [APP_CONSTANTS.ACCOUNT_MANAGER],
      'app.customerAcc.billingHistory': [APP_CONSTANTS.ACCOUNT_MANAGER],
      'app.customerAcc.subscription': [APP_CONSTANTS.ACCOUNT_MANAGER],
      'app.customerAcc.organizations': [APP_CONSTANTS.ACCOUNT_MANAGER],
      'app.sponsoredOrg': [
        APP_CONSTANTS.ACCOUNT_MANAGER,
        APP_CONSTANTS.COUNSELOR,
        APP_CONSTANTS.GROUP_COUNSELOR,
        APP_CONSTANTS.ORGANIZATION_MANAGER,
        APP_CONSTANTS.TECHNICAL
      ],
      'app.sponsoredOrg.OrgInfo': [
        APP_CONSTANTS.ACCOUNT_MANAGER,
        APP_CONSTANTS.COUNSELOR,
        APP_CONSTANTS.GROUP_COUNSELOR,
        APP_CONSTANTS.ORGANIZATION_MANAGER,
        APP_CONSTANTS.TECHNICAL
      ],
      'app.sponsoredOrg.technicalDashboard': [
        APP_CONSTANTS.ACCOUNT_MANAGER,
        APP_CONSTANTS.COUNSELOR,
        APP_CONSTANTS.GROUP_COUNSELOR,
        APP_CONSTANTS.ORGANIZATION_MANAGER,
        APP_CONSTANTS.TECHNICAL
      ],
      'app.sponsoredOrg.userManagement': [
        APP_CONSTANTS.ACCOUNT_MANAGER,
        APP_CONSTANTS.COUNSELOR,
        APP_CONSTANTS.GROUP_COUNSELOR,
        APP_CONSTANTS.ORGANIZATION_MANAGER,
        APP_CONSTANTS.TECHNICAL
      ],
      'app.orgprofile': [
        APP_CONSTANTS.OWNER,
        APP_CONSTANTS.ACCOUNT_MANAGER,
        APP_CONSTANTS.ORGANIZATION_MANAGER,
        APP_CONSTANTS.STAKEHOLDER,
        APP_CONSTANTS.TECHNICAL
      ],
      'app.createmanagegroup': [
        APP_CONSTANTS.OWNER,
        APP_CONSTANTS.ACCOUNT_MANAGER,
        APP_CONSTANTS.ORGANIZATION_MANAGER,
        APP_CONSTANTS.COUNSELOR,
        APP_CONSTANTS.GROUP_COUNSELOR,
        APP_CONSTANTS.TECHNICAL
      ],
      'app.subscription': [
        APP_CONSTANTS.CKADMIN,
        APP_CONSTANTS.OWNER,
        APP_CONSTANTS.ACCOUNT_MANAGER,
        APP_CONSTANTS.ORGANIZATION_MANAGER,
        APP_CONSTANTS.BILLING
      ],
      'app.mailbox': [
        APP_CONSTANTS.OWNER,
        APP_CONSTANTS.ACCOUNT_MANAGER,
        APP_CONSTANTS.ORGANIZATION_MANAGER,
        APP_CONSTANTS.COUNSELOR,
        APP_CONSTANTS.GROUP_COUNSELOR,
        APP_CONSTANTS.STAKEHOLDER,
        APP_CONSTANTS.TECHNICAL
      ],
      'app.acsubscription': [
        APP_CONSTANTS.CKADMIN,
        APP_CONSTANTS.OWNER,
        APP_CONSTANTS.ACCOUNT_MANAGER,
        APP_CONSTANTS.ORGANIZATION_MANAGER,
        APP_CONSTANTS.BILLING
      ],
      'app.mailbox.view': [
        APP_CONSTANTS.OWNER,
        APP_CONSTANTS.ACCOUNT_MANAGER,
        APP_CONSTANTS.ORGANIZATION_MANAGER,
        APP_CONSTANTS.COUNSELOR,
        APP_CONSTANTS.GROUP_COUNSELOR,
        APP_CONSTANTS.STAKEHOLDER,
        APP_CONSTANTS.TECHNICAL
      ],
      'app.mailbox.folder': [
        APP_CONSTANTS.OWNER,
        APP_CONSTANTS.ACCOUNT_MANAGER,
        APP_CONSTANTS.ORGANIZATION_MANAGER,
        APP_CONSTANTS.COUNSELOR,
        APP_CONSTANTS.GROUP_COUNSELOR,
        APP_CONSTANTS.STAKEHOLDER,
        APP_CONSTANTS.TECHNICAL
      ],
      'app.mailbox.compose': [
        APP_CONSTANTS.OWNER,
        APP_CONSTANTS.ACCOUNT_MANAGER,
        APP_CONSTANTS.ORGANIZATION_MANAGER,
        APP_CONSTANTS.COUNSELOR,
        APP_CONSTANTS.GROUP_COUNSELOR,
        APP_CONSTANTS.STAKEHOLDER,
        APP_CONSTANTS.TECHNICAL
      ],
      'app.discovererdetails': [
        APP_CONSTANTS.OWNER,
        APP_CONSTANTS.ACCOUNT_MANAGER,
        APP_CONSTANTS.ORGANIZATION_MANAGER,
        APP_CONSTANTS.COUNSELOR,
        APP_CONSTANTS.GROUP_COUNSELOR,
        APP_CONSTANTS.STAKEHOLDER,
        APP_CONSTANTS.TECHNICAL
      ],
      'app.pathadvisor': [
        APP_CONSTANTS.OWNER,
        APP_CONSTANTS.ACCOUNT_MANAGER,
        APP_CONSTANTS.ORGANIZATION_MANAGER,
        APP_CONSTANTS.COUNSELOR,
        APP_CONSTANTS.GROUP_COUNSELOR,
        APP_CONSTANTS.STAKEHOLDER,
        APP_CONSTANTS.TECHNICAL
      ],
      'app.discoverersprogress': [
        APP_CONSTANTS.OWNER,
        APP_CONSTANTS.ACCOUNT_MANAGER,
        APP_CONSTANTS.ORGANIZATION_MANAGER,
        APP_CONSTANTS.COUNSELOR,
        APP_CONSTANTS.GROUP_COUNSELOR,
        APP_CONSTANTS.STAKEHOLDER,
        APP_CONSTANTS.TECHNICAL
      ],
      'app.careerdecisionprofileclient': [
        APP_CONSTANTS.OWNER,
        APP_CONSTANTS.ACCOUNT_MANAGER,
        APP_CONSTANTS.ORGANIZATION_MANAGER,
        APP_CONSTANTS.COUNSELOR,
        APP_CONSTANTS.GROUP_COUNSELOR,
        APP_CONSTANTS.STAKEHOLDER,
        APP_CONSTANTS.TECHNICAL
      ],
      'app.careerdecisionprofileclientdetails': [
        APP_CONSTANTS.OWNER,
        APP_CONSTANTS.ACCOUNT_MANAGER,
        APP_CONSTANTS.ORGANIZATION_MANAGER,
        APP_CONSTANTS.COUNSELOR,
        APP_CONSTANTS.GROUP_COUNSELOR,
        APP_CONSTANTS.STAKEHOLDER,
        APP_CONSTANTS.TECHNICAL
      ],
      'app.contactus': [
        APP_CONSTANTS.CKADMIN,
        APP_CONSTANTS.OWNER,
        APP_CONSTANTS.ACCOUNT_MANAGER,
        APP_CONSTANTS.ORGANIZATION_MANAGER,
        APP_CONSTANTS.COUNSELOR,
        APP_CONSTANTS.GROUP_COUNSELOR,
        APP_CONSTANTS.STAKEHOLDER,
        APP_CONSTANTS.TECHNICAL,
        APP_CONSTANTS.BILLING
      ],
      'app.userprofile': [
        APP_CONSTANTS.CKADMIN,
        APP_CONSTANTS.OWNER,
        APP_CONSTANTS.ACCOUNT_MANAGER,
        APP_CONSTANTS.ORGANIZATION_MANAGER,
        APP_CONSTANTS.COUNSELOR,
        APP_CONSTANTS.GROUP_COUNSELOR,
        APP_CONSTANTS.STAKEHOLDER,
        APP_CONSTANTS.TECHNICAL,
        APP_CONSTANTS.BILLING
      ],
      'app.userchangepassword': [
        APP_CONSTANTS.CKADMIN,
        APP_CONSTANTS.OWNER,
        APP_CONSTANTS.ACCOUNT_MANAGER,
        APP_CONSTANTS.ORGANIZATION_MANAGER,
        APP_CONSTANTS.COUNSELOR,
        APP_CONSTANTS.GROUP_COUNSELOR,
        APP_CONSTANTS.STAKEHOLDER,
        APP_CONSTANTS.TECHNICAL,
        APP_CONSTANTS.BILLING
      ],
      'app.orgprofile.setting': [
        APP_CONSTANTS.CKADMIN,
        APP_CONSTANTS.OWNER,
        APP_CONSTANTS.ACCOUNT_MANAGER,
        APP_CONSTANTS.ORGANIZATION_MANAGER,
        APP_CONSTANTS.COUNSELOR,
        APP_CONSTANTS.GROUP_COUNSELOR,
        APP_CONSTANTS.STAKEHOLDER,
        APP_CONSTANTS.TECHNICAL,
        APP_CONSTANTS.BILLING
      ],
      'app.orgprofile.user_management': [
        APP_CONSTANTS.CKADMIN,
        APP_CONSTANTS.OWNER,
        APP_CONSTANTS.ACCOUNT_MANAGER,
        APP_CONSTANTS.ORGANIZATION_MANAGER,
        APP_CONSTANTS.COUNSELOR,
        APP_CONSTANTS.GROUP_COUNSELOR,
        APP_CONSTANTS.STAKEHOLDER,
        APP_CONSTANTS.TECHNICAL,
        APP_CONSTANTS.BILLING
      ],
      'app.mailbox.cdp': [
        APP_CONSTANTS.OWNER,
        APP_CONSTANTS.ACCOUNT_MANAGER,
        APP_CONSTANTS.ORGANIZATION_MANAGER,
        APP_CONSTANTS.COUNSELOR,
        APP_CONSTANTS.GROUP_COUNSELOR,
        APP_CONSTANTS.STAKEHOLDER,
        APP_CONSTANTS.TECHNICAL
      ]
    };

    var self = this;
    self.isAuthorized = isAuthorized;
    self.getHighPriorityRole = getHighPriorityRole;

    function isAuthorized(state) {
      var permission = [];
      permission = SessionService.getRoles().filter(function(role) {
        return accessLevelRoles[state].indexOf(role) > -1;
      });
      if (!permission.length) {
      }
      return permission.length > 0 ? true : false;
    }

    function getHighPriorityRole() {
      var userRole = APP_CONSTANTS.roleList
        .filter(function(item) {
          return SessionService.getRoles().indexOf(item.role) > -1;
        })
        .reduce(function(prev, curr) {
          return prev.priority < curr.priority ? prev : curr;
        }, 0);
      return userRole;
    }
  }
})();
