(function () {
  'use strict';

  angular
    .module('app.shared')
    .controller('ResellerDetailsController', ResellerDetailsController);

  ResellerDetailsController.$inject = [
    '$uibModal',
    'APP_CONSTANTS',
    'toaster',
    'AdminResellerSubscriptionService',
    'AdminResellerBillingService',
    'ResellerService',
    '$rootScope',
    '$scope',
    '$stateParams'
  ];
  function ResellerDetailsController(
    $uibModal,
    APP_CONSTANTS,
    toaster,
    AdminResellerSubscriptionService,
    AdminResellerBillingService,
    ResellerService,
    $rootScope,
    $scope,
    $stateParams
  ) {
    var vm = this;

    vm.autopay = '';
    vm.loading = {};
    vm.accountType = {};
    vm.percentage = 0;
    vm.usedQuantity = 0;
    vm.totalQuantity = 0;
    vm.hidePO = false;
    vm.manageSubscriptions = false;
    vm.editPaymentMethodFlag = true;
    vm.selectedSubscriptionType = '';
    vm.subscriptionOptionFlag = false;
    vm.scheduledcancellation = APP_CONSTANTS.scheduledcancellation;
    vm.constants = {
      emptyPaymentDetail: APP_CONSTANTS.emptyPaymentDetail,
      emptyBillingDetail: APP_CONSTANTS.emptyBillingDetail,
      updateCustomLabel: APP_CONSTANTS.MESSAGE.updateCustomLabel
    };
    vm.data = {
      customerId: $stateParams.resellerId,
      hidePO: vm.hidePO,
      poNumber: '',
      accountInfo: {},
      billingDetail: {},
      paymentDetail: {}
    };

    vm.dtOptions = {
      order: [],
      columnDefs: [
        {
          orderable: false,
          targets: 'no-sort'
        }
      ]
    };

    activate();
    fetchBillingDetail();

    function activate() {
      $scope.$parent.vm.setActiveTab();
      $scope.$parent.vm.generateBreadCrumbs('reseller detail');

      var modalInstance = '';

      vm.addAddons = function (size) {
        if (!modalInstance) {
          modalInstance = $uibModal.open({
            templateUrl: 'app/v2/customerAccount/views/partials/addAddonModal.html',
            controller: 'AddonsController',
            controllerAs: 'vm',
            windowClass: 'billing-modal',
            size: size,
            resolve: {
              data: function () {
                return vm.data;
              }
            }
          });

          modalInstance.result
            .then(function () {
              vm.selectedSubscription = {};
              modalInstance = '';
              getSubscription();
            })
            .catch(function () {
              modalInstance = '';
            });
        }
      };

      vm.addSubscriptionModal = function (size) {
        if (!modalInstance) {
          modalInstance = $uibModal.open({
            templateUrl: 'app/ckadmin/resellers/views/adminAddResellerSubscriptionModal.html',
            controller: 'AdminAddResellerSubscriptionModalController',
            controllerAs: 'vm',
            windowClass: 'add-subscription-modal',
            size: size,
            resolve: {
              data: function () {
                return vm.data;
              }
            }
          });

          modalInstance.result
            .then(function () {
              vm.selectedSubscription = {};
              getSubscription();
            })
            .finally(function () {
              modalInstance = '';
            });
        }
      };

      vm.nextPlan = function (size) {
        if (!modalInstance) {
          modalInstance = $uibModal.open({
            templateUrl: '/addNextPlanModal.html',
            controller: 'AddNextPlanController',
            controllerAs: 'vm',
            windowClass: 'add-next-plan-modal',
            size: size,
            resolve: {
              data: function () {
                return vm.data;
              }
            }
          });

          modalInstance.result
            .then(function () {
              vm.selectedSubscription = {};
              getSubscription();
              modalInstance = '';
            })
            .catch(function () {
              modalInstance = '';
            });
        }
      };

      vm.displayAddons = function (subscription) {
        if (!modalInstance) {
          modalInstance = $uibModal.open({
            templateUrl:
              'app/v2/customerAccount/views/partials/displayAddons.html',
            controller: 'DisplayAddonsController',
            controllerAs: 'vm',
            resolve: {
              subscription: function () {
                return subscription;
              }
            }
          });

          modalInstance.result
            .then(function () {
              modalInstance = '';
            })
            .catch(function () {
              modalInstance = '';
            });
        }
      };

      vm.cancelSubscriptionModal = function (size) {
        if (!modalInstance) {
          modalInstance = $uibModal.open({
            templateUrl: 'app/ckadmin/resellers/views/adminCancelResellerSubscriptionModal.html',
            controller: 'AdminCancelResellerSubscriptionModalController',
            controllerAs: 'vm',
            size: size,
            resolve: {
              data: function () {
                return vm.data;
              }
            }
          });

          modalInstance.result
            .then(function () {
              vm.selectedSubscription = {};
              getSubscription();
            })
            .finally(function () {
              modalInstance = '';
            });
        }
      };

      vm.editAccountInformationModal = function (size) {
        if (!modalInstance) {
          modalInstance = $uibModal.open({
            templateUrl: 'app/ckadmin/resellers/views/adminEditResellerAccountInformationModal.html',
            controller: 'AdminEditResellerAccountInformationModalController',
            controllerAs: 'vm',
            size: size,
            resolve: {
              data: function () {
                return vm.data;
              }
            }
          });

          modalInstance.result
            .then(function () {
              getAccountInfo();
            })
            .finally(function () {
              modalInstance = '';
            });
        }
      };

      vm.editBillingInformationModal = function (size) {
        if (!modalInstance) {
          modalInstance = $uibModal.open({
            templateUrl: 'app/ckadmin/resellers/views/adminEditResellerBillingInformationModal.html',
            controller: 'AdminEditResellerBillingInformationModalController',
            controllerAs: 'bc',
            size: size,
            resolve: {
              data: function () {
                return vm.data;
              }
            }
          });

          modalInstance.result
            .then(function () {
              getBillingDetail();
            })
            .finally(function () {
              modalInstance = '';
            });
        }
      };

      vm.verifyAcc = function (size) {
        if (!modalInstance) {
          modalInstance = $uibModal.open({
            templateUrl: '/verifyAccountModal.html',
            controller: 'VerifyAccountController',
            controllerAs: 'vm',
            size: size
          });

          modalInstance.result
            .then(function () {
              modalInstance = '';
            })
            .catch(function () {
              modalInstance = '';
            });
        }
      };

      vm.editPayment = function (size) {
        if (!modalInstance) {
          modalInstance = $uibModal.open({
            templateUrl: 'app/ckadmin/resellers/views/adminEditResellerPaymentModal.html',
            controller: 'AdminEditResellerPaymentModalController',
            controllerAs: 'vm',
            size: size,
            resolve: {
              parentData: function () {
                return vm.data;
              }
            }
          });

          modalInstance.result
            .then(function () {
              getPaymentDetail();
            })
            .finally(function () {
              modalInstance = '';
            });
        }
      };

      vm.handleSubscriptionSelect = function (subscription) {
        vm.nextPlanFlag = false;
        vm.addAddonsFlag = false;
        vm.upgradeToPaidPlanFlag = false;
        vm.cancelSubscriptionFlag = false;
        vm.selectedSubscription = subscription;
        vm.selectedSubscriptionType = subscription.type;

        vm.data.billingDetail = vm.billingDetail;
        vm.data.paymentDetail = vm.paymentDetail;
        vm.data.subscription = vm.selectedSubscription;

        if (
          vm.selectedSubscription.type.toLowerCase() ===
          APP_CONSTANTS.SUBSCRIPTION_TYPE.PREPAID &&
          subscription.status.toLowerCase() !==
          APP_CONSTANTS.SUBSCRIPTION_STATUS.PENDING
        ) {
          vm.addAddonsFlag = true;
        } else if (
          vm.selectedSubscription.type.toLowerCase() ===
          APP_CONSTANTS.SUBSCRIPTION_TYPE.TRIAL
        ) {
          vm.upgradeToPaidPlanFlag = true;
        } else if (
          vm.selectedSubscription.type.toLowerCase() ===
          APP_CONSTANTS.SUBSCRIPTION_TYPE.AUTO_RENEWAL &&
          !(
            vm.selectedSubscription.status ===
            APP_CONSTANTS.SUBSCRIPTION_STATUS.CANCELLED ||
            vm.selectedSubscription.status ===
            APP_CONSTANTS.SUBSCRIPTION_STATUS.SCHEDULE_TO_CANCEL
          )
        ) {
          vm.nextPlanFlag = true;
          vm.cancelSubscriptionFlag = true;
        }

        if (
          vm.upgradeToPaidPlanFlag ||
          vm.cancelSubscriptionFlag ||
          vm.addAddonsFlag ||
          vm.selectedSubscriptionType.toLowerCase() ===
          APP_CONSTANTS.SUBSCRIPTION_TYPE.AUTORENEWAL
        ) {
          vm.subscriptionOptionFlag = true;
        } else {
          vm.subscriptionOptionFlag = false;
        }
      };

      vm.updateCustomLabelModal = function (subscription) {
        if (modalInstance) return;

        modalInstance = $uibModal.open({
          templateUrl: 'app/v2/customerAccount/views/partials/updateCustomLabelModal.html',
          controller: 'UpdateCustomLabelController',
          controllerAs: 'vm',
          resolve: {
            subscription: function () {
              return subscription;
            }
          }
        });

        modalInstance.result
          .then(function () {
            getSubscription();
          })
          .finally(function () {
            modalInstance = '';
          });
      }
    }

    function fetchBillingDetail() {
      getAccountInfo();
      getSubscription();
      getBillingDetail();
      getPaymentDetail();
    }

    function getAccountInfo() {
      vm.loading.accountInfo = true;

      AdminResellerBillingService.getAccountDetail(vm.data.customerId)
        .then(function (response) {
          vm.loading.accountInfo = false;
          vm.accountInfo = _.get(response, 'data.data', {});
          angular.copy(vm.accountInfo, vm.data.accountInfo);
        })
        .catch(function (error) {
          vm.loading.accountInfo = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function getSubscription() {
      vm.loading.subscription = true;
      AdminResellerSubscriptionService.fetchSubscription(vm.data.customerId)
        .then(function (response) {
          var data = _.get(response, 'data.data', {});

          vm.usedQuantity = _.get(data, 'usedQuantity', 0);
          vm.totalQuantity = _.get(data, 'totalQuantity', 0);
          vm.subscriptions = _.get(data, 'subscriptions', 0);
          vm.percentage =
            (vm.totalQuantity && (vm.usedQuantity * 100) / vm.totalQuantity) ||
            0;

          vm.subscriptions.filter(function (subscription) {

            if (
              subscription.type === APP_CONSTANTS.SUBSCRIPTION_TYPE.PREPAID ||
              subscription.type === APP_CONSTANTS.SUBSCRIPTION_TYPE.TRIAL
            ) {
              subscription.nextPlan.planName = '-';
            }
            if (
              subscription.status ===
              APP_CONSTANTS.SUBSCRIPTION_STATUS.SCHEDULE_TO_CANCEL ||
              subscription.status ===
              APP_CONSTANTS.SUBSCRIPTION_STATUS.CANCELLED
            ) {
              subscription.nextPlan.planName = '-';
            }

            if (
              subscription.periodUnit ===
              APP_CONSTANTS.AUTO_RENEWAL_TYPE.MONTHLY &&
              subscription.type === APP_CONSTANTS.SUBSCRIPTION_TYPE.AUTO_RENEWAL
            ) {
              subscription.endDate = $rootScope
                .moment(subscription.startDate)
                .add(1, 'years')
                .format();
            }

            subscription.actualStatus = subscription.status;

            if (
              subscription.periodUnit ===
              APP_CONSTANTS.AUTO_RENEWAL_TYPE.MONTHLY
            )
              vm.hidePO = true;

          });
          vm.loading.subscription = false;
        })
        .catch(function (error) {
          vm.loading.subscription = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function getBillingDetail() {
      vm.loading.billingDetail = true;
      AdminResellerBillingService.billingDetail(vm.data.customerId)
        .then(function (response) {
          vm.loading.billingDetail = false;
          vm.billingDetail = _.get(response, 'data.data', {});
          vm.emptyBillingDetail = _.isEmpty(vm.billingDetail) ? true : false;
          vm.autoCollection = vm.billingDetail.autoCollection;
          angular.copy(vm.billingDetail, vm.data.billingDetail);
        })
        .catch(function (error) {
          vm.loading.billingDetail = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function getPaymentDetail() {
      vm.loading.payment = true;
      AdminResellerBillingService.paymentDetail(vm.data.customerId)
        .then(function (response) {
          vm.paymentDetail = [];
          vm.loading.payment = false;
          vm.paymentDetail = _.get(response, 'data.data', {});
          vm.emptyPaymentDetail = _.isEmpty(vm.paymentDetail) ? true : false;

          angular.copy(vm.paymentDetail, vm.data.paymentDetail);

          vm.accountType = {
            creditCard: vm.paymentDetail.type === APP_CONSTANTS.PAYMENT_METHOD.CARD,
            bankTransfer: vm.paymentDetail.type === APP_CONSTANTS.PAYMENT_METHOD.DIRECT_DEBIT,
            purchaseOrder: vm.paymentDetail.type === APP_CONSTANTS.PAYMENT_METHOD.PURCHASE_ORDER,
            paypal: vm.paymentDetail.type === APP_CONSTANTS.PAYMENT_METHOD.PAYPAL_EXPRESS_CHECKOUT
          };

          if (vm.accountType.bankTransfer)
            vm.verificationRequired =
              vm.paymentDetail.status === 'pending_verification' ? true : false;
        })
        .catch(function (error) {
          vm.loading.payment = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    vm.loginAsReseller = () => {
      vm.loading.login = true;

      ResellerService.loginAsReseller(vm.data.customerId)
        .catch(error => {
          vm.loading.login = false;
          toaster.pop({
            type: 'error',
            body: error.data.message || error.statusText,
          });
        });
    }
  }
})();
