(function() {
  'use strict';

  angular.module('app.shared').factory('CareersService', CareersService);

  CareersService.$inject = ['APP_CONSTANTS', 'ApiCaller', '$filter', '$q'];

  function CareersService(APP_CONSTANTS, ApiCaller, $filter, $q) {
    var self = this;

    self.saveDraft = saveDraft;
    self.revertCareer = revertCareer;
    self.publishCareer = publishCareer;
    self.addToUnpublished = addToUnpublished;
    self.searchWorkgroupCareers = searchWorkgroupCareers;
    self.fetchCareerPublishStatus = fetchCareerPublishStatus;
    self.fetchCareersByWorkgroupId = fetchCareersByWorkgroupId;

    function fetchCareersByWorkgroupId(
      productId,
      workgroupId,
      isPublished,
      searchKey,
      batchId,
      tagId,
      regionId
    ) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        isPublished
          ? APP_CONSTANTS.workgroupCareers
          : APP_CONSTANTS.workgroupUnpublishedCareers,
        APP_CONSTANTS.rootUrl,
        productId,
        workgroupId,
        searchKey || '',
        batchId,
        tagId,
        regionId
      );

      ApiCaller.apiGet(url)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function addToUnpublished(workgroupId, careerId, productId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.addWorkgroupUnpublishedCareers,
        APP_CONSTANTS.rootUrl,
        workgroupId,
        careerId
      );

      ApiCaller.apiPost(url, { productId: productId })
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function revertCareer(workgroupId, careerId, productId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.revertUnpublishedCareers,
        APP_CONSTANTS.rootUrl,
        workgroupId,
        careerId
      );

      ApiCaller.apiPost(url, { productId: productId })
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function saveDraft(updatedCareer, workgroupId, careerId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.saveCareerDraft,
        APP_CONSTANTS.rootUrl,
        workgroupId,
        careerId
      );

      ApiCaller.apiPut(url, updatedCareer)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function publishCareer(workgroupId, careerId, productId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.publishCareer,
        APP_CONSTANTS.rootUrl,
        workgroupId,
        careerId
      );

      ApiCaller.apiPut(url, { productId: productId })
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function searchWorkgroupCareers(productId, params) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.searchProductWorkgroups,
        APP_CONSTANTS.rootUrl,
        productId,
        $.param(params)
      );

      ApiCaller.apiGet(url)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function fetchCareerPublishStatus(workgroupId, careerId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.careerPublishStatus,
        APP_CONSTANTS.rootUrl,
        workgroupId,
        careerId
      );

      ApiCaller.apiGet(url)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    return self;
  }
})();
