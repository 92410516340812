/** =========================================================
 * Module: Discoverers & Progress
 * Angular controller
 =========================================================*/

/* eslint-disable */
(function () {
  'use strict';

  angular
    .module('app.careerDecisionProfileClient')
    .controller('CDPClientController', CDPClientController);

  CDPClientController.$inject = [
    'CDPClientService',
    'APP_CONSTANTS',
    '$rootScope',
    'toaster',
    '$stateParams',
    'SessionService',
    'GroupModal',
    'GroupService',
    '$state',
    '$scope',
    '$uibModal',
    '$timeout',
    'AccessRoleService',
    'ngDialog'
  ];

  function CDPClientController(
    CDPClientService,
    APP_CONSTANTS,
    $rootScope,
    toaster,
    $stateParams,
    SessionService,
    GroupModal,
    GroupService,
    $state,
    $scope,
    $uibModal,
    $timeout,
    AccessRoleService,
    ngDialog
  ) {
    var vm = this;

    vm.loading = {};

    vm.dateRange = vm.startDate + ' - ' + vm.endDate;

    vm.discoverers = [];
    vm.pageNum = 1;
    vm.pageSize = 10;
    vm.searchKey = '';
    vm.groupId = '';
    vm.totalRecords = 0;
    vm.selectedDiscoverers = [];
    vm.selectAllFlag = false;
    vm.loadedNewData = false;
    vm.categorySelected = false;
    vm.allCheck = allCheck;
    vm.resetDate = resetDate;
    vm.resetScale = resetScale;
    vm.exportAllToCSV = exportAllToCSV;
    vm.selectDiscoverer = selectDiscoverer;
    vm.exportSelectedToCSV = exportSelectedToCSV;
    vm.pageSizeOptions = APP_CONSTANTS.pageSizeOptions;
    vm.selectedPageSize = {
      key: "'" + vm.pageSize + "'",
      value: vm.pageSize
    };
    vm.isDateOpen = false;
    vm.isScaleOpen = false;
    vm.endDatePickerIsOpen = false;
    vm.startDatePickerIsOpen = false;
    vm.refreshTimeStamp = Date.now();
    vm.serviceLevels = APP_CONSTANTS.SERVICE_LEVEL;
    vm.progressLevels = APP_CONSTANTS.PROGRESS_LEVEL;
    vm.scaleLevels = APP_CONSTANTS.SCALE_LEVEL;
    vm.scoreLevels = APP_CONSTANTS.SCORE_LEVEL;

    vm.toggleScoreHistory = toggleScoreHistory;
    vm.orderBy = orderBy;
    vm.sendMessage = sendMessage;
    vm.assignDiscoverersToGroup = assignDiscoverersToGroup;
    vm.discovererDisplayName = discovererDisplayName;

    vm.currentSort = {
      property: 'cdpAssessmentDate',
      order: 'desc'
    };

    vm.filter_selected = filter_selected;
    var userRole = AccessRoleService.getHighPriorityRole();

    // TODO Refactor checking the role of the user
    vm.role = '';

    if (userRole.priority === 5) {
      vm.role = 'groupCounselor';
    }

    vm.letsConnectOptions = _.filter(APP_CONSTANTS.letsConnectOptions, function (option) {
      return option.messageType === APP_CONSTANTS.CUSTOM ||
        option.subscriptionType === APP_CONSTANTS.CDP;
    });
    vm.showLetsConnectOptions = function () {
      let serviceLevel = vm.selectedServiceLevel && vm.selectedServiceLevel.key;
      let showAssessmentCompleted = !vm.selectedDiscoverers.length &&
        vm.discoverers.some(function (discoverer) {
          return discoverer.cdpAssessmentDate;
        }) ||
        vm.selectedDiscoverers.some(function (discoverer) {
          return discoverer.cdpAssessmentDate;
        });
      let showInviteToStart = vm.progressLevel === 0 ||
        vm.selectedDiscoverers.some(function (discoverer) {
          return !discoverer.cdpAssessmentDate;
        });
      let showRetakeCDP = vm.progressLevel === 1 ||
        vm.selectedDiscoverers.some(function (discoverer) {
          return (discoverer.cdpAssessmentDate
            && discoverer.cdpScoreHistory.length < APP_CONSTANTS.CDP_RETAKE_ASSESSMENT_LIMIT
          );
        });
      vm.letsConnectOptions.forEach(function (option) {
        option.show = option.messageType === APP_CONSTANTS.CUSTOM ||
          option.messageType === serviceLevel ||
          (showAssessmentCompleted &&
            (option.messageType === APP_CONSTANTS.SELF_HELP ||
              option.messageType === APP_CONSTANTS.BRIEF_STAFF_ASSISTED ||
              option.messageType === APP_CONSTANTS.INDIVIDUAL_CASE_MANAGED_SERVICES
            )
          ) ||
          showInviteToStart && option.messageType === APP_CONSTANTS.INVITE_TO_START ||
          showRetakeCDP && option.messageType === APP_CONSTANTS.RETAKE_CDP;
      });
    }

    // Make sure latest discoverers are fetched as soon as they are added from Groups.
    vm.endDate = new Date(moment(new Date()).add(1, 'days').format());

    vm.startDate = new Date(
      moment(new Date())
        .subtract(1, 'years')
        .format()
    );

    $scope.$watch('vm.discoverers', function (discoverers) {
      if (!discoverers || !discoverers.length) return;

      $timeout(function () {
        angular.element('.ckd-datatable	tr').each(function (i, tr) {
          let height = 0;
          let cols = angular.element(tr).children('td:visible, th:visible');
          cols.each(function (j, td) {
            let colHeight = angular.element(td).height();
            if (height < colHeight) height = colHeight;
          });
          cols.height(height);
        });
      }, 10);
    }, true);

    let loadingWatcher = $scope.$watchGroup(['vm.loading.discoverers', 'vm.loading.groups'], function (newValues) {
      if (!newValues.every(val => val === false)) return;
      $scope.startTour();
      loadingWatcher();
    });

    fetchGroups();
    setDateRange();


    init();

    function init() {
      $timeout(function () {

        if ($rootScope.cdpFilters.group) {
          vm.group = $rootScope.cdpFilters.group;
          vm.groupId = $rootScope.cdpFilters.group.id;
        }

        if ($rootScope.cdpFilters.selectedServiceLevel) {
          vm.selectedServiceLevel = $rootScope.cdpFilters.selectedServiceLevel;
          vm.serviceLevel = $rootScope.cdpFilters.selectedServiceLevel.value;
        }

        if ($rootScope.cdpFilters.selectedProgressLevel) {
          vm.selectedProgressLevel = $rootScope.cdpFilters.selectedProgressLevel;
          vm.progressLevel = $rootScope.cdpFilters.selectedProgressLevel.key;
        }

        if ($rootScope.cdpFilters.startDate && $rootScope.cdpFilters.endDate) {
          vm.startDate = $rootScope.cdpFilters.startDate;
          vm.endDate = $rootScope.cdpFilters.endDate;

          setDateRange();
        }

        if ($rootScope.cdpFilters.selectedScale && $rootScope.cdpFilters.selectedScore) {
          vm.selectedScale = $rootScope.cdpFilters.selectedScale;
          vm.selectedScore = $rootScope.cdpFilters.selectedScore;

          setScaleAndScore();
        }

        vm.searchKey = $rootScope.cdpFilters.searchKey;

        if ($stateParams.parameter) {
          if ($stateParams.parameter.groupId) {
            vm.groupId = $stateParams.parameter.groupId;
          }
          if ($stateParams.parameter.serviceLevelKey) {
            vm.selectedServiceLevel = _.find(APP_CONSTANTS.SERVICE_LEVEL, {
              key: $stateParams.parameter.serviceLevelKey
            });
            vm.serviceLevel = vm.selectedServiceLevel && vm.selectedServiceLevel.value;
          }
        }

        fetchCDPDiscoverers();
      });
    }


    function resetDate() {
      vm.endDate = '';
      vm.startDate = '';
      filter_selected();
    }

    function resetScale() {
      vm.selectedScale = null;
      vm.selectedScore = null;
      vm.selectedScaleLabel && filter_selected();
    }

    function selectDiscoverer() {
      var shouldSelectAllFlag = false;
      if (vm.discoverers.length && vm.selectedDiscoverers.length >= vm.discoverers.length) {
        shouldSelectAllFlag = true;
        vm.discoverers.forEach(function (discoverer) {
          var hasSelectedDiscoverer = vm.selectedDiscoverers.some(function (selectedDiscoverer) {
            return selectedDiscoverer.id === discoverer.id;
          });
          if (!hasSelectedDiscoverer) {
            shouldSelectAllFlag = false;
          }
        });
      }
      vm.selectAllFlag = shouldSelectAllFlag;
    }

    function exportSelectedToCSV() {
      if (!vm.selectedDiscoverers.length) return;
      var discovererId = vm.selectedDiscoverers.map(function (discoverer) {
        return discoverer.id;
      });

      CDPClientService.exportToCSV({
        discovererId: discovererId,
        downloadAllFlag: false
      });
    }

    function exportAllToCSV(exportSelectedFlag) {
      exportSelectedFlag = exportSelectedFlag ? true : false;
      vm.groupId = angular.isUndefined(vm.group) ? '' : vm.group.id;
      vm.endDate = angular.isUndefined(vm.endDate) ? '' : vm.endDate;
      vm.searchKey = angular.isUndefined(vm.searchKey) ? '' : vm.searchKey;
      vm.startDate = angular.isUndefined(vm.startDate) ? '' : vm.startDate;
      vm.serviceLevel = angular.isUndefined(vm.serviceLevel)
        ? ''
        : vm.serviceLevel;

      if (!vm.startDate && !vm.endDate) {
        toaster.pop('error', 'Both start and end date should be selected');
        return;
      }

      if (angular.isUndefined(vm.progressLevel)) {
        vm.progressLevel = '';
      }

      var params = {
        groupId: vm.groupId,
        endDate: vm.endDate,
        startDate: vm.startDate,
        searchKey: vm.searchKey,
        serviceLevel: vm.serviceLevel,
        progressLevel: vm.progressLevel,
        scale: vm.scaleLevel || '',
        score: vm.scoreLevel || '',
      };

      var endDate = moment(vm.endDate);
      var todayDate = moment(new Date());
      var startDate = moment(vm.startDate);
      var differenceInStartAndEndDate = endDate.diff(startDate, 'days');
      var differenceInTodayAndStartDate = todayDate.diff(startDate, 'days');

      if (differenceInStartAndEndDate <= 0) {
        toaster.pop('error', APP_CONSTANTS.MESSAGE.startDateGreaterThanEndDate);
        return;
      }

      if (differenceInStartAndEndDate > 367) {
        toaster.pop('error', APP_CONSTANTS.MESSAGE.oneYearOnly);
        return;
      }

      if (differenceInTodayAndStartDate > 1095) {
        toaster.pop('error', APP_CONSTANTS.MESSAGE.notThreeYearBefore);
        return;
      }

      if (exportSelectedFlag && vm.totalRecords < 200) {
        CDPClientService.exportToCSV({
          params: params,
          downloadAllFlag: true
        });
      } else {
        CDPClientService.exportAllToCSV({ params: params })
          .then(function (response) {
            toaster.pop(
              'success',
              APP_CONSTANTS.MESSAGE.csvExportedThroughMail
            );
          })
          .catch(function (err) {
            toaster.pop('error', err.data.message || err.statusText);
          });
      }
    }

    function allCheck() {
      vm.selectAllFlag = !vm.selectAllFlag;

      vm.discoverers.forEach(function (discoverer) {
        var selectedDiscovererIndex = vm.selectedDiscoverers.findIndex(function (selectedDiscoverer) {
          return selectedDiscoverer.id === discoverer.id;
        });
        if (vm.selectAllFlag && selectedDiscovererIndex < 0) {
          vm.selectedDiscoverers.push(discoverer);
        }
        if (!vm.selectAllFlag && selectedDiscovererIndex > -1) {
          vm.selectedDiscoverers.splice(selectedDiscovererIndex, 1);
        }
      });
    }

    function fetchCDPDiscoverers() {
      vm.isDefaultFilter = (
        moment(vm.endDate).isSame(moment(new Date()).add(1, 'days'), 'day')
        && moment(vm.startDate).isSame(moment(new Date()).subtract(1, 'years'), 'day')
        && _.isEmpty(vm.group)
        && _.isEmpty(vm.selectedServiceLevel)
        && _.isEmpty(vm.selectedProgressLevel)
        && _.isEmpty(vm.selectedScale)
        && _.isEmpty(vm.selectedScore)
      );

      vm.loading.discoverers = true;

      var params = {
        groupId: vm.groupId,
        serviceLevel: vm.serviceLevel,
        progressLevel: vm.progressLevel,
        searchKey: vm.searchKey,
        pageSize: vm.pageSize,
        pageNum: vm.pageNum,
        sortBy: vm.currentSort.property,
        orderBy: vm.currentSort.order,
        fromDate: vm.startDate ? vm.startDate.toJSON() : '',
        toDate: vm.endDate ? vm.endDate.toJSON() : '',
        scale: vm.scaleLevel,
        score: vm.scoreLevel,
        type: APP_CONSTANTS.CDP
      };

      Object.keys(params).forEach(function (key) {
        if (params[key] === '' || params[key] === undefined) {
          delete params[key];
        }
      });

      CDPClientService.fetchCDPDiscoverers(params)
        .then(function (response) {
          vm.discoverers = _.get(response, 'data.data.discovers', [])
            .map(function (discoverer) {
              return {
                name: discoverer.name,
                firstName: discoverer.firstName,
                lastName: discoverer.lastName,
                email: discoverer.email,
                customerSourcedId: discoverer.customerSourcedId,
                id: discoverer.id,
                groupId: discoverer.groupId,
                lastContact: discoverer.lastContact,
                cdpAssessmentDate: discoverer.cdpAssessmentDate,
                cdpScore: getScales(discoverer.cdpScore),
                cdpScoreHistory: getScoreHistory(discoverer.cdpScoreHistory),
                showScoreHistory: false,
              }
            });

          vm.pageNum = response.data.data.pageNum
            ? response.data.data.pageNum
            : 1;
          vm.pageSize =
            response.data.data.pageSize == 'All'
              ? response.data.data.totalRecords
              : response.data.data.pageSize;
          vm.refreshTimeStamp = Date.now();

          vm.totalRecords = response.data.data.totalRecords;

          if (vm.serviceLevel) {
            var selectedDiv = angular.element(
              document.querySelector('#service-level-filter')
            );

            selectedDiv.find('span').addClass('ui-select-outline');
          }

          if (vm.groupId) {
            var selectedDiv = angular.element(
              document.querySelector('#group-filter')
            );

            selectedDiv.find('span').addClass('ui-select-outline');
          }

          vm.selectedDiscoverers = vm.selectedDiscoverers.map(function (selectedDiscoverer) {
            return vm.discoverers.find(function (discoverer) {
              return selectedDiscoverer.id === discoverer.id;
            }) || selectedDiscoverer;
          });

          selectDiscoverer();
          addDiscoverersGroupsName();
          vm.loading.discoverers = false;
        })
        .catch(function (error) {
          vm.loading.discoverers = false;
          toaster.pop('error', error.data.message || error.statusText);
        })
    }

    function getScales(cdpScore) {
      var scales = {};

      if (cdpScore && !_.isEmpty(cdpScore.serviceLevel)) {
        scales.hasOtherFactors = cdpScore.hasOtherFactors;
        scales.serviceLevel = cdpScore.serviceLevel;
        scales.selfClarity = _.get(cdpScore, 'score[2].scale', '');
        scales.knowledge = _.get(cdpScore, 'score[3].scale', '');
        scales.decisiveness = _.get(cdpScore, 'score[4].scale', '');
        scales.careerChoiceImportance = _.get(cdpScore, 'score[5].scale', '');
        if (
          !_.isEmpty(cdpScore.score[6]) &&
          cdpScore.score[6].reason ===
          APP_CONSTANTS.CAREER_DECISION_REASONS.BARRIER && cdpScore.score[6].scale
        ) {
          scales.barrier = _.get(cdpScore, 'score[6].scale', '');
        } else if (
          !_.isEmpty(cdpScore.score[7]) &&
          cdpScore.score[7].reason ===
          APP_CONSTANTS.CAREER_DECISION_REASONS.EXTERNAL_BARRIERS && cdpScore.score[7].scale
        ) {
          scales.barrier = _.get(cdpScore, 'score[7].scale', '');
        } else {
          scales.barrier = '';
        }

        if (
          scales.serviceLevel.toLowerCase() ===
          APP_CONSTANTS.SELF_HELP.toLowerCase()
        ) {
          scales.serviceLevelClass = 'sl-self-help fa fa-circle';
        } else if (
          scales.serviceLevel.toLowerCase() ===
          APP_CONSTANTS.BRIEF_STAFF_ASSISTED.toLowerCase()
        ) {
          scales.serviceLevelClass = 'sl-brief-staff-assist fa fa-circle';
        } else if (
          scales.serviceLevel.toLowerCase() ===
          APP_CONSTANTS.INDIVIDUAL_CASE_MANAGED_SERVICES.toLowerCase()
        ) {
          scales.serviceLevelClass = 'sl-individual-case-managed fa fa-circle';
        }
      }

      return scales;
    }

    function getScoreHistory(cdpScoreHistory) {
      var scoreHistory = [];

      if (cdpScoreHistory && cdpScoreHistory.length) {
        cdpScoreHistory.forEach(function (element) {
          if (element.cdpScore.serviceLevel) {
            scoreHistory.push({
              cdpAssessmentDate: element.cdpAssessmentDate,
              cdpScore: getScales(element.cdpScore)
            });
          }
        });
      }

      return scoreHistory;
    }

    function toggleScoreHistory(index) {
      if (
        vm.discoverers[index].cdpScoreHistory
      ) {
        vm.discoverers[index].showScoreHistory = !vm.discoverers[index]
          .showScoreHistory;
      }
    }

    function orderBy(property) {
      if (vm.currentSort.property === property) {
        if (vm.currentSort.order === 'desc') {
          vm.currentSort.order = 'asc';
          vm.sortClass = 'sort-asc';
        } else {
          vm.currentSort.order = 'desc';
          vm.sortClass = 'sort-desc';
        }
      } else {
        vm.currentSort.property = property;
        vm.currentSort.order = 'asc';
        vm.sortClass = 'sort-asc';
      }

      fetchCDPDiscoverers();
    }

    function setDateRange() {
      if (vm.startDate && vm.endDate)
        vm.dateRange =
          $rootScope.moment(vm.startDate).format('M/DD/YY') +
          ' - ' +
          $rootScope.moment(vm.endDate).format('M/DD/YY');
      else vm.dateRange = '';
    }


    vm.getTableData = function (event) {
      var $targetElement = $(event.currentTarget);

      if (
        !$targetElement.hasClass('disabled') &&
        !$targetElement.hasClass('current')
      ) {
        vm.pageNum = $targetElement.data('pageNum');

        vm.loading.discoverer = true;
        fetchCDPDiscoverers();
      }
    };

    function fetchGroups() {
      vm.loading.groups = true;
      if (vm.role !== '' && vm.role === 'groupCounselor') {
        GroupService.fetchGroupsByUser(
          SessionService.getUser().sponsoredOrganizationId
        )
          .then(function (response) {
            vm.listGroups = _.sortBy(response.data.data, (i) => i.name.toLowerCase());
            vm.discoverers_groups = _.concat({
              name: 'All Assigned Groups',
              id: ''
            }, vm.listGroups);
            vm.loading.groups = false;
            if (!_.isEmpty($stateParams.parameter)) {
              if ($stateParams.parameter.groupId !== '') {
                vm.group = vm.discoverers_groups.filter(function (item) {
                  return item.id === vm.groupId;
                })[0];
              }
            }
            addDiscoverersGroupsName();
          })
          .catch(function (error) {
            toaster.pop('error', error.data.message || error.statusText);
            vm.loading.groups = false;
          });
      } else {
        GroupService.fetchGroups()
          .then(function (response) {
            vm.listGroups = _.sortBy(response.data.data, (i) => i.name.toLowerCase());
            vm.discoverers_groups = _.concat(APP_CONSTANTS.GROUP_FILTER, vm.listGroups);
            vm.loading.groups = false;
            if (!_.isEmpty($stateParams.parameter)) {
              if ($stateParams.parameter.groupId !== '') {
                vm.group = vm.discoverers_groups.filter(function (item) {
                  return item.id === vm.groupId;
                })[0];
              }
            }
            addDiscoverersGroupsName();
          })
          .catch(function (error) {
            vm.loading.groups = false;
            toaster.pop('error', error.data.message || error.statusText);
          });
      }
    }

    function setScaleAndScore() {
      vm.scaleLevel = '';
      vm.scoreLevel = '';
      vm.selectedScaleLabel = '';
      if (vm.selectedScale && vm.selectedScale.key
        && vm.selectedScore && vm.selectedScore.key
      ) {
        vm.scaleLevel = vm.selectedScale.key;
        vm.scoreLevel = vm.selectedScore.key;
        vm.selectedScaleLabel = vm.selectedScore.value + ' in ' + vm.selectedScale.value;
      }
    }

    vm.resetFilters = function () {
      delete vm.group;
      delete vm.selectedServiceLevel;
      delete vm.selectedProgressLevel;
      delete vm.selectedScale;
      delete vm.selectedScore;
      delete vm.searchKey;
      vm.endDate = new Date(moment(new Date()).add(1, 'days').format());
      vm.startDate = new Date(moment(new Date()).subtract(1, 'years').format());
      filter_selected();
    }

    function filter_selected() {
      vm.categorySelected = false;
      vm.groupId = angular.isUndefined(vm.group) ? '' : vm.group.id;
      vm.endDate = angular.isUndefined(vm.endDate) ? '' : vm.endDate;
      vm.searchKey = angular.isUndefined(vm.searchKey) ? '' : vm.searchKey;
      vm.startDate = angular.isUndefined(vm.startDate) ? '' : vm.startDate;
      vm.serviceLevel = angular.isUndefined(vm.selectedServiceLevel)
        ? ''
        : vm.selectedServiceLevel.value;

      vm.progressLevel = angular.isUndefined(vm.selectedProgressLevel)
        ? ''
        : vm.selectedProgressLevel.key;

      vm.pageSize = angular.isUndefined(vm.selectedPageSize.value)
        ? 10
        : vm.selectedPageSize.value;

      setDateRange();
      setScaleAndScore();

      if (vm.serviceLevel && vm.serviceLevel !== 'All')
        vm.categorySelected = true;
      if ((!vm.startDate && vm.endDate) || (vm.startDate && !vm.endDate))
        return;

      var endDate = moment(vm.endDate);
      var startDate = moment(vm.startDate);
      var differenceInStartAndEndDate = endDate.diff(startDate, 'days');

      if (differenceInStartAndEndDate <= 0) {
        toaster.pop('error', APP_CONSTANTS.MESSAGE.startDateGreaterThanEndDate);
        return;
      }
      vm.isDateOpen = false;
      vm.isScaleOpen = false;
      vm.selectedDiscoverers = [];

      fetchCDPDiscoverers();

      $rootScope.cdpFilters = {
        group: vm.group,
        endDate: vm.endDate,
        startDate: vm.startDate,
        searchKey: vm.searchKey,
        selectedServiceLevel: vm.selectedServiceLevel,
        selectedProgressLevel: vm.selectedProgressLevel,
        selectedScale: vm.selectedScale,
        selectedScore: vm.selectedScore,
      }
    }

    function sendMessage(option) {
      if (!vm.selectedDiscoverers.length) {
        return openBulkEmailModal(option);
      }
      option.discoverers = vm.selectedDiscoverers;
      $state.go('app.mailbox.cdp', option);
    }

    function openBulkEmailModal(option) {
      let category = vm.selectedServiceLevel || vm.serviceLevels[0];
      if (option.template) {
        category.template = option.template;
      }

      const recipients = [];
      if (vm.group?.name) recipients.push(`Group: ${vm.group.name}`);
      if (vm.selectedServiceLevel?.value) recipients.push(`Service Level: ${vm.selectedServiceLevel.value}`);
      if (vm.selectedProgressLevel?.value) recipients.push(`Progress: ${vm.selectedProgressLevel.value}`);
      if (vm.selectedScaleLabel) recipients.push(`Scale: ${vm.selectedScaleLabel}`);
      if (vm.dateRange) recipients.push(`Date: ${vm.dateRange}`);
      if (vm.searchKey) recipients.push(`Search: ${vm.searchKey}`);

      var modalInstance = $uibModal.open({
        animation: true,
        templateUrl: 'app/sendEmailToAll/views/sendEmailToAll.html',
        controller: 'SendEmailToAllController',
        controllerAs: 'vm',
        size: 'lg',
        resolve: {
          tags: function () {
            return {
              category,
              recipients,
            };
          }
        }
      });

      modalInstance.result.then(sendBulkEmail);
    }

    function sendBulkEmail(customMessage) {
      vm.loading.message = true;

      const filters = {
        groupId: vm.groupId,
        serviceLevel: vm.serviceLevel === 'All' ? '' : vm.serviceLevel,
        progress: vm.progressLevel,
        searchKey: vm.searchKey,
        fromDate: vm.startDate?.toJSON() || '',
        toDate: vm.endDate?.toJSON() || '',
        scale: vm.scaleLevel,
        score: vm.scoreLevel,
      };

      CDPClientService.sendBulkMessage({
        filters,
        customMessage,
      }).then(
        function () {
          vm.loading.message = false;
          toaster.pop('success', APP_CONSTANTS.MESSAGE.messageSent);
        },
        function (error) {
          vm.loading.message = false;
          toaster.pop('error', error.data.message || error.statusText);
        }
      );
    }

    function assignDiscoverersToGroup() {
      GroupModal.assignDiscoverersToGroup(
        vm.listGroups,
        vm.selectedDiscoverers,
      ).then(() => {
        vm.loadedNewData = true;
        fetchCDPDiscoverers();
      });
    }

    vm.showDeleteDiscoverersBtn = () => {
      return (vm.discoverers.length
        && vm.selectedDiscoverers.length
        && !_.some(vm.selectedDiscoverers, d => d.cdpAssessmentDate.length)
      );
    }

    vm.confirmDeleteDiscoverers = () => {
      if (!vm.showDeleteDiscoverersBtn()) return;

      if (vm.selectedDiscoverers.length === 1) {
        return confirmDeleteDiscoverer(vm.selectedDiscoverers[0]);
      }

      ngDialog
        .openConfirm({
          template: 'app/shared/views/partials/ngDialogConfirmation.html',
          data: {
            message: 'Are you sure you want to delete the selected discoverers?'
          }
        })
        .then(function () {
          deleteDiscoverers(_.map(vm.selectedDiscoverers, 'id'));
        });
    }

    function confirmDeleteDiscoverer(discoverer) {
      ngDialog
        .openConfirm({
          template: 'app/shared/views/partials/ngDialogConfirmation.html',
          data: {
            message: 'Are you sure you want to delete the discoverer ' + discovererDisplayName(discoverer) + '?'
          }
        })
        .then(() => deleteDiscoverers([discoverer.id]));
    }

    function deleteDiscoverers(discovererIds) {
      vm.loading.discoverers = true;

      CDPClientService
        .deleteDiscoverers(discovererIds)
        .then(function () {
          toaster.pop('success', discovererIds.length > 1 ? APP_CONSTANTS.MESSAGE.deletedDiscoverers : APP_CONSTANTS.MESSAGE.deletedDiscoverer);
          vm.selectedDiscoverers = [];
          fetchCDPDiscoverers();
        })
        .catch(function (error) {
          vm.loading.discoverers = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function discovererDisplayName(discoverer) {
      return (discoverer.name && discoverer.name !== ' ')
        ? (discoverer.lastName + ', ' + discoverer.firstName)
        : (discoverer.email || discoverer.customerSourcedId || discoverer.id);
    }

    function addDiscoverersGroupsName() {
      if (!vm.discoverers.length) return;

      const unassign = _.find(APP_CONSTANTS.GROUP_FILTER, { id: 'unassign' });
      const groupMap = {};
      _.forEach(vm.discoverers_groups, g => {
        groupMap[g.id] = g.name;
      });
      _.forEach(vm.discoverers, dis => {
        dis.groupsName = [];
        _.forEach(dis.groupId, gid => {
          groupMap[gid] && dis.groupsName.push(groupMap[gid]);
        });
        !dis.groupsName.length && dis.groupsName.push(unassign.name);
      })
    }
  }
})();
