(function() {
  'use strict';
  angular
    .module('app.shared')
    .controller('MappedMajorsController', MappedMajorsController);

  MappedMajorsController.$inject = [
    'APP_CONSTANTS',
    'toaster',
    '$scope',
    '$uibModal',
    '$location',
    'isPublished',
    'selectedCareer',
    '$uibModalInstance',
    'WorkgroupService',
    'MajorsService',
    'ProductService'
  ];

  function MappedMajorsController(
    APP_CONSTANTS,
    toaster,
    $scope,
    $uibModal,
    $location,
    isPublished,
    selectedCareer,
    $uibModalInstance,
    WorkgroupService,
    MajorsService,
    ProductService
  ) {
    var vm = this;

    vm.loading = {};
    vm.isOpen = true;
    vm.workgroupCareers = [];
    vm.isPublished = isPublished;
    vm.mappedInstructionalProgramsList = [];
    vm.selectedCareer = selectedCareer;
    vm.dismissModal = dismissModal;
    vm.workgroupIPsArray = [];
    vm.saveMappedMajors = saveMappedMajors;
    vm.productId = ProductService.getProductId();
    vm.fetchWorkGroupIps = fetchWorkGroupIps;
    vm.fetchProductWorkgroups = fetchProductWorkgroups;
    vm.personalityTypes = APP_CONSTANTS.PERSONALITY_TYPES;
    vm.ptTypes = APP_CONSTANTS.PT_TYPES;
    vm.selectedCareerName = _.get(
      selectedCareer,
      'variations.en.name',
      APP_CONSTANTS.occupation
    );

    checkUnpublishedCareersAlreadyExist();
    resetCategorizedWorkgroups();
    fetchProductWorkgroups();

    function resetCategorizedWorkgroups() {
      vm.categorizedWorkgroups = {
        realistic: [],
        investigative: [],
        artistic: [],
        social: [],
        enterprising: [],
        conventional: []
      };
    }

    function saveMappedMajors() {
      $scope.$parent.vm.updateUnpublishedCareers(vm.selectedCareer.id);
      $scope.$parent.vm.updateMappedMajorsInCareer(
        vm.selectedCareer.id,
        vm.mappedInstructionalProgramsList
      );
      dismissModal();
    }

    function checkUnpublishedCareersAlreadyExist() {
      var alreadyUnpublishedCareer = $scope.$parent.vm.getUnpublishedCareerIfExist(
        vm.selectedCareer.id
      );

      if (alreadyUnpublishedCareer)
        vm.selectedCareer = alreadyUnpublishedCareer;

      vm.selectedCareer.instructionalPrograms &&
        vm.selectedCareer.instructionalPrograms.length &&
        vm.selectedCareer.instructionalPrograms.forEach(function(ip) {
          vm.mappedInstructionalProgramsList.push(ip);
        });
    }

    function fetchProductWorkgroups() {
      vm.loading.productInfo = true;

      WorkgroupService.fetchProductWorkgroups(vm.productId)
        .then(function(result) {
          vm.loading.productInfo = false;

          resetCategorizedWorkgroups();

          vm.productWorkgroups = _.get(result, 'data.workgroups', []);
          vm.productWorkgroups.forEach(function(workgroup) {
            if (workgroup.pt === vm.ptTypes.REALISTIC) {
              vm.categorizedWorkgroups.realistic.push(workgroup);
            } else if (workgroup.pt === vm.ptTypes.INVESTIGATIVE) {
              vm.categorizedWorkgroups.investigative.push(workgroup);
            } else if (workgroup.pt === vm.ptTypes.ARTISTIC) {
              vm.categorizedWorkgroups.artistic.push(workgroup);
            } else if (workgroup.pt === vm.ptTypes.SOCIAL) {
              vm.categorizedWorkgroups.social.push(workgroup);
            } else if (workgroup.pt === vm.ptTypes.ENTERPRISING) {
              vm.categorizedWorkgroups.enterprising.push(workgroup);
            } else if (workgroup.pt === vm.ptTypes.CONVENTIONAL) {
              vm.categorizedWorkgroups.conventional.push(workgroup);
            }
          });

          fetchWorkGroupIps(vm.categorizedWorkgroups.realistic[0]);
        })
        .catch(function(error) {
          vm.loading.productInfo = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function fetchWorkGroupIps(workgroup) {
      vm.loading.productInfo = true;
      vm.workgroupIPsArray = [];
      vm.activeWorkgroupId = workgroup.id;

      MajorsService.fetchMajorsByWorkgroupId(
        vm.productId,
        vm.activeWorkgroupId,
        true
      )
        .then(function(result) {
          vm.loading.productInfo = false;

          vm.workgroupMajors = _.get(
            result,
            'data.workgroup[0].instructionalPrograms',
            []
          );

          vm.workgroupMajors.forEach(function(major) {
            vm.workgroupIPsArray.push({
              id: major.id,
              name: major.variations.en.name,
              personalityType: workgroup.personalityType,
              workgroupId: vm.activeWorkgroupId
            });
          });
        })
        .catch(function(error) {
          vm.loading.productInfo = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function dismissModal() {
      $uibModalInstance.dismiss();
    }
  }
})();
