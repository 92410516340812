'use strict';

angular.module('careerKeyCentral').filter('nextItem', function() {
  return function(navigationList, currentLabel) {
    var index = navigationList.findIndex(function(x) {
      return x.label == currentLabel;
    });

    index++;
    if (index >= navigationList.length) index = 0;

    return navigationList[index];
  };
});
