(function() {
  'use strict';

  angular
    .module('app.customerAccount')
    .controller('DisplayAddonsController', DisplayAddonsController);

  DisplayAddonsController.$inject = [
    '$uibModal',
    'APP_CONSTANTS',
    '$uibModalInstance',
    'subscription'
  ];
  function DisplayAddonsController(
    $uibModal,
    APP_CONSTANTS,
    $uibModalInstance,
    subscription
  ) {
    var vm = this;

    vm.cancel = cancel;
    vm.subscription = subscription;

    function cancel() {
      $uibModalInstance.dismiss('cancel');
    }
  }
})();
