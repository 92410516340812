(function() {
  'use strict';

  angular.module('app.technical').factory('technicalService', technicalService);

  technicalService.$inject = [
    'APP_CONSTANTS',
    '$q',
    '$filter',
    'ApiCaller',
    'SessionService'
  ];

  function technicalService(
    APP_CONSTANTS,
    $q,
    $filter,
    ApiCaller,
    SessionService
  ) {
    var self = this;

    self.generateWidget = generateWidget;
    self.addCustomerApiKey = addCustomerApiKey;
    self.updateCustomerApiKey = updateCustomerApiKey;
    self.fetchCustomerApiKeys = fetchCustomerApiKeys;

    function generateWidget(accessKey) {
      var deferred = $q.defer();
      var url = 'app/technical/views/widget.html';
      var user = SessionService.getUser();
      var schoolId =
        user.collegeMajor === APP_CONSTANTS.collegeMajor.MY_INSTITUTION_OFFER
          ? user.schoolId
          : '';

      ApiCaller.apiGet(url)
        .then(function(response) {
          var sourceHtml = response.data;

          sourceHtml = sourceHtml.replace(/\$apiKey/, accessKey);
          
          // sourceHtml = sourceHtml.replace(/\$schoolId/, schoolId);
          
          sourceHtml = sourceHtml.replace(
            /\$hostName/g,
            APP_CONSTANTS.hostName
          );
          deferred.resolve(sourceHtml);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function addCustomerApiKey(data, customer_id) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.pluginCollection,
        APP_CONSTANTS.rootUrl,
        customer_id
      );

      ApiCaller.apiPost(url, data)
        .then(function(response) {
          deferred.resolve(response);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function fetchCustomerApiKeys(customer_id) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.customerAPIKey,
        APP_CONSTANTS.rootUrl,
        customer_id
      );

      ApiCaller.apiGet(url)
        .then(function(response) {
          deferred.resolve(response);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function updateCustomerApiKey(data, customer_id) {
      var apikey_id = data.accessKey.id;
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.deactivateCustomerAPIKey,
        APP_CONSTANTS.rootUrl,
        customer_id,
        apikey_id
      );

      ApiCaller.apiPut(url, data)
        .then(function(response) {
          deferred.resolve(response);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    return self;
  }
})();
