(function () {
  'use strict';
  angular
    .module('app.user')
    .controller('AddResellerPaymentController', AddResellerPaymentController);

  AddResellerPaymentController.$inject = [
    '$scope',
    'PaymentInfoService',
    'RegistrationService',
    'toaster',
    'APP_CONSTANTS',
    '$filter',
    'FileUploader'
  ];

  function AddResellerPaymentController(
    $scope,
    PaymentInfoService,
    RegistrationService,
    toaster,
    APP_CONSTANTS,
    $filter,
    FileUploader
  ) {
    var vm = this;
    var paypal = window.paypal;

    vm.submit = submit;
    vm.applyTax = applyTax;
    vm.removeTax = removeTax;
    vm.uploadFile = uploadFile;
    vm.printAgreement = printAgreement;
    vm.getExpiryYears = getExpiryYears;
    vm.downloadW9File = downloadW9File;
    vm.validateCardCvv = validateCardCvv;
    vm.validateCardNumber = validateCardNumber;
    vm.renderPaypalButton = renderPaypalButton;
    vm.submitPromotionCode = submitPromotionCode;
    vm.validateBankAccountNumber = validateBankAccountNumber;
    vm.validateBankRoutingNumber = validateBankRoutingNumber;
    vm.validateBankAccountNumber = validateBankAccountNumber;

    vm.cardType = '';
    vm.months = APP_CONSTANTS.MONTHS;

    vm.paymentBlock = APP_CONSTANTS.PAYMENT_METHOD.CREDIT_CARD;

    vm.paymentInfo = {
      paypal: {},
      creditCard: {},
      bankTransfer: {},
      purchaseOrder: {},
      taxExemptUrl: '',
      promotionCode: ''
    };
    vm.constants = {
      checkRequest: APP_CONSTANTS.checkRequest,
      paymentInformation: APP_CONSTANTS.paymentInformation,
      achAgreementMessage: APP_CONSTANTS.achAgreementMessage
    };

    vm.loading = false;
    vm.invalidFile = {};
    vm.taxExempt = false;
    vm.showFileUploadSuccess = false;

    var paypalActions,
      paypalButtonRendered = false,
      paypalButtonDisabled = false;
    var countryCode =
      (RegistrationService.getBillingInfo().country &&
        RegistrationService.getBillingInfo().country.countryCode) ||
      'US';

    init();

    $scope.handleFileSelect = function () {
      vm.applyTax();
    };

    function init() {
      if (
        $scope.regController.plan.period_unit ===
        APP_CONSTANTS.AUTO_RENEWAL_TYPE.MONTHLY
      ) {
        $scope.regController.hidePurchaseOrder = true;
        $scope.regController.width = { width: APP_CONSTANTS.widthWithoutPO };
      }
      if (
        $scope.regController.plan.period_unit ===
        APP_CONSTANTS.AUTO_RENEWAL_TYPE.YEARLY
      ) {
        $scope.regController.hidePurchaseOrder = false;
        $scope.regController.width = { width: APP_CONSTANTS.widthWithPO };
      }

      vm.uploader = new FileUploader({
        filters: [
          {
            name: 'imageFilter',
            fn: function (item) {
              var ext =
                '|' + item.name.slice(item.name.lastIndexOf('.') + 1) + '|';

              return (
                '|jpg|png|jpeg|docx|pdf|'.indexOf(ext.toLowerCase()) !== -1
              );
            }
          },
          {
            name: 'sizeFilter',
            fn: function (item) {
              // maximum size of 2MB
              return item.size < 2048000;
            }
          }
        ],
        url: $filter('sprintf')(
          APP_CONSTANTS.uploadTaxDocument,
          APP_CONSTANTS.rootUrl
        )
      });

      vm.uploader.onAfterAddingFile = function () {
        vm.invalidFile.type = false;
        vm.invalidFile.size = false;
      };

      vm.uploader.onSuccessItem = function (item, response) {
        vm.loading = false;
        vm.showFileUploadSuccess = true;
        vm.paymentInfo.taxExemptUrl = response.data.url;

        $scope.regController.taxable = false;
        var action = vm.promotionCode ? 'apply' : 'clear';

        vm.submitPromotionCode(action, false);

        RegistrationService.setTaxExemptFileUrl(vm.paymentInfo.taxExemptUrl);
      };

      vm.uploader.onErrorItem = function () {
        vm.loading = false;
        vm.showFileUploadSuccess = false;

        generalError(APP_CONSTANTS.MESSAGE.documentUploadFailed);
      };

      vm.uploader.onWhenAddingFileFailed = function (item, filter) {
        vm.loading = false;
        switch (filter.name) {
          case 'imageFilter':
            vm.invalidFile.type = true;
            break;
          case 'sizeFilter':
            vm.invalidFile.size = true;
            break;
          default:
            vm.invalidFile.type = false;
            vm.invalidFile.size = false;
        }
      };

      vm.uploader.onBeforeUploadItem = function (item) {
        if (
          vm.uploader.queue[0].file.name !== item.file.name &&
          vm.uploader.queue[0].isUploaded
        ) {
          vm.uploader.removeFromQueue(0);
        }
      };

      vm.uploader.onProgressItem = function () {
        vm.loading = true;
      };
    }

    function printAgreement() {
      var printPopup = window.open(
        '',
        '_blank',
        'width=600,height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no'
      );

      printPopup.document.write(
        '<html><head></head><body onload="window.print()">' +
        vm.constants.achAgreementMessage +
        '</body></html>'
      );
      printPopup.document.close();
    }

    function submitPromotionCode(type, displayToaster) {
      var quantity = 1;
      var resellerInfo = {};
      var promotionCode = [];
      var billingInfo = {
        city: $scope.regController.registrationDetails.billingInfo.city,
        state: $scope.regController.registrationDetails.billingInfo.state,
        zipCode: $scope.regController.registrationDetails.billingInfo.zipCode,
        lastName: $scope.regController.registrationDetails.billingInfo.lastName,
        firstName:
          $scope.regController.registrationDetails.billingInfo.firstName,
        addressLine1:
          $scope.regController.registrationDetails.billingInfo.addressLine1,
        addressLine2:
          $scope.regController.registrationDetails.billingInfo.addressLine2,
        country:
          $scope.regController.registrationDetails.billingInfo.country
            .countryCode,
        organization:
          $scope.regController.registrationDetails.billingInfo.country
            .countryCode
      };

      resellerInfo.taxability = $scope.regController.taxable
        ? APP_CONSTANTS.TAXABILITY.TAXABLE
        : APP_CONSTANTS.TAXABILITY.EXEMPT;

      if (type === 'apply') {
        if (!vm.promotionCode) {
          vm.invalidPromotionCode = true;

          return;
        }

        vm.invalidPromotionCode = false;
        promotionCode.push(vm.promotionCode);
      }

      var payload = {
        quantity: quantity,
        billingInfo: billingInfo,
        customerInfo: resellerInfo,
        couponCodes: promotionCode,
        planId: $scope.regController.plan.id
      };

      $scope.regController.loading.orderSummaryBlock = vm.loading = true;

      PaymentInfoService.getEstimate(payload)
        .then(function (response) {
          vm.paymentInfo.promotionCode = vm.promotionCode;
          vm.promotionCode = type === 'apply' ? vm.promotionCode : '';

          $scope.regController.promotionCode = vm.promotionCode;
          $scope.regController.loading.orderSummaryBlock = vm.loading = false;
          $scope.regController.orderSummary.totalAmount =
            response.data.data.invoice_estimate.total;
          $scope.regController.orderSummary.taxAmount =
            response.data.data.invoice_estimate.line_items[0].tax_amount;

          $scope.regController.orderSummary.discount =
            type === 'apply'
              ? response.data.data.invoice_estimate.discounts[0].amount
              : '';

          if (displayToaster) {
            var action = type === 'apply' ? 'applied' : 'cleared';
            var message = $filter('sprintf')(
              APP_CONSTANTS.MESSAGE.promotionCodeSuccess,
              action
            );

            toaster.clear('payment');
            toaster.pop({
              type: 'success',
              body: message,
              toasterId: 'payment'
            });
          }
        })
        .catch(function () {
          $scope.regController.loading.orderSummaryBlock = vm.loading = false;

          if (displayToaster) {
            var action = type === 'apply' ? 'apply' : 'clear';
            var message = $filter('sprintf')(
              APP_CONSTANTS.MESSAGE.promotionCodeFailure,
              action
            );

            toaster.clear('payment');
            toaster.pop({ type: 'error', body: message, toasterId: 'payment' });
          }
        });
    }

    function uploadFile() {
      vm.uploader.queue[vm.uploader.queue.length - 1].upload();
    }

    function downloadW9File() {
      PaymentInfoService.downloadW9File();
    }

    function renderPaypalButton() {
      if (!paypalButtonRendered && !paypalButtonDisabled) {
        vm.loading = true;
        paypalButtonRendered = true;

        paypal.Button.render(
          {
            // TODO: Inject environment here

            env: APP_CONSTANTS.PAYPAL.ENVIRONMENT, // sandbox | production

            style: {
              label: 'checkout', // checkout || credit
              size: 'small', // tiny | small | medium
              shape: 'rect', // pill | rect
              color: 'blue' // gold | blue | silver
            },

            validate: function (actions) {
              paypalActions = actions;
              $scope.$apply(function () {
                checkPaypalValidation();
              });
            },

            onClick: function () {
              $scope.$apply(function () {
                checkPaypalValidation();
              });
            },

            payment: function () {
              return paypal.request
                .post(
                  $filter('sprintf')(
                    APP_CONSTANTS.paypalPayment,
                    APP_CONSTANTS.rootUrl
                  )
                )
                .then(function (res) {
                  return res.data.TOKEN;
                })
                .catch(function (err) {
                  handlePaypalError(err);
                });
            },
            /* eslint-disable */
            onAuthorize: function (data) {
              return paypal.request
                .post(
                  $filter('sprintf')(
                    APP_CONSTANTS.paypalBillingAgreement,
                    APP_CONSTANTS.rootUrl
                  ),
                  {
                    paymentToken: data.paymentToken
                  }
                )
                .then(function (res) {
                  subscribeWithPaypal(res.data.BILLINGAGREEMENTID);
                })
                .catch(function (err) {
                  handlePaypalError(err);
                });
            }
          },
          '#paypal-button'
        ).then(function () {
          vm.loading = false;
        });
      }
    }
    /* eslint-enable */
    function checkPaypalValidation() {
      paypalActions.disable();

      if (RegistrationService.invalidAccountInfo())
        generalError(APP_CONSTANTS.MESSAGE.INVALID_ACCOUNT_INFORMATION);
      else if (RegistrationService.invalidBillingInfo())
        generalError(APP_CONSTANTS.MESSAGE.INVALID_ACCOUNT_INFORMATION);
      else paypalActions.enable();
    }

    function getExpiryYears() {
      var years = [];
      var today = new Date();
      var currentYear = today.getFullYear();

      for (var i = 0; i <= 10; i++) {
        years.push(currentYear + i);
      }

      return years;
    }

    function validateCardNumber() {
      if (
        vm.paymentInfo.creditCard.cardNumber &&
        !PaymentInfoService.validateCardNumber(
          vm.paymentInfo.creditCard.cardNumber
        )
      )
        vm.paymentInfoForm.cardNumber.$setValidity('invalidCardNo', false);
      else vm.paymentInfoForm.cardNumber.$setValidity('invalidCardNo', true);

      vm.cardType = vm.paymentInfo.creditCard.cardNumber
        ? PaymentInfoService.getCardType(vm.paymentInfo.creditCard.cardNumber)
        : '';
    }

    function validateCardCvv() {
      if (
        vm.paymentInfo.creditCard.cardCvv &&
        !PaymentInfoService.validateCardCvv(vm.paymentInfo.creditCard.cardCvv)
      )
        vm.paymentInfoForm.cardCvv.$setValidity('invalidCardCvv', false);
      else vm.paymentInfoForm.cardCvv.$setValidity('invalidCardCvv', true);
    }

    function validateBankAccountNumber() {
      if (
        vm.paymentInfo.bankTransfer.accountNumber &&
        !PaymentInfoService.validateBankAccountNumber(
          vm.paymentInfo.bankTransfer.accountNumber,
          countryCode
        )
      )
        vm.paymentInfoForm.accountNumber.$setValidity(
          'invalidAccountNo',
          false
        );
      else
        vm.paymentInfoForm.accountNumber.$setValidity('invalidAccountNo', true);
    }

    function validateBankRoutingNumber() {
      if (
        vm.paymentInfo.bankTransfer.routingNumber &&
        !PaymentInfoService.validateBankRoutingNumber(
          vm.paymentInfo.bankTransfer.routingNumber,
          countryCode
        )
      )
        vm.paymentInfoForm.routingNumber.$setValidity(
          'invalidRoutingNo',
          false
        );
      else
        vm.paymentInfoForm.routingNumber.$setValidity('invalidRoutingNo', true);
    }

    function submit(form) {
      if (!form.$valid || (vm.taxExempt && !vm.showFileUploadSuccess)) return;

      if (RegistrationService.invalidAccountInfo()) {
        generalError(APP_CONSTANTS.MESSAGE.INVALID_ACCOUNT_INFORMATION);

        return;
      }
      if (RegistrationService.invalidBillingInfo()) {
        generalError(APP_CONSTANTS.MESSAGE.INVALID_ACCOUNT_INFORMATION);

        return;
      }

      vm.loading = true;

      // TODO: Check if account info and billing info are not modified

      switch (vm.paymentBlock) {
        case APP_CONSTANTS.PAYMENT_METHOD.CREDIT_CARD:
          PaymentInfoService.createCardToken(
            vm.paymentInfo.creditCard,
            subscribeWithCreditCard
          );
          break;

        case APP_CONSTANTS.PAYMENT_METHOD.BANK_TRANSFER:
          PaymentInfoService.createBankToken(
            vm.paymentInfo.bankTransfer,
            countryCode,
            subscribeWithBankAccount
          );
          break;

        case APP_CONSTANTS.PAYMENT_METHOD.PURCHASE_ORDER:
          subscribeWithPurchaseOrder();
          break;

        default:
          vm.loading = false;

          return;
      }
    }

    function applyTax() {
      if ($scope.regController.taxable) return;
      vm.showFileUploadSuccess = false;
      vm.paymentInfo.taxExemptUrl = '';
      $scope.regController.taxable = true;

      var action = vm.promotionCode ? 'apply' : 'clear';

      vm.submitPromotionCode(action, false);
    }

    function removeTax() {
      if (!$scope.regController.taxable) return;

      var taxExemptFileUrl = RegistrationService.getTaxExemptFileUrl();

      if (taxExemptFileUrl) {
        vm.showFileUploadSuccess = true;
        vm.paymentInfo.taxExemptUrl = taxExemptFileUrl;
        $scope.regController.taxable = false;

        var action = vm.promotionCode ? 'apply' : 'clear';

        vm.submitPromotionCode(action, false);
      }
    }

    function subscribeWithCreditCard(status, response) {
      if (response.error) {
        $scope.$apply(function () {
          vm.loading = false;
          toaster.clear('payment');

          toaster.pop({
            type: 'error',
            body: response.error.message || response.error.code,
            toasterId: 'payment'
          });
          document.getElementById('payment').scrollIntoView(true);
        });
      } else {
        $('#creditCardNumber').val(
          $('#creditCardNumber')
            .val()
            .replace(/\d/g, 'X')
        );
        $('#creditCardCvv').val(
          $('#creditCardCvv')
            .val()
            .replace(/\d/g, 'X')
        );

        var paymentDetail = {
          token: response.id,
          type: APP_CONSTANTS.PAYMENT_METHOD.CARD,
          taxExemptUrl: vm.paymentInfo.taxExemptUrl,
          promotionCode: vm.paymentInfo.promotionCode,
          poNumber: vm.paymentInfo.creditCard.poNumber
        };

        RegistrationService.setPaymentDetail(paymentDetail);
        RegistrationService.setSignupType(
          APP_CONSTANTS.REGISTER_TYPE.CREDIT_CARD
        );
        $scope.$apply(function () {
          showPaymentUpdatedMessage();
        });
      }
    }

    function subscribeWithPaypal(id) {
      $scope.$apply(function () {
        vm.loading = true;
      });

      if (!id) {
        $scope.$apply(function () {
          vm.loading = false;
          toaster.clear('payment');

          toaster.pop({
            type: 'error',
            body: 'Something went wrong, Please Try Again',
            toasterId: 'payment'
          });
          document.getElementById('payment').scrollIntoView(true);
        });
      } else {
        var paymentDetail = {
          billingAgreementId: id,
          taxExemptUrl: vm.paymentInfo.taxExemptUrl,
          promotionCode: vm.paymentInfo.promotionCode,
          poNumber: vm.paymentInfo.paypal.poNumber,
          type: APP_CONSTANTS.PAYMENT_METHOD.PAYPAL_EXPRESS_CHECKOUT
        };

        RegistrationService.setPaymentDetail(paymentDetail);
        RegistrationService.setSignupType(
          APP_CONSTANTS.REGISTER_TYPE.CREDIT_CARD
        );

        disablePaypalButton();
        $scope.$apply(function () {
          showPaymentUpdatedMessage();
        });
      }
    }

    function subscribeWithBankAccount(status, response) {
      if (response.error) {
        $scope.$apply(function () {
          vm.loading = false;
          toaster.clear('payment');

          toaster.pop({
            type: 'error',
            body: response.error.message || response.error.code,
            toasterId: 'payment'
          });
          document.getElementById('payment').scrollIntoView(true);
        });
      } else {
        var paymentDetail = {
          token: response.id,
          taxExemptUrl: vm.paymentInfo.taxExemptUrl,
          promotionCode: vm.paymentInfo.promotionCode,
          poNumber: vm.paymentInfo.bankTransfer.poNumber,
          type: APP_CONSTANTS.PAYMENT_METHOD.DIRECT_DEBIT
        };

        RegistrationService.setPaymentDetail(paymentDetail);
        RegistrationService.setSignupType(
          APP_CONSTANTS.REGISTER_TYPE.BANK_TRANSFER
        );

        $scope.$apply(function () {
          showPaymentUpdatedMessage();
        });
      }
    }

    function subscribeWithPurchaseOrder() {
      var paymentDetail = {
        taxExemptUrl: vm.paymentInfo.taxExemptUrl,
        promotionCode: vm.paymentInfo.promotionCode,
        poNumber: vm.paymentInfo.purchaseOrder.poNumber,
        type: APP_CONSTANTS.PAYMENT_METHOD.PURCHASE_ORDER
      };

      RegistrationService.setPaymentDetail(paymentDetail);
      RegistrationService.setSignupType(
        APP_CONSTANTS.REGISTER_TYPE.PURCHASE_ORDER
      );

      showPaymentUpdatedMessage();
    }

    function showPaymentUpdatedMessage() {
      vm.loading = false;
      toaster.clear('payment');

      toaster.pop({
        type: 'success',
        body: 'Payment information successfully updated!',
        toasterId: 'payment',
        showCloseButton: true,
        timeout: 2000,
        onHideCallback: function () {
          openAccordion();
        }
      });
      document.getElementById('payment').scrollIntoView(true);
    }

    function generalError(message) {
      toaster.clear('payment');

      toaster.pop({
        type: 'error',
        body: message || 'Invalid Form Details',
        toasterId: 'payment'
      });
      document.getElementById('payment').scrollIntoView(true);
    }

    function disablePaypalButton() {
      paypalActions.disable();
      paypalButtonDisabled = true;
    }

    function handlePaypalError(err) {
      // TODO: Better error handle
      if (!err) {
        $scope.$apply(function () {
          generalError('Invalid Paypal Options');
        });
      }
    }

    function openAccordion() {
      var nextBlock = $filter('nextItem')(
        $scope.regController.registrationBlocks,
        $scope.regController.activeBlock.label
      );

      $scope.regController.activeBlock.label = nextBlock.label;
      $scope.regController.openBlock[nextBlock.id] = true;
    }

    vm.paymentInfo.creditCard.cardNumber = '';
    vm.paymentInfo.creditCard.type = {};
    vm.paymentInfo.creditCard.mask = '';

    $scope.maskOptions = {
      allowInvalidValue: true,
      clearOnBlur: false
    };

    $scope.$watch('vm.paymentInfo.creditCard.cardNumber', function (newNumber) {
      vm.paymentInfo.creditCard.type = getCreditCardType(newNumber);
      vm.paymentInfo.creditCard.mask = getMaskType(
        vm.paymentInfo.creditCard.type
      );
    });

    function getMaskType(cardType) {
      var masks = {
        mastercard: '9999 9999 9999 9999',
        visa: '9999 9999 9999 9999',
        amex: '9999 999999 99999',
        diners: '9999 9999 9999 99',
        discover: '9999 9999 9999 9999',
        unknown: '9999 9999 9999 9999'
      };

      return masks[cardType];
    }

    function getCreditCardType(creditCardNumber) {
      // start without knowing the credit card type
      var result = 'unknown';

      // first check for MasterCard
      if (/^5[1-5]/.test(creditCardNumber)) {
        result = 'mastercard';
      }
      // then check for Visa
      else if (/^4/.test(creditCardNumber)) {
        result = 'visa';
      }
      // then check for AmEx
      else if (/^3[47]/.test(creditCardNumber)) {
        result = 'amex';
      }
      // then check for Diners
      else if (/3(?:0[0-5]|[68][0-9])[0-9]{11}/.test(creditCardNumber)) {
        result = 'diners';
      }
      // then check for Discover
      else if (/6(?:011|5[0-9]{2})[0-9]{12}/.test(creditCardNumber)) {
        result = 'discover';
      }

      return result;
    }
  }
})();
