/**
* Created by Romit Amgai<romitamgai@lftechnology.com> on 4/18/17.
 */
(function() {
  'use strict';
  angular.module('app.user').factory('PaymentInfoService', PaymentInfoService);

  PaymentInfoService.$inject = [
    'localStorageService',
    'APP_CONSTANTS',
    'ApiCaller',
    '$filter',
    '$q',
    '$window'
  ];

  function PaymentInfoService(
    localStorageService,
    APP_CONSTANTS,
    ApiCaller,
    $filter,
    $q,
    $window
  ) {
    var self = this;
    var Stripe = window.Stripe;

    self.getCardType = getCardType;
    self.getEstimate = getEstimate;
    self.addCustomer = addCustomer;
    self.addReseller = addReseller;
    self.downloadW9File = downloadW9File;
    self.createCardToken = createCardToken;
    self.createBankToken = createBankToken;
    self.validateCardCvv = validateCardCvv;
    self.validateCardNumber = validateCardNumber;
    self.createSubscription = createSubscription;
    self.validateBankAccountNumber = validateBankAccountNumber;
    self.validateBankRoutingNumber = validateBankRoutingNumber;

    Stripe.setPublishableKey(APP_CONSTANTS.STRIPE.PUBLISHABLE_KEY);

    function validateCardNumber(cardNumber) {
      return Stripe.card.validateCardNumber(cardNumber);
    }

    function validateCardCvv(cardCvv) {
      return Stripe.card.validateCVC(cardCvv);
    }

    function validateBankAccountNumber(accountNumber, countryCode) {
      return Stripe.bankAccount.validateAccountNumber(
        accountNumber,
        countryCode
      );
    }

    function validateBankRoutingNumber(routingNumber, countryCode) {
      return Stripe.bankAccount.validateRoutingNumber(
        routingNumber,
        countryCode
      );
    }

    function getCardType(cardNumber) {
      return Stripe.card.cardType(cardNumber);
    }

    function downloadW9File() {
      var url = $filter('sprintf')(
        APP_CONSTANTS.DOWNLOAD_W9_FILE,
        APP_CONSTANTS.rootUrl
      );

      $window.open(url, '_self');
    }

    function createCardToken(cardInfo, callback) {
      Stripe.createToken(
        {
          cvc: cardInfo.cardCvv,
          number: cardInfo.cardNumber,
          exp_year: cardInfo.cardExpYear,
          exp_month: cardInfo.cardExpMonth
        },
        callback
      );
    }

    function createBankToken(bankInfo, countryCode, callback) {
      Stripe.bankAccount.createToken(
        {
          currency: 'USD',
          country: countryCode,
          routing_number: bankInfo.routingNumber,
          account_number: bankInfo.accountNumber,
          account_holder_name: bankInfo.accountHolderName,
          account_holder_type: bankInfo.accountHolderType
        },
        callback
      );
    }

    function getEstimate(payload) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.estimate,
        APP_CONSTANTS.rootUrl
      );

      ApiCaller.apiPost(url, payload).then(
        function(result) {
          deferred.resolve(result);
        },
        function(err) {
          deferred.reject(err);
        }
      );

      return deferred.promise;
    }

    function createSubscription(accountInfo, billingInfo, paymentInfo, plan) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(APP_CONSTANTS.signUp, APP_CONSTANTS.rootUrl);
      var userPayload = {
        plan: {},
        account: {},
        billing: {},
        payment: {}
      };

      // angular.copy(plan, userPayload.plan);
      angular.copy(accountInfo, userPayload.account);
      angular.copy(billingInfo, userPayload.billing);
      angular.copy(paymentInfo, userPayload.payment);

      userPayload.plan.id = plan.id;
      userPayload.plan.startDate = plan.startDate;
      delete userPayload.account.purchaseType;
      userPayload.account.country = userPayload.account.country.name;

      if (_.isEmpty(billingInfo)) {
        delete userPayload.billing;
      } else {
        userPayload.billing.country = userPayload.billing.country.countryCode;
      }

      ApiCaller.apiPost(url, userPayload).then(
        function(result) {
          deferred.resolve(result);
        },
        function(err) {
          deferred.reject(err);
        }
      );

      return deferred.promise;
    }

    function addCustomer(accountInfo, subscription) {
      const url = $filter('sprintf')(APP_CONSTANTS.addCustomer, APP_CONSTANTS.rootUrl);
      const userPayload = {};

      if (!_.isEmpty(accountInfo)) {
        userPayload.account = angular.copy(accountInfo);
      }
      if (!_.isEmpty(subscription)) {
        userPayload.subscription = angular.copy(subscription);
      }

      return ApiCaller.apiPost(url, userPayload);
    }

    function addReseller(accountInfo, billingInfo, paymentInfo, plan) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(APP_CONSTANTS.addReseller, APP_CONSTANTS.rootUrl);
      var userPayload = {
        plan: {},
        account: {},
        billing: {},
        payment: {}
      };

      // angular.copy(plan, userPayload.plan);
      angular.copy(accountInfo, userPayload.account);
      angular.copy(billingInfo, userPayload.billing);
      angular.copy(paymentInfo, userPayload.payment);

      userPayload.plan.id = plan.id;
      userPayload.plan.startDate = plan.startDate;
      delete userPayload.account.purchaseType;
      userPayload.account.country = userPayload.account.country.name;

      if (_.isEmpty(billingInfo)) {
        delete userPayload.billing;
      } else {
        userPayload.billing.country = userPayload.billing.country.countryCode;
      }

      ApiCaller.apiPost(url, userPayload).then(
        function(result) {
          deferred.resolve(result);
        },
        function(err) {
          deferred.reject(err);
        }
      );

      return deferred.promise;
    }

    return self;
  }
})();
