(function() {
  'use strict';
  angular
    .module('app.user')
    .factory('BillingInvitationService', BillingInvitationService);

  BillingInvitationService.$inject = [
    'ApiCaller',
    'APP_CONSTANTS',
    '$q',
    'SubscriptionPlansService',
    'RegistrationService',
    '$filter'
  ];

  function BillingInvitationService(
    ApiCaller,
    APP_CONSTANTS,
    $q,
    SubscriptionPlansService,
    RegistrationService,
    $filter
  ) {
    var self = this;

    self.register = register;
    self.destroyRegistrationData = destroyRegistrationData;

    function register(billingEmail) {
      var registrationInfo = RegistrationService.retrieveRegistrationInfo();
      var plan = SubscriptionPlansService.retrievePlan();

      registrationInfo.plan = plan.plan;
      registrationInfo.planId = plan.plan.id;
      registrationInfo.billingContact = billingEmail;

      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.userSignUpWithBillingInvitation,
        APP_CONSTANTS.rootUrl
      );

      ApiCaller.apiPost(url, registrationInfo).then(
        function(result) {
          deferred.resolve(result);
        },
        function(err) {
          deferred.reject(err);
        }
      );

      return deferred.promise;
    }

    function destroyRegistrationData() {
      SubscriptionPlansService.destroyPlan();
      RegistrationService.destroyRegistrationInfo();
    }

    return self;
  }
})();
