(function() {
  'use strict';
  angular.module('app.user').factory('TrialService', TrialService);

  TrialService.$inject = [
    '$window',
    'ApiCaller',
    '$q',
    'RegistrationService',
    'SubscriptionPlansService',
    'APP_CONSTANTS',
    '$filter'
  ];

  function TrialService(
    $window,
    ApiCaller,
    $q,
    RegistrationService,
    SubscriptionPlansService,
    APP_CONSTANTS,
    $filter
  ) {
    var self = this;

    self.register = register;
    self.storePlan = storePlan;
    self.retrievePlan = retrievePlan;
    self.fetchTrialPlan = fetchTrialPlan;
    self.organizationType = organizationType;
    self.destroyRegistrationData = destroyRegistrationData;

    function retrievePlan() {
      return SubscriptionPlansService.retrievePlan();
    }

    function organizationType() {
      return RegistrationService.retrieveRegistrationInfo().schoolType
        ? APP_CONSTANTS.Medium
        : APP_CONSTANTS.Higher;
    }

    function storePlan(data) {
      SubscriptionPlansService.storePlan(data);
    }

    function fetchTrialPlan() {
      var plan = RegistrationService.retrieveRegistrationInfo().schoolType
        ? APP_CONSTANTS.trialMedium
        : APP_CONSTANTS.trialHigher;
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.planTrial,
        APP_CONSTANTS.rootUrl,
        plan
      );

      ApiCaller.apiGet(url).then(
        function(result) {
          deferred.resolve(result);
        },
        function(err) {
          deferred.reject(err);
        }
      );

      return deferred.promise;
    }

    function register() {
      var registrationInfo = RegistrationService.retrieveRegistrationInfo();

      registrationInfo.planId = registrationInfo.schoolType
        ? APP_CONSTANTS.trialMedium
        : APP_CONSTANTS.trialHigher;

      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.userSignUpTrial,
        APP_CONSTANTS.rootUrl
      );

      ApiCaller.apiPost(url, registrationInfo).then(
        function(result) {
          deferred.resolve(result);
        },
        function(err) {
          deferred.reject(err);
        }
      );

      return deferred.promise;
    }

    function destroyRegistrationData() {
      SubscriptionPlansService.destroyPlan();
      RegistrationService.destroyRegistrationInfo();
    }

    return self;
  }
})();
