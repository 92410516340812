(function() {
  'use strict';
  angular
    .module('app.shared')
    .controller('ProductListController', ProductListController);

  ProductListController.$inject = [
    'APP_CONSTANTS',
    'toaster',
    '$uibModal',
    'ProductService',
    '$scope'
  ];

  function ProductListController(
    APP_CONSTANTS,
    toaster,
    $uibModal,
    ProductService,
    $scope
  ) {
    var vm = this;
    var modalInstance = '';

    vm.loading = {};
    vm.fetchProductIds = fetchProductIds;
    vm.openAddNewProductModal = openAddNewProductModal;
    vm.discoverers_riasec = APP_CONSTANTS.discoverers_riasec;

    vm.productList = [];
    $scope.$parent.vm.setActiveTab();
    $scope.$parent.vm.generateBreadCrumbs('products');
    fetchProductIds();

    function fetchProductIds() {
      vm.loading.productIds = true;

      ProductService.fetchProductIds()
        .then(function(result) {
          vm.loading.productIds = false;
          vm.productList = _.get(result, 'data.products', []);
        })
        .catch(function(error) {
          vm.loading.productIds = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function openAddNewProductModal(productList) {
      if (!modalInstance) {
        modalInstance = $uibModal.open({
          templateUrl: 'app/ckadmin/products/newProduct/newProduct.html',
          controller: 'NewProductController',
          controllerAs: 'vm',
          animation: true,
          size: 'md',
          scope: $scope,
          resolve: {
            productList: function() {
              return productList;
            }
          }
        });

        modalInstance.result
          .then(function() {
            modalInstance = '';
          })
          .catch(function() {
            modalInstance = '';
          });
      }
    }
  }
})();
