(function() {
  'use strict';

  angular.module('app.shared').factory('ProductService', ProductService);

  ProductService.$inject = ['APP_CONSTANTS', 'ApiCaller', '$filter', '$q'];

  function ProductService(APP_CONSTANTS, ApiCaller, $filter, $q) {
    var self = this;

    self.getProductId = getProductId;
    self.setProductId = setProductId;

    self.fetchProductIds = fetchProductIds;

    function setProductId(productId) {
      self.productId = productId;
    }

    function getProductId() {
      return self.productId;
    }

    function fetchProductIds() {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.productCollection,
        APP_CONSTANTS.rootUrl
      );

      ApiCaller.apiGet(url)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    return self;
  }
})();
