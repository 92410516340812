/* eslint no-undef: "off"*/
/**=========================================================
 * Module: Common
 * Service to issue accessToken by sending refreshToken to the server.
 =========================================================*/
(function() {
  'use strict';

  angular
    .module('careerKeyCentral')
    .service('AuthenticationService', AuthenticationService);

  AuthenticationService.$inject = [
    'localStorageService',
    'SessionService',
    'APP_CONSTANTS',
    'ApiCaller',
    '$q',
    '$filter',
    '$http',
    '$state',
    '$rootScope'
  ];

  function AuthenticationService(
    localStorageService,
    SessionService,
    APP_CONSTANTS,
    ApiCaller,
    $q,
    $filter,
    $http,
    $state,
    $rootScope
  ) {
    var self = this;

    self.handleSession = handleSession;

    function handleSession(rejection) {
      var deferred = $q.defer();

      var loginUrl = $filter('sprintf')(
        APP_CONSTANTS.login,
        APP_CONSTANTS.rootUrl
      );
      var url = $filter('sprintf')(
        APP_CONSTANTS.generateAccessToken,
        APP_CONSTANTS.rootUrl
      );
      var activationUrl = $filter('sprintf')(
        APP_CONSTANTS.userActivation,
        APP_CONSTANTS.rootUrl
      );

      if (rejection.config.url === url || rejection.config.url === loginUrl) {
        SessionService.removeUser();
        SessionService.removeAdminToken();
        SessionService.removeAccountManagerToken();
        
        $state.go('login');
        deferred.reject(rejection);
      } else if (rejection.config.url === activationUrl) {
        deferred.reject(rejection);
      } else {
        if (!$rootScope.issueAccessTokenFlag) {
          $rootScope.issueAccessTokenFlag = true;
          issueAccessToken(url)
            .then(function() {
              rejection.config.headers.Authorization =
                'Bearer ' + SessionService.getAccessToken();

              $http(rejection.config)
                .then(function(pendingRequests) {
                  deferred.resolve(pendingRequests);
                })
                .catch(function(error) {
                  deferred.reject(error);
                });
            })
            .catch(function(error) {
              deferred.reject(error);
            });
        } else {
          waitForAccessToken(5000)
            .then(function() {
              rejection.config.headers.Authorization =
                'Bearer ' + SessionService.getAccessToken();

              $http(rejection.config)
                .then(function(pendingRequests) {
                  deferred.resolve(pendingRequests);
                })
                .catch(function(error) {
                  deferred.reject(error);
                });
            })
            .catch(function(error) {
              deferred.reject(error);
            });
        }
      }

      return deferred.promise;
    }

    function waitForAccessToken(time) {
      var deferred = $q.defer();
      setTimeout(function() {
        if ($rootScope.issueAccessTokenFlag) return waitForAccessToken(time);
        else deferred.resolve();
      }, time);

      return deferred.promise;
    }

    function issueAccessToken(url) {
      var deferred = $q.defer();
      var data = { refreshToken: SessionService.getRefreshToken() };

      ApiCaller.apiPost(url, data)
        .then(function(response) {
          $rootScope.issueAccessTokenFlag = false;
          var user = SessionService.getUser();
          user.accessToken = response.data.accessToken;
          localStorageService.set('user', user);
          deferred.resolve(response);
        })
        .catch(function(error) {
          $rootScope.issueAccessTokenFlag = false;
          deferred.reject(error);
        });

      return deferred.promise;
    }
  }
})();
