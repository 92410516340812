(function() {
  'use strict';

  angular
    .module('app.shared')
    .factory('AddNewCareerService', AddNewCareerService);

  AddNewCareerService.$inject = ['APP_CONSTANTS', 'ApiCaller', '$filter', '$q'];

  function AddNewCareerService(APP_CONSTANTS, ApiCaller, $filter, $q) {
    var self = this;

    self.addNewCareer = addNewCareer;
    self.getUniqueCareerId = getUniqueCareerId;

    function getUniqueCareerId(productId, workgroupId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.getUniqueCareerId,
        APP_CONSTANTS.rootUrl,
        productId,
        workgroupId
      );

      ApiCaller.apiGet(url)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function addNewCareer(productId, workgroupId, careerDetails) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.addNewCareer,
        APP_CONSTANTS.rootUrl,
        productId,
        workgroupId
      );

      ApiCaller.apiPost(url, { careerDetails: careerDetails })
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    return self;
  }
})();
