(function () {
  'use strict';
  angular
    .module('app.user')
    .controller('AddResellerController', AddResellerController);
  AddResellerController.$inject = [
    'RegistrationService',
    'APP_CONSTANTS',
    '$scope',
    '$uibModalInstance',
    '$anchorScroll'
  ];

  function AddResellerController(
    RegistrationService,
    APP_CONSTANTS,
    $scope,
    $uibModalInstance
  ) {
    var vm = this;

    vm.isBillingInfoAvailable = isBillingInfoAvailable;
    vm.isAccountInfoAvailable = isAccountInfoAvailable;
    vm.isPaymentDetailAvailable = isPaymentDetailAvailable;

    vm.plan = {};
    vm.loading = {};
    vm.showBlock = {};
    vm.taxable = true;
    vm.activeBlock = {};
    vm.orderSummary = {};
    vm.registrationBlocks = [];
    vm.registrationDetails = {
      billingInfo: {},
      accountInfo: {}
    };

    vm.openBlock = {
      confirmation: false,
      subscription: true,
      accountInformation: false,
      billingInformation: false,
      paymentInformation: false,
      billingContactInformation: false
    };

    vm.signUpComplete = {
      type: '',
      status: false,
      activationId: '',
      paymentFailed: false,
      billingContactEmail: ''
    };

    vm.constants = {
      ck_w9: APP_CONSTANTS.EXTERNAL_URL.CK_W9,
      terms: APP_CONSTANTS.EXTERNAL_URL.TERMS,
      privacy: APP_CONSTANTS.EXTERNAL_URL.PRIVACY,
      trialSubscription: APP_CONSTANTS.SUBSCRIPTION_TYPE.TRIAL
    };

    init();

    function init() {
      vm.showBlock.subscription = false;
      vm.showBlock.billing = vm.showBlock.payment = true;
      angular.copy(
        APP_CONSTANTS.ADD_CUSTOMER_BLOCK,
        vm.registrationBlocks
      );
      vm.paymentType = APP_CONSTANTS.SUBSCRIPTION_TYPE.PREPAID;
    }

    function isBillingInfoAvailable() {
      return !angular.equals(vm.registrationDetails.billingInfo, {});
    }

    function isAccountInfoAvailable() {
      return !angular.equals(vm.registrationDetails.accountInfo, {});
    }

    function isPaymentDetailAvailable() {
      return !angular.equals(RegistrationService.getPaymentDetail(), {});
    }

    $scope.closeModal = function () {
      $uibModalInstance.dismiss();
    }

    $scope.$watchCollection(
      angular.bind(this, function () {
        return vm.openBlock;
      }),
      function () {
        var keys = Object.keys(vm.openBlock);
        var activeLabel = _.filter(keys, function (key) {
          if (vm.openBlock[key]) {
            return key;
          }
        });

        if (activeLabel.length === 1) {
          var activeBlock = _.filter(vm.registrationBlocks, function (block) {
            if (activeLabel[0] === block.id) {
              return block;
            }
          });

          if (activeBlock.length > 0)
            vm.activeBlock.label = activeBlock[0].label;
        }
      }
    );
  }
})();
