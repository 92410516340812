(function() {
  'use strict';
  angular
    .module('app.customerAccount')
    .factory('CancelSubscriptionService', CancelSubscriptionService);

  CancelSubscriptionService.$inject = [
    'APP_CONSTANTS',
    'ApiCaller',
    '$filter',
    '$q',
    '$rootScope'
  ];

  /* eslint-disable */
  function CancelSubscriptionService(
    APP_CONSTANTS,
    ApiCaller,
    $filter,
    $q,
    $rootScope
  ) {
    var self = this;

    self.deleteSubscriptions = deleteSubscriptions;
    self.fetchActiveSubscriptions = fetchActiveSubscriptions;

    function fetchActiveSubscriptions(customerId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.activeSubscriptionList,
        APP_CONSTANTS.rootUrl,
        customerId
      );

      ApiCaller.apiGet(url)
        .then(function(response) {
          deferred.resolve(response);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function deleteSubscriptions(payload) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.cancelSubscriptions,
        APP_CONSTANTS.rootUrl
      );

      ApiCaller.apiPut(url, payload)
        .then(function(response) {
          deferred.resolve(response);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    return self;
  }
})();
/* eslint-enable */
