(function() {
  'use strict';

  angular
    .module('app.notification')
    .controller('ProductActivationController', ProductActivationController);

  ProductActivationController.$inject = [
    '$stateParams',
    'APP_CONSTANTS',
    'ProductActivationService',
    'toaster',
    '$state',
    'CustomerService'
  ];

  function ProductActivationController(
    $stateParams,
    APP_CONSTANTS,
    ProductActivationService,
    toaster,
    $state
  ) {
    var vm = this;

    vm.notificationStatus = [
      {
        id: 1,
        status: 'Invite Technical'
      },
      {
        id: 2,
        status: 'Remind Billing'
      },
      {
        id: 3,
        status: 'Remind Technical'
      },
      {
        id: 4,
        status: 'Host Career Key Discovery '
      },
      {
        id: 5,
        status: 'View Discovery Step-by-Step'
      },
      {
        id: 6,
        status: 'Trial Expired'
      }
    ];

    /* eslint-disable */
    var userRoles = [
      {
        id: 1,
        role: 'Owner,' + 'Counselor,' + 'BillingContact'
      },
      {
        id: 2,
        role: 'Stakeholder'
      }
    ];
    /* eslint-enable */

    var notification = {
      customerId: '',
      customerName: '',
      to: [],
      notification: '',
      type: '',
      status: ''
    };

    vm.loading = {};
    vm.userRoles = [];
    vm.loading.message = false;
    vm.sendNotification = sendNotification;
    vm.fetchCutomerList = fetchCutomerList;
    vm.onStatusSelected = onStatusSelected;

    fetchCutomerList();

    function fetchCutomerList() {
      vm.fetchCustomerLoading = true;
      ProductActivationService.fetchCustomerList().then(
        function(result) {
          vm.customerList = _.filter(result.data.data, function(customer) {
            if (!_.isEmpty(customer.currentPlan)) {
              return (
                (customer.currentPlan.cf_subscription_type === 'prepaid' ||
                  customer.currentPlan.cf_subscription_type ===
                    'autorenewal') &&
                customer.accountStatus === 'active'
              );
            }
          });
          vm.fetchCustomerLoading = false;
        },
        function(error) {
          toaster.pop('error', error.data.message || error.statusText);
          vm.fetchCustomerLoading = false;
        }
      );
    }

    function onStatusSelected(selectedStatus) {
      if (selectedStatus.id === 1) {
        vm.notification.to = 'Owner,' + 'Counselor,' + 'BillingContact';
        notification.to = ['Owner', 'Counselor', 'Billing'];
        vm.notification.notification =
          APP_CONSTANTS.NOTIFICATION.productActivation.inviteTechnical;
      } else if (selectedStatus.id === 2) {
        vm.notification.to = 'Owner,' + 'Counselor,' + 'BillingContact';
        notification.to = ['Owner', 'Counselor', 'Billing'];
        vm.notification.notification =
          APP_CONSTANTS.NOTIFICATION.productActivation.remindBilling;
      } else if (selectedStatus.id === 3) {
        vm.notification.to = 'Owner,' + 'Counselor,' + 'BillingContact';
        notification.to = ['Owner', 'Counselor', 'Billing'];
        vm.notification.notification =
          APP_CONSTANTS.NOTIFICATION.productActivation.remindTechnical;
      } else if (selectedStatus.id === 4) {
        vm.notification.to = 'Technical Contact';
        notification.to = ['Technical'];
        vm.notification.notification =
          APP_CONSTANTS.NOTIFICATION.productActivation.hostCareerKeyDiscoverer;
      } else if (selectedStatus.id === 5) {
        vm.notification.to = 'Owner,' + 'Counselor,' + 'BillingContact';
        notification.to = ['Owner', 'Counselor', 'Billing'];
        vm.notification.notification =
          APP_CONSTANTS.NOTIFICATION.productActivation.viewDiscovererStepByStep;
      } else if (selectedStatus.id === 6) {
        vm.notification.to = 'Owner,' + 'Counselor,' + 'BillingContact';
        notification.to = ['Owner', 'Counselor', 'Billing'];
        vm.notification.notification =
          APP_CONSTANTS.NOTIFICATION.productActivation.trialExpored;
      }
    }

    function sendNotification(valid) {
      vm.formSubmit = true;
      if (!valid) {
        return;
      }

      notification.customerId = vm.notification.customer.id;
      notification.notification = vm.notification.notification;
      notification.customerName =
        vm.notification.customer.legalName || vm.notification.customer.name;
      notification.status = vm.notification.status.status;

      vm.loading.message = true;
      ProductActivationService.sendNotification(notification).then(
        function() {
          vm.loading.message = false;
          toaster.pop('success', APP_CONSTANTS.MESSAGE.messageSent);
          $state.go('app.notification.folder');
          vm.notification = {};
        },
        function(error) {
          vm.loading.message = false;
          vm.notification = {};
          toaster.pop('error', error.data.message || error.statusText);
        }
      );
    }
  }
})();
