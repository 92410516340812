(function() {
  'use strict';
  angular
    .module('careerKeyCentral')
    .controller('ActivityLogsController', ActivityLogsController);
  ActivityLogsController.$inject = [
    '$state',
    'toaster',
    'SessionService',
    'APP_CONSTANTS',
    'ActivityLogsService',
    '$stateParams'
  ];

  function ActivityLogsController(
    $state,
    toaster,
    SessionService,
    APP_CONSTANTS,
    ActivityLogsService,
    $stateParams
  ) {
    var vm = this;

    vm.loading = {};

    var userLoginDetail = SessionService.getUser();
    var organizationId =
      $stateParams.sponsoredOrganizationId ||
      userLoginDetail.sponsoredOrganizationId;

    vm.fetchAllLogs = function() {
      vm.loading.logs = true;
      ActivityLogsService.fetchAllLogs(organizationId).then(
        function(result) {
          vm.activityLogsList = result.data.data;
          vm.loading.logs = false;
        },
        function(error) {
          vm.loading.logs = false;
          toaster.pop('error', error.data.message || error.statusText);
        }
      );
    };
    vm.fetchAllLogs();
  }
})();
