(function() {
  'use strict';
  angular
    .module('app.shared')
    .controller('MappedSchoolsController', MappedSchoolsController);

  MappedSchoolsController.$inject = [
    'APP_CONSTANTS',
    'toaster',
    'isPublished',
    '$scope',
    'selectedMajor',
    '$uibModalInstance',
    'selectedWorkgroupId',
    'MappedSchoolsService'
  ];

  function MappedSchoolsController(
    APP_CONSTANTS,
    toaster,
    isPublished,
    $scope,
    selectedMajor,
    $uibModalInstance,
    selectedWorkgroupId,
    MappedSchoolsService
  ) {
    var vm = this;

    vm.allSchools = [];
    vm.mappedSchools = [];
    vm.isPublished = isPublished;
    vm.mappedSchoolsList = [];
    vm.searchKey = '';

    vm.selectedMajor = selectedMajor;
    vm.searchAllSchools = searchAllSchools;
    vm.saveMappedSchools = saveMappedSchools;
    vm.removeMappedSchool = removeMappedSchool;
    vm.fetchWorkgroupSchools = fetchWorkgroupSchools;
    vm.fetchWorkgroupMappedSchoolsByIds = fetchWorkgroupMappedSchoolsByIds;

    vm.selectedMajorName = _.get(
      selectedMajor,
      'variations.en.name',
      APP_CONSTANTS.instructionalProgram
    );

    vm.loading = {};
    vm.dismissModal = dismissModal;

    checkUnpublishedMajorsAlreadyExist();

    function fetchWorkgroupSchools(searchKey) {
      vm.loading.productInfo = true;

      MappedSchoolsService.fetchWorkgroupSchools(
        selectedWorkgroupId,
        searchKey || ''
      )
        .then(function(result) {
          vm.loading.productInfo = false;

          vm.allSchools = _.get(result, 'data.schools', []);
        })
        .catch(function(error) {
          vm.loading.productInfo = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function fetchWorkgroupMappedSchoolsByIds(schoolIdsArray) {
      vm.loading.productInfo = true;

      MappedSchoolsService.fetchWorkgroupMappedSchoolsById(
        selectedWorkgroupId,
        selectedMajor.id,
        schoolIdsArray
      )
        .then(function(result) {
          vm.loading.productInfo = false;

          vm.mappedSchoolsList = _.get(result, 'data.schools', []);
        })
        .catch(function(error) {
          vm.loading.productInfo = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function checkUnpublishedMajorsAlreadyExist() {
      var alreadyUnpublishedMajor = $scope.$parent.vm.getUnpublishedMajorIfExist(
        vm.selectedMajor.id
      );

      if (alreadyUnpublishedMajor) vm.selectedMajor = alreadyUnpublishedMajor;

      fetchWorkgroupMappedSchoolsByIds(vm.selectedMajor.Schools);
    }

    function saveMappedSchools() {
      $scope.$parent.vm.updateUnpublishedMajors(vm.selectedMajor.id);
      $scope.$parent.vm.updateMappedSchoolInMajor(
        vm.selectedMajor.id,
        vm.mappedSchoolsList
      );
      dismissModal();
    }

    function removeMappedSchool(selectedSchool) {
      var index = vm.mappedSchoolsList
        .map(function(school) {
          return school.id;
        })
        .indexOf(selectedSchool.id);

      if (index > -1) {
        vm.mappedSchoolsList.splice(index, 1);
      }
    }

    function searchAllSchools() {
      vm.searchKey = angular.isUndefined(vm.searchKey) ? '' : vm.searchKey;

      if (!vm.searchKey) return;

      fetchWorkgroupSchools(vm.searchKey);
    }

    function dismissModal() {
      $uibModalInstance.dismiss();
    }
  }
})();
