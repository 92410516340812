(function() {
  'use strict';

  angular.module('app.mailbox').factory('MailViewService', MailViewService);

  MailViewService.$inject = [
    '$state',
    '$rootScope',
    'APP_CONSTANTS',
    'ApiCaller',
    '$window',
    '$stateParams',
    '$q',
    '$filter'
  ];

  function MailViewService(
    $state,
    $rootScope,
    APP_CONSTANTS,
    ApiCaller,
    $window,
    $stateParams,
    $q,
    $filter
  ) {
    var self = this;

    self.fetchMailDetail = fetchMailDetail;

    function fetchMailDetail(orgId, messageId, userId) {
      var deferred = $q.defer();
      // TODO: We need to use either mail or message and use it throughout the application.
      var url = $filter('sprintf')(
        APP_CONSTANTS.mail,
        APP_CONSTANTS.rootUrl,
        orgId,
        messageId,
        userId
      );

      ApiCaller.apiGet(url).then(
        function(result) {
          deferred.resolve(result);
        },
        function(err) {
          deferred.reject(err);
        }
      );

      return deferred.promise;
    }

    return self;
  }
})();
