(function() {
  'use strict';
  angular
    .module('app.organization')
    .controller('UserInvitationController', UserInvitationController);

  UserInvitationController.$inject = [
    'APP_CONSTANTS',
    'SessionService',
    'ngDialog',
    'toaster',
    'UserInvitationService',
    '$filter'
  ];

  function UserInvitationController(
    APP_CONSTANTS,
    SessionService,
    ngDialog,
    toaster,
    UserInvitationService,
    $filter
  ) {
    var vm = this;

    vm.user = {};
    vm.loading = {};
    vm.roles = [];
    vm.emails = [];
    vm.user = SessionService.getUser();
    vm.isAccountManager = vm.user.roles.isAccountManager;
    vm.isOrgManager = vm.user.roles.isOrganizationManager;
    vm.billingRoleId = _.find(APP_CONSTANTS.invitationRoleList, function(
      roles
    ) {
      return roles.role === APP_CONSTANTS.BILLING;
    }).id;
    vm.stakeholderRoleId = _.find(APP_CONSTANTS.invitationRoleList, function(
      roles
    ) {
      return roles.role === APP_CONSTANTS.STAKEHOLDER;
    }).id;
    vm.technicalContactRoleId = _.find(
      APP_CONSTANTS.invitationRoleList,
      function(roles) {
        return roles.role === APP_CONSTANTS.TECHNICAL;
      }
    ).id;

    angular.copy(APP_CONSTANTS.invitationRoleList, vm.roles);
    vm.regex = {
      email: APP_CONSTANTS.REGEX.EMAIL
    };

    UserInvitationService.selectedRole = [];
    UserInvitationService.selectedRolesId = [];

    filterRoles();
    fetchUserList();

    function fetchUserList() {
      vm.loading.users = true;
      UserInvitationService.fetchUsersList(
        vm.user.sponsoredOrganizationId
      ).then(
        function(result) {
          vm.userList = result.data.data;
          vm.loading.users = false;
        },
        function(error) {
          vm.loading.users = false;
          toaster.pop('error', error.data.message || error.statusText);
        }
      );
    }

    function filterRoles() {
      if (vm.user.roles.isAccountManager) {
        vm.hideTechnicalContact = true;

        return;
      } else {
        if (vm.user.roles.isOrganizationManager) vm.hideBillingContact = true;

        return vm.roles.splice(0, 1);
      }
    }

    vm.selectRole = function(role) {
      if (role.id === vm.stakeholderRoleId) {
        toaster.pop('error', APP_CONSTANTS.MESSAGE.unAuthorizedRole);

        return;
      }
      UserInvitationService.selectRole(role);
    };

    vm.grantOrRevokeUser = function(user) {
      var data = {};
      var operation = user.status
        ? APP_CONSTANTS.ACCESS.GRANT
        : APP_CONSTANTS.ACCESS.REVOKE;

      data.message = $filter('sprintf')(
        APP_CONSTANTS.CONFIRMATION.GRANT_REVOKE_ACCESS,
        operation,
        user.email
      );
      ngDialog
        .openConfirm({
          template: 'app/shared/views/partials/ngDialogConfirmation.html',
          data: data
        })
        .then(
          function() {
            ngDialog.close();
            vm.loading.users = true;
            UserInvitationService.grantOrRevokeUser(user).then(
              function(response) {
                user.status = response.data.status;
                vm.loading.users = false;
                toaster.pop('success', APP_CONSTANTS.MESSAGE.onSuccess);
              },
              function(error) {
                vm.loading.users = false;
                toaster.pop('error', error.data.message || error.statusText);
              }
            );
          },
          function() {
            user.status = !user.status;
            ngDialog.close();
          }
        );
    };

    vm.sendInvite = function(valid) {
      var selectedRole = UserInvitationService.selectedRole;

      vm.invalidEmail = false;

      if (!valid) {
        vm.formNotSubmit = true;

        return;
      }

      vm.emailForm.newEmail.forEach(function(email) {
        if (!email || !vm.regex.email.test(email)) {
          vm.invalidEmail = true;
          vm.formNotSubmit = true;
        }
      });

      if (vm.invalidEmail) return;

      if (selectedRole.length < 1) {
        vm.noRoleSelected = true;

        return;
      }

      vm.loading.sendInvitation = true;

      vm.newUser = {};
      vm.formNotSubmit = false;
      vm.noRoleSelected = false;
      vm.newUser.roles = selectedRole;
      vm.newUser.modifierId = vm.user.id;
      vm.newUser.email = vm.emailForm.newEmail;
      vm.newUser.sponsoredOrganizationId = vm.user.sponsoredOrganizationId;
      vm.newUser.message = vm.emailForm.message || '';

      UserInvitationService.sendInvite(vm.newUser)
        .then(function() {
          toaster.pop('success', APP_CONSTANTS.MESSAGE.inviteUser);
          vm.loading.sendInvitation = false;
          vm.newUser = {};
          vm.emailForm.newEmail = '';
          vm.emailForm.message = '';
          vm.emailForm.$setPristine();
          vm.emailForm.$setUntouched();
          $('.user-roles').prop('checked', false);
          UserInvitationService.selectedRole = [];
          UserInvitationService.selectedRolesId = [];
        })
        .catch(function(error) {
          vm.loading.sendInvitation = false;
          toaster.pop('error', error.data.error.message || error.statusText);
        });
    };
  }
})();
