(function() {
  'use strict';

  angular.module('app.user').factory('PasswordService', PasswordService);

  PasswordService.$inject = ['APP_CONSTANTS', 'ApiCaller', '$q', '$filter'];

  function PasswordService(APP_CONSTANTS, ApiCaller, $q, $filter) {
    var self = this;

    self.fetchUser = fetchUser;
    self.resetPassword = resetPassword;
    self.changePassword = changePassword;

    function resetPassword(data) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.resetPassword,
        APP_CONSTANTS.rootUrl
      );

      ApiCaller.apiPost(url, data)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(err) {
          deferred.reject(err);
        });

      return deferred.promise;
    }

    function fetchUser(token) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.verifyforgotpassword,
        APP_CONSTANTS.rootUrl,
        token
      );

      ApiCaller.apiGet(url)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(err) {
          deferred.reject(err);
        });

      return deferred.promise;
    }

    function changePassword(data) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.changePassword,
        APP_CONSTANTS.rootUrl
      );

      ApiCaller.apiPost(url, data).then(
        function(result) {
          deferred.resolve(result);
        },
        function(error) {
          deferred.reject(error);
        }
      );

      return deferred.promise;
    }

    return self;
  }
})();
