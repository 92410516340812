(function() {
  'use strict';
  angular
    .module('app.shared')
    .controller(
      'MappedRegionsForMajorController',
      MappedRegionsForMajorController
    );

  MappedRegionsForMajorController.$inject = [
    'APP_CONSTANTS',
    '$scope',
    'isPublished',
    'selectedMajor',
    'productRegionList',
    '$uibModalInstance',
    'ProductService'
  ];

  function MappedRegionsForMajorController(
    APP_CONSTANTS,
    $scope,
    isPublished,
    selectedMajor,
    productRegionList,
    $uibModalInstance,
    ProductService
  ) {
    var vm = this;

    vm.loading = {};
    vm.productRegionList = productRegionList;
    vm.mappedRegionList = [];
    vm.isPublished = isPublished;
    vm.selectedMajor = selectedMajor;
    vm.dismissModal = dismissModal;
    vm.saveMappedRegions = saveMappedRegions;
    vm.productId = ProductService.getProductId();
    vm.selectedMajorName = _.get(
      selectedMajor,
      'variations.en.name',
      APP_CONSTANTS.instructionalProgram
    );

    vm.updatedRegionIdArray = [];

    checkUnpublishedMajorsAlreadyExist();

    function checkUnpublishedMajorsAlreadyExist() {
      var alreadyUnpublishedMajor = $scope.$parent.vm.getUnpublishedMajorIfExist(
        vm.selectedMajor.id
      );

      if (alreadyUnpublishedMajor) vm.selectedMajor = alreadyUnpublishedMajor;

      if (!vm.selectedMajor.regions) {
        vm.selectedMajor.regions = [];
      }

      fetchRegionsByIds(vm.selectedMajor.regions);
    }

    function fetchRegionsByIds(regionIdArray) {
      regionIdArray = regionIdArray || [];

      regionIdArray.forEach(function(regionId) {
        vm.productRegionList.forEach(function(region) {
          if (region.id === regionId) {
            vm.mappedRegionList.push({
              id: region.id,
              name: region.name
            });
          }
        });
      });
    }

    function getRegionIdArray(regionArray) {
      regionArray.forEach(function(region) {
        vm.updatedRegionIdArray.push(region.id);
      });

      return vm.updatedRegionIdArray;
    }

    function saveMappedRegions() {
      $scope.$parent.vm.updateUnpublishedMajors(vm.selectedMajor.id);
      $scope.$parent.vm.updateMappedRegionsInMajor(
        vm.selectedMajor.id,
        getRegionIdArray(vm.mappedRegionList)
      );
      dismissModal();
    }

    function dismissModal() {
      $uibModalInstance.dismiss();
    }
  }
})();
