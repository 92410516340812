(function() {
  'use strict';

  angular
    .module('app.mailbox')
    .filter('htmlToPlaintext', function() {
      return function(text) {
        return text
          ? String(text)
              .replace(/<\/?[^>]+(>|$)/g, '')
              .replace('&nbsp;', '')
          : '';
      };
    })
    .controller('MailFolderController', MailFolderController);

  MailFolderController.$inject = [
    'APP_CONSTANTS',
    'MailFolderService',
    'toaster',
    'SessionService',
    'DTOptionsBuilder'
  ];

  function MailFolderController(
    APP_CONSTANTS,
    MailFolderService,
    toaster,
    SessionService,
    DTOptionsBuilder
  ) {
    var vm = this;

    vm.loading = {};
    vm.dtOptions = DTOptionsBuilder.newOptions().withOption('order', [
      [0, 'desc']
    ]);

    var userLoginDetail = SessionService.getUser();

    vm.fetchAllMail = function() {
      vm.loading.messages = true;

      MailFolderService.fetchAllMail(
        userLoginDetail.sponsoredOrganizationId
      ).then(
        function(result) {
          vm.mailList = result.data.data;
          vm.loading.messages = false;
        },
        function(error) {
          vm.loading.messages = false;
          toaster.pop('error', error.data.message || error.statusText);
        }
      );
    };

    vm.fetchAllMail();
  }
})();
