/* global _:false*/
(function() {
  'use strict';
  angular.module('app.user').factory('PurchaseService', PurchaseService);

  PurchaseService.$inject = [
    'ApiCaller',
    'APP_CONSTANTS',
    '$q',
    'SubscriptionPlansService',
    'RegistrationService',
    '$filter'
  ];

  function PurchaseService(
    ApiCaller,
    APP_CONSTANTS,
    $q,
    SubscriptionPlansService,
    RegistrationService,
    $filter
  ) {
    var self = this;

    self.register = register;
    self.storePlan = storePlan;
    self.fetchPlans = fetchPlans;
    self.retrievePlan = retrievePlan;
    self.signUpWithACH = signUpWithACH;
    self.storeTaxDocument = storeTaxDocument;
    self.uploadTaxDocument = uploadTaxDocument;
    self.lookupSelectedPlan = lookupSelectedPlan;

    self.destroyRegistrationData = destroyRegistrationData;

    function retrievePlan() {
      return SubscriptionPlansService.retrievePlan();
    }

    function storePlan(data) {
      SubscriptionPlansService.storePlan(data);
    }

    function fetchPlans() {
      var deferred = $q.defer();
      // TODO: schooltype should be dynamic
      var schoolType = RegistrationService.retrieveRegistrationInfo().schoolType
        ? APP_CONSTANTS.Middle
        : APP_CONSTANTS.Higher;
      var url = $filter('sprintf')(
        APP_CONSTANTS.planCollection,
        APP_CONSTANTS.rootUrl,
        schoolType
      );

      ApiCaller.apiGet(url).then(
        function(result) {
          deferred.resolve(result);
        },
        function(err) {
          deferred.reject(err);
        }
      );

      return deferred.promise;
    }

    function storeTaxDocument(taxFileDocument) {
      var regInfo = RegistrationService.retrieveRegistrationInfo();

      regInfo.taxability = 'exempt';
      regInfo.taxDocument = taxFileDocument;
      RegistrationService.storeRegistrationInfo(regInfo);
    }

    function uploadTaxDocument(taxFileDocument) {
      var formData = new FormData();

      formData.append('file', taxFileDocument);
      formData.append(
        'fileName',
        RegistrationService.retrieveRegistrationInfo().email
      );
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.uploadTaxDocument,
        APP_CONSTANTS.rootUrl
      );

      ApiCaller.apiFilePost(url, formData, {
        transformRequest: angular.identity,
        headers: { 'Content-Type': undefined, enctype: 'multipart/form-data' }
      }).then(
        function(result) {
          deferred.resolve(result);
        },
        function(err) {
          deferred.reject(err);
        }
      );

      return deferred.promise;
    }

    function lookupSelectedPlan(planList) {
      var selectedPlan;
      var retrievePlan = self.retrievePlan();

      if (retrievePlan) {
        selectedPlan = _.find(planList, function(plan) {
          return retrievePlan.id === plan.plan.id;
        });
      } else {
        selectedPlan = planList[4];
      }

      return selectedPlan;
    }

    function register(billingEmail) {
      var registrationInfo = RegistrationService.retrieveRegistrationInfo();
      var plan = SubscriptionPlansService.retrievePlan();

      registrationInfo.plan = plan.plan;
      registrationInfo.planId = plan.plan.id;
      registrationInfo.billingEmail = billingEmail;

      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.userSignup,
        APP_CONSTANTS.rootUrl
      );

      ApiCaller.apiPost(url, registrationInfo).then(
        function(result) {
          deferred.resolve(result);
        },
        function(err) {
          deferred.reject(err);
        }
      );

      return deferred.promise;
    }

    function signUpWithACH() {
      var registrationInfo = RegistrationService.retrieveRegistrationInfo();
      var plan = SubscriptionPlansService.retrievePlan();

      registrationInfo.plan = plan.plan;
      registrationInfo.planId = plan.plan.id;

      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.userSignupWithACH,
        APP_CONSTANTS.rootUrl
      );

      ApiCaller.apiPost(url, registrationInfo).then(
        function(result) {
          deferred.resolve(result);
        },
        function(err) {
          deferred.reject(err);
        }
      );

      return deferred.promise;
    }

    function destroyRegistrationData() {
      SubscriptionPlansService.destroyPlan();
      RegistrationService.destroyRegistrationInfo();
    }

    return self;
  }
})();
