(function() {
  'use strict';
  angular
    .module('app.user')
    .controller('SubscriptionController', SubscriptionController);

  SubscriptionController.$inject = [
    '$state',
    'SubscriptionService',
    'APP_CONSTANTS',
    'toaster'
  ];

  function SubscriptionController(
    $state,
    SubscriptionService,
    APP_CONSTANTS,
    toaster
  ) {
    var vm = this;

    vm.loading = {};
    vm.planList = [];
    vm.submit = submit;
    vm.formSubmit = false;
    vm.loading.signUp = true;
    vm.openDatePicker = openDatePicker;
    vm.changeButtonText = changeButtonText;
    vm.renewalplanSelected = renewalplanSelected;
    vm.prepaidplanSelected = prepaidplanSelected;
    vm.cbCustomerId = null;

    var today = new Date();
    var sixMonthFromToday = new Date(
      new Date(today).setMonth(today.getMonth() + 6)
    );

    vm.datePicker = { opened: false };
    vm.dateOptions = {
      dateDisabled: dateDisabled,
      formatYear: 'yy',
      maxDate: sixMonthFromToday,
      minDate: today,
      startingDay: 1
    };

    function openDatePicker() {
      vm.datePicker.opened = true;
    }

    function dateDisabled(data) {
      var date = data.date,
        mode = data.mode;

      return mode === 'day' && (date.getDay() === 0 || date.getDay() === 6);
    }

    function prepaidplanSelected(plan) {
      vm.purchase.selectedPlan.plan.id = plan.id;
    }

    function renewalplanSelected(plan) {
      vm.purchase.selectedPlan.plan.id = plan.id;
    }

    SubscriptionService.getCustomer().then(
      function(result) {
        var customer = result.data.data;

        vm.cbCustomerId = customer.chargebee.customer.id;
        var schoolType = customer.type
          ? APP_CONSTANTS.Middle
          : APP_CONSTANTS.Higher;

        SubscriptionService.fetchPlans(schoolType).then(
          function(result) {
            vm.planList = result.data.data;
            vm.loading.signUp = false;
            activate();
          },
          function(error) {
            vm.loading.signUp = false;
            toaster.pop('error', error.data.message || error.statusText);
          }
        );
      },
      function(error) {
        vm.loading.signUp = false;
        toaster.pop('error', error.data.message || error.statusText);
      }
    );

    function changeButtonText() {
      vm.submitButtonText = vm.purchase.payment
        ? APP_CONSTANTS.addToCart
        : APP_CONSTANTS.Continue;
    }

    function submit() {
      vm.formSubmit = true;
      if (!vm.startDate) return;
      vm.purchase.selectedPlan.plan.startDate = vm.startDate.getTime() / 1000;
      SubscriptionService.storePlan(vm.purchase.selectedPlan);
      vm.loading.signUp = true;
      SubscriptionService.register(vm.cbCustomerId).then(
        function() {
          SubscriptionService.destroyRegistrationData();
          $state.go('app.subscription');
        },
        function(error) {
          vm.loading.signUp = false;
          toaster.pop('error', error.data.message || error.statusText);
        }
      );
    }

    function activate() {
      vm.purchase = {};
      vm.purchase.payment = false;
      changeButtonText();
      vm.purchase.selectedPlan = SubscriptionService.lookupSelectedPlan(
        vm.planList
      );
    }
  }
})();
