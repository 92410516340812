(function() {
  'use strict';
  angular
    .module('app.organization')
    .factory('OrganizationProfileService', OrganizationProfileService);

  OrganizationProfileService.$inject = [
    'APP_CONSTANTS',
    'ApiCaller',
    '$q',
    '$filter',
    'SessionService'
  ];

  function OrganizationProfileService(
    APP_CONSTANTS,
    ApiCaller,
    $q,
    $filter,
    SessionService
  ) {
    var self = this;

    self.fetchOrganization = fetchOrganization;
    self.updateOrganization = updateOrganization;
    self.uploadImage = uploadImage;
    self.fetchSchools = fetchSchools;
    self.fetchSchoolId = fetchSchoolId;

    function fetchSchools(params) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.schoolCollection,
        APP_CONSTANTS.rootUrl,
        $.param(params)
      );

      ApiCaller.apiGet(url)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function fetchOrganization(customerId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.customerSingle,
        APP_CONSTANTS.rootUrl,
        customerId
      );

      ApiCaller.apiGet(url).then(
        function(result) {
          deferred.resolve(result);
        },
        function(err) {
          deferred.reject(err);
        }
      );

      return deferred.promise;
    }

    /* eslint-disable */
    function fetchSchoolIdByCustomer(customerId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.schoolsByCustomerId,
        APP_CONSTANTS.rootUrl,
        customerId
      );

      ApiCaller.apiGet(url).then(
        function(result) {
          deferred.resolve(result);
        },
        function(err) {
          deferred.reject(err);
        }
      );

      return deferred.promise;
    }
    /* eslint-enable */

    function fetchSchoolId(params) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.schoolSingle,
        APP_CONSTANTS.rootUrl,
        $.param(params)
      );

      ApiCaller.apiGet(url).then(
        function(result) {
          deferred.resolve(result);
        },
        function(err) {
          deferred.reject(err);
        }
      );

      return deferred.promise;
    }

    function uploadImage(imageData, customerId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.customerImageUpload,
        APP_CONSTANTS.rootUrl,
        customerId
      );

      ApiCaller.apiPut(url, imageData).then(
        function(result) {
          deferred.resolve(result);
        },
        function(err) {
          deferred.reject(err);
        }
      );

      return deferred.promise;
    }

    function updateOrganization(organization) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.customerSingle,
        APP_CONSTANTS.rootUrl,
        organization.id
      );

      ApiCaller.apiPut(url, organization).then(
        function(result) {
          var user = SessionService.getUser();

          user.collegeMajor = result.data.data.collegeMajor;
          SessionService.setUser(user);
          deferred.resolve(result);
        },
        function(err) {
          deferred.reject(err);
        }
      );

      return deferred.promise;
    }

    return self;
  }
})();
