(function() {
  'use strict';
  angular
    .module('app.shared')
    .controller('ViewMajorDetailsController', ViewMajorDetailsController);

  ViewMajorDetailsController.$inject = [
    'APP_CONSTANTS',
    '$scope',
    'selectedMajor',
    '$uibModalInstance'
  ];

  function ViewMajorDetailsController(
    APP_CONSTANTS,
    $scope,
    selectedMajor,
    $uibModalInstance
  ) {
    var vm = this;

    vm.loading = {};
    vm.dismissModal = dismissModal;
    vm.selectedMajor = selectedMajor;
    vm.majorsLanguageVariationArray = [];
    vm.getLanguageVariations = getLanguageVariations;

    vm.selectedMajorName = _.get(
      selectedMajor,
      'variations.en.name',
      APP_CONSTANTS.instructionalProgram
    );

    getLanguageVariations();

    function dismissModal() {
      $uibModalInstance.dismiss();
    }

    function getLanguageVariations() {
      var alreadyUnpublishedMajor = $scope.$parent.vm.getUnpublishedMajorIfExist(
        vm.selectedMajor.id
      );

      if (alreadyUnpublishedMajor)
        vm.selectedMajor = JSON.parse(JSON.stringify(alreadyUnpublishedMajor));

      vm.majorsLanguageVariation = JSON.parse(
        JSON.stringify(vm.selectedMajor.variations)
      );

      Object.keys(vm.majorsLanguageVariation).forEach(function(key) {
        vm.majorsLanguageVariationArray.push({
          name: APP_CONSTANTS.languageVariations[key],
          variation: vm.majorsLanguageVariation[key]
        });
      });
    }
  }
})();
