(function() {
  'use strict';

  angular
    .module('app.notification')
    .controller('NotificationListController', NotificationListController);

  NotificationListController.$inject = [
    'APP_CONSTANTS',
    'NotificationListService',
    'toaster',
    'SessionService',
    'DTOptionsBuilder'
  ];

  function NotificationListController(
    APP_CONSTANTS,
    NotificationListService,
    toaster,
    SessionService,
    DTOptionsBuilder
  ) {
    var vm = this;

    vm.loading = {};
    vm.userLoginDetail = {};
    vm.userLoginDetail = SessionService.getUser();
    vm.dtOptions = DTOptionsBuilder.newOptions().withOption('order', [
      [0, 'desc']
    ]);

    /* eslint-disable */
    var userLoginDetail = SessionService.getUser();
    vm.fetchAllNotification = function() {
      vm.loading.notification = true;
      vm.notificationList = [];
      NotificationListService.fetchAllNotification().then(
        function(result) {
          vm.notificationList = result.data.data;
          vm.loading.notification = false;
        },
        function(error) {
          vm.loading.notification = false;
          toaster.pop('error', error.data.message || error.statusText);
        }
      );
    };
    vm.fetchAllNotification();
  }
})();
