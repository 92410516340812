(function() {
  'use strict';
  angular
    .module('app.customerAccount')
    .factory('AccountUpdateService', AccountUpdateService);

  AccountUpdateService.$inject = [
    'APP_CONSTANTS',
    'ApiCaller',
    '$filter',
    '$q',
    '$rootScope'
  ];

  /* eslint-disable */
  function AccountUpdateService(
    APP_CONSTANTS,
    ApiCaller,
    $filter,
    $q,
    $rootScope
  ) {
    var self = this;

    self.image = '';

    self.setImage = setImage;
    self.getImage = getImage;
    self.uploadImage = uploadImage;
    self.getAccountInfo = getAccountInfo;
    self.getAccountImage = getAccountImage;
    self.updateAccountInfo = updateAccountInfo;
    self.changePrimaryContact = changePrimaryContact;
    self.fetchAccountManagerList = fetchAccountManagerList;

    function getAccountInfo(userId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.customerInfo,
        APP_CONSTANTS.rootUrl,
        userId
      );

      ApiCaller.apiGet(url)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function updateAccountInfo(accountInfo, userId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.customerInfo,
        APP_CONSTANTS.rootUrl,
        userId
      );

      ApiCaller.apiPut(url, accountInfo)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function getAccountImage(userId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.customerAccountImage,
        APP_CONSTANTS.rootUrl,
        userId
      );

      ApiCaller.apiGet(url)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function uploadImage(imageData, customerId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.customerAccountImage,
        APP_CONSTANTS.rootUrl,
        customerId
      );

      ApiCaller.apiPut(url, imageData)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function fetchAccountManagerList(customerId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.accountManagerList,
        APP_CONSTANTS.rootUrl,
        customerId
      );

      ApiCaller.apiGet(url)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function changePrimaryContact(data, customerId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.customerPrimaryAccountManager,
        APP_CONSTANTS.rootUrl,
        customerId
      );

      ApiCaller.apiPut(url, data)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function setImage(url) {
      self.image = url;
    }

    function getImage() {
      return self.image;
    }

    return self;
  }
})();
/* eslint-enable */
