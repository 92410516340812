angular
  .module('app.shared')

  .factory('fileUpload', [
    '$http',
    '$filter',
    'APP_CONSTANTS',
    'ApiCaller',
    '$q',
    function($http, $filter, APP_CONSTANTS, ApiCaller, $q) {
      this.uploadCsvFile = function(discoverers) {
        var formData = new FormData();
        var deferred = $q.defer();
        var url = $filter('sprintf')(
          APP_CONSTANTS.discoverersByGroupId,
          APP_CONSTANTS.rootUrl,
          discoverers.group.id
        );

        formData.append('file', discoverers.file);
        formData.append('assessmentType', discoverers.assessmentType.value);

        ApiCaller.apiFilePost(url, formData, {
          transformRequest: angular.identity,
          headers: { 'Content-Type': undefined }
        }).then(
          function(result) {
            deferred.resolve(result);
          },
          function(error) {
            deferred.reject(error);
          }
        );

        return deferred.promise;
      };

      this.uploadCsvFileWithoutGroup = function(discoverers) {
        var formData = new FormData();
        var deferred = $q.defer();
        var url = $filter('sprintf')(
          APP_CONSTANTS.discoverersWithoutGroupId,
          APP_CONSTANTS.rootUrl
        );

        formData.append('file', discoverers.file);
        formData.append('assessmentType', discoverers.assessmentType.value);

        ApiCaller.apiFilePost(url, formData, {
          transformRequest: angular.identity,
          headers: { 'Content-Type': undefined }
        }).then(
          function(result) {
            deferred.resolve(result);
          },
          function(error) {
            deferred.reject(error);
          }
        );

        return deferred.promise;
      };

      return this;
    }
  ]);
