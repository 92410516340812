(function() {
  'use strict';
  angular
    .module('app.shared')
    .controller('BatchesController', BatchesController);

  BatchesController.$inject = [
    'APP_CONSTANTS',
    '$uibModal',
    '$scope',
    'toaster',
    '$rootScope',
    'BatchesService',
    'ProductService'
  ];

  function BatchesController(
    APP_CONSTANTS,
    $uibModal,
    $scope,
    toaster,
    $rootScope,
    BatchesService,
    ProductService
  ) {
    var vm = this;

    var modalInstance = '';

    vm.loading = {};
    vm.batchList = [];
    vm.orderCol = 1;
    vm.pageNumber = 1;
    vm.pageSize = 10;
    vm.searchKey = '';
    vm.sortBy = 'name';
    vm.orderBy = 'asc';
    vm.totalRecords = 0;
    vm.uploadEndDate = '';
    vm.uploadStartDate = '';
    vm.loadedNewData = false;
    vm.filteredBatchList = [];
    vm.isUploadDateOpen = false;
    vm.refreshTimeStamp = Date.now();
    vm.filter_selected = filter_selected;
    vm.endUploadDatePickerIsOpen = false;
    vm.resetUploadDate = resetUploadDate;
    vm.startUploadDatePickerIsOpen = false;
    vm.productId = ProductService.getProductId();
    vm.openAddNewBatchModal = openAddNewBatchModal;
    vm.pageSizeOptions = APP_CONSTANTS.pageSizeOptions;
    vm.fetchBatchesByProductId = fetchBatchesByProductId;

    setUploadDateRange();

    fetchBatchesByProductId();

    vm.pageSizeOption = {
      key: "'" + vm.pageSize + "'",
      value: vm.pageSize
    };

    vm.dtOptions = {
      paging: false,
      searching: false,
      info: false,
      order: [],
      columnDefs: [
        {
          orderable: false,
          targets: [2]
        },
        {
          orderDataType: 'batches-custom-sort',
          targets: [0, 1, 3]
        }
      ]
    };

    function resetUploadDate() {
      vm.uploadStartDate = '';
      vm.uploadEndDate = '';
      setUploadDateRange();

      fetchBatchesByProductId();
    }

    function setUploadDateRange() {
      if (vm.uploadStartDate && vm.uploadEndDate)
        vm.uploadDateRange =
          $rootScope.moment(vm.uploadStartDate).format('M/DD/YY') +
          ' - ' +
          $rootScope.moment(vm.uploadEndDate).format('M/DD/YY');
      else vm.uploadDateRange = '';
    }

    function filter_selected() {
      setUploadDateRange();

      if (
        (!vm.uploadStartDate && vm.uploadEndDate) ||
        (vm.uploadStartDate && !vm.uploadEndDate)
      )
        return;

      vm.isUploadDateOpen = false;
      vm.loadedNewData = true;

      vm.fetchBatchesByProductId(1);
    }

    $.fn.dataTable.ext.order['batches-custom-sort'] = function(settings, col) {
      var scope = angular.element($('.table-responsive')).scope();

      if (scope.vm.orderCol !== col) {
        scope.vm.orderCol = col;
        scope.vm.orderBy = 'asc';

        if (col === 0) {
          scope.vm.sortBy = 'name';
        } else if (col === 1) {
          scope.vm.sortBy = 'type';
        } else if (col === 3) {
          scope.vm.sortBy = '_ckCreatedAt';
        }

        scope.vm.loadedNewData = true;
        scope.vm.fetchBatchesByProductId();
      } else if (scope.vm.orderCol === col) {
        if (scope.vm.loadedNewData) {
          scope.vm.loadedNewData = false;
        } else {
          scope.vm.orderBy = scope.vm.orderBy === 'asc' ? 'desc' : 'asc';
          scope.vm.loadedNewData = true;
          scope.vm.fetchBatchesByProductId();
        }
      }

      return false;
    };

    vm.getTableData = function(event) {
      var $targetElement = $(event.currentTarget);

      if (
        !$targetElement.hasClass('disabled') &&
        !$targetElement.hasClass('current')
      ) {
        var pageNumber = $targetElement.data('pageNum');

        vm.pageNumber = pageNumber;
        vm.loadedNewData = true;
        vm.fetchBatchesByProductId(pageNumber);
      }
    };

    vm.pageSizeChanged = function() {
      vm.pageSize = vm.pageSizeOption;
      vm.searchKey = '';
      vm.loadedNewData = true;
      vm.fetchBatchesByProductId(1);
    };

    vm.searchByKey = function(event) {
      if (event.keyCode === 13) {
        vm.loadedNewData = true;
        vm.fetchBatchesByProductId(1);
      }
    };

    function fetchBatchesByProductId(pageNumber) {
      vm.loading.productInfo = true;
      vm.isUploadDateOpen = false;

      var uploadFromDate = vm.uploadStartDate
        ? vm.uploadStartDate.toJSON()
        : '';
      var uploadToDate = vm.uploadEndDate ? vm.uploadEndDate.toJSON() : '';

      BatchesService.fetchBatchesByProductId(
        vm.productId,
        pageNumber || vm.pageNumber,
        vm.pageSize,
        vm.searchKey,
        vm.sortBy,
        vm.orderBy,
        uploadFromDate,
        uploadToDate
      )
        .then(function(result) {
          vm.loading.productInfo = false;

          vm.batchList = _.get(result, 'data.data.batchList', []);
          vm.totalRecords = _.get(result, 'data.data.totalRecords', 0);
          vm.pageNumber = result.data.data.pageNumber
            ? result.data.data.pageNumber
            : 1;
          vm.pageSize = result.data.data.pageSize
            ? result.data.data.pageSize
            : 10;
          vm.refreshTimeStamp = Date.now();
          vm.pageSizeOption = {
            key: "'" + vm.pageSize + "'",
            value: vm.pageSize
          };
        })
        .catch(function(error) {
          vm.loading.productInfo = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function openAddNewBatchModal() {
      if (!modalInstance) {
        modalInstance = $uibModal.open({
          templateUrl: 'app/ckadmin/products/batches/addBatch/addBatch.html',
          controller: 'AddNewBatchController',
          controllerAs: 'vm',
          animation: true,
          size: 'md',
          scope: $scope
        });

        modalInstance.result
          .then(function() {
            modalInstance = '';
          })
          .catch(function() {
            modalInstance = '';
          });
      }
    }
  }
})();
