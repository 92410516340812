(function() {
  'use strict';

  angular
    .module('app.notification')
    .factory('NotificationListService', NotificationListService);

  NotificationListService.$inject = [
    '$state',
    '$rootScope',
    'APP_CONSTANTS',
    'ApiCaller',
    '$window',
    '$stateParams',
    '$q',
    '$filter'
  ];

  function NotificationListService(
    $state,
    $rootScope,
    APP_CONSTANTS,
    ApiCaller,
    $window,
    $stateParams,
    $q,
    $filter
  ) {
    var self = this;

    self.fetchAllNotification = fetchAllNotification;

    function fetchAllNotification() {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.notificationCollection,
        APP_CONSTANTS.rootUrl
      );

      ApiCaller.apiGet(url).then(
        function(result) {
          deferred.resolve(result);
        },
        function(err) {
          deferred.reject(err);
        }
      );

      return deferred.promise;
    }

    return self;
  }
})();
