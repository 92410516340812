(function () {
  'use strict';

  angular.module('careerKeyCentral')
    .directive('outsideClick', outsideClick);

  outsideClick.$inject = ['$window', '$parse'];

  function outsideClick($window, $parse) {
    return {
      link: function (scope, el, attr) {
        if (!attr.outsideClick) {
          return;
        }

        var ignore;

        if (attr.ignoreIf) {
          ignore = $parse(attr.ignoreIf);
        }

        var nakedEl = el[0];
        var fn = $parse(attr.outsideClick);

        var handler = function (e) {
          if (
            nakedEl === e.target ||
            nakedEl.contains(e.target) ||
            (ignore && ignore(scope))
          ) {
            return;
          }

          scope.$apply(fn);
        };

        $window.addEventListener('click', handler, true);

        scope.$on('$destroy', function () {
          $window.removeEventListener('click', handler);
        });
      }
    };
  };
})();
