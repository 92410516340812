(function() {
  'use strict';
  angular
    .module('app.shared')
    .controller('BillingController', BillingController);

  BillingController.$inject = [
    'toaster',
    'BillingService',
    'UserInvitationService',
    'SessionService',
    'OrganizationProfileService',
    '$state'
  ];

  function BillingController(
    toaster,
    BillingService,
    UserInvitationService,
    SessionService,
    OrganizationProfileService,
    $state
  ) {
    $state.go('app.ownerdashboard');
    var vm = this;

    vm.card = {};
    vm.loading = {};
    vm.billingInfo = {};
    vm.organization = {};
    vm.chargeBeeCustomerId = null;
    var currentUser = SessionService.getUser();
    var customerId = currentUser.customerId;

    vm.payNow = payNow;
    vm.showBillingInfo = showBillingInfo;
    vm.updatePaymentMethod = updatePaymentMethod;

    fetchUserList();
    fetchSubscription();
    loadOrganizationDetails();

    function loadOrganizationDetails() {
      vm.organization.legalName = currentUser.customerLegalName;
      vm.organization.profileImage = SessionService.getOrganizationImage();
    }

    function fetchSubscription() {
      vm.loading.subscription = true;
      vm.loading.transaction = true;
      BillingService.fetchSubscription().then(
        function(result) {
          vm.subscription = result.data.data;
          fetchCustomerDetail();
          var subscription = _.filter(vm.subscription, function(subscription) {
            if (subscription.customer) {
              return subscription;
            }
          });

          if (!_.isEmpty(subscription)) {
            vm.card = subscription[0].card;
            vm.paymentMethod = subscription[0].customer.payment_method;
            vm.billingInfo = subscription[0].customer.billing_address;
            vm.chargeBeeCustomerId = subscription[0].customer.id;
            fetchTransaction(vm.chargeBeeCustomerId);
          }
        },
        function(error) {
          vm.loading.subscription = false;
          toaster.pop('error', error.data.message || error.statusText);
        }
      );
    }

    function fetchCustomerDetail() {
      OrganizationProfileService.fetchOrganization(customerId).then(
        function(response) {
          vm.loading.subscription = false;
          vm.loading.transaction = false;
          var organizationSubscriptionUsed = response.data.data.subscriptions;

          for (var sub in vm.subscription) {
            vm.subscription[sub].usedSubscription = _.filter(
              organizationSubscriptionUsed,
              function(subs) {
                return (
                  subs.orderNumber === vm.subscription[sub].subscription.id
                );
              }
            )[0].subscriptionBlocks[0].discovererRegistrations.length;
          }
        },
        function(error) {
          vm.loading.subscription = false;
          vm.loading.transaction = false;
          toaster.pop('error', error.data.message || error.statusText);
        }
      );
    }

    function fetchTransaction(customerId) {
      vm.loading.transaction = true;
      BillingService.fetchTransaction(customerId).then(
        function(result) {
          vm.transaction = result.data.data.list;
          vm.loading.transaction = false;
        },
        function(error) {
          vm.loading.transaction = false;
          toaster.pop('error', error.data.message || error.statusText);
        }
      );
    }

    function payNow(subscription) {
      BillingService.payNow(subscription).then(
        function(result) {
          var url = result.data.data.hosted_page.url;

          location.href = url;
        },
        function(error) {
          vm.loading.signUp = false;
          toaster.pop('error', error.data.message || error.statusText);
        }
      );
    }

    function showBillingInfo(subscription) {
      vm.selectedSubscription = subscription.subscription;
      vm.billingInfo = subscription.customer.billing_address;
      vm.card = subscription.card;
    }

    function updatePaymentMethod() {
      if (_.isNull(vm.chargeBeeCustomerId)) {
        return;
      }
      BillingService.updatePaymentMethod(vm.chargeBeeCustomerId).then(
        function(result) {
          var url = result.data.data.hosted_page.url;

          location.href = url;
        },
        function(error) {
          vm.loading.signUp = false;
          toaster.pop('error', error.data.message || error.statusText);
        }
      );
    }

    function fetchUserList() {
      UserInvitationService.fetchUsersList(customerId).then(
        function(result) {
          vm.billingContactUsers = _.filter(result.data.data, function(user) {
            return user.isBillingContact;
          });
          vm.technicalContactUsers = _.filter(result.data.data, function(user) {
            return user.isTechnicalContact;
          });
        },
        function(error) {
          toaster.pop('error', error.data.message || error.statusText);
        }
      );
    }
  }
})();
