(function() {
  'use strict';
  angular
    .module('app.shared')
    .controller('MappedClustersController', MappedClustersController);

  MappedClustersController.$inject = [
    'APP_CONSTANTS',
    'selectedItem',
    'programType',
    'toaster',
    '$uibModalInstance',
    'isPublished',
    'ProductService',
    'MappedClustersService',
    '$scope'
  ];

  function MappedClustersController(
    APP_CONSTANTS,
    selectedItem,
    programType,
    toaster,
    $uibModalInstance,
    isPublished,
    ProductService,
    MappedClustersService,
    $scope
  ) {
    var vm = this;

    vm.loading = {};
    vm.isOpen = true;
    vm.productId = ProductService.getProductId();
    vm.selectedItem = selectedItem;
    vm.selectedItemName = _.get(vm.selectedItem, 'variations.en.name', '');
    vm.dismissModal = dismissModal;
    vm.mappedClusterList = [];
    vm.mappedSubClusterList = [];
    vm.fetchedClusters = [];
    vm.programType = programType;
    vm.programTypeKey =
      programType === APP_CONSTANTS.instructionalProgram
        ? 'instructionalPrograms'
        : 'occupations';
    vm.isPublished = isPublished;
    vm.subClustersOfSelectedCluster = [];
    vm.getClusterName = getClusterName;
    vm.getClusterSetName = getClusterSetName;
    vm.getClusters = getClusters;
    vm.getSubClusters = getSubClusters;
    vm.saveMappedClusters = saveMappedClusters;
    vm.check = check;

    fetchAllClusters();

    function fetchAllClusters() {
      vm.loading.fetchingClusters = true;

      MappedClustersService.fetchAllClusters(vm.productId)
        .then(function(result) {
          var fetchedClusters = Object.keys(result.data.cluster).map(function(
            clusterSet
          ) {
            result.data.cluster[clusterSet].clusters.forEach(function(cluster) {
              cluster.subClusters &&
                cluster.subClusters.length &&
                cluster.subClusters.forEach(function(subCluster) {
                  if (
                    vm.selectedItem.subClusterId &&
                    vm.selectedItem.subClusterId.length &&
                    vm.selectedItem.subClusterId.some(function(
                      mappedSubClusterId
                    ) {
                      return mappedSubClusterId === subCluster.id;
                    })
                  ) {
                    subCluster.parentClusterId = cluster.id;
                    subCluster.parentClusterName = vm.getClusterName(cluster);
                    vm.mappedSubClusterList.push(subCluster);
                  }
                });
              if (
                vm.selectedItem.clusterId &&
                vm.selectedItem.clusterId.length &&
                vm.selectedItem.clusterId.some(function(mappedClusterId) {
                  return mappedClusterId === cluster.id;
                })
              ) {
                vm.mappedClusterList.push(cluster);
              }
            });
            var key = clusterSet;
            var obj = {};

            obj[key] = result.data.cluster[clusterSet];

            return obj;
          });

          fetchedClusters.map(function(value) {
            vm.fetchedClusters.push(value);
          });
          vm.loading.fetchingClusters = false;
        })
        .then(function() {
          vm.getSubClusters();
        })
        .catch(function(error) {
          vm.loading.fetchingClusters = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function getClusterName(cluster) {
      return _.get(cluster, 'variations.en.name', '');
    }

    function getClusterSetName(clusterSet) {
      return _.get(
        clusterSet[Object.keys(clusterSet)[0]],
        'variations.en.title',
        ''
      );
    }

    function getClusters(clusterSet) {
      return clusterSet[Object.keys(clusterSet)[0]].clusters;
    }

    function getSubClusters() {
      vm.subClustersOfSelectedCluster = [];
      vm.mappedClusterList.length &&
        vm.mappedClusterList.forEach(function(mappedCluster) {
          mappedCluster.subClusters &&
            mappedCluster.subClusters.length &&
            mappedCluster.subClusters.forEach(function(subCluster) {
              subCluster.parentClusterId = mappedCluster.id;
              subCluster.parentClusterName = vm.getClusterName(mappedCluster);
              vm.subClustersOfSelectedCluster.push(subCluster);
            });
        });
    }

    function check() {
      vm.mappedSubClusterList = vm.mappedSubClusterList.filter(function(
        mappedSubCluster
      ) {
        return vm.mappedClusterList.some(function(mappedCluster) {
          return mappedCluster.id === mappedSubCluster.parentClusterId;
        });
      });

      vm.getSubClusters();
    }

    function saveMappedClusters() {
      var mappedClusterIds = vm.mappedClusterList.map(function(mappedCluster) {
        return mappedCluster.id;
      });
      var mappedSubClusterIds = vm.mappedSubClusterList.map(function(
        mappedSubCluster
      ) {
        return mappedSubCluster.id;
      });

      if (vm.programType === APP_CONSTANTS.instructionalProgram) {
        $scope.$parent.vm.updateUnpublishedMajors(vm.selectedItem.id);
        $scope.$parent.vm.updateMappedClustersInMajor(
          vm.selectedItem.id,
          mappedClusterIds,
          mappedSubClusterIds
        );
      } else {
        $scope.$parent.vm.updateUnpublishedCareers(vm.selectedItem.id);
        $scope.$parent.vm.updateMappedClustersInCareer(
          vm.selectedItem.id,
          mappedClusterIds,
          mappedSubClusterIds
        );
      }

      dismissModal();
    }

    function dismissModal() {
      $uibModalInstance.dismiss();
    }
  }
})();
