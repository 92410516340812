(() => {
  'use strict';

  angular.module('app.user').controller('SsoController', [
    '$stateParams',
    '$timeout',
    '$window',
    'APP_CONSTANTS',
    'toaster',
    'SsoService',
    SsoController
  ]);

  function SsoController(
    $stateParams,
    $timeout,
    $window,
    APP_CONSTANTS,
    toaster,
    SsoService
  ) {
    const vm = this;
    vm.loading = false;
    vm.companyDomain = $stateParams.domain || '';
    vm.email = '';
    vm.regex = {
      email: APP_CONSTANTS.REGEX.EMAIL,
    };


    $timeout(() => {
      if ($stateParams.domain) {
        vm.submitDomain();
      } else {
        $('#inputDomain').focus();
      }
    }, 100);

    vm.submit = (form) => {
      if (!form.$valid) return;

      if (form.email && vm.email) {
        return vm.submitEmail();
      }
    };

    vm.submitEmail = () => {
      $('.form-control').blur();
      vm.loading = true;

      SsoService.getEmail(vm.email)
        .then((result) => {
          const authUrl = result.data?.authorizationUrl || '';
          if (!authUrl) {
            toaster.pop('error', 'Email not found');
            return;
          }

          toaster.pop('success', 'Email found');
          $window.open(authUrl, '_self');
        })
        .catch((error) => {
          toaster.pop('error', error.data?.message || error.statusText);
        })
        .finally(() => {
          vm.loading = false;
        });
    };

    vm.submitDomain = () => {
      $('#inputDomain').blur();
      vm.loading = true;

      SsoService.getDomain(vm.companyDomain)
        .then((result) => {
          const authUrl = result.data?.authorizationUrl || '';
          if (!authUrl) {
            toaster.pop('error', 'SSO domain not found');
            return;
          }

          toaster.pop('success', 'SSO domain found');
          $window.open(authUrl, '_self');
        })
        .catch((error) => {
          toaster.pop('error', error.data?.message || error.statusText);
        })
        .finally(() => {
          vm.loading = false;
        });
    };
  }
})();