/**
 * Created by Romit Amgai<romitamgai@lftechnology.com> on 4/18/17.
 */

(function () {
  'use strict';
  angular.module('app.user').controller('AddCustomerConfirmController', AddCustomerConfirmController);

  AddCustomerConfirmController.$inject = [
    '$scope',
    'toaster',
    'APP_CONSTANTS',
    'PaymentInfoService',
    'RegistrationService'
  ];

  function AddCustomerConfirmController(
    $scope,
    toaster,
    APP_CONSTANTS,
    PaymentInfoService,
    RegistrationService
  ) {
    var vm = this;

    vm.constants = {
      productLocales: APP_CONSTANTS.productLocales,
      decimalFixedValue: APP_CONSTANTS.decimalFixedValue,
    };
    vm.confirmOrder = confirmOrder;

    function confirmOrder() {
      if (_.isEmpty($scope.regController.subscription)) {
        return showError(APP_CONSTANTS.MESSAGE.INVALID_SUBSCRIPTION);
      }
      if (RegistrationService.invalidAccountInfo()) {
        return showError(APP_CONSTANTS.MESSAGE.INVALID_ACCOUNT_INFORMATION);
      }

      vm.loading = true;

      PaymentInfoService.addCustomer(
        RegistrationService.getAccountInfo(),
        $scope.regController.subscription,
      )
        .then(function (res) {
          vm.loading = false;
          toaster.clear('confirm');

          $scope.$parent.closeModal();
          toaster.pop('success', 'Customer created successfully!');
        })
        .catch(function (err) {
          vm.loading = false;

          createSubscriptionError(err);
        });
    }

    function createSubscriptionError(err) {
      if (_.get(err, 'data.code') == 400) {
        let message = 'Invalid Form Details';
        let detail = _.map(err.data.details, 'message').join();
        if (detail) message += ': ' + detail;
        showError(message);
      }
      else {
        showError(err.data.message || err.statusText);
      }

      document.getElementById('confirm').scrollIntoView(true);
    }

    function showError(message) {
      toaster.clear('confirm');
      toaster.pop({
        type: 'error',
        body: message,
        toasterId: 'confirm'
      });
    }
  }
})();
