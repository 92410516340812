(function() {
  'use strict';

  angular
    .module('careerKeyCentral')
    .factory('tokenInterceptor', tokenInterceptor);

  tokenInterceptor.$inject = [
    '$q',
    '$injector',
    'SessionService',
    'APP_CONSTANTS'
  ];

  function tokenInterceptor($q, $injector, SessionService, APP_CONSTANTS) {
    var self = this;
    var accessToken;

    self.request = request;
    self.requestError = requestError;
    self.responseError = responseError;

    function request(config) {
      accessToken = SessionService.getUser()
        ? SessionService.getAccessToken()
        : null;

      if (accessToken) {
        config.headers = config.headers || {};
        config.headers.Authorization = 'Bearer ' + accessToken;
      }

      return config;
    }

    function requestError(rejection) {
      return $q.reject(rejection);
    }

    function responseError(rejection) {
      var deferred = $q.defer();

      if (rejection.status == APP_CONSTANTS.unAuthorized) {
        // Circular dependency
        var AuthenticationService = $injector.get('AuthenticationService');

        AuthenticationService.handleSession(rejection)
          .then(function(response) {
            deferred.resolve(response);
          })
          .catch(function(error) {
            deferred.reject(error);
          });
      } else {
        deferred.reject(rejection);
      }

      return deferred.promise;
    }

    return self;
  }
})();
