(function() {
  'use strict';

  angular
    .module('app.customerAccount')
    .controller('AddNextPlanController', AddNextPlanController);

  AddNextPlanController.$inject = [
    '$uibModal',
    '$state',
    'APP_CONSTANTS',
    'toaster',
    '$uibModalInstance',
    'SessionService',
    'RegistrationService',
    'NewSubscriptionService',
    'ngDialog',
    'data',
    'NewBillingService',
    'BillingInfoService'
  ];
  function AddNextPlanController(
    $uibModal,
    $state,
    APP_CONSTANTS,
    toaster,
    $uibModalInstance,
    SessionService,
    RegistrationService,
    NewSubscriptionService,
    ngDialog,
    data,
    NewBillingService,
    BillingInfoService
  ) {
    var vm = this;

    var currentUser = SessionService.getUser();

    vm.loading = {};
    vm.selectedPlan = {};
    vm.selectedPlanType = '';
    vm.isSchoolTypeEnterprise = false;
    vm.noPaymentOrBillingDetail = false;
    vm.handlePlanSelect = handlePlanSelect;
    vm.decimalFixedValue = APP_CONSTANTS.decimalFixedValue;

    vm.cancel = cancel;
    vm.submit = submit;
    vm.selectPlanType = selectPlanType;
    vm.calculatePerUserPrice = calculatePerUserPrice;

    vm.constants = {
      heading: APP_CONSTANTS.LABEL.SELECT_SUBSCRIPTION,
      noPaymentOrBillingDetail: APP_CONSTANTS.noPaymentOrBillingDetail
    };

    init();

    function init() {
      if (_.isEmpty(data.billingDetail || _.isEmpty(data.paymentDetail)))
        vm.noPaymentOrBillingDetail = true;
      else {
        fetchPlans();
        vm.billingDetail = data.billingDetail;
        vm.autoCollection = data.billingDetail.autoCollection;
      }
    }

    function fetchPlans() {
      vm.loading.plans = true;
      if (
        _.isEmpty(data.subscription) ||
        data.subscription.type.toLowerCase() !==
          APP_CONSTANTS.SUBSCRIPTION_TYPE.AUTO_RENEWAL
      ) {
        closePopup();
        vm.loading.plans = false;
        toaster.pop(
          'error',
          APP_CONSTANTS.MESSAGE.selectAutorenwalSubscription
        );

        return;
      }

      vm.selectedSubscription = data.subscription;

      var params = {
        cf_language:
          (currentUser.locale &&
            currentUser.locale.language &&
            currentUser.cf_language_id) ||
          'en',
        cf_country:
          (currentUser.locale &&
            currentUser.locale.country &&
            currentUser.cf_country_id) ||
          'US'
      };

      NewBillingService.getCustomerPlans(params)
        .then(function(planDetails) {
          if (
            planDetails.customer_type === APP_CONSTANTS.CUSTOMER_TYPE.ENTERPRISE
          )
            vm.isSchoolTypeEnterprise = true;

          var plans = planDetails.plans;

          vm.allPrepaidPlans = plans.filter(_checkPrepaidPlans);
          vm.allAutoRenewalPlans = plans.filter(_checkAutoRenewalPlans);
          vm.adult = {
            prepaidPlans: vm.allPrepaidPlans.filter(_getAdultPlan),
            autoRenewalPlans: vm.allAutoRenewalPlans.filter(_getAdultPlan)
          };
          vm.higher = {
            prepaidPlans: vm.allPrepaidPlans.filter(_getHigherPlan),
            autoRenewalPlans: vm.allAutoRenewalPlans.filter(_getHigherPlan)
          };
          vm.secondary = {
            prepaidPlans: vm.allPrepaidPlans.filter(_getSecondaryPlan),
            autoRenewalPlans: vm.allAutoRenewalPlans.filter(_getSecondaryPlan)
          };

          defaultSelectAPlan(plans);
        })
        .catch(function(err) {
          toaster.pop({
            type: 'error',
            body: err.data.message || err.statusText
          });

          vm.loading.plans = false;
        });
    }

    function calculatePerUserPrice(plan) {
      if (plan.period_unit === APP_CONSTANTS.AUTO_RENEWAL_TYPE.MONTHLY)
        return (parseFloat(plan.price) * 12) / plan.cf_quantity;
      else return parseFloat(plan.price) / plan.cf_quantity;
    }

    function defaultSelectAPlan(plans) {
      var selectedPlan = plans.filter(function(plan) {
        return plan.plan.id === vm.selectedSubscription.planId;
      })[0].plan;

      if (!_.isEmpty(selectedPlan)) {
        handlePlanSelect(selectedPlan);
        vm.selectedPlan.planId = vm.selectedSubscription.planId;
        vm.autoRenewalType = selectedPlan.period_unit.toLowerCase();
        vm.selectedPlanType = selectedPlan.cf_audience.toLowerCase();
        selectPlanType();

        return;
      }

      vm.autoRenewalType = APP_CONSTANTS.AUTO_RENEWAL_TYPE.YEARLY;
      vm.prepaidPlans = vm.isSchoolTypeEnterprise
        ? vm.higher.prepaidPlans
        : vm.secondary.prepaidPlans;
      vm.autoRenewalPlans = vm.isSchoolTypeEnterprise
        ? vm.higher.autoRenewalPlans
        : vm.secondary.autoRenewalPlans;
      vm.selectedPlanType = vm.isSchoolTypeEnterprise
        ? APP_CONSTANTS.AUDIENCE.HIGHER
        : APP_CONSTANTS.AUDIENCE.SECONDARY;
    }

    function _getAdultPlan(plan) {
      return (
        plan.plan.cf_audience.toLowerCase() === APP_CONSTANTS.AUDIENCE.ADULT
      );
    }

    function _getHigherPlan(plan) {
      return (
        plan.plan.cf_audience.toLowerCase() === APP_CONSTANTS.AUDIENCE.HIGHER
      );
    }

    function _getSecondaryPlan(plan) {
      return (
        plan.plan.cf_audience.toLowerCase() === APP_CONSTANTS.AUDIENCE.SECONDARY
      );
    }

    function _checkPrepaidPlans(plan) {
      return (
        plan.plan.cf_subscription_type.toLowerCase() ===
        APP_CONSTANTS.SUBSCRIPTION_TYPE.PREPAID
      );
    }

    function _checkAutoRenewalPlans(plan) {
      return (
        plan.plan.cf_subscription_type.toLowerCase() ===
        APP_CONSTANTS.SUBSCRIPTION_TYPE.AUTO_RENEWAL
      );
    }

    function selectPlanType() {
      switch (vm.selectedPlanType) {
        case APP_CONSTANTS.AUDIENCE.ADULT:
          vm.prepaidPlans = vm.adult.prepaidPlans;
          vm.autoRenewalPlans = vm.adult.autoRenewalPlans;
          break;

        case APP_CONSTANTS.AUDIENCE.HIGHER:
          vm.prepaidPlans = vm.higher.prepaidPlans;
          vm.autoRenewalPlans = vm.higher.autoRenewalPlans;
          break;

        case APP_CONSTANTS.AUDIENCE.SECONDARY:
          vm.prepaidPlans = vm.secondary.prepaidPlans;
          vm.autoRenewalPlans = vm.secondary.autoRenewalPlans;
          break;

        default:
          vm.prepaidPlans = vm.higher.prepaidPlans;
          vm.autoRenewalPlans = vm.higher.autoRenewalPlans;
      }
    }

    function handlePlanSelect(plan) {
      vm.loading.plans = true;
      vm.plan = plan;
      var customerInfo = {
        taxability: plan.taxable
          ? APP_CONSTANTS.TAXABILITY.TAXABLE
          : APP_CONSTANTS.TAXABILITY.EXEMPT
      };

      vm.billingInfo = {};

      vm.billingInfo.country = {
        countryCode: vm.billingDetail.country,
        name: vm.billingDetail.state_code
      };
      vm.billingInfo.city = vm.billingDetail.city;
      vm.billingInfo.zipCode = vm.billingDetail.zip;
      vm.billingInfo.addressLine1 = vm.billingDetail.line1;
      vm.billingInfo.lastName = vm.billingDetail.last_name;
      vm.billingInfo.firstName = vm.billingDetail.first_name;

      if (plan.cf_subscription_type === APP_CONSTANTS.SUBSCRIPTION_TYPE.PREPAID)
        vm.plan.paymentType = APP_CONSTANTS.PAYMENT_PERIOD.PREPAID;

      if (
        plan.cf_subscription_type ===
        APP_CONSTANTS.SUBSCRIPTION_TYPE.AUTO_RENEWAL
      )
        if (plan.period_unit === APP_CONSTANTS.AUTO_RENEWAL_TYPE.YEARLY)
          vm.plan.paymentType =
            APP_CONSTANTS.PAYMENT_PERIOD.AUTO_RENEWAL_ANNUAL;
        else
          vm.plan.paymentType =
            APP_CONSTANTS.PAYMENT_PERIOD.AUTO_RENEWAL_MONTHLY;

      BillingInfoService.getTaxEstimate(plan.id, vm.billingInfo, customerInfo)
        .then(function(result) {
          vm.loading.plans = false;
          vm.totalAmount = result.data.data.invoice_estimate.total;
          vm.taxAmount =
            result.data.data.invoice_estimate.line_items[0].tax_amount;
        })
        .catch(function(err) {
          vm.loading.plans = false;

          if (err.data.error && err.data.error.code === 400)
            toaster.pop({
              type: 'error',
              body: APP_CONSTANTS.MESSAGE.INVALID_BILLING_INFORMATION,
              toasterId: 'billing'
            });
          else
            toaster.pop({
              type: 'error',
              body: err.data.message || err.statusText,
              toasterId: 'billing'
            });
        });
    }

    function submit() {
      vm.invalid = false;
      vm.planNotSelected = false;

      if (_.isEmpty(vm.selectedPlan)) {
        vm.invalid = true;

        return;
      }
      if (_.isEmpty(vm.selectedPlan.planId)) {
        vm.planNotSelected = true;

        return;
      }
      vm.selectedPlan.subscriptionId = vm.selectedSubscription.id;

      ngDialog
        .openConfirm({
          template: 'addNextPlanConfirmDialog'
        })
        .then(
          function() {
            vm.loading.plans = true;
            addNextPlan();
          },
          function() {
            ngDialog.close();
          }
        );
    }

    function addNextPlan() {
      NewSubscriptionService.addNextPlan(vm.selectedPlan)
        .then(function() {
          vm.loading.plans = false;
          closePopup();
          toaster.pop(
            'success',
            APP_CONSTANTS.MESSAGE.nextPlanAddedSuccesfully
          );
        })
        .catch(function(error) {
          vm.loading.plans = false;
          toaster.pop({
            type: 'error',
            body: error.data.message || error.statusText,
            toasterId: 'addNextPlan'
          });
        });
    }

    function closePopup() {
      $uibModalInstance.close('closed');
    }

    function cancel() {
      $uibModalInstance.dismiss('cancel');
    }
  }
})();
