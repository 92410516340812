(function() {
  'use strict';

  angular
    .module('careerKeyCentral')

    .directive('phonenumberDirective', [
      '$filter',
      function($filter) {
        function link(scope) {
          scope.$watch('phonenumberModel', function(value) {
            value = String(value);
            var number = value.replace(/[^0-9]+/g, '');
            scope.phonenumberModel = $filter('phonenumber')(number);
          });
        }

        return {
          link: link,
          restrict: 'A',
          scope: {
            phonenumberModel: '=ngModel'
          }
        };
      }
    ])

    .filter('phonenumber', function() {
      return function(number) {
        if (!number) {
          return '';
        }
        number = String(number);
        var formattedNumber = number;
        var countryCode = number[0] == '1' ? '1 ' : '';
        number = number[0] == '1' ? number.slice(1) : number;
        var area = number.substring(0, 3);
        var front = number.substring(3, 6);
        var end = number.substring(6, 10);

        if (front) {
          formattedNumber = countryCode + '(' + area + ') ' + front;
        }
        if (end) {
          formattedNumber += '-' + end;
        }
        return formattedNumber;
      };
    });
})();
