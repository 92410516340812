/**
 * Created by Romit Amgai<romitamgai@lftechnology.com> on 4/18/17.
 */
/* eslint-disable */
(function() {
  'use strict';
  angular
    .module('app.user')
    .factory('SubscriptionListService', SubscriptionListService);

  SubscriptionListService.$inject = [
    'localStorageService',
    'APP_CONSTANTS',
    'ApiCaller',
    '$filter',
    '$q'
  ];

  function SubscriptionListService(
    localStorageService,
    APP_CONSTANTS,
    ApiCaller,
    $filter,
    $q
  ) {
    var self = this;

    return self;
  }
})();
/* eslint-enable */
