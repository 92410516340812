(function() {
  'use strict';
  angular
    .module('app.shared')
    .controller('PublishCareerController', PublishCareerController);

  PublishCareerController.$inject = [
    '$scope',
    '$rootScope',
    '$uibModalInstance',
    'selectedCareer',
    'selectedWorkgroupId',
    'PublishCareerService'
  ];

  function PublishCareerController(
    $scope,
    $rootScope,
    $uibModalInstance,
    selectedCareer,
    selectedWorkgroupId
  ) {
    var vm = this;

    vm.loading = {};
    vm.selectedDate = '';
    vm.publish = publish;
    vm.isPublishNowChecked = false;
    vm.checkPublishNow = checkPublishNow;
    vm.dismissModal = dismissModal;
    vm.publishDatePickerIsOpen = false;

    vm.dateOptions = {
      minDate: $rootScope
        .moment(new Date())
        .add(1, 'day')
        .format()
    };

    function checkPublishNow() {
      if (vm.isPublishNowChecked === true) {
        vm.selectedDate = ' ';
      }
    }

    function publish() {
      if (!vm.isPublishNowChecked) {
        $scope.$parent.vm.setCareerPublishDate(
          selectedWorkgroupId,
          selectedCareer.id,
          vm.selectedDate
        );
      } else {
        $scope.$parent.vm.publishCareer(selectedCareer.id);
      }

      dismissModal();
    }

    function dismissModal() {
      $uibModalInstance.dismiss();
    }
  }
})();
