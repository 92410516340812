(function () {
  'use strict';

  angular
    .module('app.shared')
    .controller('AdminEditResellerAccountInformationModalController', AdminEditResellerAccountInformationModalController);

  AdminEditResellerAccountInformationModalController.$inject = [
    '$uibModalInstance',
    'AdminResellerBillingService',
    'RegistrationService',
    'toaster',
    'APP_CONSTANTS',
    '$filter',
    'data'
  ];

  function AdminEditResellerAccountInformationModalController(
    $uibModalInstance,
    AdminResellerBillingService,
    RegistrationService,
    toaster,
    APP_CONSTANTS,
    $filter,
    data
  ) {
    var vm = this;

    vm.loading = {};
    vm.accountInfo = {};
    vm.originalAccountInfo = {};
    vm.selectedPlanIds = [];
    vm.fetchStates = fetchStates;
    vm.changeTab = changeTab;
    vm.updateAccountInformation = updateAccountInformation;
    vm.decimalFixedValue = APP_CONSTANTS.decimalFixedValue;
    vm.calculatePerUserPrice = calculatePerUserPrice;

    init();

    function init() {
      angular.copy(data.accountInfo, vm.accountInfo);
      angular.copy(data.accountInfo, vm.originalAccountInfo);
      angular.copy(data.accountInfo.plans, vm.selectedPlanIds);
      fetchCountries();
      fetchPlans();
    }

    function fetchCountries(name) {
      vm.loading.countries = true;

      RegistrationService.getCountries(name)
        .then(function (result) {
          vm.countries = result.data.data.map(function (country) {
            return {
              id: country.countryCode,
              name: country.name
            }
          });
          fetchStates();
        })
        .catch(function (error) {
          toaster.pop('error', error.data.message || error.statusText);
        })
        .finally(function () {
          vm.loading.countries = false;
        });
    }

    function fetchStates() {
      vm.loading.states = false;

      RegistrationService.getStates(vm.accountInfo.country.id)
        .then(function (result) {
          vm.states = result.data.data.states;
          var selectedState = vm.states.find(function (state) {
            return state.code === vm.originalAccountInfo.state_code;
          });
          vm.accountInfo.state_code = selectedState ? selectedState.code : null;
        })
        .catch(function (error) {
          toaster.pop('error', error.data.message || error.statusText);
        })
        .finally(function () {
          vm.loading.states = false;
        });
    }

    function fetchPlans() {
      vm.loading.plans = true;

      var params = {
        cf_language: data.accountInfo.language.id || 'en',
        cf_country: data.accountInfo.country.id || 'US',
        cf_customer_type: data.accountInfo.customer_type
      };

      AdminResellerBillingService.getResellerPlans(params)
        .then(function (plans) {
          if (plans.length === 0) {
            vm.noAvailablePlans = true;
            return;
          }

          var allPrepaidPlans = $filter('PlanFilter')(plans, APP_CONSTANTS.SUBSCRIPTION_TYPE.PREPAID);
          var allAutoRenewalPlans = $filter('PlanFilter')(plans, APP_CONSTANTS.SUBSCRIPTION_TYPE.AUTO_RENEWAL);

          vm.allCKDPrepaidPlans = $filter('PlanFilter')(allPrepaidPlans, APP_CONSTANTS.CKD);
          vm.allCDPPrepaidPlans = $filter('PlanFilter')(allPrepaidPlans, APP_CONSTANTS.CDP);

          vm.allCKDAutoRenewalPlans = $filter('PlanFilter')(allAutoRenewalPlans, APP_CONSTANTS.CKD);
          vm.allCDPAutoRenewalPlans = $filter('PlanFilter')(allAutoRenewalPlans, APP_CONSTANTS.CDP);

          vm.hasCKDPlans = vm.allCKDPrepaidPlans.length || vm.allCKDAutoRenewalPlans.length;
          vm.hasCDPPlans = vm.allCDPPrepaidPlans.length || vm.allCDPAutoRenewalPlans.length;

          changeTab(vm.hasCKDPlans ? APP_CONSTANTS.CKD : APP_CONSTANTS.CDP);
        })
        .catch(function (error) {
          toaster.pop('error', error.data.message || error.statusText);
        })
        .finally(function () {
          vm.loading.plans = false;
        });
    }

    function changeTab(tabName) {
      if (vm.activeTab === tabName) return;

      vm.activeTab = tabName;
      vm.autoRenewalType = APP_CONSTANTS.AUTO_RENEWAL_TYPE.YEARLY;
      vm.prepaidPlans = vm.activeTab === APP_CONSTANTS.CDP ? vm.allCDPPrepaidPlans : vm.allCKDPrepaidPlans;
      vm.autoRenewalPlans = vm.activeTab === APP_CONSTANTS.CDP ? vm.allCDPAutoRenewalPlans : vm.allCKDAutoRenewalPlans;
    }

    function calculatePerUserPrice(plan) {
      if (plan.period_unit === APP_CONSTANTS.AUTO_RENEWAL_TYPE.MONTHLY)
        return (parseFloat(plan.price) * 12) / plan.cf_quantity;
      else return parseFloat(plan.price) / plan.cf_quantity;
    }

    function updateAccountInformation(form) {
      vm.form = form;

      if (vm.accountInfo.selfService && !vm.selectedPlanIds.length) {
        vm.form.$invalid = true;
      }
      if (vm.form.$invalid) return;

      var newAccountInfo = {
        city: vm.form.city.$modelValue,
        zip: vm.form.zipCode.$modelValue,
        state: vm.accountInfo.state_code,
        line1: vm.form.addressLine1.$modelValue,
        line2: vm.form.addressLine2.$modelValue,
        last_name: vm.form.lastName.$modelValue,
        first_name: vm.form.firstName.$modelValue,
        country: vm.accountInfo.country.id,
        selfService: vm.accountInfo.selfService,
        chargebeeEmailNotification: vm.accountInfo.chargebeeEmailNotification,
        plans: vm.selectedPlanIds
      };

      vm.loading.accountInfo = true;

      AdminResellerBillingService.updateAccountInformation(newAccountInfo, data.customerId)
        .then(function (response) {
          vm.loading.accountInfo = false;
          vm.accountInfo = _.get(response, 'data.data', {});
          toaster.pop(
            'success',
            APP_CONSTANTS.MESSAGE.accountInfoUpdateSuccesss
          );
          vm.ok();
        })
        .catch(function (err) {
          vm.loading.accountInfo = false;

          if (err.data && err.data.error && err.data.error.code === 400)
            toaster.pop({
              type: 'error',
              body: 'Invalid Form Details',
              toasterId: 'updateAccount'
            });
          else
            toaster.pop({
              type: 'error',
              body: err.data.message || err.statusText,
              toasterId: 'updateAccount'
            });
        });
    }

    vm.ok = function () {
      $uibModalInstance.close('closed');
    };

    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  }
})();