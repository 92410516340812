(function() {
  'use strict';

  angular.module('careerKeyCentral').directive('accessLevel', accessLevel);

  accessLevel.$inject = ['$compile', 'SessionService'];

  function accessLevel($compile, SessionService) {
    return {
      restrict: 'A',
      link: function($scope, element, attrs) {
        var rolePermissions = attrs.accessLevel.split(',');
        var accessLevelRoles = SessionService.getRoles().filter(function(
          roles
        ) {
          return rolePermissions.indexOf(roles) > -1;
        });
        if (accessLevelRoles.length === 0) element.hide();
        else element.show();
      }
    };
  }
})();
