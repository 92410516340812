(function() {
  'use strict';

  angular
    .module('app.mailbox')
    .controller('CDPMessageController', CDPMessageController);

  CDPMessageController.$inject = [
    '$stateParams',
    'APP_CONSTANTS',
    'CDPMessageService',
    'toaster',
    '$state',
    'SessionService',
    'GroupService',
    'SendEmailToAllService',
    'ngDialog'
  ];

  function CDPMessageController(
    $stateParams,
    APP_CONSTANTS,
    CDPMessageService,
    toaster,
    $state,
    SessionService,
    GroupService,
    SendEmailToAllService,
    ngDialog
  ) {
    var vm = this;

    vm.loading = {};

    vm.accessKey = '';
    vm.customMessage = {};
    var userLoginDetail = SessionService.getUser();
    vm.isSuperAdmin = userLoginDetail.roles.isSuperAdmin;
    vm.fetchDiscoverersBySponsoredOrg = fetchDiscoverersBySponsoredOrg;

    function fetchDiscoverersBySponsoredOrg(key) {
      if (key) {
        GroupService.fetchdiscoverer(
          key,
          userLoginDetail.sponsoredOrganizationId
        ).then(function(response) {
          vm.discoverers = response.data.data;
          vm.calculatedDiscoverer(vm.discoverers);
        });
      }
    }

    vm.fetchTemplates = function() {
      vm.template = $stateParams.template;
      if (!vm.template && $stateParams.messageType) {
        let letsConnectOption = _.find(APP_CONSTANTS.letsConnectOptions, {
          messageType: $stateParams.messageType,
          subscriptionType: APP_CONSTANTS.CDP,
        });
        if (!letsConnectOption) return;

        vm.template = letsConnectOption.template;
      }
      if (!vm.template) return;

      vm.loading.message = true;
      SendEmailToAllService.fetchEmailTemplates(vm.template).then(
        function(response) {
          vm.customMessage.subject = response.data.subject;
          vm.customMessage.message = response.data.message;
          vm.loading.message = false;
        },
        function(error) {
          vm.loading.message = false;
          toaster.pop('error', error.data.message || error.statusText);
        }
      );
    };

    vm.sendMessage = function () {
      if (!vm.message.$valid) return;
      if (!vm.customMessage.discoverer.length) {
        return toaster.pop('warning', APP_CONSTANTS.MESSAGE.messageEmailValidation);
      }

      vm.customMessage.messageType = $stateParams.messageType;

      CDPMessageService.validateEmail(vm.customMessage.discoverer).then(
        function () {
          vm.loading.message = true;
          CDPMessageService.sendMessage(vm.customMessage).then(
            function (response) {
              vm.loading.message = false;
              let deliveryStatus = response.data || {};

              if (!deliveryStatus.failure || !deliveryStatus.failure.length) {
                toaster.pop('success', APP_CONSTANTS.MESSAGE.messageSent);
                $state.go('app.mailbox.folder');
                return;
              }

              ngDialog.openConfirm({
                template: 'app/letsConnect/views/deliveryStatusPopup.html',
                data: deliveryStatus
              }).finally(function () {
                $state.go('app.mailbox.folder');
              });
            },
            function (error) {
              vm.loading.message = false;
              toaster.pop('error', error.data.message || error.statusText);
            }
          );
        },
        function (error) {
          ngDialog.openConfirm({
            template: 'app/letsConnect/views/invalidEmailPopup.html',
            data: {
              invalidEmail: error
            }
          });
        }
      );
    };

    vm.calculatedDiscoverer = function(discoverers) {
      vm.availableDiscoverers = _.map(discoverers, 'email');

      if (!_.isEmpty($stateParams.discoverers))
        vm.customMessage.discoverer = vm.availableDiscoverers;
    };

    vm.fetchTemplates();
    vm.calculatedDiscoverer($stateParams.discoverers);
  }
})();
