(function() {
  'use strict';
  angular
    .module('app.discovererProgress')
    .factory('discovererDetailsService', discovererDetailsService);

  discovererDetailsService.$inject = [
    'APP_CONSTANTS',
    'ApiCaller',
    '$q',
    '$filter',
    'SessionService'
  ];

  function discovererDetailsService(
    APP_CONSTANTS,
    ApiCaller,
    $q,
    $filter,
    SessionService
  ) {
    var self = this;

    self.addNote = addNote;
    self.shareResult = shareResult;
    self.filterLevel = filterLevel;
    self.getLocaleFile = getLocaleFile;
    // self.getARHeadersDescriptors = getARHeadersDescriptors;
    self.generateDiscovererProfilePdf = generateDiscovererProfilePdf;
    self.generateDiscovererDetailsApiGet = generateDiscovererDetailsApiGet;

    function getLocaleFile() {
      var currentUser = SessionService.getUser();
      var deferred = $q.defer();
      var country = (currentUser.country && currentUser.country.id) || 'US';
      var language = (currentUser.language && currentUser.language.id) || 'en';
      var url = 'app/common/resources/' + language + '-' + country + '.json';

      ApiCaller.apiGet(url)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function generateDiscovererDetailsApiGet(discovererId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.discovererDetail,
        APP_CONSTANTS.rootUrl,
        discovererId
      );

      ApiCaller.apiGet(url)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function generateDiscovererProfilePdf(discovererId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.discovererProfilePdf,
        APP_CONSTANTS.rootUrl,
        discovererId
      );

      ApiCaller.apiProfilePdfGet(url)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    // function getARHeadersDescriptors() {
    //   var deferred = $q.defer();
    //   var url = $filter('sprintf')(
    //     APP_CONSTANTS.resource,
    //     APP_CONSTANTS.rootUrl,
    //     APP_CONSTANTS.ARHeadersDescriptors
    //   );

    //   ApiCaller.apiGet(url)
    //     .then(function(result) {
    //       deferred.resolve(result);
    //     })
    //     .catch(function(error) {
    //       deferred.reject(error);
    //     });

    //   return deferred.promise;
    // }

    function filterLevel(level) {
      return level.substr(0, level.indexOf(' ')).toLowerCase();
    }

    function addNote(discovererId, note) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.discovererNote,
        APP_CONSTANTS.rootUrl,
        discovererId
      );

      ApiCaller.apiPost(url, { notes: note })
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    /* eslint-disable */
    function deleteNote(discovererId, noteId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.discoverSingleNote,
        APP_CONSTANTS.rootUrl,
        discovererId,
        noteId
      );

      ApiCaller.apiDelete(url)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }
    /* eslint-enable */

    function shareResult(discovererId, email) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.shareCKDResult,
        APP_CONSTANTS.rootUrl,
        discovererId
      );

      ApiCaller.apiPost(url, { email: email })
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    return self;
  }
})();
