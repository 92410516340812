(function() {
  'use strict';

  angular.module('app.shared').factory('GroupService', GroupService);

  GroupService.$inject = ['ApiCaller', 'APP_CONSTANTS', '$q', '$filter'];

  function GroupService(ApiCaller, APP_CONSTANTS, $q, $filter) {
    var self = this;

    self.editGroup = editGroup;
    self.deleteGroup = deleteGroup;
    self.createGroup = createGroup;
    self.fetchGroups = fetchGroups;
    self.fetchGroupById = fetchGroupById;
    self.fetchdiscoverer = fetchdiscoverer;
    self.createDiscoverers = createDiscoverers;
    self.fetchGroupsByUser = fetchGroupsByUser;
    self.assignUsersToGroup = assignUsersToGroup;
    self.removeDiscovererFromGroup = removeDiscovererFromGroup;
    self.fetchDiscoverersByGroupId = fetchDiscoverersByGroupId;
    self.fetchDiscoverersByCustomerId = fetchDiscoverersByCustomerId;
    self.createDiscoverersWithoutGroupId = createDiscoverersWithoutGroupId;

    function fetchdiscoverer(searchKey, sponsoredOrganizationId, eventCategory) {
      var searchByCategory = '';
      if (angular.isArray(eventCategory)) {
        searchByCategory = eventCategory.join();
      }
      if (angular.isString(eventCategory)) {
        searchByCategory = eventCategory;
      }

      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.searchOrganizationDiscoverers,
        APP_CONSTANTS.rootUrl,
        sponsoredOrganizationId,
        searchKey,
        searchByCategory || ''
      );

      ApiCaller.apiGet(url)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function createGroup(group) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.groupCollection,
        APP_CONSTANTS.rootUrl
      );

      ApiCaller.apiPost(url, group)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function fetchGroups() {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.groupCollection,
        APP_CONSTANTS.rootUrl
      );

      ApiCaller.apiGet(url)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function fetchGroupById(group) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.groupSingle,
        APP_CONSTANTS.rootUrl,
        group.id
      );

      ApiCaller.apiGet(url)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function fetchGroupsByUser(customerId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.fetchUserGroup,
        APP_CONSTANTS.rootUrl,
        customerId
      );

      ApiCaller.apiGet(url)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function createDiscoverers(discoverers) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.discoverersByGroupId,
        APP_CONSTANTS.rootUrl,
        discoverers.group.id
      );

      ApiCaller.apiPost(url, discoverers)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function createDiscoverersWithoutGroupId(discoverers) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.discoverersWithoutGroupId,
        APP_CONSTANTS.rootUrl
      );

      ApiCaller.apiPost(url, discoverers)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function fetchDiscoverersByGroupId(
      groupId,
      pageNumber,
      pageSize,
      sortBy,
      sortOrder,
      searchKey
    ) {
      var deferred = $q.defer();

      var parameters = jQuery.param({
        pageNumber: pageNumber,
        pageSize: pageSize,
        sortBy: sortBy,
        sortOrder: sortOrder,
        searchKey: searchKey
      });

      var url = $filter('sprintf')(
        APP_CONSTANTS.discoverersByGroupId,
        APP_CONSTANTS.rootUrl,
        groupId,
        parameters
      );

      ApiCaller.apiGet(url)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function fetchDiscoverersByCustomerId(customerId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.discoverersByCustomerId,
        APP_CONSTANTS.rootUrl,
        customerId
      );

      ApiCaller.apiGet(url)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function deleteGroup(groupId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.groupSingle,
        APP_CONSTANTS.rootUrl,
        groupId
      );

      ApiCaller.apiDelete(url)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function assignUsersToGroup(group, groupId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.groupSingle,
        APP_CONSTANTS.rootUrl,
        groupId
      );

      ApiCaller.apiPut(url, group).then(
        function(result) {
          deferred.resolve(result);
        },
        function(error) {
          deferred.reject(error);
        }
      );

      return deferred.promise;
    }

    function editGroup(group) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.groupSingle,
        APP_CONSTANTS.rootUrl,
        group.id
      );

      ApiCaller.apiPut(url, group)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function removeDiscovererFromGroup(discovererId, groupId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.discovererByGroupId,
        APP_CONSTANTS.rootUrl,
        groupId,
        discovererId
      );

      ApiCaller.apiPut(url)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    return self;
  }
})();
