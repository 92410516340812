/**
 * Created by Romit Amgai<romitamgai@lftechnology.com> on 5/04/17.
 */

(function() {
  'use strict';
  angular
    .module('app.user')
    .factory('BillingContactService', BillingContactService);

  BillingContactService.$inject = [
    'localStorageService',
    'APP_CONSTANTS',
    'ApiCaller',
    '$filter',
    '$q'
  ];

  function BillingContactService(
    localStorageService,
    APP_CONSTANTS,
    ApiCaller,
    $filter,
    $q
  ) {
    var self = this;

    self.registerAndInviteBilling = registerAndInviteBilling;

    function registerAndInviteBilling(accountInfo, billingContact, plan) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.signUpWithBilling,
        APP_CONSTANTS.rootUrl
      );
      var userPayload = {
        account: {},
        billing: {},
        plan: {}
      };

      angular.copy(accountInfo, userPayload.account);
      angular.copy(billingContact, userPayload.billing);

      userPayload.plan.id = plan.id;
      delete userPayload.account.purchaseType;
      userPayload.account.country = userPayload.account.country.name;

      ApiCaller.apiPost(url, userPayload).then(
        function(result) {
          deferred.resolve(result);
        },
        function(err) {
          deferred.reject(err);
        }
      );

      return deferred.promise;
    }

    return self;
  }
})();
