(function() {
  'use strict';

  angular
    .module('app.sponsoredOrganization')
    .factory('UserManagementService', UserManagementService);

  UserManagementService.$inject = [
    '$state',
    '$rootScope',
    'APP_CONSTANTS',
    'ApiCaller',
    '$window',
    '$stateParams',
    '$q',
    '$filter'
  ];

  function UserManagementService(
    $state,
    $rootScope,
    APP_CONSTANTS,
    ApiCaller,
    $window,
    $stateParams,
    $q,
    $filter
  ) {
    var vm = this;

    vm.selectedRole = [];
    vm.selectRole = selectRole;
    vm.sendInvite = sendInvite;
    vm.fetchUsersList = fetchUsersList;
    vm.grantOrRevokeUser = grantOrRevokeUser;
    vm.addB2BUser = addB2BUser;

    function addB2BUser(data) {
      const url = $filter('sprintf')(
        APP_CONSTANTS.addB2BUser,
        APP_CONSTANTS.rootUrl,
      );

      return ApiCaller.apiPost(url, data);
    }

    function fetchUsersList(sponsoredOrganizationId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.usersByCustomerId,
        APP_CONSTANTS.rootUrl,
        sponsoredOrganizationId
      );

      ApiCaller.apiGet(url).then(
        function(result) {
          deferred.resolve(result);
        },
        function(err) {
          deferred.reject(err);
        }
      );

      return deferred.promise;
    }

    function selectRole(role) {
      var selectedRole = _.findIndex(vm.selectedRole, {role: role.role});

      if (selectedRole > -1) {
        vm.selectedRole.splice(selectedRole, 1);
      } else {

        var roles = {
          role: role.role
        };

        vm.selectedRole.push(roles);
      }
    }

    function grantOrRevokeUser(user) {
      var deferred = $q.defer();
      var url = '';
      var originalStatus = user.status;

      if (user.status) {
        url = $filter('sprintf')(
          APP_CONSTANTS.userGrant,
          APP_CONSTANTS.rootUrl,
          user.id
        );
      } else {
        url = $filter('sprintf')(
          APP_CONSTANTS.userRevoke,
          APP_CONSTANTS.rootUrl,
          user.id
        );
      }

      ApiCaller.apiPost(url, {}).then(
        function(result) {
          user.status = !user.status;
          deferred.resolve(result);
        },
        function(err) {
          user.status = originalStatus;
          deferred.reject(err);
        }
      );

      return deferred.promise;
    }

    function sendInvite(user) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.sponsoredOrganizationUserInvitation,
        APP_CONSTANTS.rootUrl
      );

      ApiCaller.apiPost(url, user).then(
        function(result) {
          deferred.resolve(result);
        },
        function(err) {
          deferred.reject(err);
        }
      );

      return deferred.promise;
    }

    vm.fetchInvitedUsers = function (sponsoredOrganizationId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.sponsoredOrganizationUserInvitation,
        APP_CONSTANTS.rootUrl,
        sponsoredOrganizationId
      );

      ApiCaller.apiGet(url).then(
        function(result) {
          deferred.resolve(result);
        },
        function(err) {
          deferred.reject(err);
        }
      );

      return deferred.promise;
    };

    vm.deleteInvitedUser = function (invitedUserId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.sponsoredOrganizationUserInvitation,
        APP_CONSTANTS.rootUrl,
        invitedUserId
      );

      ApiCaller.apiDelete(url).then(
        function (result) {
          deferred.resolve(result);
        },
        function (err) {
          deferred.reject(err);
        }
      );

      return deferred.promise;
    };

    vm.resendInvitation = function (invitedUserId) {
      var url = $filter('sprintf')(
        APP_CONSTANTS.sponsoredOrganizationUserInvitation,
        APP_CONSTANTS.rootUrl,
        invitedUserId
      );

      return ApiCaller.apiPut(url);
    };

    return vm;
  }
})();
