(function() {
  'use strict';
  angular.module('app.shared').factory('BillingService', BillingService);

  BillingService.$inject = ['APP_CONSTANTS', 'ApiCaller', '$q', '$filter'];

  function BillingService(APP_CONSTANTS, ApiCaller, $q, $filter) {
    var self = this;

    self.payNow = payNow;
    self.fetchSubscription = fetchSubscription;
    self.fetchTransaction = fetchTransaction;
    self.updatePaymentMethod = updatePaymentMethod;

    function fetchSubscription() {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.subscriptionCollection,
        APP_CONSTANTS.rootUrl
      );

      ApiCaller.apiGet(url).then(
        function(result) {
          deferred.resolve(result);
        },
        function(err) {
          deferred.reject(err);
        }
      );

      return deferred.promise;
    }

    function fetchTransaction(customerId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.transactions,
        APP_CONSTANTS.rootUrl,
        customerId
      );

      ApiCaller.apiGet(url).then(
        function(result) {
          deferred.resolve(result);
        },
        function(err) {
          deferred.reject(err);
        }
      );

      return deferred.promise;
    }

    function payNow(subscription) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.generateCheckoutPage,
        APP_CONSTANTS.rootUrl
      );

      ApiCaller.apiPost(url, subscription.owner).then(
        function(result) {
          deferred.resolve(result);
        },
        function(err) {
          deferred.reject(err);
        }
      );

      return deferred.promise;
    }

    function updatePaymentMethod(customerId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.updatePaymentMethod,
        APP_CONSTANTS.rootUrl,
        customerId
      );

      ApiCaller.apiGet(url).then(
        function(result) {
          deferred.resolve(result);
        },
        function(err) {
          deferred.reject(err);
        }
      );

      return deferred.promise;
    }

    return self;
  }
})();
