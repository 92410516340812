(function() {
  'use strict';
  angular
    .module('app.shared')
    .controller('MappedCareersController', MappedCareersController);

  MappedCareersController.$inject = [
    'APP_CONSTANTS',
    'toaster',
    '$scope',
    '$uibModal',
    '$location',
    'isPublished',
    'selectedMajor',
    '$uibModalInstance',
    'WorkgroupService',
    'MajorsService',
    'CareersService',
    'ProductService'
  ];

  function MappedCareersController(
    APP_CONSTANTS,
    toaster,
    $scope,
    $uibModal,
    $location,
    isPublished,
    selectedMajor,
    $uibModalInstance,
    WorkgroupService,
    MajorsService,
    CareersService,
    ProductService
  ) {
    var vm = this;

    vm.loading = {};
    vm.isOpen = true;
    vm.workgroupCareers = [];
    vm.mappedCareerList = [];
    vm.isPublished = isPublished;
    vm.selectedMajor = selectedMajor;
    vm.dismissModal = dismissModal;
    vm.workgroupCareersArray = [];
    vm.saveMappedCareers = saveMappedCareers;
    vm.productId = ProductService.getProductId();
    vm.fetchWorkGroupCareers = fetchWorkGroupCareers;
    vm.fetchProductWorkgroups = fetchProductWorkgroups;
    vm.personalityTypes = APP_CONSTANTS.PERSONALITY_TYPES;
    vm.ptTypes = APP_CONSTANTS.PT_TYPES;
    vm.selectedMajorName = _.get(
      selectedMajor,
      'variations.en.name',
      APP_CONSTANTS.instructionalProgram
    );

    checkUnpublishedMajorsAlreadyExist();
    resetCategorizedWorkgroups();
    fetchProductWorkgroups();

    function resetCategorizedWorkgroups() {
      vm.categorizedWorkgroups = {
        realistic: [],
        investigative: [],
        artistic: [],
        social: [],
        enterprising: [],
        conventional: []
      };
    }

    function checkUnpublishedMajorsAlreadyExist() {
      var alreadyUnpublishedMajor = $scope.$parent.vm.getUnpublishedMajorIfExist(
        vm.selectedMajor.id
      );

      if (alreadyUnpublishedMajor) vm.selectedMajor = alreadyUnpublishedMajor;

      vm.selectedMajor.careers &&
        vm.selectedMajor.careers.length &&
        vm.selectedMajor.careers.forEach(function(career) {
          vm.mappedCareerList.push(career);
        });
    }

    function fetchProductWorkgroups() {
      vm.loading.productInfo = true;

      WorkgroupService.fetchProductWorkgroups(vm.productId)
        .then(function(result) {
          vm.loading.productInfo = false;

          resetCategorizedWorkgroups();

          vm.productWorkgroups = _.get(result, 'data.workgroups', []);
          vm.productWorkgroups.forEach(function(workgroup) {
            if (workgroup.pt === vm.ptTypes.REALISTIC) {
              vm.categorizedWorkgroups.realistic.push(workgroup);
            } else if (workgroup.pt === vm.ptTypes.INVESTIGATIVE) {
              vm.categorizedWorkgroups.investigative.push(workgroup);
            } else if (workgroup.pt === vm.ptTypes.ARTISTIC) {
              vm.categorizedWorkgroups.artistic.push(workgroup);
            } else if (workgroup.pt === vm.ptTypes.SOCIAL) {
              vm.categorizedWorkgroups.social.push(workgroup);
            } else if (workgroup.pt === vm.ptTypes.ENTERPRISING) {
              vm.categorizedWorkgroups.enterprising.push(workgroup);
            } else if (workgroup.pt === vm.ptTypes.CONVENTIONAL) {
              vm.categorizedWorkgroups.conventional.push(workgroup);
            }
          });

          fetchWorkGroupCareers(vm.categorizedWorkgroups.realistic[0].id);
        })
        .catch(function(error) {
          vm.loading.productInfo = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function fetchWorkGroupCareers(workgroupId) {
      vm.loading.productInfo = true;
      vm.workgroupCareersArray = [];
      vm.activeWorkgroupId = workgroupId;

      CareersService.fetchCareersByWorkgroupId(vm.productId, workgroupId, true)
        .then(function(result) {
          vm.loading.productInfo = false;

          vm.workgroupCareers = _.get(result, 'data.workgroup[0].careers', []);

          vm.workgroupCareers.forEach(function(career) {
            vm.workgroupCareersArray.push({
              id: career.id,
              name: career.variations.en.name,
              personalityType:
                APP_CONSTANTS.PERSONALITY_SCORE[career.hollandCodes],
              workgroupId: vm.activeWorkgroupId
            });
          });
        })
        .catch(function(error) {
          vm.loading.productInfo = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function saveMappedCareers() {
      $scope.$parent.vm.updateUnpublishedMajors(vm.selectedMajor.id);
      $scope.$parent.vm.updateMappedCareerInMajor(
        vm.selectedMajor.id,
        vm.mappedCareerList
      );
      dismissModal();
    }

    function dismissModal() {
      $uibModalInstance.dismiss();
    }
  }
})();
