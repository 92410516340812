(function() {
  'use strict';
  angular
    .module('app.shared')
    .controller('AssessmentsController', AssessmentsController);

  AssessmentsController.$inject = ['APP_CONSTANTS', '$scope'];

  function AssessmentsController(APP_CONSTANTS, $scope) {
    $scope.$parent.vm.generateBreadCrumbs(
      APP_CONSTANTS.CKADMIN_TABS.ASSESSMENTS
    );
  }
})();
