(function() {
  'use strict';

  angular
    .module('app.notification')
    .controller('ProductUptimeController', ProductUptimeController);

  ProductUptimeController.$inject = [
    '$stateParams',
    'APP_CONSTANTS',
    'ProductUptimeService',
    'toaster',
    '$state'
  ];

  function ProductUptimeController(
    $stateParams,
    APP_CONSTANTS,
    ProductUptimeService,
    toaster,
    $state
  ) {
    var vm = this;

    vm.loading = {};
    vm.notificationTo = 'All';
    vm.loading.message = false;
    vm.subjectSelected = subjectSelected;
    vm.sendNotification = sendNotification;

    vm.notification = {
      customerId: '',
      customerName: '',
      to: [vm.notificationTo],
      notification: '',
      type: '',
      status: ''
    };

    vm.notificationStatus = [
      {
        id: 1,
        status: 'Scheduled Maintenance'
      },
      {
        id: 2,
        status: 'Some function disable'
      },
      {
        id: 3,
        status: 'Finished our update'
      }
    ];

    function subjectSelected(item) {
      vm.selectedStatus = item.status;
      switch (item.id) {
        case 1:
          vm.notification.notification =
            APP_CONSTANTS.NOTIFICATION.productUpTime.scheduledMaintenance;
          break;
        case 2:
          vm.notification.notification =
            APP_CONSTANTS.NOTIFICATION.productUpTime.someFunctionDisbale;
          break;
        default:
          vm.notification.notification =
            APP_CONSTANTS.NOTIFICATION.productUpTime.FinishedUpdated;
          break;
      }
    }

    function sendNotification(valid) {
      vm.formSubmit = true;
      if (!valid) {
        return;
      }

      vm.notification.status = vm.selectedStatus;
      vm.loading.message = true;
      ProductUptimeService.sendNotification(vm.notification).then(
        function() {
          vm.loading.message = false;
          toaster.pop('success', APP_CONSTANTS.MESSAGE.messageSent);
          $state.go('app.notification.folder');
        },
        function(error) {
          vm.loading.message = false;
          toaster.pop('error', error.data.message || error.statusText);
        }
      );
    }
  }
})();
