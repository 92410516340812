(function() {
  'use strict';
  angular
    .module('app.shared')
    .controller('UserProfileController', UserProfileController);
  UserProfileController.$inject = [
    '$state',
    'UserProfileService',
    '$stateParams',
    'SessionService',
    '$scope',
    '$uibModal',
    'APP_CONSTANTS',
    'toaster',
    '$rootScope',
    'RegistrationService'
  ];

  function UserProfileController(
    $state,
    UserProfileService,
    $stateParams,
    SessionService,
    $scope,
    $uibModal,
    APP_CONSTANTS,
    toaster,
    $rootScope,
    RegistrationService
  ) {
    var vm = this;

    vm.loading = {};
    vm.user = {
      userId: $stateParams.id
    };

    vm.userInfo = undefined;
    vm.myImage = '';
    vm.croppedImage = '';
    vm.formNotSubmit = false;
    vm.loggedInUserProfile = false;
    vm.connectGoogleAccount = connectGoogleAccount;

    var modalInstance = '';
    var userLoginDetail = SessionService.getUser();

    if (vm.user.userId === '') {
      vm.user.userId = userLoginDetail.id;
      vm.loggedInUserProfile = true;
    } else {
      if (userLoginDetail.id === vm.user.userId) {
        vm.loggedInUserProfile = true;
      }
    }

    var fetchUser = function() {
      vm.loading.user = true;
      UserProfileService.fetchUser(vm.user.userId).then(
        function(response) {
          vm.userInfo = response.data.data;
          vm.croppedImage =
            vm.userInfo.profileImage || 'app/common/img/user/user.png';

          UserProfileService.setProfileImage(vm.croppedImage);

          if (vm.userInfo.profileImage !== '') {
            vm.croppedImage =
              vm.userInfo.profileImage + '?=' + $rootScope.moment().unix();
          }
          vm.loading.user = false;
        },
        function(error) {
          vm.loading.user = false;
          toaster.pop('error', error.data.message || error.statusText);
        }
      );
    };

    vm.updateUser = function(valid) {
      if (!valid) {
        vm.formNotSubmit = true;

        return;
      }

      if (vm.croppedImage !== vm.userInfo.profileImage) {
        vm.userInfo.profileImage = vm.croppedImage;
        vm.userInfo.changeImageFlag = true;
      } else {
        vm.userInfo.changeImageFlag = false;
      }
      vm.loading.user = true;
      UserProfileService.updateUser(vm.userInfo).then(
        function() {
          if (vm.user.userId === userLoginDetail.id) {
            userLoginDetail.firstName = vm.userInfo.firstName;
            userLoginDetail.lastName = vm.userInfo.lastName;
            userLoginDetail.email = vm.userInfo.email;
            userLoginDetail.profileImage = vm.userInfo.profileImage;

            if (
              !userLoginDetail.superAdminId &&
              !userLoginDetail.accountManagerId
            ) {
              SessionService.setUser(userLoginDetail);
            }

            toaster.pop({
              type: 'success',
              body: APP_CONSTANTS.MESSAGE.successfullyUpdated,
              showCloseButton: true,
              bodyOutputType: 'trustedHtml',
              timeout: 1000,
              onHideCallback: function() {
                vm.loading.user = false;
                $state.reload();
              }
            });
          } else {
            fetchUser();
            toaster.pop('success', APP_CONSTANTS.MESSAGE.successfullyUpdated);
          }
        },
        function(error) {
          vm.loading.user = false;
          toaster.pop('error', error.data.message || error.statusText);
        }
      );
    };

    function connectGoogleAccount(credential) {
      vm.loading.user = true;

      return RegistrationService.checkEmail(credential.email)
        .then(function(result) {
          vm.duplicateEmail = result.data.data > 0;
          if (!vm.duplicateEmail) {
            UserProfileService.linkGoogleAccount(
              vm.userInfo.id,
              credential.token
            )
              .then(function() {
                fetchUser();
              })
              .catch(function(error) {
                vm.loading.user = false;
                toaster.pop('error', error.data.message || error.statusText);
              });
          } else {
            vm.loading.user = false;
            toaster.pop(
              'error',
              'User exists for given google account. Please select another account.'
            );

            return;
          }
        })
        .catch(function(error) {
          vm.loading.user = false;
          toaster.pop(
            'error',
            error.data.message ||
              error.statusText ||
              'Cannot signup using google.'
          );
        });
    }

    function activate() {
      $scope.reset = function() {
        $scope.image = '';
        $scope.imgcropType = 'square';
      };
      $scope.reset();

      vm.open = function(size) {
        if (!modalInstance) {
          modalInstance = $uibModal.open({
            templateUrl:
              'app/userProfile/views/userProfileImageUploadModal.html',
            controller: 'UserProfileImageUploadController',
            controllerAs: 'vm',
            size: size
          });

          modalInstance.result
            .then(function() {
              modalInstance = '';
              $state.reload();
            })
            .catch(function() {
              modalInstance = '';
            });
        }
      };
    }
    fetchUser();
    activate();
  }
})();
