(function() {
  'use strict';
  angular
    .module('app.user')
    .factory('SubscriptionService', SubscriptionService);

  SubscriptionService.$inject = [
    'ApiCaller',
    'APP_CONSTANTS',
    '$q',
    'SubscriptionPlansService',
    'RegistrationService',
    '$filter',
    'SessionService'
  ];

  function SubscriptionService(
    ApiCaller,
    APP_CONSTANTS,
    $q,
    SubscriptionPlansService,
    RegistrationService,
    $filter,
    SessionService
  ) {
    var self = this;

    self.register = register;
    self.storePlan = storePlan;
    self.fetchPlans = fetchPlans;
    self.getCustomer = getCustomer;
    self.retrievePlan = retrievePlan;
    self.lookupSelectedPlan = lookupSelectedPlan;

    self.destroyRegistrationData = destroyRegistrationData;

    function retrievePlan() {
      return SubscriptionPlansService.retrievePlan();
    }

    function storePlan(data) {
      SubscriptionPlansService.storePlan(data);
    }

    function getCustomer() {
      var customerId = SessionService.getUser().customerId;
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.customerSingle,
        APP_CONSTANTS.rootUrl,
        customerId
      );

      ApiCaller.apiGet(url).then(
        function(result) {
          deferred.resolve(result);
        },
        function(err) {
          deferred.reject(err);
        }
      );

      return deferred.promise;
    }

    function fetchPlans(schoolType) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.planCollection,
        APP_CONSTANTS.rootUrl,
        schoolType
      );

      ApiCaller.apiGet(url).then(
        function(result) {
          deferred.resolve(result);
        },
        function(err) {
          deferred.reject(err);
        }
      );

      return deferred.promise;
    }

    function lookupSelectedPlan(planList) {
      var selectedPlan;
      var retrievePlan = self.retrievePlan();

      if (retrievePlan) {
        selectedPlan = _.find(planList, function(plan) {
          return retrievePlan.id === plan.plan.id;
        });
      } else {
        selectedPlan = planList[4];
      }

      return selectedPlan;
    }

    function register(cbCustomerId) {
      var buySubscription = {};

      buySubscription.plan = SubscriptionPlansService.retrievePlan();
      buySubscription.cbCustomerId = cbCustomerId;
      buySubscription.customerId = SessionService.getUser().customerId;
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.buySubscription,
        APP_CONSTANTS.rootUrl
      );

      ApiCaller.apiPost(url, buySubscription).then(
        function(result) {
          deferred.resolve(result);
        },
        function(err) {
          deferred.reject(err);
        }
      );

      return deferred.promise;
    }

    function destroyRegistrationData() {
      SubscriptionPlansService.destroyPlan();
      RegistrationService.destroyRegistrationInfo();
    }

    return self;
  }
})();
