(function() {
  'use strict';

  angular
    .module('app.notification')
    .controller('ProductUsageController', ProductUsageController);

  ProductUsageController.$inject = [
    '$stateParams',
    'APP_CONSTANTS',
    'ProductUptimeService',
    'toaster',
    '$state',
    'ProductActivationService'
  ];

  function ProductUsageController(
    $stateParams,
    APP_CONSTANTS,
    ProductUptimeService,
    toaster,
    $state,
    ProductActivationService
  ) {
    var vm = this;
    var notificationType = [
      {
        id: 1,
        type: 'Prepaid'
      },
      {
        id: 2,
        type: 'Auto Renewal'
      }
    ];

    var userRoles = [
      {
        id: 1,
        role: 'Owner,' + 'Counselor,' + 'BillingContact'
      },
      {
        id: 2,
        role: 'Stakeholder'
      }
    ];

    var notification = {
      customerId: '',
      customerName: '',
      to: [],
      notification: '',
      type: '',
      status: ''
    };

    vm.loading = {};
    vm.userRoles = [];
    vm.isPrepaid = true;
    vm.notificationType = [];
    vm.loading.message = false;
    vm.roleSelected = roleSelected;
    vm.customerSelected = customerSelected;
    vm.sendNotification = sendNotification;
    vm.fetchCutomerList = fetchCutomerList;
    vm.onStatusSelected = onStatusSelected;
    vm.userRoles = angular.copy(userRoles);

    vm.notificationStatus = [
      {
        id: 1,
        status: 'You have Subcription Limit'
      },
      {
        id: 2,
        status: 'You are about Subcription Surpass'
      }
    ];

    fetchCutomerList();

    function fetchCutomerList() {
      vm.fetchCustomerLoading = true;
      ProductActivationService.fetchCustomerList().then(
        function(result) {
          vm.customerList = _.filter(result.data.data, function(customer) {
            if (!_.isEmpty(customer.currentPlan)) {
              return (
                (customer.currentPlan.cf_subscription_type === 'prepaid' ||
                  customer.currentPlan.cf_subscription_type ===
                    'autorenewal') &&
                customer.accountStatus === 'active'
              );
            }
          });
          vm.fetchCustomerLoading = false;
        },
        function(error) {
          toaster.pop('error', error.data.message || error.statusText);
          vm.fetchCustomerLoading = false;
        }
      );
    }

    function customerSelected(customer) {
      vm.notification = {};
      vm.notification.customer = customer;
      if (!customer.currentPlan) {
        vm.notificationType = '';

        return;
      }
      switch (customer.currentPlan.cf_subscription_type) {
        case 'prepaid':
          vm.notificationType = notificationType[0].type;
          vm.notification.status = 'Subscription Limit';
          vm.isPrepaid = true;
          vm.userRoles = angular.copy(userRoles);
          break;
        case 'autorenewal':
          vm.notificationType = notificationType[1].type;
          vm.isPrepaid = false;
          if (vm.userRoles.indexOf(vm.userRoles[1]) >= 0) {
            vm.userRoles.splice(vm.userRoles.indexOf(vm.userRoles[1]), 1);
          }
          break;
        default:
          vm.notificationType = '';
          break;
      }
    }

    function roleSelected(selectedRole) {
      if (vm.notificationType === 'Prepaid') {
        if (selectedRole.id === 1) {
          vm.notification.notification =
            APP_CONSTANTS.NOTIFICATION.productUsage.prepaid.subscriptionLimitAll;
        } else {
          vm.notification.notification =
            APP_CONSTANTS.NOTIFICATION.productUsage.prepaid.subscriptionLimitToStakeholder;
        }
      }
    }

    function onStatusSelected(selectedStatus) {
      if (selectedStatus.id === 1) {
        vm.notification.notification =
          APP_CONSTANTS.NOTIFICATION.productUsage.autoRenewal.subscriptionLimit;
      } else {
        vm.notification.notification =
          APP_CONSTANTS.NOTIFICATION.productUsage.autoRenewal.surpassSubscriptionLimit;
      }
    }

    function sendNotification(valid) {
      vm.formSubmit = true;
      if (!valid) {
        return;
      }

      notification.type = vm.notificationType;
      notification.to = vm.notification.to.role.split(',');
      notification.customerId = vm.notification.customer.id;
      notification.notification = vm.notification.notification;
      notification.customerName =
        vm.notification.customer.legalName || vm.notification.customer.name;
      notification.status =
        vm.notification.status.status || vm.notification.status;

      vm.loading.message = true;
      ProductUptimeService.sendNotification(notification).then(
        function() {
          vm.loading.message = false;
          toaster.pop('success', APP_CONSTANTS.MESSAGE.messageSent);
          $state.go('app.notification.folder');
          vm.notification = {};
        },
        function(error) {
          vm.loading.message = false;
          vm.notification = {};
          toaster.pop('error', error.data.message || error.statusText);
        }
      );
    }
  }
})();
