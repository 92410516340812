(function() {
  'use strict';
  angular
    .module('app.customerAccount')
    .factory('OrganizationService', OrganizationService);

  OrganizationService.$inject = [
    'localStorageService',
    'APP_CONSTANTS',
    'ApiCaller',
    '$filter',
    '$q',
    'SessionService',
    '$rootScope'
  ];

  /* eslint-disable */
  function OrganizationService(
    localStorageService,
    APP_CONSTANTS,
    ApiCaller,
    $filter,
    $q,
    SessionService,
    $rootScope
  ) {
    var self = this;

    self.checkEmail = checkEmail;
    self.fetchSubscriptions = fetchSubscriptions;
    self.addSponsoredOrganization = addSponsoredOrganization;
    self.fetchSponsoredOrganizations = fetchSponsoredOrganizations;
    self.updateSponsoredOrganization = updateSponsoredOrganization;
    self.grantOrRevokeCkdWidgetAccess = grantOrRevokeCkdWidgetAccess;
    self.getOrganizationLoggedInDetails = getOrganizationLoggedInDetails;
    self.grantOrRevokeOrganizationStatus = grantOrRevokeOrganizationStatus;

    function addSponsoredOrganization(organization, customerId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.sponsoredOrganizationCollection,
        APP_CONSTANTS.rootUrl,
        customerId
      );

      ApiCaller.apiPost(url, organization)
        .then(function(response) {
          deferred.resolve(response);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function fetchSubscriptions() {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.subscriptionList,
        APP_CONSTANTS.rootUrl
      );

      ApiCaller.apiGet(url)
        .then(function(response) {
          deferred.resolve(response);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function checkEmail(email) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.userEmail,
        APP_CONSTANTS.rootUrl,
        encodeURIComponent(email)
      );

      ApiCaller.apiGet(url)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function fetchSponsoredOrganizations(customerId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.sponsoredOrganizationCollection,
        APP_CONSTANTS.rootUrl,
        customerId
      );

      ApiCaller.apiGet(url)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function updateSponsoredOrganization(customerId, payload) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.sponsoredOrganizationSingle,
        APP_CONSTANTS.rootUrl,
        customerId
      );

      ApiCaller.apiPut(url, payload)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function grantOrRevokeOrganizationStatus(sponsoredOrgId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.sponsoredOrganizationStatus,
        APP_CONSTANTS.rootUrl,
        sponsoredOrgId
      );

      ApiCaller.apiPut(url)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function grantOrRevokeCkdWidgetAccess(sponsoredOrgId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.sponsoredOrgCkdWidgetStatus,
        APP_CONSTANTS.rootUrl,
        sponsoredOrgId
      );

      ApiCaller.apiPut(url)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function getOrganizationLoggedInDetails(organizationId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.organizationToken,
        APP_CONSTANTS.rootUrl,
        organizationId
      );

      ApiCaller.apiGet(url).then(
        function(result) {
          var tempAccountManager = {
            user: SessionService.getUser(),
            customerImg: SessionService.getOrganizationImage()
          };

          SessionService.setAccountManagerToken(tempAccountManager);
          SessionService.setUser(result.data.data);
          SessionService.setOrganizationImage(
            _.get(
              result,
              'data.data.customerImg',
              APP_CONSTANTS.defaultOrganizationImage
            )
          );
          deferred.resolve(result);
        },
        function(err) {
          deferred.reject(err);
        }
      );

      return deferred.promise;
    }

    return self;
  }
})();
/* eslint-enable */
