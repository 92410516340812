(function() {
  'use strict';
  angular.module('app.shared').config(routesConfig);

  routesConfig.$inject = [
    '$stateProvider',
    '$locationProvider',
    '$urlRouterProvider',
    'RouteHelpersProvider'
  ];

  function routesConfig(
    $stateProvider,
    $locationProvider,
    $urlRouterProvider,
    helper
  ) {
    // Set the following to true to enable the HTML5 Mode
    // You may have to set <base> tag in index and a routing configuration in your server
    $locationProvider.html5Mode(false);
    // Application Routes
    // -----------------------------------
    $stateProvider
      .state('app', {
        url: '/app',
        abstract: true,
        templateUrl: helper.basepath('shared/views/app.html'),
        resolve: helper.resolveFor('modernizr', 'icons')
      })
      .state('app.billingdashboard', {
        url: '/billing',
        title: 'Billing Dashboard',
        templateUrl: helper.basepath('billing/views/billing.html'),
        controller: 'BillingController',
        controllerAs: 'bc',
        resolve: helper.resolveFor('modernizr', 'icons', 'datatables'),
        secured: true
      })
      // //TOP NAVBAR
      .state('app.userprofile', {
        url: '/user-profile/:id',
        title: 'User Profile',
        templateUrl: helper.basepath('userProfile/views/userProfile.html'),
        controller: 'UserProfileController',
        controllerAs: 'user',
        resolve: helper.resolveFor(
          'modernizr',
          'icons',
          'ngImgCrop',
          'filestyle'
        ),
        secured: true
      })
      .state('app.userchangepassword', {
        url: '/change-password',
        title: 'Change Password',
        templateUrl: helper.basepath('shared/views/user/changepassword.html'),
        controller: 'ChangePasswordController',
        controllerAs: 'rs',
        resolve: helper.resolveFor('modernizr', 'icons'),
        secured: true
      })
      .state('app.acsubscription', {
        url: '/acsubscription',
        title: 'Account Subscription',
        templateUrl: helper.basepath('subscription/views/addsubscription.html'),
        resolve: helper.resolveFor('modernizr', 'icons', 'datatables'),
        secured: true
      })
      .state('app.subscription', {
        url: '/accounts',
        title: 'Subscription',
        templateUrl: helper.basepath('subscription/views/subscription.html'),
        resolve: helper.resolveFor(
          'modernizr',
          'icons',
          'datatables',
          'ngDialog'
        ),
        secured: true
      })

      .state('app.ckadmin', {
        url: '/admin',
        title: 'Admin',
        controller: 'ckAdminController',
        controllerAs: 'vm',
        abstract: true,
        templateUrl: helper.basepath('ckadmin/index.html'),
        resolve: helper.resolveFor(
          'modernizr',
          'icons',
          'datatables',
          'ngDialog'
        ),
        secured: true
      })

      .state('app.createmanagegroup', {
        url: '/create-and-manage-group',
        title: 'Create Manage Group',
        templateUrl: helper.basepath('group/views/createManageGroups.html'),
        controller: 'GroupController',
        controllerAs: 'vm',
        resolve: helper.resolveFor(
          'modernizr',
          'icons',
          'datatables',
          'ui.select',
          'ngDialog',
          'filestyle'
        ),

        secured: true
      })
      // RESOURCES NAVIGATION
      .state('app.blog', {
        url: '/app.blog',
        title: 'Blog',
        templateUrl: helper.basepath('blog/views/blog.html'),
        resolve: helper.resolveFor('modernizr', 'icons'),
        secured: true
      })
      // SUPPORT NAVIGATION
      .state('app.setup', {
        url: '/app.setup',
        title: 'Setup',
        templateUrl: helper.basepath('support/views/setup.html'),
        resolve: helper.resolveFor('modernizr', 'icons', 'datatables'),
        secured: true
      })
      // SUPPORT NAVIGATION
      .state('app.contactus', {
        url: '/contact-us',
        title: 'Contact Us',
        templateUrl: helper.basepath('support/views/contactUs.html'),
        resolve: helper.resolveFor('modernizr', 'icons', 'datatables'),
        secured: true
      })
      .state('error', {
        url: '/error',
        title: 'Error',
        templateUrl: 'app/shared/views/error.html',
        controller: 'ErrorController',
        controllerAs: 'vm',
        params: {
          message: null
        }
      });
  }
})();
