(function () {
  'use strict';

  angular
    .module('app.shared')
    .controller('AdminAddSubscriptionModalController', AdminAddSubscriptionModalController);

  AdminAddSubscriptionModalController.$inject = [
    'APP_CONSTANTS',
    'ProductService',
    'toaster',
    '$uibModalInstance',
    'AdminCustomerSubscriptionService',
    'data',
  ];

  function AdminAddSubscriptionModalController(
    APP_CONSTANTS,
    ProductService,
    toaster,
    $uibModalInstance,
    AdminCustomerSubscriptionService,
    data,
  ) {
    var vm = this;

    vm.loading = {};
    vm.durations = APP_CONSTANTS.SUBSCRIPTION_DURATIONS;
    vm.selected = {};
    vm.selected.duration = vm.durations[0];
    vm.subscription = {};

    activate();
    initDatePicker();

    function activate() {
      vm.loading.products = true;

      ProductService.fetchProductIds()
        .then(function (result) {
          vm.products = _.get(result, 'data.products', []);
          vm.loading.products = false;
        })
        .catch(function (error) {
          toaster.pop({
            type: 'error',
            body: error.data.message || error.statusText,
            toasterId: 'addSubscription'
          });

          vm.loading.products = false;
        });
    }

    function initDatePicker() {
      var today = new Date();
      var sixMonthFromToday = moment(today).add({ month: 6 }).toDate();

      vm.subscription.startDate = today;
      vm.datePicker = { opened: false };
      vm.dateOptions = {
        dateDisabled: function (data) {
          return data.mode === 'day'
            && (data.date.getDay() === 0 || data.date.getDay() === 6);
        },
        formatYear: 'yy',
        maxDate: sixMonthFromToday,
        minDate: today,
        startingDay: 1
      };

      vm.openDatePicker = function () {
        vm.datePicker.opened = true;
      }
    }

    vm.submit = function (form) {
      if (form.$invalid) return;

      form.$setPristine();
      vm.subscription.product = {
        id: vm.selected.product.id,
        name: vm.selected.product.name,
        version: vm.selected.product.version,
        assessmentType: vm.selected.product.assessmentType,
      };
      vm.subscription.endDate = moment(vm.subscription.startDate)
        .add({ year: vm.selected.duration.value })
        .toDate();

      toaster.clear('addSubscription');
      vm.loading.subscription = true;

      AdminCustomerSubscriptionService.addNewSubscription(vm.subscription, data.customerId)
        .then(function () {
          vm.loading.subscription = false;
          closePopup();
          toaster.pop(
            'success',
            APP_CONSTANTS.MESSAGE.subscriptionCreatedSuccessfully
          );
        })
        .catch(function (error) {
          vm.loading.subscription = false;
          toaster.pop({
            type: 'error',
            body: error.data.message || error.statusText,
            toasterId: 'addSubscription'
          });
          document.getElementById('addSubscription').scrollIntoView(true);
        });
    }

    function closePopup() {
      $uibModalInstance.close('closed');
    }

    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    }
  }
})();
