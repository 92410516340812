(() => {
  'use strict';

  angular.module('careerKeyCentral')
    .directive('breadCrumb', () => ({
      controller: 'BreadCrumbController',
      controllerAs: 'vm',
      link: (scope, element, attrs) => {
        element.addClass('media'); // fix styling issue
      },
      templateUrl: '/app/shared/views/partials/breadCrumb.html',
      restrict: 'A',
      scope: {}, // isolate scope !important
    }));

})();
