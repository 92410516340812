(function () {
  'use strict';

  angular.module('app.shared')
    .controller('AssignDiscoverersToGroupController', [
      'APP_CONSTANTS',
      'GroupService',
      'toaster',
      '$uibModalInstance',
      'data',
      AssignDiscoverersToGroupController
    ]);

  function AssignDiscoverersToGroupController(
    APP_CONSTANTS,
    GroupService,
    toaster,
    $uibModalInstance,
    data
  ) {
    const vm = this;
    vm.dismiss = $uibModalInstance.dismiss;
    vm.loading = {};
    vm.groups = data?.groups || [];
    vm.selectedGroup = '';

    vm.updateGroup = () => {
      vm.loading.updateGroup = true;
      const updateDiscoverer = {
        newDiscoverers: data?.discoverers || [],
        group: vm.selectedGroup,
      };

      GroupService.createDiscoverers(updateDiscoverer)
        .then(() => {
          const successMessage = updateDiscoverer.newDiscoverers.length > 1
            ? APP_CONSTANTS.MESSAGE.discoverersAssigned
            : APP_CONSTANTS.MESSAGE.discovererAssigned;

          vm.loading.updateGroup = false;
          toaster.pop('success', successMessage);
          $uibModalInstance.close();
        })
        .catch((error) => {
          vm.loading.updateGroup = false;
          toaster.pop('error', error.data?.message || error.statusText);
        });
    };
  }

})();