/**
 * Created by Romit Amgai<romitamgai@lftechnology.com> on 4/18/17.
 */
(function() {
  'use strict';
  angular.module('app.user').factory('AccountInfoService', AccountInfoService);

  AccountInfoService.$inject = [
    'localStorageService',
    'APP_CONSTANTS',
    'ApiCaller',
    '$filter',
    '$q'
  ];

  function AccountInfoService(
    localStorageService,
    APP_CONSTANTS,
    ApiCaller,
    $filter,
    $q
  ) {
    var self = this;

    self.createSubscription = createSubscription;

    function createSubscription(accountInfo, paymentInfo, plan) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(APP_CONSTANTS.signUp, APP_CONSTANTS.rootUrl);
      var userPayload = {
        plan: {},
        account: {},
        payment: {}
      };

      angular.copy(accountInfo, userPayload.account);
      angular.copy(paymentInfo, userPayload.payment);

      userPayload.plan.id = plan.id;
      delete userPayload.account.purchaseType;
      userPayload.account.country = userPayload.account.country.name;

      ApiCaller.apiPost(url, userPayload).then(
        function(result) {
          deferred.resolve(result);
        },
        function(err) {
          deferred.reject(err);
        }
      );

      return deferred.promise;
    }

    return self;
  }
})();
