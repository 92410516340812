(function() {
  'use strict';
  angular
    .module('app.user')
    .controller('PurchaseController', PurchaseController);

  PurchaseController.$inject = [
    '$scope',
    '$state',
    'PurchaseService',
    'APP_CONSTANTS',
    'toaster'
  ];

  function PurchaseController(
    $scope,
    $state,
    PurchaseService,
    APP_CONSTANTS,
    toaster
  ) {
    var vm = this;

    vm.loading = {};
    vm.planList = [];
    vm.submit = submit;
    vm.formSubmit = false;
    vm.taxDocument = null;
    vm.loading.signUp = true;

    vm.signUpWithACH = signUpWithACH;
    vm.openDatePicker = openDatePicker;
    vm.changeButtonText = changeButtonText;
    vm.uploadTaxDocument = uploadTaxDocument;
    vm.renewalplanSelected = renewalplanSelected;
    vm.prepaidplanSelected = prepaidplanSelected;
    vm.signUpWithCreditCard = signUpWithCreditCard;
    vm.signUpWithPurchaseOrder = signUpWithPurchaseOrder;

    var today = new Date();
    var sixMonthFromToday = new Date(
      new Date(today).setMonth(today.getMonth() + 6)
    );

    vm.datePicker = { opened: false };
    vm.dateOptions = {
      dateDisabled: dateDisabled,
      formatYear: 'yy',
      maxDate: sixMonthFromToday,
      minDate: today,
      startingDay: 1
    };

    function openDatePicker() {
      vm.datePicker.opened = true;
    }

    function dateDisabled(data) {
      var date = data.date,
        mode = data.mode;

      return mode === 'day' && (date.getDay() === 0 || date.getDay() === 6);
    }

    function prepaidplanSelected(plan) {
      vm.purchase.selectedPlan.plan.id = plan.id;
    }

    function uploadTaxDocument() {
      vm.loading.signUp = true;
      PurchaseService.uploadTaxDocument(vm.purchase.taxDocument).then(
        function(result) {
          vm.loading.signUp = false;
          vm.taxDocument = result.data.data;
          PurchaseService.storeTaxDocument(result.data.data);
          toaster.pop({
            type: 'success',
            body: 'File Uploaded SuccessFully',
            toasterId: 1
          });
        },
        function(error) {
          toaster.pop('error', error.data.message || error.statusText);
        }
      );
    }

    function renewalplanSelected(plan) {
      vm.purchase.selectedPlan.plan.id = plan.id;
    }

    PurchaseService.fetchPlans().then(
      function(result) {
        vm.planList = result.data.data;
        vm.loading.signUp = false;
        activate();
      },
      function(error) {
        vm.loading.signUp = false;
        toaster.pop('error', error.data.message || error.statusText);
      }
    );

    function changeButtonText() {
      vm.submitButtonText = vm.purchase.payment
        ? APP_CONSTANTS.addToCart
        : APP_CONSTANTS.Continue;
    }

    function signUpWithCreditCard() {
      vm.loading.signUp = true;
      PurchaseService.register().then(
        function(result) {
          vm.loading.signUp = false;
          PurchaseService.destroyRegistrationData();
          var url = result.data.data.hostedPage.url;

          location.href = url;
        },
        function(error) {
          vm.loading.signUp = false;
          toaster.pop('error', error.data.message || error.statusText);
        }
      );
    }

    function signUpWithACH() {
      vm.loading.signUp = true;
      PurchaseService.signUpWithACH().then(
        function(result) {
          vm.loading.signUp = false;
          var url = result.data.data.hosted_page.url;

          location.href = url;
        },
        function(error) {
          vm.loading.signUp = false;
          toaster.pop('error', error.data.message || error.statusText);
        }
      );
    }

    function signUpWithPurchaseOrder() {
      $state.go('purchaseorder');
    }

    function submit() {
      vm.formSubmit = true;
      if (!vm.startDate) return;
      if (vm.purchase.taxExempt === 'exempt') {
        if (_.isNull(vm.taxDocument)) return;
      }

      vm.purchase.selectedPlan.plan.startDate = vm.startDate.getTime() / 1000;
      PurchaseService.storePlan(vm.purchase.selectedPlan);
      if (vm.purchase.payment) {
        switch (vm.purchase.paymentMethod) {
          case 'ach':
            vm.signUpWithACH();
            break;
          case 'purchaseOrder':
            vm.signUpWithPurchaseOrder();
            break;
          default:
            vm.signUpWithCreditCard();
            break;
        }
      } else {
        $state.go('billinginvitation');
      }
    }

    function activate() {
      vm.purchase = {};
      vm.purchase.payment = false;
      vm.purchase.paymentMethod = 'creditCard';
      vm.purchase.taxExempt = 'taxable';
      changeButtonText();
      vm.purchase.selectedPlan = PurchaseService.lookupSelectedPlan(
        vm.planList
      );
    }
  }
})();
