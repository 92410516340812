(function() {
  'use strict';

  angular.module('app.shared').factory('EditMajorService', EditMajorService);

  EditMajorService.$inject = ['APP_CONSTANTS', 'ApiCaller', '$filter', '$q'];

  function EditMajorService(APP_CONSTANTS, ApiCaller, $filter, $q) {
    var self = this;

    self.fetchLanguageVariationsForMajor = fetchLanguageVariationsForMajor;

    function fetchLanguageVariationsForMajor(workgroupId, majorId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.workgroupMajorsLanguageVariation,
        APP_CONSTANTS.rootUrl,
        workgroupId,
        majorId
      );

      ApiCaller.apiGet(url)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    return self;
  }
})();
