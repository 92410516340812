(function() {
  'use strict';
  angular.module('app.stakeholder').config(state);

  state.$inject = ['$stateProvider', 'RouteHelpersProvider'];

  function state($stateProvider, helper) {
    $stateProvider.state('app.stakeholderdashboard', {
      url: '/stakeholder/dashboard',
      title: 'stakeholder Dashboard',
      templateUrl: helper.basepath(
        'stakeholder/views/stakeholderdashboard.html'
      ),
      controller: 'StakeHolderDashboardController',
      controllerAs: 'vm',
      secured: true,
      resolve: helper.resolveFor('icons', 'ui.select')
    });
  }
})();
