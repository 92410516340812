(function() {
  'use strict';

  angular.module('app.user').factory('logoutService', logoutService);

  logoutService.$inject = [
    'APP_CONSTANTS',
    '$q',
    '$filter',
    'SessionService',
    'ApiCaller'
  ];

  function logoutService(
    APP_CONSTANTS,
    $q,
    $filter,
    SessionService,
    ApiCaller
  ) {
    var self = this;

    self.logoutApiPost = logoutApiPost;
    self.logoutCustomerApiPost = logoutCustomerApiPost;

    function logoutApiPost() {
      var deferred = $q.defer();
      var url = $filter('sprintf')(APP_CONSTANTS.logout, APP_CONSTANTS.rootUrl);

      ApiCaller.apiPost(url, { refreshToken: SessionService.getRefreshToken() })
        .then(function(result) {
          SessionService.removeUser();
          SessionService.removeAdminToken();
          SessionService.removeAccountManagerToken();
          
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.resolve(error);
        });

      return deferred.promise;
    }

    function logoutCustomerApiPost() {
      var deferred = $q.defer();
      var url = $filter('sprintf')(APP_CONSTANTS.logout, APP_CONSTANTS.rootUrl);

      ApiCaller.apiPost(url, { refreshToken: SessionService.getRefreshToken() })
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.resolve(error);
        });

      return deferred.promise;
    }

    return self;
  }
})();
