(function() {
  'use strict';

  angular.module('app.user').factory('InvitationService', InvitationService);

  InvitationService.$inject = ['APP_CONSTANTS', 'ApiCaller', '$q', '$filter'];

  function InvitationService(APP_CONSTANTS, ApiCaller, $q, $filter) {
    var self = this;

    self.activate = activate;
    self.fetchById = fetchById;
    self.fetchByEmail = fetchByEmail;

    function fetchById(invitationId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.invitationSingle,
        APP_CONSTANTS.rootUrl,
        invitationId
      );

      ApiCaller.apiGet(url).then(
        function(result) {
          deferred.resolve(result);
        },
        function(err) {
          deferred.reject(err);
        }
      );

      return deferred.promise;
    }

    function fetchByEmail(email) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.invitationCollection,
        APP_CONSTANTS.rootUrl
      );

      url += '?email=' + email;

      ApiCaller.apiGet(url).then(
        function(result) {
          deferred.resolve(result);
        },
        function(err) {
          deferred.reject(err);
        }
      );

      return deferred.promise;
    }

    function activate(user) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.userActivate,
        APP_CONSTANTS.rootUrl,
        user.account.invitationId
      );

      ApiCaller.apiPost(url, user).then(
        function(result) {
          deferred.resolve(result);
        },
        function(err) {
          deferred.reject(err);
        }
      );

      return deferred.promise;
    }

    /* eslint-disable */
    function manageRoles(roles) {
      angular.forEach(roles, function(value, key) {
        self.roles[value.role] = true;
      });

      return self.roles;
    }
    /* eslint-enable */

    return self;
  }
})();
