(function () {
  'use strict';
  angular
    .module('app.user')
    .controller('AddResellerSubscriptionController', AddResellerSubscriptionController);
  AddResellerSubscriptionController.$inject = [
    '$scope',
    '$filter',
    'SubscriptionListService',
    'BillingInfoService',
    'RegistrationService',
    'toaster',
    'APP_CONSTANTS',
    'PaymentInfoService'
  ];

  function AddResellerSubscriptionController(
    $scope,
    $filter,
    SubscriptionListService,
    BillingInfoService,
    RegistrationService,
    toaster,
    APP_CONSTANTS,
    PaymentInfoService
  ) {
    var vm = this;

    vm.loading = {};
    vm.submit = submit;
    vm.selectedPlan = {};
    vm.subscription = {};
    vm.decimalFixedValue = APP_CONSTANTS.decimalFixedValue;
    vm.selectSubcription = selectSubcription;
    vm.calculatePerUserPrice = calculatePerUserPrice;
    $scope.regController.hidePurchaseOrder = false;
    init();

    var today = new Date();

    vm.subscription.startDate = today;

    var sixMonthFromToday = new Date(
      new Date(today).setMonth(today.getMonth() + 6)
    );

    vm.openDatePicker = openDatePicker;
    vm.datePicker = { opened: false };
    vm.dateOptions = {
      dateDisabled: dateDisabled,
      formatYear: 'yy',
      maxDate: sixMonthFromToday,
      minDate: today,
      startingDay: 1
    };

    function openDatePicker() {
      vm.datePicker.opened = true;
    }

    function dateDisabled(data) {
      var date = data.date,
        mode = data.mode;

      return mode === 'day' && (date.getDay() === 0 || date.getDay() === 6);
    }

    function init_bkp() {
      vm.loading.plans = true;
      vm.constants = {
        prepaid: APP_CONSTANTS.SUBSCRIPTION_MESSAGE.PREPAID,
        autoRenewal: APP_CONSTANTS.SUBSCRIPTION_MESSAGE.AUTO_RENEWAL
      };
      vm.autoRenewalType = APP_CONSTANTS.AUTO_RENEWAL_TYPE.MONTHLY;
      angular.copy($scope.regController.plan, vm.selectedPlan);

      if (
        vm.selectedPlan &&
        !_.isEmpty(vm.selectedPlan) &&
        vm.selectedPlan.cf_subscription_type !==
        APP_CONSTANTS.SUBSCRIPTION_TYPE.TRIAL
      ) {
        var params = {
          cf_language: vm.selectedPlan.cf_language,
          cf_country: vm.selectedPlan.cf_country,
          cf_audience: vm.selectedPlan.cf_audience,
          cf_customer_type: vm.selectedPlan.cf_customer_type
        };

        RegistrationService.getPlans(params)
          .then(function (plans) {
            vm.prepaidPlans = plans.filter(_checkPrepaidPlans);
            vm.autoRenewalPlans = plans.filter(_checkAutoRenewalPlans);
            vm.loading.plans = false;
            if (
              vm.selectedPlan.cf_subscription_type ===
              APP_CONSTANTS.SUBSCRIPTION_TYPE.AUTO_RENEWAL
            )
              vm.autoRenewalType = vm.selectedPlan.period_unit;
          })
          .catch(function (error) {
            toaster.pop({
              type: 'error',
              body: error.data.message || error.statusText,
              toasterId: 'subscription'
            });

            vm.loading.plans = false;
          });
      }
    }

    function init() {
      vm.loading.plans = true;
      vm.constants = {
        prepaid: APP_CONSTANTS.SUBSCRIPTION_MESSAGE.PREPAID,
        autoRenewal: APP_CONSTANTS.SUBSCRIPTION_MESSAGE.AUTO_RENEWAL
      };
      vm.autoRenewalType = APP_CONSTANTS.AUTO_RENEWAL_TYPE.MONTHLY;
      RegistrationService.getPlans()
        .then(function (plans) {
          vm.plans = plans;
          vm.loading.plans = false;
        })
        .catch(function (error) {
          toaster.pop({
            type: 'error',
            body: error.data.message || error.statusText,
            toasterId: 'subscription'
          });

          vm.loading.plans = false;
        });
    }

    function _checkPrepaidPlans(plan) {
      return (
        plan.plan.cf_subscription_type.toLowerCase() ===
        APP_CONSTANTS.SUBSCRIPTION_TYPE.PREPAID
      );
    }

    function _checkAutoRenewalPlans(plan) {
      return (
        plan.plan.cf_subscription_type.toLowerCase() ===
        APP_CONSTANTS.SUBSCRIPTION_TYPE.AUTO_RENEWAL
      );
    }

    function calculatePerUserPrice(plan) {
      if (plan.period_unit === APP_CONSTANTS.AUTO_RENEWAL_TYPE.MONTHLY)
        return (parseFloat(plan.price) * 12) / plan.cf_quantity;
      else return parseFloat(plan.price) / plan.cf_quantity;
    }

    function selectSubcription() {
      var billingInfo = RegistrationService.getBillingInfo();
      var selectedPlan = RegistrationService.getPlan(vm.selectedPlan.id);

      angular.copy(selectedPlan, $scope.regController.plan);

      if (selectedPlan.period_unit === APP_CONSTANTS.AUTO_RENEWAL_TYPE.YEARLY) {
        $scope.regController.hidePurchaseOrder = false;
        $scope.regController.width = { width: APP_CONSTANTS.widthWithoutPO };
      }
      if (
        selectedPlan.period_unit === APP_CONSTANTS.AUTO_RENEWAL_TYPE.MONTHLY
      ) {
        $scope.regController.hidePurchaseOrder = true;
        $scope.regController.width = { width: APP_CONSTANTS.widthWithoutPO };
      }

      $scope.regController.paymentType =
        selectedPlan.cf_subscription_type.toLowerCase() ===
          APP_CONSTANTS.SUBSCRIPTION_TYPE.PREPAID
          ? APP_CONSTANTS.PAYMENT_PERIOD.PREPAID
          : selectedPlan.period_unit.toLowerCase() ===
            APP_CONSTANTS.AUTO_RENEWAL_TYPE.MONTHLY
            ? APP_CONSTANTS.PAYMENT_PERIOD.AUTO_RENEWAL_MONTHLY
            : APP_CONSTANTS.PAYMENT_PERIOD.AUTO_RENEWAL_ANNUAL;

      if (!_.isEmpty(billingInfo)) {
        $scope.regController.loading.orderSummaryBlock = true;

        var resellerInfo = {
          taxability: $scope.regController.taxable ? 'taxable' : 'exempt'
        };

        if ($scope.regController.promotionCode) {
          var quantity = 1;
          var promotionCode = [];

          billingInfo = {
            city: billingInfo.city,
            state: billingInfo.state,
            zipCode: billingInfo.zipCode,
            lastName: billingInfo.lastName,
            firstName: billingInfo.firstName,
            addressLine1: billingInfo.addressLine1,
            addressLine2: billingInfo.addressLine2,
            organization: billingInfo.organization,
            country: billingInfo.country.countryCode
          };

          promotionCode.push($scope.regController.promotionCode);

          var payload = {
            quantity: quantity,
            billingInfo: billingInfo,
            couponCodes: promotionCode,
            customerInfo: resellerInfo,
            planId: $scope.regController.plan.id
          };

          PaymentInfoService.getEstimate(payload)
            .then(function (response) {
              vm.loading = false;

              $scope.regController.loading.orderSummaryBlock = false;
              $scope.regController.orderSummary.discount =
                (response.data.data.invoice_estimate.discounts &&
                  response.data.data.invoice_estimate.discounts[0].amount) ||
                '';
              $scope.regController.orderSummary.taxAmount =
                response.data.data.invoice_estimate.line_items[0].tax_amount;
              $scope.regController.orderSummary.totalAmount =
                response.data.data.invoice_estimate.total;

              RegistrationService.setTaxAmount(
                $scope.regController.orderSummary.taxAmount
              );
            })
            .catch(function () {
              vm.loading = false;
              toaster.pop({
                type: 'error',
                body: 'Could not apply promotion code.',
                toasterId: 'subscription'
              });
            });
        } else {
          BillingInfoService.getTaxEstimate(
            $scope.regController.plan.id,
            billingInfo,
            resellerInfo
          ).then(
            function (result) {
              $scope.regController.orderSummary.discount =
                (result.data.data.invoice_estimate.discounts &&
                  result.data.data.invoice_estimate.discounts[0].amount) ||
                '';
              $scope.regController.orderSummary.taxAmount =
                result.data.data.invoice_estimate.line_items[0].tax_amount;
              $scope.regController.orderSummary.totalAmount =
                result.data.data.invoice_estimate.total;
              $scope.regController.loading.orderSummaryBlock = false;

              RegistrationService.setTaxAmount(
                $scope.regController.orderSummary.taxAmount
              );
            },
            function (err) {
              $scope.regController.loading.orderSummaryBlock = false;
              vm.loading = false;
              if (err.data.error && err.data.error.code === 400)
                toaster.pop({
                  type: 'error',
                  body: APP_CONSTANTS.MESSAGE.INVALID_BILLING_INFORMATION,
                  toasterId: 'subscription'
                });
              else
                toaster.pop({
                  type: 'error',
                  body: err.data.message || err.statusText,
                  toasterId: 'subscription'
                });
            }
          );
        }
      } else {
        $scope.regController.orderSummary.totalAmount =
          $scope.regController.plan.price;
      }

      var lastActiveBlock = RegistrationService.getLastActiveBlock();

      $scope.regController.openBlock[lastActiveBlock] = true;
      $scope.regController.showBlock.subscription = false;
    }

    function submit(subscriptionForm) {
      if (!subscriptionForm.$valid) return;

      $scope.regController.plan = vm.subscription.plan.plan;
      $scope.regController.plan.startDate = vm.subscription.startDate;

      openAccordion();
    }

    function openAccordion() {
      var nextBlock = $filter('nextItem')(
        $scope.regController.registrationBlocks,
        $scope.regController.activeBlock.label
      );

      $scope.regController.activeBlock.label = nextBlock.label;
      $scope.regController.openBlock[nextBlock.id] = true;
    }
  }
})();
