(function() {
  'use strict';

  angular
    .module('careerKeyCentral', [
      'app.core',
      'app.routes',
      'app.sidebar',
      'app.user',
      'app.owner',
      'app.counselor',
      'app.stakeholder',
      'app.technical',
      'app.ckadmin',
      'app.discovererProgress',
      'app.careerDecisionProfileClient',
      'app.pathAdvisor',
      'app.shared',
      'app.organization',
      'app.account',
      'app.navsearch',
      'app.preloader',
      'app.loadingbar',
      'app.translate',
      'app.settings',
      'app.utils',
      'app.mailbox',
      'app.notification',
      'app.tour',
      'LocalStorageModule',
      'ngSlimScroll',
      'btorfs.multiselect',
      'ngclipboard',
      'toaster',
      'ngCsv',
      'ui.mask',
      'app.customerAccount',
      'app.sponsoredOrganization',
      'ngFileSaver'
    ])

    // Set token on every $http request
    .config([
      '$httpProvider',
      '$stateProvider',
      '$urlRouterProvider',
      '$locationProvider',
      '$qProvider',
      function($httpProvider) {
        $httpProvider.interceptors.push('tokenInterceptor');
      }
    ])

    .config([
      'localStorageServiceProvider',
      function (localStorageServiceProvider) {
        localStorageServiceProvider.setPrefix('ckCentralClientR3');
      }
    ])

    .run([
      '$state',
      '$rootScope',
      'SessionService',
      'AccessRoleService',
      '$window',
      'toaster',
      'UserProfileService',
      'APP_CONSTANTS',
      function(
        $state,
        $rootScope,
        SessionService,
        AccessRoleService,
        $window,
        toaster,
        UserProfileService,
        APP_CONSTANTS
      ) {
        $rootScope._ = $window._;
        $rootScope.moment = $window.moment;
        $rootScope.issueAccessTokenFlag = false;

        // To retain the filters selected in discovery progress and CDP Clients page
        $rootScope.ckdFilters = {};
        $rootScope.cdpFilters = {};

        // $rootScope.language.set(userLanguage.toLowerCase());
        $rootScope.$on('$stateChangeStart', function(event, nextRoute) {
          $rootScope.stateLoading = true;
          var user = SessionService.getUser();
          var menu = [];

          if (user) {
            menu = SessionService.getMenus();
          }

          if (nextRoute.secured) {
            if (
              !SessionService.isAuthenticatedUser() ||
              !AccessRoleService.isAuthorized(nextRoute.name)
            ) {
              event.preventDefault();
              $state.go('login');
            }
          }

          $rootScope.isFirstMenuActive = false;
          if (APP_CONSTANTS.dashboardList.indexOf(nextRoute.name) >= 0) {
            // var user = SessionService.getUser();

            if (
              _.isEmpty(
                menu.find(function(userMenu) {
                  if (userMenu.sref === nextRoute.name) return userMenu;
                })
              )
            ) {
              event.preventDefault();
              $state.go('login');
            }

            if (
              user.roles.isAccountManager ||
              user.roles.isOrganizationManager ||
              user.roles.isCounselor ||
              user.roles.isTechnicalContact
            ) {
              $rootScope.isFirstMenuActive = menu[1].sref == nextRoute.name;
              // if (user.isFirstTimeLogin) {// check is user admin?
              //   setTimeout(function() {
              //     toaster.pop('custom', APP_CONSTANTS.MESSAGE.firstTimeLogin);
              //   }, 2000);
              //   user.isFirstTimeLogin = false;
              //   UserProfileService.updateUser(user)
              //     .then(function() {
              //       SessionService.setUser(user);
              //     })
              //     .catch(function(error) {
              //       toaster.pop(
              //         'error',
              //         error.data.message || error.statusText
              //       );
              //     });
              // }
            }
          }

          if (
            APP_CONSTANTS.publiclyAccessibleRoutes.indexOf(nextRoute.name) >= 0
          ) {
            if (SessionService.isAuthenticatedUser()) {
              event.preventDefault();
              $state.go(
                _.get(
                  menu[1],
                  'sref',
                  AccessRoleService.getHighPriorityRole().state
                )
              );
            }
          }

          if (nextRoute.name === 'admin') {
            event.preventDefault();
            $state.go('app.ckadmin.products');
          }
        });

        $rootScope.$on('$stateChangeSuccess', function() {
          $rootScope.stateLoading = false;
        });

        $rootScope.$on('$stateChangeError', function() {
          $rootScope.stateLoading = false;
        });
      }
    ]);

  var initInjector = angular.injector(['ng']);
  var $http = initInjector.get('$http');

  return $http
    .get('config.json')
    .then(function(response) {
      angular.module('careerKeyCentral').constant('ENV', response.data);
    })
    .then(function bootstrapApplication() {
      angular.element(document).ready(function() {
        angular.bootstrap(document, ['careerKeyCentral']);
      });
    });
})();
