(function() {
  'use strict';
  angular.module('app.sponsoredOrganization').config(state);

  state.$inject = ['$stateProvider', 'RouteHelpersProvider'];

  function state($stateProvider, helper) {
    $stateProvider
      .state('app.sponsoredOrg', {
        url: '/sponsored-organization',
        controller: 'SponsoredOrganizationController',
        controllerAs: 'vm',
        abstract: true,
        templateUrl: helper.basepath('v2/sponsoredOrganization/index.html'),
        secured: true
      })
      .state('app.sponsoredOrg.OrgInfo', {
        url: '/organization-info',
        title: 'Organization Info',
        controller: 'OrganizationInfoController',
        controllerAs: 'vm',
        templateUrl: helper.basepath(
          'v2/sponsoredOrganization/organizationInfo/organizationInfo.html'
        ),
        resolve: helper.resolveFor(
          'ui.select',
          'filestyle',
          'ngImgCrop',
          'modernizr',
          'icons',
          'ngDialog'
        ),
        secured: true
      })
      .state('app.sponsoredOrg.technicalDashboard', {
        url: '/technical-dashboard',
        controller: 'TechnicalDashboardController',
        controllerAs: 'vm',
        title: 'Technical Dashboard',
        templateUrl: helper.basepath(
          'v2/sponsoredOrganization/technicalDashboard/technicalDashboard.html'
        ),
        resolve: helper.resolveFor(
          'datatables',
          'ui.select',
          'colorpicker.module',
          'ngDialog',
          'ngImgCrop',
          'icons',
          'filestyle'
        ),
        secured: true
      })
      .state('app.sponsoredOrg.userManagement', {
        url: '/user-management',
        controller: 'UserManagementController',
        controllerAs: 'vm',
        title: 'User Management',
        templateUrl: helper.basepath(
          'v2/sponsoredOrganization/userManagement/userManagement.html'
        ),
        resolve: helper.resolveFor('ui.select', 'datatables', 'ngDialog'),
        secured: true
      });
  }
})();
