(function() {
  'use strict';
  angular.module('app.user').factory('AchService', AchService);

  AchService.$inject = [
    'APP_CONSTANTS',
    '$q',
    '$filter',
    'ApiCaller',
    'SubscriptionPlansService',
    'RegistrationService'
  ];

  function AchService(
    APP_CONSTANTS,
    $q,
    $filter,
    ApiCaller,
    SubscriptionPlansService,
    RegistrationService
  ) {
    var self = this;

    self.subscribe = subscribe;
    self.fetchHostedPageDetails = fetchHostedPageDetails;
    self.destroyRegistrationData = destroyRegistrationData;

    function fetchHostedPageDetails(hostedPageId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.hostedPage,
        APP_CONSTANTS.rootUrl,
        hostedPageId
      );

      ApiCaller.apiGet(url).then(
        function(result) {
          deferred.resolve(result);
        },
        function(err) {
          deferred.reject(err);
        }
      );

      return deferred.promise;
    }

    function subscribe(hostedPageDetail) {
      var subscription = {};

      subscription.plan = SubscriptionPlansService.retrievePlan().plan;
      subscription.customerId = hostedPageDetail.customerId;
      subscription.cbCustomer = hostedPageDetail.customer;
      var deferred = $q.defer();

      var url = $filter('sprintf')(
        APP_CONSTANTS.subscription,
        APP_CONSTANTS.rootUrl
      );

      ApiCaller.apiPost(url, subscription).then(
        function(result) {
          deferred.resolve(result);
        },
        function(err) {
          deferred.reject(err);
        }
      );

      return deferred.promise;
    }

    function destroyRegistrationData() {
      SubscriptionPlansService.destroyPlan();
      RegistrationService.destroyRegistrationInfo();
    }

    return self;
  }
})();
