/**
 * Created by Romit Amgai<romitamgai@lftechnology.com> on 4/18/17.
 */
(function() {
  'use strict';
  angular.module('app.user').factory('BillingInfoService', BillingInfoService);

  BillingInfoService.$inject = [
    'localStorageService',
    'APP_CONSTANTS',
    'ApiCaller',
    '$filter',
    '$q'
  ];

  function BillingInfoService(
    localStorageService,
    APP_CONSTANTS,
    ApiCaller,
    $filter,
    $q
  ) {
    var self = this;

    self.getTaxEstimate = getTaxEstimate;
    self.getAddonTaxEstimate = getAddonTaxEstimate;

    function getTaxEstimate(planId, billingInfo, customerInfo, promotionCode) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.estimateTax,
        APP_CONSTANTS.rootUrl
      );
      var estimatePayload = {
        billingInfo: {},
        customerInfo: {},
        couponCodes: promotionCode || []
      };

      angular.copy(billingInfo, estimatePayload.billingInfo);
      angular.copy(customerInfo, estimatePayload.customerInfo);

      estimatePayload.billingInfo.country =
        estimatePayload.billingInfo.country.countryCode;
      estimatePayload.planId = planId;

      ApiCaller.apiPost(url, estimatePayload).then(
        function(result) {
          deferred.resolve(result);
        },
        function(err) {
          deferred.reject(err);
        }
      );

      return deferred.promise;
    }

    function getAddonTaxEstimate(
      planId,
      addonId,
      billingInfo,
      customerInfo,
      quantity
    ) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.estimateAddonTax,
        APP_CONSTANTS.rootUrl
      );
      var estimatePayload = {
        billingInfo: {},
        customerInfo: {}
      };

      angular.copy(billingInfo, estimatePayload.billingInfo);
      angular.copy(customerInfo, estimatePayload.customerInfo);

      estimatePayload.billingInfo.country =
        estimatePayload.billingInfo.country.countryCode;
      estimatePayload.id = addonId;
      estimatePayload.planId = planId;
      estimatePayload.quantity = quantity;

      ApiCaller.apiPost(url, estimatePayload).then(
        function(result) {
          deferred.resolve(result);
        },
        function(err) {
          deferred.reject(err);
        }
      );

      return deferred.promise;
    }

    return self;
  }
})();
