/**
 * Created by Romit Amgai<romitamgai@lftechnology.com> on 4/18/17.
 */

(function() {
  'use strict';
  angular
    .module('app.user')
    .controller('RegistrationTokenController', RegistrationTokenController);
  RegistrationTokenController.$inject = [
    '$state',
    'RegistrationTokenService',
    '$stateParams'
  ];

  function RegistrationTokenController(
    $state,
    RegistrationTokenService,
    $stateParams
  ) {
    var vm = this;

    vm.loading = true;
    var tokenId = $stateParams.tokenId;

    if (!tokenId) {
      $state.go('login');

      return;
    }

    RegistrationTokenService.store(tokenId);
    vm.loading = false;
    $state.go('register');
  }
})();
