(function() {
  'use strict';

  angular.module('app.shared').factory('CustomerService', CustomerService);

  CustomerService.$inject = [
    'APP_CONSTANTS',
    'ApiCaller',
    '$q',
    '$filter',
    '$state',
    'SubscriptionPlansService',
    'RegistrationService',
    'localStorageService',
    'SessionService'
  ];

  function CustomerService(
    APP_CONSTANTS,
    ApiCaller,
    $q,
    $filter,
    $state,
    SubscriptionPlansService,
    RegistrationService,
    localStorageService,
    SessionService
  ) {
    var self = this;

    self.register = register;
    self.storePlan = storePlan;
    self.storeEmail = storeEmail;
    self.retrievePlan = retrievePlan;
    // self.fetchTrialPlan = fetchTrialPlan;
    self.fetchCustomerList = fetchCustomerList;
    self.storeRegistrationInfo = storeRegistrationInfo;
    self.destroyRegistrationData = destroyRegistrationData;
    self.getCustomerLoggedInDetails = getCustomerLoggedInDetails;

    function fetchCustomerList(
      pageNumber,
      pageSize,
      searchKey,
      sortBy,
      orderBy
    ) {
      var deferred = $q.defer();
      var parameters = {
        pageNumber: pageNumber,
        pageSize: pageSize,
        searchKey: searchKey,
        isReseller: false,
        sortBy: sortBy,
        orderBy: orderBy
      };

      var url = $filter('sprintf')(
        APP_CONSTANTS.customerCollection,
        APP_CONSTANTS.rootUrl,
        $.param(parameters)
      );

      ApiCaller.apiGet(url).then(
        function(result) {
          deferred.resolve(result);
        },
        function(err) {
          deferred.reject(err);
        }
      );

      return deferred.promise;
    }

    function retrievePlan() {
      return SubscriptionPlansService.retrievePlan();
    }

    function storePlan(data) {
      SubscriptionPlansService.storePlan(data);
    }

    function storeRegistrationInfo(data) {
      RegistrationService.storeRegistrationInfo(data);
    }

    // function fetchTrialPlan() {
    //   var deferred = $q.defer();
    //   var url = $filter('sprintf')(
    //     APP_CONSTANTS.planTrial,
    //     APP_CONSTANTS.rootUrl
    //   );

    //   ApiCaller.apiGet(url).then(
    //     function(result) {
    //       deferred.resolve(result);
    //     },
    //     function(err) {
    //       deferred.reject(err);
    //     }
    //   );

    //   return deferred.promise;
    // }

    function register() {
      var registrationInfo = RegistrationService.retrieveRegistrationInfo();

      registrationInfo.planId = registrationInfo.schoolType
        ? APP_CONSTANTS.trialMedium
        : APP_CONSTANTS.trialHigher;

      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.userSignUpTrial,
        APP_CONSTANTS.rootUrl
      );

      ApiCaller.apiPost(url, registrationInfo).then(
        function(result) {
          deferred.resolve(result);
        },
        function(err) {
          deferred.reject(err);
        }
      );

      return deferred.promise;
    }

    function getCustomerLoggedInDetails(customerId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.customerToken,
        APP_CONSTANTS.rootUrl,
        customerId
      );

      ApiCaller.apiGet(url).then(
        function(result) {
          deferred.resolve(result);
        },
        function(err) {
          deferred.reject(err);
        }
      );

      return deferred.promise;
    }

    self.loginAsCustomer = (customerId) => getCustomerLoggedInDetails(customerId)
      .then(result => {
        const primaryToken = {
          user: SessionService.getUser(),
          customerImg: SessionService.getOrganizationImage()
        };

        SessionService.setAdminToken(primaryToken);
        SessionService.setUser(result.data.data);
        SessionService.setOrganizationImage(result.data.data.customerImg);

        $state.go('login');
      });

    function destroyRegistrationData() {
      SubscriptionPlansService.destroyPlan();
      RegistrationService.destroyRegistrationInfo();
    }

    function storeEmail(email) {
      localStorageService.set('discoverer', email);
    }

    return self;
  }
})();
