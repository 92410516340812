(function() {
  'use strict';
  angular
    .module('app.shared')
    .controller(
      'MappedRegionsForCareerController',
      MappedRegionsForCareerController
    );

  MappedRegionsForCareerController.$inject = [
    'APP_CONSTANTS',
    '$scope',
    'isPublished',
    'selectedCareer',
    'productRegionList',
    '$uibModalInstance',
    'ProductService'
  ];

  function MappedRegionsForCareerController(
    APP_CONSTANTS,
    $scope,
    isPublished,
    selectedCareer,
    productRegionList,
    $uibModalInstance,
    ProductService
  ) {
    var vm = this;

    vm.loading = {};
    vm.productRegionList = productRegionList;
    vm.mappedRegionList = [];
    vm.isPublished = isPublished;
    vm.selectedCareer = selectedCareer;
    vm.dismissModal = dismissModal;
    vm.saveMappedRegions = saveMappedRegions;
    vm.productId = ProductService.getProductId();
    vm.selectedCareerName = _.get(
      selectedCareer,
      'variations.en.name',
      APP_CONSTANTS.occupation
    );

    vm.updatedRegionIdArray = [];

    checkUnpublishedCareersAlreadyExist();

    function checkUnpublishedCareersAlreadyExist() {
      var alreadyUnpublishedCareer = $scope.$parent.vm.getUnpublishedCareerIfExist(
        vm.selectedCareer.id
      );

      if (alreadyUnpublishedCareer)
        vm.selectedCareer = alreadyUnpublishedCareer;

      if (!vm.selectedCareer.regions) {
        vm.selectedCareer.regions = [];
      }

      fetchRegionsByIds(vm.selectedCareer.regions);
    }

    function fetchRegionsByIds(regionIdArray) {
      regionIdArray = regionIdArray || [];

      regionIdArray.forEach(function(regionId) {
        vm.productRegionList.forEach(function(region) {
          if (region.id === regionId) {
            vm.mappedRegionList.push({
              id: region.id,
              name: region.name
            });
          }
        });
      });
    }

    function getRegionIdArray(regionArray) {
      regionArray.forEach(function(region) {
        vm.updatedRegionIdArray.push(region.id);
      });

      return vm.updatedRegionIdArray;
    }

    function saveMappedRegions() {
      $scope.$parent.vm.updateUnpublishedCareers(vm.selectedCareer.id);
      $scope.$parent.vm.updateMappedRegionsInCareer(
        vm.selectedCareer.id,
        getRegionIdArray(vm.mappedRegionList)
      );
      dismissModal();
    }

    function dismissModal() {
      $uibModalInstance.dismiss();
    }
  }
})();
