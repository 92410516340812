(function() {
  'use strict';
  angular.module('app.customerAccount').config(state);

  state.$inject = ['$stateProvider', 'RouteHelpersProvider'];

  function state($stateProvider, helper) {
    $stateProvider
      .state('app.customerAcc', {
        url: '/customer-account',
        abstract: true,
        templateUrl: helper.basepath('v2/customerAccount/views/index.html'),
        controller: 'CustomerAccountController',
        controllerAs: 'vm',
        secured: true
      })
      .state('app.customerAcc.accountInfo', {
        url: '/account-info',
        title: 'Account Info',
        templateUrl: helper.basepath(
          'v2/customerAccount/views/partials/accountUpdate.html'
        ),
        controller: 'AccountUpdateController',
        controllerAs: 'vm',
        resolve: helper.resolveFor(
          'ui.select',
          'filestyle',
          'ngImgCrop',
          'modernizr',
          'icons',
          'ngDialog'
        ),
        secured: true
      })
      .state('app.customerAcc.billing', {
        url: '/billing',
        title: 'Billing',
        templateUrl: helper.basepath(
          'v2/customerAccount/views/partials/billing.html'
        ),
        controller: 'NewBillingController',
        controllerAs: 'vm',
        resolve: helper.resolveFor('datatables', 'ui.select', 'ngDialog'),
        secured: true
      })
      .state('app.customerAcc.billingHistory', {
        url: '/billing-history',
        title: 'BillingHistory',
        templateUrl: helper.basepath(
          'v2/customerAccount/views/partials/billingHistory.html'
        ),
        controller: 'BillingHistoryController',
        controllerAs: 'vm',
        resolve: helper.resolveFor('datatables', 'ui.select'),
        secured: true
      })
      .state('app.customerAcc.subscription', {
        url: '/customer-subscription',
        title: 'Customer Subscription',
        templateUrl: helper.basepath(
          'v2/customerAccount/views/partials/subscription.html'
        ),
        controller: 'NewSubscriptionController',
        controllerAs: 'vm',
        resolve: helper.resolveFor('datatables', 'ngDialog'),
        secured: true
      })
      .state('app.customerAcc.organizations', {
        url: '/organizations',
        title: 'Organization',
        templateUrl: helper.basepath(
          'v2/customerAccount/views/partials/organizations.html'
        ),
        controller: 'OrganizationController',
        controllerAs: 'vm',
        resolve: helper.resolveFor('datatables', 'ui.select', 'ngDialog'),
        secured: true
      });
  }
})();
