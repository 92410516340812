/**
 * Created by Romit Amgai<romitamgai@lftechnology.com> on 4/18/17.
 */

(function() {
  'use strict';
  angular
    .module('app.user')
    .controller('OrderSummaryController', OrderSummaryController);
  OrderSummaryController.$inject = [
    '$scope',
    '$state',
    'OrderSummaryService',
    'RegistrationService',
    'toaster',
    'APP_CONSTANTS'
  ];

  function OrderSummaryController(
    $scope,
    $state,
    OrderSummaryService,
    RegistrationService,
    toaster,
    APP_CONSTANTS
  ) {
    var vm = this;

    vm.editSubscription = editSubscription;
    vm.cancelSubscription = cancelSubscription;
    vm.constants = {
      productLocales: APP_CONSTANTS.productLocales,
      decimalFixedValue: APP_CONSTANTS.decimalFixedValue,
      trialSubscription: APP_CONSTANTS.SUBSCRIPTION_TYPE.TRIAL
    };

    init();

    function init() {
      if ($scope.regController.plan && !_.isEmpty($scope.regController.plan)) {
        $scope.regController.orderSummary.totalAmount =
          $scope.regController.plan.price *
          ($scope.regController.plan.billing_cycles ||
            APP_CONSTANTS.defaultBillingCycle);
        vm.selectedLocale =
          $scope.regController.plan.cf_language_name +
          ' - ' +
          $scope.regController.plan.cf_country_id;

        if ($scope.regController.plan.setup_cost) {
          $scope.regController.orderSummary.setupCost =
            $scope.regController.plan.setup_cost;
          $scope.regController.orderSummary.totalAmount +=
            $scope.regController.plan.setup_cost;
        }
      }
    }

    function editSubscription() {
      var keys = Object.keys($scope.regController.openBlock);

      var lastActiveBlock = keys.filter(function(key) {
        return $scope.regController.openBlock[key];
      });

      RegistrationService.setLastActiveBlock(lastActiveBlock);
      $scope.regController.showBlock.subscription = true;
      $scope.regController.openBlock.subscription = true;
    }

    function cancelSubscription() {
      var lastActiveBlock = RegistrationService.getLastActiveBlock();

      $scope.regController.openBlock[lastActiveBlock] = true;
      $scope.regController.showBlock.subscription = false;
    }

    /* eslint-disable */
    vm.changeProductLocale = function(locale) {
      // TODO API Call on product change
    };
    /* eslint-enable */
  }
})();
