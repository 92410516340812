(function() {
  'use strict';

  angular.module('app.shared').factory('BatchesService', BatchesService);

  BatchesService.$inject = ['APP_CONSTANTS', 'ApiCaller', '$filter', '$q'];

  function BatchesService(APP_CONSTANTS, ApiCaller, $filter, $q) {
    var self = this;

    self.fetchBatchesByProductId = fetchBatchesByProductId;
    self.fetchBatchesByProductAndType = fetchBatchesByProductAndType;

    function fetchBatchesByProductAndType(productId, batchType) {
      var deferred = $q.defer();
      var parameters = {
        productId: productId,
        batchType: batchType
      };
      var url = $filter('sprintf')(
        APP_CONSTANTS.productBatches,
        APP_CONSTANTS.rootUrl,
        productId,
        $.param(parameters)
      );

      ApiCaller.apiGet(url)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function fetchBatchesByProductId(
      productId,
      pageNumber,
      pageSize,
      searchKey,
      sortBy,
      orderBy,
      uploadStartDate,
      uploadEndDate
    ) {
      var deferred = $q.defer();
      var parameters = {
        pageNumber: pageNumber,
        pageSize: pageSize,
        searchKey: searchKey,
        sortBy: sortBy,
        orderBy: orderBy,
        uploadStartDate: uploadStartDate,
        uploadEndDate: uploadEndDate
      };

      var url = $filter('sprintf')(
        APP_CONSTANTS.productBatches,
        APP_CONSTANTS.rootUrl,
        productId,
        $.param(parameters)
      );

      ApiCaller.apiPost(url, { productId: productId })
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    return self;
  }
})();
