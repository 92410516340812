(function() {
  'use strict';
  angular
    .module('app.shared')
    .controller('SchoolsController', SchoolsController);

  SchoolsController.$inject = [
    '$scope',
    'APP_CONSTANTS',
    'toaster',
    '$uibModal',
    'SchoolsService'
  ];

  function SchoolsController(
    $scope,
    APP_CONSTANTS,
    toaster,
    $uibModal,
    SchoolsService
  ) {
    var vm = this;

    var modalInstance = '';

    vm.loading = {};
    vm.schools = [];
    vm.pageNum = 1;
    vm.orderCol = 0;
    vm.sortBy = 'name';
    vm.orderBy = 'asc';
    vm.pageSize = 10;
    vm.searchKey = '';
    vm.totalRecords = 0;
    vm.isDateOpen = false;
    vm.loadedNewData = false;
    vm.refreshTimeStamp = Date.now();
    vm.pageSizeOptions = APP_CONSTANTS.pageSizeOptions;

    vm.selectedPageSize = {
      key: "'" + vm.pageSize + "'",
      value: vm.pageSize
    };

    vm.getAllBatches = getAllBatches;
    vm.filterSelected = filterSelected;
    vm.fetchAllSchools = fetchAllSchools;
    vm.openMajorsModal = openMajorsModal;

    vm.dtOptions = {
      paging: false,
      searching: false,
      info: false,
      order: [],
      columnDefs: [
        {
          orderable: false,
          targets: [1, 4, 5, 6]
        },
        {
          orderDataType: 'disable-custom-sort',
          targets: [0, 2, 3]
        }
      ]
    };

    fetchAllSchools();

    $scope.$parent.vm.setActiveTab();

    // TODO: Replace dummy data with appropriate API call
    function getAllBatches() {
      vm.batches = [
        {
          id: 1,
          name: 'Batch1'
        },
        {
          id: 2,
          name: 'Batch2'
        },
        {
          id: 3,
          name: 'Batch3'
        },
        {
          id: 4,
          name: 'Batch4'
        },
        {
          id: 5,
          name: 'Batch5'
        }
      ];
    }

    $.fn.dataTable.ext.order['disable-custom-sort'] = function(settings, col) {
      var scope = angular.element($('.table-responsive')).scope();

      if (scope.vm.orderCol !== col) {
        scope.vm.orderCol = col;
        scope.vm.orderBy = 'asc';

        if (col === 0) {
          scope.vm.sortBy = 'name';
        } else if (col === 1) {
          scope.vm.sortBy = 'id';
        } else if (col === 2) {
          scope.vm.sortBy = 'city';
        } else if (col === 3) {
          scope.vm.sortBy = 'url';
        }

        scope.vm.loadedNewData = true;

        scope.vm.fetchAllSchools(
          scope.vm.pageNum,
          scope.vm.pageSize,
          scope.vm.searchKey,
          scope.vm.sortBy,
          scope.vm.orderBy
        );
      } else if (scope.vm.orderCol === col) {
        if (scope.vm.loadedNewData) {
          scope.vm.loadedNewData = false;
        } else {
          scope.vm.orderBy = scope.vm.orderBy === 'asc' ? 'desc' : 'asc';
          scope.vm.loadedNewData = true;
          scope.vm.fetchAllSchools(
            scope.vm.pageNum,
            scope.vm.pageSize,
            scope.vm.searchKey,
            scope.vm.sortBy,
            scope.vm.orderBy
          );
        }
      }

      return false;
    };

    function fetchAllSchools(pageNum, pageSize, searchKey, sortBy, orderBy) {
      vm.loading.productInfo = true;
      vm.pageSize = angular.isUndefined(vm.selectedPageSize.value)
        ? 10
        : vm.selectedPageSize.value;

      var params = {
        pageNumber: pageNum || vm.pageNum,
        pageSize: pageSize || vm.pageSize,
        searchKey: searchKey || vm.searchKey,
        sortBy: sortBy || vm.sortBy,
        orderBy: orderBy || vm.orderBy
      };

      Object.keys(params).forEach(function(key) {
        if (!params[key]) {
          delete params[key];
        }
      });

      SchoolsService.fetchAllSchools(params)
        .then(function(result) {
          vm.loading.productInfo = false;
          vm.refreshTimeStamp = Date.now();

          vm.pageNum = params.pageNumber;

          vm.schools = _.get(result, 'data.data.schools', []);
          vm.totalRecords = _.get(result, 'data.data.totalCount', 0);
        })
        .catch(function(error) {
          vm.loading.productInfo = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    vm.getTableData = function(event) {
      var $targetElement = $(event.currentTarget);

      if (
        !$targetElement.hasClass('disabled') &&
        !$targetElement.hasClass('current')
      ) {
        var pageNumber = $targetElement.data('pageNum');

        vm.loadedNewData = true;

        fetchAllSchools(pageNumber);
      }
    };

    function filterSelected() {
      vm.searchKey = angular.isUndefined(vm.searchKey) ? '' : vm.searchKey;
      vm.pageSize = angular.isUndefined(vm.selectedPageSize.value)
        ? 10
        : vm.selectedPageSize.value;
      vm.loadedNewData = true;

      fetchAllSchools(1, vm.pageSize, vm.searchKey, vm.sortBy, vm.orderBy);
    }

    function openMajorsModal(selectedSchool) {
      if (!modalInstance) {
        modalInstance = $uibModal.open({
          templateUrl:
            'app/ckadmin/products/schools/mappedMajors/mappedMajors.html',
          controller: 'MappedMajorsSchoolsController',
          controllerAs: 'vm',
          animation: true,
          size: 'lg',
          scope: $scope,
          resolve: {
            selectedSchool: function() {
              return selectedSchool;
            }
          }
        });

        modalInstance.result
          .then(function() {
            modalInstance = '';
          })
          .catch(function() {
            modalInstance = '';
          });
      }
    }
  }
})();
