(function() {
  'use strict';

  angular
    .module('app.customerAccount')
    .factory('NewBillingService', NewBillingService);

  NewBillingService.$inject = ['ApiCaller', 'APP_CONSTANTS', '$q', '$filter'];

  function NewBillingService(ApiCaller, APP_CONSTANTS, $q, $filter) {
    var self = this;

    self.getAddons = getAddons;
    self.applyAddon = applyAddon;
    self.billingDetail = billingDetail;
    self.paymentDetail = paymentDetail;
    self.fetchCountries = fetchCountries;
    self.updatePoNumber = updatePoNumber;
    self.getAccountDetail = getAccountDetail;
    self.setBillingDetail = setBillingDetail;
    self.getCustomerPlans = getCustomerPlans;
    self.upgradeFreeTrial = upgradeFreeTrial;
    self.updateBillingDetail = updateBillingDetail;
    self.updatePaymentDetail = updatePaymentDetail;
    self.fetchActiveSubscriptions = fetchActiveSubscriptions;
    self.fetchPendingSubscriptions = fetchPendingSubscriptions;

    function setBillingDetail(billingDetail) {
      self.billingDetail = {
        zip: billingDetail.zip,
        city: billingDetail.city,
        line1: billingDetail.line1,
        line2: billingDetail.line2,
        last_name: billingDetail.last_name,
        first_name: billingDetail.first_name
      };

      return;
    }

    function billingDetail() {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.billingDetail,
        APP_CONSTANTS.rootUrl
      );

      ApiCaller.apiGet(url)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function getAccountDetail(userId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.accountDetail,
        APP_CONSTANTS.rootUrl,
        userId
      );

      ApiCaller.apiGet(url)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function updateBillingDetail(billingDetail) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.billingDetail,
        APP_CONSTANTS.rootUrl
      );

      ApiCaller.apiPut(url, billingDetail)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function paymentDetail() {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.paymentSource,
        APP_CONSTANTS.rootUrl
      );

      ApiCaller.apiGet(url)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function updatePoNumber(data) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.updatePoNumber,
        APP_CONSTANTS.rootUrl
      );

      ApiCaller.apiPut(url, data)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function updatePaymentDetail(payload) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.paymentSource,
        APP_CONSTANTS.rootUrl
      );

      ApiCaller.apiPut(url, payload)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function fetchCountries(name) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.countriesByCode,
        APP_CONSTANTS.rootUrl,
        name
      );

      ApiCaller.apiGet(url)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function fetchActiveSubscriptions(customerId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.activeSubscriptionList,
        APP_CONSTANTS.rootUrl,
        customerId
      );

      ApiCaller.apiGet(url)
        .then(function(response) {
          deferred.resolve(response);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function fetchPendingSubscriptions(customerId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.pendingSubscriptionList,
        APP_CONSTANTS.rootUrl,
        customerId
      );

      ApiCaller.apiGet(url)
        .then(function(response) {
          deferred.resolve(response);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function getAddons(planId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.addons,
        APP_CONSTANTS.rootUrl,
        planId
      );

      ApiCaller.apiGet(url)
        .then(function(response) {
          deferred.resolve(response);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function applyAddon(payload) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.applyAddons,
        APP_CONSTANTS.rootUrl
      );

      ApiCaller.apiPost(url, payload)
        .then(function(response) {
          deferred.resolve(response);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function upgradeFreeTrial(payload) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.upgradeFreeTrial,
        APP_CONSTANTS.rootUrl,
        payload.subscriptionId
      );

      ApiCaller.apiPut(url, payload)
        .then(function(response) {
          deferred.resolve(response);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function getCustomerPlans(params) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.customerPlanCollection,
        APP_CONSTANTS.rootUrl,
        $.param(params)
      );

      ApiCaller.apiGet(url).then(
        function(result) {
          self.plans = result.data.data;
          deferred.resolve(result.data.data);
        },
        function(err) {
          deferred.reject(err);
        }
      );

      return deferred.promise;
    }

    return self;
  }
})();
