(function() {
  'use strict';

  angular
    .module('app.shared')
    .factory('MappedClustersService', MappedClustersService);

  MappedClustersService.$inject = [
    'APP_CONSTANTS',
    'ApiCaller',
    '$filter',
    '$q'
  ];

  function MappedClustersService(APP_CONSTANTS, ApiCaller, $filter, $q) {
    var self = this;

    self.fetchAllClusters = fetchAllClusters;

    function fetchAllClusters(productId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.clusters,
        APP_CONSTANTS.rootUrl,
        productId
      );

      ApiCaller.apiGet(url)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    return self;
  }
})();
