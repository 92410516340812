(function() {
  'use strict';
  angular
    .module('careerKeyCentral')
    .controller('MessageSentListController', MessageSentListController);
  MessageSentListController.$inject = [
    '$state',
    'toaster',
    'SessionService',
    'APP_CONSTANTS',
    'MessageSentListService',
    '$stateParams'
  ];

  function MessageSentListController(
    $state,
    toaster,
    SessionService,
    APP_CONSTANTS,
    MessageSentListService,
    $stateParams
  ) {
    var vm = this;

    vm.loading = {};
    vm.displaySponsoredOrgMail = $stateParams.sponsoredOrganizationId
      ? true
      : false;

    var userLoginDetail = SessionService.getUser();
    var organizationId =
      $stateParams.sponsoredOrganizationId ||
      userLoginDetail.sponsoredOrganizationId;

    vm.fetchAllMail = function() {
      vm.loading.messages = true;
      MessageSentListService.fetchAllMail(organizationId).then(
        function(result) {
          vm.mailList = result.data.data;
          vm.loading.messages = false;
        },
        function(error) {
          vm.loading.messages = false;
          toaster.pop('error', error.data.message || error.statusText);
        }
      );
    };
    vm.fetchAllMail();
  }
})();
