(function() {
  'use strict';
  angular
    .module('app.user')
    .controller('BillingInvitationController', BillingInvitationController);
  BillingInvitationController.$inject = [
    '$state',
    'BillingInvitationService',
    'APP_CONSTANTS',
    'toaster'
  ];

  function BillingInvitationController(
    $state,
    BillingInvitationService,
    APP_CONSTANTS,
    toaster
  ) {
    var vm = this;

    vm.register = register;
    vm.billingContactList = false;

    function register(valid) {
      if (!valid) {
        vm.formSubmit = true;

        return;
      }
      vm.loading = true;
      BillingInvitationService.register(vm.email).then(
        function() {
          BillingInvitationService.destroyRegistrationData();
          toaster.pop({
            type: 'info',
            title: APP_CONSTANTS.successHeader,
            body: APP_CONSTANTS.registrationSuccessWithBillingInvitatioin,
            showCloseButton: true,
            bodyOutputType: 'trustedHtml',
            timeout: 5000,
            onHideCallback: function() {
              vm.loading = false;
              $state.go('login');
            }
          });
        },
        function(error) {
          vm.loading = false;
          toaster.pop('error', error.data.message || error.statusText);
        }
      );
    }
  }
})();
