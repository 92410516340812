(function () {
  'use strict';

  angular.module('careerKeyCentral').directive('adjustHeight', adjustHeight);

  adjustHeight.$inject = ['$timeout'];

  function adjustHeight($timeout) {
    function updateHeight(row) {
      $timeout(function () {
        var cells = row.find('th,td');
        var minHeight = 22; // min-height for a row

        cells.each(function (i, cell) {
          minHeight = minHeight > $(cell).height() ? minHeight : $(cell).height();
        });
        cells.height(minHeight);
      }, 10);
    }

    function link(scope, element, attributes) {
      var row = element.closest('tr');

      if (!attributes.adjustHeight) return updateHeight(row);

      scope.$watch(attributes.adjustHeight, function (value) {
        value && updateHeight(row);
      });
    }

    return {
      link
    }
  }
})();
