(function() {
  'use strict';

  angular.module('app.shared').factory('MajorsService', MajorsService);

  MajorsService.$inject = ['APP_CONSTANTS', 'ApiCaller', '$filter', '$q'];

  function MajorsService(APP_CONSTANTS, ApiCaller, $filter, $q) {
    var self = this;

    self.saveDraft = saveDraft;
    self.revertMajor = revertMajor;
    self.publishMajor = publishMajor;
    self.addToUnpublished = addToUnpublished;
    self.searchWorkgroupMajors = searchWorkgroupMajors;
    self.fetchMajorPublishStatus = fetchMajorPublishStatus;
    self.fetchMajorsByWorkgroupId = fetchMajorsByWorkgroupId;

    function fetchMajorsByWorkgroupId(
      productId,
      workgroupId,
      isPublished,
      searchKey,
      batchId,
      tagId,
      regionId
    ) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        isPublished
          ? APP_CONSTANTS.workgroupMajors
          : APP_CONSTANTS.workgroupUnpublishedMajors,
        APP_CONSTANTS.rootUrl,
        productId,
        workgroupId,
        searchKey || '',
        batchId,
        tagId,
        regionId
      );

      ApiCaller.apiGet(url)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function fetchMajorPublishStatus(workgroupId, majorId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.majorPublishStatus,
        APP_CONSTANTS.rootUrl,
        workgroupId,
        majorId
      );

      ApiCaller.apiGet(url)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function addToUnpublished(workgroupId, majorId, productId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.addWorkgroupUnpublishedMajors,
        APP_CONSTANTS.rootUrl,
        workgroupId,
        majorId
      );

      ApiCaller.apiPost(url, { productId: productId })
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function revertMajor(workgroupId, majorId, productId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.revertUnpublishedMajors,
        APP_CONSTANTS.rootUrl,
        workgroupId,
        majorId
      );

      ApiCaller.apiPost(url, { productId: productId })
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function saveDraft(updatedMajor, workgroupId, majorId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.saveMajorDraft,
        APP_CONSTANTS.rootUrl,
        workgroupId,
        majorId
      );

      ApiCaller.apiPut(url, updatedMajor)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function publishMajor(workgroupId, majorId, productId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.publishMajor,
        APP_CONSTANTS.rootUrl,
        workgroupId,
        majorId
      );

      ApiCaller.apiPut(url, { productId: productId })
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function searchWorkgroupMajors(productId, params) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.searchProductWorkgroups,
        APP_CONSTANTS.rootUrl,
        productId,
        $.param(params)
      );

      ApiCaller.apiGet(url)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    return self;
  }
})();
