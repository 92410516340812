(function () {
  'use strict';
  angular
    .module('app.user')
    .controller('AddCustomerController', AddCustomerController);
  AddCustomerController.$inject = [
    'APP_CONSTANTS',
    '$scope',
    '$uibModalInstance',
  ];

  function AddCustomerController(
    APP_CONSTANTS,
    $scope,
    $uibModalInstance
  ) {
    var vm = this;

    vm.loading = {};
    vm.orderSummary = {};
    vm.subscription = {};

    vm.openBlock = {
      subscription: true,
      accountInformation: false,
      confirmation: false,
    };

    $scope.closeModal = function () {
      $uibModalInstance.dismiss();
    }

    vm.gotoNextBlock = () => {
      let shouldOpenNextBlock = false;
      _.forIn(vm.openBlock, (val, key) => {
        if (shouldOpenNextBlock) {
          vm.openBlock[key] = true;
        }
        shouldOpenNextBlock = val;
      });
    }
  }
})();
