(function () {
  'use strict';
  angular.module('careerKeyCentral').filter('invitationRoleLabel', invitationRoleLabel);

  invitationRoleLabel.$inject = ['APP_CONSTANTS'];

  function invitationRoleLabel(APP_CONSTANTS) {
    return function (input) {
      let invitationRole = {};
      switch (input) {
        case 'isAccountManager':
          invitationRole = APP_CONSTANTS.invitationRoleList.find(function (role) {
            return role.role === APP_CONSTANTS.ACCOUNT_MANAGER;
          });
          break;

        case 'isOrganizationManager':
          invitationRole = APP_CONSTANTS.invitationRoleList.find(function (role) {
            return role.role === APP_CONSTANTS.ORGANIZATION_MANAGER;
          });
          break;

        case 'isCounselor':
          invitationRole = APP_CONSTANTS.invitationRoleList.find(function (role) {
            return role.role === APP_CONSTANTS.COUNSELOR;
          });
          break;

        case 'isGroupCounselor':
          invitationRole = APP_CONSTANTS.invitationRoleList.find(function (role) {
            return role.role === APP_CONSTANTS.GROUP_COUNSELOR;
          });
          break;

        case 'isTechnicalContact':
          invitationRole = APP_CONSTANTS.invitationRoleList.find(function (role) {
            return role.role === APP_CONSTANTS.TECHNICAL;
          });
          break;

        case 'isBillingContact':
          invitationRole = APP_CONSTANTS.invitationRoleList.find(function (role) {
            return role.role === APP_CONSTANTS.BILLING;
          });
          break;

        default:
          invitationRole = APP_CONSTANTS.invitationRoleList.find(function (role) {
            return role.role === input;
          });
          break;
      }
      return invitationRole.label || '';
    };
  }

})();
