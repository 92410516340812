(function() {
  'use strict';

  angular
    .module('app.shared')
    .factory('MappedMajorsForSchoolsService', MappedMajorsForSchoolsService);

  MappedMajorsForSchoolsService.$inject = [
    'APP_CONSTANTS',
    'ApiCaller',
    '$filter',
    '$q'
  ];

  function MappedMajorsForSchoolsService(
    APP_CONSTANTS,
    ApiCaller,
    $filter,
    $q
  ) {
    var self = this;

    self.fetchMappedMajors = fetchMappedMajors;
    self.updateMappedMajors = updateMappedMajors;

    function fetchMappedMajors(schoolId, cipArray) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.majorsByCipIds,
        APP_CONSTANTS.rootUrl,
        schoolId
      );

      ApiCaller.apiPost(url, { cipArray: cipArray })
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function updateMappedMajors(schoolId, cipArray) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.majorsByCipIds,
        APP_CONSTANTS.rootUrl,
        schoolId
      );

      ApiCaller.apiPut(url, { majors: cipArray })
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    return self;
  }
})();
