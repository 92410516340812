(function() {
  'use strict';
  angular
    .module('app.shared')
    .controller('DetailsController', DetailsController);

  DetailsController.$inject = [
    'APP_CONSTANTS',
    'toaster',
    '$scope',
    '$timeout',
    'ngDialog',
    'DetailsService',
    'ProductService'
  ];

  function DetailsController(
    APP_CONSTANTS,
    toaster,
    $scope,
    $timeout,
    ngDialog,
    DetailsService,
    ProductService
  ) {
    var vm = this;

    vm.loading = {};
    vm.allAssessments = [];
    vm.addTag = addTag;
    vm.addRegion = addRegion;
    vm.editRegion = editRegion;
    vm.editTag = editTag;
    vm.showEditRegionForm = [];
    vm.showEditTagForm = [];
    vm.displayEditRegionInput = displayEditRegionInput;
    vm.displayEditTagInput = displayEditTagInput;

    vm.fetchAllAssessments = fetchAllAssessments;
    vm.addSurveyVariation = addSurveyVariation;
    vm.removeSurveyVariation = removeSurveyVariation;
    vm.fetchProductDetails = fetchProductDetails;
    vm.openRemoveAssessmentDialog = openRemoveAssessmentDialog;

    vm.productId = ProductService.getProductId();
    vm.productAssessments = [];
    vm.productRegions = [];
    vm.productTags = [];
    vm.isOpen = true;

    $scope.$parent.vm.setActiveTab();

    fetchProductDetails();

    function displayEditRegionInput(index, status) {
      vm.showEditRegionForm[index] = status;

      $timeout(function() {
        document.getElementById('region-field' + index).focus();
      }, 0);
    }

    function displayEditTagInput(index, status) {
      vm.showEditTagForm[index] = status;

      $timeout(function() {
        document.getElementById('tag-field' + index).focus();
      }, 0);
    }

    function editRegion(index, region) {
      vm.loading.productInfo = true;
      displayEditRegionInput(index, false);

      DetailsService.editRegion(vm.productId, region)
        .then(function() {
          vm.loading.productInfo = false;

          fetchProductDetails();
          toaster.pop(
            'success',
            APP_CONSTANTS.MESSAGE.regionSuccessfullyEdited
          );
        })
        .catch(function(error) {
          vm.loading.productInfo = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function editTag(index, tag) {
      vm.loading.productInfo = true;
      displayEditTagInput(index, false);

      DetailsService.editTag(vm.productId, tag)
        .then(function() {
          vm.loading.productInfo = false;

          fetchProductDetails();
          toaster.pop('success', APP_CONSTANTS.MESSAGE.tagSuccessfullyEdited);
        })
        .catch(function(error) {
          vm.loading.productInfo = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function fetchProductDetails() {
      vm.loading.productInfo = true;

      DetailsService.fetchProductDetails(vm.productId)
        .then(function(result) {
          vm.loading.productInfo = false;

          vm.productAssessments = _.get(
            result,
            'data.products[0].surveyVariations',
            []
          );
          vm.productRegions = _.get(result, 'data.products[0].regions', []);
          vm.productTags = _.get(result, 'data.products[0].tags', []);

          fetchAllAssessments();
        })
        .catch(function(error) {
          vm.loading.productInfo = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function addSurveyVariation() {
      vm.loading.productInfo = true;

      DetailsService.addSurveyVariation(vm.productId, vm.assessment.id)
        .then(function() {
          vm.loading.productInfo = false;

          vm.assessment = '';
          fetchProductDetails();
          toaster.pop(
            'success',
            APP_CONSTANTS.MESSAGE.assessmentSuccessfullyAdded
          );
        })
        .catch(function(error) {
          vm.loading.productInfo = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function addRegion() {
      vm.loading.productInfo = true;

      DetailsService.addRegion(vm.productId, vm.region)
        .then(function() {
          vm.loading.productInfo = false;
          vm.region = '';

          fetchProductDetails();
          toaster.pop('success', APP_CONSTANTS.MESSAGE.regionSuccessfullyAdded);
        })
        .catch(function(error) {
          vm.loading.productInfo = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function addTag() {
      vm.loading.productInfo = true;

      DetailsService.addTag(vm.productId, vm.tag)
        .then(function() {
          vm.loading.productInfo = false;
          vm.tag = '';

          fetchProductDetails();
          toaster.pop('success', APP_CONSTANTS.MESSAGE.tagSuccessfullyAdded);
        })
        .catch(function(error) {
          vm.loading.productInfo = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function removeSurveyVariation(assessmentId) {
      vm.loading.productInfo = true;

      DetailsService.removeSurveyVariation(vm.productId, assessmentId)
        .then(function() {
          vm.loading.productInfo = false;

          fetchProductDetails();
          toaster.pop(
            'success',
            APP_CONSTANTS.MESSAGE.assessmentSuccessfullyRemoved
          );
        })
        .catch(function(error) {
          vm.loading.productInfo = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function fetchAllAssessments() {
      vm.loading.productInfo = true;

      DetailsService.fetchAllAssessments()
        .then(function(result) {
          vm.loading.productInfo = false;

          vm.allAssessments = _.get(result, 'data.cks', []);

          vm.filteredAssessments = [];

          vm.allAssessments.map(function(assessment) {
            if (
              !_.find(vm.productAssessments, function(productAssessment) {
                return productAssessment.cks === assessment.id;
              })
            ) {
              vm.filteredAssessments.push(assessment);
            }
          });
        })
        .catch(function(error) {
          vm.loading.productInfo = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function openRemoveAssessmentDialog(assessmentId) {
      ngDialog
        .openConfirm({
          template: 'removeAssessmentDialog'
        })
        .then(
          function() {
            removeSurveyVariation(assessmentId);
          },
          function() {
            ngDialog.close();
          }
        );
    }
  }
})();
