(function () {
  'use strict';

  angular.module('careerKeyCentral')
    .directive('googleSignInButton', () => new GoogleSignInButton);

  const googleLibraryLoaded = $.Deferred();
  const googleLibraryInitialized = $.Deferred();
  //https://developers.google.com/identity/gsi/web/reference/js-reference#onGoogleLibraryLoad
  window.onGoogleLibraryLoad = () => googleLibraryLoaded.resolve();

  class GoogleSignInButton {
    constructor() {
      Object.assign(this, {
        restrict: 'E',
        scope: {
          callback: '&callback'
        }
      });
    }

    controller($scope, APP_CONSTANTS) {
      googleLibraryLoaded.then(() => {
        google.accounts.id.initialize({
          client_id: APP_CONSTANTS.googleOauthClientId,
          callback: (response) => {
            const credential = Object.assign(
              {
                token: response.credential
              },
              JSON.parse(response.credential.split('.')[1].base64Decode())
            );

            $scope.callback({ credential });
          }
        });

        googleLibraryInitialized.resolve();
      });
    }

    link(scope, element, attrs) {
      //https://developers.google.com/identity/gsi/web/reference/js-reference#attribute_types
      const options = {
        type: "standard",
        theme: "filled_blue",
        size: "medium",
      };

      if (attrs.type === "icon") {
        options.type = "icon";
        options.theme = attrs.theme || "outline";
        options.size = attrs.size || "large";
        attrs.width = 40;
      }
      else if (!attrs.width || attrs.width < 201) {
        options.width = '201px';
      }
      else if (attrs.width > 400) {
        options.width = '400px';
      }
      else {
        options.width = attrs.width + 'px';
      }

      element.css({
        display: 'block',
        width: attrs.width + 'px',
        margin: '0 auto',
      });

      googleLibraryInitialized.then(() => {
        google.accounts.id.renderButton(
          element.context,
          options
        );
      });
    }
  }
})();
