(function () {
  'use strict';

  angular
    .module('app.pathAdvisor')
    .factory('PathAdvisorService', [
      'APP_CONSTANTS',
      'ApiCaller',
      '$filter',
      PathAdvisorService
    ]);

  function PathAdvisorService(
    APP_CONSTANTS,
    ApiCaller,
    $filter
  ) {
    const self = this;

    self.exportAllToCSV = exportAllToCSV;
    self.exportToCSV = exportToCSV;
    self.fetchDiscoverers = fetchDiscoverers;
    self.deleteDiscoverers = deleteDiscoverers;


    function deleteDiscoverers(discovererIds) {
      var url = $filter('sprintf')(
        APP_CONSTANTS.discoverersDelete,
        APP_CONSTANTS.rootUrl
      );
      const data = {
        discovererIds,
        assessmentType: APP_CONSTANTS.BOTH
      };

      return ApiCaller.apiDelete(url, data);
    }

    function exportAllToCSV(params) {
      const data = Object.assign({}, params, {
        type: 'pathAdvisor'
      })

      const url = $filter('sprintf')(
        APP_CONSTANTS.exportAllToCSV,
        APP_CONSTANTS.rootUrl
      );

      return ApiCaller.apiPost(url, {
        params: data
      });
    }

    function exportToCSV(params) {
      const data = Object.assign({}, params, {
        type: 'pathAdvisor'
      });

      const url = $filter('sprintf')(
        APP_CONSTANTS.exportSelectedToCSV,
        APP_CONSTANTS.rootUrl
      );

      return ApiCaller.apiFormPost(url, data);
    }

    function fetchDiscoverers(params) {
      const query = Object.assign({}, params, {
        type: 'pathAdvisor'
      });

      const url = $filter('sprintf')(
        APP_CONSTANTS.getAllDiscoverers,
        APP_CONSTANTS.rootUrl,
        $.param(query)
      );

      return ApiCaller.apiGet(url);
    }

    return self;
  }
})();
