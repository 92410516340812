(function () {
  angular
    .module('app.shared')
    .controller('SendEmailToAllController', SendEmailToAllController);

  SendEmailToAllController.$inject = [
    '$uibModalInstance',
    'tags',
    'SendEmailToAllService',
    'toaster',
    'APP_CONSTANTS'
  ];

  function SendEmailToAllController(
    $uibModalInstance,
    tags,
    SendEmailToAllService,
    toaster,
    APP_CONSTANTS
  ) {
    var vm = this;

    vm.loading = {};
    vm.customMessage = {
      subject: '',
      message: '',
      template: tags.category && tags.category.template || APP_CONSTANTS.CUSTOM,
    };
    vm.tags = tags;
    vm.sendMessage = sendMessage;
    vm.dismissModal = dismissModal;

    fetchTemplates();

    function fetchTemplates() {
      if (vm.customMessage.template === APP_CONSTANTS.CUSTOM) return;

      vm.loading.message = true;
      SendEmailToAllService
        .fetchEmailTemplates(vm.customMessage.template)
        .then(function (response) {
          vm.customMessage.subject = response.data.subject;
          vm.customMessage.message = response.data.message;
        })
        .catch(function (error) {
          toaster.pop('error', error.data.message || error.statusText);
        })
        .finally(function () {
          vm.loading.message = false;
        });
    }

    function sendMessage() {
      if (!vm.customMessage.subject || !vm.customMessage.message) return;

      $uibModalInstance.close(vm.customMessage);
    }

    function dismissModal() {
      $uibModalInstance.dismiss();
    }
  }
})();
