(function() {
  'use strict';
  angular
    .module('app.user')
    .controller(
      'RegistrationCompleteController',
      RegistrationCompleteController
    );

  RegistrationCompleteController.$inject = [
    '$state',
    'RegistrationCompleteService',
    'APP_CONSTANTS',
    'toaster'
  ];

  function RegistrationCompleteController(
    $state,
    RegistrationCompleteService,
    APP_CONSTANTS,
    toaster
  ) {
    var vm = this;

    vm.loading = {};
    vm.loading.registration = true;
    var hostedPageId = $state.params.id;

    RegistrationCompleteService.fetchHostedPageDetails(hostedPageId).then(
      function(result) {
        var paymentDetail = result.data.data.hosted_page.content;

        paymentDetail.customerId =
          result.data.data.hosted_page.pass_thru_content;
        RegistrationCompleteService.updateOrganizationPaymentDetails(
          paymentDetail
        ).then(
          function() {
            RegistrationCompleteService.destroyRegistrationData();
            vm.loading.registration = false;
            toaster.pop({
              type: 'info',
              title: APP_CONSTANTS.successHeader,
              body: APP_CONSTANTS.registrationSuccessWithPayment,
              showCloseButton: true,
              bodyOutputType: 'trustedHtml',
              timeout: 150000,
              onHideCallback: function() {
                $state.go('login');
              }
            });
          },
          function(error) {
            vm.loading.registration = false;
            toaster.pop('error', error.data.message || error.statusText);
          }
        );
      },
      function(error) {
        vm.loading.registration = false;
        toaster.pop('error', error.data.message || error.statusText);
      }
    );
  }
})();
