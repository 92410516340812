/**
 * Created by Romit Amgai<romitamgai@lftechnology.com> on 4/18/17.
 */
/* eslint-disable */
(function() {
  'use strict';
  angular
    .module('app.user')
    .controller('CheckoutController', CheckoutController);
  CheckoutController.$inject = [
    '$scope',
    '$state',
    'CheckoutService',
    'toaster',
    'APP_CONSTANTS'
  ];

  function CheckoutController(
    $scope,
    $state,
    CheckoutService,
    toaster,
    APP_CONSTANTS
  ) {
    var vm = this;
  }
})();
/* eslint-enable */
