(function() {
  'use strict';
  angular.module('app.user').controller('PaymentController', PaymentController);

  PaymentController.$inject = [
    '$state',
    'PaymentService',
    'APP_CONSTANTS',
    'toaster'
  ];

  function PaymentController($state, PaymentService, APP_CONSTANTS, toaster) {
    var vm = this;

    vm.loading = {};
    vm.loading.registration = true;
    var hostedPageId = $state.params.id;

    PaymentService.fetchHostedPageDetails(hostedPageId).then(
      function(result) {
        var paymentDetail = result.data.data.hosted_page.content;

        paymentDetail.customerId =
          result.data.data.hosted_page.pass_thru_content;
        paymentDetail.deletePendingSubscriptionData = true;
        PaymentService.updateOrganizationPaymentDetails(paymentDetail).then(
          function() {
            PaymentService.destroyRegistrationData();
            vm.loading.registration = false;
            $state.go('login');
          },
          function(error) {
            vm.loading.registration = false;
            toaster.pop('error', error.data.message || error.statusText);
          }
        );
      },
      function(error) {
        vm.loading.registration = false;
        toaster.pop('error', error.data.message || error.statusText);
      }
    );
  }
})();
