(function() {
  'use strict';

  angular
    .module('app.notification')
    .controller('NotificationViewController', NotificationViewController);

  NotificationViewController.$inject = [
    '$stateParams',
    'NotificationViewService',
    'APP_CONSTANTS',
    'toaster',
    'SessionService'
  ];

  function NotificationViewController(
    $stateParams,
    NotificationViewService,
    APP_CONSTANTS,
    toaster,
    SessionService
  ) {
    var vm = this;

    vm.loading = {};

    vm.userLoginDetail = SessionService.getUser();

    vm.fetchNotificationDetail = function() {
      vm.loading.mail = true;

      NotificationViewService.fetchNotificationDetail(
        $stateParams.notificationId
      ).then(
        function(result) {
          vm.notificationDetail = result.data.data;
          vm.loading.mail = false;
        },
        function(error) {
          vm.loading.mail = false;
          toaster.pop('error', error.data.message || error.statusText);
        }
      );
    };
    vm.fetchNotificationDetail();
  }
})();
