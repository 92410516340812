/**
 * Created by Romit Amgai<romitamgai@lftechnology.com> on 4/18/17.
 */

(function() {
  'use strict';
  angular
    .module('app.user')
    .controller('RegistrationController', RegistrationController);
  RegistrationController.$inject = [
    'RegistrationService',
    'APP_CONSTANTS',
    'getPlanDetails',
    '$scope',
    '$state',
    '$anchorScroll'
  ];

  function RegistrationController(
    RegistrationService,
    APP_CONSTANTS,
    getPlanDetails,
    $scope,
    $state
  ) {
    var vm = this;

    vm.isBillingInfoAvailable = isBillingInfoAvailable;
    vm.isAccountInfoAvailable = isAccountInfoAvailable;
    vm.isPaymentDetailAvailable = isPaymentDetailAvailable;

    vm.plan = {};
    vm.loading = {};
    vm.showBlock = {};
    vm.taxable = true;
    vm.activeBlock = {};
    vm.orderSummary = {};
    vm.registrationBlocks = [];
    vm.registrationDetails = {
      billingInfo: {},
      accountInfo: {}
    };

    vm.openBlock = {
      confirmation: false,
      subscription: false,
      accountInformation: true,
      billingInformation: false,
      paymentInformation: false,
      billingContactInformation: false
    };

    vm.signUpComplete = {
      type: '',
      status: false,
      activationId: '',
      paymentFailed: false,
      billingContactEmail: ''
    };

    vm.constants = {
      ck_w9: APP_CONSTANTS.EXTERNAL_URL.CK_W9,
      terms: APP_CONSTANTS.EXTERNAL_URL.TERMS,
      privacy: APP_CONSTANTS.EXTERNAL_URL.PRIVACY,
      trialSubscription: APP_CONSTANTS.SUBSCRIPTION_TYPE.TRIAL
    };

    init();

    function init() {
      angular.copy(getPlanDetails, vm.plan);
      if (vm.plan.id) {
        vm.showBlock.billing = vm.showBlock.payment =
          vm.plan.cf_subscription_type !== APP_CONSTANTS.REGISTER_TYPE.TRIAL;

        if (
          vm.plan.cf_subscription_type === APP_CONSTANTS.REGISTER_TYPE.TRIAL
        ) {
          vm.registrationDetails.billingInfo = {};
          vm.paymentType = APP_CONSTANTS.PAYMENT_PERIOD.TRIAL;
          RegistrationService.setBillingInfo(
            vm.registrationDetails.billingInfo
          );
          angular.copy(
            APP_CONSTANTS.REGISTRATION_BLOCK.TRIAL,
            vm.registrationBlocks
          );
        } else {
          vm.paymentType =
            vm.plan.cf_subscription_type.toLowerCase() ===
            APP_CONSTANTS.SUBSCRIPTION_TYPE.PREPAID
              ? APP_CONSTANTS.PAYMENT_PERIOD.PREPAID
              : vm.plan.period_unit.toLowerCase() ===
                APP_CONSTANTS.AUTO_RENEWAL_TYPE.MONTHLY
                ? APP_CONSTANTS.PAYMENT_PERIOD.AUTO_RENEWAL_MONTHLY
                : APP_CONSTANTS.PAYMENT_PERIOD.AUTO_RENEWAL_ANNUAL;
          angular.copy(
            APP_CONSTANTS.REGISTRATION_BLOCK.PURCHASE,
            vm.registrationBlocks
          );
        }

        vm.activeBlock.label = APP_CONSTANTS.LABEL.ACCOUNT_INFORMATION;
      } else {
        $state.go('login');
      }
    }

    function isBillingInfoAvailable() {
      return !angular.equals(vm.registrationDetails.billingInfo, {});
    }

    function isAccountInfoAvailable() {
      return !angular.equals(vm.registrationDetails.accountInfo, {});
    }

    function isPaymentDetailAvailable() {
      return !angular.equals(RegistrationService.getPaymentDetail(), {});
    }

    $scope.$watchCollection(
      angular.bind(this, function() {
        return vm.openBlock;
      }),
      function() {
        var keys = Object.keys(vm.openBlock);
        var activeLabel = _.filter(keys, function(key) {
          if (vm.openBlock[key]) {
            return key;
          }
        });

        if (activeLabel.length === 1) {
          var activeBlock = _.filter(vm.registrationBlocks, function(block) {
            if (activeLabel[0] === block.id) {
              return block;
            }
          });

          if (activeBlock.length > 0)
            vm.activeBlock.label = activeBlock[0].label;

          // if (vm.activeBlock.label !== APP_CONSTANTS.LABEL.CONFIRMATION)
          //   $anchorScroll();
        }
      }
    );
  }
})();
