(function() {
  'use strict';
  angular.module('app.shared').controller('GroupController', GroupController);

  GroupController.$inject = [
    'GroupService',
    'SessionService',
    'UserInvitationService',
    'fileUpload',
    'APP_CONSTANTS',
    'ngDialog',
    'toaster',
    '$scope',
    '$stateParams'
  ];

  function GroupController(
    GroupService,
    SessionService,
    UserInvitationService,
    fileUpload,
    APP_CONSTANTS,
    ngDialog,
    toaster,
    $scope,
    $stateParams
  ) {
    var vm = this;
    var discoverers = {};
    var isFromEdit = false;
    var organizationsDiscoverers = [];
    var currentUser = SessionService.getUser();
    // TODO: Need to change the naming to sponsoredOrganizationId
    var customerId = currentUser.sponsoredOrganizationId;

    vm.user = {};
    vm.disc = '';
    vm.group = {};
    vm.discoverer = {};
    vm.csvFile = '';
    vm.loading = {};
    vm.submit = submit;
    vm.breadCrumbs = '';
    vm.isChanged = false;
    vm.isCollapsed = true;
    vm.selectedUsers = [];
    vm.clearForm = clearForm;
    vm.filterUsers = filterUsers;
    vm.isShowCheckbox = false;
    vm.isShowCancelButton = false;
    vm.buttonText = 'Create group';
    vm.groupsDiscoverersList = [];
    vm.pageNum = 1;
    vm.pageSize = 10;
    vm.orderCol = 0;
    vm.sortBy = 'first';
    vm.orderBy = 'asc';
    vm.loadedNewData = false;
    vm.searchKey = '';
    vm.totalRecords = 0;
    vm.refreshTimeStamp = Date.now();
    vm.showAssignedUsersDiv = false;
    vm.fetchGroupById = fetchGroupById;
    vm.assignUsersToGroup = assignUsersToGroup;
    vm.pageSizeOptions = APP_CONSTANTS.pageSizeOptions;
    vm.deleteGroupConfirmation = deleteGroupConfirmation;
    vm.fetchDiscoverersByGroupId = fetchDiscoverersByGroupId;
    vm.fetchDiscoverersBySponsoredOrg = fetchDiscoverersBySponsoredOrg;
    vm.removeDiscovererFromGroupConfirmation = removeDiscovererFromGroupConfirmation;
    vm.activeTab = APP_CONSTANTS.CREATE_MANAGE_GROUP_TABS.GROUP;
    vm.changeTab = changeTab;
    vm.submitDiscoverer = submitDiscoverer;
    vm.clearAddDiscoverersForm = clearAddDiscoverersForm;
    vm.selectGroup = selectGroup;

    vm.assessmentTypes = [
      { name: 'CKD', value: APP_CONSTANTS.CKD },
      { name: 'CDP', value: APP_CONSTANTS.CDP },
      { name: 'BOTH', value: APP_CONSTANTS.BOTH },
    ];
    vm.subscriptionType = currentUser.subscriptionType;

    let loadingWatcher = $scope.$watch('vm.loading.groups', function (newValue) {
      if (newValue !== false) return;
      $scope.startTour();
      loadingWatcher();
    });

    assignAssessmentType();

    vm.regex = {
      email: APP_CONSTANTS.REGEX.EMAIL
    };

    var sponsoredOrganizationId = $stateParams.sponsoredOrganizationId;

    vm.viewSponsoredOrganizationDashboard = sponsoredOrganizationId
      ? true
      : false;


    fetchGroups();


    vm.pageSizeOption = {
      key: "'" + vm.pageSize + "'",
      value: vm.pageSize
    };

    $.fn.dataTable.ext.order['disable-custom-sort'] = function(settings, col) {
      var scope = angular.element($('.table-responsive')).scope();

      if (scope.vm.orderCol !== col) {
        scope.vm.orderCol = col;
        scope.vm.orderBy = 'asc';
        if (col === 0) {
          scope.vm.sortBy = 'first';
        } else if (col === 1) {
          scope.vm.sortBy = 'last';
        } else if (col === 2) {
          scope.vm.sortBy = 'email';
        } else if (col === 3) {
          scope.vm.sortBy = '_ckCreatedAt';
        }

        scope.vm.loadedNewData = true;
        scope.vm.fetchDiscoverersByGroupId(
          scope.vm.selectedGroup,
          scope.vm.pageNum
        );
      } else if (scope.vm.orderCol === col) {
        if (scope.vm.loadedNewData) {
          scope.vm.loadedNewData = false;
        } else {
          scope.vm.orderBy = scope.vm.orderBy === 'asc' ? 'desc' : 'asc';
          scope.vm.loadedNewData = true;
          scope.vm.fetchDiscoverersByGroupId(
            scope.vm.selectedGroup,
            scope.vm.pageNum
          );
        }
      }

      return false;
    };

    vm.dtOptions = {
      paging: false,
      searching: false,
      info: false,
      order: [],
      destroy: true,
      columnDefs: [
        {
          orderable: false,
          targets: [4]
        },
        {
          orderDataType: 'disable-custom-sort',
          targets: [0, 1, 2, 3]
        }
      ]
    };

    fetchUserList();

    function fetchDiscoverersBySponsoredOrg(key) {
      if (key) {
        GroupService.fetchdiscoverer(encodeURIComponent(key), customerId).then(
          function(response) {
            vm.discoverers = response.data.data;
          }
        );
      }
    }

    function uploadCsvFile(discoverers) {
      vm.loading.createGroup = true;
      fileUpload
        .uploadCsvFile(discoverers)
        .then(function(response) {
          vm.loading.createGroup = false;
          clearAddDiscoverersForm();
          ngDialog
            .openConfirm({
              template: 'discovererUploadSuccess',
              className: 'ngdialog-theme-default',
              controller: function() {
                var vm = this;

                vm.result = {
                  successDiscoverers: response.data.data.discoverersList,
                  duplicateDiscoverers: response.data.data.duplicateData,
                  invalidDiscoverers: response.data.data.invalidData
                };
              },
              controllerAs: 'vm'
            })
            .then(
              function() {
                organizationsDiscoverers.concat(
                  response.data.data.discoverersList
                );
                vm.searchKey = '';
                vm.loadedNewData = true;
                fetchDiscoverersByGroupId(discoverers.group, 1);
                vm.buttonText = 'Create group';
              },
              function() {
                organizationsDiscoverers.concat(
                  response.data.data.discoverersList
                );
                vm.searchKey = '';
                vm.loadedNewData = true;
                fetchDiscoverersByGroupId(discoverers.group, 1);
                vm.buttonText = 'Create group';
              }
            );
        })
        .catch(function(error) {
          vm.loading.createGroup = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function uploadCsvFileWithoutGroup(discoverers) {
      vm.loading.createGroup = true;
      fileUpload
        .uploadCsvFileWithoutGroup(discoverers)
        .then(function(response) {
          vm.loading.createGroup = false;
          clearAddDiscoverersForm();
          ngDialog.openConfirm({
            template: 'discovererUploadSuccess',
            className: 'ngdialog-theme-default',
            controller: function() {
              var vm = this;

              vm.result = {
                successDiscoverers: response.data.data.discoverersList,
                duplicateDiscoverers: response.data.data.duplicateData,
                invalidDiscoverers: response.data.data.invalidData
              };
            },
            controllerAs: 'vm'
          });
        })
        .catch(function(error) {
          vm.loading.createGroup = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function submit(valid) {
      var group = vm.existingGroup ? vm.existingGroup : vm.group;

      vm.formSubmit = true;
      if (!valid) {
        return;
      }
      if (!group.id) {
        createGroup(vm.group);
      } else {
        if (
          vm.existingGroup.name !== vm.group.name ||
          vm.existingGroup.description !== vm.group.description
        ) {
          editGroup();
        } else if (!vm.group.discoverer && !vm.csvFile) {
          toaster.pop('warning', APP_CONSTANTS.MESSAGE.discovererRequired);
        } else {
          assignDiscoverersToGroup(group);
        }
      }
    }

    function assignDiscoverersToGroup(group) {
      if (vm.csvFile) {
        discoverers = {
          group: group && group.id ? group : {},
          file: vm.csvFile,
          assessmentType: vm.importDiscovererType
        };
        if (group && group.id) {
          uploadCsvFile(discoverers);
        } else {
          uploadCsvFileWithoutGroup(discoverers);
        }
      } else if (vm.group.discoverer) {
        discoverers = {
          group: group,
          newDiscoverers: vm.group.discoverer.assigned
        };
        createDiscoverers(discoverers);
      } else {
        discoverers = {
          newDiscoverers: {
            groupId: group && group.id ? group.id : '',
            firstName: vm.discoverer.firstName,
            lastName: vm.discoverer.lastName,
            email: vm.discoverer.email,
            assessmentType: vm.discoverer.assessmentType.value
          }
        };
        createDiscoverersWithoutGroupId(discoverers, group);
      }
    }

    function fetchGroupById(group) {
      if (vm.group.discoverer) {
        vm.group.discoverer.assigned = '';
      }
      // angular.element("input[type='text']").val(null);
      angular.element("input[type='file']").val(null);
      isFromEdit = true;
      vm.loading.createGroup = true;
      vm.searchKey = '';
      vm.loadedNewData = true;
      fetchDiscoverersByGroupId(group, 1);
      GroupService.fetchGroupById(group)
        .then(function(response) {
          vm.toBeDisplayDiscoverers = _.filter(
            organizationsDiscoverers,
            function(discoverer) {
              return !(discoverer.groupId.indexOf(response.data.data.id) > -1);
            }
          );
          vm.toBeDisplayDiscoverers = _.sortBy(
            vm.toBeDisplayDiscoverers,
            function(i) {
              return i.first.toLowerCase();
            }
          );
          vm.loading.createGroup = false;
          vm.isShowCancelButton = true;
          vm.existingGroup = response.data.data;
          vm.buttonText = 'Update';
          vm.group.name = response.data.data.name;

          vm.group.description = response.data.data.description;
        })
        .catch(function(error) {
          vm.loading.createGroup = false;
          isFromEdit = false;
          clearForm();
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function createGroup(group) {
      if (isGroupNameExists(group.name)) {
        return toaster.pop('error', APP_CONSTANTS.MESSAGE.groupNameExists);
      }

      vm.loading.createGroup = true;
      GroupService.createGroup(group)
        .then(function(response) {
          fetchGroups();
          if (vm.group.discoverer || vm.csvFile) {
            assignDiscoverersToGroup(response.data.data);
          } else {
            vm.loading.createGroup = false;
            toaster.pop(
              'success',
              APP_CONSTANTS.MESSAGE.groupSuccessfullyCreated
            );
            clearForm();
          }
        })
        .catch(function(error) {
          vm.loading.createGroup = false;
          clearForm();
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function createDiscoverers(discoverers) {
      vm.loading.createGroup = true;
      GroupService.createDiscoverers(discoverers)
        .then(function() {
          toaster.pop({
            type: 'success',
            body: APP_CONSTANTS.MESSAGE.onSuccess,
            showCloseButton: true,
            bodyOutputType: 'trustedHtml',
            onShowCallback: function() {
              if (vm.csvFile) {
                discoverers = {
                  group: discoverers.group,
                  file: vm.csvFile
                };
                uploadCsvFile(discoverers);
              } else {
                vm.buttonText = 'Create group';
                vm.loading.createGroup = false;
                vm.searchKey = '';
                vm.loadedNewData = true;
                fetchDiscoverersByGroupId(discoverers.group, 1);
              }
            }
          });
        })
        .catch(function(error) {
          vm.loading.createGroup = false;
          toaster.pop('error', error.data.message || error.statusText);
          vm.buttonText = 'Create group';
          clearForm();
        });
    }

    function createDiscoverersWithoutGroupId(discoverers, group) {
      vm.loading.createGroup = true;
      GroupService.createDiscoverersWithoutGroupId(discoverers)
        .then(function() {
          toaster.pop({
            type: 'success',
            body: APP_CONSTANTS.MESSAGE.onSuccess,
            showCloseButton: true,
            bodyOutputType: 'trustedHtml'
          });
          vm.loading.createGroup = false;
          clearAddDiscoverersForm();
          if (group) {
            fetchDiscoverersByGroupId(group);
          }
        })
        .catch(function(error) {
          vm.loading.createGroup = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function fetchGroups() {
      vm.loading.groups = true;
      GroupService.fetchGroups().then(
        function(response) {
          let groups = _.get(response, 'data.data', []);
          let filters = _.filter(APP_CONSTANTS.GROUP_FILTER, { id: 'unassign' });
          vm.groupList = filters.concat(groups);
          vm.selectedGroup = _.first(groups.concat(filters));

          vm.searchKey = '';
          if (vm.selectedGroup) {
            vm.loadedNewData = true;
            fetchDiscoverersByGroupId(vm.selectedGroup, 1);
          }

          vm.loading.groups = false;
        },
        function(error) {
          vm.loading.groups = false;
          toaster.pop('error', error.data.message || error.statusText);
        }
      );
    }

    function fetchDiscoverersByGroupId(group, pageNumber) {
      if (!isFromEdit) {
        clearForm();
      }

      vm.pageNum = typeof pageNumber !== 'undefined' ? pageNumber : 1;
      vm.buttonText = 'Create group';
      vm.selectedGroup = group;
      vm.loading.discoverers = true;

      GroupService.fetchDiscoverersByGroupId(
        vm.selectedGroup.id,
        vm.pageNum,
        vm.pageSize,
        vm.sortBy,
        vm.orderBy,
        vm.searchKey
      )
        .then(function(response) {
          isFromEdit = false;
          vm.groupsDiscoverersList = response.data.data.groupDiscoverList;
          vm.pageNum = response.data.data.pageNumber
            ? response.data.data.pageNumber
            : 1;
          vm.pageSize = response.data.data.pageSize
            ? response.data.data.pageSize
            : 10;
          vm.totalRecords = response.data.data.totalRecords;
          vm.refreshTimeStamp = Date.now();
          vm.pageSizeOption = {
            key: "'" + vm.pageSize + "'",
            value: vm.pageSize
          };
          vm.loading.discoverers = false;
          vm.selectedUsers = _.filter(vm.filteredUsers, function(user) {
            return vm.selectedGroup && (
              vm.selectedGroup.manager?.indexOf(user.id) > -1 ||
              vm.selectedGroup.counselor?.indexOf(user.id) > -1 ||
              vm.selectedGroup.stakeholder?.indexOf(user.id) > -1
            );
          });

          vm.originalSelectedUser = vm.selectedUsers;
          if (vm.originalSelectedUser.length > 0) {
            vm.showAssignedUsersDiv = true;
          } else {
            vm.showAssignedUsersDiv = false;
          }
        })
        .catch(function(error) {
          isFromEdit = false;
          vm.loading.discoverers = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    vm.getTableData = function(event) {
      var $targetElement = $(event.currentTarget);

      if (
        !$targetElement.hasClass('disabled') &&
        !$targetElement.hasClass('current')
      ) {
        var pageNumber = $targetElement.data('pageNum');

        vm.loadedNewData = true;
        fetchDiscoverersByGroupId(vm.selectedGroup, pageNumber);
      }
    };

    vm.pageSizeChanged = function pageSizeChanged() {
      vm.pageSize = vm.pageSizeOption;
      vm.searchKey = '';
      vm.loadedNewData = true;
      fetchDiscoverersByGroupId(vm.selectedGroup, 1);
    };

    vm.searchByKey = function searchByKey(event) {
      if (event.keyCode === 13) {
        vm.loadedNewData = true;
        fetchDiscoverersByGroupId(vm.selectedGroup, 1);
      }
    };

    function deleteGroup(group) {
      vm.loading.groups = true;
      GroupService.deleteGroup(group.id)
        .then(function() {
          fetchGroups();
          clearForm();
          toaster.pop(
            'success',
            APP_CONSTANTS.MESSAGE.groupSuccessfullydeleted
          );
        })
        .catch(function(error) {
          vm.loading.groups = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function fetchUserList() {
      UserInvitationService.fetchUsersList(customerId).then(
        function(response) {
          vm.userList = response.data.data;
          filterUsers();
        },
        function(error) {
          toaster.pop('error', error.data.message || error.statusText);
        }
      );
    }

    function filterUsers() {
      vm.filteredUsers = _.filter(vm.userList, function(item) {
        return (
          (item.roles.isAccountManager ||
            item.roles.isOrganizationManager ||
            item.roles.isStakeholder ||
            item.roles.isCounselor || 
            item.roles.isGroupCounselor) &&
          item.isActivated
        );
      });
    }

    function assignUsersToGroup() {
      vm.loading.assignUser = true;
      var manager = _.filter(vm.selectedUsers, function(user) {
        return user.roles.isAccountManager || user.roles.isOrganizationManager;
      }).map(function(item) {
        return item.id;
      });

      var counselors = _.filter(vm.selectedUsers, function(user) {
        return user.roles.isCounselor || user.roles.isGroupCounselor;
      }).map(function(item) {
        return item.id;
      });

      var stakeholders = _.filter(vm.selectedUsers, function(user) {
        return user.roles.isStakeholder;
      }).map(function(item) {
        return item.id;
      });

      vm.selectedGroup['manager'] = angular.copy(manager);
      vm.selectedGroup['counselor'] = angular.copy(counselors);
      vm.selectedGroup['stakeholder'] = angular.copy(stakeholders);

      GroupService.assignUsersToGroup(
        vm.selectedGroup,
        vm.selectedGroup.id
      ).then(
        function() {
          vm.loading.assignUser = false;
          toaster.pop('success', APP_CONSTANTS.MESSAGE.onSuccess);
          if (vm.selectedUsers.length > 0) {
            vm.showAssignedUsersDiv = true;
          } else {
            vm.showAssignedUsersDiv = false;
          }
          vm.isCollapsed = true;
        },
        function(error) {
          vm.loading.assignUser = false;
          toaster.pop('error', error.data.message || error.statusText);
        }
      );
    }

    function editGroup() {
      if (isGroupNameExists(vm.group.name, vm.existingGroup.id)) {
        return toaster.pop('error', APP_CONSTANTS.MESSAGE.groupNameExists);
      }

      var tobeUpdate = {};

      tobeUpdate = angular.copy(vm.existingGroup);
      vm.loading.createGroup = true;
      tobeUpdate.name = vm.group.name;
      tobeUpdate.description = vm.group.description;

      GroupService.editGroup(tobeUpdate)
        .then(function(response) {
          vm.loading.createGroup = false;
          vm.group.name = '';
          vm.group.description = '';
          vm.formSubmit = false;

          var index = _.indexOf(
            vm.groupList,
            _.find(vm.groupList, { id: vm.existingGroup.id })
          );

          vm.groupList.splice(index, 1, response.data.data);

          if (vm.group.discoverer || vm.csvFile) {
            assignDiscoverersToGroup(response.data.data);
          } else {
            toaster.pop('success', APP_CONSTANTS.MESSAGE.onSuccess);
            vm.searchKey = '';
            vm.loadedNewData = true;
            fetchDiscoverersByGroupId(response.data.data, 1);
            clearForm();
          }
        })
        .catch(function(error) {
          vm.loading.createGroup = false;
          toaster.pop('error', error.data.message || error.statusText);
          clearForm();
        });
    }

    function removeDiscovererFromGroup(discoverer) {
      var discovererId = discoverer.id;
      var groupId = vm.selectedGroup.id;

      vm.loading.discoverers = true;
      GroupService.removeDiscovererFromGroup(discovererId, groupId)
        .then(function() {
          vm.loading.discoverers = false;
          vm.searchKey = '';
          vm.loadedNewData = true;
          fetchDiscoverersByGroupId(vm.selectedGroup, 1);
          toaster.pop('success', APP_CONSTANTS.MESSAGE.onSuccess);
          clearForm();
        })
        .catch(function(error) {
          clearForm();
          vm.loading.discoverers = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function deleteGroupConfirmation(group) {
      var data = {};

      data.message =
        'Are you sure you want to the delete the group ' + group.name;
      ngDialog
        .openConfirm({
          template: 'app/shared/views/partials/ngDialogConfirmation.html',
          data: data
        })
        .then(
          function() {
            ngDialog.close();
            deleteGroup(group);
          },
          function() {
            ngDialog.close();
          }
        );
    }

    function removeDiscovererFromGroupConfirmation(discoverer) {
      var data = {};

      data.message =
        'Are you sure you want to the delete the discoverer ' +
        discoverer.email +
        ' from this group';
      ngDialog
        .openConfirm({
          template: 'app/shared/views/partials/ngDialogConfirmation.html',
          data: data
        })
        .then(
          function() {
            ngDialog.close();
            removeDiscovererFromGroup(discoverer);
          },
          function() {
            ngDialog.close();
          }
        );
    }

    function clearForm() {
      vm.group = {};
      vm.csvFile = '';
      vm.formSubmit = false;
      vm.existingGroup = {};
      vm.buttonText = 'Create group';
      vm.isShowCancelButton = false;
      angular.element("input[type='text']").val(null);
      angular.element("input[type='file']").val(null);
    }

    function changeTab(tab) {
      vm.activeTab = tab;
      vm.discoverer = {};
      assignAssessmentType();
    }

    function submitDiscoverer(valid) {
      vm.discovererFormSubmit = true;
      vm.fileValid = true;
      if (vm.csvFile === 'invalid') {
        vm.fileValid = false;
      }
      if (!valid || !vm.fileValid) {
        return;
      }
      if (
        !vm.discoverer.firstName &&
        !vm.discoverer.lastName &&
        !vm.discoverer.email &&
        !vm.discoverer.assessmentType &&
        !vm.csvFile
      ) {
        toaster.pop('warning', APP_CONSTANTS.MESSAGE.discovererRequired);
      } else {
        assignDiscoverersToGroup(vm.discoverer.group);
      }
    }

    function clearAddDiscoverersForm() {
      vm.discoverer = {};
      vm.csvFile = '';
      vm.importDiscovererType = '';
      vm.discovererFormSubmit = false;
      assignAssessmentType();
      angular.element("input[type='text']").val(null);
      angular.element("input[type='file']").val(null);
    }

    function selectGroup() {
      vm.selectedGroup = vm.discoverer.group;
      fetchDiscoverersByGroupId(vm.selectedGroup);
    }

    function assignAssessmentType() {
      if(vm.subscriptionType===APP_CONSTANTS.BOTH) return;

      const assessmentType = vm.assessmentTypes.find(v => v.value === vm.subscriptionType);
      vm.discoverer.assessmentType = assessmentType;
      vm.importDiscovererType = assessmentType;
    }

    function isGroupNameExists(name, id = '') {
      return (vm.groupList.some(function (group) {
          return (group.id !== id
            && group.name.toLowerCase() === name.toLowerCase());
        })
      );
    }
  }
})();
