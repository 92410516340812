'use strict';

angular.module('careerKeyCentral').filter('sprintf', function() {
  function parse(str, args) {
    var i = 0;
    return str.replace(/%s/g, function() {
      return args[i++] || '';
    });
  }

  return function() {
    return parse(
      Array.prototype.slice.call(arguments, 0, 1)[0],
      Array.prototype.slice.call(arguments, 1)
    );
  };
});
