/**
 * Created by Romit Amgai<romitamgai@lftechnology.com> on 5/04/17.
 */

(function() {
  'use strict';
  angular
    .module('app.user')
    .controller('BillingContactController', BillingContactController);
  BillingContactController.$inject = [
    '$state',
    'BillingContactService',
    'RegistrationService',
    'toaster',
    'APP_CONSTANTS',
    '$scope',
    '$filter'
  ];

  function BillingContactController(
    $state,
    BillingContactService,
    RegistrationService,
    toaster,
    APP_CONSTANTS,
    $scope,
    $filter
  ) {
    var vm = this;

    vm.submit = submit;
    vm.billingRequest = {};
    vm.checkEmail = checkEmail;
    vm.openAccordion = openAccordion;

    vm.regex = {
      email: APP_CONSTANTS.REGEX.EMAIL
    };

    function submit(form) {
      if (!form.$valid) return;
      if (vm.duplicateEmail) return;

      if (RegistrationService.invalidAccountInfo()) {
        toaster.clear('billingContact');

        toaster.pop({
          type: 'error',
          body: APP_CONSTANTS.MESSAGE.INVALID_ACCOUNT_INFORMATION,
          toasterId: 'billingContact'
        });
        document.getElementById('billing-contact').scrollIntoView(true);

        return;
      }

      vm.loading = true;
      BillingContactService.registerAndInviteBilling(
        RegistrationService.getAccountInfo(),
        vm.billingRequest,
        $scope.regController.plan
      ).then(
        function(result) {
          vm.loading = false;

          $scope.regController.signUpComplete.status = true;
          $scope.regController.signUpComplete.activationId =
            result.data.data.activationId;
          $scope.regController.signUpComplete.billingContactEmail =
            vm.billingRequest.email;
          $scope.regController.signUpComplete.type =
            APP_CONSTANTS.REGISTER_TYPE.BILLING_CONTACT;

          angular.copy(
            result.data.data,
            $scope.regController.registrationDetails.confirmation
          );

          toaster.clear('billingContact');
          openAccordion();
        },
        function(err) {
          vm.loading = false;

          toaster.clear('billingContact');

          if (err.data && err.data.error && err.data.error.code === 400)
            toaster.pop({
              type: 'error',
              body: APP_CONSTANTS.MESSAGE.INVALID_ACCOUNT_INFORMATION,
              toasterId: 'billingContact'
            });
          else
            toaster.pop({
              type: 'error',
              body: err.data.message || err.statusText,
              toasterId: 'billingContact'
            });

          document.getElementById('billing-contact').scrollIntoView(true);
        }
      );
    }

    function checkEmail() {
      if (!vm.billingRequest.email) {
        return;
      }

      RegistrationService.checkEmail(vm.billingRequest.email).then(
        function(result) {
          vm.duplicateEmail = result.data.data > 0;
        },
        function(err) {
          toaster.clear('billingContact');

          toaster.pop({
            type: 'error',
            body: err.data.message || err.statusText,
            toasterId: 'billingContact'
          });
          document.getElementById('billing-contact').scrollIntoView(true);
        }
      );
    }

    function openAccordion() {
      var nextBlock = $filter('nextItem')(
        $scope.regController.registrationBlocks,
        $scope.regController.activeBlock.label
      );

      $scope.regController.activeBlock.label = nextBlock.label;
      $scope.regController.openBlock[nextBlock.id] = true;
    }
  }
})();
