(function() {
  'use strict';
  angular
    .module('app.customerAccount')
    .controller('UpdateCustomLabelController', UpdateCustomLabelController);

  UpdateCustomLabelController.$inject = [
    '$state',
    'APP_CONSTANTS',
    'toaster',
    'NewSubscriptionService',
    'SessionService',
    '$uibModalInstance',
    'subscription'
  ];

  function UpdateCustomLabelController(
    $state,
    APP_CONSTANTS,
    toaster,
    NewSubscriptionService,
    SessionService,
    $uibModalInstance,
    subscription
  ) {
    var vm = this;
    var userLoginDetail = SessionService.getUser();
    vm.isSuperAdmin = userLoginDetail.roles.isSuperAdmin;
    vm.loading = {};
    vm.formData = {
      subscriptionId: subscription.id,
      customLabel: subscription.customLabel,
      po_number: subscription.purchaseOrder,
      invoiceNumber: subscription.invoiceNumber,
      status: subscription.status,
    };
    vm.statusOptions = [
      APP_CONSTANTS.SUBSCRIPTION_STATUS.PENDING,
      APP_CONSTANTS.SUBSCRIPTION_STATUS.ACTIVE,
      APP_CONSTANTS.SUBSCRIPTION_STATUS.EXPIRED,
      APP_CONSTANTS.SUBSCRIPTION_STATUS.CANCELLED,
    ];
    vm.showInvoiceNumber = vm.isSuperAdmin && _.isEmpty(subscription.chargebeeSubscriptionId);
    vm.showPurchaseOrder = vm.isSuperAdmin && !vm.showInvoiceNumber;

    vm.submit = submit;
    vm.cancel = cancel;

    function submit(form) {
      if (form.$invalid) return;

      vm.loading.customLabel = true;

      NewSubscriptionService.updateCustomLabelAndPO(vm.formData, subscription.id)
        .then(function() {
          vm.loading.customLabel = false;
          closePopup();
          toaster.pop('success', APP_CONSTANTS.MESSAGE.successfullyUpdated);
        })
        .catch(function(error) {
          vm.loading.customLabel = false;
          toaster.clear('customLabel');
          toaster.pop({
            type: 'error',
            body: error.data.message || error.statusText,
            toasterId: 'customLabel'
          });
        });
    }

    function closePopup() {
      $uibModalInstance.close('closed');
    }

    function cancel() {
      $uibModalInstance.dismiss('cancel');
    }
  }
})();
