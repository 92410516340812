(function() {
  'use strict';

  angular
    .module('app.customerAccount')
    .factory('NewSubscriptionService', NewSubscriptionService);

  NewSubscriptionService.$inject = [
    'ApiCaller',
    'APP_CONSTANTS',
    '$q',
    '$filter'
  ];

  function NewSubscriptionService(ApiCaller, APP_CONSTANTS, $q, $filter) {
    var self = this;

    self.addNextPlan = addNextPlan;
    self.fetchSubscription = fetchSubscription;
    self.addNewSubscription = addNewSubscription;
    self.updateCustomLabelAndPO = updateCustomLabelAndPO;

    function fetchSubscription() {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.newSubscription,
        APP_CONSTANTS.rootUrl
      );

      ApiCaller.apiGet(url)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function addNewSubscription(newPlan) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.newSubscription,
        APP_CONSTANTS.rootUrl
      );

      ApiCaller.apiPost(url, newPlan)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function updateCustomLabelAndPO(data, subscriptionId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.customLabelAndPO,
        APP_CONSTANTS.rootUrl,
        subscriptionId
      );

      ApiCaller.apiPut(url, data)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function addNextPlan(planDetail) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.addNextPlan,
        APP_CONSTANTS.rootUrl,
        planDetail.subscriptionId
      );

      ApiCaller.apiPut(url, planDetail)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    return self;
  }
})();
