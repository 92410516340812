(function() {
  'use strict';

  angular
    .module('app.shared')
    .factory('SendEmailToAllService', SendEmailToAllService);

  SendEmailToAllService.$inject = [
    'APP_CONSTANTS',
    'ApiCaller',
    '$q',
    '$filter'
  ];

  function SendEmailToAllService(APP_CONSTANTS, ApiCaller, $q, $filter) {
    var self = this;

    self.fetchEmailTemplates = fetchEmailTemplates;

    function fetchEmailTemplates(category) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.emailContent,
        APP_CONSTANTS.rootUrl,
        category
      );

      ApiCaller.apiGet(url).then(
        function(result) {
          deferred.resolve(result);
        },
        function(error) {
          deferred.reject(error);
        }
      );

      return deferred.promise;
    }

    return self;
  }
})();
