(function () {
  'use strict';

  angular
    .module('app.shared')
    .factory('AdminCustomerSubscriptionService', AdminCustomerSubscriptionService);

  AdminCustomerSubscriptionService.$inject = [
    'ApiCaller',
    'APP_CONSTANTS',
    '$q',
    '$filter'
  ];

  function AdminCustomerSubscriptionService(ApiCaller, APP_CONSTANTS, $q, $filter) {
    var self = this;

    self.addNextPlan = addNextPlan;
    self.fetchSubscription = fetchSubscription;
    self.addNewSubscription = addNewSubscription;
    self.deleteSubscriptions = deleteSubscriptions;

    function fetchSubscription(customerId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.customerSubscription,
        APP_CONSTANTS.rootUrl,
        customerId
      );

      ApiCaller.apiGet(url)
        .then(function (result) {
          deferred.resolve(result);
        })
        .catch(function (error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function addNewSubscription(newPlan, customerId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.customerSubscription,
        APP_CONSTANTS.rootUrl,
        customerId
      );

      ApiCaller.apiPost(url, newPlan)
        .then(function (result) {
          deferred.resolve(result);
        })
        .catch(function (error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function deleteSubscriptions(customerId, subscriptionId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.customerSubscription,
        APP_CONSTANTS.rootUrl,
        customerId,
        subscriptionId
      );

      ApiCaller.apiDelete(url)
        .then(function (result) {
          deferred.resolve(result);
        })
        .catch(function (error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function addNextPlan(planDetail) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.addNextPlan,
        APP_CONSTANTS.rootUrl,
        planDetail.subscriptionId
      );

      ApiCaller.apiPut(url, planDetail)
        .then(function (result) {
          deferred.resolve(result);
        })
        .catch(function (error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    return self;
  }
})();
