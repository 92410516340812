(function() {
  'use strict';
  angular
    .module('app.shared')
    .controller('ProductController', ProductController);

  ProductController.$inject = [
    'APP_CONSTANTS',
    '$state',
    'toaster',
    '$scope',
    '$stateParams',
    'ProductService',
    'DetailsService'
  ];

  function ProductController(
    APP_CONSTANTS,
    $state,
    toaster,
    $scope,
    $stateParams,
    ProductService,
    DetailsService
  ) {
    var vm = this;

    vm.loading = {};
    vm.productTagList = [];
    vm.productRegionList = [];
    vm.setActiveTab = setActiveTab;
    vm.fetchProductName = fetchProductName;

    ProductService.setProductId($stateParams.productId);
    vm.productId = ProductService.getProductId();

    fetchProductName();
    getProductDetails();

    function setActiveTab() {
      vm.activeTabIndex = APP_CONSTANTS.PRODUCT_TAB_INDEX[$state.current.name];
    }

    function getProductDetails() {
      vm.loading.productInfo = true;

      DetailsService.fetchProductDetails(vm.productId)
        .then(function(result) {
          vm.loading.productInfo = false;

          vm.productTagList = _.get(result, 'data.products[0].tags', []);
          vm.productRegionList = _.get(result, 'data.products[0].regions', []);
        })
        .catch(function(error) {
          vm.loading.productInfo = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function fetchProductName() {
      $scope.$parent.vm.loadBreadCrumbs(true);

      DetailsService.fetchProductDetails(vm.productId)
        .then(function(result) {
          $scope.$parent.vm.loadBreadCrumbs(false);

          vm.productName = _.get(result, 'data.products[0].name', '');
          vm.productCountry = _.get(result, 'data.products[0].country.id', '');
          vm.occupationsProductId = result.data.products[0].occupationsProduct;
          vm.instructionalProgramsProductId =
            result.data.products[0].instructionalProgramsProduct;

          $scope.$parent.vm.generateBreadCrumbs(
            APP_CONSTANTS.CKADMIN_TABS.PRODUCTS +
              ' > ' +
              vm.productCountry +
              ' ' +
              vm.productName
          );
        })
        .catch(function(error) {
          $scope.$parent.vm.loadBreadCrumbs(false);
          toaster.pop('error', error.data.message || error.statusText);
        });
    }
  }
})();
