(function () {
  'use strict';
  angular
    .module('app.sponsoredOrganization')
    .controller('UserManagementController', UserManagementController);

  UserManagementController.$inject = [
    'APP_CONSTANTS',
    'SessionService',
    'ngDialog',
    'toaster',
    'UserManagementService',
    'GroupService',
    'OrganizationInfoService',
    '$q',
    '$filter',
    '$state',
    '$scope'
  ];

  function UserManagementController(
    APP_CONSTANTS,
    SessionService,
    ngDialog,
    toaster,
    UserManagementService,
    GroupService,
    OrganizationInfoService,
    $q,
    $filter,
    $state,
    $scope
  ) {
    var vm = this;

    vm.user = SessionService.getUser();
    if (
      !(
        vm.user.roles.isOrganizationManager ||
        vm.user.roles.isAccountManager ||
        vm.user.roles.isCounselor ||
        vm.user.roles.isTechnicalContact
      )
    ) {
      $state.go('app.ownerdashboard');

      return;
    }

    vm.isAccountManager = vm.user.roles.isAccountManager;
    vm.isOrganizationManager = vm.user.roles.isOrganizationManager;

    vm.loading = {};
    vm.roles = [];
    vm.emails = [];
    // vm.billingRoleId = _.find(APP_CONSTANTS.invitationRoleList, function (
    //   roles
    // ) {
    //   return roles.role === APP_CONSTANTS.BILLING;
    // }).id;
    // vm.stakeholderRoleId = _.find(APP_CONSTANTS.invitationRoleList, function (
    //   roles
    // ) {
    //   return roles.role === APP_CONSTANTS.STAKEHOLDER;
    // }).id;
    // vm.technicalContactRoleId = _.find(
    //   APP_CONSTANTS.invitationRoleList,
    //   function (roles) {
    //     return roles.role === APP_CONSTANTS.TECHNICAL;
    //   }
    // ).id;

    vm.counselorGroupSelected = false;
    vm.selectedCounselorGroups = [];
    vm.noGroupSelected = false;

    vm.grantOrRevokeUser = grantOrRevokeUser;

    angular.copy(APP_CONSTANTS.invitationRoleList, vm.roles);

    vm.regex = {
      email: APP_CONSTANTS.REGEX.EMAIL
    };

    vm.allowMultipleRoles = false;
    resetSelectedRole();

    vm.dtOptions = {
      order: [],
      columnDefs: [
        {
          orderable: false,
          targets:
            vm.isAccountManager || vm.isOrganizationManager ? [5, 6] : [5]
        }
      ]
    };

    vm.dtInvitedUsersOptions = {
      order: [],
      columnDefs: [
        {
          orderable: false,
          targets: vm.isAccountManager || vm.isOrganizationManager ? [3] : []
        }
      ]
    };

    activate();

    function activate() {
      fetchGroups();
      filterRoles();
      fetchUserList();

      fetchOrganizationInformation().then(() => {
        if (vm.ssoEnabled) return;

        fetchInvitedUsers();
      });
    }

    function fetchOrganizationInformation() {
      return $q((resolve, reject) => {
        vm.ssoEnabled = $scope.$parent.vm?.organizationInfo?.ssoEnabled;

        if (vm.ssoEnabled !== undefined) return resolve();

        vm.loading.organizationInfo = true;

        OrganizationInfoService.fetchOrganizationInfo(vm.user.sponsoredOrganizationId)
          .then((response) => {
            vm.ssoEnabled = response.data?.data?.ssoEnabled || false;
          })
          .catch((error) => {
            toaster.pop('error', error.data?.message || error.statusText);
          })
          .finally(() => {
            vm.loading.organizationInfo = false;
            resolve();
          });
      });
    }

    function fetchInvitedUsers() {
      vm.loading.invitedUsers = true;
      UserManagementService
        .fetchInvitedUsers(
          vm.user.sponsoredOrganizationId
        )
        .then(
          function (result) {
            vm.invitedUsers = result.data.data;
            vm.loading.invitedUsers = false;
          },
          function (error) {
            vm.loading.invitedUsers = false;
            toaster.pop('error', error.data.error.message || error.statusText);
          }
        );
    }

    function fetchUserList() {
      vm.loading.users = true;
      UserManagementService.fetchUsersList(
        vm.user.sponsoredOrganizationId
      ).then(
        function (result) {
          vm.userList = result.data.data;
          vm.loading.users = false;
        },
        function (error) {
          vm.loading.users = false;
          toaster.pop('error', error.data.error.message || error.statusText);
        }
      );
    }

    function filterRoles() {
      vm.roles = _.filter(vm.roles, function (role) {
        return (
          role.role !== APP_CONSTANTS.BILLING
          && role.role !== APP_CONSTANTS.STAKEHOLDER
        );
      });

      if (!vm.user.roles.isAccountManager)
        vm.roles = _.filter(vm.roles, function (role) {
          return role.role !== APP_CONSTANTS.ACCOUNT_MANAGER;
        });

      if (!vm.user.roles.isAccountManager && !vm.user.roles.isOrganizationManager)
        vm.roles = _.filter(vm.roles, function (role) {
          return role.role !== APP_CONSTANTS.ORGANIZATION_MANAGER;
        });
      
    }

    function fetchGroups() {
      vm.loading.sendInvitation = true;
      GroupService.fetchGroups()
        .then(function (response) {
          vm.groups = response.data.data;
          vm.loading.sendInvitation = false;
        })
        .catch(function (error) {
          toaster.pop('error', error.data.message || error.statusText);
          vm.loading.sendInvitation = false;
        });
    }

    vm.selectedRoleKeys = function (user) {
      var roleKeys = [];

      angular.forEach(user.roles, function (value, key) {
        angular.isObject(value) && value.role && roleKeys.push(value.role);
        value === true && roleKeys.push(key);
      });

      return roleKeys;
    }

    function resetSelectedRole() {
      UserManagementService.selectedRole = [];
      vm.roles.forEach(r => r.selected = false);
    }

    vm.selectRole = function (role) {
      // if (role.id === vm.stakeholderRoleId) {
      //   toaster.pop('error', APP_CONSTANTS.MESSAGE.unAuthorizedRole);

      //   return;
      // }
      if (!vm.allowMultipleRoles) {
        resetSelectedRole();
        vm.roles.forEach(r => {
          r.selected = r.id == role.id;
        });
      }

      UserManagementService.selectRole(role);
      var selectedRoles = UserManagementService.selectedRole;

      // Check if selected role is Group Counselor
      if (selectedRoles.length === 1 && selectedRoles[0].role === 'groupCounselor') {
        vm.counselorGroupSelected = true;
      } else {
        vm.counselorGroupSelected = false;
      }

      if (selectedRoles.length > 0) {
        vm.noRoleSelected = false;
      }
    };

    function grantOrRevokeUser(user) {
      var newUserData = {};

      angular.copy(user, newUserData);

      user.status = !user.status;

      var data = {};
      var operation = newUserData.status
        ? APP_CONSTANTS.ACCESS.GRANT
        : APP_CONSTANTS.ACCESS.REVOKE;

      data.message = $filter('sprintf')(
        APP_CONSTANTS.CONFIRMATION.GRANT_REVOKE_ACCESS_USER,
        operation,
        user.email
      );

      ngDialog
        .openConfirm({
          template: 'app/shared/views/partials/ngDialogConfirmation.html',
          data: data
        })
        .then(
          function () {
            ngDialog.close();
            vm.loading.users = true;
            UserManagementService.grantOrRevokeUser(newUserData).then(
              function (response) {
                user.status = response.data.status;
                vm.loading.users = false;
                toaster.pop('success', APP_CONSTANTS.MESSAGE.onSuccess);
              },
              function (error) {
                vm.loading.users = false;
                toaster.pop('error', error.data.message || error.statusText);
              }
            );
          },
          function () {
            ngDialog.close();
          }
        );
    }

    vm.sendInvite = function (valid) {
      if (!valid) return;

      var selectedRoles = UserManagementService.selectedRole;

      vm.emailForm.newEmailList = vm.emailForm.newEmail.split(/\s*,\s*/);

      if (selectedRoles.length < 1) {
        vm.noRoleSelected = true;

        return;
      }

      vm.noRoleSelected = false;

      vm.newUser = {
        roles: selectedRoles,
        email: vm.emailForm.newEmailList,
        message: vm.emailForm.message || ''
      };

      // Check if selected role is Group Counselor
      var selectedRoles = UserManagementService.selectedRole;
      
      if (selectedRoles.length === 1 && selectedRoles[0].role === 'groupCounselor') {
        if (vm.selectedCounselorGroups.length === 0) {
          vm.noGroupSelected = true;

          return;
        } else {
          vm.newUser.groups = vm.selectedCounselorGroups;
        }
      }

      vm.loading.sendInvitation = true;
      let promise;

      if (vm.ssoEnabled) {
        vm.newUser.firstName = vm.emailForm.newFirstName;
        vm.newUser.lastName = vm.emailForm.newLastName;
        vm.newUser.email = vm.newUser.email[0];
        promise = UserManagementService.addB2BUser(vm.newUser);
      }
      else {
        vm.newUser.modifierId = vm.user.id;
        vm.newUser.sponsoredOrganizationId = vm.user.sponsoredOrganizationId;
        promise = UserManagementService.sendInvite(vm.newUser);
      }

      promise
        .then(function () {
          vm.loading.sendInvitation = false;
          vm.newUser = {};
          vm.emailForm.newFirstName = '';
          vm.emailForm.newLastName = '';
          vm.emailForm.newEmail = '';
          vm.emailForm.message = '';
          vm.emailForm.$setPristine();
          vm.emailForm.$setUntouched();
          $('.user-roles').prop('checked', false);
          resetSelectedRole();
          vm.noGroupSelected = false;
          vm.selectedCounselorGroups = [];

          if (vm.ssoEnabled) {
            toaster.pop('success', APP_CONSTANTS.MESSAGE.addUserSuccess);
            fetchUserList();
          } else {
            toaster.pop('success', APP_CONSTANTS.MESSAGE.inviteUser);
            fetchInvitedUsers();
          }
        })
        .catch(function (error) {
          vm.loading.sendInvitation = false;
          toaster.pop('error', error.data.message);
        });
    };

    vm.deleteInvitedUserConfirmation = function (invitedUser) {
      ngDialog
        .openConfirm({
          template: 'app/shared/views/partials/ngDialogConfirmation.html',
          data: { message: 'Are you sure you want to the delete the invited user ' + invitedUser.email + '?' }
        })
        .then(function () {
          vm.loading.invitedUsers = true;

          UserManagementService
            .deleteInvitedUser(invitedUser.id)
            .then(function () {
              toaster.pop('success', APP_CONSTANTS.MESSAGE.onSuccess);
              fetchInvitedUsers();
            })
            .catch(function (error) {
              toaster.pop('error', error.data.error.message || error.statusText);
              vm.loading.invitedUsers = false;
            });
        });
    };

    vm.resendInvitation = (invitedUser) => {
      vm.loading.invitedUsers = true;

      UserManagementService
        .resendInvitation(invitedUser.id)
        .then(() => {
          toaster.pop('success', APP_CONSTANTS.MESSAGE.onSuccess);
          invitedUser.resend = true;
        })
        .catch((error) => {
          toaster.pop('error', error.data.message || error.statusText);
        })
        .finally(() => {
          vm.loading.invitedUsers = false;
        });
    };
  }
})();
