(function() {
  'use strict';
  angular
    .module('app.sponsoredOrganization')
    .factory('ConfigurePluginService', ConfigurePluginService);

  ConfigurePluginService.$inject = [
    'APP_CONSTANTS',
    'ApiCaller',
    '$q',
    '$filter'
  ];

  function ConfigurePluginService(APP_CONSTANTS, ApiCaller, $q, $filter) {
    var self = this;

    self.logoUrl = '';
    self.uploadImage = uploadImage;
    self.fetchSchools = fetchSchools;
    self.updatePlugin = updatePlugin;
    self.getLogoUrl = getLogoUrl;
    self.setLogoUrl = setLogoUrl;
    self.fetchSchoolId = fetchSchoolId;
    self.fetchClusterSets = fetchClusterSets;
    self.fetchAvailableLanguages = fetchAvailableLanguages;
    function fetchSchools(params) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.schoolCollection,
        APP_CONSTANTS.rootUrl,
        $.param(params)
      );

      ApiCaller.apiGet(url)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function fetchAvailableLanguages(sponsoredOrganizationId, type) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.availableWidgetLanguages,
        APP_CONSTANTS.rootUrl,
        sponsoredOrganizationId,
        type
      );

      ApiCaller.apiGet(url)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function fetchSchoolId(params) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.schoolSingle,
        APP_CONSTANTS.rootUrl,
        $.param(params)
      );

      ApiCaller.apiGet(url)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(err) {
          deferred.reject(err);
        });

      return deferred.promise;
    }

    function fetchClusterSets(params) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.clusterSets,
        APP_CONSTANTS.rootUrl,
        params
      );

      ApiCaller.apiGet(url)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(err) {
          deferred.reject(err);
        });

      return deferred.promise;
    }

    function updatePlugin(plugin) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.pluginSingle,
        APP_CONSTANTS.rootUrl,
        plugin.sponsoredOrganizationId,
        plugin.id
      );

      if (plugin.school) {
        plugin.schoolId = plugin.school.id || plugin.schoolId || '';
        delete plugin.school;
      }

      ApiCaller.apiPut(url, plugin)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(err) {
          deferred.reject(err);
        });

      return deferred.promise;
    }

    function uploadImage(imageData, plugin) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.pluginImage,
        APP_CONSTANTS.rootUrl,
        plugin.sponsoredOrganizationId,
        plugin.id
      );

      ApiCaller.apiPut(url, imageData).then(
        function(result) {
          deferred.resolve(result);
        },
        function(err) {
          deferred.reject(err);
        }
      );

      return deferred.promise;
    }

    function setLogoUrl(url) {
      self.logoUrl = url;
    }

    function getLogoUrl() {
      return self.logoUrl;
    }

    return self;
  }
})();
