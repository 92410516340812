(function () {
  'use strict';

  angular
    .module('app.shared')
    .factory('AdminResellerSubscriptionService', AdminResellerSubscriptionService);

  AdminResellerSubscriptionService.$inject = [
    'ApiCaller',
    'APP_CONSTANTS',
    '$q',
    '$filter'
  ];

  function AdminResellerSubscriptionService(ApiCaller, APP_CONSTANTS, $q, $filter) {
    var self = this;

    self.addNextPlan = addNextPlan;
    self.fetchSubscription = fetchSubscription;
    self.addNewSubscription = addNewSubscription;
    self.deleteSubscriptions = deleteSubscriptions;

    function fetchSubscription(resellerId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.customerSubscription,
        APP_CONSTANTS.rootUrl,
        resellerId
      );

      ApiCaller.apiGet(url)
        .then(function (result) {
          deferred.resolve(result);
        })
        .catch(function (error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function addNewSubscription(newPlan, resellerId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.customerSubscription,
        APP_CONSTANTS.rootUrl,
        resellerId
      );

      ApiCaller.apiPost(url, newPlan)
        .then(function (result) {
          deferred.resolve(result);
        })
        .catch(function (error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function deleteSubscriptions(resellerId, subscriptionId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.customerSubscription,
        APP_CONSTANTS.rootUrl,
        resellerId,
        subscriptionId
      );

      ApiCaller.apiDelete(url)
        .then(function (result) {
          deferred.resolve(result);
        })
        .catch(function (error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function addNextPlan(planDetail) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.addNextPlan,
        APP_CONSTANTS.rootUrl,
        planDetail.subscriptionId
      );

      ApiCaller.apiPut(url, planDetail)
        .then(function (result) {
          deferred.resolve(result);
        })
        .catch(function (error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    return self;
  }
})();
