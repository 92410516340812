(function() {
    'use strict';
    angular
      .module('app.user')
      .controller('AddCustomerAccountController', AddCustomerAccountController);
      AddCustomerAccountController.$inject = [
      'RegistrationService',
      'toaster',
      'APP_CONSTANTS',
      '$scope',
    ];

    function AddCustomerAccountController(
      RegistrationService,
      toaster,
      APP_CONSTANTS,
      $scope,
    ) {
      var vm = this;

      vm.accountInfo = {};
      vm.selected = {};
      vm.regex = {
        email: APP_CONSTANTS.REGEX.EMAIL,
      };

      // Check privacy disclaimer by default for B2B customers
      vm.accountInfo.showPrivacyDisclaimer = true;

      vm.submit = submit;
      vm.checkEmail = checkEmail;
      vm.countrySelected = countrySelected;

      fetchCountries();

      function fetchCountries(name) {
        RegistrationService.getCountries(name)
          .then(function(result) {
            vm.countries = result.data.data;
          })
          .catch(function(error) {
            toaster.clear('account');
  
            toaster.pop({
              type: 'error',
              body: error.data.message || error.statusText,
              toasterId: 'account'
            });
            document.getElementById('account-details').scrollIntoView(true);
          });
      }

      function countrySelected() {
        RegistrationService.getStates(vm.selected.country.countryCode)
          .then(function(result) {
            vm.states = result.data.data.states;
            vm.accountInfo.state = null;
          })
          .catch(function(error) {
            toaster.clear('account');
  
            toaster.pop({
              type: 'error',
              body: error.data.message || error.statusText,
              toasterId: 'account'
            });
            document.getElementById('account-details').scrollIntoView(true);
          });
      }

      function submit(form) {
        if (!form.$valid || vm.duplicateEmail) return;

        form.$setPristine();
        vm.accountInfo.country = vm.selected.country.name;
        RegistrationService.setAccountInfo(vm.accountInfo);
        toaster.clear('account');
        $scope.regController.gotoNextBlock();
      }

      function checkEmail(email) {
        if (!email) {
          return;
        }
  
        RegistrationService.checkEmail(email).then(
          function(result) {
            vm.duplicateEmail = result.data.data > 0;
          },
          function(error) {
            toaster.clear('account');
  
            toaster.pop({
              type: 'error',
              body: error.data.message || error.statusText,
              toasterId: 'account'
            });
            document.getElementById('account-details').scrollIntoView(true);
          }
        );
      }
    }
})();
