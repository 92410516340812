(function() {
  'use strict';

  angular.module('careerKeyCentral').directive('orderSummary', orderSummary);

  function orderSummary() {
    return {
      restrict: 'E',
      templateUrl: '/app/shared/views/partials/orderSummary.html'
    };
  }
})();
