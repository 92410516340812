(() => {
  'use strict';

  angular.module('app.user').factory('SsoService', [
    'ApiCaller',
    'APP_CONSTANTS',
    SsoService
  ]);

  function SsoService(
    ApiCaller,
    APP_CONSTANTS,
  ) {
    var self = this;

    self.getDomain = getDomain;
    self.getEmail = getEmail;

    function getEmail(email) {
      const url = APP_CONSTANTS.rootUrl + '/sso/email';
      const data = {
        email: email,
      };

      return ApiCaller.apiPost(url, data);
    }

    function getDomain(domain) {
      const url = APP_CONSTANTS.rootUrl + '/sso/domain';
      const data = {
        domain: domain,
      };

      return ApiCaller.apiPost(url, data);
    }

    return self;
  }
})();