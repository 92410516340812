(function() {
  'use strict';
  angular
    .module('app.sponsoredOrganization')
    .factory('OrganizationInfoService', OrganizationInfoService);

  OrganizationInfoService.$inject = [
    'APP_CONSTANTS',
    'ApiCaller',
    '$filter',
    '$q',
  ];

  /* eslint-disable */
  function OrganizationInfoService(
    APP_CONSTANTS,
    ApiCaller,
    $filter,
    $q,
  ) {
    var self = this;

    self.setImage = setImage;
    self.getImage = getImage;
    self.getOrganizationImage = getOrganizationImage;
    self.changePrimaryContact = changePrimaryContact;
    self.fetchDiscoverersCount = fetchDiscoverersCount;
    self.fetchOrganizationInfo = fetchOrganizationInfo;
    self.updateOrganizationInfo = updateOrganizationInfo;
    self.updateOrganizationImage = updateOrganizationImage;
    self.fetchOrganizationClusters = fetchOrganizationClusters;
    self.fetchAccountAndOrganizationManagers = fetchAccountAndOrganizationManagers;

    function fetchOrganizationInfo(organziationId) {
      const key = `organizationInfo-${organziationId}`;

      if (self[key]) return self[key];

      var url = $filter('sprintf')(
        APP_CONSTANTS.sponsoredOrganizationDetails,
        APP_CONSTANTS.rootUrl,
        organziationId
      );

      self[key] = ApiCaller.apiGet(url);

      self[key].finally(() => {
        delete self[key];
      });

      return self[key];
    }

    function fetchOrganizationClusters(organizationId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.organizationClusters,
        APP_CONSTANTS.rootUrl,
        organizationId
      );

      ApiCaller.apiGet(url)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function getOrganizationImage(organziationId) {
      const key = `organizationImage-${organziationId}`;

      if (self[key]) return self[key];

      var url = $filter('sprintf')(
        APP_CONSTANTS.sponsoredOrganizationImage,
        APP_CONSTANTS.rootUrl,
        organziationId
      );

      self[key] = ApiCaller.apiGet(url);

      self[key].finally(() => {
        delete self[key];
      });

      return self[key];
    }

    function fetchDiscoverersCount() {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.discoverersCount,
        APP_CONSTANTS.rootUrl
      );

      ApiCaller.apiGet(url)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function updateOrganizationInfo(organizationInfo, sponsoredOrganizationId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.sponsoredOrganizationDetails,
        APP_CONSTANTS.rootUrl,
        sponsoredOrganizationId
      );

      ApiCaller.apiPut(url, organizationInfo)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function updateOrganizationImage(imageData, customerId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.sponsoredOrganizationImage,
        APP_CONSTANTS.rootUrl,
        customerId
      );

      ApiCaller.apiPut(url, imageData)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function fetchAccountAndOrganizationManagers(sponsoredOrganizationId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.accountAndOrganizationManagerList,
        APP_CONSTANTS.rootUrl,
        sponsoredOrganizationId
      );

      ApiCaller.apiGet(url)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function changePrimaryContact(data, sponsoredOrganizationId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.organizationPrimaryAccountManager,
        APP_CONSTANTS.rootUrl,
        sponsoredOrganizationId
      );

      ApiCaller.apiPut(url, data)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function setImage(image) {
      self.image = image;
    }

    function getImage(image) {
      return self.image;
    }

    return self;
  }
})();
/* eslint-enable */
