(function() {
  'use strict';

  angular
    .module('app.customerAccount')
    .controller('AddSubscriptionController', AddSubscriptionController);

  AddSubscriptionController.$inject = [
    '$uibModal',
    '$state',
    'APP_CONSTANTS',
    'toaster',
    '$uibModalInstance',
    'SessionService',
    'RegistrationService',
    'NewSubscriptionService',
    'ngDialog',
    'data',
    'NewBillingService',
    'BillingInfoService',
    '$anchorScroll',
    '$location'
  ];
  function AddSubscriptionController(
    $uibModal,
    $state,
    APP_CONSTANTS,
    toaster,
    $uibModalInstance,
    SessionService,
    RegistrationService,
    NewSubscriptionService,
    ngDialog,
    data,
    NewBillingService,
    BillingInfoService,
    $anchorScroll,
    $location
  ) {
    var vm = this;

    vm.loading = {};
    vm.billingInfo = {};
    vm.selectedPlan = {};
    vm.paymentMenthod = {};
    vm.selectedPlanType = '';
    vm.noAvailablePlans = false;
    vm.isSchoolTypeEnterprise = false;
    vm.noPaymentOrBillingDetail = false;
    vm.decimalFixedValue = APP_CONSTANTS.decimalFixedValue;
    vm.allCKDPlans = [];
    vm.allCDPPlans = [];

    vm.cancel = cancel;
    vm.submit = submit;
    vm.changeTab = changeTab;
    vm.clearCouponCode = clearCouponCode;
    vm.applyCouponCode = applyCouponCode;
    vm.selectPlanType = selectPlanType;
    vm.handlePlanSelect = handlePlanSelect;
    vm.calculatePerUserPrice = calculatePerUserPrice;

    var currentUser = SessionService.getUser();
    var isFreeTrail =
      data.subscription &&
      data.subscription.type === APP_CONSTANTS.REGISTER_TYPE.TRIAL
        ? true
        : false;

    vm.constants = {
      noAvailablePlans: APP_CONSTANTS.noAvailablePlans,
      heading: APP_CONSTANTS.LABEL.ADD_SUBSCRIPTION,
      noPaymentOrBillingDetail: APP_CONSTANTS.noPaymentOrBillingDetail
    };

    activate();

    function activate() {
      vm.paymentMenthod.poNumber = data.poNumber;

      if (_.isEmpty(data.billingDetail) || _.isEmpty(data.paymentDetail))
        vm.noPaymentOrBillingDetail = true;
      else {
        fetchPlans();
        vm.billingDetail = data.billingDetail;
        vm.autoCollection = data.billingDetail.autoCollection;
      }
    }

    function calculatePerUserPrice(plan) {
      if (plan.period_unit === APP_CONSTANTS.AUTO_RENEWAL_TYPE.MONTHLY)
        return (parseFloat(plan.price) * 12) / plan.cf_quantity;
      else return parseFloat(plan.price) / plan.cf_quantity;
    }

    function fetchPlans() {
      vm.loading.plans = true;

      var params = {
        cf_language:
          (currentUser.locale &&
            currentUser.locale.language &&
            currentUser.cf_language_id) ||
          'en',
        cf_country:
          (currentUser.locale &&
            currentUser.locale.country &&
            currentUser.cf_country_id) ||
          'US'
      };

      NewBillingService.getCustomerPlans(params)
        .then(function(planDetails) {
          if (
            planDetails.customer_type === APP_CONSTANTS.CUSTOMER_TYPE.ENTERPRISE
          )
            vm.isSchoolTypeEnterprise = true;

          var plans = planDetails.plans;

          if (planDetails.plans.length === 0) vm.noAvailablePlans = true;
          else {
            vm.allPrepaidPlans = plans.filter(_checkPrepaidPlans);
            vm.allAutoRenewalPlans = plans.filter(_checkAutoRenewalPlans);
            
            vm.allCKDPlans = plans.filter(_getCKDPlans);
            vm.allCDPPlans = plans.filter(_getCDPPlans);

            vm.allCKDPrepaidPlans = vm.allPrepaidPlans.filter(_getCKDPlans);
            vm.allCDPPrepaidPlans = vm.allPrepaidPlans.filter(_getCDPPlans);

            vm.allCKDAutoRenewalPlans = vm.allAutoRenewalPlans.filter(_getCKDPlans);
            vm.allCDPAutoRenewalPlans = vm.allAutoRenewalPlans.filter(_getCDPPlans);
            getActiveTab();
            categorizePlans();
          }

          vm.loading.plans = false;
        })
        .catch(function(err) {
          closePopup();
          toaster.pop({
            type: 'error',
            body: err.data.message || err.statusText
          });
          vm.loading.plans = false;
        });
    }

    function categorizePlans() {
      var prepaidPlans = vm.allCKDPrepaidPlans;
      var autoRenewalPlans = vm.allCKDAutoRenewalPlans;

      if (vm.activeTab === APP_CONSTANTS.CDP) {
        prepaidPlans = vm.allCDPPrepaidPlans;
        autoRenewalPlans = vm.allCDPAutoRenewalPlans;
      }
      vm.adult = {
        prepaidPlans: prepaidPlans.filter(_getAdultPlan),
        autoRenewalPlans: autoRenewalPlans.filter(_getAdultPlan)
      };
      vm.higher = {
        prepaidPlans: prepaidPlans.filter(_getHigherPlan),
        autoRenewalPlans: autoRenewalPlans.filter(_getHigherPlan)
      };
      vm.secondary = {
        prepaidPlans: prepaidPlans.filter(_getSecondaryPlan),
        autoRenewalPlans: autoRenewalPlans.filter(_getSecondaryPlan)
      };

      vm.autoRenewalType = APP_CONSTANTS.AUTO_RENEWAL_TYPE.YEARLY;
      
      // vm.prepaidPlans = vm.isSchoolTypeEnterprise
      //   ? vm.higher.prepaidPlans
      //   : vm.secondary.prepaidPlans;

      vm.prepaidPlans = prepaidPlans;

      // vm.autoRenewalPlans = vm.isSchoolTypeEnterprise
      //   ? vm.higher.autoRenewalPlans
      //   : vm.secondary.autoRenewalPlans;
      
      vm.autoRenewalPlans = autoRenewalPlans;

      vm.selectedPlanType = vm.isSchoolTypeEnterprise
        ? APP_CONSTANTS.AUDIENCE.HIGHER
        : APP_CONSTANTS.AUDIENCE.SECONDARY;
    }

    function _getAdultPlan(plan) {
      return (
        plan.plan.cf_audience.toLowerCase() === APP_CONSTANTS.AUDIENCE.ADULT
      );
    }

    function _getHigherPlan(plan) {
      return (
        plan.plan.cf_audience.toLowerCase() === APP_CONSTANTS.AUDIENCE.HIGHER
      );
    }

    function _getSecondaryPlan(plan) {
      return (
        plan.plan.cf_audience.toLowerCase() === APP_CONSTANTS.AUDIENCE.SECONDARY
      );
    }

    function _checkPrepaidPlans(plan) {
      return (
        plan.plan.cf_subscription_type.toLowerCase() ===
        APP_CONSTANTS.SUBSCRIPTION_TYPE.PREPAID
      );
    }

    function _checkAutoRenewalPlans(plan) {
      return (
        plan.plan.cf_subscription_type.toLowerCase() ===
        APP_CONSTANTS.SUBSCRIPTION_TYPE.AUTO_RENEWAL
      );
    }

    function _getCKDPlans(plan) {
      return (
        !plan.plan.cf_type ||
        plan.plan.cf_type.toLowerCase() === APP_CONSTANTS.CKD
      );
    }

    function _getCDPPlans(plan) {
      return (
        plan.plan.cf_type &&
        plan.plan.cf_type.toLowerCase() === APP_CONSTANTS.CDP
      );
    }

    function selectPlanType() {
      switch (vm.selectedPlanType) {
        case APP_CONSTANTS.AUDIENCE.ADULT:
          vm.prepaidPlans = vm.adult.prepaidPlans;
          vm.autoRenewalPlans = vm.adult.autoRenewalPlans;
          break;

        case APP_CONSTANTS.AUDIENCE.HIGHER:
          vm.prepaidPlans = vm.higher.prepaidPlans;
          vm.autoRenewalPlans = vm.higher.autoRenewalPlans;
          break;

        case APP_CONSTANTS.AUDIENCE.SECONDARY:
          vm.prepaidPlans = vm.secondary.prepaidPlans;
          vm.autoRenewalPlans = vm.secondary.autoRenewalPlans;
          break;

        default:
          vm.prepaidPlans = vm.higher.prepaidPlans;
          vm.autoRenewalPlans = vm.higher.autoRenewalPlans;
      }
    }

    function clearCouponCode() {
      if (vm.plan && vm.promotionCode) handlePlanSelect(vm.plan);

      vm.type = '';
      vm.promotionCode = '';
    }

    function applyCouponCode() {
      vm.type = 'apply';
      var promotionCode = [];

      vm.invalidPlan = false;
      vm.invalidPromotionCode = false;

      if (!vm.plan) {
        vm.invalidPlan = true;

        return;
      }

      if (!vm.promotionCode) {
        vm.invalidPromotionCode = true;

        return;
      }

      promotionCode.push(vm.promotionCode);

      handlePlanSelect(vm.plan, promotionCode);
    }

    function handlePlanSelect(plan, promotionCode) {
      vm.loading.plans = true;
      vm.plan = plan;
      promotionCode = promotionCode || [];

      if (!promotionCode.length) {
        vm.type = '';
        vm.promotionCode = '';
      }

      var customerInfo = {
        taxability: plan.taxable
          ? APP_CONSTANTS.TAXABILITY.TAXABLE
          : APP_CONSTANTS.TAXABILITY.EXEMPT
      };

      vm.billingInfo.country = {
        countryCode: vm.billingDetail.country,
        name: vm.billingDetail.state_code
      };
      vm.billingInfo.city = vm.billingDetail.city;
      vm.billingInfo.zipCode = vm.billingDetail.zip;
      vm.billingInfo.addressLine1 = vm.billingDetail.line1;
      vm.billingInfo.lastName = vm.billingDetail.last_name;
      vm.billingInfo.firstName = vm.billingDetail.first_name;

      if (plan.cf_subscription_type === APP_CONSTANTS.SUBSCRIPTION_TYPE.PREPAID)
        vm.plan.paymentType = APP_CONSTANTS.PAYMENT_PERIOD.PREPAID;

      if (
        plan.cf_subscription_type ===
        APP_CONSTANTS.SUBSCRIPTION_TYPE.AUTO_RENEWAL
      )
        if (plan.period_unit === APP_CONSTANTS.AUTO_RENEWAL_TYPE.YEARLY)
          vm.plan.paymentType =
            APP_CONSTANTS.PAYMENT_PERIOD.AUTO_RENEWAL_ANNUAL;
        else
          vm.plan.paymentType =
            APP_CONSTANTS.PAYMENT_PERIOD.AUTO_RENEWAL_MONTHLY;

      BillingInfoService.getTaxEstimate(
        plan.id,
        vm.billingInfo,
        customerInfo,
        promotionCode || []
      )
        .then(function(result) {
          vm.loading.plans = false;
          vm.totalAmount = result.data.data.invoice_estimate.total;
          vm.taxAmount =
            result.data.data.invoice_estimate.line_items[0].tax_amount;
          vm.discount =
            vm.type === 'apply'
              ? result.data.data.invoice_estimate.discounts[0].amount
              : '';
        })
        .catch(function(err) {
          vm.loading.plans = false;

          if (err.data.error && err.data.error.code === 400)
            toaster.pop({
              type: 'error',
              body: APP_CONSTANTS.MESSAGE.INVALID_BILLING_INFORMATION,
              toasterId: 'addSubscription'
            });
          else if (err.data && err.data.code === 404)
            toaster.pop({
              type: 'error',
              body: 'Could not apply promotion code.',
              toasterId: 'addSubscription'
            });
          else
            toaster.pop({
              type: 'error',
              body: err.data.message || err.statusText,
              toasterId: 'addSubscription'
            });
        });
    }

    function submit() {
      vm.invalid = false;
      vm.planNotSelected = false;

      if (_.isEmpty(vm.selectedPlan)) {
        vm.invalid = true;
        $location.hash('myModalLabel');
        $anchorScroll();
        toaster.clear('addBillingSubscription');
        toaster.pop({
          type: 'error',
          body: APP_CONSTANTS.MESSAGE.planSelectionValidation,
          showCloseButton: true,
          toasterId: 'addBillingSubscription',
          timeout: 5000
        });

        return;
      }

      if (_.isEmpty(vm.selectedPlan.planId)) {
        vm.planNotSelected = true;

        return;
      }

      if (!_.isEmpty(vm.paymentMenthod)) {
        vm.selectedPlan.payment = vm.paymentMenthod;
      }

      ngDialog
        .openConfirm({
          template: 'addSubscriptionConformDialog'
        })
        .then(
          function() {
            vm.loading.plans = true;
            if (isFreeTrail) upgradeFreeTrail();
            else addNewSubscription();
          },
          function() {
            ngDialog.close();
          }
        );
    }

    function upgradeFreeTrail() {
      vm.loading.plans = true;
      vm.selectedPlan.subscriptionId = data.subscription.id;
      vm.selectedPlan.payment.promotionCode =
        vm.promotionCode && vm.type === 'apply' ? vm.promotionCode : '';

      NewBillingService.upgradeFreeTrial(vm.selectedPlan)
        .then(function() {
          vm.loading.plans = false;
          closePopup();
          toaster.pop(
            'success',
            APP_CONSTANTS.MESSAGE.subscriptionUpgradedSuccessfully
          );
        })
        .catch(function(error) {
          vm.loading.plans = false;
          toaster.pop({
            type: 'error',
            body: error.data.message || error.statusText,
            toasterId: 'addSubscription'
          });
        });
    }

    function addNewSubscription() {
      vm.selectedPlan.payment.promotionCode =
        vm.promotionCode && vm.type === 'apply' ? vm.promotionCode : '';

      NewSubscriptionService.addNewSubscription(vm.selectedPlan)
        .then(function() {
          vm.loading.plans = false;
          closePopup();
          toaster.pop(
            'success',
            APP_CONSTANTS.MESSAGE.subscriptionCreatedSuccessfully
          );
        })
        .catch(function(error) {
          vm.loading.plans = false;
          toaster.pop({
            type: 'error',
            body: error.data.message || error.statusText,
            toasterId: 'addSubscription'
          });
          document.getElementById('addSubscription').scrollIntoView(true);
        });
    }

    function closePopup() {
      $uibModalInstance.close('closed');
    }

    function cancel() {
      $uibModalInstance.dismiss('cancel');
    }

    function getActiveTab() {
      vm.activeTab = APP_CONSTANTS.CKD;

      if (vm.allCDPPlans.length && !vm.allCKDPlans.length) {
        vm.activeTab = APP_CONSTANTS.CDP;
      }
    }

    function changeTab(tabName) {
      if (vm.activeTab !== tabName) {
        vm.activeTab = tabName;
        categorizePlans();
      }
    }
  }
})();
