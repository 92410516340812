/**
 * Created by Romit Amgai<romitamgai@lftechnology.com> on 4/20/17.
 */
(function() {
  'use strict';
  angular.module('careerKeyCentral').filter('chargebeePrice', chargebeePrice);

  chargebeePrice.$inject = ['$filter'];

  function chargebeePrice($filter) {
    return function(price, decimals) {
      if (isNaN(price) || isNaN(decimals)) {
        return 0;
      }

      var calculatedPrice = parseFloat(price / 100).toFixed(decimals);
      //Rounding the price to nearest 25 cent
      return (Math.round(calculatedPrice * 4) / 4).toFixed(2);
    };
  }
})();
