(function() {
  'use strict';

  angular.module('app.mailbox').factory('CDPMessageService', CDPMessageService);

  CDPMessageService.$inject = ['APP_CONSTANTS', 'ApiCaller', '$q', '$filter'];

  function CDPMessageService(APP_CONSTANTS, ApiCaller, $q, $filter) {
    var self = this;

    self.sendMessage = sendMessage;
    self.fetchDiscoverers = fetchDiscoverers;
    self.validateEmail = validateEmail;

    function fetchDiscoverers() {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.fetchDiscovererGroup,
        APP_CONSTANTS.rootUrl
      );

      ApiCaller.apiGet(url).then(
        function(result) {
          deferred.resolve(result);
        },
        function(error) {
          deferred.reject(error);
        }
      );

      return deferred.promise;
    }

    function sendMessage(messageContent) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.sendCdpMessage,
        APP_CONSTANTS.rootUrl
      );

      ApiCaller.apiPost(url, messageContent).then(
        function(result) {
          deferred.resolve(result);
        },
        function(error) {
          deferred.reject(error);
        }
      );

      return deferred.promise;
    }

    function validateEmail(emails) {
      var deferred = $q.defer();
      var invalidEmails = [];

      for (var email in emails) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!re.test(emails[email])) {
          invalidEmails.push(emails[email]);
        }
      }
      if (invalidEmails.length > 0) {
        deferred.reject(invalidEmails);
      } else {
        deferred.resolve();
      }

      return deferred.promise;
    }

    return self;
  }
})();
