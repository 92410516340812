(function() {
  'use strict';
  angular
    .module('app.shared')
    .controller('ChangePasswordController', ChangePasswordController);
  ChangePasswordController.$inject = [
    'toaster',
    'SessionService',
    'APP_CONSTANTS',
    'PasswordService'
  ];

  function ChangePasswordController(
    toaster,
    SessionService,
    APP_CONSTANTS,
    PasswordService
  ) {
    var vm = this;

    vm.loading = {};
    vm.email = '';
    vm.keyPress = keyPress;
    vm.passwordDontMatch = false;
    vm.invalidPasswordPattern = false;
    vm.loginUserDetail = SessionService.getUser();
    vm.refreshToken = SessionService.getRefreshToken();

    vm.regex = {
      password: APP_CONSTANTS.REGEX.PASSWORD
    };

    vm.showPassword = {
      currentPassword: false,
      newPassword: false,
      confirmPassword: false
    };

    vm.viewPassword = viewPassword;
    vm.changePassword = changePassword;
    vm.checkPasswordMatch = checkPasswordMatch;
    vm.checkPasswordPattern = checkPasswordPattern;

    function checkPasswordMatch() {
      if (!vm.newPassword || vm.newPassword !== vm.newConfirmPassword)
        vm.passwordDontMatch = true;
      else vm.passwordDontMatch = false;
    }

    function checkPasswordPattern() {
      vm.invalidPasswordPattern = vm.newPassword ? false : true;
      if (vm.newConfirmPassword) checkPasswordMatch();
    }

    function changePassword() {
      if (!vm.formChangePassword.$valid || vm.passwordDontMatch) {
        vm.formSubmit = true;

        return;
      } else {
        if (vm.oldPassword === vm.newPassword) {
          toaster.pop(
            'warning',
            APP_CONSTANTS.MESSAGE.oldNewPasswordValidation
          );

          return;
        }

        var data = {
          oldPassword: vm.oldPassword,
          newPassword: vm.newPassword,
          refreshToken: vm.refreshToken,
          confirmNewPassword: vm.newConfirmPassword
        };

        var payload = {
          password: data
        };

        vm.loading.changePassword = true;
        PasswordService.changePassword(payload).then(
          function() {
            vm.loading.changePassword = false;
            vm.newPassword = vm.oldPassword = vm.newConfirmPassword = '';
            toaster.pop('success', APP_CONSTANTS.MESSAGE.resetPassword);
          },
          function(error) {
            vm.loading.changePassword = false;
            toaster.pop('error', error.data.message || error.statusText);
          }
        );
      }
    }

    function keyPress(event) {
      if (event.keyCode === 13) {
        return vm.changePassword();
      }
    }

    function viewPassword(key) {
      vm.showPassword[key] = !vm.showPassword[key];
    }
  }
})();
