(function() {
  'use strict';

  angular
    .module('app.customerAccount')
    .controller('BillingHistoryController', BillingHistoryController);

  BillingHistoryController.$inject = [
    '$uibModal',
    '$state',
    'APP_CONSTANTS',
    'toaster',
    'BillingHistoryService',
    '$window',
    'DTOptionsBuilder',
    '$scope'
  ];
  function BillingHistoryController(
    $uibModal,
    $state,
    APP_CONSTANTS,
    toaster,
    BillingHistoryService,
    $window,
    DTOptionsBuilder,
    $scope
  ) {
    var vm = this;

    vm.loading = {};
    vm.selectedType = 'All';
    vm.billingHistories = [];
    vm.getInvoice = getInvoice;
    vm.typeChanged = typeChanged;
    vm.getCreditNote = getCreditNote;
    vm.getInvoiceOrCreditNote = getInvoiceOrCreditNote;
    vm.decimalFixedValue = APP_CONSTANTS.decimalFixedValue;
    vm.allPaymentType = Object.values(APP_CONSTANTS.PAYMENT_TYPE);

    vm.dtOptions = DTOptionsBuilder.newOptions().withOption('order', [
      [2, 'asc']
    ]);

    activate();

    function activate() {
      $scope.$parent.vm.setActiveTab();
      fetchBillingHistory();
    }

    function fetchBillingHistory() {
      vm.loading.billingHistory = true;
      BillingHistoryService.fetchBillingHistory()
        .then(function(response) {
          vm.allBillingHistories = _.get(response, 'data.data', {});
          angular.copy(vm.allBillingHistories, vm.billingHistories);

          vm.loading.billingHistory = false;
        })
        .catch(function(error) {
          vm.loading.billingHistory = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function getInvoiceOrCreditNote(orderNo, paymentType) {
      if (
        paymentType.toLowerCase() ===
        APP_CONSTANTS.PAYMENT_TYPE.REFUND.toLowerCase()
      )
        vm.getCreditNote(orderNo);
      else vm.getInvoice(orderNo);
    }

    function getInvoice(orderNo) {
      vm.loading.billingHistory = true;
      BillingHistoryService.fetchInvoiceLink(orderNo)
        .then(function(response) {
          vm.downloadLink = response.data.data;
          vm.loading.billingHistory = false;
          $window.open(vm.downloadLink.download_url, '_self');
        })
        .catch(function(error) {
          vm.loading.billingHistory = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function typeChanged() {
      if (vm.selectedType === 'All')
        angular.copy(vm.allBillingHistories, vm.billingHistories);
      else
        angular.copy(
          vm.allBillingHistories.filter(function(history) {
            if (history.type.toLowerCase() === vm.selectedType.toLowerCase())
              return history;
          }),
          vm.billingHistories
        );
    }

    function getCreditNote(orderNo) {
      vm.loading.billingHistory = true;
      BillingHistoryService.fetchCreditNoteLink(orderNo)
        .then(function(response) {
          vm.downloadLink = response.data.data;
          vm.loading.billingHistory = false;
          $window.open(vm.downloadLink.download_url, '_self');
        })
        .catch(function(error) {
          vm.loading.billingHistory = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }
  }
})();
