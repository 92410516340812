(function () {
  'use strict';

  angular
    .module('app.shared')
    .controller('AdminEditAccountInformationModalController', AdminEditAccountInformationModalController);

  AdminEditAccountInformationModalController.$inject = [
    '$uibModalInstance',
    'AdminCustomerBillingService',
    'RegistrationService',
    'toaster',
    'APP_CONSTANTS',
    'data'
  ];

  function AdminEditAccountInformationModalController(
    $uibModalInstance,
    AdminCustomerBillingService,
    RegistrationService,
    toaster,
    APP_CONSTANTS,
    data
  ) {
    var vm = this;

    vm.loading = {};
    vm.accountInfo = {};
    vm.originalAccountInfo = {};
    vm.fetchStates = fetchStates;
    vm.updateAccountInformation = updateAccountInformation;

    init();

    function init() {
      angular.copy(data.accountInfo, vm.accountInfo);
      angular.copy(data.accountInfo, vm.originalAccountInfo);
      fetchCountries();
    }

    function fetchCountries(name) {
      vm.loading.countries = true;

      RegistrationService.getCountries(name)
        .then(function (result) {
          vm.countries = result.data.data.map(function (country) {
            return {
              id: country.countryCode,
              name: country.name
            }
          });
          fetchStates();
        })
        .catch(function (error) {
          toaster.pop('error', error.data.message || error.statusText);
        })
        .finally(function () {
          vm.loading.countries = false;
        });
    }

    function fetchStates() {
      vm.loading.states = false;

      RegistrationService.getStates(vm.accountInfo.country.id)
        .then(function (result) {
          vm.states = result.data.data.states;
          var selectedState = vm.states.find(function (state) {
            return state.code === vm.originalAccountInfo.state_code;
          });
          vm.accountInfo.state_code = selectedState ? selectedState.code : null;
        })
        .catch(function (error) {
          toaster.pop('error', error.data.message || error.statusText);
        })
        .finally(function () {
          vm.loading.states = false;
        });
    }

    function updateAccountInformation(form) {
      vm.form = form;

      if (vm.form.$invalid) return;

      var newAccountInfo = {
        city: vm.form.city.$modelValue,
        zip: vm.form.zipCode.$modelValue,
        state: vm.accountInfo.state_code,
        line1: vm.form.addressLine1.$modelValue,
        line2: vm.form.addressLine2.$modelValue,
        last_name: vm.form.lastName.$modelValue,
        first_name: vm.form.firstName.$modelValue,
        country: vm.accountInfo.country.id,
      };

      vm.loading.accountInfo = true;

      AdminCustomerBillingService.updateAccountInformation(newAccountInfo, data.customerId)
        .then(function (response) {
          vm.loading.accountInfo = false;
          vm.accountInfo = _.get(response, 'data.data', {});
          toaster.pop(
            'success',
            APP_CONSTANTS.MESSAGE.accountInfoUpdateSuccesss
          );
          vm.ok();
        })
        .catch(function (err) {
          vm.loading.accountInfo = false;

          if (err.data && err.data.error && err.data.error.code === 400)
            toaster.pop({
              type: 'error',
              body: 'Invalid Form Details',
              toasterId: 'updateAccount'
            });
          else
            toaster.pop({
              type: 'error',
              body: err.data.message || err.statusText,
              toasterId: 'updateAccount'
            });
        });
    }

    vm.ok = function () {
      $uibModalInstance.close('closed');
    };

    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  }
})();