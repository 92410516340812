(function() {
  'use strict';

  /**
   * Allow users to input only numbers and "-" in zipcode field
   */
  angular.module('careerKeyCentral').directive('zipCode', zipCode);

  function zipCode() {
    return {
      require: 'ngModel',
      link: function(scope, element, attrs, modelCtrl) {
        modelCtrl.$parsers.push(function(inputValue) {
          var transformedInput = inputValue
            ? inputValue.replace(/[^0-9a-zA-Z- ]/g, '')
            : null;

          if (transformedInput != inputValue) {
            modelCtrl.$setViewValue(transformedInput);
            modelCtrl.$render();
          }

          return transformedInput;
        });
      }
    };
  }
})();
