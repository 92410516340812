(function() {
  'use strict';

  angular
    .module('app.sponsoredOrganization')
    .controller('TechnicalDashboardController', TechnicalDashboardController);

  TechnicalDashboardController.$inject = [
    '$rootScope',
    '$scope',
    '$uibModal',
    '$state',
    'APP_CONSTANTS',
    'toaster',
    'ngDialog',
    'TechnicalDashboardService',
    'SessionService',
    '$timeout',
    '$filter'
  ];

  function TechnicalDashboardController(
    $rootScope,
    $scope,
    $uibModal,
    $state,
    APP_CONSTANTS,
    toaster,
    ngDialog,
    TechnicalDashboardService,
    SessionService,
    $timeout,
    $filter
  ) {
    var currentUser = SessionService.getUser();

    if (
      !(
        currentUser.roles.isOrganizationManager ||
        currentUser.roles.isAccountManager ||
        currentUser.roles.isCounselor ||
        currentUser.roles.isTechnicalContact
      )
    ) {
      $state.go('app.ownerdashboard');

      return;
    }

    var vm = this;

    vm.subscriptionType = currentUser.subscriptionType || APP_CONSTANTS.CKD;
    vm.plugins = [];
    
    // TODO: Need to refactor hostPageUrl and customLabel assignments
    vm.customLabel = [];
    vm.hostPageUrl = [];
    vm.ckdPlugins = [];
    vm.cdpPlugins = [];
    vm.unifyPlugins = [];
    vm.filteredPlugins = [];
    vm.loading = {};
    getActiveTab();
    getHeading();

    vm.saveUrl = saveUrl;
    vm.addPlugin = addPlugin;
    vm.changeTab = changeTab;
    vm.generatePlugin = generatePlugin;
    vm.configurePlugin = configurePlugin;
    vm.hideHostUrlField = hideHostUrlField;
    vm.changePluginStatus = changePluginStatus;
    vm.displayCKDInstallationInstructions = displayCKDInstallationInstructions;

    $scope.copyToolTip = 'Copy!';

    var modalInstance = '';
    var sponsoredOrganizationId = currentUser.sponsoredOrganizationId;

    activate();

    function activate() {
      if ($state.includes('app.technicaldashboard')) {
        vm.showBreadCrumb = true;
      }
      fetchPlugins();
    }


    $scope.copyClicked = function() {
      $scope.copyToolTip = 'Copied to clipboard!';
      $timeout(resetCopyToolTip, 3000);
    };

    function resetCopyToolTip() {
      $scope.copyToolTip = 'Copy!';
    }

    function fetchPlugins() {
      vm.plugins = [];
      vm.loading.keys = true;

      TechnicalDashboardService.fetchCustomerApiKeys(sponsoredOrganizationId)
        .then(function(response) {
          vm.plugins = _.get(response, 'data.data', []);
          vm.ckdPlugins = [];
          vm.cdpPlugins = [];
          vm.unifyPlugins = [];
          vm.plugins.forEach(p => {
            if (p.type === APP_CONSTANTS.CDP) return vm.cdpPlugins.push(p);
            if (p.type === APP_CONSTANTS.BOTH) return vm.unifyPlugins.push(p);
            if (p.type === APP_CONSTANTS.CKD) return vm.ckdPlugins.push(p);
          });
          populatePluginTable();
          vm.loading.keys = false;
        })
        .catch(function(error) {
          vm.loading.keys = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }


    function saveUrl(index, valid) {
      if (!valid) {
        toaster.pop('warning', APP_CONSTANTS.MESSAGE.validUrl);

        return;
      }

      var apiKey = existingApiKey(vm.hostPageUrl[index], null);

      if (apiKey.length) {
        ngDialog
          .openConfirm({
            template: 'confirmationDialog',
            controllerAs: 'vm',
            plan: true,
            controller: function() {
              var vm = this;

              vm.confirmationMessage =
                APP_CONSTANTS.MESSAGE.technicalConfirmMessage;
            }
          })
          .then(
            function() {
              apiKey[0].status = false;
              addApiKey(apiKey[0], vm.hostPageUrl[index], vm.customLabel[index]);
            },
            function() {
              ngDialog.close();
            }
          );
      } else {
        addApiKey('', vm.hostPageUrl[index], vm.customLabel[index]);
      }
    }

    function existingApiKey(hostPageUrl, id) {
      var filteredApikeys = _.filter(vm.plugins, function(item) {
        if (id !== null) {
          return (
            item.hostPageUrl === hostPageUrl &&
            item.status === true &&
            item.id !== id
          );
        } else {
          return item.hostPageUrl === hostPageUrl && item.status === true;
        }
      });

      return filteredApikeys;
    }

    function hideHostUrlField() {
      vm.hostPageUrl = [];
      vm.customLabel = [];
      vm.filteredPlugins.splice(0, 1);
      vm.loading.keys = false;
    }

    function addApiKey(existingApiKey, hostedPageUrl, customLabel) {
      vm.loading.keys = true;
      TechnicalDashboardService.addCustomerApiKey(
        {
          hostPageUrl: hostedPageUrl,
          customLabel: customLabel,
          existingApiKey: existingApiKey,
          sponsoredOrganizationId: sponsoredOrganizationId,
          type: vm.activeTab
        },
        sponsoredOrganizationId
      )
        .then(function() {
          vm.hostPageUrl = [];
          vm.customLabel = [];
          vm.loading.keys = false;

          fetchPlugins();
          toaster.pop(
            'success',
            APP_CONSTANTS.MESSAGE.technicalApiKeyAddedSuccess
          );
        })
        .catch(function(error) {
          vm.loading.keys = false;
          vm.hostPageUrl = [];
          vm.customLabel = [];

          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function addPlugin() {
      if (
        vm.filteredPlugins.length === 0 ||
        vm.filteredPlugins[0].hostPageUrl !== null
      ) {
        vm.filteredPlugins.unshift({
          isNewPlugin: true,
          status: false,
          createdAt: '',
          hostPageUrl: null,
          customLabel: '',
          accessKey: '',
          copy: false
        });
      }
    }

    function generatePlugin(plugin) {
      if (plugin.isNewPlugin) return;

      TechnicalDashboardService.generateWidget(plugin)
        .then(function(response) {
          $scope.widget = response;
          ngDialog.open({
            template:
              'app/v2/sponsoredOrganization/technicalDashboard/ckdPlugin/ckdPluginWrapper.html',
            scope: $scope,
            className: 'ngdialog-widget'
          });
        })
        .catch(function(error) {
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function configurePlugin(plugin) {
      if (plugin.isNewPlugin || modalInstance) return;

      modalInstance = $uibModal.open({
        templateUrl: 'app/v2/sponsoredOrganization/technicalDashboard/pluginConfiguration/configurePlugin.html',
        controller: 'ConfigurePluginController',
        controllerAs: 'vm',
        size: 'lg',
        resolve: {
          plugin: () => plugin
        }
      });

      modalInstance.result
        .then(() => fetchPlugins())
        .finally(() => modalInstance = '');
    }

    function displayCKDInstallationInstructions() {
      if (!modalInstance) {
        modalInstance = $uibModal.open({
          templateUrl:
            'app/v2/sponsoredOrganization/technicalDashboard/widgetInstallationInstructions/displayInstructions.html',
          controller: 'DisplayInstructionsController',
          controllerAs: 'vm'
        });

        modalInstance.result
          .then(function() {
            modalInstance = '';
          })
          .catch(function() {
            modalInstance = '';
          });
      }
    }

    function getConfirmationMessage(status) {
      if (status) return APP_CONSTANTS.MESSAGE.technicalReactivateConfirm;

      return APP_CONSTANTS.MESSAGE.technicalDeactivateConfirm;
    }

    function changePluginStatus(apiKey) {
      var confirmationMessage = getConfirmationMessage(apiKey.status);
      var activeApiKey = existingApiKey(apiKey.hostPageUrl, apiKey.id);

      if (activeApiKey.length) {
        activeApiKey[0].status = false;
      }

      ngDialog
        .openConfirm({
          template: 'confirmationDialog',
          controllerAs: 'vm',
          plan: true,
          controller: function() {
            var vm = this;

            vm.confirmationMessage = confirmationMessage;
          }
        })
        .then(
          function() {
            vm.loading.keys = true;
            vm.confirmationMessage = confirmationMessage;

            var accessKey = apiKey;

            TechnicalDashboardService.updateCustomerApiKey(
              { accessKey: accessKey, activeApiKey: activeApiKey[0] },
              sponsoredOrganizationId
            ).then(function() {
              vm.hostPageUrl = [];
              vm.customLabel = [];
              vm.loading.keys = false;

              fetchPlugins();
              toaster.pop(
                'success',
                APP_CONSTANTS.MESSAGE.technicalApiKeyUpdateSuccess
              );
            });
          },
          function() {
            apiKey.status = apiKey.status ? false : true;
            ngDialog.close();
            if (activeApiKey.length) {
              activeApiKey[0].status = true;
            }
            vm.loading.keys = false;
            vm.hostPageUrl = [];
            vm.customLabel = [];
          }
        );
    }

    function changeTab(tabName) {
      if (tabName !== vm.activeTab) {
        vm.activeTab = tabName;
        getHeading();
        populatePluginTable();
      }
    }

    function populatePluginTable() {
      if (vm.activeTab === APP_CONSTANTS.CDP) {
        vm.filteredPlugins = vm.cdpPlugins;
      } else if (vm.activeTab === APP_CONSTANTS.BOTH) {
        vm.filteredPlugins = vm.unifyPlugins;
      } else {
        vm.filteredPlugins = vm.ckdPlugins;
      }
    }

    function getHeading() {
      vm.organizationName = $filter('sprintf')(
        (
          (vm.activeTab === APP_CONSTANTS.CDP && APP_CONSTANTS.CDP_TECHNICAL_PAGE_HEADING)
          || (vm.activeTab === APP_CONSTANTS.BOTH && APP_CONSTANTS.CK_TECHNICAL_PAGE_HEADING)
          || APP_CONSTANTS.CKD_TECHNICAL_PAGE_HEADING
        ),
        currentUser.sponsoredOrganizationLegalName
      );
    }

    function getActiveTab() {
      if (vm.subscriptionType === APP_CONSTANTS.BOTH) {
        vm.activeTab = APP_CONSTANTS.BOTH;
      } else if (vm.subscriptionType === APP_CONSTANTS.CDP) {
        vm.activeTab = APP_CONSTANTS.CDP;
      } else {
        vm.activeTab = APP_CONSTANTS.CKD;
      }
    }
  }
})();
