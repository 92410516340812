(function() {
  'use strict';
  angular
    .module('app.shared')
    .controller('MappedTagsForMajorController', MappedTagsForMajorController);

  MappedTagsForMajorController.$inject = [
    'APP_CONSTANTS',
    '$scope',
    'isPublished',
    'selectedMajor',
    'productTagList',
    '$uibModalInstance',
    'ProductService'
  ];

  function MappedTagsForMajorController(
    APP_CONSTANTS,
    $scope,
    isPublished,
    selectedMajor,
    productTagList,
    $uibModalInstance,
    ProductService
  ) {
    var vm = this;

    vm.loading = {};
    vm.productTagList = productTagList;
    vm.mappedTagList = [];
    vm.isPublished = isPublished;
    vm.selectedMajor = selectedMajor;
    vm.dismissModal = dismissModal;
    vm.saveMappedTags = saveMappedTags;
    vm.productId = ProductService.getProductId();
    vm.selectedMajorName = _.get(
      selectedMajor,
      'variations.en.name',
      APP_CONSTANTS.instructionalProgram
    );

    vm.updatedTagIdArray = [];

    checkUnpublishedMajorsAlreadyExist();

    function checkUnpublishedMajorsAlreadyExist() {
      var alreadyUnpublishedMajor = $scope.$parent.vm.getUnpublishedMajorIfExist(
        vm.selectedMajor.id
      );

      if (alreadyUnpublishedMajor) vm.selectedMajor = alreadyUnpublishedMajor;

      if (!vm.selectedMajor.tags) {
        vm.selectedMajor.tags = [];
      }

      fetchTagsByIds(vm.selectedMajor.tags);
    }

    function fetchTagsByIds(tagIdArray) {
      tagIdArray = tagIdArray || [];

      tagIdArray.forEach(function(tagId) {
        vm.productTagList.forEach(function(tag) {
          if (tag.id === tagId) {
            vm.mappedTagList.push({
              id: tag.id,
              name: tag.name
            });
          }
        });
      });
    }

    function getTagIdArray(tagArray) {
      tagArray.forEach(function(tag) {
        vm.updatedTagIdArray.push(tag.id);
      });

      return vm.updatedTagIdArray;
    }

    function saveMappedTags() {
      $scope.$parent.vm.updateUnpublishedMajors(vm.selectedMajor.id);
      $scope.$parent.vm.updateMappedTagsInMajor(
        vm.selectedMajor.id,
        getTagIdArray(vm.mappedTagList)
      );
      dismissModal();
    }

    function dismissModal() {
      $uibModalInstance.dismiss();
    }
  }
})();
