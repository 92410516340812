(function() {
  'use strict';
  angular
    .module('app.organization')
    .controller('UserOrganizationController', UserOrganizationController);

  UserOrganizationController.$inject = ['SessionService', '$state'];

  function UserOrganizationController(SessionService, $state) {
    $state.go('app.ownerdashboard');
    var vm = this;

    vm.organization = {};
    var currentUser = SessionService.getUser();

    loadOrganizationDetails();

    function loadOrganizationDetails() {
      vm.organization.legalName = currentUser.customerLegalName;
      vm.organization.profileImage = SessionService.getOrganizationImage();
    }
  }
})();
