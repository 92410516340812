(function() {
  'use strict';
  angular
    .module('app.careerDecisionProfileClient')
    .factory('CDPClientService', CDPClientService);

  CDPClientService.$inject = [
    'APP_CONSTANTS',
    'ApiCaller',
    '$q',
    '$filter',
    'SessionService'
  ];

  function CDPClientService(
    APP_CONSTANTS,
    ApiCaller,
    $q,
    $filter,
    SessionService
  ) {
    var self = this;

    self.exportToCSV = exportToCSV;
    self.exportAllToCSV = exportAllToCSV;
    self.fetchCDPDiscoverers = fetchCDPDiscoverers;
    self.sendBulkMessage = sendBulkMessage;
    self.deleteDiscoverers = deleteDiscoverers;

    function fetchCDPDiscoverers(params) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.getAllDiscoverers,
        APP_CONSTANTS.rootUrl,
        $.param(params)
      );

      ApiCaller.apiGet(url)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function exportAllToCSV(data) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.exportAllCDPToCSV,
        APP_CONSTANTS.rootUrl
      );

      ApiCaller.apiPost(url, data)
        .then(function(result) {
          deferred.resolve(result.data);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function exportToCSV(data) {
      var url = $filter('sprintf')(
        APP_CONSTANTS.exportSelectedCDPToCSV,
        APP_CONSTANTS.rootUrl
      );

      open('POST', url, data, '_self');
    }

    function open(verb, url, data, target) {
      var refreshToken = SessionService.getRefreshToken();

      data.refreshToken = refreshToken;
      var form = document.createElement('form');

      form.action = url;
      form.method = verb;
      form.target = target || '_self';
      if (data) {
        for (var key in data) {
          var input = document.createElement('textarea');

          input.name = key;
          input.value =
            typeof data[key] === 'object'
              ? JSON.stringify(data[key])
              : data[key];
          form.appendChild(input);
        }
      }
      form.style.display = 'none';
      document.body.appendChild(form);
      form.submit();
    }

    function sendBulkMessage(params) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.cdpBulkEmail,
        APP_CONSTANTS.rootUrl
      );

      ApiCaller.apiPost(url, params).then(
        function(result) {
          deferred.resolve(result);
        },
        function(err) {
          deferred.reject(err);
        }
      );

      return deferred.promise;
    }

    function deleteDiscoverers(discovererIds) {
      var url = $filter('sprintf')(
        APP_CONSTANTS.discoverersDelete,
        APP_CONSTANTS.rootUrl
      );
      const data = {
        discovererIds,
        assessmentType: APP_CONSTANTS.CDP
      };

      return ApiCaller.apiDelete(url, data);
    }

    return self;
  }
})();
