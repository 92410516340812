(function() {
  'use strict';
  angular
    .module('app.user')
    .controller('ForgotPasswordController', ForgotPasswordController);
  ForgotPasswordController.$inject = [
    '$state',
    '$scope',
    'ApiCaller',
    'ForgotPasswordService',
    'APP_CONSTANTS',
    'toaster'
  ];

  function ForgotPasswordController(
    $state,
    $scope,
    ApiCaller,
    ForgotPasswordService,
    APP_CONSTANTS,
    toaster
  ) {
    var vm = this;

    vm.email = '';
    vm.loading = {};
    vm.submit = function(isValid) {
      if (isValid) {
        vm.loading.forgotPasswordRequest = true;
        ForgotPasswordService.resetRequest(vm.email).then(
          function() {
            // ApiCaller.apiGet(ROOTPATH.rootUrl + ROOTPATH.forgotPasswordEndpoint + 'email=' + vm.email, function (result) {
            vm.email = '';
            vm.form.$setPristine();
            vm.form.$setUntouched();
            vm.loading.forgotPasswordRequest = false;
            toaster.pop(
              'success',
              APP_CONSTANTS.successHeader,
              APP_CONSTANTS.MESSAGE.forgotPassword
            );
          },
          function(error) {
            vm.loading.forgotPasswordRequest = false;
            toaster.pop('error', error.data.message || error.statusText);
          }
        );
      }
    };
  }
})();
