(function() {
  'use strict';
  angular
    .module('app.user')
    .controller('ReviewOrderController', ReviewOrderController);
  ReviewOrderController.$inject = [
    '$state',
    'UserDetails',
    'TrialService',
    'APP_CONSTANTS'
  ];
  function ReviewOrderController(
    $state,
    UserDetails,
    TrialService,
    APP_CONSTANTS
  ) {
    var vm = this;
    var msg = APP_CONSTANTS.MESSAGE.welcomeMessage;

    activate();

    function activate() {
      vm.orderDetails = {};
      vm.register = function() {
        UserDetails.orderDetails = vm.orderDetails;
        TrialService.register(UserDetails, msg);
      };
    }
  }
})();
