(function() {
  'use strict';
  angular
    .module('app.shared')
    .controller('MajorsNewVariationController', MajorsNewVariationController);

  MajorsNewVariationController.$inject = [
    'APP_CONSTANTS',
    'toaster',
    '$scope',
    'selectedMajor',
    '$uibModalInstance',
    'DetailsService',
    'ProductService'
  ];

  function MajorsNewVariationController(
    APP_CONSTANTS,
    toaster,
    $scope,
    selectedMajor,
    $uibModalInstance,
    DetailsService,
    ProductService
  ) {
    var vm = this;

    vm.loading = {};
    vm.availableLanguages = [];
    vm.productAssessments = [];
    vm.productLanguages = [];
    vm.newLanguageVariation = {};
    vm.dismissModal = dismissModal;
    vm.selectedMajor = selectedMajor;
    vm.majorsLanguageVariationArray = [];
    vm.addNewVariation = addNewVariation;
    vm.productId = ProductService.getProductId();
    vm.fetchProductAssessments = fetchProductAssessments;

    vm.selectedMajorName = _.get(
      selectedMajor,
      'variations.en.name',
      APP_CONSTANTS.instructionalProgram
    );

    fetchProductAssessments();

    function dismissModal() {
      $uibModalInstance.dismiss();
    }

    function fetchProductAssessments() {
      vm.loading.productInfo = true;

      DetailsService.fetchProductDetails(vm.productId)
        .then(function(result) {
          var matchedLanguage = [];

          vm.loading.productInfo = false;

          vm.productAssessments = _.get(
            result,
            'data.products[0].surveyVariations',
            []
          );

          vm.productAssessments.forEach(function(assessment) {
            vm.productLanguages.push(assessment.locale.language);
          });

          vm.productLanguages.forEach(function(language) {
            matchedLanguage = Object.keys(vm.selectedMajor.variations).filter(
              function(key) {
                return key === language.id;
              }
            );

            if (!matchedLanguage.length) vm.availableLanguages.push(language);
          });
        })
        .catch(function(error) {
          vm.loading.productInfo = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function addNewVariation() {
      $scope.$parent.vm.updateUnpublishedMajors(vm.selectedMajor.id);
      $scope.$parent.vm.updateNewVariationInMajor(
        vm.selectedMajor.id,
        vm.newLanguageVariation
      );

      dismissModal();
    }
  }
})();
