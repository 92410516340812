(function() {
  'use strict';

  angular
    .module('app.customerAccount')
    .controller('VerifyAccountController', VerifyAccountController);

  VerifyAccountController.$inject = [
    '$uibModal',
    '$state',
    'APP_CONSTANTS',
    '$scope',
    '$uibModalInstance'
  ];
  function VerifyAccountController(
    $uibModal,
    $state,
    APP_CONSTANTS,
    $scope,
    $uibModalInstance
  ) {
    var vm = this;

    vm.verifyAccountMessage = APP_CONSTANTS.verifyAccountMessage;

    vm.ok = function() {
      $uibModalInstance.close('closed');
    };
  }
})();
