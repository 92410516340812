(function() {
  'use strict';

  angular.module('app.user').factory('ActivationService', ActivationService);

  ActivationService.$inject = [
    'APP_CONSTANTS',
    'ApiCaller',
    '$q',
    '$filter',
    'SessionService',
    'AccessRoleService'
  ];

  function ActivationService(
    APP_CONSTANTS,
    ApiCaller,
    $q,
    $filter,
    SessionService,
    AccessRoleService
  ) {
    var self = this;

    self.activate = activate;
    self.getNextState = getNextState;
    self.storeUser = storeUser;

    function storeUser(user) {
      SessionService.setUser(user);
    }

    function activate(activationCode) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.userActivation,
        APP_CONSTANTS.rootUrl
      );

      ApiCaller.apiPost(url, { activationCode: activationCode }).then(
        function(result) {
          deferred.resolve(result);
        },
        function(err) {
          deferred.reject(err);
        }
      );

      return deferred.promise;
    }

    function getNextState() {
      var userRole = AccessRoleService.getHighPriorityRole();
      var menu = SessionService.getMenus();

      return _.get(menu[1], 'sref', userRole.state);
    }

    return self;
  }
})();
