/* eslint no-undef: "off"*/
/**=========================================================
 * Module: Common
 * Services to share user session details
 =========================================================*/
(function() {
  'use strict';

  angular.module('careerKeyCentral').service('SessionService', SessionService);

  SessionService.$inject = ['localStorageService', 'APP_CONSTANTS'];

  function SessionService(localStorageService, APP_CONSTANTS) {
    var self = this;

    self.setUser = setUser;
    self.getUser = getUser;
    self.getRoles = getRoles;
    self.getMenus = getMenus;
    self.removeUser = removeUser;
    self.appendMenu = appendMenu;
    self.getAccessToken = getAccessToken;
    self.getAdminToken = getAdminToken;
    self.setAdminToken = setAdminToken;
    self.getRefreshToken = getRefreshToken;
    self.removeAdminToken = removeAdminToken;
    self.isAuthenticatedUser = isAuthenticatedUser;
    self.setOrganizationImage = setOrganizationImage;
    self.getOrganizationImage = getOrganizationImage;
    self.setAccountManagerToken = setAccountManagerToken;
    self.getAccountManagerToken = getAccountManagerToken;
    self.removeAccountManagerToken = removeAccountManagerToken;

    function setUser(user) {
      localStorageService.set('user', user);
    }

    function setAdminToken(adminToken) {
      localStorageService.set('adminToken', adminToken);
    }

    function setAccountManagerToken(accountManagerToken) {
      localStorageService.set('accountManagerToken', accountManagerToken);
    }

    function setOrganizationImage(orgImg) {
      localStorageService.set('orgImg', orgImg);
    }

    function getOrganizationImage() {
      return localStorageService.get('orgImg');
    }

    function getUser() {
      return localStorageService.get('user');
    }

    function getAdminToken() {
      return localStorageService.get('adminToken');
    }

    function getAccountManagerToken() {
      return localStorageService.get('accountManagerToken');
    }

    function isAuthenticatedUser() {
      return localStorageService.get('user') ? true : false;
    }

    function getAccessToken() {
      return localStorageService.get('user').accessToken;
    }

    function getRefreshToken() {
      return localStorageService.get('user').refreshToken;
    }

    function getRoles() {
      return localStorageService.get('user').role;
    }

    function getMenus() {
      return localStorageService.get('user').menus;
    }

    function removeUser() {
      if (localStorageService.get('user')) {
        localStorageService.remove('user');
        localStorageService.remove('orgImg');
      }
    }

    function removeAdminToken() {
      if (localStorageService.get('adminToken')) {
        localStorageService.remove('adminToken');
      }
    }

    function appendMenu(user) {
      var oldAssessmentType = user.subscriptionType;

      if (
        !oldAssessmentType ||
        (oldAssessmentType === 'ckd' || oldAssessmentType === 'cdp')
      ) {
        user.subscriptionType = 'both';

        if (oldAssessmentType === 'cdp') {
          if (
            !user.menus.find(function(menu) {
              menu.sref === APP_CONSTANTS.CKD_MENU.OWNER_DASHBOARD.sref;
            })
          ) {
            user.menus.splice(1, 0, APP_CONSTANTS.CKD_MENU.OWNER_DASHBOARD);
          }
          if (
            !user.menus.find(function(menu) {
              menu.sref === APP_CONSTANTS.CKD_MENU.DISCOVERY_PROGRESS.sref;
            })
          ) {
            user.menus.splice(2, 0, APP_CONSTANTS.CKD_MENU.DISCOVERY_PROGRESS);
          }
        }
        if (!oldAssessmentType || oldAssessmentType === 'ckd') {
          if (
            !user.menus.find(function(menu) {
              menu.sref ===
                APP_CONSTANTS.CDP_MENU.CAREER_DECISION_PROFILE_CLIENT.sref;
            })
          ) {
            user.menus.splice(
              3,
              0,
              APP_CONSTANTS.CDP_MENU.CAREER_DECISION_PROFILE_CLIENT
            );
          }
        }

        self.setUser(user);
      }
    }

    function removeAccountManagerToken() {
      if (localStorageService.get('accountManagerToken')) {
        localStorageService.remove('accountManagerToken');
      }
    }
  }
})();
