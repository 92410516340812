(function() {
  'use strict';

  angular
    .module('app.customerAccount')
    .factory('BillingHistoryService', BillingHistoryService);

  BillingHistoryService.$inject = [
    'ApiCaller',
    'APP_CONSTANTS',
    '$q',
    '$filter'
  ];

  function BillingHistoryService(ApiCaller, APP_CONSTANTS, $q, $filter) {
    var self = this;

    self.fetchInvoiceLink = fetchInvoiceLink;
    self.fetchBillingHistory = fetchBillingHistory;
    self.fetchCreditNoteLink = fetchCreditNoteLink;

    function fetchBillingHistory() {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.billingHistory,
        APP_CONSTANTS.rootUrl
      );

      ApiCaller.apiGet(url)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function fetchInvoiceLink(invoiceId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.invoiceLink,
        APP_CONSTANTS.rootUrl,
        invoiceId
      );

      ApiCaller.apiGet(url)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function fetchCreditNoteLink(creditNoteId) {
      var deferred = $q.defer();

      creditNoteId;
      var url = $filter('sprintf')(
        APP_CONSTANTS.creditNoteLink,
        APP_CONSTANTS.rootUrl,
        creditNoteId
      );

      ApiCaller.apiGet(url)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    return self;
  }
})();
