(function() {
  'use strict';
  angular
    .module('app.shared')
    .controller('EditMajorController', EditMajorController);

  EditMajorController.$inject = [
    'APP_CONSTANTS',
    '$scope',
    'selectedMajor',
    '$uibModalInstance'
  ];

  function EditMajorController(
    APP_CONSTANTS,
    $scope,
    selectedMajor,
    $uibModalInstance
  ) {
    var vm = this;

    vm.loading = {};
    vm.newLanguageVariation = {};
    vm.dismissModal = dismissModal;
    vm.selectedMajor = selectedMajor;
    vm.majorsLanguageVariationArray = [];
    vm.getLanguageVariations = getLanguageVariations;
    vm.saveUpdatedMajor = saveUpdatedMajor;

    vm.selectedMajorName = _.get(
      selectedMajor,
      'variations.en.name',
      APP_CONSTANTS.instructionalProgram
    );

    getLanguageVariations();

    function dismissModal() {
      $uibModalInstance.dismiss();
    }

    function saveUpdatedMajor() {
      var editableFields = {};

      Object.keys(vm.majorsLanguageVariation).forEach(function(key) {
        editableFields[key] = {};
        editableFields[key].description =
          vm.majorsLanguageVariation[key].description;
        editableFields[key].name = vm.majorsLanguageVariation[key].name;
        editableFields[key].shortDescription =
          vm.majorsLanguageVariation[key].shortDescription;
        editableFields[key].descriptionUrl =
          vm.majorsLanguageVariation[key].descriptionUrl;
        editableFields[key].duration = vm.majorsLanguageVariation[key].duration;
        editableFields[key].educationalRequirementName =
          vm.majorsLanguageVariation[key].educationalRequirementName;
      });

      $scope.$parent.vm.updateUnpublishedMajors(vm.selectedMajor.id);
      $scope.$parent.vm.updateExistingVariationInMajor(
        vm.selectedMajor.id,
        editableFields
      );

      dismissModal();
    }

    function getLanguageVariations() {
      var alreadyUnpublishedMajor = $scope.$parent.vm.getUnpublishedMajorIfExist(
        vm.selectedMajor.id
      );

      if (alreadyUnpublishedMajor)
        vm.selectedMajor = JSON.parse(JSON.stringify(alreadyUnpublishedMajor));

      vm.majorsLanguageVariation = JSON.parse(
        JSON.stringify(vm.selectedMajor.variations)
      );

      Object.keys(vm.majorsLanguageVariation).forEach(function(key) {
        vm.majorsLanguageVariationArray.push({
          name: APP_CONSTANTS.languageVariations[key],
          variation: vm.majorsLanguageVariation[key]
        });
      });
    }
  }
})();
