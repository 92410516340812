(function () {
  'use strict';
  angular
    .module('app.counselor')
    .controller('CounselorDashboardController', CounselorDashboardController);

  function CounselorDashboardController(
  ) {
    var vm = this;
    vm.loading = {};

  }
})();
