(function() {
  'use strict';
  angular
    .module('app.shared')
    .controller(
      'UserProfileImageUploadController',
      UserProfileImageUploadController
    );
  UserProfileImageUploadController.$inject = [
    '$scope',
    '$uibModalInstance',
    'UserProfileService',
    'SessionService',
    'toaster',
    'APP_CONSTANTS',
    '$rootScope',
    '$stateParams'
  ];

  function UserProfileImageUploadController(
    $scope,
    $uibModalInstance,
    UserProfileService,
    SessionService,
    toaster,
    APP_CONSTANTS,
    $rootScope,
    $stateParams
  ) {
    var vm = this;

    vm.loading = false;
    vm.wrongImageFormat = false;

    vm.submit = submit;
    vm.cancel = cancel;

    var userLoginDetail = SessionService.getUser();

    vm.user = {
      userId: $stateParams.id
    };
    if (vm.user.userId === '') {
      vm.user.userId = userLoginDetail.id;
      vm.loggedInUserProfile = true;
    } else {
      if (userLoginDetail.id === vm.user.userId) {
        vm.loggedInUserProfile = true;
      }
    }

    $scope.validateImage = function(files) {
      var ext = files[0].name.match(/\.(.+)$/)[1];

      if (
        angular.lowercase(ext) === 'jpg' ||
        angular.lowercase(ext) === 'jpeg' ||
        angular.lowercase(ext) === 'png' ||
        angular.lowercase(ext) === 'gif'
      ) {
        vm.wrongImageFormat = false;
        handleFileSelect(files);
      } else {
        vm.wrongImageFormat = true;
      }
    };

    function handleFileSelect(files) {
      var file = files[0];
      var reader = new FileReader();

      reader.onload = function(evt) {
        $scope.$apply(function() {
          vm.image = evt.target.result;
          vm.croppedImage = evt.target.result;
        });
      };
      if (file) reader.readAsDataURL(file);
    }

    function saveImage() {
      var imageData = {};
      var user = SessionService.getUser();

      imageData.base64 = vm.croppedImage;
      vm.loading = true;

      UserProfileService.uploadImage(imageData, user.id).then(
        function(response) {
          vm.loading = false;
          user.profileImage =
            response.data.data + '?=' + $rootScope.moment().unix();

          if (!user.superAdminId && !user.accountManagerId) {
            SessionService.setUser(user);
            UserProfileService.setProfileImage(user.profileImage);
          }

          toaster.pop('success', APP_CONSTANTS.MESSAGE.successfullyUpdated);
          $uibModalInstance.close();
        },
        function(error) {
          vm.loading = false;
          if (error.status === 413) {
            toaster.pop('error', APP_CONSTANTS.MESSAGE.imageSizeLarge);
          } else {
            toaster.pop('error', error.data.message || error.statusText);
          }
          vm.croppedImage =
            UserProfileService.getProfileImage() ||
            'app/common/img/user/user.png';
        }
      );
    }

    angular
      .element(document.querySelector('#fileInput'))
      .on('change', handleFileSelect);

    function submit(cropUrl) {
      if (cropUrl.substring(0, 10) === 'data:image' && cropUrl.length > 40) {
        vm.wrongImageFormat = false;
        vm.croppedImage = cropUrl;
        saveImage();
      } else {
        $scope.$apply(function() {
          vm.wrongImageFormat = true;
        });

        return;
      }
    }

    function cancel() {
      $uibModalInstance.dismiss('cancel');
    }
  }
})();
