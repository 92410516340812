(function() {
  'use strict';
  angular.module('app.technical').config(state);

  state.$inject = ['$stateProvider', 'RouteHelpersProvider'];

  function state($stateProvider, helper) {
    $stateProvider.state('app.technicaldashboard', {
      url: '/technical/dashboard',
      title: 'Technical Dashboard',
      templateUrl: helper.basepath(
        'v2/sponsoredOrganization/technicalDashboard/technicalDashboard.html'
      ),
      controller: 'TechnicalDashboardController',
      controllerAs: 'vm',
      resolve: helper.resolveFor(
        'datatables',
        'ui.select',
        'colorpicker.module',
        'ngDialog',
        'ngImgCrop',
        'icons',
        'filestyle'
      ),
      secured: true
    });
  }
})();
