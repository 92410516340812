(function() {
  'use strict';
  angular
    .module('app.shared')
    .controller('MappedMajorsSchoolsController', MappedMajorsSchoolsController);

  MappedMajorsSchoolsController.$inject = [
    'APP_CONSTANTS',
    'toaster',
    '$scope',
    'selectedSchool',
    '$uibModalInstance',
    'WorkgroupService',
    'MajorsService',
    'ProductService',
    'MappedMajorsForSchoolsService'
  ];

  function MappedMajorsSchoolsController(
    APP_CONSTANTS,
    toaster,
    $scope,
    selectedSchool,
    $uibModalInstance,
    WorkgroupService,
    MajorsService,
    ProductService,
    MappedMajorsForSchoolsService
  ) {
    var vm = this;

    vm.loading = {};
    vm.isOpen = true;
    vm.workgroupCareers = [];
    vm.workgroupIPsArray = [];
    vm.mappedMajorsList = [];
    vm.dismissModal = dismissModal;
    vm.selectedSchool = selectedSchool;
    vm.ptTypes = APP_CONSTANTS.PT_TYPES;
    vm.productId = ProductService.getProductId();
    vm.fetchWorkGroupIps = fetchWorkGroupIps;
    vm.updateMappedMajors = updateMappedMajors;
    vm.fetchProductWorkgroups = fetchProductWorkgroups;
    vm.personalityTypes = APP_CONSTANTS.PERSONALITY_TYPES;
    vm.fetchMajorDetailsForSchool = fetchMajorDetailsForSchool;

    resetCategorizedWorkgroups();
    fetchProductWorkgroups();

    function resetCategorizedWorkgroups() {
      vm.categorizedWorkgroups = {
        realistic: [],
        investigative: [],
        artistic: [],
        social: [],
        enterprising: [],
        conventional: []
      };
    }

    function fetchProductWorkgroups() {
      vm.loading.productInfo = true;

      WorkgroupService.fetchProductWorkgroups(vm.productId)
        .then(function(result) {
          resetCategorizedWorkgroups();

          vm.productWorkgroups = _.get(result, 'data.workgroups', []);
          vm.productWorkgroups.forEach(function(workgroup) {
            if (workgroup.pt === vm.ptTypes.REALISTIC) {
              vm.categorizedWorkgroups.realistic.push(workgroup);
            } else if (workgroup.pt === vm.ptTypes.INVESTIGATIVE) {
              vm.categorizedWorkgroups.investigative.push(workgroup);
            } else if (workgroup.pt === vm.ptTypes.ARTISTIC) {
              vm.categorizedWorkgroups.artistic.push(workgroup);
            } else if (workgroup.pt === vm.ptTypes.SOCIAL) {
              vm.categorizedWorkgroups.social.push(workgroup);
            } else if (workgroup.pt === vm.ptTypes.ENTERPRISING) {
              vm.categorizedWorkgroups.enterprising.push(workgroup);
            } else if (workgroup.pt === vm.ptTypes.CONVENTIONAL) {
              vm.categorizedWorkgroups.conventional.push(workgroup);
            }
          });

          fetchMajorDetailsForSchool();
          fetchWorkGroupIps(vm.categorizedWorkgroups.realistic[0]);
        })
        .catch(function(error) {
          vm.loading.productInfo = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function fetchMajorDetailsForSchool() {
      vm.loading.productInfo = true;
      var cipArray = [];

      vm.selectedSchool.majors.forEach(function(major) {
        cipArray.push(major.cip);
      });

      var invalidIndex = cipArray.indexOf('99');

      if (invalidIndex > -1) cipArray.splice(invalidIndex, 1);

      MappedMajorsForSchoolsService.fetchMappedMajors(
        vm.selectedSchool.id,
        cipArray
      )
        .then(function(result) {
          vm.loading.productInfo = false;

          vm.mappedMajorsList = _.get(result, 'data.data', []);
        })
        .catch(function(error) {
          vm.loading.productInfo = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function updateMappedMajors() {
      vm.loading.productInfo = true;

      var updatedCipArray = [];

      vm.mappedMajorsList.forEach(function(major) {
        updatedCipArray.push({ cip: major.cip });
      });

      MappedMajorsForSchoolsService.updateMappedMajors(
        vm.selectedSchool.id,
        updatedCipArray
      )
        .then(function() {
          vm.loading.productInfo = false;

          toaster.pop('success', APP_CONSTANTS.MESSAGE.majorsMappedForSchools);
          $scope.$parent.vm.fetchAllSchools();

          dismissModal();
        })
        .catch(function(error) {
          vm.loading.productInfo = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function fetchWorkGroupIps(workgroup) {
      vm.loading.productInfo = true;
      vm.workgroupIPsArray = [];
      vm.activeWorkgroupId = workgroup.id;

      MajorsService.fetchMajorsByWorkgroupId(
        vm.productId,
        vm.activeWorkgroupId,
        true
      )
        .then(function(result) {
          vm.loading.productInfo = false;

          vm.workgroupMajors = _.get(
            result,
            'data.workgroup[0].instructionalPrograms',
            []
          );

          vm.workgroupMajors.forEach(function(major) {
            vm.workgroupIPsArray.push({
              id: major.id,
              cip: major.cip,
              name: major.variations.en.name,
              personalityType: workgroup.personalityType,
              workgroupId: vm.activeWorkgroupId
            });
          });
        })
        .catch(function(error) {
          vm.loading.productInfo = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function dismissModal() {
      $uibModalInstance.dismiss();
    }
  }
})();
