(function() {
  'use strict';
  angular.module('careerKeyCentral').filter('percentage', percentage);

  percentage.$inject = ['$filter'];

  function percentage($filter) {
    return function(input, decimals) {
      if (isNaN(input)) {
        return 0;
      }

      // Incase if we want decimal points to be displayed
      //     return $filter('number')(input * 100, decimals) + '%';

      return Math.round(input) + '%';
    };
  }
})();
