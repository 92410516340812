(function() {
  'use strict';

  angular.module('app.shared').factory('SchoolsService', SchoolsService);

  SchoolsService.$inject = ['APP_CONSTANTS', 'ApiCaller', '$filter', '$q'];

  function SchoolsService(APP_CONSTANTS, ApiCaller, $filter, $q) {
    var self = this;

    self.fetchAllSchools = fetchAllSchools;

    function fetchAllSchools(params) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.schoolList,
        APP_CONSTANTS.rootUrl,
        $.param(params)
      );

      ApiCaller.apiGet(url)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    return self;
  }
})();
