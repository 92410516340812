(function () {
  'use strict';

  angular.module('app.shared')
    .factory('GroupModal', [
      '$uibModal',
      GroupModal
    ]);

  function GroupModal(
    $uibModal
  ) {
    const self = this;

    self.assignDiscoverersToGroup = assignDiscoverersToGroup;

    function assignDiscoverersToGroup(
      groups,
      discoverers,
    ) {
      return $uibModal.open({
        templateUrl: 'app/group/views/assignDiscoverersToGroup.html',
        controller: 'AssignDiscoverersToGroupController',
        controllerAs: 'vm',
        resolve: {
          data: () => ({
            groups,
            discoverers,
          })
        }
      }).result;
    }

    return self;
  }
})();
