/* =========================================================
 * Module: Discoverer Detatils
 =========================================================*/

(function() {
  'use strict';

  angular
    .module('app.discovererProgress')
    .controller('DiscovererDetailsController', DiscovererDetailsController);

  DiscovererDetailsController.$inject = [
    '$state',
    '$stateParams',
    'APP_CONSTANTS',
    'toaster',
    'discovererDetailsService',
    'FileSaver',
    'SessionService',
    'OrganizationInfoService',
    '$scope',
    '$uibModal'
  ];

  function DiscovererDetailsController(
    $state,
    $stateParams,
    APP_CONSTANTS,
    toaster,
    discovererDetailsService,
    FileSaver,
    SessionService,
    OrganizationInfoService,
    $scope,
    $uibModal
  ) {
    var vm = this;

    vm.loading = {};
    vm.category = {};
    vm.messages = [];
    vm.clusters = [];
    vm.careers = [];
    vm.majors = [];
    vm.dtOptions = {
      paging: false,
      searching: false,
      info: false,
      order: [],
      destroy: true,
      columnDefs: [
        {
          orderable: true
        },
        {
          orderDataType: 'fa-check-circle',
          targets: [3]
        }
      ]
    };
    $.fn.dataTable.ext.order['fa-check-circle'] = function (settings, col) {
      return this.api().column(col, { order: 'index' }).nodes().map(function (td, i) {
        return $('i', td).hasClass('fa-check-circle');
      });
    }
    getLocale();
    vm.pdfOperation = APP_CONSTANTS.PDF_OPERATION;
    vm.ckd = APP_CONSTANTS.CKD;
    vm.cdp = APP_CONSTANTS.CDP;
    vm.subscriptionType = APP_CONSTANTS.CKD;

    function getLocale() {
      discovererDetailsService
        .getLocaleFile()
        .then(function(response) {
          vm.category.major = response.data.Major;
          discovererDetails($stateParams.discovererId);
        })
        .catch(function(error) {
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    vm.saveNotes = saveNotes;
    vm.exportToPdf = exportToPdf;
    vm.shareResult = shareResult;
    vm.computeEnvironmentColor = computeEnvironmentColor;
    vm.getClusterDetails = getClusterDetails;
    vm.toggleResults = toggleResults;
    vm.sendMessage = sendMessage;

    function discovererDetails(discovererId) {
      vm.loading.discovererDetails = true;
      discovererDetailsService
        .generateDiscovererDetailsApiGet(discovererId)
        .then(function(result) {
          let discoverer = _.get(result, 'data.data');
          if (!discoverer) return toaster.pop('error', APP_CONSTANTS.MESSAGE.dataNotFound);

          vm.discovererEmail = discoverer.email;
          vm.messages = _.filter(discoverer.messages, { type: APP_CONSTANTS.CKD })
          .map(function (message) {
            let option = _.find(APP_CONSTANTS.letsConnectOptions, function (option) {
              return message.template === option.template ||
                message.template === option.messageType;
            });
            return {
              date: message.date,
              subject: message.subject,
              template: option.label || message.template,
              deliveryStatus: message.deliveryStatus !== false
            };
          });
          vm.lastContact = vm.messages.length
            && _.get(_.last(_.sortBy(vm.messages, 'date')), 'date')
            || discoverer.lastContact;

          showDiscovererDetails(discoverer);
          if (discoverer.careers && discoverer.careers.length ||
            discoverer.majors && discoverer.majors.length) {
            fetchOrganizationClusters(SessionService.getUser().sponsoredOrganizationId);
          }
          handleSendMessageOptions(discoverer.lastEventCategory);
        })
        .catch(function(error) {
          toaster.pop('error', error.data.message || error.statusText);
        })
        .finally(function () {
          vm.loading.discovererDetails = false;
        });
    }

    function showDiscovererDetails(results) {
      vm.first = results.first;
      vm.last = results.last;
      vm.fullName = results.first
        ? results.first + ' ' + results.last
        : results.email || results.customerSourcedId || results.id;
      vm.lastActivity = results.lastEventAt;
      vm.notes = results.notes;
      vm.discovererId = results.id;
      vm.selectedClusters = results.clusters;
      vm.assignedGroups = results.groupsName && results.groupsName.join(', ') || 'Unassigned';

      vm.isAssessmentCompleted = !!(results.personalityProfile && results.personalityProfile.details);
      if (!vm.isAssessmentCompleted) return;

      vm.assessmentResults = results.personalityProfileHistory || [];
      results.personalityProfile && vm.assessmentResults.unshift({
        assessmentDate: results.assessmentDate,
        personalityProfile: results.personalityProfile,
        serviceLevel: results.serviceLevel || 'Assessment Not Completed',
        scorePattern: results.scorePattern || '',
      });

      toggleResults(0);

      vm.majors = filterRepeatedClusters(results.majors);
      vm.careers = filterRepeatedClusters(results.careers);
    }

    function toggleResults(index) {
      if (!vm.assessmentResults[index]) return;

      let assessmentResult = vm.assessmentResults[index];
      let personalityProfile = assessmentResult.personalityProfile || {};
      vm.activeIndex = index;
      vm.assessmentDate = assessmentResult.assessmentDate;
      vm.personalityScores = personalityProfile.details || [];
      vm.recommendations = personalityProfile.recommendations || {};
      vm.serviceLevel = assessmentResult.serviceLevel || 'Not Calculated';
      vm.scorePattern = assessmentResult.scorePattern;

      switch (vm.serviceLevel.toLowerCase()) {
        case APP_CONSTANTS.SELF_HELP.toLowerCase():
          vm.serviceLevelClass = 'sl-self-help fa fa-circle';
          break;
        case APP_CONSTANTS.BRIEF_STAFF_ASSISTED.toLowerCase():
          vm.serviceLevelClass = 'sl-brief-staff-assist fa fa-circle';
          break;
        case APP_CONSTANTS.INDIVIDUAL_CASE_MANAGED_SERVICES.toLowerCase():
          vm.serviceLevelClass = 'sl-individual-case-managed fa fa-circle';
          break;
        default:
          vm.serviceLevelClass = 'text-muted fa fa-circle';
          break;
      }
    }

    function handleSendMessageOptions(lastEventCategory) {
      let availableMessageTypes = [lastEventCategory];
      if (vm.isAssessmentCompleted) {
        availableMessageTypes.push(APP_CONSTANTS.DIFFERENTIATED_CONSISTENT);
        availableMessageTypes.push(APP_CONSTANTS.DIFFERENTIATED_INCONSISTENT);
        availableMessageTypes.push(APP_CONSTANTS.UNDIFFERENTIATED);
      }
      vm.letsConnectOptions = _.filter(APP_CONSTANTS.letsConnectOptions, function (option) {
        if (option.messageType === APP_CONSTANTS.CUSTOM) return true;
        if (option.subscriptionType !== APP_CONSTANTS.CKD) return false;

        return availableMessageTypes.some((value) => value === option.messageType);
      });
    }

    function sendMessage(option) {
      option.emails = [vm.discovererEmail];
      $state.go('app.mailbox.compose', option);
    }

    function saveNotes(note) {
      vm.loading.notes = true;
      // note = note.replace(/((?:\w+ ){8})/g, "$1\n")
      discovererDetailsService
        .addNote($stateParams.discovererId, note)
        .then(function(response) {
          vm.notes = response.data.data.notes;
          toaster.pop('success', APP_CONSTANTS.MESSAGE.noteSave);
          vm.loading.notes = false;
        })
        .catch(function(error) {
          vm.loading.notes = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    /**
     * TODO add delete features
     */
    /*
     function deleteNote(note) {
     vm.loading.discovererDetails = true;
     discovererDetailsService.deleteNote(discovererId, note.id)
     .then(function (response) {
     // var index = _.indexOf(vm.NoteList, _.find(vm.NoteList, { id: note.id }));
     // vm.NoteList.splice(index, 1, note);
     })
     .catch(function (error) {
     vm.loading.discovererDetails = false;
     toaster.pop('error', error.data.message || error.statusText);
     })
     }
     */

    function exportToPdf(operation) {
      vm.loading.discovererDetails = true;
      var file;

      discovererDetailsService
        .generateDiscovererProfilePdf($stateParams.discovererId)
        .then(function(response) {
          file = new Blob([response.data], { type: 'application/pdf' });

          if (operation === APP_CONSTANTS.PDF_OPERATION.DOWNLOAD) {
            FileSaver.saveAs(file, 'profile.pdf');
          } else {
            var fileURL = window.URL.createObjectURL(file);
            printPage(file, fileURL);
          }
        })
        .catch(function(error) {
          toaster.pop('error', error.data.message || error.statusText);
        })
        .finally(function () {
          vm.loading.discovererDetails = false;
        });
    }

    function closePrint() {
      document.body.removeChild(this.__container__);
    }

    function setPrint() {
      this.contentWindow.__container__ = this;
      this.contentWindow.onbeforeunload = closePrint;
      this.contentWindow.onafterprint = closePrint;
      this.contentWindow.focus(); // Required for IE
      this.contentWindow.print();
    }

    function printPage(file, URL) {
      var isFirefox = typeof InstallTrigger !== 'undefined';

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        return window.navigator.msSaveOrOpenBlob(file, 'profile.pdf');
      } else if (isFirefox) {
        window.open(URL, '_blank');
      } else {
        var oHiddFrame = document.createElement('iframe');

        oHiddFrame.onload = setPrint;
        oHiddFrame.style.visibility = 'hidden';
        oHiddFrame.style.position = 'fixed';
        oHiddFrame.style.right = '0';
        oHiddFrame.style.bottom = '0';
        oHiddFrame.src = URL;
        document.body.appendChild(oHiddFrame);
      }
    }

    function computeEnvironmentColor(environmentType) {
      if (environmentType === APP_CONSTANTS.ENVIRONMENT_TYPE.THRIVING) {
        return 'ck-green';
      } else if (environmentType === APP_CONSTANTS.ENVIRONMENT_TYPE.PROMISING) {
        return 'ck-orange';
      } else {
        return 'ck-gray';
      }
    }

    function fetchOrganizationClusters(sponsoredOrganizationId) {
      vm.loading.clusters = true;

      OrganizationInfoService.fetchOrganizationClusters(sponsoredOrganizationId)
        .then(function(response) {
          vm.clusters = response.data.data;
          if (vm.clusters.length) {
            vm.clusters = mapClusterColors(vm.clusters);
            vm.showClusters = true;
            getTopClusters();
          }
          vm.loading.clusters = false;
        })
        .catch(function(error) {
          toaster.pop('error', error.data.message || error.statusText);
          vm.loading.clusters = false;
        });
    }

    function getEnvironmentTypeForPrograms(programs) {
      var programsWithEnvironmentType =
        programs &&
        programs.length &&
        programs.map(function(program) {
          program.environmentType = '';
          var personalityScore =
            vm.personalityScores &&
            vm.personalityScores.find(function(personalityScore) {
              if (
                personalityScore.personalityType === program.personalityType
              ) {
                return personalityScore;
              }
            });

          if (personalityScore) {
            program.environmentType = personalityScore.environmentType;
          }

          return program;
        });

      return programsWithEnvironmentType;
    }

    function shareResult(discovererId) {
      $scope.modalInstance = $uibModal.open({
        animation: true,
        templateUrl: 'emailTemplate',
        controller: function() {
          var vm = this;
          vm.loading = {};
          vm.shareEmail = '';
          vm.discovererId = discovererId;
          vm.isSubmitted = false;
          vm.regex = {
            email: APP_CONSTANTS.REGEX.EMAIL
          };

          vm.close = function() {
            $scope.modalInstance.close();
          };
          vm.sendEmail = function(form) {
            vm.isSubmitted = true;
            if (!form.$valid) {
              return;
            }
            vm.loading.shareResult = true;

            discovererDetailsService
              .shareResult(vm.discovererId, vm.shareEmail)
              .then(function(result) {
                vm.isSubmitted = false;
                vm.loading.shareResult = false;

                $scope.modalInstance.close();
              })
              .catch(function(error) {
                vm.isSubmitted = false;
                vm.loading.shareResult = false;
                toaster.pop('error', error.data.message || error.statusText);
              });
          };
        },
        controllerAs: 'vm',
        size: 'md',
        scope: $scope
      });
    }

    function filterRepeatedClusters(programs) {
      programs &&
        programs.length &&
        programs.map(function(program) {
          if (program.clusters && program.clusters.length) {
            program.clusters = program.clusters.filter(function(
              clusterId,
              index,
              array
            ) {
              return array.indexOf(clusterId) === index;
            });
          }
        });

      return getEnvironmentTypeForPrograms(programs);
    }

    function getClusterDetails(clusterIdArray) {
      var clusterDetails = [];

      clusterIdArray &&
        clusterIdArray.forEach(function(clusterId) {
          clusterDetails = clusterDetails.concat(
            vm.clusters.filter(function(cluster) {
              if (cluster.clusterId === clusterId) {
                return cluster;
              }
            })
          );
        });

      return clusterDetails;
    }

    function getTopClusters() {
      var topClusters = '';
      var clusterDetails = getClusterDetails(vm.selectedClusters);

      clusterDetails.length &&
        clusterDetails.forEach(function(cluster, index) {
          if (index === clusterDetails.length - 1) {
            topClusters = topClusters + cluster.clusterName;
          } else {
            topClusters = topClusters + cluster.clusterName + ', ';
          }
        });

      vm.topClusters = topClusters;
    }

    function mapClusterColors(clusters) {
      var clusterSetIds = [];

      clusters.forEach(function(cluster) {
        var index = clusterSetIds.indexOf(cluster.clusterSetId);

        if (index < 0) {
          clusterSetIds.push(cluster.clusterSetId);

          cluster.color =
            APP_CONSTANTS.CLUSTER_COLORS[
              clusterSetIds.indexOf(cluster.clusterSetId)
            ] || '';
        } else {
          cluster.color = APP_CONSTANTS.CLUSTER_COLORS[index] || '';
        }
      });

      return clusters;
    }
  }
})();
