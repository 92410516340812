(function() {
  'use strict';
  angular
    .module('app.shared')
    .controller('CareersController', CareersController);

  CareersController.$inject = [
    'APP_CONSTANTS',
    'toaster',
    '$scope',
    '$rootScope',
    '$uibModal',
    'WorkgroupService',
    'CareersService',
    'ProductService',
    'PublishCareerService',
    'DetailsService',
    'BatchesService'
  ];

  function CareersController(
    APP_CONSTANTS,
    toaster,
    $scope,
    $rootScope,
    $uibModal,
    WorkgroupService,
    CareersService,
    ProductService,
    PublishCareerService,
    DetailsService,
    BatchesService
  ) {
    var vm = this;

    var modalInstance = '';

    vm.updateNewVariationInCareer = updateNewVariationInCareer;
    vm.updateExistingVariationInCareer = updateExistingVariationInCareer;
    vm.loading = {};
    vm.careersCount = {};
    vm.existingSearchKey = '';
    vm.selectedFilters = {};
    vm.saveDraft = saveDraft;
    vm.getAllBatches = getAllBatches;
    vm.revertCareer = revertCareer;
    vm.updatedUnpublishedCareerList = [];
    vm.openAddNewCareerModal = openAddNewCareerModal;
    vm.getProductPublishOptions = getProductPublishOptions;
    vm.fetchProductWorkgroups = fetchProductWorkgroups;
    vm.fetchWorkGroupCareers = fetchWorkGroupCareers;
    vm.extractOccupationId = extractOccupationId;
    vm.addToUnpublished = addToUnpublished;
    vm.publishCareer = publishCareer;
    vm.setCareerPublishDate = setCareerPublishDate;
    vm.openNewVariationModal = openNewVariationModal;
    vm.searchWorkgroupCareers = searchWorkgroupCareers;
    vm.openPublishCareerModal = openPublishCareerModal;
    vm.mapInstructionalPrograms = mapInstructionalPrograms;
    vm.mapClusters = mapClusters;
    vm.openViewCareerDetailsModal = openViewCareerDetailsModal;
    vm.updateMappedMajorsInCareer = updateMappedMajorsInCareer;
    vm.updateMappedClustersInCareer = updateMappedClustersInCareer;
    vm.getUnpublishedCareerIfExist = getUnpublishedCareerIfExist;
    vm.updateUnpublishedCareers = updateUnpublishedCareers;
    vm.getCareerPublishStatus = getCareerPublishStatus;
    vm.openEditCareersModal = openEditCareersModal;
    vm.selectedFilters.publishOption = { id: 1, name: 'Published' };
    vm.publishedId = APP_CONSTANTS.productPublishOptions[0].id;
    vm.unpublishedId = APP_CONSTANTS.productPublishOptions[1].id;
    vm.isPublished = true;
    vm.formatDate = formatDate;
    vm.getTagsByIds = getTagsByIds;
    vm.getRegionsByIds = getRegionsByIds;
    vm.getProductDetails = getProductDetails;
    vm.openMappedTagsModal = openMappedTagsModal;
    vm.openMappedRegionsModal = openMappedRegionsModal;
    vm.updateMappedTagsInCareer = updateMappedTagsInCareer;
    vm.updateMappedRegionsInCareer = updateMappedRegionsInCareer;

    vm.productId = ProductService.getProductId();
    vm.productAssessments = [];
    vm.productWorkgroups = [];
    vm.workgroupCareers = [];
    vm.isOpen = true;
    vm.ptTypes = APP_CONSTANTS.PT_TYPES;
    vm.personalityTypes = APP_CONSTANTS.PERSONALITY_TYPES;

    init();

    function init() {
      resetCategorizedWorkgroups();
      fetchProductWorkgroups();
      resetCareersCount();
    }

    function getProductPublishOptions() {
      vm.productPublishOptions = APP_CONSTANTS.productPublishOptions;
    }

    function getProductDetails() {
      vm.loading.productInfo = true;

      DetailsService.fetchProductDetails(vm.productId)
        .then(function(result) {
          vm.loading.productInfo = false;

          vm.productTagList = _.get(result, 'data.products[0].tags', []);
          vm.productRegionList = _.get(result, 'data.products[0].regions', []);
        })
        .catch(function(error) {
          vm.loading.productInfo = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function getCareerPublishStatus(career) {
      if (!career.isPublishStatusFetched) {
        vm.loading.productInfo = true;
        CareersService.fetchCareerPublishStatus(vm.activeWorkgroupId, career.id)
          .then(function(result) {
            vm.loading.productInfo = false;
            career.isPublishStatusFetched = true;

            career.publishStatus = _.get(result, 'data', {});
          })
          .catch(function(error) {
            vm.loading.productInfo = false;
            toaster.pop('error', error.data.message || error.statusText);
          });
      }
    }

    function resetCareersCount() {
      vm.careersCount = {
        realistic: 0,
        investigative: 0,
        artistic: 0,
        social: 0,
        enterprising: 0,
        conventional: 0
      };
    }

    function getTagsByIds(tagIdArray) {
      var tags = [];

      tagIdArray = tagIdArray || [];

      tagIdArray.forEach(function(tagId) {
        vm.productTagList.forEach(function(tag) {
          if (tag.id === tagId) {
            tags.push(tag.name);
          }
        });
      });

      return tags.join().replace(/,/g, ', ');
    }

    function getRegionsByIds(regionIdArray) {
      var regions = [];

      regionIdArray = regionIdArray || [];

      regionIdArray.forEach(function(regionId) {
        vm.productRegionList.forEach(function(region) {
          if (region.id === regionId) {
            regions.push(region.name);
          }
        });
      });

      return regions.join().replace(/,/g, ', ');
    }

    function getAllBatches() {
      vm.loading.productInfo = false;

      BatchesService.fetchBatchesByProductAndType(
        vm.productId,
        APP_CONSTANTS.batchTypeOptions[1].name
      )
        .then(function(result) {
          vm.loading.productInfo = false;

          vm.batches = _.get(result, 'data.batches', []);
        })
        .catch(function(error) {
          vm.loading.productInfo = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function formatDate(date) {
      return $rootScope.moment(date).format('MMMM DD, YYYY');
    }

    function revertCareer(careerId) {
      CareersService.revertCareer(vm.activeWorkgroupId, careerId, vm.productId)
        .then(function() {
          vm.loading.productInfo = false;
          removeCareersFromUpdateList(careerId);
          toaster.pop('success', APP_CONSTANTS.MESSAGE.careerReverted);

          fetchWorkGroupCareers(vm.activeWorkgroupId);
        })
        .catch(function(error) {
          vm.loading.productInfo = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function resetCategorizedWorkgroups() {
      vm.categorizedWorkgroups = {
        realistic: [],
        investigative: [],
        artistic: [],
        social: [],
        enterprising: [],
        conventional: []
      };
    }

    function getUnpublishedCareerIfExist(careerId) {
      var careerDetail = vm.updatedUnpublishedCareerList.filter(function(
        updatedCareer
      ) {
        return updatedCareer.id === careerId;
      });

      if (careerDetail.length) {
        return careerDetail[0];
      }
    }

    function updateMappedMajorsInCareer(careerId, mappedMajors) {
      var careerDetail = vm.updatedUnpublishedCareerList.filter(function(
        updateCareer
      ) {
        return updateCareer.id === careerId;
      });

      careerDetail[0].instructionalPrograms = mappedMajors;
    }

    function updateMappedClustersInCareer(
      careerId,
      mappedClusters,
      mappedSubClusters
    ) {
      var careerDetail = vm.updatedUnpublishedCareerList.filter(function(
        updateCareer
      ) {
        return updateCareer.id === careerId;
      });

      careerDetail[0].clusterId = mappedClusters;
      careerDetail[0].subClusterId = mappedSubClusters;
    }

    function updateNewVariationInCareer(careerId, newLanguageVariation) {
      var careerDetail = vm.updatedUnpublishedCareerList.filter(function(
        updatedCareer
      ) {
        return updatedCareer.id === careerId;
      });

      careerDetail[0].variations[
        newLanguageVariation.language.id
      ] = formatVariationObject(newLanguageVariation);
    }

    function updateExistingVariationInCareer(
      careerId,
      editableFieldsObject,
      commonDetails
    ) {
      var careerDetail = vm.updatedUnpublishedCareerList.filter(function(
        updatedCareer
      ) {
        return updatedCareer.id === careerId;
      });

      Object.keys(editableFieldsObject).forEach(function(languageId) {
        Object.keys(editableFieldsObject[languageId]).forEach(function(key) {
          if (
            careerDetail[0].variations[languageId][key] !==
            editableFieldsObject[languageId][key]
          ) {
            careerDetail[0].variations[languageId][key] =
              editableFieldsObject[languageId][key];
          }
        });
      });

      careerDetail[0].soc = commonDetails.soc;
      careerDetail[0].medianPay = commonDetails.medianPay;
      careerDetail[0].jobOutlook = commonDetails.jobOutlook;
      careerDetail[0].alternateId = commonDetails.alternateId;
      careerDetail[0].educationalRequirementID =
        commonDetails.educationalRequirementID;
    }

    function formatVariationObject(newLanguageVariation) {
      return {
        name: newLanguageVariation.name,
        description: newLanguageVariation.description || '',
        shortDescription: newLanguageVariation.shortDescription || '',
        socname: newLanguageVariation.socname || '',
        educationalRequirementName:
          newLanguageVariation.educationalRequirementName || '',
        descriptionUrl: newLanguageVariation.descriptionUrl || '',
        duration: newLanguageVariation.duration || '',
        Values: []
      };
    }

    function searchWorkgroupCareers($event) {
      if ($event.keyCode !== 13) {
        return;
      }

      vm.loading.productInfo = true;
      vm.existingSearchKey = vm.searchKey;

      if (vm.searchKey === '') {
        fetchProductWorkgroups();
        resetCareersCount();
      } else {
        resetCareersCount();

        var params = {
          type: APP_CONSTANTS.careers,
          publishType: vm.selectedFilters.publishOption.name.toLowerCase(),
          searchKey: vm.existingSearchKey
        };

        CareersService.searchWorkgroupCareers(vm.productId, params)
          .then(function(result) {
            vm.loading.productInfo = false;

            vm.filteredWorkgroups = _.get(result, 'data.workgroups', []);

            vm.productWorkgroups.forEach(function(productWorkgroup) {
              var filteredWorkgroup = vm.filteredWorkgroups.filter(function(
                filteredWorkgroup
              ) {
                return productWorkgroup.id === filteredWorkgroup.id;
              });

              productWorkgroup.count = filteredWorkgroup.length
                ? filteredWorkgroup[0].count
                : 0;

              switch (productWorkgroup.pt) {
                case 'R':
                  vm.careersCount.realistic += productWorkgroup.count;
                  break;
                case 'I':
                  vm.careersCount.investigative += productWorkgroup.count;
                  break;
                case 'A':
                  vm.careersCount.artistic += productWorkgroup.count;
                  break;
                case 'S':
                  vm.careersCount.social += productWorkgroup.count;
                  break;
                case 'E':
                  vm.careersCount.enterprising += productWorkgroup.count;
                  break;
                case 'C':
                  vm.careersCount.conventional += productWorkgroup.count;
                  break;
                default:
                  break;
              }
            });

            fetchWorkGroupCareers(vm.activeWorkgroupId);
          })
          .catch(function(error) {
            vm.loading.productInfo = false;
            toaster.pop('error', error.data.message || error.statusText);
          });
      }
    }

    function updateUnpublishedCareers(careerId) {
      var careerDetail = vm.updatedUnpublishedCareerList.filter(function(
        updatedMajor
      ) {
        return updatedMajor.id === careerId;
      });

      if (!careerDetail.length) {
        var selectedCareer = vm.workgroupCareers.filter(function(
          selectedCareer
        ) {
          return selectedCareer.id === careerId;
        });

        vm.updatedUnpublishedCareerList.push(selectedCareer[0]);
      }
    }

    function saveDraft(careerId) {
      var index = vm.updatedUnpublishedCareerList
        .map(function(updatedCareer) {
          return updatedCareer.id;
        })
        .indexOf(careerId);

      if (index < 0) {
        toaster.pop('info', APP_CONSTANTS.MESSAGE.makeChangeToSaveDraft);

        return;
      }

      vm.updatedUnpublishedCareerList[index].productId = vm.productId;

      delete vm.updatedUnpublishedCareerList[index].isPublishStatusFetched;
      delete vm.updatedUnpublishedCareerList[index].publishStatus;

      vm.loading.productInfo = true;
      CareersService.saveDraft(
        vm.updatedUnpublishedCareerList[index],
        vm.activeWorkgroupId,
        careerId
      )
        .then(function() {
          vm.loading.productInfo = false;
          removeCareersFromUpdateList(careerId);
          toaster.pop(
            'success',
            APP_CONSTANTS.MESSAGE.careerUpdatedToUnpublished
          );
          fetchWorkGroupCareers(vm.activeWorkgroupId);
        })
        .catch(function(error) {
          vm.loading.productInfo = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function removeCareersFromUpdateList(careerId) {
      var index = vm.updatedUnpublishedCareerList
        .map(function(career) {
          return career.id;
        })
        .indexOf(careerId);

      if (index > -1) {
        vm.updatedUnpublishedCareerList.splice(index, 1);
        toaster.pop('success', APP_CONSTANTS.MESSAGE.successfullyRevertChanges);
      } else toaster.pop('info', APP_CONSTANTS.MESSAGE.noChangesToRevert);
    }

    function addToUnpublished(careerId) {
      CareersService.addToUnpublished(
        vm.activeWorkgroupId,
        careerId,
        vm.productId
      )
        .then(function() {
          vm.loading.productInfo = false;

          toaster.pop(
            'success',
            APP_CONSTANTS.MESSAGE.careerAddedToUnpublished
          );
        })
        .catch(function(error) {
          vm.loading.productInfo = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function fetchProductWorkgroups() {
      vm.loading.productInfo = true;
      vm.searchKey = '';
      vm.existingSearchKey = '';
      resetCareersCount();
      vm.isPublished = vm.selectedFilters.publishOption.id === vm.publishedId;

      WorkgroupService.fetchProductWorkgroups(vm.productId)
        .then(function(result) {
          vm.loading.productInfo = false;

          resetCategorizedWorkgroups();

          vm.productWorkgroups = _.get(result, 'data.workgroups', []);
          vm.productWorkgroups.forEach(function(workgroup) {
            if (workgroup.pt === vm.ptTypes.REALISTIC) {
              vm.categorizedWorkgroups.realistic.push(workgroup);
            } else if (workgroup.pt === vm.ptTypes.INVESTIGATIVE) {
              vm.categorizedWorkgroups.investigative.push(workgroup);
            } else if (workgroup.pt === vm.ptTypes.ARTISTIC) {
              vm.categorizedWorkgroups.artistic.push(workgroup);
            } else if (workgroup.pt === vm.ptTypes.SOCIAL) {
              vm.categorizedWorkgroups.social.push(workgroup);
            } else if (workgroup.pt === vm.ptTypes.ENTERPRISING) {
              vm.categorizedWorkgroups.enterprising.push(workgroup);
            } else if (workgroup.pt === vm.ptTypes.CONVENTIONAL) {
              vm.categorizedWorkgroups.conventional.push(workgroup);
            }
          });

          fetchWorkGroupCareers(
            vm.activeWorkgroupId || vm.categorizedWorkgroups.realistic[0].id
          );
        })
        .catch(function(error) {
          vm.loading.productInfo = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function fetchWorkGroupCareers(workgroupId) {
      vm.loading.productInfo = true;
      vm.activeWorkgroupId = workgroupId;
      var isPublishedFlag =
        vm.selectedFilters.publishOption.id ===
        APP_CONSTANTS.productPublishOptions[0].id;
      var batchId = angular.isUndefined(vm.selectedFilters.batch)
        ? ''
        : vm.selectedFilters.batch.id;
      var tagId = angular.isUndefined(vm.selectedFilters.tag)
        ? ''
        : vm.selectedFilters.tag.id;
      var regionId = angular.isUndefined(vm.selectedFilters.region)
        ? ''
        : vm.selectedFilters.region.id;

      CareersService.fetchCareersByWorkgroupId(
        vm.productId,
        workgroupId,
        isPublishedFlag,
        vm.existingSearchKey,
        batchId,
        tagId,
        regionId
      )
        .then(function(result) {
          vm.loading.productInfo = false;

          vm.workgroupCareers = _.get(result, 'data.workgroup[0].careers', []);
          vm.selectedWorkgroupId = _.get(result, 'data.workgroup[0].id', null);
        })
        .catch(function(error) {
          vm.loading.productInfo = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function publishCareer(careerId) {
      vm.loading.productInfo = true;

      CareersService.publishCareer(vm.activeWorkgroupId, careerId, vm.productId)
        .then(function() {
          vm.loading.productInfo = false;

          fetchWorkGroupCareers(vm.activeWorkgroupId);

          toaster.pop(
            'success',
            APP_CONSTANTS.MESSAGE.careerSuccessfullyPublished
          );
        })
        .catch(function(error) {
          vm.loading.productInfo = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function setCareerPublishDate(workgroupId, careerId, selectedDate) {
      vm.loading.productInfo = true;

      var publishDate = $rootScope.moment(selectedDate).format('YYYY-MM-DD');

      PublishCareerService.setCareerPublishDate(workgroupId, careerId, {
        publishDate: publishDate
      })
        .then(function() {
          vm.loading.productInfo = false;

          fetchWorkGroupCareers(workgroupId);

          toaster.pop(
            'success',
            APP_CONSTANTS.MESSAGE.careerPublishDateSuccessfullySet
          );
        })
        .catch(function(error) {
          vm.loading.productInfo = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function mapInstructionalPrograms(selectedCareer) {
      if (!modalInstance) {
        modalInstance = $uibModal.open({
          templateUrl:
            'app/ckadmin/products/careers/mappedMajors/mappedMajors.html',
          controller: 'MappedMajorsController',
          controllerAs: 'vm',
          animation: true,
          size: 'lg',
          scope: $scope,
          resolve: {
            selectedCareer: function() {
              return selectedCareer;
            },
            isPublished: function() {
              return vm.selectedFilters.publishOption.id === vm.publishedId;
            }
          }
        });

        modalInstance.result
          .then(function() {
            modalInstance = '';
          })
          .catch(function() {
            modalInstance = '';
          });
      }
    }

    function mapClusters(selectedCareer) {
      if (!modalInstance) {
        modalInstance = $uibModal.open({
          templateUrl:
            'app/ckadmin/products/clusters/mappedClusters/mappedClusters.html',
          controller: 'MappedClustersController',
          controllerAs: 'vm',
          animation: true,
          size: 'lg',
          scope: $scope,
          resolve: {
            selectedItem: function() {
              return selectedCareer;
            },
            isPublished: function() {
              return vm.selectedFilters.publishOption.id === vm.publishedId;
            },
            programType: function() {
              return APP_CONSTANTS.occupation;
            }
          }
        });

        modalInstance.result
          .then(function() {
            modalInstance = '';
          })
          .catch(function() {
            modalInstance = '';
          });
      }
    }

    function openEditCareersModal(selectedCareer) {
      if (!modalInstance) {
        modalInstance = $uibModal.open({
          templateUrl:
            'app/ckadmin/products/careers/editCareer/editCareer.html',
          controller: 'EditCareerController',
          controllerAs: 'vm',
          animation: true,
          size: 'lg',
          scope: $scope,
          resolve: {
            selectedCareer: function() {
              return selectedCareer;
            }
          }
        });

        modalInstance.result
          .then(function() {
            modalInstance = '';
          })
          .catch(function() {
            modalInstance = '';
          });
      }
    }

    function openViewCareerDetailsModal(selectedCareer) {
      if (!modalInstance) {
        modalInstance = $uibModal.open({
          templateUrl:
            'app/ckadmin/products/careers/viewCareerDetails/viewCareerDetails.html',
          controller: 'ViewCareerDetailsController',
          controllerAs: 'vm',
          animation: true,
          size: 'lg',
          scope: $scope,
          resolve: {
            selectedCareer: function() {
              return selectedCareer;
            }
          }
        });

        modalInstance.result
          .then(function() {
            modalInstance = '';
          })
          .catch(function() {
            modalInstance = '';
          });
      }
    }

    function openNewVariationModal(selectedCareer) {
      if (!modalInstance) {
        modalInstance = $uibModal.open({
          templateUrl:
            'app/ckadmin/products/careers/newVariation/newVariation.html',
          controller: 'CareersNewVariationController',
          controllerAs: 'vm',
          animation: true,
          size: 'lg',
          scope: $scope,
          resolve: {
            selectedCareer: function() {
              return selectedCareer;
            }
          }
        });

        modalInstance.result
          .then(function() {
            modalInstance = '';
          })
          .catch(function() {
            modalInstance = '';
          });
      }
    }

    function openAddNewCareerModal(selectedWorkgroupId) {
      if (!modalInstance) {
        modalInstance = $uibModal.open({
          templateUrl:
            'app/ckadmin/products/careers/addNewCareer/addNewCareer.html',
          controller: 'AddNewCareerController',
          controllerAs: 'vm',
          animation: true,
          size: 'lg',
          scope: $scope,
          resolve: {
            selectedWorkgroupId: function() {
              return selectedWorkgroupId;
            },
            selectedProductId: function() {
              return vm.productId;
            }
          }
        });

        modalInstance.result
          .then(function() {
            modalInstance = '';
          })
          .catch(function() {
            modalInstance = '';
          });
      }
    }

    function openPublishCareerModal(selectedCareer, selectedWorkgroupId) {
      if (!modalInstance) {
        modalInstance = $uibModal.open({
          templateUrl: 'app/ckadmin/products/careers/publish/publish.html',
          controller: 'PublishCareerController',
          controllerAs: 'vm',
          animation: true,
          size: 'sm',
          scope: $scope,
          resolve: {
            selectedCareer: function() {
              return selectedCareer;
            },
            selectedWorkgroupId: function() {
              return selectedWorkgroupId;
            }
          }
        });

        modalInstance.result
          .then(function() {
            modalInstance = '';
          })
          .catch(function() {
            modalInstance = '';
          });
      }
    }

    function openMappedTagsModal(selectedCareer) {
      if (!modalInstance) {
        modalInstance = $uibModal.open({
          templateUrl:
            'app/ckadmin/products/careers/mappedTags/mappedTags.html',
          controller: 'MappedTagsForCareerController',
          controllerAs: 'vm',
          animation: true,
          size: 'md',
          scope: $scope,
          resolve: {
            selectedCareer: function() {
              return selectedCareer;
            },
            isPublished: function() {
              return vm.selectedFilters.publishOption.id === vm.publishedId;
            },
            productTagList: function() {
              return vm.productTagList;
            }
          }
        });

        modalInstance.result
          .then(function() {
            modalInstance = '';
          })
          .catch(function() {
            modalInstance = '';
          });
      }
    }

    function openMappedRegionsModal(selectedCareer) {
      if (!modalInstance) {
        modalInstance = $uibModal.open({
          templateUrl:
            'app/ckadmin/products/careers/mappedRegions/mappedRegions.html',
          controller: 'MappedRegionsForCareerController',
          controllerAs: 'vm',
          animation: true,
          size: 'md',
          scope: $scope,
          resolve: {
            selectedCareer: function() {
              return selectedCareer;
            },
            isPublished: function() {
              return vm.selectedFilters.publishOption.id === vm.publishedId;
            },
            productRegionList: function() {
              return vm.productRegionList;
            }
          }
        });

        modalInstance.result
          .then(function() {
            modalInstance = '';
          })
          .catch(function() {
            modalInstance = '';
          });
      }
    }

    function extractOccupationId(id) {
      var occupationId = '';

      occupationId = id.split('base-')[1];

      return occupationId;
    }

    function updateMappedTagsInCareer(careerId, mappedTags) {
      var careerDetail = vm.updatedUnpublishedCareerList.filter(function(
        updatedCareer
      ) {
        return updatedCareer.id === careerId;
      });

      careerDetail[0].tags = mappedTags;
    }

    function updateMappedRegionsInCareer(careerId, mappedRegions) {
      var careerDetail = vm.updatedUnpublishedCareerList.filter(function(
        updatedCareer
      ) {
        return updatedCareer.id === careerId;
      });

      careerDetail[0].regions = mappedRegions;
    }
  }
})();
