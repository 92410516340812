(function() {
  'use strict';
  angular
    .module('app.shared')
    .controller('AddNewMajorController', AddNewMajorController);

  AddNewMajorController.$inject = [
    'APP_CONSTANTS',
    'toaster',
    '$scope',
    '$uibModalInstance',
    'AddNewMajorService',
    'ProductService',
    'selectedWorkgroupId',
    'selectedProductId'
  ];

  function AddNewMajorController(
    APP_CONSTANTS,
    toaster,
    $scope,
    $uibModalInstance,
    AddNewMajorService,
    ProductService,
    selectedWorkgroupId,
    selectedProductId
  ) {
    var vm = this;

    vm.loading = {};
    vm.newMajor = {};
    vm.submit = submit;
    vm.addNewMajorFormError = {};
    vm.validateMajorId = validateMajorId;
    vm.productId = ProductService.getProductId();
    vm.dismissModal = dismissModal;

    getUniqueMajorId(vm.productId, selectedWorkgroupId);

    function getUniqueMajorId(productId, workgroupId) {
      vm.loading.productInfo = true;

      AddNewMajorService.getUniqueMajorId(productId, workgroupId)
        .then(function(result) {
          vm.loading.productInfo = false;

          vm.newMajor.id = _.get(result, 'data.uniqueMajorId', '');
        })
        .catch(function(error) {
          vm.loading.productInfo = false;

          dismissModal();
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function validateMajorId() {
      var splittedMajorIdArray = vm.newMajor.id.split('-');
      var splittedProductIdArray = selectedProductId.split('.');
      var splittedWorkgroupIdArray = selectedWorkgroupId.split('.');

      var generatedMajorId =
        'ckip' +
        '.' +
        splittedProductIdArray[1] +
        '.' +
        splittedProductIdArray[2] +
        '-' +
        splittedWorkgroupIdArray[3] +
        '.' +
        splittedWorkgroupIdArray[4];
      var userDefinedMajorId =
        splittedMajorIdArray[0] + '-' + splittedMajorIdArray[1];

      if (generatedMajorId !== userDefinedMajorId) {
        vm.addNewMajorFormError.id = true;
      } else {
        vm.addNewMajorFormError.id = false;
      }
    }

    function submit() {
      vm.loading.productInfo = true;

      AddNewMajorService.addNewMajor(
        vm.productId,
        selectedWorkgroupId,
        vm.newMajor
      )
        .then(function() {
          vm.loading.productInfo = false;

          dismissModal();
          $scope.$parent.vm.fetchWorkGroupMajors(selectedWorkgroupId);
          toaster.pop('success', APP_CONSTANTS.MESSAGE.majorSuccessfullyAdded);
        })
        .catch(function(error) {
          vm.loading.productInfo = false;

          dismissModal();
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function dismissModal() {
      $uibModalInstance.dismiss();
    }
  }
})();
