(function() {
  'use strict';
  angular.module('app.ckadmin').config(state);

  state.$inject = ['$stateProvider', 'RouteHelpersProvider'];

  function state($stateProvider, helper) {
    $stateProvider
      .state('app.ckadmin.products', {
        url: '/products',
        title: 'Products',
        controller: 'ProductListController',
        controllerAs: 'vm',
        resolve: helper.resolveFor('ui.select'),
        templateUrl: helper.basepath('ckadmin/products/index.html'),
        secured: true
      })
      .state('app.ckadmin.customers', {
        url: '/customers',
        title: 'Customers',
        controller: 'CustomerListController',
        controllerAs: 'cc',
        templateUrl: helper.basepath('ckadmin/customers/views/customerList.html'),
        resolve: helper.resolveFor(
          'modernizr',
          'icons',
          'datatables',
          'ngDialog',
          'ui.select',
          'filestyle',
          'angularFileUpload'
        ),
        secured: true
      })
      .state('app.ckadmin.customerDetails', {
        url: '/customers/:customerId',
        title: 'Customer Details',
        controller: 'CustomerDetailsController',
        controllerAs: 'vm',
        adminTabState: 'app.ckadmin.customers',
        templateUrl: helper.basepath('ckadmin/customers/views/customerDetails.html'),
        resolve: helper.resolveFor(
          'modernizr',
          'icons',
          'datatables',
          'ngDialog',
          'ui.select',
          'filestyle',
          'angularFileUpload'
        ),
        secured: true
      })
      .state('app.ckadmin.resellers', {
        url: '/resellers',
        title: 'Resellers',
        controller: 'ResellerListController',
        controllerAs: 'cc',
        templateUrl: helper.basepath('ckadmin/resellers/views/resellerList.html'),
        resolve: helper.resolveFor(
          'modernizr',
          'icons',
          'datatables',
          'ngDialog',
          'ui.select',
          'filestyle',
          'angularFileUpload'
        ),
        secured: true
      })
      .state('app.ckadmin.resellerDetails', {
        url: '/resellers/:resellerId',
        title: 'Reseller Details',
        controller: 'ResellerDetailsController',
        controllerAs: 'vm',
        adminTabState: 'app.ckadmin.resellers',
        templateUrl: helper.basepath('ckadmin/resellers/views/resellerDetails.html'),
        resolve: helper.resolveFor(
          'modernizr',
          'icons',
          'datatables',
          'ngDialog',
          'ui.select',
          'filestyle',
          'angularFileUpload'
        ),
        secured: true
      })
      .state('app.ckadmin.assessments', {
        url: '/assessments',
        title: 'Assessments',
        controller: 'AssessmentsController',
        controllerAs: 'cc',
        templateUrl: helper.basepath('ckadmin/assessments/views/index.html'),
        secured: true
      })
      .state('app.ckadmin.discoverers', {
        url: '/discoverers',
        title: 'Discoverers',
        controller: 'DiscoverersController',
        controllerAs: 'vm',
        templateUrl: helper.basepath('ckadmin/discoverers/views/index.html'),
        secured: true
      })
      .state('app.ckadmin.selectedProduct', {
        url: '/products/:productId',
        title: 'Product Details',
        templateUrl: helper.basepath('ckadmin/products/product.html'),
        controller: 'ProductController',
        controllerAs: 'vm',
        resolve: helper.resolveFor('ui.select'),
        secured: true
      })
      .state('app.ckadmin.selectedProduct.details', {
        url: '/',
        title: 'Product Details Tab',
        controller: 'DetailsController',
        controllerAs: 'vm',
        templateUrl: helper.basepath('ckadmin/products/details/details.html'),
        secured: true
      })
      .state('app.ckadmin.selectedProduct.ips', {
        url: '/',
        title: 'Product Instructional Programs Tab',
        controller: 'MajorsController',
        controllerAs: 'vm',
        templateUrl: helper.basepath('ckadmin/products/majors/majors.html'),
        secured: true
      })
      .state('app.ckadmin.selectedProduct.occupations', {
        url: '/',
        title: 'Product Occupations Tab',
        controller: 'CareersController',
        controllerAs: 'vm',
        templateUrl: helper.basepath('ckadmin/products/careers/careers.html'),
        secured: true
      })
      .state('app.ckadmin.selectedProduct.schools', {
        url: '/',
        title: 'Product Schools ',
        controller: 'SchoolsController',
        controllerAs: 'vm',
        templateUrl: helper.basepath('ckadmin/products/schools/schools.html'),
        secured: true
      })
      .state('app.ckadmin.selectedProduct.batches', {
        url: '/',
        title: 'Products',
        controller: 'BatchesController',
        controllerAs: 'vm',
        templateUrl: helper.basepath('ckadmin/products/batches/batches.html'),
        secured: true
      });
  }
})();
