(function() {
  'use strict';

  angular
    .module('app.user')
    .factory('OwnerDashboardService', OwnerDashboardService);

  OwnerDashboardService.$inject = [
    'ApiCaller',
    'APP_CONSTANTS',
    '$q',
    '$filter'
  ];

  function OwnerDashboardService(ApiCaller, APP_CONSTANTS, $q, $filter) {
    var self = this;

    self.fetchEventCategories = fetchEventCategories;
    self.fetchCustomerDetails = fetchCustomerDetails;
    self.fetchSponsoredOrganizationName = fetchSponsoredOrganizationName;

    function fetchEventCategories(groupId, sponsoredOrganizationId) {
      var deferred = $q.defer();
      var params = {
        groupId: groupId,
        sponsoredOrganizationId: sponsoredOrganizationId
      };
      var url = $filter('sprintf')(
        APP_CONSTANTS.discovererProgress,
        APP_CONSTANTS.rootUrl,
        $.param(params)
      );

      ApiCaller.apiGet(url)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function fetchCustomerDetails(customerId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.customerDetails,
        APP_CONSTANTS.rootUrl,
        customerId
      );

      ApiCaller.apiGet(url)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function fetchSponsoredOrganizationName(userId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.sponsoredOrganizationInfo,
        APP_CONSTANTS.rootUrl,
        userId
      );

      ApiCaller.apiGet(url)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    return self;
  }
})();
