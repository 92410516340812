(function () {
  'use strict';

  var appTour = {
    init: function () { }, // will be set later in the code on module.run
    isEndedByUser: false,
    isFirstTime: false,
    notifications: {},
    pages: [],
    registerNotification: function (notificationName) {
      if (!this.tour || !this.notifications[notificationName]) return;
      if (this.tour._events[notificationName]) {
        this.tour.off(notificationName, this.notifications[notificationName]);
      }
      // see https://github.com/benmarch/angular-ui-tour#tour-notifications
      this.tour.on(notificationName, this.notifications[notificationName]);
    },
    shouldStartTour: false,
    startFromLastTour: false,
    tour: null,
  }

  angular.module('app.tour', ['bm.uiTour'])
    .config(['TourConfigProvider',
      function (TourConfigProvider) {
        TourConfigProvider.enableNavigationInterceptors();
        TourConfigProvider.set('appendToBody', true);
        TourConfigProvider.set('scrollOffset', 200);
        TourConfigProvider.set('backdrop', true);
        TourConfigProvider.set('disableBackdropOptimizations', true);
        TourConfigProvider.set('templateUrl', 'app/tour/views/tourStepTemplate.html');
        TourConfigProvider.set('useUiRouter', true);
        // TourConfigProvider.set('onBackdropClick', function () {
        //   appTour.tour && appTour.tour.end();
        // });
      }
    ])
    .run([
      '$rootScope',
      '$state',
      '$timeout',
      'SessionService',
      'uiTourService',
      'UserProfileService',
      function (
        $rootScope,
        $state,
        $timeout,
        SessionService,
        uiTourService,
        UserProfileService,
      ) {
        let setupAppTour = function () {
          appTour.pages = [];

          let user = SessionService.getUser();
          if (!user) return;

          let adminOrAccountManager = SessionService.getAdminToken() || SessionService.getAccountManagerToken();
          if (adminOrAccountManager) return;

          let menus = SessionService.getMenus() || [];
          let availableTourPages = [
            'app.ownerdashboard',
            // 'app.discoverersprogress',
            // 'app.careerdecisionprofileclient',
            // 'app.createmanagegroup',
          ];
          let hasTourMenu = false;

          menus.forEach(function (menu) {
            if (availableTourPages.indexOf(menu.sref) !== -1) {
              appTour.pages.push(menu.sref);
            }
            if (menu.text == 'Tour') {
              hasTourMenu = true;
            }
          });
          if (!hasTourMenu) return;

          let firstMenu = menus.find(menu => menu.sref && !menu.heading);
          if (firstMenu.sref == 'app.technicaldashboard') return;

          if (user.isFirstTimeLogin) {
            appTour.isFirstTime = true;
            appTour.shouldStartTour = true;
            user.isFirstTimeLogin = false;
            UserProfileService.updateUser(user)
              .then(function () {
                SessionService.setUser(user);
              })
          }
        }

        appTour.notifications.started = function () {
          if (!appTour.tour) return;

          let steps = appTour.tour._getSteps();
          if (!steps.length) return;

          let firstStep = steps[0];
          if (!firstStep.onPrev && $rootScope.hasPrevPageTour()) {
            firstStep.onPrev = $rootScope.navigateToPrevPageTour;
          }

          let lastStep = steps[steps.length - 1];
          if (!lastStep.onNext && $rootScope.hasNextPageTour()) {
            lastStep.onNext = $rootScope.navigateToNextPageTour;
          }
        }
        appTour.notifications.stepChanged = function (nextStep) {
          $rootScope.$broadcast('appTourStepChange', {
            stepId: nextStep.stepId,
          });
        }
        appTour.notifications.ended = function () {
          $rootScope.$broadcast('appTourEnd', {
            isEndedByUser: appTour.isEndedByUser,
          });
          appTour.isEndedByUser = false;
        }
        appTour.init = function () {
          setupAppTour();
          if (appTour.tour) return;
          appTour.tour = uiTourService.getTour();
          appTour.registerNotification('started');
          appTour.registerNotification('stepChanged');
          appTour.registerNotification('ended');
        }

        $rootScope.hasNextPageTour = function () {
          let currentTourIndex = appTour.pages.indexOf($state.current.name);
          return (
            currentTourIndex !== -1
            && appTour.pages[currentTourIndex + 1]
          );
        }

        $rootScope.hasPrevPageTour = function () {
          let currentTourIndex = appTour.pages.indexOf($state.current.name);
          return currentTourIndex > 0;
        }

        $rootScope.navigateToNextPageTour = function () {
          let currentTourIndex = appTour.pages.indexOf($state.current.name);
          let path = appTour.pages[currentTourIndex + 1];
          appTour.shouldStartTour = true;
          appTour.startFromLastTour = false;
          appTour.tour.end();
          $state.go(path);
        }

        $rootScope.navigateToPrevPageTour = function () {
          let currentTourIndex = appTour.pages.indexOf($state.current.name);
          let path = appTour.pages[currentTourIndex - 1];
          appTour.shouldStartTour = true;
          appTour.startFromLastTour = true;
          appTour.tour.end();
          $state.go(path);
        }

        $rootScope.startTour = function (force = false) {
          appTour.init();
          if (force) appTour.shouldStartTour = true;
          if (!appTour.shouldStartTour || !appTour.tour) return;

          $rootScope.$broadcast('appTourStart', {
            isFirstTime: appTour.isFirstTime,
          })

          $timeout(function () {
            let stepId = 0;

            if (appTour.startFromLastTour) {
              appTour.tour._getSteps().forEach(function (step) {
                if (step.enabled) {
                  stepId = step.stepId;
                }
              });
            }

            appTour.tour.startAt(stepId);
            appTour.isFirstTime = false;
            appTour.shouldStartTour = false;
            appTour.startFromLastTour = false;
          }, 100);
        }

        $rootScope.endTour = function () {
          appTour.tour && appTour.tour.end();
          appTour.isEndedByUser = true;
        }
      }
    ])

})();
