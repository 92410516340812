(function() {
  'use strict';

  angular
    .module('app.customerAccount')
    .controller('AccountUpdateController', AccountUpdateController);

  AccountUpdateController.$inject = [
    '$uibModal',
    '$state',
    'APP_CONSTANTS',
    'toaster',
    'RegistrationService',
    'AccountUpdateService',
    'SessionService',
    'ngDialog',
    '$filter',
    '$scope',
    '$rootScope'
  ];
  function AccountUpdateController(
    $uibModal,
    $state,
    APP_CONSTANTS,
    toaster,
    RegistrationService,
    AccountUpdateService,
    SessionService,
    ngDialog,
    $filter,
    $scope,
    $rootScope
  ) {
    var vm = this;

    vm.loading = {};

    vm.open = open;
    vm.submit = submit;
    vm.displayConfirmationPopup = displayConfirmationPopup;

    vm.accountInfo = {};
    vm.accountInfo.isNotPrimaryContact = true;

    var modalInstance = '';
    var currentUser = SessionService.getUser();

    activate();

    function activate() {
      vm.regex = {
        email: APP_CONSTANTS.REGEX.EMAIL
      };
      vm.fetchCountries = fetchCountries;
      vm.fetchAccountInformation = fetchAccountInformation;
      vm.availableLanguages = APP_CONSTANTS.availableLanguages;

      $scope.$parent.vm.setActiveTab();

      fetchCountries();
      fetchAccountImage();
      fetchAccountInformation();
    }

    function fetchAccountManagerList() {
      vm.loading.managerList = true;
      AccountUpdateService.fetchAccountManagerList(currentUser.customerId)
        .then(function(response) {
          vm.accountManagers = _.get(response, 'data.data', []);
          vm.loading.managerList = false;
        })
        .catch(function(error) {
          vm.loading.managerList = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function fetchAccountInformation() {
      vm.loading.accountInfo = true;
      AccountUpdateService.getAccountInfo(currentUser.customerId)
        .then(function(result) {
          vm.loading.accountInfo = false;
          vm.accountInfo = _.get(result, 'data.data', {});
          vm.accountInfo.country = vm.accountInfo.locale.country;
          vm.accountInfo.isNotPrimaryContact =
            vm.accountInfo.primaryContact.id !== currentUser.id ? true : false;
          vm.accountInfo.language = _.isEmpty(vm.accountInfo.locale.language)
            ? vm.availableLanguages[0]
            : vm.accountInfo.locale.language;

          if (!vm.accountInfo.isNotPrimaryContact) fetchAccountManagerList();
        })
        .catch(function(error) {
          vm.loading.accountInfo = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function fetchAccountImage() {
      vm.loading.accountImage = true;
      AccountUpdateService.getAccountImage(currentUser.customerId)
        .then(function(response) {
          vm.loading.accountImage = false;
          vm.profileImage =
            response.data.data.profileImage ||
            APP_CONSTANTS.defaultOrganizationImage;
          vm.profileImage += '?=' + $rootScope.moment().unix();
          AccountUpdateService.setImage(vm.profileImage);
        })
        .catch(function(error) {
          vm.loading.accountImage = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function displayConfirmationPopup(accountManager) {
      var data = {};

      data.message = $filter('sprintf')(
        APP_CONSTANTS.CONFIRMATION.CHANGE_PRIMARY_CONTACT,
        accountManager.name
      );

      ngDialog
        .openConfirm({
          template: 'app/shared/views/partials/ngDialogConfirmation.html',
          data: data
        })
        .then(function() {
          ngDialog.close();
          changePrimaryContact(accountManager);
        });
    }

    function changePrimaryContact(manager) {
      vm.loading.accountInfo = true;
      var payload = {
        email: manager.email,
        primaryId: manager.id
      };

      AccountUpdateService.changePrimaryContact(payload, currentUser.customerId)
        .then(function() {
          vm.loading.accountInfo = true;
          toaster.pop(
            'success',
            APP_CONSTANTS.MESSAGE.primaryContactChangedSuccessfully
          );
          vm.fetchAccountInformation();
        })
        .catch(function(error) {
          vm.loading.accountInfo = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function open(size) {
      if (!modalInstance) {
        modalInstance = $uibModal.open({
          templateUrl:
            'app/v2/customerAccount/views/partials/imageUploadModal.html',
          controller: 'ImageUploadController',
          controllerAs: 'vm',
          size: size
        });

        modalInstance.result
          .then(function() {
            vm.profileImage = AccountUpdateService.getImage();
            $scope.$parent.vm.fetchAccountImage();
            modalInstance = '';
          })
          .catch(function() {
            modalInstance = '';
          });
      }
    }

    function fetchCountries(name) {
      RegistrationService.getCountries(name)
        .then(function(result) {
          vm.countries = [];
          var countries = _.get(result, 'data.data', {});

          countries.forEach(function(country) {
            vm.countries.push(country.name);
          });
        })
        .catch(function(error) {
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function submit(form) {
      vm.submitted = form.$submitted;
      vm.formData = form;

      if (form.$invalid) return;
      var newAccountInfo = {
        title: form.userTitle.$modelValue,
        lastName: form.lastName.$modelValue,
        firstName: form.firstName.$modelValue,
        legalName: form.organizationName.$modelValue,
        phone: form.phone.$modelValue.replace(/[^0-9]+/g, '')
      };

      vm.loading.accountInfo = true;
      AccountUpdateService.updateAccountInfo(
        newAccountInfo,
        currentUser.customerId
      )
        .then(function() {
          vm.loading.accountInfo = false;
          fetchAccountInformation();
          $scope.$parent.vm.fetchAccountInformation();
          toaster.pop(
            'success',
            APP_CONSTANTS.MESSAGE.accountInfoUpdateSuccess
          );
        })
        .catch(function() {
          vm.loading.accountInfo = false;
          toaster.pop('error', APP_CONSTANTS.MESSAGE.accountInfoUpdateFailed);
        });
    }
  }
})();
