(function() {
  'use strict';

  angular
    .module('app.customerAccount')
    .controller(
      'UpdateSponsoredOrganizationController',
      UpdateSponsoredOrganizationController
    );

  UpdateSponsoredOrganizationController.$inject = [
    '$uibModalInstance',
    'OrganizationService',
    '$scope',
    '$filter',
    'SessionService',
    'toaster',
    'APP_CONSTANTS',
    'sponsoredOrg',
    'OrganizationInfoService',
    '$state'
  ];
  function UpdateSponsoredOrganizationController(
    $uibModalInstance,
    OrganizationService,
    $scope,
    $filter,
    SessionService,
    toaster,
    APP_CONSTANTS,
    sponsoredOrg,
    OrganizationInfoService,
    $state
  ) {
    var vm = this;

    vm.loading = {};
    vm.formData = {};
    vm.cdpPlan = [];
    vm.ckdPlan = [];
    vm.formData = angular.copy(sponsoredOrg, {});

    vm.submit = submit;
    vm.cancel = cancel;
    vm.apiResponseCounter = 0;

    activate();

    function activate() {
      if (_.get(vm.formData, 'ckdSubscriptions', '')) {
        vm.formData.ckdSubscriptionIds = _.map(vm.formData.ckdSubscriptions, 'subscriptionId');
        vm.formData.ckdSubscriptionIsRequired = true;
      }
      if (_.get(vm.formData, 'cdpSubscriptions', '')) {
        vm.formData.cdpSubscriptionIds = _.map(vm.formData.cdpSubscriptions, 'subscriptionId');
        vm.formData.cdpSubscriptionIsRequired = true;
      }
      vm.formData.primaryContact = {
        id: vm.formData.primaryContact.id,
        email: vm.formData.primaryContact.email,
        name: vm.formData.firstName + ' ' + vm.formData.lastName
      };
      fetchPlans();
      fetchAccountAndOrganizationManagers();
    }

    function fetchAccountAndOrganizationManagers() {
      vm.loading.updateSponsoredOrg = true;

      OrganizationInfoService.fetchAccountAndOrganizationManagers(
        vm.formData.organizationId
      )
        .then(function(response) {
          var organizationManagers = response.data.data;

          vm.managers = [];
          organizationManagers.forEach(function(organizationManager) {
            var name =
              organizationManager.firstName +
              ' ' +
              organizationManager.lastName;
            var temp = {
              name: name,
              id: organizationManager.id,
              email: organizationManager.email
            };

            vm.apiResponseCounter++;
            if (vm.apiResponseCounter === 2) vm.managers.push(temp);
          });
          vm.loading.updateSponsoredOrg = false;
        })
        .catch(function(error) {
          vm.loading.updateSponsoredOrg = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function fetchPlans() {
      OrganizationService.fetchSubscriptions().then(
        function(result) {
          vm.apiResponseCounter = 2;
          if (vm.apiResponseCounter) vm.loading.updateSponsoredOrg = false;

          var subscriptions = _.get(result, 'data.data', {});

          subscriptions.forEach(function(plan) {
            if (plan.assessmentType === APP_CONSTANTS.CDP) {
              vm.cdpPlan.push(plan);
            } else {
              vm.ckdPlan.push(plan);
            }
          });
        },
        function(error) {
          vm.loading.addSponsoredOrg = false;
          toaster.pop('error', error.data.message || error.statusText);
        }
      );
    }

    function submit(formData) {
      vm.form = formData;
      vm.submitted = true;

      if (vm.form.$invalid
        || (_.isEmpty(vm.formData.ckdSubscriptionIds) && vm.formData.ckdSubscriptionIsRequired)
        || (_.isEmpty(vm.formData.cdpSubscriptionIds) && vm.formData.cdpSubscriptionIsRequired)
      ) return;

      vm.loading.updateSponsoredOrg = true;

      var payload = {
        organizationId: vm.formData.organizationId,
        ckdSubscriptionIds: vm.formData.ckdSubscriptionIds,
        cdpSubscriptionIds: vm.formData.cdpSubscriptionIds,
        organizationName: vm.form.organizationName.$modelValue,
        primaryContact: {
          email: vm.form.primaryContact.$modelValue.email,
          primaryId: vm.form.primaryContact.$modelValue.id
        }
      };

      var user = SessionService.getUser();

      OrganizationService.updateSponsoredOrganization(user.customerId, payload)
        .then(function() {
          vm.loading.updateSponsoredOrg = false;
          toaster.pop('success', APP_CONSTANTS.MESSAGE.successfullyUpdated);
          if (
            user.sponsoredOrganizationId === sponsoredOrg.organizationId &&
            payload.ckdSubscriptionIds &&
            payload.cdpSubscriptionIds
          ) {
            if (
              (sponsoredOrg.ckdSubscriptions && !sponsoredOrg.cdpSubscriptions) ||
              (!sponsoredOrg.ckdSubscriptions && sponsoredOrg.cdpSubscriptions)
            ) {
              SessionService.appendMenu(user);
              $state.reload();
            }
          }
          closePopup();
        })
        .catch(function(error) {
          toaster.clear('updateSponsoredOrg');
          vm.loading.updateSponsoredOrg = false;
          toaster.pop({
            type: 'error',
            body: error.data.message || error.statusText,
            toasterId: 'updateSponsoredOrg'
          });
        });
    }

    function closePopup() {
      $uibModalInstance.close('closed');
    }

    function cancel() {
      $uibModalInstance.dismiss('cancel');
    }
  }
})();
