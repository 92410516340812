(function() {
  'use strict';
  angular.module('app.owner').config(state);

  state.$inject = ['$stateProvider', 'RouteHelpersProvider'];

  function state($stateProvider, helper) {
    $stateProvider.state('app.ownerdashboard', {
      url: '/owner/dashboard/:sponsoredOrganizationId',
      title: 'Owner Dashboard',
      templateUrl: helper.basepath('owner/views/dashboard.html'),
      controller: 'OwnerDashboardController',
      controllerAs: 'vm',
      secured: true,
      resolve: helper.resolveFor('icons', 'ui.select')
    });
  }
})();
