(function () {
  'use strict';
  angular
    .module('app.user')
    .controller('AddResellerBillingController', AddResellerBillingController);

  AddResellerBillingController.$inject = [
    'BillingInfoService',
    'RegistrationService',
    'toaster',
    'APP_CONSTANTS',
    '$scope',
    '$filter'
  ];

  function AddResellerBillingController(
    BillingInfoService,
    RegistrationService,
    toaster,
    APP_CONSTANTS,
    $scope,
    $filter
  ) {
    var vm = this;

    vm.submit = submit;
    vm.modified = modified;
    vm.fetchCountries = fetchCountries;
    vm.requestBilling = requestBilling;
    vm.countrySelected = countrySelected;
    vm.copyAccountInfo = copyAccountInfo;

    vm.errors = {};
    vm.states = [];
    vm.countries = [];
    vm.billingInfo = {};
    vm.accountInfo = false;

    fetchCountries();

    function fetchCountries(name) {
      RegistrationService.getCountries(name)
        .then(function (result) {
          vm.countries = result.data.data;
        })
        .catch(function (error) {
          toaster.clear('billing');

          toaster.pop({
            type: 'error',
            body: error.data.message || error.statusText,
            toasterId: 'billing'
          });
          document.getElementById('billing-details').scrollIntoView(true);
        });
    }

    function submit(form) {
      if (!form.$valid) return;
      $scope.regController.loading.orderSummaryBlock = vm.loading = true;

      var resellerInfo = {
        taxability: $scope.regController.taxable
          ? APP_CONSTANTS.TAXABILITY.TAXABLE
          : APP_CONSTANTS.TAXABILITY.EXEMPT
      };

      BillingInfoService.getTaxEstimate(
        $scope.regController.plan.id,
        vm.billingInfo,
        resellerInfo
      ).then(
        function (result) {
          $scope.regController.orderSummary.discount =
            (result.data.data.invoice_estimate.discounts &&
              result.data.data.invoice_estimate.discounts[0].amount) ||
            '';
          $scope.regController.orderSummary.taxAmount =
            result.data.data.invoice_estimate.line_items[0].tax_amount;
          $scope.regController.orderSummary.totalAmount =
            result.data.data.invoice_estimate.total;

          RegistrationService.setTaxAmount(
            $scope.regController.orderSummary.taxAmount
          );
          RegistrationService.setBillingInfo(vm.billingInfo);
          vm.billingInfoForm.$setPristine();
          $scope.regController.loading.orderSummaryBlock = vm.loading = false;

          angular.copy(
            vm.billingInfo,
            $scope.regController.registrationDetails.billingInfo
          );

          toaster.clear('billing');
          openAccordion();
        },
        function (err) {
          $scope.regController.loading.orderSummaryBlock = vm.loading = false;
          vm.loading = false;
          toaster.clear('billing');

          if (err.data.error && err.data.error.code === 400)
            toaster.pop({
              type: 'error',
              body: APP_CONSTANTS.MESSAGE.INVALID_BILLING_INFORMATION,
              toasterId: 'billing'
            });
          else
            toaster.pop({
              type: 'error',
              body: err.data.message || err.statusText,
              toasterId: 'billing'
            });

          document.getElementById('billing-details').scrollIntoView(true);
        }
      );
    }

    function requestBilling(form) {
      if (form.$valid) {
        openAccordion();
      }
    }

    function copyAccountInfo() {
      if (vm.accountInfo) {
        vm.billingInfo = RegistrationService.mapAccountInfo();
        var copied = true;

        countrySelected(copied);
      }
    }

    function countrySelected(copied) {
      RegistrationService.getStates(vm.billingInfo.country.countryCode)
        .then(function (result) {
          vm.states = result.data.data.states;

          if (!copied) vm.billingInfo.state = null;
        })
        .catch(function (error) {
          toaster.clear('billing');

          toaster.pop({
            type: 'error',
            body: error.data.message || error.statusText,
            toasterId: 'billing'
          });
          document.getElementById('billing-details').scrollIntoView(true);
        });
    }

    function modified() {
      if (!vm.billingInfoForm.$pristine) {
        // TODO: Set form status to not updated
      }
    }

    function openAccordion() {
      var nextBlock = $filter('nextItem')(
        $scope.regController.registrationBlocks,
        $scope.regController.activeBlock.label
      );

      $scope.regController.activeBlock.label = nextBlock.label;
      $scope.regController.openBlock[nextBlock.id] = true;
    }
  }
})();
