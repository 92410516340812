(function() {
  'use strict';

  angular.module('app.shared').factory('AddBatchService', AddBatchService);

  AddBatchService.$inject = ['APP_CONSTANTS', 'ApiCaller', '$filter', '$q'];

  function AddBatchService(APP_CONSTANTS, ApiCaller, $filter, $q) {
    var self = this;

    self.addNewBatch = addNewBatch;

    function addNewBatch(batch, productId) {
      var deferred = $q.defer();
      var formData = new FormData();
      var url = $filter('sprintf')(
        APP_CONSTANTS.batchCollection,
        APP_CONSTANTS.rootUrl,
        productId,
        batch.type.identifier
      );

      formData.append('file', batch.file);
      formData.append(
        'batchDetails',
        JSON.stringify({
          name: batch.name,
          type: batch.type.name,
          productId: productId,
          publishDate: batch.publishDate,
          publishNow: batch.publishNow
        })
      );

      ApiCaller.apiFilePost(url, formData, {
        transformRequest: angular.identity,
        headers: { 'Content-Type': undefined, enctype: 'multipart/form-data' }
      })
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    return self;
  }
})();
