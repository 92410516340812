(function() {
  'use strict';
  angular
    .module('app.technical')
    .controller('TechnicalController', TechnicalController);

  TechnicalController.$inject = [
    '$scope',
    'technicalService',
    'APP_CONSTANTS',
    'SessionService',
    'ngDialog',
    'toaster',
    '$timeout',
    '$uibModal',
    '$rootScope',
  ];

  function TechnicalController(
    $scope,
    technicalService,
    APP_CONSTANTS,
    SessionService,
    ngDialog,
    toaster,
    $timeout,
    $uibModal,
    $rootScope,
  ) {
    var vm = this;

    vm.add = add;
    vm.loading = {};
    vm.accessKeys = [];
    vm.cancel = cancel;
    vm.organization = {};
    vm.confirmationMessage = '';
    vm.toggleChange = toggleChange;
    vm.downloadWidget = downloadWidget;
    vm.generatePlugin = generatePlugin;
    vm.configurePlugin = configurePlugin;
    vm.fetchCustomerApiKeys = fetchCustomerApiKeys;
    vm.displayCKDInstallationInstructions = displayCKDInstallationInstructions;

    $scope.copyToolTip = 'Copy!';

    var modalInstance = '';
    var currentUser = SessionService.getUser();
    var sponsoredOrganizationId = currentUser.sponsoredOrganizationId;

    fetchCustomerApiKeys();

    $scope.copyClicked = function() {
      $scope.copyToolTip = 'Copied to clipboard!';
      $timeout(resetCopyToolTip, 3000);
    };

    function resetCopyToolTip() {
      $scope.copyToolTip = 'Copy!';
    }

    function generatePlugin(accessKey) {
      technicalService
        .generateWidget(accessKey)
        .then(function(response) {
          $scope.widget = response;
          ngDialog.open({
            template: 'app/technical/views/ckdiscovery.html',
            scope: $scope,
            className: 'ngdialog-widget'
          });
        })
        .catch(function(error) {
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function displayCKDInstallationInstructions() {
      if (!modalInstance) {
        modalInstance = $uibModal.open({
          templateUrl:
            'app/v2/sponsoredOrganization/technicalDashboard/widgetInstallationInstructions/displayInstructions.html',
          controller: 'DisplayInstructionsController',
          controllerAs: 'vm'
        });

        modalInstance.result
          .then(function() {
            modalInstance = '';
          })
          .catch(function() {
            modalInstance = '';
          });
      }
    }

    function configurePlugin(plugin) {
      if (!modalInstance) {
        modalInstance = $uibModal.open({
          templateUrl:
            'app/v2/sponsoredOrganization/technicalDashboard/pluginConfiguration/configurePlugin.html',
          controller: 'ConfigurePluginController',
          controllerAs: 'vm',
          resolve: {
            plugin: function() {
              return plugin;
            }
          }
        });

        modalInstance.result
          .then(function() {
            fetchCustomerApiKeys();
            modalInstance = '';
          })
          .catch(function() {
            modalInstance = '';
          });
      }
    }

    function downloadWidget(accessKey) {
      technicalService
        .generateWidget(accessKey)
        .then(function(response) {
          var element = document.createElement('a');

          element.setAttribute(
            'href',
            'data:text/plain;charset=utf-8,' + encodeURIComponent(response)
          );
          element.setAttribute('download', 'ckdiscovery.html');
          element.style.display = 'none';
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
        })
        .catch(function(error) {
          toaster.pop('error', error.data.message || error.statusText);
        });
    }


    function fetchCustomerApiKeys() {
      vm.loading.keys = true;
      technicalService
        .fetchCustomerApiKeys(sponsoredOrganizationId)
        .then(function(response) {
          vm.accessKeys = response.data.data;
          vm.loading.keys = false;
        })
        .catch(function(error) {
          vm.loading.keys = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function cancel() {
      vm.accessKeys.splice(vm.accessKeys.length - 1, vm.accessKeys.length);
      vm.loading.keys = false;
    }

    function existingApiKey(hostPageUrl, id) {
      var filteredApikeys = _.filter(vm.accessKeys, function(item) {
        if (id !== null) {
          return (
            item.hostPageUrl === hostPageUrl &&
            item.status === true &&
            item.id !== id
          );
        } else {
          return item.hostPageUrl === hostPageUrl && item.status === true;
        }
      });

      return filteredApikeys;
    }

    function add(index, valid) {
      if (!valid) {
        toaster.pop('warning', APP_CONSTANTS.MESSAGE.validUrl);

        return;
      }

      var apiKey = existingApiKey(vm.hostPageUrl[index], null);

      if (apiKey.length) {
        ngDialog
          .openConfirm({
            template: 'deactivateConformDialog',
            controllerAs: 'vm',
            plan: true,
            controller: function() {
              var vm = this;

              vm.confirmationMessage =
                APP_CONSTANTS.MESSAGE.technicalConfirmMessage;
            }
          })
          .then(
            function() {
              apiKey[0].status = false;
              addApiKey(apiKey[0], vm.hostPageUrl[index]);
            },
            function() {
              ngDialog.close();
            }
          );
      } else {
        addApiKey('', vm.hostPageUrl[index]);
      }
    }

    function addApiKey(existingApiKey, hostedPageUrl) {
      vm.loading.keys = true;

      technicalService
        .addCustomerApiKey(
          {
            hostPageUrl: hostedPageUrl,
            existingApiKey: existingApiKey,
            sponsoredOrganizationId: sponsoredOrganizationId
          },
          sponsoredOrganizationId
        )
        .then(function() {
          vm.hostPageUrl = null;
          vm.loading.keys = false;

          fetchCustomerApiKeys();
          toaster.pop(
            'success',
            APP_CONSTANTS.MESSAGE.technicalApiKeyAddedSuccess
          );
        })
        .catch(function(error) {
          vm.hostPageUrl = null;
          vm.loading.keys = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function getConfirmationMessage(status) {
      if (status) return APP_CONSTANTS.MESSAGE.technicalReactivateConfirm;

      return APP_CONSTANTS.MESSAGE.technicalDeactivateConfirm;
    }

    function toggleChange(apiKey) {
      var confirmationMessage = getConfirmationMessage(apiKey.status);
      var activeApiKey = existingApiKey(apiKey.hostPageUrl, apiKey.id);

      if (activeApiKey.length) {
        activeApiKey[0].status = false;
      }

      ngDialog
        .openConfirm({
          template: 'deactivateConformDialog',
          controllerAs: 'vm',
          plan: true,
          controller: function() {
            var vm = this;

            vm.confirmationMessage = confirmationMessage;
          }
        })
        .then(
          function() {
            vm.loading.keys = true;
            vm.confirmationMessage = confirmationMessage;
            var accessKey = apiKey;

            accessKey.status = accessKey.status;

            technicalService
              .updateCustomerApiKey(
                { accessKey: accessKey, activeApiKey: activeApiKey[0] },
                sponsoredOrganizationId
              )
              .then(function() {
                vm.hostPageUrl = null;
                vm.loading.keys = false;

                fetchCustomerApiKeys();
                toaster.pop(
                  'success',
                  APP_CONSTANTS.MESSAGE.technicalApiKeyUpdateSuccess
                );
              });
          },
          function() {
            apiKey.status = apiKey.status ? false : true;
            ngDialog.close();
            if (activeApiKey.length) {
              activeApiKey[0].status = true;
            }
            vm.hostPageUrl = null;
            vm.loading.keys = false;
          }
        );
    }

    vm.addNew = function() {
      if (
        vm.accessKeys.length === 0 ||
        vm.accessKeys[vm.accessKeys.length - 1].hostPageUrl !== null
      ) {
        vm.accessKeys.push({
          status: false,
          createdAt: '',
          hostPageUrl: '',
          accessKey: '',
          copy: false
        });
        vm.accessKeys[vm.accessKeys.length - 1].hostPageUrl = null;
      }
    };
  }
})();
