(function() {
  'use strict';

  angular.module('app.mailbox').factory('MailFolderService', MailFolderService);

  MailFolderService.$inject = [
    '$state',
    '$rootScope',
    'APP_CONSTANTS',
    'ApiCaller',
    '$window',
    '$stateParams',
    '$q',
    '$filter'
  ];

  function MailFolderService(
    $state,
    $rootScope,
    APP_CONSTANTS,
    ApiCaller,
    $window,
    $stateParams,
    $q,
    $filter
  ) {
    var self = this;

    self.fetchAllMail = fetchAllMail;

    function fetchAllMail(customerId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.mailList,
        APP_CONSTANTS.rootUrl,
        customerId
      );

      ApiCaller.apiGet(url).then(
        function(result) {
          deferred.resolve(result);
        },
        function(err) {
          deferred.reject(err);
        }
      );

      return deferred.promise;
    }

    return self;
  }
})();
