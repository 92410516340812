/* eslint-disable */
(function() {
  'use strict';

  angular
    .module('app.notification')
    .controller('NotificationBoxController', NotificationBoxController);

  NotificationBoxController.$inject = ['SessionService'];

  function NotificationBoxController(SessionService) {
    var vm = this;
  }
})();
/* eslint-enable */
