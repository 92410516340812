(function() {
  'use strict';
  angular.module('app.user').config(routesConfig);
  routesConfig.$inject = [
    '$stateProvider',
    '$locationProvider',
    '$urlRouterProvider',
    'RouteHelpersProvider'
  ];

  function routesConfig(
    $stateProvider,
    $locationProvider,
    $urlRouterProvider,
    helper
  ) {
    $locationProvider.html5Mode(false);
    $urlRouterProvider.otherwise('/user/login');
    $stateProvider
      .state('login', {
        url: '/user/login/:token',
        title: 'Sign In',
        templateUrl: helper.basepath('user/login/views/login.html'),
        controller: 'LoginController',
        controllerAs: 'login',
        params: {
          token: {squash: true, value: null},
        },
        resolve: helper.resolveFor('modernizr', 'icons', 'ngDialog')
      })
      .state('sso', {
        url: '/user/sso/:domain',
        title: 'Sign in with SSO',
        templateUrl: helper.basepath('user/sso/sso.html'),
        controller: 'SsoController',
        controllerAs: 'vm',
        params: {
          domain: {squash: true, value: null},
        },
        resolve: helper.resolveFor('modernizr', 'icons', 'ngDialog')
      })
      .state('activation', {
        url: '/user/activation/:activationId',
        title: 'Activation',
        params: {
          activationId: null
        },
        templateUrl: helper.basepath('user/activation/views/activation.html'),
        controller: 'ActivationController',
        controllerAs: 'vm',
        resolve: helper.resolveFor('modernizr', 'icons', 'ngDialog')
      })
      .state('invitation', {
        url: '/invitations/:invitationId',
        title: 'Invitation',
        templateUrl: helper.basepath('user/invitation/views/invitation.html'),
        controller: 'InvitationFormController',
        controllerAs: 'vm',
        resolve: helper.resolveFor('modernizr', 'icons', 'ngDialog')
      })
      .state('invitationByEmail', {
        url: '/invitations/email/:invitationEmail',
        title: 'Invitation',
        templateUrl: helper.basepath('user/invitation/views/invitation.html'),
        controller: 'InvitationFormController',
        controllerAs: 'vm',
        resolve: helper.resolveFor('modernizr', 'icons', 'ngDialog')
      })
      .state('activateRegistration', {
        url: '/register/token/:tokenId',
        title: 'Sign Up',
        controller: 'RegistrationTokenController',
        controllerAs: 'vm',
        templateUrl: helper.basepath(
          'user/v2/registrationToken/views/registrationToken.html'
        )
      })
      .state('register', {
        url: '/register',
        title: 'Sign Up',
        controller: 'RegistrationController',
        controllerAs: 'regController',
        templateUrl: helper.basepath(
          'user/v2/register/views/registration.html'
        ),
        resolve: angular.extend(
          helper.resolveFor(
            'modernizr',
            'icons',
            'ngDialog',
            'ui.select',
            'filestyle',
            'angularFileUpload'
          ),
          {
            getPlanDetails: [
              'PlanDetailService',
              function(PlanDetailService) {
                return PlanDetailService.getPlanDetails();
              }
            ]
          }
        )
      })
      .state('forgot', {
        url: '/forgot',
        title: 'Forgot Password',
        templateUrl: helper.basepath(
          'user/forgotPassword/views/forgotPassword.html'
        ),
        controller: 'ForgotPasswordController',
        controllerAs: 'vm',
        resolve: helper.resolveFor('modernizr', 'icons')
      })
      .state('resetpassword', {
        url: '/resetpassword/:userId',
        title: 'Reset Password',
        templateUrl: helper.basepath(
          'user/resetPassword/views/resetPassword.html'
        ),
        controller: 'ResetPasswordController',
        controllerAs: 'vm',
        resolve: helper.resolveFor('modernizr', 'icons', 'ngDialog')
      })
      .state('purchase', {
        url: '/purchase',
        title: 'Purchase',
        controller: 'PurchaseController',
        controllerAs: 'pur',
        templateUrl: helper.basepath('user/subscription/views/purchase.html'),
        resolve: helper.resolveFor('modernizr', 'icons', 'filestyle')
      })
      .state('buysubscription', {
        url: '/buysubscription',
        title: 'Buy Subscription',
        controller: 'SubscriptionController',
        controllerAs: 'pur',
        templateUrl: helper.basepath('subscription/views/buySubscription.html'),
        resolve: helper.resolveFor('modernizr', 'icons', 'filestyle')
      })
      .state('trial', {
        url: '/trial',
        title: 'Trial',
        templateUrl: helper.basepath('user/subscription/views/trial.html'),
        resolve: helper.resolveFor('modernizr', 'icons')
      })
      .state('billinginvitation', {
        url: '/billinginvitation',
        title: 'Billing Invitation',
        templateUrl: helper.basepath(
          'user/subscription/views/billingInvitation.html'
        ),
        resolve: helper.resolveFor('modernizr', 'icons')
      })
      .state('review_order', {
        url: '/review_order',
        title: 'reviewOrder',
        templateUrl: helper.basepath('user/subscription/views/reviewOrder.html')
      })
      .state('registrationcomplete', {
        url: '/registrationcomplete?id&state',
        title: 'Registration Complete',
        templateUrl: helper.basepath(
          'user/subscription/views/registrationComplete.html'
        ),
        resolve: helper.resolveFor('modernizr', 'icons')
      })
      .state('payment', {
        url: '/payment?id&state',
        title: 'Payment',
        controller: 'PaymentController',
        controllerAs: 'vm',
        templateUrl: helper.basepath('user/subscription/views/payment.html'),
        resolve: helper.resolveFor('modernizr', 'icons')
      })
      .state('purchaseorder', {
        url: '/purchaseorder',
        title: 'Purchase Order',
        controller: 'PurchaseOrderController',
        controllerAs: 'vm',
        templateUrl: helper.basepath(
          'user/subscription/views/purchaseOrder.html'
        ),
        resolve: helper.resolveFor('modernizr', 'icons')
      })
      .state('achpayment', {
        url: '/achpayment?id&state',
        title: 'achpayment Method',
        controller: 'AchController',
        controllerAs: 'vm',
        templateUrl: helper.basepath('user/subscription/views/ach.html'),
        resolve: helper.resolveFor('modernizr', 'icons')
      });
  }
})();
