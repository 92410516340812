/**
 * Created by Romit Amgai<romitamgai@lftechnology.com> on 4/18/17.
 */
(function() {
  'use strict';
  angular
    .module('app.user')
    .factory('OrderSummaryService', OrderSummaryService);

  OrderSummaryService.$inject = [
    'localStorageService',
    'APP_CONSTANTS',
    'ApiCaller',
    '$filter',
    '$q'
  ];

  function OrderSummaryService(
    localStorageService,
    APP_CONSTANTS,
    ApiCaller,
    $filter
  ) {
    var self = this;

    self.parseInitialPlan = parseInitialPlan;

    function parseInitialPlan(plan) {
      var parsedPlan = {};

      var filteredChargebeePrice = $filter('chargebeePrice')(
        plan.price,
        APP_CONSTANTS.decimalFixedValue
      );

      parsedPlan.id = plan.id;
      parsedPlan.subscriptionType = plan.name;
      parsedPlan.billingCycles =
        plan.billing_cycles || APP_CONSTANTS.defaultBillingCycle;
      parsedPlan.quantity = plan.cf_quantity;
      parsedPlan.country = plan.cf_country;
      parsedPlan.language = plan.cf_language;
      parsedPlan.periodUnit = plan.period_unit;
      parsedPlan.period = plan.period;
      parsedPlan.timelyPrice = filteredChargebeePrice;
      parsedPlan.total = filteredChargebeePrice; // TODO Calculate total with tax
      parsedPlan.price = (
        filteredChargebeePrice * parsedPlan.billingCycles
      ).toFixed(APP_CONSTANTS.decimalFixedValue);
      parsedPlan.currency = plan.currency_code;

      return parsedPlan;
    }

    return self;
  }
})();
