/**
 * Created by Romit Amgai<romitamgai@lftechnology.com> on 4/18/17.
 */
(function() {
  'use strict';
  angular
    .module('app.user')
    .factory('RegistrationService', RegistrationService);

  RegistrationService.$inject = [
    'localStorageService',
    'APP_CONSTANTS',
    'ApiCaller',
    '$filter',
    '$q'
  ];

  function RegistrationService(
    localStorageService,
    APP_CONSTANTS,
    ApiCaller,
    $filter,
    $q
  ) {
    var self = this;

    self.plans = [];
    self.taxAmount;
    self.signupType;
    self.lastActiveBlock;
    self.taxExemptFileUrl;
    self.accountInfo = {};
    self.billingInfo = {};
    self.paymentDetail = {};

    self.getPlan = getPlan;
    self.getToken = getToken;
    self.getPlans = getPlans;
    self.getStates = getStates;
    self.checkEmail = checkEmail;
    self.getCountries = getCountries;
    self.getTaxAmount = getTaxAmount;
    self.setTaxAmount = setTaxAmount;
    self.getSignupType = getSignupType;
    self.setSignupType = setSignupType;
    self.checkGoogleId = checkGoogleId;
    self.getPlanDetails = getPlanDetails;
    self.mapAccountInfo = mapAccountInfo;
    self.setAccountInfo = setAccountInfo;
    self.getAccountInfo = getAccountInfo;
    self.setBillingInfo = setBillingInfo;
    self.getBillingInfo = getBillingInfo;
    self.getPaymentDetail = getPaymentDetail;
    self.setPaymentDetail = setPaymentDetail;
    self.setLastActiveBlock = setLastActiveBlock;
    self.getLastActiveBlock = getLastActiveBlock;
    self.invalidAccountInfo = invalidAccountInfo;
    self.invalidBillingInfo = invalidBillingInfo;
    self.getChargebeeAmount = getChargebeeAmount;
    self.setTaxExemptFileUrl = setTaxExemptFileUrl;
    self.getTaxExemptFileUrl = getTaxExemptFileUrl;

    function setAccountInfo(accountInfo) {
      self.accountInfo = accountInfo;
    }

    function getAccountInfo() {
      return self.accountInfo;
    }

    function setSignupType(signupType) {
      self.signupType = signupType;
    }

    function getSignupType() {
      return self.signupType;
    }

    function setPaymentDetail(paymentDetail) {
      self.paymentDetail = paymentDetail;
    }

    function getPaymentDetail() {
      return self.paymentDetail;
    }

    function setLastActiveBlock(lastActiveBlock) {
      self.lastActiveBlock = lastActiveBlock;
    }

    function getLastActiveBlock() {
      return self.lastActiveBlock;
    }

    function setTaxAmount(amount) {
      self.taxAmount = amount;
    }

    function getTaxAmount() {
      return self.taxAmount;
    }

    function setTaxExemptFileUrl(url) {
      self.taxExemptFileUrl = url;
    }

    function getTaxExemptFileUrl() {
      return self.taxExemptFileUrl;
    }

    function setBillingInfo(billingInfo) {
      self.billingInfo = billingInfo;
    }

    function getBillingInfo() {
      return self.billingInfo;
    }

    function invalidAccountInfo() {
      return angular.equals(self.accountInfo, {});
    }

    function invalidBillingInfo() {
      return angular.equals(self.billingInfo, {});
    }

    function getCountries(name) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.countries,
        APP_CONSTANTS.rootUrl,
        name
      );

      ApiCaller.apiGet(url).then(
        function(result) {
          deferred.resolve(result);
        },
        function(err) {
          deferred.reject(err);
        }
      );

      return deferred.promise;
    }

    function mapAccountInfo() {
      return {
        city: self.accountInfo.city,
        title: self.accountInfo.title,
        state: self.accountInfo.state ? self.accountInfo.state : '',
        addressLine1: self.accountInfo.addressLine1,
        addressLine2: self.accountInfo.addressLine2,
        zipCode: self.accountInfo.zipCode,
        country: self.accountInfo.country,
        lastName: self.accountInfo.lastName,
        firstName: self.accountInfo.firstName,
        organization: self.accountInfo.organization
      };
    }

    function getStates(countryCode) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.statesByCountryCode,
        APP_CONSTANTS.rootUrl,
        countryCode
      );

      ApiCaller.apiGet(url).then(
        function(result) {
          deferred.resolve(result);
        },
        function(err) {
          deferred.reject(err);
        }
      );

      return deferred.promise;
    }

    function getPlans(params) {
      var deferred = $q.defer();
      var url;
      
      if (params) {
        url = $filter('sprintf')(
          APP_CONSTANTS.planCollection,
          APP_CONSTANTS.rootUrl,
          $.param(params)
        );
      } else {
        url = $filter('sprintf')(
          APP_CONSTANTS.planCollection,
          APP_CONSTANTS.rootUrl
        );
      }

      ApiCaller.apiGet(url).then(
        function(result) {
          self.plans = result.data.data;
          deferred.resolve(result.data.data);
        },
        function(err) {
          deferred.reject(err);
        }
      );

      return deferred.promise;
    }

    function getPlan(planId) {
      var selectedPlanArray = self.plans.filter(function(plan) {
        return plan.plan.id === planId;
      });

      return selectedPlanArray[0].plan;
    }

    function getToken() {
      return localStorageService.get('registrationToken');
    }

    function getPlanDetails(token) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.planFromToken,
        APP_CONSTANTS.rootUrl,
        token
      );

      ApiCaller.apiGet(url).then(
        function(result) {
          deferred.resolve(result);
        },
        function(err) {
          deferred.reject(err);
        }
      );

      return deferred.promise;
    }

    function checkEmail(email) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.checkEmail,
        APP_CONSTANTS.rootUrl,
        encodeURIComponent(email)
      );

      ApiCaller.apiGet(url).then(
        function(result) {
          deferred.resolve(result);
        },
        function(err) {
          deferred.reject(err);
        }
      );

      return deferred.promise;
    }

    function getChargebeeAmount(amount) {
      return $filter('chargebeePrice')(amount, APP_CONSTANTS.decimalFixedValue);
    }

    function checkGoogleId(googleId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.checkGoogleId,
        APP_CONSTANTS.rootUrl,
        googleId
      );

      ApiCaller.apiGet(url).then(
        function(result) {
          deferred.resolve(result);
        },
        function(err) {
          deferred.reject(err);
        }
      );

      return deferred.promise;
    }

    return self;
  }
})();
