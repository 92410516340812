(function() {
  'use strict';
  angular
    .module('app.shared')
    .controller('ViewCareerDetailsController', ViewCareerDetailsController);

  ViewCareerDetailsController.$inject = [
    'APP_CONSTANTS',
    '$scope',
    'selectedCareer',
    '$uibModalInstance'
  ];

  function ViewCareerDetailsController(
    APP_CONSTANTS,
    $scope,
    selectedCareer,
    $uibModalInstance
  ) {
    var vm = this;

    vm.loading = {};
    vm.careerDetails = {};
    vm.dismissModal = dismissModal;
    vm.selectedCareer = selectedCareer;
    vm.careersLanguageVariationArray = [];
    vm.getLanguageVariations = getLanguageVariations;

    vm.selectedCareerName = _.get(
      selectedCareer,
      'variations.en.name',
      APP_CONSTANTS.occupation
    );

    getLanguageVariations();

    function dismissModal() {
      $uibModalInstance.dismiss();
    }

    function getLanguageVariations() {
      var alreadyUnpublishedCareer = $scope.$parent.vm.getUnpublishedCareerIfExist(
        vm.selectedCareer.id
      );

      if (alreadyUnpublishedCareer)
        vm.selectedCareer = JSON.parse(
          JSON.stringify(alreadyUnpublishedCareer)
        );

      vm.careerDetails = {
        alternateId: vm.selectedCareer.alternateId,
        soc: vm.selectedCareer.soc,
        medianPay: vm.selectedCareer.medianPay,
        jobOutlook: vm.selectedCareer.jobOutlook,
        educationalRequirementID: vm.selectedCareer.educationalRequirementID
      };

      vm.careersLanguageVariation = JSON.parse(
        JSON.stringify(vm.selectedCareer.variations)
      );

      Object.keys(vm.careersLanguageVariation).forEach(function(key) {
        vm.careersLanguageVariationArray.push({
          name: APP_CONSTANTS.languageVariations[key],
          variation: vm.careersLanguageVariation[key]
        });
      });
    }
  }
})();
