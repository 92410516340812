(function() {
  'use strict';

  angular.module('app.user').factory('loginService', loginService);

  loginService.$inject = [
    '$rootScope',
    '$state',
    'APP_CONSTANTS',
    '$q',
    '$filter',
    'SessionService',
    'ApiCaller',
    'AccessRoleService'
  ];

  function loginService(
    $rootScope,
    $state,
    APP_CONSTANTS,
    $q,
    $filter,
    SessionService,
    ApiCaller,
    AccessRoleService
  ) {
    var self = this;

    self.loginApiPost = loginApiPost;
    self.getNextState = getNextState;
    self.sendDemo = sendDemo;

    function loginApiPost(account) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(APP_CONSTANTS.login, APP_CONSTANTS.rootUrl);

      ApiCaller.apiPost(url, account)
        .then(function(result) {
          SessionService.setUser(result.data.data);
          SessionService.setOrganizationImage(result.data.data.customerImg);

          // TODO: Change the language according to the language set in the database
          // $rootScope.language.set(result.data.data.locale);
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function getNextState() {
      var userRole = AccessRoleService.getHighPriorityRole();
      
      var menu = SessionService.getMenus();

      return _.get(menu[1], 'sref', userRole.state);
    }

    function sendDemo(emailId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.sendDemo,
        APP_CONSTANTS.rootUrl
      );

      ApiCaller.apiPost(url, { email: emailId })
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    return self;
  }
})();
