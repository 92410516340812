(function() {
  'use strict';
  angular.module('app.user').controller('AchController', AchController);

  AchController.$inject = ['$state', 'AchService', 'APP_CONSTANTS', 'toaster'];

  function AchController($state, AchService, APP_CONSTANTS, toaster) {
    var vm = this;

    vm.loading = {};
    vm.loading.registration = true;
    var hostedPageId = $state.params.id;

    AchService.fetchHostedPageDetails(hostedPageId).then(
      function(result) {
        var hostedPageDetail = result.data.data.hosted_page.content;

        hostedPageDetail.customerId =
          result.data.data.hosted_page.pass_thru_content;

        AchService.subscribe(hostedPageDetail).then(
          function() {
            // AchService.destroyRegistrationData();
            vm.loading.registration = false;
            $state.go('login');
          },
          function(error) {
            vm.loading.registration = false;
            toaster.pop('error', error.data.message || error.statusText);
          }
        );
      },
      function(error) {
        vm.loading.registration = false;
        toaster.pop('error', error.data.message || error.statusText);
      }
    );
  }
})();
