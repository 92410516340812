(function() {
  'use strict';
  angular
    .module('app.shared')
    .controller('MappedTagsForCareerController', MappedTagsForCareerController);

  MappedTagsForCareerController.$inject = [
    'APP_CONSTANTS',
    '$scope',
    'isPublished',
    'selectedCareer',
    'productTagList',
    '$uibModalInstance',
    'ProductService'
  ];

  function MappedTagsForCareerController(
    APP_CONSTANTS,
    $scope,
    isPublished,
    selectedCareer,
    productTagList,
    $uibModalInstance,
    ProductService
  ) {
    var vm = this;

    vm.loading = {};
    vm.productTagList = productTagList;
    vm.mappedTagList = [];
    vm.isPublished = isPublished;
    vm.selectedCareer = selectedCareer;
    vm.dismissModal = dismissModal;
    vm.saveMappedTags = saveMappedTags;
    vm.productId = ProductService.getProductId();
    vm.selectedCareerName = _.get(
      selectedCareer,
      'variations.en.name',
      APP_CONSTANTS.occupation
    );

    vm.updatedTagIdArray = [];

    checkUnpublishedCareersAlreadyExist();

    function checkUnpublishedCareersAlreadyExist() {
      var alreadyUnpublishedCareer = $scope.$parent.vm.getUnpublishedCareerIfExist(
        vm.selectedCareer.id
      );

      if (alreadyUnpublishedCareer)
        vm.selectedCareer = alreadyUnpublishedCareer;

      if (!vm.selectedCareer.tags) {
        vm.selectedCareer.tags = [];
      }

      fetchTagsByIds(vm.selectedCareer.tags);
    }

    function fetchTagsByIds(tagIdArray) {
      tagIdArray = tagIdArray || [];

      tagIdArray.forEach(function(tagId) {
        vm.productTagList.forEach(function(tag) {
          if (tag.id === tagId) {
            vm.mappedTagList.push({
              id: tag.id,
              name: tag.name
            });
          }
        });
      });
    }

    function getTagIdArray(tagArray) {
      tagArray.forEach(function(tag) {
        vm.updatedTagIdArray.push(tag.id);
      });

      return vm.updatedTagIdArray;
    }

    function saveMappedTags() {
      $scope.$parent.vm.updateUnpublishedCareers(vm.selectedCareer.id);
      $scope.$parent.vm.updateMappedTagsInCareer(
        vm.selectedCareer.id,
        getTagIdArray(vm.mappedTagList)
      );
      dismissModal();
    }

    function dismissModal() {
      $uibModalInstance.dismiss();
    }
  }
})();
