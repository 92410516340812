(function() {
  'use strict';
  angular
    .module('app.shared')
    .controller('NewProductController', NewProductController);

  NewProductController.$inject = [
    'APP_CONSTANTS',
    'toaster',
    '$scope',
    '$uibModalInstance',
    'productList',
    'NewProductService'
  ];

  function NewProductController(
    APP_CONSTANTS,
    toaster,
    $scope,
    $uibModalInstance,
    productList,
    NewProductService
  ) {
    var vm = this;

    vm.loading = {};
    vm.product = {};
    vm.allAssessments = [];
    vm.productList = _.sortBy(productList, ['name', 'version']);
    vm.productNames = [
      'Career Decision Profile',
      'Career Key Discovery Transitions',
      'Career Key Discovery Student',
    ];
    vm.assessmentTypes = [
      APP_CONSTANTS.CDP,
      APP_CONSTANTS.CKD,
    ];
    vm.dismissModal = dismissModal;
    vm.addNewProduct = addNewProduct;
    vm.checkBoxModel = {
      disableOccupationsProduct: false,
      disableInstructionalProgramsProduct: false
    };

    fetchAllAssessments();
    fetchCountries();

    function fetchAllAssessments() {
      vm.loading.allAssessments = true;

      NewProductService.fetchAllAssessments()
        .then(function(result) {
          vm.loading.allAssessments = false;

          vm.allAssessments = _.get(result, 'data.cks', []);
        })
        .catch(function(error) {
          vm.loading.allAssessments = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function fetchCountries(name) {
      vm.loading.countryList = true;

      NewProductService.fetchCountries(name)
        .then(function(result) {
          vm.loading.countryList = false;

          vm.countryList = _.get(result, 'data.data', []);
        })
        .catch(function(error) {
          vm.loading.countryList = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function addNewProduct() {
      if (vm.addNewProductForm.$invalid) return;

      vm.loading.productInfo = true;

      var payload = {
        id: vm.product.id,
        version: vm.product.version,
        assessmentType: vm.product.assessmentType,
        name: vm.product.name,
        country: {
          id: vm.product.country?.countryCode,
          name: vm.product.country?.name
        },
        occupationsProduct: !vm.checkBoxModel.disableOccupationsProduct
          && vm.product.occupationsProduct || '',
        instructionalProgramsProduct: !vm.checkBoxModel.disableInstructionalProgramsProduct
          && vm.product.instructionalProgramsProduct || '',
        assessments: vm.product.assessments || [],
        variation: vm.product.variations || {}
      };

      NewProductService.addNewProduct(payload)
        .then(function() {
          vm.loading.productInfo = false;

          $scope.$parent.vm.fetchProductIds();

          toaster.pop('success', APP_CONSTANTS.MESSAGE.productAdded);
          dismissModal();
        })
        .catch(function(error) {
          vm.loading.productInfo = false;
          toaster.pop('error', error.data.message || error.statusText);
        });
    }

    function dismissModal() {
      $uibModalInstance.dismiss();
    }
  }
})();
