(function() {
  'use strict';
  angular
    .module('app.careerDecisionProfileClient')
    .factory('CDPClientDetailsService', CDPClientDetailsService);

  CDPClientDetailsService.$inject = [
    '$q',
    'APP_CONSTANTS',
    'ApiCaller',
    '$filter'
  ];

  function CDPClientDetailsService($q, APP_CONSTANTS, ApiCaller, $filter) {
    var self = this;

    self.shareResult = shareResult;
    self.getClientDetails = getClientDetails;
    self.generateCDPProfilePdf = generateCDPProfilePdf;

    function generateCDPProfilePdf(discovererId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.generateCdpProfilePdf,
        APP_CONSTANTS.rootUrl,
        discovererId
      );

      ApiCaller.apiProfilePdfGet(url)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function getClientDetails(clientId) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.cdpDiscovererDetail,
        APP_CONSTANTS.rootUrl,
        clientId
      );

      ApiCaller.apiGet(url)
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    function shareResult(discovererId, email) {
      var deferred = $q.defer();
      var url = $filter('sprintf')(
        APP_CONSTANTS.shareCDPResult,
        APP_CONSTANTS.rootUrl,
        discovererId
      );

      ApiCaller.apiPost(url, { email: email })
        .then(function(result) {
          deferred.resolve(result);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    return self;
  }
})();
