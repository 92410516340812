(function () {
  'use strict';

  angular
    .module('app.shared')
    .controller('AdminCancelSubscriptionModalController', AdminCancelSubscriptionModalController);

  AdminCancelSubscriptionModalController.$inject = [
    'APP_CONSTANTS',
    'toaster',
    '$uibModalInstance',
    'AdminCustomerSubscriptionService',
    'data',
    '$location',
    '$anchorScroll'
  ];

  function AdminCancelSubscriptionModalController(
    APP_CONSTANTS,
    toaster,
    $uibModalInstance,
    AdminCustomerSubscriptionService,
    data,
    $location,
    $anchorScroll
  ) {
    var vm = this;

    vm.loading = {};
    vm.noPaymentOrBillingDetail = false;
    vm.noActiveSubscriptions =
      _.isEmpty(data.subscription)
        || data.subscription.actualStatus.toLowerCase() !== APP_CONSTANTS.SUBSCRIPTION_STATUS.ACTIVE
        || data.subscription.type.toLowerCase() !== APP_CONSTANTS.SUBSCRIPTION_TYPE.AUTO_RENEWAL
        ? true
        : false;
    vm.subscription = !vm.noActiveSubscriptions ? data.subscription : {};
    vm.isPeriodUnitMonthly =
      !vm.noActiveSubscriptions
        && vm.subscription.periodUnit.toLowerCase() === 'month'
        ? true
        : false;

    vm.cancel = cancel;
    vm.submit = submit;

    function submit(form) {
      vm.form = form;

      if (!vm.choice) {
        $location.hash('myModalLabel');
        $anchorScroll();
        toaster.clear('cancelBillingSubscription');
        toaster.pop({
          type: 'error',
          body: APP_CONSTANTS.MESSAGE.optionSelectionValidation,
          showCloseButton: true,
          toasterId: 'cancelBillingSubscription',
          timeout: 5000
        });

        return;
      }
      if (vm.choice === 'goBack') {
        cancel();

        return;
      }

      vm.loading.deleteSubscriptions = true;

      AdminCustomerSubscriptionService.deleteSubscriptions(data.customerId, vm.subscription.id)
        .then(function () {
          vm.loading.deleteSubscriptions = false;
          toaster.pop(
            'success',
            APP_CONSTANTS.MESSAGE.subscriptionCancellationSuccess
          );
          closePopup();
        })
        .catch(function () {
          vm.loading.deleteSubscriptions = false;
          toaster.pop({
            type: 'error',
            body: APP_CONSTANTS.MESSAGE.subscriptionCancellationFailure,
            toasterId: 'subscriptionCancellation'
          });
        });
    }

    function closePopup() {
      $uibModalInstance.close('closed');
    }

    function cancel() {
      $uibModalInstance.dismiss('cancel');
    }
  }
})();
