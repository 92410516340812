(function() {
  'use strict';
  angular
    .module('app.user')
    .controller('ActivationController', ActivationController);

  ActivationController.$inject = [
    '$state',
    'APP_CONSTANTS',
    'toaster',
    'ActivationService',
    'loginService',
    'SessionService'
  ];

  function ActivationController(
    $state,
    APP_CONSTANTS,
    toaster,
    ActivationService
  ) {
    var vm = this;

    vm.loading = {};
    vm.loading.activation = true;
    var activationCode = $state.params.activationId;

    ActivationService.activate(activationCode)
      .then(function(result) {
        ActivationService.storeUser(result.data.data);
        $state.go(ActivationService.getNextState());
        vm.loading.activation = false;
      })
      .catch(function(error) {
        vm.loading.activation = false;
        $state.go('error', { message: error.data.message });
      });
  }
})();
