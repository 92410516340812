/**
 * Created by Romit Amgai<romitamgai@lftechnology.com> on 4/18/17.
 */
(function() {
  'use strict';
  angular
    .module('app.user')
    .factory('RegistrationTokenService', RegistrationTokenService);

  RegistrationTokenService.$inject = ['localStorageService'];

  function RegistrationTokenService(localStorageService) {
    var self = this;

    self.store = store;

    function store(token) {
      localStorageService.set('registrationToken', token);
    }

    return self;
  }
})();
