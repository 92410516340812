/**
 * Created by Romit Amgai<romitamgai@lftechnology.com> on 4/18/17.
 */

(function() {
  'use strict';
  angular
    .module('app.user')
    .controller('AccountInfoController', AccountInfoController);
  AccountInfoController.$inject = [
    '$state',
    'AccountInfoService',
    'RegistrationService',
    'toaster',
    'APP_CONSTANTS',
    '$filter',
    '$scope',
    '$uibModal',
  ];

  function AccountInfoController(
    $state,
    AccountInfoService,
    RegistrationService,
    toaster,
    APP_CONSTANTS,
    $filter,
    $scope,
    $uibModal,
  ) {
    var vm = this;

    vm.accountInfo = {
      purchaseType: APP_CONSTANTS.REGISTER_TYPE.PURCHASE,
      newsupdates: false
    };

    vm.regex = {
      email: APP_CONSTANTS.REGEX.EMAIL,
      password: APP_CONSTANTS.REGEX.PASSWORD
    };

    vm.submit = submit;
    vm.checkEmail = checkEmail;
    vm.openEmailModal = openEmailModal;
    vm.signUpWithGoogle = signUpWithGoogle;
    vm.openAccordion = openAccordion;
    vm.fetchCountries = fetchCountries;
    vm.countrySelected = countrySelected;
    vm.purchaseTypeSelected = purchaseTypeSelected;

    vm.showPurchaseTypeSelection = $scope.regController.showBlock.billing;
    vm.submitBtnText = vm.showPurchaseTypeSelection
      ? 'Next'
      : 'Start Free Trial';

    fetchCountries();

    function fetchCountries(name) {
      RegistrationService.getCountries(name)
        .then(function(result) {
          vm.countries = result.data.data;
        })
        .catch(function(error) {
          toaster.clear('account');

          toaster.pop({
            type: 'error',
            body: error.data.message || error.statusText,
            toasterId: 'account'
          });
          document.getElementById('account-details').scrollIntoView(true);
        });
    }

    function countrySelected() {
      RegistrationService.getStates(vm.accountInfo.country.countryCode)
        .then(function(result) {
          vm.states = result.data.data.states;
          vm.accountInfo.state = null;
        })
        .catch(function(error) {
          toaster.clear('account');

          toaster.pop({
            type: 'error',
            body: error.data.message || error.statusText,
            toasterId: 'account'
          });
          document.getElementById('account-details').scrollIntoView(true);
        });
    }

    function submit(form) {
      if (!form.$valid) return;
      if (vm.duplicateEmail) return;
      if (
        !vm.googleOauthSuccess &&
        !vm.accountInfo.email &&
        !vm.accountInfo.password &&
        !vm.accountInfo.confirmEmail &&
        !vm.accountInfo.confirmPassword
      )
        return;

      form.$setPristine();
      RegistrationService.setAccountInfo(vm.accountInfo);

      if (vm.showPurchaseTypeSelection) {
        angular.copy(
          vm.accountInfo,
          $scope.regController.registrationDetails.accountInfo
        );

        toaster.clear('account');
        openAccordion();
      } else {
        var paymentDetail = {
          type: APP_CONSTANTS.REGISTER_TYPE.TRIAL
        };

        RegistrationService.setPaymentDetail(paymentDetail);
        RegistrationService.setSignupType(APP_CONSTANTS.REGISTER_TYPE.TRIAL);

        openAccordion();
      }
    }

    function purchaseTypeSelected() {
      if (
        vm.accountInfo.purchaseType ===
        APP_CONSTANTS.REGISTER_TYPE.BILLING_CONTACT
      ) {
        angular.copy(
          APP_CONSTANTS.REGISTRATION_BLOCK.BILLING,
          $scope.regController.registrationBlocks
        );
        $scope.regController.showBlock.billingContact = true;
      } else {
        $scope.regController.showBlock.billingContact = false;
        if (
          APP_CONSTANTS.REGISTRATION_BLOCK.PURCHASE !==
          $scope.regController.registrationBlocks
        ) {
          angular.copy(
            APP_CONSTANTS.REGISTRATION_BLOCK.PURCHASE,
            $scope.regController.registrationBlocks
          );
        }
      }
    }

    function checkEmail(email) {
      if (!email) {
        return;
      }

      RegistrationService.checkEmail(email).then(
        function(result) {
          vm.duplicateEmail = result.data.data > 0;
        },
        function(error) {
          toaster.clear('account');

          toaster.pop({
            type: 'error',
            body: error.data.message || error.statusText,
            toasterId: 'account'
          });
          document.getElementById('account-details').scrollIntoView(true);
        }
      );
    }

    function checkGoogleId(googleId) {
      if (!googleId) {
        return;
      }

      RegistrationService.checkGoogleId(googleId).then(
        function(result) {},
        function(error) {
          toaster.clear('account');
          vm.duplicateEmail = true;

          toaster.pop({
            type: 'error',
            body: error.data.message || error.statusText,
            toasterId: 'account'
          });
          document.getElementById('account-details').scrollIntoView(true);
        }
      );
    }

    function openAccordion() {
      var nextBlock = $filter('nextItem')(
        $scope.regController.registrationBlocks,
        $scope.regController.activeBlock.label
      );

      $scope.regController.activeBlock.label = nextBlock.label;
      $scope.regController.openBlock[nextBlock.id] = true;
    }

    function setAccountInfo(accountInfo) {
      vm.accountInfo = accountInfo;
      delete vm.accountInfo.oauth;
      vm.googleOauthSuccess = false;
      vm.duplicateEmail = false;
    }

    function openEmailModal() {
      $scope.modalInstance = $uibModal.open({
        animation: true,
        templateUrl: 'accountInfo',
        controller: function() {
          var modal = this;

          modal.accountInfo = angular.copy(vm.accountInfo, {});
          modal.regex = vm.regex;
          modal.showPassword = {
            password: false,
            confirmPassword: false
          };

          modal.viewPassword = function(id) {
            modal.showPassword[id] = !modal.showPassword[id];
          };

          modal.checkEmail = function() {
            if (!modal.accountInfo.email) {
              return;
            }

            RegistrationService.checkEmail(modal.accountInfo.email).then(
              function(result) {
                modal.duplicateEmail = result.data.data > 0;
              },
              function(error) {
                toaster.clear('account');

                toaster.pop({
                  type: 'error',
                  body: error.data.message || error.statusText,
                  toasterId: 'account'
                });
                document.getElementById('account-details').scrollIntoView(true);
              }
            );
          };

          // modal.generatePattern = function(password) {
          //   var generatedPattern = '';

          //   if (password) {
          //     for (var i = 0; i < password.length; i++) {
          //       if (
          //         password[i] === '*' ||
          //         password[i] === '$' ||
          //         password[i] === '^'
          //       ) {
          //         generatedPattern = generatedPattern + '\\' + password[i];
          //       } else {
          //         generatedPattern = generatedPattern + password[i];
          //       }
          //     }

          //     return generatedPattern;
          //   }

          //   return;
          // };

          modal.generateEmailPattern = function(email) {
            var generatedPattern = '';

            if (email) {
              for (var i = 0; i < email.length; i++) {
                if (email[i] === '+') {
                  generatedPattern = generatedPattern + '\\' + email[i];
                } else {
                  generatedPattern = generatedPattern + email[i];
                }
              }

              return generatedPattern;
            }

            return;
          };

          modal.submit = function(form) {
            if (!form.$valid) return;
            if (modal.duplicateEmail) return;

            form.$setPristine();

            setAccountInfo(modal.accountInfo);
            $scope.modalInstance.close();
          };

          modal.cancel = function() {
            modal.accountInfo = {};
            $scope.modalInstance.close();
          };
        },
        controllerAs: 'modal',
        size: 'md',
        scope: $scope
      });
    }

    function signUpWithGoogle(credential) {
      checkGoogleId(credential.token);
      checkEmail(credential.email);
      vm.googleOauthSuccess = true;
      vm.accountInfo.email = credential.email;
      vm.accountInfo.oauth = {
        token: credential.token,
        type: 'google'
      };
    }
  }
})();
